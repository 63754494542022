import { Typography, Button } from "@mui/material";
import { Stack, Box } from "@mui/system";
import React, { useState } from "react";
import purchaseimg from "../../../assets/images/purchas.png";
import Verified from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";
function Purchase({handleBuyNowClose,nftData}:{handleBuyNowClose:() => void;nftData:any}) {

  const navigate =  useNavigate()
  console.log("nftData",nftData)
  const [showComponent, setShowComponent] = useState(true);

  const handleCloseButtonClick = () => {
    setShowComponent(false);
    navigate("/nft")
  };

  const handleBuyClick = () =>{
    navigate("/nft")
  }

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "15px",
      }}
    >
      {showComponent && (
        <Box
          sx={{
            width: " 555px",
            // height: "135px",
            borderRadius: "24px",

            background:
              " linear-gradient(267deg, rgba(255, 153, 13, 0.40) -3.12%, rgba(157, 0, 255, 0.40) 93.15%)",

            backdropFilter: "blur(2px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "13px", md: "22px" },
                  fontWeight: "500",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  color:"white"
                }}
              >
                Purchase Successful
              </Typography>
            </Box>

            <>
              {/* {isVisible && ( */}
              <Button
                sx={{
                  position: " absolute",
                  right: { xs: "5px", md: "8px" },
                  fontSize: { xs: "12px", md: "20px" },
                  color: "#fff",
                }}
                onClick={handleCloseButtonClick}
              >
                X
              </Button>
            </>
          </Box>

          <Box
            sx={{
              borderRadius: "24px",

              background: "  var(--272727, #272727)",
              backdropFilter: "blur(2px)",

              padding: { xs: "10px", md: "30px" },
            }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "13px", md: "18px" },
                  fontWeight: "400",
                  color:"white"
                }}
              >
                You have successfully purchased the NFT
                <br />
                NFT has been added to your profile
              </Typography>
            </Box>
            <Box>
              {" "}
              <img src={purchaseimg} width="100%" alt="img" draggable="false" />
            </Box>
            <Box
              sx={{
                borderRadius: "12px",
                display: "flex",
                border: " 1px solid #686868",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "125px",
                  width: "107px",
                  alignItems: "center",
                  display: "flex",
                  marginLeft: "10px",
                }}
              >
                {" "}
                <img
                  style={{ borderRadius: "20px" }}
                  src={nftData.imageUrl}
                  alt="img"
                  draggable="false"
                  width={"100%"}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "20px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      color: "#fff",

                      fontSize: "22px",
                      fontFamily: "outfit",
                      fontWeight: "500px",
                    }}
                  >
                    {" "}
                    {nftData.nftName}
                    {/* DEX 3708 */}
                  </Typography>
                  <Button
                    sx={{
                      position: " absolute",
                      right: "20px",
                      fontSize: 15,
                      color: "#fff",
                    }}
                  >
                    X
                  </Button>
                </Box>
                <Typography sx={{ fontFamily: "outfit", color:"white" }}>
                  {" "}
                  {nftData.price} MATIC
                </Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <Box sx={{ color: "blue" }}>
                    <Verified />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "20.168px",
                      fontFamily: "outfit",
                      fontWeight: "400", color:"white"
                    }}
                  >
                     {nftData.ownedBy}{" "}
                  </Typography>
                </Box>{" "}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: 3,
                  paddingLeft: 8,
                  paddingRight: 8,
                  width: { xs: "auto", md: "400px" },
                  mt: "50px",
                  background:
                    "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  fontSize: {
                    xs: 10,
                    sm: 7,
                    md: 10,
                    lg: 18,
                  },
                }}
                onClick={() =>handleBuyClick()}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Stack>
  );
}

export default Purchase;
