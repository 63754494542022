import { Box } from "@mui/system";
import { useState } from "react";
import {
  Button,
  TextField,
  Typography,
} from "@mui/material";
import VoeuxGameHeader from "../../components/voeuxGame/VoeuxGameHeader";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {useNavigate} from "react-router-dom";
import VoeuxFooter from "../../components/voeuxGame/VoeuxFooter";
import resetPass from "../../assets/VoeuxGame/resertimg.png";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import axios from "axios";
import { Environment } from "../../../src/environments";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

function ResetPassword() {
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenValue = params.get('token');
  console.log("params",tokenValue)
  const handleBack = () => {
    navigate(-1);
  };
  const [showBuyNow, setShowBuyNow] = useState(false);

  const handleListItemClick = () => {
    setShowBuyNow(!showBuyNow);
  };
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
const [resetData, setResetData] = useState<any>({})
  const handleResetPassword = async(e:any) => {
    e.preventDefault();
    setShowLoader(true)
    console.log("reset password",resetData )
    if(resetData.password.length <= 8){
      showToastErrorMessageClick("Password should be atleast 8 character")
      setShowLoader(false)
      return false
    }
    if(resetData.password !== resetData.renter){
      showToastErrorMessageClick("Password doesnt match")
      setShowLoader(false)
      return false
    }
    try{
      const response = await axios.post(
        `${Environment.metavoeuxBackend}game/reset-password`,
        {
          password: resetData.password,
          token:  tokenValue,
        }
        
      );
      console.log("reset password",response)
      showToastMessageClick("Password reset is successfully.")
      setTimeout(() =>  {
        navigate("/user/signin")
        setShowLoader(false)
      },1000)
     
    }catch(error:any){
      console.log("reset password",error)
      showToastErrorMessageClick("Failed to reset the password. Please try again.")
      setTimeout(() =>  {
        navigate("/forgotPassword")
        setShowLoader(false)
      },1000)
    }
  }
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  const [ showLoader, setShowLoader] = useState(false)
  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1400,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
     <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      <Box
        sx={{
          background: "black",
          width: "100%",
          minHeight: "100vh",
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          overflow: "hidden",
          position: "relative",
          zIndex: 0,
        }}
      >
        <VoeuxGameHeader menubar={false}/>
        <Box
          sx={{
            ...circleStyle,
            display: { xs: "flex", sm: "flex", md: "flex" },
            top: 0,
            right: 0,
            left: "0",
            margin: "auto",
            bottom: "0px",
            // transform: "translateY(37%)",
            width: "60%", // Adjusted width
            height: "50%", // Adjusted height
            borderRadius: "100%", // Ensures it remains a perfect circle
            background: ` #464646`,
            filter: "blur(114px)",
            backdropFilter: { md: "none", xs: "blur(114px)" },
            WebkitBackdropFilter: { md: "none", xs: "blur(114px)" },
            overflow: "hidden",
          }}
        ></Box>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              padding: "0% 10px",
            },
          display:"flex",
          alignItems:"center"
            // background:"radial-gradient(50% 49.66% at 50% 49.66%, #464646 45.28%, #000 100%)"
          }}
        >
          <Box
            sx={{ display: "none", marginBottom: "10px", cursor: "pointer" }}
            onClick={() => handleBack()} 
          >
            <ArrowBackIosIcon onClick={() => handleBack()} sx={{color:"#ECE218"}} />
            <Typography sx={{ fontFamily: "outfit",color:"#ECE218" }}> Back</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { md: "row", xs: "column" },
              rowGap: "30px",
              alignItems: "center",
              columnGap: "100px",
              marginBottom: "40px",
            }}
          >
            {/* userProfile section */}
            <Box
              //   className="polygonStyle2"
              sx={{
                display: "flex",
                flexDirection: "column",
                // background: "#181818",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                // backdropFilter: "blur(10.781231880187988px)",
                width: { xs: "100%", md: "80%" },
                height: "auto",
                // height: { md: "364px", xs: "300px" },
                gap: "20px",
                textAlign: "center",
              }}
            >
              <img
                draggable="false"
                src={resetPass}
                alt=""
                width="inherit"
                style={{ width: "inherit" }}
              />
            </Box>
            {/* buy now/withdraw section */}
            <form onSubmit={(e) =>  handleResetPassword(e)}>

         
            <Box
              className="polygonStyle2"
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "#131313",

                backdropFilter: "blur(10.781231880187988px)",
                width: { md: "751px", xs: "100%" },
                height: "max-content",
                justifyContent: "start",
                alignItems: "center",
                position: "relative",
                px: { xs: "20px" },
                py: "100px",
              }}
            >
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "flex", sm: "flex", md: "flex" },
                  top: 0,
                  margin: "auto",
                  left: "0",
                  right: "0%",
                  bottom: "0px",
                  // transform: "translateY(37%)",
                  width: "535px", // Adjusted width
                  height: "172px", // Adjusted height
                  borderRadius: "100%", // Ensures it remains a perfect circle
                  background: ` #ECE218`,
                  filter: "blur(145px)",
                  backdropFilter: { md: "none", xs: "blur(145px)" },
                  WebkitBackdropFilter: { md: "none", xs: "blur(145px)" },
                  overflow: "hidden",
                }}
              ></Box>
              <Typography
                className="blendarPro"
                sx={{
                  color: " #ECE218",
                  textAlign: "center",
                  fontSize: { xs: "22px", md: "36px" },

                  fontWeight: "700",
                  lineHeight: "normal",
                  letterSpacing: "-0.72px",
                  textTransform: "uppercase",
                  marginBottom: "32px",
                }}
              >
                reset password?
              </Typography>

              <Box
                className=" inputpolygonStyle"
                sx={{
                  border: `1px solid #818181`,
                  // padding: "2px",
                  marginTop: "20px",
                  width: { md: "auto", xs: "inherit" },
                  background: `transparent`,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "1px",
                    height: "100%",
                    background: `#818181`,
                    position: "absolute",
                    transform: "rotate(52deg)",
                    top: "-12px",
                    left: "8px",
                  }}
                ></Box>
                <TextField
                  sx={{
                    // height: "40px",
                    width: { md: "448px", xs: "100%" },

                    "& input": {
                      outline: "none",
                      border: "0.65px solid #818181",
                      color: "white",
                      background: "transparent",
                      paddingRight: "30px",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  type="password"
                  id="password"
                  placeholder="Enter password"
                  variant="outlined"
                  name="password"
                  required
                  onChange={(e:any) => 
                    setResetData({
                      ...resetData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                <Box
                  sx={{
                    width: "1px",
                    height: "100%",
                    background: `#818181`,
                    position: "absolute",
                    transform: "rotate(60deg)",
                    bottom: "-12px",
                    right: "3px",
                  }}
                ></Box>
              </Box>
              <Box
                className=" inputpolygonStyle"
                sx={{
                  border: `1px solid #818181`,
                  // padding: "2px",
                  marginTop: "20px",
                  width: { md: "auto", xs: "inherit" },
                  background: `transparent`,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "1px",
                    height: "100%",
                    background: `#818181`,
                    position: "absolute",
                    transform: "rotate(52deg)",
                    top: "-12px",
                    left: "8px",
                  }}
                ></Box>
                <TextField
                  sx={{
                    // height: "40px",
                    width: { md: "448px", xs: "100%" },

                    "& input": {
                      outline: "none",
                      border: "0.65px solid #818181",
                      color: "white",
                      background: "transparent",
                      paddingRight: "30px",
                    },
                    "& fieldset": {
                      border: "none",
                    },
                  }}
                  type="password"
                  id="password"
                  placeholder="Confirm password"
                  variant="outlined"
                  name="renter"
                  required
                  onChange={(e:any) => 
                    setResetData({
                      ...resetData,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                <Box
                  sx={{
                    width: "1px",
                    height: "100%",
                    background: `#818181`,
                    position: "absolute",
                    transform: "rotate(60deg)",
                    bottom: "-12px",
                    right: "3px",
                  }}
                ></Box>
              </Box>
              <Box
                className="inputpolygonStyle BlenderProBold"
                sx={{
                  border: `1px solid #D2C915`,
                  padding: "2px",
                  width: { md: "448px", xs: "100%" },
                  // width: "448px",
                  background: `#D2C915`,
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  className="BlenderProBold"
                  sx={{
                    borderColor: "white",
                    padding: "10px 16px",
                    color: "#000",
                    fontSize: { xs: "17px", md: "20px" },
                    "&:hover": {
                      background: `#D2C915`,
                      color: "#000",
                    },
                  }}
                  type="submit"
                >
                  Reset
                </Button>
              </Box>

              <Box
                className="inputpolygonStyle BlenderProBold"
                sx={{
                  border: `1px solid #D2C915`,
                  padding: "2px",
                  width: { md: "448px", xs: "100%" },
                  // width: "448px",
                  // background: `#D2C915`,
                  textAlign: "center",
                  marginTop: "20px",
                  position:"relative",
                  "&:hover": {
                    background: `#D2C915`,
                    color: "#000",  
                  },
                }}
                onClick={() => navigate("/voeuxGame")}
              >
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#D2C915`,
                      position: "absolute",
                      transform: "rotate(52deg)",
                      top: "-11px",
                      left: "-0px",
                    }}
                  ></Box>
                <Button
                  className="BlenderProBold"
                  sx={{
                    // borderColor: "white",
                    padding: "10px 16px",
                    color: "#D2C915",
                    fontSize: { xs: "17px", md: "20px" },
                    // "&:hover": {
                    //   background: `#D2C915`,
                    //   color: "#000",
                    // },
                    "&:hover": {
                      background: `#D2C915`,
                      color: "#000",  
                    },
                  }}
                >
                  Cancel
                </Button>
                <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#D2C915`,
                      position: "absolute",
                      transform: "rotate(54deg)",
                      bottom: "-12px",
                      right: "0px",
                    }}
                  ></Box>
              </Box>

            </Box>
            </form>
          </Box>
        </Box>
      </Box>
      <Box>
        <VoeuxFooter />
      </Box>
    </>
  );
}

export default ResetPassword;
