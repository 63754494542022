// NftCollectionCard
import React, { useEffect, useState } from "react";
// import distantgalaxy from "../../assets/images/distantgalaxy.png";
// import life from "../../assets/images/life.png";
// import AstroFiction from "../../assets/images/AstroFiction.png";
// import ColorfulDog from "../../assets/images/ColorfulDog.png";

import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import hearticon from "../../assets/svgs/Heart.svg";
import plainheart from "../../assets/svgs/whiteheart.svg";

interface MyNftCollectionProps {
  collectionList?: any;
  isWatchListRequired?: Boolean;
}

const NftCollectionCard: React.FC<MyNftCollectionProps> = ({
  collectionList,
  isWatchListRequired,
}) => {
  const naviagte = useNavigate();
  const handleCardClick = () => {
    naviagte("/overview");
  };
  // const collectionList = [
  //   {
  //     id: 1,
  //     creatorimg: distantgalaxy,
  //     nftTitle: "Distant Galaxy",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 2,
  //     creatorimg: life,
  //     nftTitle: "Life On Edena",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 3,
  //     creatorimg: AstroFiction,
  //     nftTitle: "AstroFiction",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 4,
  //     creatorimg: ColorfulDog,
  //     nftTitle: "ColorfulDog 0524",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 5,
  //     creatorimg: AstroFiction,
  //     nftTitle: "AstroFiction",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 6,
  //     creatorimg: distantgalaxy,
  //     nftTitle: "Distant Galaxy",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 7,
  //     creatorimg: life,
  //     nftTitle: "Life On Edena",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 8,
  //     creatorimg: AstroFiction,
  //     nftTitle: "AstroFiction",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 9,
  //     creatorimg: ColorfulDog,
  //     nftTitle: "ColorfulDog 0524",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 10,
  //     creatorimg: distantgalaxy,
  //     nftTitle: "Distant Galaxy",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 11,
  //     creatorimg: life,
  //     nftTitle: "Life On Edena",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 12,
  //     creatorimg: AstroFiction,
  //     nftTitle: "AstroFiction",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 13,
  //     creatorimg: ColorfulDog,
  //     nftTitle: "ColorfulDog 0524",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 14,
  //     creatorimg: distantgalaxy,
  //     nftTitle: "Distant Galaxy",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  //   {
  //     id: 15,
  //     creatorimg: life,
  //     nftTitle: "Distant Galaxy",
  //     pricetitle: "price",
  //     price: "1.63 Voeux",
  //   },
  // ];
  const [collectionList1, setCollectionList] = useState<any>(collectionList);
  const hanldeWishlist = (index: number) => {
    collectionList1[index].isWishList = !collectionList1[index].isWishList;
    setCollectionList([...collectionList1, collectionList1]);
  };

  useEffect(() => {
    setCollectionList([...collectionList])
  }, [collectionList]);
  return (
    <>
      {collectionList1.map((item: any, index: number) => (
        <Box
          sx={{
            fontFamily: "outfit",
            background: "#181818",
            borderRadius: "18px",
            position: "relative",
          }}
          key={item.id}
        >
          <img
            src={item.creatorimg}
            alt={"image"}
            draggable="false"
            style={{ width: "-webkit-fill-available" }}
          />
          {isWatchListRequired && (
            <Box
              sx={{
                width: "30px",
                height: "30px",

                border: "1px solid #fff",
                borderRadius: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 1,
                right: 1,
                margin: "1rem",
              }}
            >
              <img
                src={item.isWishList ? hearticon : plainheart}
                alt={"love"}
                draggable="false"
                onClick={() => hanldeWishlist(index)}
                style={{
                  // position: "absolute",

                  margin: "8px",
                  cursor: "pointer",
                }}
              />
            </Box>
          )}{" "}
          <h3 style={{ paddingLeft: "16px" }}>{item.nftTitle}</h3>
          <Box sx={{ px: "16px" }} onClick={() => handleCardClick()}>
            <Typography sx={{ color: "gray" }}>{item.pricetitle}</Typography>
            <Typography sx={{ mb: "2rem" }}>{item.price}</Typography>
          </Box>
        </Box>
      ))}
    </>

    // <Grid item xs={12} sm={6} md={4} lg={3}>
    //   <Box
    //     sx={{
    //       display: "grid",
    //       gridTemplateColumns: {
    //         xs: "repeat(1, 100%)",
    //         lg: "repeat(5, 276px)",

    //       },
    //     }}
    //   >

    //   </Box>
    // </Grid>
  );
};

export default NftCollectionCard;
