import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import coin from "../../assets/svgs/coin.svg";
import PC from "../../assets/svgs/pc.svg";
import apple from "../../assets/svgs/apple.svg";
import android from "../../assets/svgs/android.svg";
import Profile from "../../assets/svgs/profile.svg";
import "../../components/voeuxcard/Voeuxcard.css";

interface MyCardProps {
  /** The text to display inside the button */
  type: string;
  iscenter?: boolean;
  data: Item[];
  cardresize?: boolean;
  Signup?: boolean;
  SignupButton?: boolean;
  offer?: boolean;
  offerdata?: boolean;
  tokendata?: boolean;
  onClickAction?: () => void;
}
interface Item {
  imageURL: string;
  title: string;
  availableFor?: boolean;
  pcHideFor?: boolean;
  caption?: boolean;
  followers?: boolean;
  Signup?: string;
  offer?: string;
  token?: boolean;
}
function Voeuxcard({
  type,
  iscenter,
  data,
  cardresize,
  SignupButton,
  offerdata,
  tokendata,
  onClickAction,
}: MyCardProps) {
  return (
    //   <button disabled={disabled}>{title}</button>
    <>
      {type === "offer" && (
        <Grid
          container
          className="offer-grid-paremt"
          sx={{ justifyContent: "space-between" }}
        >
          {data.map((x) => (
            <Grid item className="offer-grid" xs={12} sm={4} md={2}>
              <Stack
                direction="column"
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent="space-between"
                alignItems="center"
              >
                <img src={x.imageURL}  alt=""/>
                <p>{x.title}</p>
              </Stack>
            </Grid>
          ))}
        </Grid>
      )}
      {type == "cardstyle1" && (
        <Grid
          container
          sx={{ justifyContent: iscenter ? "space-between" : "start" }}
          className={
            cardresize
              ? "offer-grid-game-container individual-cards"
              : "offer-grid-game-container"
          }
        >
          {data.map((x) => (
            <Grid
              item
              className="offer-grid-game"
              xs={12}
              sm={4}
              md={cardresize ? 3 : 2}
              xl={cardresize ? 1.8 : 1.8}
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="left"
              >
                <img src={x.imageURL} alt=""/>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{ marginTop: "0px !important", padding: "7px" }}
                >
                  <p
                    className="title"
                    onClick={onClickAction}
                    style={{ cursor: "pointer" }}
                  >
                    {x.title}
                  </p>

                  {x.caption == true && (
                    <p className="caption">
                      Lorem ipsum dolor sit amet consectetur. urna.
                    </p>
                  )}

                  {x.availableFor == false && (
                    <Stack direction="row" spacing={1}>
                      <div className="device-tage">
                        <img src={PC} alt="" />
                        <span>PC</span>
                      </div>
                      <div className="device-tage">
                        <img src={android} alt=""/>
                        <span>Android</span>
                      </div>
                      <div className="device-tage">
                        <img src={apple} alt=""/>
                        <span>Apple</span>
                      </div>
                    </Stack>
                  )}

                  {x.pcHideFor == true && (
                    <Stack direction="row" spacing={1}>
                      <div className="device-tage">
                        <img src={android} alt="" />
                        <span>Android</span>
                      </div>
                      <div className="device-tage">
                        <img src={apple} alt="" />
                        <span>Apple</span>
                      </div>
                    </Stack>
                  )}

                  {x.followers === true && (
                    <Stack direction="row" spacing={1}>
                      <div className="followers">
                        <img src={Profile} alt="" />
                        <span>+2.8k Members</span>
                      </div>
                    </Stack>
                  )}
                  <div className="signuprow">
                    {x.token == true && (
                      <div className="coinsContainer">
                        <img src={coin} alt="" />
                        <p className="coins-count">2000 VX Points</p>
                      </div>
                    )}

                    {/* Signup Button */}
                    {SignupButton && (
                      <div className="signupcontainer">
                        <p className="singuptext">Signup</p>
                      </div>
                    )}
                  </div>
                  {/* offer for shop and earn */}
                  {offerdata && <p className="offershop"> 2% Cash Back</p>}

                  {x.availableFor == true && (
                    <div className="endingitimecontainer">
                      <h6>Ending In :</h6>
                      <p className="timer">08:04:30:2</p>
                      <Stack
                        direction="row"
                        sx={{ justifyContent: "center", padding: "3px" }}
                        spacing={3}
                        className="timer-format"
                      >
                        <p>Day</p>
                        <p>Hours</p>
                        <p>Minutes</p>
                        <p>Seconds</p>
                      </Stack>
                    </div>
                  )}
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}

export default Voeuxcard;
