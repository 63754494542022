import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../../../src/pages/nftLanding/NftLanding.module.css";
import UserProfile from "./UserProfile";
import { MarketplaceService } from "src/services/marketplaceService";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import { useLocation } from "react-router-dom";

import axios from "axios";
import { Environment } from "src/environments";
const NftSalesPage = () => {
  const navigate = useNavigate();
  const [formdata, setFormData] = useState<any>({});
  const [nftData, setNftData] = useState<any>();

  const location = useLocation();
  console.log(location.state);
  const [previousPath, setPreviousPath] = useState(location.state);
  const [nftCreateResponse, setNftCreateResponse] = useState<any>([]);
  let { nftid } = useParams();
  const [nftId, setNftId] = useState(nftid);
  const [marketplaceInstance, setMarketplaceInstance] =
    useState<MarketplaceService>();
  useEffect(() => {
    const fetchNftData = async () => {
      handleShowLoader(true);
      try {
        const response = await axios.get(
          `${Environment.metavoeuxBackend}nft/${nftid}`
        );
        console.log("nft response:", response.data);
        setNftCreateResponse(response.data);
        if (previousPath == "Create") {
          showToastMessageClick("NFT Created Successfully");
        }

        const data = {
          nftId: response.data.nftData._id,
          collectionAddress:
            response.data.nftData.collectionId.collectionAddress,
          user: response.data.nftData.currentOwner,
          tokenId: response.data.nftData.tokenId,
          // creatorFee:response.data.nftData.creatorFee
        };
        console.log("nft  details of that id:", data);
        setNftData(data);
        handleShowLoader(false);
      } catch (error) {
        handleShowLoader(false);
        showToastErrorMessageClick("Failed in getting NFT Details ");
        // throw error;
      }
    };
    fetchNftData();
  }, [nftid]);

  const handleSalesClick = (e: any) => {
    handleShowLoader(true, "Listing your NFT in the marketplace ...");
    e.preventDefault();

    console.log("sales", formdata);
    listNft();
  };

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const hanldeCancelClick = () => {
    navigate("/Userdetails");
  };
  useEffect(() => {
    const instance = new MarketplaceService();
    setMarketplaceInstance(instance);
  }, []);

  const listNft = async () => {
    // I need collection address
    // get nft id
    // take price from the form
    try {
      console.log("nftData", nftData);
      const nfts = [nftData.collectionAddress];
      const tokenIds = [Number(nftData.tokenId)];
      const prices = [Number(formdata.listingPrice) * 10 ** 9];
      const listed = await marketplaceInstance?.listNft(nfts, tokenIds, prices);
      // .then(() => getFormData(formdata));

      console.log("formdata", formdata);
      // getFormData(formdata)
      console.log("listed nft", listed);
      //update the listNft table of our backend after this

      localListing();
    } catch (err) {
      console.error("Failed to list nft", err);
      showToastErrorMessageClick("Failed in Listing NFT");
      handleShowLoader(false);
    }
  };

  const localListing = async () => {
    console.log("localListing");
    try {
      const listingData = {
        user: nftData.user._id,
        nft: nftData.nftId,
        listingPrice: formdata.listingPrice,
        // creatorFee:nftData?.creatorFee ? nftData?.creatorFee  : formdata.creatorFee
      };
      console.log("listingData", listingData);
      const response = await axios.post(
        `${Environment.metavoeuxBackend}listingnft`,
        listingData
      );
      console.log("response of list NFT", response);
      handleShowLoader(false);
      navigate("/nftoverview");
    } catch (err) {
      handleShowLoader(false);
      throw err;
    }
  };

  //state to show loader
  const [showLoader, setShowLoader] = useState(false);
  const [showText, setShowText] = useState("");
  const handleShowLoader = (active: boolean, text?: any) => {
    setShowLoader(active);
    setShowText(text);
  };
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  return (
    <>
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />

      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />

      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100vh",
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent text={showText} />
        </Box>
      )}
      <Box
        className={styles.mainContainer}
        p={4}
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          paddingTop: 0,
          "@media (max-width: 600px)": {
            // padding: 2,
          },
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader nftHeader={true} />
        <Grid
          container
          spacing={{ xs: 10, lg: 20 }}
          sx={{
            "@media (max-width: 600px)": {
              padding: 2,
            },
          }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "65%" },
                  left: "-22%",
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  // background: "red",
                  filter: "blur(140px)",
                  backdropFilter: "blur(140px)",
                  WebkitBackdropFilter: "blur(140px)",
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "50%" },
                    left: "-20%",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                  },
                }}
              ></Box>
              <UserProfile nftCreateResponse={nftCreateResponse} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "23%" },
                  right: "0%",
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  // background: "red",
                  filter: "blur(140px)",
                  backdropFilter: "blur(140px)",
                  WebkitBackdropFilter: "blur(140px)",
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "50%" },
                    left: "-20%",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                  },
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: { xs: "16px", md: "32px" },
                  fontWeight: 600,
                  mb: "20px",
                  fontFamily: "outfit",
                }}
              >
                List for Sale
              </Typography>
              {/*<Typography
          sx={{
            color: "#F6EBFD",
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: 300,
            mb: "30px",
          }}
        >
          Choose a Type for Sale
        </Typography>
        <Box
          sx={{
            display: "flex",
            p: "16px",
            borderRadius: "16px",
            background: "rgba(217, 217, 217, 0.11)",
            alignItems: "center",
            columnGap: "13px",
            border: "1px solid #FFF",
            mb: "30px",
            cursor: "pointer",
            rowGap: "13px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              borderRadius: "16px",
              background: "rgba(217, 217, 217, 0.11)",
              flexGrow: 1,
              flexBasics: 0,
              textAlign: "center",
              py: 1,
              width: { xs: "100%", sm: "100%" },
            }}
          >
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "34px", md: "52px" },
                fontWeight: 500,
              }}
            >
              $
            </Typography>
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "16px", md: "16px" },
                fontWeight: 300,
              }}
            >
              Fixed Price
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: "16px",
              background: "rgba(217, 217, 217, 0.11)",
              flexGrow: 1,
              flexBasics: 0,
              textAlign: "center",
              py: 1,
              width: { xs: "100%", sm: "100%" },
            }}
          >
            <img src={TimeAuction} />

            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "16px", md: "16px" },
                fontWeight: 300,
              }}
            >
              Time Auction
            </Typography>
          </Box>
            </Box> */}
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "30px",
                }}
                onSubmit={(e) => handleSalesClick(e)}
              >
                {/* <Box>
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "20px", md: "20px" },
                fontWeight: 500,
              }}
            >
              Choose a method
            </Typography>
            <TextField
              type="text"
              name="methodName"
              placeholder="Sell to highest bidder"
              required
              inputProps={{ style: { color: "#777" } }}
              sx={{
                border: "1px solid #777",
                borderRadius: "12px",
                color: "#828282",
                width: "100%",
              }}
              onChange={(e) =>
                setFormData({ ...formdata, [e.target.name]: e.target.value })
              }
            />
          </Box> */}
                <Box>
                  <Typography
                    sx={{
                      color: "#F6EBFD",
                      fontSize: { xs: "20px", md: "20px" },
                      fontWeight: 500,
                    }}
                  >
                    Starting price
                  </Typography>
                  <Box
                    sx={{
                      py: 1,
                      px: 1,
                      border: "1px solid #7C7C7C",
                      height: { xs: "60px", xl: "70px" },
                      position: "relative",
                      borderRadius: "8px",
                      overflow: "hidden", // Prevents text from exceeding the box
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      type="text"
                      name="listingPrice"
                      placeholder="Amount"
                      required
                      inputProps={{ style: { color: "#777" } }}
                      sx={{
                        border: "none",
                        borderRadius: "12px",
                        color: "#828282",
                        width: "100%",
                        background: "transparent",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: "none",
                          },
                      }}
                      onChange={(e) =>
                        setFormData({
                          ...formdata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <Typography
                      sx={{
                        color: "#F6EBFD",
                        fontSize: { xs: "18px", md: "18px" },
                        fontWeight: 500,
                      }}
                    >
                      POLYGON
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#F6EBFD",
                      fontSize: { xs: "20px", md: "20px" },
                      fontWeight: 600,
                      fontFamily: "outfit",
                    }}
                  >
                    Summary
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#F6EBFD",
                        fontSize: { xs: "18px", md: "18px" },
                        fontWeight: 300,
                        fontFamily: "outfit",
                      }}
                    >
                      Listing Price
                    </Typography>
                    <Typography
                      sx={{
                        color: "#F6EBFD",
                        fontSize: { xs: "18px", md: "18px" },
                        fontWeight: 300,
                        fontFamily: "outfit",
                      }}
                    >
                      {formdata.listingPrice} Voeux
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#F6EBFD",
                        fontSize: { xs: "18px", md: "18px" },
                        fontWeight: 300,
                        fontFamily: "outfit",
                      }}
                    >
                      Service Fee
                    </Typography>
                    <Typography
                      sx={{
                        color: "#F6EBFD",
                        fontSize: { xs: "18px", md: "18px" },
                        fontWeight: 300,
                        fontFamily: "outfit",
                      }}
                    >
                      2.5%
                    </Typography>
                  </Box>

                  <Box
                    sx={{ display: "none", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "#F6EBFD",
                        fontSize: { xs: "18px", md: "18px" },
                        fontWeight: 300,
                        fontFamily: "outfit",
                      }}
                    >
                      Creator Fee
                    </Typography>
                    <Typography
                      sx={{
                        color: "#F6EBFD",
                        fontSize: { xs: "18px", md: "18px" },
                        fontWeight: 300,
                        fontFamily: "outfit",
                      }}
                    >
                      {nftData?.creatorFee
                        ? nftData?.creatorFee
                        : formdata.creatorFee}{" "}
                      %
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Button
                      sx={{
                        background: " #FF990D",
                        padding: "16px 32px",
                        color: "white",
                        borderRadius: "12px",
                        fontFamily: "outfit",
                        "&:hover": {
                          background: " #FF990D",
                          color: "white",
                        },
                      }}
                      type="submit"
                    >
                      Complete Listing
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      sx={{
                        background: " transparent",
                        border: "1px solid #FF990D ",
                        padding: "16px 32px",
                        color: "#FF990D",
                        borderRadius: "12px",
                        fontFamily: "outfit",
                        "&:hover": {
                          background: " #FF990D",
                          color: "white",
                        },
                      }}
                      onClick={() => hanldeCancelClick()}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <LandingPageFooter />
    </>
  );
};
export default NftSalesPage;
