import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Stack, height } from "@mui/system";
import React from "react";
import careericon from "../../assets/svgs/careerIconsvg.svg";
// import careericon from "../../assets/svgs/metavoeux_logo.svg";
import image1 from "../../assets/images/faqimg1.png";
import image2 from "../../assets/images/faqimg2.png";
import BasicAccordion from "../../components/Accordion/BasicAccordion";
// import LandingPageSubscribe from "../../components/landingPageSubscribe/LandingPageSubscribe";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";

interface FaqItem {
  id: number;
  icon: string;
  question: string;
  answer: string;
}

interface FaqAccoordion {
  id: number;
  question: string;
  answer: string;
}

const faqAccordionData: FaqAccoordion[] = [
  {
    id: 0,
    question: "What is the role of Polygon Blockchain in MetaVoeux?",
    answer:
      "Polygon is used as a Layer 2 scaling solution for Ethereum, addressing issues related to low fees, fast transactions, and scalability. It helps reduce costs, speed transactions, and make the blockchain more practical for everyday tasks within MetaVoeux.",
  },
  {
    id: 1,
    question: "What makes MetaVoeux unique?",
    answer:
      "MetaVoeux stands out by offering an all-in-one platform, leveraging blockchain technology, integrating DeFi features, exploring the Metaverse, and maintaining a user-centric approach. The platform's innovation and vision are dedicated to pushing the boundaries of blockchain, DeFi, and the Metaverse.",
  },
  {
    id: 2,
    question: "How can I participate in MetaVoeux activities?",
    answer:
      "To participate in MetaVoeux activities, you can explore the platform, play games, attend events, stake tokens, and engage in various social and educational experiences. Earning potential, entertainment value, social interaction, and discounts on goods and services are among the benefits of active participation.",
  },
  {
    id: 3,
    question: "What is the development roadmap for MetaVoeux?",
    answer:
      "The development roadmap is divided into phases, with the first phase (2022 Q1 to Q2 2024) focusing on the website ecosystem, NFT marketplace, Voeux Token, Voeux Money, Voeux Pay, Voeux Farming, and blockchain games. Subsequent phases include blockchain real estate, Metavoeux Metaverse expansion, Voeux Blockchain, and more.",
  },
];

const faqData: FaqItem[] = [
  {
    id: 1,
    icon: careericon,
    question: "What is MetaVoeux?",
    answer:
      "MetaVoeux is an all-in-one platform that seamlessly combines Web 3.0 and blockchain technology. It offers various products, including NFT marketplace, Voeux farming, blockchain games (play to earn), Voeux Pay payment gateway, Voeux Money (act to earn), blockchain real estate, metaverse, and more. MetaVoeux aims to enhance the user experience in the digital world.",
  },
  {
    id: 2,
    icon: careericon,
    question: " What is the objective of MetaVoeux?",
    answer:
      "The main objective of MetaVoeux is to create an ecosystem where users can access various technologies in one place, promoting convenience and efficiency. Users are not just passive consumers but active participants in the MetaVoeux economy, earning tokens through their actions.",
  },
  {
    id: 3,
    icon: careericon,
    question: "What is the Voeux Token?",
    answer:
      "Voeux Token (VOEUX) is an ERC-20 utility token designed for the entire MetaVoeux ecosystem. It facilitates transactions and arrangements between users and MetaVoeux products and services.",
  },
  {
    id: 4,
    icon: careericon,
    question: "How can I earn tokens in MetaVoeux?",
    answer:
      "You can earn tokens through various activities, including playing games, participating in Voeux token airdrops, staking tokens, joining events, using Voeux Money, engaging in social experiences, educational experiences, creating content, and more.",
  },
];

interface FaqDataProps {
  faq: FaqItem;
}

function FaqData({ faq }: FaqDataProps) {
  return (
    <Card
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.0)",
      }}
    >
      <CardContent sx={{ px: "0 !important" }}>
        <Grid container alignItems="center" style={{ width: "100%" }}>
          {/* <Grid item>
            <Box
              sx={{
                backgroundColor: "#7C3F00",
                borderRadius: "50%",
                width: "48px",
                height: "48px",
                marginRight: "8px",
                position: "relative",
              }}
            >
              <img
                src={faq.icon}
                alt="FAQ Icon"
                width={30}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                
              />
               <Typography
              variant="body1"
              sx={{
                color: "white",
                fontSize: "20px",
                fontWeight: 600,
                fontFamily: "outfit",
              }}
            >
              {faq.question} 
            </Typography>
            </Box>
          </Grid> */}
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                backgroundColor: "#7C3F00",
                borderRadius: "50%",
                padding: "24px",
                marginRight: "8px",
                position: "relative",
              }}
            >
              <img
                src={faq.icon}
                alt="FAQ Icon"
                width={30}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "white",
                fontSize: { xs: "17px", md: "23px" },
                fontWeight: 600,
                fontFamily: "outfit",
              }}
            >
              {faq.question}
            </Typography>
          </Box>

          {/* <Grid item>
            <Typography
              variant="body1"
              sx={{
                color: "white",
                fontSize: "20px",
                fontWeight: 600,
                fontFamily: "outfit",
              }}
            >
              {faq.question} 
            </Typography>
          </Grid> */}
        </Grid>

        <Typography
          variant="body2"
          color="text.secondary"
          mt={2}
          sx={{
            color: "white",
            fontSize: { xs: "15px", md: "18px" },
            fontWeight: 400,
            fontFamily: "outfit",
            marginLeft: { xs: "0", sm: "0", md: "7.5%" },
            marginTop: "0! important",
          }}
        >
          {faq.answer}
        </Typography>
      </CardContent>
    </Card>
  );
}

function Faq() {
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box sx={{ maxWidth: "100%", overflowX: "hidden" }}>
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: "23px", md: "32px" },
          fontWeight: 600,
          fontFamily: "outfit",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        Frequently Asked Questions
      </Typography>
      {/* <Box
        sx={{
          ...circleStyle,
          display: { xs: "none", sm: "none", md: "flex" },
          top: { xl: "60%" },
          left: "0%",
          transform: "translateY(-50%)",
          width: "550px", // Adjusted width
          height: "200px", // Adjusted height
          borderRadius: "0 50% 50% 0", // Ensures it remains a perfect circle
          background: "#9D00FF",
          filter: "blur(160px)",
          overflow: "hidden",
          "@media (min-width: 1721px)": {
            width: "550px", // Adjusted width
            height: "200px", // Adjusted height
            top: { xl: "60%" },
            left: "0%",
            filter: "blur(210px)",
          },
        }}
      ></Box> */}

      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: "auto",

          justifyContent: "center",
          alignItems: "center",
          // padding: "0 16px", // Add padding to both sides
        }}
      >
        {isMobile && (
          <Typography
            sx={{
              typography: { xs: "body2", sm: "body2" },
              textAlign: { xs: "center", md: "inherit" },
            }}
            justifyContent={"center"}
          >
            <span
              style={{
                color: "#F2F2F2",

                textAlign: "center",

                fontWeight: 400,
                fontFamily: "outfit",
                fontSize: "15px",
              }}
            >
              Everything you need to know about Metavoeux.
            </span>
          </Typography>
        )}

        {!isMobile && (
          <>
            <Typography
              sx={{
                typography: { xs: "body2", sm: "body2" },
                textAlign: { xs: "center", md: "inherit" },
              }}
              justifyContent={"center"}
            >
              <span
                style={{
                  color: "#F2F2F2",

                  textAlign: "center",

                  fontWeight: 400,
                  fontFamily: "outfit",
                  fontSize: "20px",
                }}
              >
                Everything you need to know about Metavoeux.
              </span>
            </Typography>
          </>
        )}
      </Stack>

      <Stack
        sx={{
          display: "flex",
          flexDirection: "column",
          mx: "auto",
          // my: 6,
          justifyContent: "space-between",
          padding: "0 16px", // Add padding to both sides
        }}
      >
        <Grid container spacing={4}>
          {/* Left side for cards */}
          <Grid item xs={12} md={12} lg={6}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "100%" },
                margin: { xs: "0", md: "0 25px" },
                background: "#9D00F",
              }}
            >
              {faqData.map((faq) => (
                <FaqData key={faq.id} faq={faq} />
              ))}
            </Stack>
          </Grid>
          {/* Right side for images */}
          <Grid item xs={12} md={6}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "100%" },

                margin: { xs: "0", md: "4rem 60px" },
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  borderRadius: "22px",
                  display: "flex",
                  justifyContent: "center",
                  // marginBottom: 2,
                  width: { xs: "100%", sm: "100%", md: "100%", lg: "600px" },
                  height: { xs: "100%", sm: "100%", md: "100%", lg: "600px" },
                }}
              >
                <video
                  width="inherit"
                  style={{ width: "inherit" }}
                  height="inherit"
                  autoPlay
                  loop
                  muted
                >
                  <source
                    src={
                      "https://d24m5mu8cxqwfm.cloudfront.net/images/faqvideo.mp4"
                    }
                    type="video/mp4"
                  ></source>
                </video>
              </Box>

              {/* <Box sx={{ borderRadius: "22px" }}>
                <img src={image2} alt="" width={"85%"} />
              </Box> */}
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "20px", md: "32px" },
            fontWeight: 600,
            fontFamily: "outfit",
            display: "flex",

            justifyContent: { xs: "left", md: "center" },

            marginLeft: { xs: "14px" },
          }}
        >
          Frequently Asked Questions
        </Typography>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: "auto",
          }}
        >
          <Typography
            sx={{
              typography: { xs: "body2", sm: "body2" },
              textAlign: { xs: "center", md: "inherit" },
            }}
            justifyContent={"center"}
          ></Typography>
        </Stack>
        <Stack sx={{ px: { lg: "15%" }, my: 5 }}>
          <Box
            sx={{
              ...circleStyle,
              display: { xs: "none", sm: "none", md: "flex" },
              down: { xl: "20%" },
              left: "1%",
              transform: "translateY(37%)",
              width: "250px", // Adjusted width
              height: "200px", // Adjusted height
              borderRadius: "50%", // Ensures it remains a perfect circle
              background: "#9D00FF",
              filter: "blur(132px)",
              backdropFilter: "blur(132px)",
              WebkitBackdropFilter: "blur(132px)",
              overflow: "hidden",
            }}
          ></Box>
          <BasicAccordion FaqAccordionData={faqAccordionData} />
        </Stack>
        <Box
          sx={{
            ...circleStyle,
            display: { xs: "none", sm: "none", md: "flex" },
            top: { xl: "1%" },
            left: "0%",
            transform: "translateY(37%)",
            width: "250px", // Adjusted width
            height: "200px", // Adjusted height
            borderRadius: "50%", // Ensures it remains a perfect circle
            background: "#9D00FF",
            filter: "blur(132px)",
            backdropFilter: "blur(132px)",
            WebkitBackdropFilter: "blur(132px)",
            overflow: "hidden",
          }}
        ></Box>
        <Box
          sx={{
            ...circleStyle,
            display: { xs: "none", sm: "none", md: "flex" },
            top: { xl: "40%" },
            left: "80%",
            transform: "translateY(37%)",
            width: "250px", // Adjusted width
            height: "200px", // Adjusted height
            borderRadius: "50%", // Ensures it remains a perfect circle
            background: "#9D00FF",
            filter: "blur(132px)",
            backdropFilter: "blur(132px)",
            WebkitBackdropFilter: "blur(132px)",
            overflow: "hidden",
          }}
        ></Box>
      </Box>
      {/* <LandingPageSubscribe /> */}
    </Box>
  );
}

export default Faq;
