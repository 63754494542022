// ProtectedRoutes.js
import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoutes = () => {
  // const [user, setUser] = useState<any>(null);
  let isSignedIn = localStorage.getItem("metaMaskAddress")
 
  return isSignedIn !== null ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
