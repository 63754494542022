import {
  Typography,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Slider from "react-slick";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import VoeuxPayHeader from "./voexheader/VoeuxPayHeader";
import easylogo from "../../assets/voeuxpay/easylogo.png";
import globallogo from "../../assets/voeuxpay/globallogo.png";
import securelogo from "../../assets/voeuxpay/securelogo.png";
import voeuxpaylogo from "../../assets/voeuxpay/voeuxpaylogo.png";
import paymentlinkimg from "../../assets/voeuxpay/Credit Card Link.png";
import multicurrencyimg from "../../assets/voeuxpay/multicurrency.png";
import ewalletimg from "../../assets/voeuxpay/E Wallet.png";
import bankimg from "../../assets/voeuxpay/DigitalBank.png";
import onrampimg from "../../assets/voeuxpay/onramp.png";
import paymentgatwayimg from "../../assets/voeuxpay/Payment Gateway.png";
import voeuxcardimg from "../../assets/voeuxpay/voeuxcard.png";
import qrbankimg from "../../assets/voeuxpay/qrbank.png";
import paymentsolimg from "../../assets/voeuxpay/Mobile Wallet.png";
import offramp from "../../assets/voeuxpay/offramp.png";
import bussinessimg from "../../assets/voeuxpay/businessacc.png";
import integrateimg from "../../assets/voeuxpay/integrate.png";
import payoptionimg from "../../assets/voeuxpay/payoption.png";
import getprofitimg from "../../assets/voeuxpay/getprofit.png";
import line from "../../assets/voeuxpay/Line 332.svg";
import Choosecards from "./Choosecards";
import ChoosecardsMobile from "./ChoosecardsMobile";
import VoeuxpayAccordion from "./VoeuxpauAccordion";
import landingvideo from "../../assets/voeuxpay/landing.gif";
import VoeuxPayFooter from "./VoeuxPayFooter";
import topbanneranimation from "../../assets/voeuxpay/voeux-card.gif";
import StepsMobile from "./StepsMobile";
import FlagAnimationMob from "./FlagAnimationMob";
import contactimg from "../../assets/voeuxpay/contactPay.png";
import { useLocation } from "react-router-dom";
import FlagAnimation from "./FlagAnimation";

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "rgba(255, 255, 255, 0.30)",
        zIndex: 1,
        borderRadius: "6px",
        textAlign: "center",
        verticalAlign: "middle",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "rgba(255, 255, 255, 0.30)",
        zIndex: 1,
        borderRadius: "6px",
        textAlign: "center",
        verticalAlign: "middle",
      }}
      onClick={onClick}
    />
  );
}

function VoeuxPayLanding() {
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const getInTouchRef = useRef<any>(null);
  const servicesRef = useRef<any>(null);
  const howToWork = useRef<any>(null);
  const faq = useRef<any>(null);
  const voeuxPay = useRef<any>(null);

  const handleContactUS = () => {
    getInTouchRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleServices = () => {
    servicesRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const cardData = [
    {
      id: 1,
      image: securelogo,
      title: "SECURE PAYMENTS ",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud.",
    },
    {
      id: 2,
      image: globallogo,
      title: "GLOBAL PAYMENTS",
      caption:
        "We Reach More - Global Payments, Where Borders Don't Limit Transactions.",
    },
    {
      id: 3,
      image: easylogo,
      title: "EASY PAYMENTS",
      caption:
        "Voeux Pay is made to be simple. Whether you're a tech expert or not, you can use it without a problem.",
    },
    {
      id: 4,
      image: voeuxpaylogo,
      title: "VOEUX PAY",
      caption:
        "Whether you're a small business or a big company, Voeux Pay can fit into different verticals. It gives everyone an easy payment experience.",
    },
  ];

  const ourService = [
    {
      id: 1,
      image: paymentlinkimg,
      title: "Payment Link",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 2,
      image: multicurrencyimg,
      title: "Multi Currency Processing",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 3,
      image: ewalletimg,
      title: "E- Wallet",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 4,
      image: bankimg,
      title: "Online Banking",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 5,
      image: onrampimg,
      title: "On Ramp",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 6,
      image: paymentgatwayimg,
      title: "Payment Gateway Integration",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 7,
      image: voeuxcardimg,
      title: "Voeux Card",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 8,
      image: qrbankimg,
      title: "QR bank transfer",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 9,
      image: paymentsolimg,
      title: "Customized Payment Solution",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
    {
      id: 10,
      image: offramp,
      title: "Off Ramp",
      caption:
        "Security is a big deal regarding online transactions. Voeux Pay ensures your data is safe with encryption and tools to prevent fraud. It's all about making users feel confident.",
    },
  ];

  interface PayAccordion {
    id: number;
    question: string;
    answer: string;
  }

  const PayAccordionData: PayAccordion[] = [
    {
      id: 0,
      question: "What is Voeux Pay?",
      answer:
        "Voeux Pay is a payment gateway designed for your online payment transactions. A processor of multi-currency fiat and crypto for payments & settlement, online banking, and other alternative payment methods, A boundless solution across the globe that ensures security for your business and customer.",
    },
    {
      id: 1,
      question: "What is included in VoeuxPay online banking product?",
      answer:
        "It combines settlement and merchant account services into one powerful package, providing a comprehensive solution for managing finances for payments and other transactions.",
    },
    {
      id: 2,
      question: "What services does Voeux Pay offer?",
      answer:
        "VoeuxPay manages and processes merchants’ online transactions with consumers allowing merchants to be more hands-off in their payment process.   Payment Gateway & Custom Solutions, Multi-currency, Other Payment Methods & Fraud Detection Prevention.",
    },
    {
      id: 3,
      question: "How does Voeux Pay support transaction?",
      answer:
        "We support them in the encryption process. to protect sensitive data like credit card numbers and personal information. This helps prevent fraud and ensures the security of the transaction. Authorization process, order fulfillment, & reporting transactions to keep track of sales and finances.",
    },
    {
      id: 4,
      question: "Can individuals use Voeux Pay?",
      answer:
        "Yes VoeuxPay is designed to serve businesses in different vertical industries within its geography cover and currently working on the development of wallet apps that are tailored for end users or consumers.",
    },
    {
      id: 5,
      question: "How can re-sellers benefit from Voeux Pay?",
      answer:
        "Re-sellers can leverage Voeux Pay to streamline transactions, offering customers the flexibility to pay in multi-currency and other local payment methods. This expands payment options and enhances the overall shopping experience. ",
    },
  ];

  const [showDescription, setShowDescription] = useState(false);
  const [cardNumber, setCardNumber] = useState(null);
  const handleShowDescription = (index: any) => {
    setShowDescription(true);
    setCardNumber(index);
  };

  const handleHideDescription = (index: any) => {
    setShowDescription(false);
    setCardNumber(null);
  };

  const isMobile = useMediaQuery("(max-width:600px)");
  const data = useLocation();
  console.log("data 12", data);

  useEffect(() => {
    if (data.state === "work") {
      howToWork.current?.scrollIntoView({ behavior: "smooth" });
    } else if (data.state === "contact") {
      handleContactUS();
    } else if (data.state === "faq") {
      faq.current?.scrollIntoView({ behavior: "smooth" });
    } else if (data.state === "services") {
      servicesRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      voeuxPay.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const [selectedOption, setSelectedOption] = useState("option1");
  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const settings = {
    //

    // className: "center",
    // centerMode: true,
    // infinite: true,
    // centerPadding: "60px",
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // autoplay: true,
  };

  const settings1 = {
    //

    // className: "center",
    // centerMode: true,
    // infinite: true,
    // centerPadding: "60px",
    // slidesToShow: 1,
    // speed: 500,

    // infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    slidesToScroll: 0.5,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <>
      <Box
        ref={voeuxPay}
        p={7}
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 2.7,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          "@media (max-width: 600px)": {
            padding: "0% 5% 0% 5%",
          },
        }}
      >
        <VoeuxPayHeader
          handleContactUS={handleContactUS}
          handleServices={handleServices}
        />
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "flex" },
            ...circleStyle,
            top: { xl: "40%" },
            left: "0%",
            transform: "translateY(-70%)",
            width: { xs: "40%", sm: "300px" }, // Adjusted width
            // width: "300px", // Adjusted width
            // height: "300px", // Adjusted height
            height: { xs: "40%", sm: "300px" },
            borderRadius: "547.625px",
            background:
              "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
            filter: "blur(160px)",
            backdropFilter: "blur(160px)",
            WebkitBackdropFilter: "blur(160px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "40%" },

              left: "0%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "flex" },
            ...circleStyle,
            top: { xl: "40%" },
            right: "0%",
            transform: "translateY(-50%)",
            width: { xs: "40%", sm: "300px" }, // Adjusted width
            // width: "300px", // Adjusted width
            height: { xs: "40%", sm: "300px" },
            // height: "300px", // Adjusted height
            borderRadius: "672.058px",
            background: "var(--56-ccf-2, #56CCF2)",
            filter: "blur(162px)",
            backdropFilter: "blur(162px)",
            WebkitBackdropFilter: "blur(162px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "40%" },
              display: "none",
              right: "0%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection={{
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "inherit",
              justifyContent: "space-between",
            }}
          >
            {!isMobile && (
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  height: "100%",
                  width: { xs: "100%", md: "50%" },
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      flex: 0.1,
                      marginLeft: "1px",
                      borderBottom: "1px solid #FFBB33",
                    }}
                  ></div>
                  <Typography
                    sx={{
                      color: "#FFBB33",
                      fontFamily: "Bebas Neue",
                      fontSize: "20px",
                      position: "relative",
                      marginLeft: "10px",
                      letterSpacing: " 0.60px;",
                    }}
                  >
                    Seamless Experience
                  </Typography>
                </div>

                <Box>
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Bebas Neue ",
                      fontSize: { xs: "36px", md: "66px" },
                      fontWeight: 400,

                      wordWrap: "break-word",
                    }}
                  >
                    EMPOWERING YOU TO
                  </Typography>

                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Bebas Neue ",
                      fontSize: { xs: "36px", md: "66px" },
                      fontWeight: 400,

                      textTransform: "none",
                      wordWrap: "break-word",
                    }}
                  >
                    PAY
                    <span
                      style={{ color: "#FFBB33", fontFamily: "Bebas Neue " }}
                    >
                      {" "}
                      CRYPTO
                    </span>
                    <span style={{ color: "#fff", fontFamily: "Bebas Neue " }}>
                      {" "}
                      AND
                    </span>
                    <span
                      style={{ color: "#FFBB33", fontFamily: "Bebas Neue " }}
                    >
                      {" "}
                      FIAT
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Bebas Neue ",
                      fontSize: { xs: "36px", md: "66px" },
                      fontWeight: 400,

                      wordWrap: "break-word",
                    }}
                  >
                    ANYWHERE, ANYTIME.
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    fontSize: { xs: "14px", md: "18px" },
                    fontFamily: "outfit",
                    letterSpacing: "0.397px",
                    lineHeight: "27px",
                    marginTop: "15px",
                  }}
                >
                  Voeux Pay is a payment gateway that helps your business grow.
                  We help businesses accept multiple payment methods and send
                  funds to multiple destinations.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 3,
                      fontFamily: "Bebas Neue ",
                      backgroundImage:
                        "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                      fontWeight: "400px",
                      fontSize: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 22,
                      },
                    }}
                    onClick={() => handleContactUS()}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Box>
            )}

            {isMobile && (
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  height: "100%",
                  width: { xs: "100%" },
                  marginTop: "50px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      flex: 0.1,
                      borderBottom: "1px solid #FFBB33",
                    }}
                  ></div>
                  <Typography
                    sx={{
                      color: "#FFBB33",
                      fontFamily: "Bebas Neue",
                      fontSize: "16px",
                      position: "relative",
                      marginLeft: "10px",
                      letterSpacing: " 0.60px;",
                    }}
                  >
                    Seamless Experience
                  </Typography>
                </div>
                <Box>
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Bebas Neue ",
                      fontSize: { xs: "32px" },
                      fontWeight: 400,

                      wordWrap: "break-word",
                    }}
                  >
                    EMPOWERING YOU TO PAY
                  </Typography>

                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Bebas Neue ",
                      fontSize: { xs: "32px" },
                      fontWeight: 400,

                      textTransform: "none",
                      wordWrap: "break-word",
                    }}
                  >
                    <span
                      style={{ color: "#FFBB33", fontFamily: "Bebas Neue " }}
                    >
                      {" "}
                      CRYPTO
                    </span>
                    <span style={{ color: "#fff", fontFamily: "Bebas Neue " }}>
                      {" "}
                      AND
                    </span>
                    <span
                      style={{ color: "#FFBB33", fontFamily: "Bebas Neue " }}
                    >
                      {" "}
                      FIAT
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontFamily: "Bebas Neue ",
                      fontSize: { xs: "32px" },
                      fontWeight: 400,

                      wordWrap: "break-word",
                    }}
                  >
                    ANYWHERE, ANYTIME.
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: "16px" },
                    fontFamily: "outfit",
                    letterSpacing: "0.21px",
                    lineHeight: "24px",
                    marginTop: "15px",
                  }}
                >
                  Voeux Pay is a payment gateway that helps your business grow.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "7px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 3,
                      // marginTop:1,
                      fontFamily: "Bebas Neue ",
                      backgroundImage:
                        "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                      fontWeight: "400px",
                      fontSize: {
                        xs: 12,
                      },
                    }}
                    onClick={() => handleContactUS()}
                  >
                    Contact Us
                  </Button>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                height: {
                  xs: 400,
                  sm: 400,
                  md: 400,
                  lg: 600,
                },
                width: {
                  xs: "100%",
                  sm: 300,
                  md: 400,
                  lg: 600,
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: { xs: 2, md: 10 },
              }}
            >
              <img
                src={topbanneranimation}
                draggable="false"
                alt="animation"
                height={"80%"}
                width={"80%"}
              />
            </Box>
          </Box>
        </Box>
        {/* card session */}
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "flex" },
            ...circleStyle,
            top: { xl: "150%" },
            left: "0%",
            transform: "translateY(-70%)",
            width: { xs: "40%", sm: "300px" }, // Adjusted width
            // width: "300px", // Adjusted width
            height: { xs: "40%", sm: "300px" },
            // height: "300px", // Adjusted height
            borderRadius: "547.625px",
            background:
              "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
            filter: "blur(160px)",
            backdropFilter: "blur(160px)",
            WebkitBackdropFilter: "blur(160px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "150%" },

              left: "0%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "flex" },
            ...circleStyle,
            top: { xl: "150%" },
            right: "0%",
            transform: "translateY(-50%)",
            width: { xs: "40%", sm: "300px" }, // Adjusted width
            // width: "300px", // Adjusted width
            height: { xs: "40%", sm: "300px" },
            // height: "300px", // Adjusted height
            borderRadius: "672.058px",
            background: "var(--56-ccf-2, #56CCF2)",
            filter: "blur(162px)",
            backdropFilter: "blur(162px)",
            WebkitBackdropFilter: "blur(162px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "0%" },

              right: "0%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>

        {!isMobile && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            flexWrap="wrap"
            mt={10}
            // marginBottom={"6rem"}
          >
            {cardData.map((card, index) => (
              <Box
                key={card.id}
                width={283}
                height={250}
                m={2}
                p={2}
                borderRadius={4}
                boxShadow={3}
                bgcolor="#181818"
                textAlign="center"
                position="relative"
                mb={index !== cardData.length - 1 ? 2 : 0}
              >
                <img
                  src={card.image}
                  alt={card.title}
                  draggable="false"
                  height={100}
                  style={{
                    position: "absolute",
                    top: -50,
                    left: "50%",
                    transform: "translateX(-50%)",
                    marginBottom: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Bebas Neue",
                    marginBottom: "10px",
                    marginTop: "60px",
                    fontSize: "20px",
                  }}
                  variant="h5"
                  mt={2}
                >
                  {card.title}
                </Typography>
                <Typography
                  sx={{ fontFamily: "outfit" }}
                  variant="body1"
                  color="white"
                  fontSize={16}
                >
                  {card.caption}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {/* first slider session for mobile */}

        {isMobile && (
          <Box>
            <div className="slider-container voeuxpay">
              <Slider {...settings}>
                {cardData.map((card, index) => (
                  <Box
                    key={card.id}
                    width={283}
                    height={270}
                    m={2}
                    p={2}
                    borderRadius={4}
                    boxShadow={3}
                    bgcolor="#181818"
                    textAlign="center"
                    position="relative"
                    mb={index !== cardData.length - 1 ? 2 : 0}
                    // sx={{ marginLeft: "-10px" }}
                  >
                    <img
                      src={card.image}
                      alt={card.title}
                      draggable="false"
                      height={100}
                      style={{
                        position: "absolute",
                        top: -50,
                        left: "50%",
                        transform: "translateX(-50%)",
                        marginBottom: "20px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        marginBottom: "10px",
                        marginTop: "60px",
                        fontSize: "20px",
                      }}
                      variant="h5"
                      mt={2}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      sx={{ fontFamily: "outfit" }}
                      variant="body1"
                      color="white"
                      fontSize={16}
                    >
                      {card.caption}
                    </Typography>
                  </Box>
                ))}
              </Slider>
            </div>
          </Box>
        )}

        {/* sliderended */}

        <Box
          sx={{
            alignContent: "center",
            textAlign: "center",
            // marginBottom: "15rem",
          }}
          ref={servicesRef}
        >
          <Typography
            sx={{
              fontFamily: "Bebas Neue",
              fontSize: { xs: "23px", md: "36px" },
              fontWeight: "400px",
              marginTop: { xs: "2rem" },
            }}
          >
            OUR SERVICES
          </Typography>
          {!isMobile && (
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                marginBottom: "4rem",
              }}
            >
              Seamless Transactions, Secure Solutions, Elevate Your Business
              <br /> with Our Trusted Payment Gateway Services.
            </Typography>
          )}
          {isMobile && (
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                marginBottom: "2rem",
              }}
            >
              Seamless Transactions, Secure Solutions, Elevate Your Business
              with Our Trusted Payment Gateway
            </Typography>
          )}

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              ...circleStyle,
              top: { xl: "250%" },
              left: "0%",
              transform: "translateY(-70%)",
              width: { xs: "40%", sm: "370px" }, // Adjusted width
              // width: "370px", // Adjusted width
              height: { xs: "40%", sm: "300px" },
              // height: "300px", // Adjusted height
              borderRadius: "547.625px",
              background:
                "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
              overflow: "hidden",
              "@media (min-width: 1721px)": {
                width: "450px", // Adjusted width
                height: "700px", // Adjusted height
                top: { xl: "250%" },

                left: "0%",
                filter: "blur(160px)",
                backdropFilter: "blur(160px)",
                WebkitBackdropFilter: "blur(160px)",
              },
            }}
          ></Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex" },
              ...circleStyle,
              top: { xl: "250%" },
              right: "0%",
              transform: "translateY(-50%)",
              width: { xs: "40%", sm: "370px" }, // Adjusted width
              // width: "370px", // Adjusted width
              height: { xs: "40%", sm: "300px" },
              // height: "300px", // Adjusted height
              borderRadius: "672.058px",
              background: "var(--56-ccf-2, #56CCF2)",
              filter: "blur(162px)",
              backdropFilter: "blur(162px)",
              WebkitBackdropFilter: "blur(162px)",
              overflow: "hidden",
              "@media (min-width: 1721px)": {
                width: "450px", // Adjusted width
                height: "700px", // Adjusted height
                top: { xl: "250%" },

                right: "0%",
                filter: "blur(160px)",
                backdropFilter: "blur(160px)",
                WebkitBackdropFilter: "blur(160px)",
              },
            }}
          ></Box>
          {/* not for mobile */}
          {!isMobile && (
            <Box
              sx={{
                // display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "30px",
                flexWrap: "wrap",
                display: "inline-block",
              }}
            >
              {/* firstbox */}
              <Box
                sx={{
                  float: "left",
                  flexDirection: "column",
                  marginRight: ["0px", "20px"],
                  marginBottom: ["20px", "0px"],
                }}
              >
                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    // height: "177.64px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  onMouseEnter={() => handleShowDescription(0)}
                  onMouseLeave={() => handleHideDescription(0)}
                >
                  <Box>
                    <Box>
                      <img
                        src={paymentlinkimg}
                        alt="profile"
                        draggable="false"
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      Payment Link
                    </Typography>
                    {showDescription && cardNumber == 0 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        With VoeuxPay we integrate suitable solutions that
                        support seamless payment transactions using Link method
                        that directly push your customers to payment gateway,
                        with clickable or scannable codes
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(1)}
                  onMouseLeave={() => handleHideDescription(1)}
                >
                  <Box>
                    <Box>
                      <img
                        src={paymentgatwayimg}
                        alt="profile"
                        draggable="false"
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      Payment Gateway Integration
                    </Typography>

                    {showDescription && cardNumber == 1 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        VoeuxPay enable the Developer Hub for opensource
                        integration, our team will launch an open source data
                        and bring customized support services to support SME's
                        and Large businessess.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* secondbox */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: ["0px", "20px"],
                  marginBottom: ["20px", "0px"],
                  marginTop: { lg: "140px" },
                  float: "left",
                }}
              >
                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(2)}
                  onMouseLeave={() => handleHideDescription(2)}
                >
                  <Box>
                    <Box>
                      <img
                        src={multicurrencyimg}
                        alt="profile"
                        draggable="false"
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      Multi Currency Processing
                    </Typography>
                    {showDescription && cardNumber == 2 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        VoeuxPay integrating global payment solutions enable to
                        accept and process payment in different currencies from
                        global customers.
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(3)}
                  onMouseLeave={() => handleHideDescription(3)}
                >
                  <Box>
                    <Box>
                      <img src={voeuxcardimg} alt="profile" draggable="false" />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      Voeux Card
                    </Typography>
                    {showDescription && cardNumber == 3 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        Our Voeux Card for individuals and merchants are soon be
                        able to maximize comprehensive solutions that gives our
                        loyal clients and partners to gain rewards and
                        incentivize them from our ecosystem
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* thirdbox */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: ["0px", "20px"],
                  marginBottom: ["20px", "0px"],
                  float: "left",
                }}
              >
                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(4)}
                  onMouseLeave={() => handleHideDescription(4)}
                >
                  <Box>
                    <Box>
                      <img src={ewalletimg} alt="profile" draggable="false" />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      E- Wallet
                    </Typography>
                    {showDescription && cardNumber == 4 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        Alternative way of accepting payment for your customer
                        choice, using various method like Pay pal, google pay,
                        Apple Pay, Amazon Pay and more to come
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(5)}
                  onMouseLeave={() => handleHideDescription(5)}
                >
                  <Box>
                    <Box>
                      <img src={qrbankimg} alt="profile" draggable="false" />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      QR bank transfer
                    </Typography>
                    {showDescription && cardNumber == 5 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        VoeuxPay QR bank transfer provide ease and convenience
                        to our clients Recipients can then receive the cash by
                        scanning the QR code makes transactions faster and more
                        secure.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* fourthbox
               */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: ["0px", "20px"],
                  marginBottom: ["20px", "0px"],
                  marginTop: { lg: "140px" },
                  float: "left",
                }}
              >
                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(6)}
                  onMouseLeave={() => handleHideDescription(6)}
                >
                  <Box>
                    <Box>
                      <img src={bankimg} alt="profile" draggable="false" />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      Online Banking
                    </Typography>
                    {showDescription && cardNumber == 6 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        Our online banking serves you to connect in the
                        Southeast Asia. VoeuxPay will bring VC MC and other type
                        of major banks for debit and credit processing.
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(7)}
                  onMouseLeave={() => handleHideDescription(7)}
                >
                  <Box>
                    <Box>
                      <img
                        src={paymentsolimg}
                        alt="profile"
                        draggable="false"
                      />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      Customized Payment Solution
                    </Typography>
                    {showDescription && cardNumber == 7 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        VoeuxPay are continuously scaling its payment solutions
                        with our local and international partners and bring
                        tailored gateway for our merchants and businesses.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* fifthbox
               */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: ["0px", "20px"],
                  marginBottom: ["20px", "0px"],
                  float: "left",
                }}
              >
                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(8)}
                  onMouseLeave={() => handleHideDescription(8)}
                >
                  <Box>
                    <Box>
                      <img src={onrampimg} alt="profile" draggable="false" />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      On Ramp
                    </Typography>
                    {showDescription && cardNumber == 8 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        You can effortlessly accept payments in fiat currency
                        and receive cryptocurrencies directly into your business
                        account. Unlock a world of finance with ease, security,
                        and transparency with VoeuxPay
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    background: "#181818",
                    borderRadius: "17.646px",
                    marginBottom: "20px",
                    width: "246.206px",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    p: "20px",
                  }}
                  onMouseEnter={() => handleShowDescription(9)}
                  onMouseLeave={() => handleHideDescription(9)}
                >
                  <Box>
                    <Box>
                      <img src={offramp} alt="profile" draggable="false" />
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "19.607px",
                        textTransform: "uppercase",
                      }}
                    >
                      Off Ramp
                    </Typography>
                    {showDescription && cardNumber == 9 && (
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "14px",
                          textTransform: "none",
                        }}
                      >
                        you can easily accept crypto payments and receive them
                        directly into your wallet, all with just a few clicks.
                        With VoeuxPay you can upgrade your business into Web3
                        banking solutions.
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* for mobile */}
          {/* id: 1,
      image: paymentlinkimg,
      title: "Payment Link",
      caption:
        "Secu */}
          {isMobile && (
            <div
              style={{ marginBottom: "2rem" }}
              className="slider-container ourservices"
            >
              <Slider {...settings1}>
                {ourService.map((service, index) => (
                  <Box
                    sx={{
                      background: "#181818",
                      borderRadius: "17.646px",
                      marginBottom: "20px",
                      width: "246.206px",
                      height: "auto",
                      padding: "10px !important",
                      // height: "177.64px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    onMouseEnter={() => handleShowDescription(index)}
                    onMouseLeave={() => handleHideDescription(index)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={service.image}
                        draggable="false"
                        alt="profile"
                        style={{ height: "80px", marginBottom: "10px" }}
                      />

                      <Typography
                        sx={{
                          fontFamily: "Bebas Neue",
                          fontSize: "14.607px",
                          textTransform: "uppercase",
                        }}
                      >
                        {service.title}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </div>
          )}
        </Box>

        <Box ref={howToWork}>
          {!isMobile && (
            <Box
              sx={{
                alignContent: "center",
                textAlign: "center",
                marginTop: "4rem",
                marginBottom: "3rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "36px",
                  fontWeight: "400px",
                }}
              >
                START WITH{" "}
                <span
                  style={{
                    fontFamily: "Bebas Neue",
                    fontSize: "36px",
                    fontWeight: "400px",
                    color: "#FF990D",
                  }}
                >
                  VOEUX PAY
                </span>
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "outfit",
                  fontWeight: "400px",
                }}
              >
                Efficiently set up and use your business account with VoeuxPay
                for seamless online
                <br /> payments, We ensure a seamless user experience and
                ongoing business growth <br />
                through regular process optimization.
              </Typography>
            </Box>
          )}
          {isMobile && (
            <Box
              sx={{
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "23px",
                  fontWeight: "400px",
                }}
              >
                START WITH{" "}
                <span
                  style={{
                    fontFamily: "Bebas Neue",

                    fontWeight: "400px",
                    color: "#FF990D",
                  }}
                >
                  VOEUX PAY
                </span>
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "outfit",
                  fontWeight: "400px",
                }}
              >
                Efficiently set up and use your business account with VoeuxPay
                for seamless online payments, We ensure a seamless user
                experience and ongoing business growth through regular process
                optimization.
              </Typography>
            </Box>
          )}

          {/* for other resolution(steps) */}
          {!isMobile && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontFamily: "outfit", fontSize: "16px" }}>
                      step1
                    </Typography>
                  </Box>
                  <Box>
                    <img src={bussinessimg} alt="profile" draggable="false" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontFamily: "Bebas Neue",
                        textTransform: "uppercase",
                        fontWeight: "400px",
                      }}
                    >
                      Create Business Account
                    </Typography>{" "}
                  </Box>
                </Box>
                <img src={line} alt="profile" draggable="false" />
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ fontFamily: "outfit", fontSize: "16px" }}
                      >
                        step2
                      </Typography>
                    </Box>
                    <Box>
                      <img src={integrateimg} alt="profile" draggable="false" />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontFamily: "Bebas Neue",
                          textTransform: "uppercase",
                          fontWeight: "400px",
                        }}
                      >
                        Integrate With VoeuxPay
                      </Typography>{" "}
                    </Box>
                  </Box>
                  <img src={line} alt="profile" draggable="false" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ fontFamily: "outfit", fontSize: "16px" }}
                      >
                        step3
                      </Typography>
                    </Box>
                    <Box>
                      <img src={payoptionimg} alt="profile" draggable="false" />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontFamily: "Bebas Neue",
                          textTransform: "uppercase",
                          fontWeight: "400px",
                        }}
                      >
                        Place Your Payment Option
                      </Typography>{" "}
                    </Box>
                  </Box>
                  <img src={line} alt="profile" draggable="false" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ fontFamily: "outfit", fontSize: "16px" }}
                      >
                        step4
                      </Typography>
                    </Box>
                    <Box>
                      <img src={getprofitimg} alt="profile" draggable="false" />
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontFamily: "Bebas Neue",
                          textTransform: "uppercase",
                          fontWeight: "400px",
                        }}
                      >
                        Get Profit
                      </Typography>{" "}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* mobile screen resolution(steps) */}
          {isMobile && (
            <Box sx={{ marginTop: "20px", marginBottom: "2rem" }}>
              <StepsMobile />
            </Box>
          )}
        </Box>
        {/* CHOOSE  FOR OTHER RESOLUTION*/}
        {!isMobile && (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "9rem",
              marginBottom: "1rem",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Bebas Neue",
                fontSize: "36px",
                fontWeight: "400px",
                textTransform: "uppercase",
                marginBottom: "1rem",
              }}
            >
              Choose your account
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Choosecards />
            </Box>
          </Box>
        )}

        {/* CHOOSE  FOR MOBILE RESOLUTION*/}
        {isMobile && (
          <Box
            sx={{
              textAlign: "center",
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Bebas Neue",
                fontSize: "23px",
                fontWeight: "400px",
                textTransform: "uppercase",
                marginBottom: "1rem",
              }}
            >
              Choose your account
            </Typography>

            <Box>
              <ChoosecardsMobile />
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "flex" },
            ...circleStyle,
            top: { xl: "385%" },
            left: "0%",
            transform: "translateY(-70%)",
            // width: "370px", // Adjusted width
            width: { xs: "40%", sm: "370px" }, // Adjusted width
            height: { xs: "40%", sm: "300px" },
            // height: "300px", // Adjusted height
            borderRadius: "547.625px",
            background:
              "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
            filter: "blur(160px)",
            backdropFilter: "blur(160px)",
            WebkitBackdropFilter: "blur(160px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "385%" },

              left: "0%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "flex" },
            ...circleStyle,
            top: { xl: "385%" },
            right: "0%",
            transform: "translateY(-50%)",
            // width: "370px", // Adjusted width
            width: { xs: "40%", sm: "370px" }, // Adjusted width
            height: { xs: "40%", sm: "300px" },
            // height: "300px", // Adjusted height
            borderRadius: "672.058px",
            background: "var(--56-ccf-2, #56CCF2)",
            filter: "blur(162px)",
            backdropFilter: "blur(162px)",
            WebkitBackdropFilter: "blur(162px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "380%" },

              right: "0%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>

        <Box sx={{ textAlign: "center", marginTop: "2rem" }}>
          <Typography
            sx={{
              fontFamily: "Bebas Neue",
              fontSize: { xs: "23px", md: "36px" },
              fontWeight: "400px",
              textTransform: "uppercase",
            }}
          >
            <span
              style={{
                color: "#FF990D",
                fontFamily: "Bebas Neue",

                fontWeight: "400px",
                textTransform: "uppercase",
              }}
            >
              WE{" "}
            </span>{" "}
            support All Industries
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                height: {
                  xs: 400,
                  sm: 400,
                  md: 400,
                  lg: " 70%",
                },
                width: {
                  xs: "100%",
                  sm: 300,
                  md: 400,
                  lg: "70%",
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: { xs: 2, md: 10 },
              }}
            >
              <img
                draggable="false"
                src={landingvideo}
                alt="animation"
                height={"100%"}
                width={"100%"}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "flex" },
            ...circleStyle,
            top: { xl: "550%" },
            left: "0%",
            transform: "translateY(-70%)",
            // width: "300px", // Adjusted width
            width: { xs: "40%", sm: "300px" }, // Adjusted width
            height: { xs: "40%", sm: "300px" },
            // height: "300px", // Adjusted height
            borderRadius: "547.625px",
            background:
              "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
            filter: "blur(160px)",
            backdropFilter: "blur(160px)",
            WebkitBackdropFilter: "blur(160px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "550%" },

              left: "0%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "flex" },
            ...circleStyle,
            top: { xl: "550%" },
            right: "0%",
            transform: "translateY(-50%)",
            width: { xs: "40%", sm: "300px" }, // Adjusted width
            height: { xs: "40%", sm: "300px" },
            // height: "300px", // Adjusted height
            borderRadius: "672.058px",
            background: "var(--56-ccf-2, #56CCF2)",
            filter: "blur(162px)",
            backdropFilter: "blur(162px)",
            WebkitBackdropFilter: "blur(162px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "550%" },

              right: "0%",
              filter: "blur(160px)",

              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>

        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "6rem",
              width: "80%",
              margin: "auto",
            }}
          >
            <img
              src="https://d24m5mu8cxqwfm.cloudfront.net/images/MAP.svg"
              alt="map"
              draggable="false"
              style={{ width: "inherit" }}
            />
          </Box>
        )}
        {isMobile && (
          <>
            <Box
              sx={{
                textAlign: "center",
                marginTop: "2rem",
                marginBottom: "3rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "23px",
                  fontWeight: "400px",
                  textTransform: "uppercase",
                }}
              >
                Growing Global Coverage
              </Typography>
            </Box>
          </>
        )}
        {/* flag other resolution */}
        {!isMobile && (
          <>
            <Box
              sx={{
                textAlign: "center",
                marginTop: "6rem",
                marginBottom: "3rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "36px",
                  fontWeight: "400px",
                  textTransform: "uppercase",
                }}
              >
                Growing Global Coverage
              </Typography>
            </Box>

            <FlagAnimation />
          </>
        )}

        {/* mobileflag */}
        {isMobile && (
          <Box>
            <FlagAnimationMob />
          </Box>
        )}

        {/* question */}
        {!isMobile && (
          <Box ref={faq}>
            <Box
              sx={{
                textAlign: "center",
                marginTop: { xs: "1rem", md: "6rem" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "36px",
                  fontWeight: "400px",
                  textTransform: "uppercase",
                }}
              >
                Frequently Asked Questions
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: { xs: "1rem", md: "6rem" },
              }}
            >
              <Box
                sx={{
                  width: "80%",
                }}
              >
                {" "}
                <VoeuxpayAccordion PayAccordionData={PayAccordionData} />
              </Box>
            </Box>
          </Box>
        )}

        {isMobile && (
          <Box ref={faq}>
            <Box
              sx={{
                textAlign: "center",
                marginTop: "3rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "23px",
                  fontWeight: "400px",
                  textTransform: "uppercase",
                }}
              >
                Frequently Asked Questions
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",

                marginBottom: "3rem",
              }}
            >
              <VoeuxpayAccordion PayAccordionData={PayAccordionData} />
            </Box>
          </Box>
        )}

        {/* Contact form */}

        <Box
          sx={{
            backgroundImage: `url(${contactimg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            padding: { md: "80px 80px", xs: "20px 20px" },
            width: "100%",
            height: "100%",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* contact */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.80)",
              borderRadius: "13.15px",
              margin: "auto",
              height: "auto",
              padding: "20px 20px",

              flexDirection: "column",
              // width: "fit-content",
              width: {
                lg: "calc(100% - 59%)",
                md: "calc(100% - 29%),",
                sm: "fit-content",
                xs: "fit-content",
              },
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: { xs: "23px", md: "42px" },
                  fontWeight: "400px",
                  textTransform: "uppercase",
                  textAlign: "center",
                  marginTop: "4%",
                }}
              >
                Get in touch
              </Typography>
              <Typography
                sx={{
                  fontFamily: "outfit",
                  fontSize: "16px",
                  fontWeight: "400px",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                Our team would love to hear from you.
              </Typography>
            </Box>
            <Grid sx={{}} container spacing={2} padding={2}>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                  }}
                >
                  First Name*
                </Typography>
                <Box>
                  <TextField
                    type="text"
                    name="firstName"
                    fullWidth
                    placeholder="Enter your first name"
                    className="firstnameinput"
                    required
                    InputProps={{
                      style: {
                        color: "#667085",
                        border: "1px solid #797979",
                        borderRadius: "8px",

                        boxShadow: "none",
                      },
                    }}
                    sx={{
                      background: "transparent",
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "none",
                        },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                  }}
                >
                  Last Name*
                </Typography>
                <TextField
                  type="text"
                  name="lastName"
                  fullWidth
                  placeholder="Enter your last name"
                  className="firstnameinput"
                  required
                  InputProps={{
                    style: {
                      color: "#667085",
                      border: "1px solid #797979",
                      borderRadius: "8px",
                      boxShadow: "none",
                    },
                  }}
                  sx={{
                    background: "transparent",
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "15px", fontFamily: "outfit" }}>
                  Phone Number
                </Typography>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControl sx={{ flexBasis: "200px" }}>
                      <Select
                        className="phonenumber"
                        style={{
                          border: "1px solid #797979",
                          borderRadius: "8px 0px 0px 8px",
                          color: "#667085",
                          outline: "none",

                          // width: "85px",
                          // marginRight: "10px",
                        }}
                        sx={{
                          background: "transparent",
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none",
                            },
                        }}
                        labelId="country"
                        id="simpleselect"
                        value={selectedOption}
                        onChange={handleChange}
                      >
                        <MenuItem value="option1">+971</MenuItem>
                        <MenuItem value="option2">+990</MenuItem>
                        <MenuItem value="option3">+105</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      type="number"
                      fullWidth
                      sx={{
                        border: "1px solid #797979",
                        borderRadius: "0px 8px 8px 0px",
                        borderLeft: "0px",
                        color: "#667085",
                        // marginLeft: { xs: "16px", md: "5px" },
                        outline: "none",
                      }}
                    />
                  </Box>
                </Box>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontFamily: "outfit",
                      marginTop: "1rem",
                    }}
                  >
                    Country/Region*
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      style={{
                        border: "1px solid #797979",
                        borderRadius: "8px",
                        marginBottom: "1rem",
                        color: "#667085",
                        outline: "none",
                      }}
                      labelId="country"
                      id="simpleselect"
                      // You can set a state variable to manage the selected country value
                      // value={selectedCountry}
                      // onChange={handleCountryChange}
                    >
                      <MenuItem value="India">India</MenuItem>
                      <MenuItem value="Canada">Canada</MenuItem>
                      <MenuItem value="Australia">Australia</MenuItem>
                      {/* Add more countries as needed */}
                    </Select>
                  </FormControl>
                </Grid>
                <Typography sx={{ fontSize: "15px", fontFamily: "outfit" }}>
                  Work Email*
                </Typography>
                <TextField
                  type="email"
                  name="email"
                  fullWidth
                  placeholder="you@company.com"
                  className="emphn"
                  required
                  InputProps={{
                    style: {
                      border: "1px solid #797979",
                      borderRadius: "8px",
                      marginBottom: "1rem",
                      color: "#667085",
                      outline: "none",
                    },
                  }}
                  sx={{
                    background: "transparent",
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                  }}
                />
                <Box>
                  <Typography sx={{ fontSize: "15px", fontFamily: "outfit" }}>
                    Message*
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    name="message"
                    fullWidth
                    placeholder="Leave us a message.."
                    required
                    InputProps={{
                      style: {
                        color: "#667085",

                        border: "1px solid #797979",
                        borderRadius: "8px",
                        marginBottom: "5px",
                        width: "100%",
                        outline: "none",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#9E9E9E",
                      },
                    }}
                    sx={{
                      background: "transparent",
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: "none",
                        },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    gap: "5px",
                    marginTop: 2,
                    flexDirection: "row",
                  }}
                >
                  <input type="checkbox" />
                  You agree to our
                  <a
                    href="#"
                    style={{ color: "orange", textDecoration: "none" }}
                  >
                    Privacy Policy.
                  </a>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#FF990D",
                    border: "1px solid #FF990D",
                    color: "#fff",
                    padding: "8px",
                    borderRadius: "6.57px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "3rem",

                    // margin: "15px",

                    "&:hover": {
                      backgroundColor: "#FF990D",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Bebas Neue",
                      fontSize: "18px",
                      textTransform: "uppercase",
                    }}
                  >
                    Send message
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <VoeuxPayFooter
        getInTouchRef={getInTouchRef}
        servicesRef={servicesRef}
        howToWork={howToWork}
        faq={faq}
      />
    </>
  );
}

export default VoeuxPayLanding;
