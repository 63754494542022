import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Typography from "@mui/material/Typography";
import PlusIcon from "../../assets/Createnft/Plus.svg";
import { useEffect, useState } from "react";
import TraitPlusIcon from "../../assets/Createnft/AddTraits.svg";
import TraitDeletePlus from "../../assets/Createnft/Delete.svg";
import Button from "@mui/material/Button";
import { MenuItem, Select } from "@mui/material";
import DragAndDrop from "../../components/createNft/DragAndDrop";
import LeftArrow from "../../assets/svgs/LeftArrow.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CreacteNftServices } from "src/services/createNftService";
import { Environment } from "../../environments";


const CreateSingleNftPage = ({
  handleCreateCollection,
  handleCreateClick,
  getNftImageurl,
  handleLeftArrow,
  handleShowLoader,
  getFormData,
  showToastErrorMessageClick,
}: {
  handleCreateCollection: () => void;
  handleCreateClick: (value: any, data: any) => void;
  getNftImageurl: (data: any, file: any) => void;
  handleLeftArrow: () => void;
  handleShowLoader: (active: boolean, text?: any) => void;
  getFormData: (data: any) => void;
  showToastErrorMessageClick: (data: any) => void;
}) => {
  const [traitInput, setTraitInput] = useState([
    { type: "", value: "", delete: false },
  ]);

  const walletData = localStorage.getItem("account_address");
  const [nftService, setNftService] = useState<CreacteNftServices>();
  const navigate = useNavigate();
  //   const handleLeftArrow = () => {
  //     navigate("/nftmarketplace")
  //   };
  const [singleNftDragContent, setSingleNftDragContent] = useState({
    imageURL: "",
    selectedFile: "",
  });
  const getImageurl = (data: any, file: any) => {
    setSingleNftDragContent((prevState) => ({
      ...prevState,
      imageURL: data,
      selectedFile: file,
    }));
    getNftImageurl(data, file);
  };

  const handleCreateNewCollectionDropdown = (e: any) => {
    handleCreateCollection();
    e.preventDefault();
  };

  useEffect(() => {
    const service = new CreacteNftServices();
    const intervalId = setInterval(() => {
      if (
        service.createNftInstance.contractInstance != null &&
        service.createNftInstance.contractInstance != undefined
      ) {
        setNftService(service);
        clearInterval(intervalId);
      }
    }, 100);
  }, []);

  const handleAddTrait = (index: any) => {
    traitInput[index].delete = true;
    const newArray = [...traitInput];

    // Prepend the new element to the copied array
    newArray.unshift({ type: "", value: "", delete: false });
    setTraitInput(newArray);
    formatTraitsData();
  };
  const [traitInputData, setTraitInputData] = useState([]);

  const formatTraitsData = () => {
    if (
      traitInput.length == 1 &&
      traitInput[0].type == "" &&
      traitInput[0].value == ""
    ) {
      setTraitData([]);
    } else {
      // const obj = { a: 1, b: 2, c: 3, d: 4 }
      // const clone = (([{delete, ...o }] ) => o)(traitInput) // remove b and c

      // console.log(clone)
      let sample: any = traitInput.map((obj) => ({
        type: obj.type,
        value: obj.value,
      }));
      setTraitInputData(sample);
    }
  };
  const handleDeleteTraits = (index: any) => {
    const newArray = [...traitInput];
    newArray.splice(index, 1);
    setTraitInput(newArray);
  };
  const [formdata, setFormData] = useState<any>({ supply: 1 });
  const [traitData, setTraitData] = useState<any>({});

  const handleInputTraitData = (keyValue: any, value: any, index: any) => {
    if (keyValue == "type") {
      traitInput[index].type = value;
    } else {
      traitInput[index].value = value;
    }

    setTraitInput([...traitInput]);
    formatTraitsData();
  };

  const handleFormSubmit = async (e: any) => {
    handleShowLoader(true,"Connecting to Blockchain....");
    e.preventDefault();
    console.log("singleNftDragContent", singleNftDragContent);
    formdata.traits = traitInputData;
    setFormData({ ...formdata });

    // getFormData(formdata)
    if (formdata.supply <= 0) {
      showToastErrorMessageClick("Supply should be greater than 0");
      handleShowLoader(false);
      return;
    }

    if (!singleNftDragContent.imageURL) {
      showToastErrorMessageClick("Upload the NFT image");
      handleShowLoader(false);
      return;
    }

    try {
      await handleCreateNft();
    } catch (error) {
      console.log("error 131", error);
    }

    // handleCreateNft()
    // handleCreateClick(true,formdata)
    const imageCID = getNftImageurl(
      singleNftDragContent.imageURL,
      singleNftDragContent.selectedFile
    );
  };
  const [collectionNames, setCollectionNames] = useState<any>([]);
  const [collectionAddress, setCollectionAddress] = useState<string>("");
  const handleCollectionDropdown = (e: any) => {
    console.log("in handle collection name change");
    let userid = collectionNames.findIndex(
      (obj: any) => obj.collectionName === e.target.value
    );

    console.log("e.target", e.target.value, collectionNames, userid);
    console.log("collection of specific user", collectionNames);

    setCollectionAddress(collectionNames[userid].collectionAddress);
    setFormData({
      ...formdata,
      [e.target.name]: collectionNames[userid].collectionId,
      user: collectionNames[userid].userId,
    });
  };

  const handleCreateNft = async () => {
    if (nftService) {
      const collectionIndex = await nftService.getCollectionIndex(
        collectionAddress
      );
      console.log(
        "index of the contract service exists ===>",
        Number(collectionIndex)
      );
      if (Number(collectionIndex) >= 0) {
        console.log(
          "inside if condition of the index exists",
          formdata.NftName
        );

        try {
          console.log("nftService ==>", nftService);
          const creatednft = await nftService.createERC1155NFT(
            Number(collectionIndex),
            formdata.nftName,
            formdata.supply
          );
          console.log("created nft", creatednft);
          //contract
          const contractIndex = await nftService.contractToIndex(
            collectionAddress
          );
          console.log("contractIndex", Number(contractIndex));

          const getIdName = await nftService.getIdByName(
            Number(contractIndex),
            formdata.nftName
          );
          console.log("contractIndex", getIdName);
          const getNftId = Number(getIdName);
          formdata.tokenId = getNftId;
          handleCreateClick(true, formdata);
          handleShowLoader(false);
          // .then(hash => { handleCreateClick(true,formdata); handleShowLoader(false) })

          // setFormData({ ...formdata, tokenId: getNftId })
        } catch (error: any) {
          console.log("error ", error);
          handleShowLoader(false);
          showToastErrorMessageClick("Creating NFT Failed");
        }
      }
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      console.log("fetchUserDetails === >");
      try {
        // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
        const response = await axios.get(
          `${Environment.metavoeuxBackend}nftCollection/user`,
          {
            params: { walletAddress: walletData },
          }
        );
        console.log("get user details", response.data);
        const collections = response.data.map((data: any) => {
          return {
            collectionId: data._id,
            collectionName: data.contractName,
            collectionAddress: data.collectionAddress,
            userId: data.currentOwner._id,
          };
        });
        setCollectionNames(collections);
        handleShowLoader(false);
        // formdata.user  = response.data
        // handleShowLoader()
      } catch (error) {
        console.error("Error fetching data:", error);
        handleShowLoader(false);
      }
    };

    fetchUserDetails();
    // console.log("formdata",formdata);
  }, [walletData]);

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  return (
    <>
      <Grid container spacing={{ xs: 10, lg: 20 }}>
        <Grid item xs={12} md={6} lg={6}>
          <Box sx={{ position: "relative" }}>
            <img src={LeftArrow} alt="" onClick={() => handleLeftArrow()} />
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                ...circleStyle,
                top: { xl: "65%" },
                left: "-22%",
                transform: "translateY(-50%)",
                width: "350px", // Adjusted width
                height: "200px", // Adjusted height
                borderRadius: "50%", // Ensures it remains a perfect circle
                background: "#9D00FF",
                // background: "red",
                filter: "blur(140px)",
                backdropFilter: "blur(140px)",
                WebkitBackdropFilter: "blur(140px)",
                overflow: "hidden",
                "@media (min-width: 1721px)": {
                  width: "450px", // Adjusted width
                  height: "400px", // Adjusted height
                  top: { xl: "50%" },
                  left: "-20%",
                  filter: "blur(160px)",
                  backdropFilter: "blur(160px)",
                  WebkitBackdropFilter: "blur(160px)",
                },
              }}
            ></Box>

            <DragAndDrop
              createCollection
              getImageurl={getImageurl}
              data={singleNftDragContent}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box>
            <Typography
              sx={{
                color: "#FFF",
                fontSize: { xs: "16px", md: "32px" },
                fontWeight: 600,
                mb: "20px",
                fontFamily: "outfit",
              }}
            >
              Mint an NFT
            </Typography>
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 300,
                mb: "30px",
                fontFamily: "outfit",
              }}
            >
              Once your item is minted you will not be able to change any of its
              information
            </Typography>
            <Box
              sx={{
                display: "flex",
                p: "16px",
                borderRadius: "16px",
                background: "rgba(217, 217, 217, 0.11)",
                alignItems: "center",
                columnGap: "13px",
                border: "1px dashed #FFF",
                mb: "30px",
                cursor: "pointer",
              }}
              onClick={() => handleCreateCollection()}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "20%" },
                  right: "0%",
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  // background: "red",
                  filter: "blur(140px)",
                  backdropFilter: "blur(140px)",
                  WebkitBackdropFilter: "blur(140px)",
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "50%" },
                    left: "-20%",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                  },
                }}
              ></Box>
              <Box
                sx={{
                  borderRadius: "16px",
                  background: "rgba(217, 217, 217, 0.11)",
                  p: "19px ",
                }}
              >
                <img src={PlusIcon} alt="" />
              </Box>
              <Typography
                sx={{
                  color: "#F6EBFD",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: 300,
                  fontFamily: "outfit",
                }}
              >
                Create a New Collection
              </Typography>
            </Box>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "30px",
              }}
              onSubmit={(e) => handleFormSubmit(e)}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "20px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  Collection Name
                </Typography>
                <Select
                  name="collectionId"
                  placeholder="Select collection"
                  className="collectionNameInput"
                  required
                  sx={{
                    border: "1px solid #777",
                    borderRadius: "12px",
                    color: "#828282",
                    width: "100%",
                  }}
                  onChange={
                    (e) => handleCollectionDropdown(e)
                    // setFormData({ ...formdata, [e.target.name]: e.target.value })
                  }
                >
                  <MenuItem>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 1,
                        background: "#FF990D",
                        fontSize: "20px",
                        "&:hover": {
                          background: "#FF990D",
                        },
                      }}
                      onClick={(e) => handleCreateNewCollectionDropdown(e)}
                    >
                      Create New Collection
                    </Button>
                  </MenuItem>
                  {collectionNames.length > 0 &&
                    collectionNames.map((collection: any) => {
                      // console.log("collection in jsx ==>", collection);
                      return (
                        <MenuItem
                          id={collection?.collectionId}
                          value={collection?.collectionName}
                        >
                          {collection?.collectionName}
                        </MenuItem>
                      );
                    })}

                  {/* <MenuItem value="option2">Collection 2</MenuItem>
              <MenuItem value="option1"> Collection 3</MenuItem>
              <MenuItem value="option2">Collection 4</MenuItem> */}
                </Select>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "20px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  NFT Name
                </Typography>
                <TextField
                  type="text"
                  name="nftName"
                  placeholder="Enter your NFT name"
                  required
                  inputProps={{ style: { color: "#777" } }}
                  sx={{
                    border: "1px solid #777",
                    borderRadius: "12px",
                    color: "#828282",
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setFormData({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "20px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  Supply
                </Typography>
                <TextField
                  type="number"
                  name="supply"
                  placeholder="Enter your Supply name"
                  required
                  // defaultValue={1}
                  value={formdata.supply}
                  inputProps={{ style: { color: "#777" } }}
                  sx={{
                    border: "1px solid #777",
                    borderRadius: "12px",
                    color: "#828282",
                    width: "100%",
                  }}
                  onChange={(e) =>
                    setFormData({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Box>
              {/* <Box>
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "20px", md: "20px" },
                fontWeight: 500,
                fontFamily:"outfit",
              }}
            >
              External Link
            </Typography>
            <TextField
              type="text"
              name="externalLink"
              placeholder="Enter your first name"
              required
              inputProps={{ style: { color: "#777" } }}
              sx={{
                border: "1px solid #777",
                borderRadius: "12px",
                color: "#828282",
                width: "100%",
              }}
              onChange={(e) =>
                setFormData({ ...formdata, [e.target.name]: e.target.value })
              }
            />
          </Box> */}
              <Box>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "20px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  Description
                </Typography>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder=" Enter your Description"
                  name="description"
                  style={{
                    border: "1px solid #777",
                    borderRadius: "12px",
                    color: "#828282",
                    width: "100%",
                    background: "none",
                    height: "120px",
                    padding: "15px",
                  }}
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "20px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  Traits (Optional)
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                  }}
                >
                  {traitInput.map((item, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: "20px",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        type="text"
                        name="type"
                        inputProps={{ style: { color: "#777" } }}
                        placeholder="Type"
                        onChange={
                          (e) =>
                            handleInputTraitData(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          // setTraitData({ ...traitData, [e.target.name]: e.target.value })
                        }
                        value={item.type}
                        sx={{
                          border: "1px solid #777",
                          borderRadius: "12px",
                          color: "#828282",
                          width: "100%",
                        }}
                      />
                      <TextField
                        type="text"
                        name="traitName"
                        placeholder="Name"
                        inputProps={{ style: { color: "#777" } }}
                        onChange={
                          (e) =>
                            handleInputTraitData(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          // setTraitData({ ...traitData, [e.target.name]: e.target.value })
                        }
                        value={item.value}
                        sx={{
                          border: "1px solid #777",
                          borderRadius: "12px",
                          color: "#828282",
                          width: "100%",
                        }}
                      />
                      <Box sx={{ cursor: "pointer" }}>
                        {item.delete ? (
                          <img
                            src={TraitDeletePlus}
                            alt=""
                            onClick={() => handleDeleteTraits(index)}
                          />
                        ) : (
                          <img
                            src={TraitPlusIcon}
                            alt=""
                            onClick={() => handleAddTrait(index)}
                          />
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Button
                sx={{
                  background: " #FF990D",
                  padding: "16px 32px",
                  color: "white",
                  borderRadius: "12px", fontSize:"18px",
                  fontFamily: "outfit",width:"40%",margin:"auto",
                  "&:hover": {
                    background: " #FF990D",
                    color: "white",
                  },
                }}
                type="submit"
              >
                Create
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default CreateSingleNftPage;
