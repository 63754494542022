import { Contract } from "ethers";
import { ContractInstance } from "./contractInstance";

export class DappToken {
  contractInstance: Contract | null;

  constructor(
    DAPPTOKEN_CONTRACT_ADDRESS: string,
    DAPPTOKEN_CONTRACT_ABI: any[]
  ) {
    this.contractInstance = null;
    const contract = new ContractInstance();
     contract.buildContractInstance(
      DAPPTOKEN_CONTRACT_ADDRESS,
      DAPPTOKEN_CONTRACT_ABI
    ).then(contIns=>{
      this.contractInstance = contIns;
    })
  }
   
 

  getAllowance = async (owner: string, spender: string): Promise<number> => {
    try {
      return await this.contractInstance?.allowance(owner, spender);
    } catch (err) {
      console.error("GetAllowance",err);
      throw err;
    }
  };

  approveTransaction = async (
    spender: string,
    value: BigInt
  ): Promise<object> => {
    try {
      return await this.contractInstance?.approve(spender, value);
    } catch (err) {
      console.error("ApproveTransaction",err);
      throw err;
    }
  };

  getBalance = async (account: string): Promise<BigInt> => {
    try {
      const response = await this.contractInstance?.balanceOf(account);
      return response;
    } catch (err) { 
      console.error("GetBalance",err);
      throw err;
    }
  };

  getUserName = async (): Promise<string> => {
    try {
      return await this.contractInstance?.name();
    } catch (err) {
      console.error("GetUserName",err);
      throw err;
    }
  };

  getTokenSymbol = async (): Promise<string> => {
    try {
      return await this.contractInstance?.symbol();
    } catch (err) {
      console.error("GetTokenSymbol",err);
      throw err;
    }
  };

  getDecimals = async (): Promise<number> => {
    try {
      return await this.contractInstance?.decimals();
    } catch (err) {
      console.error("GtDecimals",err);
      throw err;
    }
  };

  getToatalSupply = async (): Promise<number> => {
    try {
      return await this.contractInstance?.totalSupply();
    } catch (err) {
      console.error("GetToatalSupply",err);
      throw err;
    }
  };

  transferToken = async (to: string, value: number): Promise<boolean> => {
    try {
      console.log("=====in daptoken transferToken ===>");
      const txn =  await this.contractInstance?.transfer(to, value);
      await txn.wait();
      return true;
    } catch (err) {
      console.error("TransferToken",err);
      return false;
    }
  };

  transferFromThirdParty = async (
    from: string,
    to: string,
    value: number
  ): Promise<boolean> => {
    try {
      return await this.contractInstance?.transferFrom(from, to, value);
    } catch (err) {
      console.error("GetTransferDetail",err);
      throw err;
    }
  };

  
}
