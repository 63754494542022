import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import chart from "../../assets/svgs/chart.svg";
import styles from "./LandingPageFloatingList.module.css";
interface TokenList {
  name: string;
  logo: string;
  symbol: string;
  price: string;
  priceChange24hrs: number;
  priceChange7days: number;
  marketCap: number;
}

const LandingPageFloatingList = ({ list }: { list: TokenList[] }) => {
  return (
    <Box display="flex" className={styles.floatingListContainer}>
      {list.map((token) => {
        return (
          <Box
            className={styles.floatingList}
            // mr={2}
            sx={{ fontFamily: "outfit" }}
          >
            <Box
              height={"5rem"}
              width={"20rem"}
              //   border={"1px solid white"}
              mr={2}
            >
              <Stack
                direction="column"
                spacing={1}
                sx={{ fontFamily: "outfit" }}
              >
                <Typography sx={{ fontFamily: "Outfit" }}>
                  {token.name} <span>{token.symbol}</span>
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Stack width={"50%"} direction={"row"} spacing={1}>
                    <Box
                      height={"3rem"}
                      width={"3rem"}
                      //   border="1px solid white"
                    >
                      <img
                        src={token.logo}
                        alt=""
                        height={"100%"}
                        width={"100%"}
                      />
                    </Box>
                    <Stack direction="column">
                      <Typography sx={{ fontFamily: "outfit" }}>
                        {token.price}
                      </Typography>
                      <Typography
                        fontSize={13}
                        sx={{ fontFamily: "outfit" }}
                        textOverflow={"wrap"}
                      >
                        {token.symbol}/USDT
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="column" width={"50%"}>
                    <Typography
                      sx={{ fontFamily: "outfit" }}
                      textAlign={"left"}
                      color={token.priceChange24hrs < 0 ? "red" : "green"}
                    >
                      {`${
                        token.priceChange24hrs < 0
                          ? token.priceChange24hrs
                          : "+" + token.priceChange24hrs
                      }%`}
                    </Typography>
                    <Box>
                      <img src={chart} alt="" />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default LandingPageFloatingList;
