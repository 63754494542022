import {
  Grid,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import { title } from "process";
import React from "react";
import clock from "../../assets/svgs/clock.svg";
import { hover } from "@testing-library/user-event/dist/hover";

const newData = [
  {
    Title: "Marekting Specialist",
    Branch: "Marketing ",
    Caption:
      "As a Digital Marketer at Voeux Information Technology, you will be responsible for creating and executing digital marketing campaigns that enhance our brand visibility and drive engagement. Your expertise in platforms such as Google Ads, Facebook Ads, TikTok Ads, Twitter Ads, LinkedIn Ads, content creation, SEO (on-page and off-page), and post scheduling will be crucial in achieving our marketing goals.",
    time: "Full-time",
    salary: "Dhs 5k",
  },
  {
    Title: "Graphic Designer",
    Branch: "Design ",
    Caption:
      "We are looking for a talented and passionate video editor and creator to join our team. You will be responsible for creating high-quality video content that engages our audience and helps us achieve our goals.",
    time: "Full-time",
    salary: "Dhs 5k",
  },
  {
    Title: "Project Manager",
    Branch: "Development",
    Caption:
      "We're seeking a seasoned Blockchain Project Manager with a minimum of 5 years' experience in leading and managing blockchain projects, specifically in the realm of Web3 and Information Technology.",
    time: "Full-time",
    salary: "Dhs 5k",
  },
  {
    Title: "Product Manager",
    Branch: "Development",
    Caption:
      "We are seeking a dynamic Web 3.0 Product Manager to drive the design, sales and revenue strategies of our innovative blockchain projects. As a key player in our team, you will be instrumental in creating a lasting impact on the evolving Web 3.0 landscape.",
    time: "Full-time",
    salary: "Dhs 5k",
  },
  {
    Title: "Media Representative ",
    Branch: "Marketing ",
    Caption:
      "We are looking for a talented individual to join our team as a representative for our company on social media platforms and at various events. As a Social Media & Event Representative you will play a crucial role in promoting our projects, engaging with our audience, building community, and creating a positive brand image. The ideal candidate should possess excellent communication skills, a strong social media presence, and a passion for fashion and lifestyle.",
    time: "Full-time",
    salary: "Dhs 5k",
  },
  {
    Title: "Game Developer – Unity",
    Branch: "Design ",
    Caption:
      "We are looking for a skilled and experienced Senior Game Developer - Unity/Unreal with a minimum of 5 years of professional experience. As a Sr. Game Developer at Voeux IT Solution LLC, you will play a pivotal role in shaping the future of blockchain gaming.",
    time: "Full-time",
    salary: "Dhs 5k",
  },
  {
    Title: "Level Designer - 3D Artist",
    Branch: "Design ",
    Caption:
      "As a Unity/Unreal Engine Artist (Level Designer) at Voeux Information Technology, you will play a crucial role in designing the gameplay and other details like actions, events, objects and environment. You will also design the characters and the ways they behave.",
    time: "Full-time",
    salary: "Dhs 5k",
  },
  {
    Title: "Blockchain Developer",
    Branch: "Development ",
    Caption:
      "As a Blockchain Developer at Voeux Information Technology, you will be working on the development and implementation of blockchain solutions that have the potential to reshape industries. Your role will involve collaborating with cross-functional teams to design, develop, and maintain blockchain-based applications, smart contracts, and decentralized systems.",
    time: "Full-time",
    salary: "Dhs 5k",
  },
];

function CareerCardMui() {
  const buttonStyles = {
    background:
      "linear-gradient(#232323 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    cursor: "pointer",
    padding: ".5% 4%",

    marginRight: { xs: "0px", md: "20px" },
    backgroundColor: "#232323",
    color: "#ffffff",
    // padding: { xs: ".2% 1%" },
  };

  const hoverStyles = {
    backgroundImage: "linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%)",
  };
  const borderGradient = {
    background:
      "linear-gradient(#232323 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
    border: "1px solid transparent",
    borderRadius: "10px",
    cursor: "pointer",
    padding: ".5% 4%",
    // marginRight: "20px",
    backgroundColor: "#232323",
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <List
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      {newData.map((job, index) => (
        <ListItem
          key={index}
          style={{
            width: "100%",
            maxWidth: "768px",
            // padding: "10px",
            boxSizing: "border-box",
          }}
        >
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              marginTop: "1rem",

              color: "#ffffff",
              ...borderGradient,
            }}
          >
            <CardContent style={{ width: "100%", marginTop: "1rem" }}>
              <Grid container alignItems="center">
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontFamily: "outfit",
                    fontSize: { xs: "16px", md: "23px" },
                  }}
                >
                  {job.Title}
                </Typography>
                <Box
                  sx={{
                    width: "100px",
                    borderRadius: "16px",
                    background: "rgba(255, 153, 13, 0.09)",
                    marginLeft: "8px", // Add margin between title and branch
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    color="orange"
                    gutterBottom
                    sx={{
                      fontFamily: "outfit",
                      fontSize: { xs: "12px" },
                      textAlign: "center",
                    }}
                  >
                    {job.Branch}
                  </Typography>
                </Box>
              </Grid>

              <Typography
                sx={{
                  marginTop: "1rem",
                  lineHeight: "24px",
                  fontFamily: "outfit",
                  fontSize: { xs: "15px", md: "18px" },
                }}
                variant="body2"
                paragraph
              >
                {job.Caption}
              </Typography>
              {!isMobile && (
                <List
                  dense
                  style={{
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <ListItem
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 0,
                      fontFamily: "outfit",
                    }}
                  >
                    <img
                      src={clock}
                      alt="Clock"
                      style={{ marginRight: "8px" }}
                    />
                    <ListItemText
                      primary={job.time}
                      sx={{
                        fontFamily: "outfit",
                      }}
                    />
                    <ListItemText
                      primary={`Salary: ${job.salary}`}
                      sx={{
                        fontFamily: "outfit",
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        ...buttonStyles,
                        fontFamily: "outfit",
                        "&:hover": hoverStyles,
                        fontSize: { xs: "10px", md: "16px" },
                        textTransform: "none",
                      }}
                    >
                      Apply Now
                    </Button>
                  </ListItem>
                </List>
              )}

              {isMobile && (
                <List dense style={{ width: "100%", padding: 0 }}>
                  <ListItem
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 0,
                      fontFamily: "outfit",
                      columnGap: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={clock}
                        alt="Clock"
                        style={{ marginRight: "8px" }}
                      />
                      <ListItemText
                        primary={job.time}
                        sx={{
                          fontFamily: "outfit",
                        }}
                      />
                    </div>
                    <ListItemText
                      primary={`Salary: ${job.salary}`}
                      sx={{
                        fontFamily: "outfit",
                      }}
                    />
                  </ListItem>
                  <ListItem style={{ padding: 0 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        ...buttonStyles,
                        fontFamily: "outfit",
                        "&:hover": hoverStyles,
                        fontSize: { xs: "16px",},
                        textTransform: "none",
                        padding: "7px",
                        width: "40%",
                        marginTop:"10px"
                      }}
                    >
                      Apply Now
                    </Button>
                  </ListItem>
                </List>
              )}
            </CardContent>
          </Card>
        </ListItem>
      ))}
    </List>
  );
}

export default CareerCardMui;
