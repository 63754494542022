import {
  Button,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import Arrow from "../../assets/svgs/Double arrow.svg";
import { useNavigate, useParams } from "react-router";
import { RegisterFarmService } from "src/services/registerFarmService";
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import { FarmDetails } from "src/domains/farmDetails.dto";
import axios from "axios";
import { Environment } from "../../environments";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { RewardFarmService } from "src/services/rewardFarmService";

function UpdateFarm() {
  const navigate = useNavigate();

  const { id } = useParams();
  const handleBack = () => {
    navigate(`/staking/${Number(id)}`);
  };
  const [registerFarmInstance, setRegisterFarmInstance] =
    useState<RegisterFarmService>();
  let account = useSelector((state: RootState) => state.wallet.account);
  const [isManager, setIsManager] = useState(false);
  const [farmDetail, setFarmDetail] = useState<FarmDetails>();
  const [phasesData, setPhasesData] = useState<any>([]);
  const [rewardFarmService, setRewardFarmService]=useState<RewardFarmService>();
  useEffect(() => {
    handleShowLoader(true);

    const farmInst = new RegisterFarmService();
    const intvId = setInterval(() => {
      if (
        farmInst?.registerFarmInstance.contractInstance != null &&
        farmInst?.registerFarmInstance.contractInstance != undefined
      ) {
        setRegisterFarmInstance(farmInst);
        clearInterval(intvId);
      }
    }, 100);
    
  }, []);

  useEffect(() => {
    if (registerFarmInstance) {
      getFarmDetails();
    }
  }, [registerFarmInstance]);

  const statusArray = [
    "Inactive",
    "Active",
    "Completed",
    "Paused",
    "Skipped",
    "Terminated",
  ];

  const [farmDetailsInfo, setFarmDetailsInfo] = useState<any>({});
  const getFarmDetails = async () => {
    const basicDetail = await registerFarmInstance?.getBasicDetails(Number(id));
    const advanceDetail = await registerFarmInstance?.getAdvanceDetails(
      Number(id)
    );

    if (basicDetail && advanceDetail) {
      if (account.toLocaleLowerCase() === advanceDetail.manager.toLocaleLowerCase()) {
        setIsManager(true);
      }
      const pahseDetails: any = await getPhaseDetails(advanceDetail?.maxPhase);
      const phaseData1 = pahseDetails.map((details: any) => {
        return {
          priority: Number(details.priority),
          name: details.name,
          detail: details.detail,
          status: [Number(details.status)],
        };
      });
      const rewardFarmInst = new RewardFarmService(advanceDetail.farmAddress);
        const intervalId = setInterval(() => {
          if (
            rewardFarmInst.rewardFarmInstance.contractInstance != null &&
            rewardFarmInst.rewardFarmInstance.contractInstance != undefined
          ) {
            setRewardFarmService(rewardFarmInst);
            clearInterval(intervalId);
          }
        }, 100);
        
      
      setPhasesData(phaseData1);
      const farmDatils = {
        name: basicDetail.name,
        description: basicDetail.description,
        investmentRequired: basicDetail.investmentRequired,
        investmentTokenInvestment: basicDetail.investmentTokenInvestment,
        status: advanceDetail.status,
        maxPhase: advanceDetail.maxPhase,
        manager: advanceDetail.manager,
        totalInvestment: advanceDetail.totalInvestment,
        apy: advanceDetail.apy,
        farmAddress: advanceDetail.farmAddress,
        supportedToken: "VOEUX",
        rewardToken: "VOEUX",
      };
      setFarmDetail( farmDatils );
      setFarmDetailsInfo({ ...farmDatils });

      handleShowLoader(false);
    }
  };

  useEffect(() => {
    const fetchFarmData = async()=>{

      if (rewardFarmService?.rewardFarmInstance?.contractInstance != null){
        const duration = await rewardFarmService.getFarmDuration();
        const rewardRate = await rewardFarmService.getRewardRate();
        const convertedRewardRate = Number(rewardRate) / 10 ** 18;
        console.log("fetched reward rate and duration: " + rewardRate, duration,convertedRewardRate);
        setFarmDetailsInfo({...farmDetailsInfo, duration, rewardRate:convertedRewardRate});
      }
    }
    fetchFarmData(); 
  },[rewardFarmService]);

  const getPhaseDetails = async (maxPhase: any) => {
    const numberOfPhases = Number(maxPhase) || 0;
    const phasePromises = Array.from(
      { length: numberOfPhases },
      async (_, index) => {
        const phasedata: any = await registerFarmInstance?.getPhaseDetails(
          Number(id),
          index
        );

        return phasedata;
      }
    );
    const phasesData: any = await Promise.all(phasePromises);
    console.log("phasesdata at fetch time: ", phasesData);
    if (phasesData.length === 0) {
      return [{ name: "", priority: 0, detail: "", status: "" }];
    }
    return phasesData;
  };
  const handleUpdateFarm = async () => {
    handleShowLoader(true);
    console.log("handleUpdateFarm");
    // await updateDurationAndRewardRate();
    const totalPhasesData = [...phasesData, ...addnewPhases];
    totalPhasesData.map((x) => (x.status = statusArray[x.status]));
    try {
      const updateFarm = {
        farmId: Number(id),
        description: farmDetailsInfo?.description || "this is second farm",
        investmentRequired: Number(farmDetailsInfo?.investmentRequired) || 10,
        phases: totalPhasesData,
        apy: Number(farmDetailsInfo?.apy) || 10,
      };
      const updateFarmResponse = await registerFarmInstance?.updateFarm(
        updateFarm
      );
      console.log("updateFarmResponse:", updateFarmResponse);
      
      const apiData = {
        farmDescription: farmDetailsInfo?.description,
        farmApy: Number(farmDetailsInfo?.apy),
        investmentRequired: Number(farmDetailsInfo?.investmentRequired),
        maxPhase: totalPhasesData.length,
        // farmId:Number(id),
        blockChainfarmId: Number(id),
        phases: totalPhasesData,
      };
      console.log("apiData", apiData);
      const response = await axios.put(
        `${Environment.metavoeuxBackend}farm`,
        apiData
      );
      console.log("update the phases data", response.data);
      handleShowLoader(false);
      showToastMessageClick("Farm has been updated");
    } catch (err) {
      handleShowLoader(false);
      showToastErrorMessageClick("Error in creating Farm");
      throw err;
    }
  };
  const updateDuration = async()=>{

    try{
        const updatedDuration = await rewardFarmService?.setRewardsDuration(Number(farmDetailsInfo.duration));
      
    }catch (err) {
      throw err;
    }

  }
  const updateRewardRate = async()=>{

    try{
      if(Number(farmDetailsInfo.rewardRate)){
        const updatedRewardRate = await rewardFarmService?.setRewardRate(Number(farmDetailsInfo.rewardRate));
        console.log("updated reward rate:", updatedRewardRate);
      }
    }catch (err) {
      throw err;
    }

  }
  const [seelctedDropdownIndex, setSelectedDropdownIndex] = useState(1);
  const [seelctedDropdownText, setSelectedDropdownText] = useState("Phase 1");
  const handleCollectionDropdown = (e: any) => {
    console.log("dropdown value", e.target.value);
    setSelectedDropdownIndex(e.target.value);
    setSelectedDropdownText("Phase " + e.target.value);
    console.log("seelctedDropdownText", seelctedDropdownText);
  };

  const handlePhasesChnage = (index: any, type: any, value: any) => {
    setSelectedDropdownIndex(index + 1);
    if (type === "priority") {
      phasesData[index].priority = Number(value);
    }
    if (type === "name") {
      phasesData[index].name = value;
    }
    if (type === "detail") {
      phasesData[index].detail = value;
    }
    if (type === "status") {
      phasesData[index].status = value;
    }
    setPhasesData([...phasesData]);
  };

  const [addnewPhases, setAddNewPhases] = useState<any>([]);
  const [addPhaseCount, setAddPhaseCount] = useState<any>(phasesData);

  const addNewPhase = () => {
    const newArray = [...addnewPhases];
    // setAddPhaseCount(Number(addPhaseCount) + 1);
    newArray.push({ priority: "", name: "", detail: "", status: "0" });
    console.log("newArray", newArray);
    setAddNewPhases([...newArray]);
  };

  useEffect(() => {}, [addnewPhases]);
  {
    console.log("farmDetailsInfo", farmDetailsInfo, phasesData);
  }

  const [showLoader, setShowLoader] = useState(false);

  const handleShowLoader = (active: any) => {
    setShowLoader(active);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, settoastMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (data: string) => {
    setShowToastMessage(true);
    settoastMessage(data);
  };

  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  return (
    <>
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />

      {/* <Box p={4}>
        <NewNftHeader />
      </Box> */}
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100vh",
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}

      <Box
        // p={4}
        sx={{
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          px: { xs: 1, sm: 3, md: 5 },
          "@media (max-width: 600px)": {
            padding: "0 8px ",
          },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <NewNftHeader />
        <Box
          sx={{
            ...circleStyle,
            top: { md: "10%" },
            transform: "translate(-50%, -50%)",
            width: "447.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter:"blur(120px)",
            WebkitBackdropFilter:"blur(120px)",  
            // leftcorner
          }}
        ></Box>
        <Box
          sx={{
            ...circleStyle,
            top: { md: "10%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "80%", md: "90%" },
            transform: "translate( -50%)",
            width: "300.625px",
            height: "187.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(140px)",
            backdropFilter:"blur(140px)",
            WebkitBackdropFilter:"blur(140px)",  
            right: "0%",
            // righttop
          }}
        ></Box>

        <Box
          sx={{
            ...circleStyle,
            top: { md: "231%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "80%", md: "90%" },
            right: { xs: "0%", md: "0%" },
            transform: "translate( -50%)",
            width: "300.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter:"blur(120px)",
            WebkitBackdropFilter:"blur(120px)",  
            // rightsecond
          }}
        ></Box>
         <Box sx={{
          "@media (max-width: 600px)": {
            padding: "0 10px ",
          }
         }}>
        <Box sx={{ display: "flex" }}>
          {" "}
          <img src={Arrow} alt="" onClick={() => handleBack()} />
          <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
        </Box>

        <Box
          height="30%"
          width={"100%"}
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={"10px"}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection={"column"}
            sx={{
              width: "100%",
              background:
                "linear-gradient(267deg, #ff990d -3.12%, #9d00ff 93.15%)",
              borderRadius: 4,
              p: "20px 0",
              marginBottom: "20px",
            }}

            // py="2%"
          >
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontSize: { md: "32px", xs: "24px" },
                fontWeight: 600,
                fontFamily: "outfit",
              }}
            >
              Update Farm
            </Typography>
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",

                fontSize: {
                  xs: 16,
                  md: 24,
                },
                fontWeight: 300,
                width: {
                  xs: "80%",
                  md: "50%",
                },
                mb: "1%",
                fontFamily: "outfit",
              }}
            >
              Metavoeux
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            rowGap: "30px",
            columnGap: "30px",
            marginBottom: "20px",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: "flexStart",
          }}
        >
          <Box
            sx={{
              width: { xs: "auto", md: "422.564px" },

              borderRadius: "28.422px",
              background: "#28272C",

              padding: "20px 25px",
            }}
          >
            <Typography
              sx={{
                fontSize:12,
                marginTop:"16px",
                fontFamily: "outfit",
                paddingLeft:"5px",
                fontWeight: 200,
              }}
              >Investment Required</Typography> 
            <Box className="textArea">
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder=" Enter Description"
                name="description"
                value={farmDetailsInfo.description}
                style={{
                  border: "1px solid #777",
                  borderRadius: "12px",
                  color: "#828282",
                  width: "100%",
                  background: "none",
                  height: "120px",
                  padding: "15px",
                }}
                required
                onChange={(e) => (
                  (farmDetailsInfo.description = e.target.value),
                  setFarmDetailsInfo({ ...farmDetailsInfo })
                )}
              />
            </Box>
            <Typography
              sx={{
                fontSize:12,
                marginTop:"16px",
                fontFamily: "outfit",
                paddingLeft:"5px",
                fontWeight: 200,
              }}
              >Investment Required</Typography>
            <Box>
              <TextField
                type="text"
                name="investmentRequired"
                placeholder="Investment Required"
                required
                inputProps={{ style: { color: "#777" } }}
                value={Number(farmDetailsInfo.investmentRequired)}
                sx={{
                  border: "1px solid #777",
                  borderRadius: "12px",
                  color: "#828282",
                  width: "100%",
                  marginTop: "4px",
                }}
                onChange={(e) => (
                  (farmDetailsInfo.investmentRequired = e.target.value),
                  setFarmDetailsInfo({ ...farmDetailsInfo })
                )}
              />
            </Box>
            <Typography
              sx={{
                fontSize:12,
                marginTop:"16px",
                fontFamily: "outfit",
                paddingLeft:"5px",
                fontWeight: 200,
              }}
              >APY</Typography>
            <Box>
              <TextField
                type="text"
                name="apy"
                placeholder="APY"
                required
                inputProps={{ style: { color: "#777" } }}
                value={Number(farmDetailsInfo.apy)}
                sx={{
                  border: "1px solid #777",
                  borderRadius: "12px",
                  color: "#828282",
                  width: "100%",
                  marginTop: "4px",
                }}
                onChange={(e) => (
                  (farmDetailsInfo.apy = e.target.value),
                  setFarmDetailsInfo({ ...farmDetailsInfo })
                )}
              />
            </Box>
            
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "622.564px" },

              borderRadius: "28.422px",
              background: "#28272C",

              padding: "20px 25px",
            }}
          >
            <Box sx={{ width: { xs: "auto" } }}>
              <Typography
                sx={{
                  color: "#F6EBFD",
                  fontSize: { xs: "20px", md: "20px" },
                  fontWeight: 500,
                  fontFamily: "outfit",
                }}
              >
                No of Phases
              </Typography>
              <Box sx={{
                display:"flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "5px",
               
              }}>
              <Select
                placeholder="Select Phase"
                required
                sx={{
                  border: "1px solid #777",
                  borderRadius: "12px",
                  color: "#828282",
                  width: "60%",
                }}
                className="customDropdown"
                value={seelctedDropdownIndex}
                onChange={(e) => handleCollectionDropdown(e)}
              >
                {phasesData?.length > 0 &&
                  phasesData.map((phase: any, index: any) => {
                    return (
                      <MenuItem id={index} value={index + 1}>
                        Phase {index + 1}
                      </MenuItem>
                    );
                  })}
              </Select>
              <Box width={"30%"}>
                <Button
                  onClick={() => addNewPhase()}
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 2,
                    gap: "5px",
                    background: "#F2F2F2",
                    border: " 1px solid #F2F2F2",
                    color: "black",
                    width: "100%",
                    marginTop: "0",
                    "&:hover": {
                      background: "#F2F2F2",
                      color: "black",
                    },
                  }}
                >
                  <AddCircleOutlineIcon />
                  Add New Phase
                </Button>
                {/* <Button onClick={() => addNewPhase()}>Add New Phase</Button> */}
              </Box>
                
              </Box>
              <Typography
              sx={{
                fontSize:12,
                marginTop:"8px",
                fontFamily: "outfit",
                paddingLeft:"5px",
                fontWeight: 200,
              }}
              >Duration</Typography>
              <Box sx={{
                display:"flex",
                alignItems: "center",
                justifyContent: "space-between",
                // border: "1px solid white",
                padding: "5px",
              }}>
              <TextField
                type="number"
                name="duration"
                placeholder="Enter duration"
                required
                inputProps={{ style: { color: "#777" } }}
                value={Number(farmDetailsInfo.duration)  }
                sx={{
                  border: "1px solid #777",
                  borderRadius: "12px",
                  color: "#828282",
                  width: "60%",
                  marginTop: "0",
                }}
                onChange={(e) => (
                  (farmDetailsInfo.duration = e.target.value),
                  setFarmDetailsInfo({ ...farmDetailsInfo })
                )}
              />
              <Box width={"30%"}>
                <Button
                  onClick={updateDuration}
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 2,
                    gap: "5px",
                    background: "#F2F2F2",
                    border: " 1px solid #F2F2F2",
                    color: "black",
                    width: "100%",
                    marginTop: "0",
                    "&:hover": {
                      background: "#F2F2F2",
                      color: "black",
                    },
                  }}
                >
                  {/* <AddCircleOutlineIcon /> */}
                  Set Duration
                </Button>
                {/* <Button onClick={() => addNewPhase()}>Add New Phase</Button> */}
              </Box>
              </Box>
              <Typography
              sx={{
                fontSize:12,
                fontWeight: 200,
                marginTop:"8px",
                paddingLeft:"5px",   
                fontFamily: "outfit",          }}
              >Reward Rate</Typography>
              <Box sx={{
                display:"flex",
                alignItems: "center",
                justifyContent: "space-between",
                // border: "1px solid white",
                padding: "5px",
              }}>
              <TextField
                type="text"
                name="duration"
                placeholder="Enter duration"
                required
                inputProps={{ style: { color: "#777" } }}
                value={Number(farmDetailsInfo.rewardRate) }
                sx={{
                  border: "1px solid #777",
                  borderRadius: "12px",
                  color: "#828282",
                  width: "60%",
                  marginTop: "0",
                }}
                onChange={(e) => (
                  (farmDetailsInfo.rewardRate = e.target.value),
                  setFarmDetailsInfo({ ...farmDetailsInfo })
                )}
              />
              <Box width={"30%"}>
                <Button
                  onClick={updateRewardRate}
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 2,
                    gap: "5px",
                    background: "#F2F2F2",
                    border: " 1px solid #F2F2F2",
                    color: "black",
                    width: "100%",
                    marginTop: "0",
                    "&:hover": {
                      background: "#F2F2F2",
                      color: "black",
                    },
                  }}
                >
                  {/* <AddCircleOutlineIcon /> */}
                  Set Reward Rate
                </Button>
                {/* <Button onClick={() => addNewPhase()}>Add New Phase</Button> */}
              </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              md: "repeat(auto-fill, minmax(400.564px, 1fr))",
              xs: "repeat(1, 100%)",
            },
            rowGap: "20px",
            columnGap: "20px",
          }}
        >
          {/* {phasesData.length > 0  */}
          {true && (
            <form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "flexStart",
                  alignItems: "center",
                  // marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", md: "422.564px" },
                    // height: "450.385px",
                    borderRadius: "28.422px",
                    background: "#28272C",
                    padding: "20px 25px",
                  }}
                >
                  <Typography sx={{ fontFamily: "outfit", fontSize: "24px" }}>
                    {" "}
                    Phases
                  </Typography>
                  <Typography sx={{ fontFamily: "outfit", fontSize: "18px" }}>
                    {" "}
                    Phase {seelctedDropdownIndex}
                  </Typography>

                  <Typography
              sx={{
                fontSize:12,
                marginTop:"16px",
                fontFamily: "outfit",
                paddingLeft:"5px",
                fontWeight: 200,
              }}
              >Priority</Typography>
                  <Box>
                    <TextField
                      type="text"
                      name="priority"
                      placeholder="Enter  priority"
                      required
                      inputProps={{ style: { color: "#777" } }}
                      sx={{
                        border: "1px solid #777",
                        borderRadius: "12px",
                        color: "#828282",
                        width: "100%",
                        marginTop: "4px",
                      }}
                      value={Number(
                        phasesData[seelctedDropdownIndex - 1]?.priority
                      )}
                      onChange={(e) =>
                        handlePhasesChnage(
                          seelctedDropdownIndex - 1,
                          "priority",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  <Typography
              sx={{
                fontSize:12,
                marginTop:"16px",
                fontFamily: "outfit",
                paddingLeft:"5px",
                fontWeight: 200,
              }}
              >Name</Typography>
                  <Box>
                    <TextField
                      type="text"
                      name="phaseName"
                      placeholder="Enter  Name"
                      required
                      inputProps={{ style: { color: "#777" } }}
                      sx={{
                        border: "1px solid #777",
                        borderRadius: "12px",
                        color: "#828282",
                        width: "100%",
                        marginTop: "4px",
                      }}
                      value={phasesData[seelctedDropdownIndex - 1]?.name}
                      onChange={(e) =>
                        handlePhasesChnage(
                          seelctedDropdownIndex - 1,
                          "name",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  <Typography
              sx={{
                fontSize:12,
                marginTop:"16px",
                fontFamily: "outfit",
                paddingLeft:"5px",
                fontWeight: 200,
              }}
              >Detail</Typography>
                  <Box>
                    <TextField
                      type="text"
                      name="detail"
                      placeholder="Enter  Details"
                      required
                      inputProps={{ style: { color: "#777" } }}
                      sx={{
                        border: "1px solid #777",
                        borderRadius: "12px",
                        color: "#828282",
                        width: "100%",
                        marginTop: "4px",
                      }}
                      value={phasesData[seelctedDropdownIndex - 1]?.detail}
                      onChange={(e) =>
                        handlePhasesChnage(
                          seelctedDropdownIndex - 1,
                          "detail",
                          e.target.value
                        )
                      }
                    />
                  </Box>
                  <Typography
              sx={{
                fontSize:12,
                marginTop:"16px",
                fontFamily: "outfit",
                paddingLeft:"5px",
                fontWeight: 200,
              }}
              >Status</Typography>
                  <Box>
                    <Select
                      name="status"
                      placeholder="Status"
                      sx={{
                        border: "1px solid #777",
                        borderRadius: "12px",
                        color: "#828282",
                        width: "100%",
                        marginTop: "4px",
                      }}
                      className="customDropdown"
                      value={Number(
                        phasesData[seelctedDropdownIndex - 1]?.status
                      )}
                      onChange={(e) =>
                        handlePhasesChnage(
                          seelctedDropdownIndex - 1,
                          "status",
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value="0">Inactive</MenuItem>
                      <MenuItem value="1"> Active</MenuItem>
                      <MenuItem value="2">Completed</MenuItem>
                      <MenuItem value="3"> Paused</MenuItem>
                      <MenuItem value="4">Skipped</MenuItem>
                      <MenuItem value="5"> Terminated</MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Box>
            </form>
          )}

          {addnewPhases.map((phases: any, index: any) => (
            <form>
              {/* first row */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "flexStart",
                  alignItems: "center",

                    // marginBottom: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", md: "422.564px" },
                      // height: "450.385px",
                      borderRadius: "28.422px",
                      background: "#28272C",
                      padding: "20px 25px",
                    }}
                  >
                    <Typography sx={{ fontFamily: "outfit", fontSize: "24px" }}>
                      {" "}
                      Phases
                    </Typography>
                    <Typography sx={{ fontFamily: "outfit", fontSize: "18px" }}>
                      {" "}
                      Phase {Number(phasesData.length + 1) + Number(index)}
                    </Typography>
                    <Box>
                      <Box>
                        <Typography
                          sx={{
                            fontFamily: "outfit",
                            fontSize: "14px",
                            fontWeight: 200,
                            marginBottom: "5px",
                          }}
                        >
                          Enter Priority
                        </Typography>
                      </Box>
                      <TextField
                        type="number"
                        name="priority"
                        required
                        inputProps={{ style: { color: "#777" } }}
                        sx={{
                          border: "1px solid #777",
                          borderRadius: "12px",
                          color: "#828282",
                          width: "100%",

                          background: "transparent",
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none",
                            },
                        }}
                        value={Number(addnewPhases[index].priority)}
                        onChange={(e) => (
                          (addnewPhases[index].priority = Number(
                            e.target.value
                          )),
                          setAddNewPhases([...addnewPhases])
                        )}
                        //  onChange={(e) =>
                        //    handlePhasesChnage(seelctedDropdownIndex-1,"priority",e.target.value)
                        //  }
                      />
                    </Box>
                    <Box>
                      <Box sx={{ marginTop: "14px" }}>
                        <Typography
                          sx={{
                            fontFamily: "outfit",
                            fontSize: "14px",
                            fontWeight: 200,
                            marginBottom: "5px",
                          }}
                        >
                          Enter Name
                        </Typography>
                      </Box>
                      <TextField
                        type="text"
                        name="phaseName"
                        required
                        inputProps={{ style: { color: "#777" } }}
                        sx={{
                          border: "1px solid #777",
                          borderRadius: "12px",
                          color: "#828282",
                          width: "100%",

                          background: "transparent",
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none",
                            },
                        }}
                        value={phases?.name}
                        onChange={(e) => (
                          (addnewPhases[index].name = e.target.value),
                          setAddNewPhases([...addnewPhases])
                        )}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ marginTop: "14px" }}>
                        <Typography
                          sx={{
                            fontFamily: "outfit",
                            fontSize: "14px",
                            fontWeight: 200,
                            marginBottom: "5px",
                          }}
                        >
                          Enter Detail
                        </Typography>
                      </Box>
                      <TextField
                        type="text"
                        name="detail"
                        required
                        inputProps={{ style: { color: "#777" } }}
                        sx={{
                          border: "1px solid #777",
                          borderRadius: "12px",
                          color: "#828282",
                          width: "100%",

                          background: "transparent",
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none",
                            },
                        }}
                        value={phases?.detail}
                        onChange={(e) => (
                          (addnewPhases[index].detail = e.target.value),
                          setAddNewPhases([...addnewPhases])
                        )}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ marginTop: "14px" }}>
                        <Typography
                          sx={{
                            fontFamily: "outfit",
                            fontSize: "14px",
                            fontWeight: 200,
                            marginBottom: "5px",
                          }}
                        >
                          Status
                        </Typography>
                      </Box>
                      <Select
                        name="status"
                        placeholder="Status"
                        sx={{
                          border: "1px solid #777",
                          borderRadius: "12px",
                          color: "#828282",
                          width: "100%",
                        }}
                        className="customDropdown"
                        value={Number(phases?.status)}
                        onChange={(e) => (
                          (addnewPhases[index].status = e.target.value),
                          setAddNewPhases([...addnewPhases])
                        )}
                      >
                        <MenuItem value="0">Inactive</MenuItem>
                        <MenuItem value="1"> Active</MenuItem>
                        <MenuItem value="2">Completed</MenuItem>
                        <MenuItem value="3"> Paused</MenuItem>
                        <MenuItem value="4">Skipped</MenuItem>
                        <MenuItem value="5"> Terminated</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                </Box>
                {/* first row ended */}
              </form>
            ))}
          </Box>
          <Box
            sx={{
              ...circleStyle,
              top: "298%",
              display: { xs: "flex", md: "none" },
              left: "80%",
              right: "0%",
              transform: "translate(-50%)",
              width: "126.625px",
              height: "147.734px",
              borderRadius: "547.625px",
              background: "#9D00FF",
              filter: "blur(120px)",
              backdropFilter: "blur(120px)",
              WebkitBackdropFilter: "blur(120px)",
              bottom: "0%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              style={{
                fontSize: "20px",
                fontFamily: "outfit",
                width: "203.307px",
                height: "39.894px",
                borderRadius: "10px",
                background: "transparent",
                color: "white",
                textTransform: "capitalize",
                border: "0.977px solid var(--Linear, #FF990D)",
              }}
              onClick={() => handleUpdateFarm()}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
      <LandingPageFooter />
    </>
  );
}

export default UpdateFarm;
