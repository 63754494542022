import { Box } from "@mui/system";
import { useState } from "react";
import {
  Button,
  TextField,
  Typography,
} from "@mui/material";
import VoeuxGameHeader from "../../components/voeuxGame/VoeuxGameHeader";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import VoeuxFooter from "../../components/voeuxGame/VoeuxFooter";
import forgotPass from "../../assets/VoeuxGame/forgotPass.png";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import { Environment } from "../../../src/environments";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

import axios from "axios";

function ForgotPassword() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  // const resetPassword = () => {
  //   navigate("/resetPassword");
  // };
  const [email, setEmail] = useState<any>("");

  const handleForgotPassword = async () => {
    setShowLoader(true);

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!email.match(mailformat)) {
      showToastErrorMessageClick("Please Enter Valid Email Address");
      setShowLoader(false);
      return false;
    }
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}game/recovery-email?email=${email}`
      );
      console.log("response", response);
      setShowLoader(false);
      showToastMessageClick(
        `Successfully send a reset link on this ${email}  `
      );
      setTimeout(() => {
        navigate("/user/signin");
      }, 2000);
    } catch (error: any) {
      showToastErrorMessageClick("Error in reset the password");
      setShowLoader(false);
    }
  };
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  const [showLoader, setShowLoader] = useState(false);
  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1400,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <>
        <ToastMessage
          text={toastMessage}
          display={showToastMessage}
          handleClose={closeToastMessage}
        />
        <ToastMessageFailure
          text={toasterrorMessage}
          display={showToastErrorMessage}
          handleClose={closeToastErrorMessage}
        />
        <Box
          sx={{
            background: "black",
            width: "100%",
            minHeight: "100vh",
            px: { xs: 1, sm: 3, md: 5 },
            // mt: { lg: 3 },
            pt: 0,
            "@media (min-width: 1721px)": {
              padding: "0% 10% 0% 10%",
            },
            overflow: "hidden",
            position: "relative",
            zIndex: 0,
          }}
        >
          <VoeuxGameHeader />
          <Box
            sx={{
              ...circleStyle,
              display: { xs: "flex", sm: "flex", md: "flex" },
              top: 0,
              right: 0,
              left: "0",
              margin: "auto",
              bottom: "0px",
              // transform: "translateY(37%)",
              width: "60%", // Adjusted width
              height: "50%", // Adjusted height
              borderRadius: "100%", // Ensures it remains a perfect circle
              background: ` #464646`,
              filter: "blur(114px)",
              backdropFilter: { md: "none", xs: "blur(114px)" },
              WebkitBackdropFilter: { md: "none", xs: "blur(114px)" },
              overflow: "hidden",
            }}
          ></Box>
          <Box
            sx={{
              ...circleStyle,
              display: { xs: "flex", sm: "flex", md: "flex" },
              top: 0,
              right: 0,
              left: "0",
              margin: "auto",
              bottom: "0px",
              // transform: "translateY(37%)",
              width: "60%", // Adjusted width
              height: "50%", // Adjusted height
              borderRadius: "100%", // Ensures it remains a perfect circle
              background: ` #464646`,
              filter: "blur(114px)",
              backdropFilter: { md: "none", xs: "blur(114px)" },
              WebkitBackdropFilter: { md: "none", xs: "blur(114px)" },
              overflow: "hidden",
            }}
          ></Box>
          <Box
            sx={{
              position: "relative",
              "@media (max-width: 600px)": {
                padding: "0% 10px",
              },
              // background:"radial-gradient(50% 49.66% at 50% 49.66%, #464646 45.28%, #000 100%)"
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginBottom: "10px",
                cursor: "pointer",
                color: "#ECE218",
              }}
            >
              <ArrowBackIosIcon
                className="arrowBack"
                onClick={() => handleBack()}
              />
              <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
            </Box>
            <Box
              sx={{
                ...circleStyle,
                display: { xs: "flex", sm: "flex", md: "flex" },
                top: 0,
                margin: "auto",
                left: "0",
                right: "0%",
                bottom: "0px",
                // transform: "translateY(37%)",
                width: "535px", // Adjusted width
                height: "172px", // Adjusted height
                borderRadius: "100%", // Ensures it remains a perfect circle
                background: ` #464646`,
                filter: "blur(145px)",
                backdropFilter: { md: "none", xs: "blur(145px)" },
                WebkitBackdropFilter: { md: "none", xs: "blur(145px)" },
                overflow: "hidden",
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { md: "row", xs: "column" },
                rowGap: "30px",
                // alignItems: "center",
                columnGap: "100px",
                marginBottom: "40px",
              }}
            >
              {/* userProfile section */}
              <Box
                //   className="polygonStyle2"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // background: "#181818",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  // backdropFilter: "blur(10.781231880187988px)",
                  width: { xs: "100%", md: "70%" },
                  height: "auto",
                  // height: { md: "364px", xs: "300px" },
                  gap: "20px",
                  textAlign: "center",
                }}
              >
                <img
                  draggable="false"
                  src={forgotPass}
                  alt=""
                  width="inherit"
                  style={{ width: "inherit" }}
                />
              </Box>
              {/* forgot session */}
              <Box
                className="polygonStyle2"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  background: "#131313",

                  backdropFilter: "blur(10.781231880187988px)",
                  width: { md: "751px", xs: "100%" },
                  height: { md: "460px", xs: "400px" },
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  px: { xs: "20px" },
                }}
              >
                <Box
                  sx={{
                    ...circleStyle,
                    display: { xs: "flex", sm: "flex", md: "flex" },
                    top: 0,
                    margin: "auto",
                    left: "0",
                    right: "0%",
                    bottom: "0px",
                    // transform: "translateY(37%)",
                    width: "535px", // Adjusted width
                    height: "172px", // Adjusted height
                    borderRadius: "100%", // Ensures it remains a perfect circle
                    background: ` #ECE218`,
                    filter: "blur(145px)",
                    backdropFilter: { md: "none", xs: "blur(145px)" },
                    WebkitBackdropFilter: { md: "none", xs: "blur(145px)" },
                    overflow: "hidden",
                  }}
                ></Box>
                <Typography
                  className="blendarPro"
                  sx={{
                    color: " #ECE218",
                    textAlign: "center",
                    fontSize: { xs: "22px", md: "36px" },

                    fontWeight: "700",
                    lineHeight: "normal",
                    letterSpacing: "-0.72px",
                    textTransform: "uppercase",
                    marginBottom: "32px",
                    marginTop: "7%",
                  }}
                >
                  forgot password?
                </Typography>

                <Box
                  className=" inputpolygonStyle"
                  sx={{
                    border: `1px solid #818181`,
                    // padding: "2px",

                    width: { md: "auto", xs: "inherit" },
                    background: `transparent`,
                    position: "relative",
                    marginBottom: "32px",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(52deg)",
                      top: "-12px",
                      left: "8px",
                    }}
                  ></Box>
                  <TextField
                    sx={{
                      // height: "40px",
                      width: { md: "448px", xs: "100%" },

                      "& input": {
                        outline: "none",
                        border: "0.65px solid #818181",
                        color: "white",
                        background: "transparent",
                        paddingRight: "30px",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                    type="textfield"
                    id="text"
                    placeholder="Enter Email address"
                    variant="outlined"
                    onChange={(e: any) => setEmail(e.target.value)}
                  />
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(60deg)",
                      bottom: "-12px",
                      right: "3px",
                    }}
                  ></Box>
                </Box>
                <Box
                  className="inputpolygonStyle BlenderProBold"
                  sx={{
                    border: `1px solid #D2C915`,
                    padding: "2px",
                    width: { md: "448px", xs: "100%" },
                    // width: "448px",
                    background: `#D2C915`,
                    textAlign: "center",
                  }}
                >
                  <Button
                    className="BlenderProBold"
                    sx={{
                      borderColor: "white",
                      padding: "10px 16px",
                      color: "#000",
                      fontSize: { xs: "17px", md: "20px" },
                      "&:hover": {
                        background: `#D2C915`,
                        color: "#000",
                      },
                    }}
                    onClick={() => handleForgotPassword()}
                  >
                    Send Link
                  </Button>
                </Box>
                {/* <Box className="BlenderProBold">
                {" "}
                <Typography
                  sx={{
                    marginTop: "20px",
                    color: "#ECE218",
                    fontSize: "20px",
                    fontFamily: "BlenderProBold",
                    cursor: "pointer",
                  }}
                  onClick={resetPassword}
                >
                  {" "}
                  RESET PASSWORD
                </Typography>
              </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <VoeuxFooter />
        </Box>
      </>
    </>
  );
}

export default ForgotPassword;
