import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import NftCollectionCard from "../../pages/nftLanding/NftCollectionCard";

import distantgalaxy from "../../assets/images/distantgalaxy.png";

const NftWatchList = () => {
  const collectionList = [
    {
      id: 1,
      creatorimg: distantgalaxy,
      category: "art",
      nftTitle: "Distant Galaxy",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "buyNow",
      type: "ERC721",
    },
    {
      id: 2,
      creatorimg: distantgalaxy,
      category: "art",
      nftTitle: "Life On Edena",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "buyNow",
      type: "ERC721",
    },
  ];
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: "32px",
            fontFamily: "outfit",
            fontWeight: 700,
            marginBottom: "1rem",
          }}
        >
          {" "}
          WatchList
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fit, 100%)",
              lg: "repeat(auto-fit, 276px)",
              
            },
            rowGap: "15px",
            columnGap: "15px",
          }}
        >
          <NftCollectionCard collectionList={collectionList} />
        </Box>
      </Box>
    </>
  );
};

export default NftWatchList;
