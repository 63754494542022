import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import NewNftHeader from "../components/newNftHeader/NewNftHeader";
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OutlinedCard from "./component/ExploreFarm/CardFarm";
import StakingFarmBanner from "../assets/images/StakingFarmBanner.png";
import LandingPageFooter from "../components/landingPageFooter/LandingPageFooter";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { useNavigate } from "react-router-dom";
import { RegisterFarmService } from "src/services/registerFarmService";
import { BasicFarmDetails } from "src/domains/basicFarmDetails.dto";
import farmLogo from "../assets/svgs/farmLogo.svg";
import networkLogo from "../assets/svgs/networkLogo.svg";
import voeuxTokenLogo from "../assets/svgs/voeuxTokenLogo.svg";
import MuiWalletConnect from "../components/muiconnectwallet/Muiconnect";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent";

const ExploreFarm = () => {
  const balance = useSelector((state: RootState) => state.wallet.balance);
  const navigate = useNavigate();
  const [registerFarmInstance, setRegisterFarmInstance] = useState(
    new RegisterFarmService()
  );
  const [farmLevels, setFarmLevels] = useState(0);
  const [currentFarmId, setCurrentFarmId] = useState(0);
  const [farmCards, setFarmCards] = useState<any>([]);
  const [farmAdvCards, setFarmAdvCards] = useState<any>([]);
  const [farmListData, setFarmListData] = useState<any>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentWalletAddress, setCurrentWalletAddress] = useState(
    localStorage.getItem("account_address")
  );

  useEffect(() => {
    console.log(" ===in first useEffect ===");
    const farmInst = new RegisterFarmService();
    const intervalId = setInterval(() => {
      try {
        if (
          farmInst?.registerFarmInstance?.contractInstance != null &&
          farmInst?.registerFarmInstance?.contractInstance != undefined
        ) {
          setRegisterFarmInstance(farmInst);
          clearInterval(intervalId);
        }
      } catch (error) {
        console.log("Please connect to met mask");
      }
    }, 100);
  }, []);

  useEffect(() => {
    console.log("in second useEffect ==> after getting register farm instance");
    const fetchData = async () => {
      try {
        // let level:number|undefined=undefined;
        const level = await registerFarmInstance?.getLevels();
        console.log("fetched leves ===>", level);
        if (level) {
          setFarmLevels(parseInt(level.toString(), 10));
        }
        const admin = await registerFarmInstance?.getAdminAddress();
        if (admin.toLowerCase() === currentWalletAddress?.toLowerCase()) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
        console.log("admin", admin, isAdmin, currentWalletAddress);
      } catch (error) {
        console.error("Error fetching farm count:", error);
      }
      console.log("in fetch data");
    };

    const timerId = setTimeout(fetchData, 1000);

    return () => clearTimeout(timerId);
  }, [registerFarmInstance]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);
  useEffect(() => {
    handleShowLoader(true);
    if (farmLevels > 0) {
      getFarmBasicDetails();
    } else {
      handleShowLoader(false);
    }
  }, [farmLevels]);

  const getFarmBasicDetails = async () => {
    const numOfFarmsToBeFetched = Math.min(10, farmLevels - currentFarmId);
    const detailsPromises = Array.from(
      { length: numOfFarmsToBeFetched },
      async (_, index) => {
        const details = await registerFarmInstance?.getBasicDetails(
          currentFarmId + index
        );

        setCurrentFarmId((prev: any) => prev + 1);
        console.log("fetched details of Farm", currentFarmId + index, details);
        return details;
      }
    );

    const advanceDetails = Array.from(
      { length: numOfFarmsToBeFetched },
      async (_, index) => {
        const advance_details = await registerFarmInstance?.getAdvanceDetails(
          currentFarmId + index
        );
        console.log(
          "fetched details of Farm",
          currentFarmId + index,
          advance_details
        );
        // const mergedObject =[details,advance_details]
        return advance_details;
      }
    );

    try {
      const farmDetails = await Promise.all(detailsPromises);
      console.log("farmDetails", farmDetails);
      const farmAdvDetails = await Promise.all(advanceDetails);
      console.log("farmDetails", farmAdvDetails);
      const updatedFarmCards = [...farmDetails, ...farmCards];
      setFarmCards(updatedFarmCards.filter(Boolean));
      setFarmAdvCards(farmAdvDetails.filter(Boolean));
      console.log("fetched cards ===>", updatedFarmCards);
      handleShowLoader(false);
    } catch (error) {
      console.error("Error fetching farm details:", error);
      handleShowLoader(false);
    }
  };

  useEffect(() => {
    const data = farmCards.map((card: any, index: any) => {
      return {
        token: {
          icon: voeuxTokenLogo,
          name: "Voeux",
        },
        network: {
          icon: networkLogo,
          name: "Polygon",
        },
        name: card.name,
        description: card.description,
        apy: farmAdvCards[index]?.apy,
        investmentRequired: Number(card.investmentRequired || 0),
      };
    });

    setFarmListData(data);
  }, [farmCards]);
  const ref: any = React.useRef();

  const [showBuyNowDialog, setShowBuyNowDialog] = useState<boolean>(false);
  const [farmIndex, setFarmIndex] = useState<any>(0);
  const handleStakeNow = (index: number) => {
    console.log("index in handle stake now fuction===>", index);
    if (localStorage.getItem("account_address")) {
      navigate(`/staking/${index}`);
    } else {
      console.log("connectWalletRef", ref);
      setFarmIndex(index);
      setShowBuyNowDialog(true);
    }
  };

  const handleRedirect = () => {
    navigate(`/staking/${farmIndex}`);
  };

  const handleClose = () => {
    setShowBuyNowDialog(false);
  };

  useEffect(() => {
    console.log(" ===in first useEffect ===");
    setRegisterFarmInstance(new RegisterFarmService());
  }, []);

  useEffect(() => {
    console.log("in second useEffect ==> after getting register farm instance");
    const fetchData = async () => {
      try {
        // let level:number|undefined=undefined;
        const level = await registerFarmInstance?.getLevels();
        console.log("fetched leves ===>", level);
        if (level) {
          setFarmLevels(parseInt(level.toString(), 10));
        }
      } catch (error) {
        console.error("Error fetching farm count:", error);
      }
      console.log("in fetch data");
    };

    const timerId = setTimeout(fetchData, 1000);

    return () => clearTimeout(timerId);
  }, [registerFarmInstance]);

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const handleCreateFarm = () => {
    navigate("/createfarm");
  };
  //state to show loader
  const [showLoader, setShowLoader] = useState(false);

  const handleShowLoader = (active: boolean) => {
    setShowLoader(active);
  };
  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100vh",
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <Box
        sx={{
          // pb: "30px",
          padding: "0 2.5rem 2.5rem 2.5rem",
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          "@media (max-width: 600px)": {
            padding: "0 8px ",
          },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <NewNftHeader farmHeader={true} />
        <Box
          sx={{
            ...circleStyle,
            top: { md: "10%" },
            transform: "translate(-50%, -50%)",
            width: "447.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter: "blur(120px)",
            WebkitBackdropFilter: "blur(120px)",
          }}
        ></Box>
        <Box
          sx={{
            ...circleStyle,
            top: { md: "10%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "80%", md: "90%" },
            transform: "translate( -50%)",
            width: "547.625px",
            height: "187.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(140px)",
            backdropFilter: "blur(140px)",
            WebkitBackdropFilter: "blur(140px)",
          }}
        ></Box>

        <Box
          sx={{
            ...circleStyle,
            top: { md: "70%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "80%", md: "90%" },
            right: { xs: "0%", md: "0%" },
            transform: "translate( -50%)",
            width: "447.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter: "blur(120px)",
            WebkitBackdropFilter: "blur(120px)",
          }}
        ></Box>

        <Box
          sx={{
            ...circleStyle,
            top: { md: "70%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "0%", md: "0%" },
            transform: "translate( -50%)",
            width: "447.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter: "blur(120px)",
            WebkitBackdropFilter: "blur(120px)",
          }}
        ></Box>

        <Box
          sx={{
            ...circleStyle,
            top: { md: "70%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "0%", md: "0%" },
            transform: "translate( -50%)",
            width: "447.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter: "blur(120px)",
            WebkitBackdropFilter: "blur(120px)",
          }}
        ></Box>

        <Box
          sx={{
            "@media (max-width: 600px)": {
              padding: "0 10px ",
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "22px",
              background:
                "var(--Linear, linear-gradient(267deg, #FF990D -3.12%, #9D00FF 93.15%))",
              // height:"40%",
            }}
          >
            <Box
              sx={{
                borderRadius: "22px",
                background:
                  "var(--Linear, linear-gradient(267deg, #FF990D -3.12%, #9D00FF 93.15%))",
                position: "relative",
                overflow: "hidden",
                // height:"40%",
              }}
            >
              <Box
                sx={{
                  ...circleStyle,
                  top: { xs: "-17px", md: "-50px" },
                  display: { xs: "flex", md: "flex" },
                  right: { xs: "-6%", md: "-6%" },
                  transform: { xs: "translate( 0%)", md: "translate( -23%)" },
                  width: { xs: "60px", md: "230px" },
                  height: { xs: "60px", md: "230px" },
                  borderRadius: "100%",
                  background: "rgba(255, 255, 255, 0.2)",
                  // filter: "blur(140px)",
                  zIndex: "0 !important",
                }}
              ></Box>

              <Grid
                container
                sx={{
                  py: 2,
                  px: 2,
                  position: "relative",
                  overflow: "overlay",
                  borderRadius: "20px",
                }}
              >
                {/* <Box
                sx={{
                  ...circleStyle,
                  top: { xs: "0", md: "-26px" },
                  display: { xs: "flex", md: "flex" },
                  left: { xs: "0%", md: "0%" },
                  transform: "translate( -23%)",
                  width: { xs: "30px", md: "85px" },
                  height: { xs: "30px", md: "85px" },

                  borderRadius: "100%",
                  background: "rgba(255, 255, 255, 0.2)",
                  // filter: "blur(140px)",
                  // zIndex: "0 !important",
                }}
              ></Box> */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: { xs: "auto", lg: "559px" },
                      pl: { md: "4" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        textAlign: { md: "left", lg: "left", xs: "center" },
                        fontSize: { xs: "24px", md: "32px" },
                      }}
                    >
                      Earn While You Hold Explore Our Crypto Staking Farm
                    </Typography>
                    <Typography
                      sx={{
                        my: 2,
                        display: { xs: "none", sm: "none", md: "block" },
                        fontFamily: "outfit",
                        fontSize: "21px",
                      }}
                    >
                      Welcome to our Crypto Staking Farm, where you can earn
                      rewards just by holding into your cryptocurrencies! It's
                      like planting seeds and watching your rewards grow.{" "}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "flex-end" },
                      height: { xs: "150px", md: "321px" },
                    }}
                  >
                    <img
                      src={StakingFarmBanner}
                      height="inherit"
                      width="auto"
                      alt=""
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                my: 5,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "outfit",
                  "@media (max-width: 600px)": {
                    width: "100%",
                    textAlign: "center",
                  },
                }}
                variant="h5"
              >
                Voeux Farms
              </Typography>
              {isAdmin && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "162px",
                    height: "45px",
                  }}
                >
                  <Button
                    onClick={() => {
                      navigate("/createfarm");
                    }}
                    sx={{
                      border: "1px solid #FF990D",
                      borderRadius: 2,
                      marginRight: "4px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "#FF990D", pr: "4px", fontFamily: "outfit" }}
                    >
                      +
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#FF990D",
                        textTransform: "none",
                        fontFamily: "outfit",
                        fontSize: "16px",
                      }}
                    >
                      Create Farm
                    </Typography>
                  </Button>
                </Box>
              )}
            </Stack>
            <Grid container spacing={2} sx={{}}>
              <OutlinedCard
                farms={farmListData}
                handleStakeNow={handleStakeNow}
              />
            </Grid>
            <Stack>
              <Box
                sx={{
                  ...circleStyle,
                  top: "90%",
                  display: { xs: "flex", md: "none" },
                  left: "80%",
                  right: "0%",
                  transform: "translate(-50%)",
                  width: "447.625px",
                  height: "147.734px",
                  borderRadius: "547.625px",
                  background: "#9D00FF",
                  filter: "blur(120px)",
                  backdropFilter: "blur(120px)",
                  WebkitBackdropFilter: "blur(120px)",
                }}
              />

              {farmLevels > 0 && farmLevels - currentFarmId > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: { xs: 2, md: 3, xl: 4 },
                  }}
                  onClick={getFarmBasicDetails}
                >
                  <Button
                    size="small"
                    sx={{
                      py: { md: 1 },
                      borderRadius: "8px",
                      color: "#fff",
                      fontWeight: 500,
                      border: "0.931px solid #fff",
                      px: { xs: 2, lg: 4 },
                      textTransform: "none",
                      fontFamily: "outfit",
                    }}
                  >
                    Load More
                  </Button>
                </Box>
              )}
            </Stack>
          </Stack>
        </Box>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent", // Set the dialog background to transparent
              boxShadow: "none", // Remove the default Material-UI box shadow
            },
          }}
          open={showBuyNowDialog}
          onClose={handleClose}
          maxWidth={maxWidth}
        >
          <DialogContent>
            <MuiWalletConnect
              handleClose={() => handleClose()}
              buyConnect={true}
              handleRedirect={() => handleRedirect()}
            />
          </DialogContent>
        </Dialog>
      </Box>
      <LandingPageFooter />
    </>
  );
};

export default ExploreFarm;
