import React from "react";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import indiaflag from "../../assets/voeuxpay/IN.png";
import vietnam from "../../assets/voeuxpay/VN.png";
import thai from "../../assets/voeuxpay/TH.png";
import indo from "../../assets/voeuxpay/INDO.png";
import sing from "../../assets/voeuxpay/SG.png";
import mal from "../../assets/voeuxpay/MY.png";
import phil from "../../assets/voeuxpay/PH.png";
import styles1 from "../../../src/components/LandingPageFloatingList/LandingPageFloatingList.module.css";
const flagsDataone = [
  { image: indiaflag, country: "INDIA" },
  { image: vietnam, country: " VIETNAM" },
  { image: thai, country: " THAILAND" },
  { image: indo, country: " INDONESIA" },
  { image: phil, country: " PHILIPPINES" },
  { image: sing, country: " SINGAPORE" },
  { image: mal, country: " MALAYSIA" },
  { image: indo, country: " INDONESIA" },
  { image: mal, country: " MALAYSIA" },
  { image: phil, country: " PHILIPPINES" },
  { image: indiaflag, country: "INDIA" },
  { image: vietnam, country: " VIETNAM" },
  { image: thai, country: " THAILAND" },
  { image: indo, country: " INDONESIA" },
  { image: phil, country: " PHILIPPINES" },
  { image: indiaflag, country: "INDIA" },
  { image: vietnam, country: " VIETNAM" },
  { image: thai, country: " THAILAND" },
  { image: phil, country: " PHILIPPINES" },
  { image: indiaflag, country: "INDIA" },
  { image: vietnam, country: " VIETNAM" },
  { image: thai, country: " THAILAND" },
];

function FlagAnimation() {
  return (
    <>
      <Box
        display="flex"
        className={styles1.floatingListContainer}
        sx={{ margin: "auto" }}
      >
        {flagsDataone.map((flag) => {
          return (
            <Box
              className={styles1.floatingList}
              // mr={2}
              sx={{ fontFamily: "outfit" }}
            >
              <Box
                //   border={"1px solid white"}
                mr={2}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    fontFamily: "outfit",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "60px",
                  }}
                >
                  <img
                    src={flag.image}
                    alt={flag.country}
                    style={{ width: "40px", height: "30px" }}
                  />
                  <Typography
                    sx={{ fontSize: "16px", fontFamily: " Bebas Neue" }}
                  >
                    {flag.country}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default FlagAnimation;
