// StepTwo.tsx
import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";

import backimg from "../../../assets/voeuxpay/Back.svg";
import { Environment } from "../../../environments";
import axios from "axios";

const StepTwo = ({
  currency,
  getPaymentMethod,
  handleNext,
  handleBack,
  handleShowLoader,
  showToastErrorMessageClick,
}: {
  currency: any;
  getPaymentMethod: (serviceCode: any, methodname: any, logo: any) => void;
  handleNext: () => void;
  handleBack: () => void;
  handleShowLoader: (active: any) => void;
  showToastErrorMessageClick: (text: string) => void;
}) => {
  const [showComponent, setShowComponent] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState<any>({});
  const [paymentMethods, setPaymnetMethods] = useState<any>({});

  const handleVNDPayClick = (
    paymentServiceCode: any,
    paymentmethod: any,
    logo: any
  ) => {
    setPaymentMethod({
      paymentMethod: paymentmethod,
      serviceCode: paymentServiceCode,
      serviceLogo: logo,
    });
    getPaymentMethod(paymentServiceCode, paymentmethod, logo);
  };

  const getPaymnetMethods = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}currency/getway?currency=${currency}`
      );
      const paymentsData = {
        currencyName: response.data?.currency,
        services: response.data?.services,
        paymentID: response.data?._id,
      };
      setPaymnetMethods(paymentsData);
      setTimeout(() => {
        handleShowLoader(false);
      }, 500);
    } catch (error) {
      handleShowLoader(false);
      showToastErrorMessageClick(
        `Failed in generating the Payments methods for ${currency}`
      );
    }
  };

  const handleNextbutton = () => {
    if (!paymentMethod.paymentMethod) {
      showToastErrorMessageClick("Please select any of the payment methods");
      return false;
    }
    handleNext();
  };
  useEffect(() => {
    handleShowLoader(true);
    getPaymnetMethods();
  }, [currency]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {showComponent && (
        <Box>
          <Box
            sx={{
              height: "auto",
              width: "551px",
              borderRadius: "18.34px",
              background: "#181818",
              marginBottom: "25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img src={backimg} alt="back" onClick={handleBack} />
              <Box sx={{ flex: 1, textAlign: "center" }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      fontFamily: "Bebas Neue",
                      fontSize: "32.10px",
                      fontWeight: "400",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      color: "white",
                      marginTop: 2,
                      textTransform: "uppercase",
                      marginRight: "60px",
                    }}
                  >
                    Select payment method
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginRight: "15px",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "36px",
                  color: "#FF990D",
                  marginBottom: "3px",
                  flex: 1,
                  textAlign: "center",
                }}
              >
                {paymentMethods.currencyName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, 170px)",
                justifyContent: "center",
                rowGap: "20px",
                alignItems: "center",
              }}
            >
              {paymentMethods.services?.map((service: any) => (
                <Box
                  sx={{
                    cursor: "pointer",
                    width: "151px",
                    height: "41.51px",
                    borderRadius: "8.30px",
                    border: "0.71px #D0D5DD solid",
                    "&:active": {
                      background: "#fff",
                      color: "black",
                    },
                  }}
                  id={service.paymentID}
                  onClick={() =>
                    handleVNDPayClick(
                      service.serviceCode,
                      service.serviceName,
                      service.logo
                    )
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontSize: "11.28px",
                        fontWeight: "400px",
                        textTransform: "uppercase",
                      }}
                    >
                      {service.serviceName}
                    </Typography>

                    <img src={service.logo} />
                  </Box>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "27px",
                alignItems: "center",
              }}
            >
              {" "}
              <Button
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "18px",
                  textTransform: "uppercase",
                  background: "#FF990D",
                  width: "100%",
                  color: "#fff",

                  "&:hover": {
                    background: "#FF990D",
                    color: "#fff",
                  },
                }}
                onClick={() => handleNextbutton()}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default StepTwo;
