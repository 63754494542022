import { Contract, Interface } from "ethers";
import { ContractInstance } from "./contractInstance";
import { MARKETPLACE_CONTRACT_ADDRESS } from "src/constants/ABIs/marketPlaceABI";

export class ERC1155Token {
        contractInstance: Contract | null;
        constructor(
                ERC1155TOKEN_Address: string,
                ERC1155TOKEN_ABI: any[]
        ){
                this.contractInstance = null;
                const contract = new ContractInstance();
                contract.buildContractInstance(
                        ERC1155TOKEN_Address, ERC1155TOKEN_ABI
                ).then((contIns)=>{
                        this.contractInstance = contIns;
                })
        }

        grantTransferPermission  =  async()=>{
                try{
                     console.log("in grant permission ",MARKETPLACE_CONTRACT_ADDRESS,this.contractInstance);
                     const tx =  await  this.contractInstance?.setApprovalForAll(MARKETPLACE_CONTRACT_ADDRESS, true);
                     console.log("transaction ==>",tx);
                     return tx.wait();
                }catch(err){
                        throw err;
                }
                
        }

        revokeTransferPermission = async()=>{
                try{
                        console.log("in grant permission ",MARKETPLACE_CONTRACT_ADDRESS,this.contractInstance);
                        const tx =  await  this.contractInstance?.setApprovalForAll(MARKETPLACE_CONTRACT_ADDRESS, false);
                        console.log("transaction ==>",tx);
                        return tx.wait();
                   }catch(err){
                           throw err;
                   } 
        }
}