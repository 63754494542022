import { Box, Stack } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import NewNftHeader from "../components/newNftHeader/NewNftHeader";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { use } from "i18next";
import { Environment } from "../../src/environments";
import LandingPageFooter from "../components/landingPageFooter/LandingPageFooter";
import arrow from "../assets/svgs/arrow-up-right.svg";

const BlogDetails = () => {
  const navigate = useNavigate();
  let { blogId } = useParams();
  console.log("use parms", blogId);
  const [blogDetails, setBlogDetails] = useState<any>({});

  const handleBlogDetailsClick = (blogId: any) => {
    navigate(`/blogDetails/${blogId}`);
    getBlogDetails(blogId);
    getAllBlog(blogId);
  };
  const getBlogDetails = async (blogId: any) => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}blog/${blogId}`
      );

      console.log("blog details", response);
      setBlogDetails(response.data);
    } catch (error) {
      console.log("error in blog", error);
    }
  };
  const [blogData, setBlogData] = useState<any>([]);
  const getAllBlog = async (blogId: any) => {
    try {
      // http://localhost:8000/blog
      const response = await axios.get(`${Environment.metavoeuxBackend}blog`);
      console.log("blog response", response);
      const data = response.data.filter((blog: any) => blog.blogId !== blogId);
      setBlogData(data);
    } catch (error) {
      console.log("Error in getting blog");
    }
  };

  useEffect(() => {
    getBlogDetails(blogId).then(() => getAllBlog(blogId));
  }, []);

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  return (
    <>
      <Box
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader />

        <Box
          sx={{
            ...circleStyle,
            display: { xs: "none", sm: "none", md: "flex" },
            down: { xl: "20%" },
            left: "1%",
            transform: "translateY(37%)",
            width: "300px", // Adjusted width
            height: "200px", // Adjusted height
            borderRadius: "50%", // Ensures it remains a perfect circle
            background: "#9D00FF",
            filter: "blur(132px)",
            backdropFilter: "blur(132px)",
            WebkitBackdropFilter: "blur(132px)",
            overflow: "hidden",
          }}
        ></Box>
        <Box
          sx={{
            ...circleStyle,
            display: { xs: "none", sm: "none", md: "flex" },
            top: { xl: "40%" },
            right: "0%",
            transform: "translateY(37%)",
            width: "250px", // Adjusted width
            height: "250px", // Adjusted height
            borderRadius: "50%", // Ensures it remains a perfect circle
            background: "#9D00FF",
            filter: "blur(132px)",
            backdropFilter: "blur(132px)",
            WebkitBackdropFilter: "blur(132px)",
            overflow: "hidden",
          }}
        ></Box>

        <Grid container item spacing={4}>
          {/* <Grid item lg={3}>
testing
          </Grid> */}
          <Grid
            item
            lg={9}
            spacing={6}
            sx={{
              paddingRight: "32px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "80%" }}>
              <Typography
                sx={{
                  fontSize: "30px",
                  lineHeight: "38px",
                  fontWeight: 600,
                }}
              >
                {blogDetails.mainHeading}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "32px",
                  fontWeight: 400,
                }}
              >
                {blogDetails.sortDes}
              </Typography>
              <img
                src={blogDetails.mainImage}
                width="100%"
                height="300px"
                style={{
                  objectFit: "cover",
                  marginTop: "30px",
                  borderRadius: "30px",
                  objectPosition: "0 0",
                  marginBottom: "30px",
                }}
              />
              <Box>
                {blogDetails.content?.map((blogdata: any) => (
                  <>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        lineHeight: "32px",
                        fontWeight: 400,
                        fontFamily: "Inter",
                      }}
                    >
                      {blogdata.heading}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "28px",
                        fontWeight: 400,
                        fontFamily: "Inter",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: blogdata.content }}
                      />
                    </Typography>
                    {blogdata.image && (
                      <img
                        src={blogdata.image}
                        alt="Main"
                        width="100%"
                        style={{
                          objectFit: "cover",
                          marginTop: "30px",
                          borderRadius: "30px",
                          objectPosition: "0 0",
                          marginBottom: "30px",
                        }}
                        height="300px"
                      />
                    )}
                  </>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={3}
            sx={{
              borderLeft: { xs: "none", md: "none", xl: "0.5px solid #FFFFFF" },
            }}
          >
            {blogData.map((blog: any, index: any) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                key={index}
                sx={{ mb: "30px" }}
              >
                <Card
                  sx={{
                    borderRadius: 4.402,
                    opacity: 0.95,
                    background: "#272727",
                    backdropFilter: "blur(12.059269905090332px)",
                    overflow: "hidden",
                  }}
                  key={index}
                  onClick={() => handleBlogDetailsClick(blog.blogId)}
                >
                  <CardActionArea
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      columnGap: "10px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        height: "159px",
                        width: "140px",
                        objectFit: "inherit",
                      }}
                      image={blog.mainImage}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#FF990D",
                            ontFamily: "outfit",
                            fontSize: { xs: "8px", md: "10px" },
                          }}
                        >
                          {blog.category +
                            " . " +
                            new Date(blog.createdAt).toLocaleString("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </Typography>
                        <img
                          src={arrow}
                          alt=""
                          style={{
                            width: "30px",
                            height: "30px",
                            position: "absolute",
                            right: "11px",
                          }}
                        />
                      </Stack>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{
                          color: "#fff",
                          fontFamily: "outfit",
                          fontSize: { xs: "12px", md: "17px" },
                          height: { xs: "40px", sm: "40px", md: "52px" },

                          overflow: "hidden",
                        }}
                      >
                        {blog.mainHeading}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "outfit",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxHeight: "50px",
                          height: "50px",
                        }}
                      >
                        {blog.sortDes}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Box
          sx={{
            ...circleStyle,
            display: { xs: "none", sm: "none", md: "flex" },
            down: { xl: "60%" },
            left: "1%",
            transform: "translateY(37%)",
            width: "300px", // Adjusted width
            height: "200px", // Adjusted height
            borderRadius: "50%", // Ensures it remains a perfect circle
            background: "#9D00FF",
            filter: "blur(132px)",
            backdropFilter:"blur(132px)",
                    WebkitBackdropFilter:"blur(132px)",  
            overflow: "hidden",
          }}
        ></Box>

        <Box
          sx={{
            ...circleStyle,
            display: { xs: "none", sm: "none", md: "flex" },
            down: { xl: "30%" },
            right: "0%",
            transform: "translateY(37%)",
            width: "250px", // Adjusted width
            height: "250px", // Adjusted height
            borderRadius: "50%", // Ensures it remains a perfect circle
            background: "#9D00FF",
            filter: "blur(132px)",
            backdropFilter:"blur(132px)",
                    WebkitBackdropFilter:"blur(132px)",  
            overflow: "hidden",
          }}
        ></Box>
      </Box>
      <Box sx={{ mt: { xs: 5 } }}>
        <LandingPageFooter />
      </Box>
    </>
  );
};

export default BlogDetails;
