// NftCollectionLiveCard
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import hearticon from "../../assets/svgs/Heart.svg";
import plainheart from "../../assets/svgs/whiteheart.svg";
import cross from "../../assets/svgs/card_cross.svg"


interface MyNftCollectionProps {
  collectionList?: any;
  isWatchListRequired?: Boolean;
  activeListing?:Boolean;
  handleReloadNft?:(index:any) => void;
  detailsPage?:Boolean
  category?:any
  handleReload?:(id:any,name:any) => void
  handleMoreLikeNFtClick?:(id:any) => void
}

const NftCollectionLiveCard: React.FC<MyNftCollectionProps> = ({
  collectionList,
  isWatchListRequired,
  activeListing=false,
  handleReloadNft,
  detailsPage=false,
  category = null,
  handleReload = () => {},
  handleMoreLikeNFtClick = () => {}
}) => {
  const naviagte = useNavigate();

  console.log("collection of list live crad",collectionList)
  const handleCardClick = (nftid: string,name?:string) => {
    // naviagte("/overview", { state: id });
    if(category == "moreLikeCollection"){
      naviagte(`/nftDetail/${nftid}`)
      handleMoreLikeNFtClick(nftid)
      return;
    }
    if(category == "collectionList"){
     
      // naviagte("/categoryNft", {state:{category:"collection",collectionId:nftid, collectionName:name } } )
      handleReload(nftid,name)
      return
    }
    if(!detailsPage){
      naviagte(`/nftDetail/${nftid}`)
    }else{
      naviagte(`/nftSales/${nftid}`,{state:"Profile"})
    }
   
    

  };
 
  const [collectionList1, setCollectionList] = useState<any>(collectionList);
  const hanldeWishlist = (index: number) => {
    collectionList1[index].isWishList = !collectionList1[index].isWishList;
    setCollectionList([...collectionList1, collectionList1]);
  };

  useEffect(() => {
    setCollectionList(collectionList);
    console.log("collectionList", collectionList);
  }, [collectionList]);

  const handleCross = async(item:any,index:any) => {
    console.log("item",item)
    handleReloadNft &&  handleReloadNft(index)
    // http://localhost:8000/listingnft/cancel?userId=${item.userId}&nftId=${item.nftID}&listingId=${item.listingId}
    // try {
      
    //   const response = await axios.delete(`${Environment.metavoeuxBackend}listingnft/cancel?userId=${item.userId}&nftId=${item.nftID}&listingId=${item.listingId}`);
    //   console.log("handleCross", response.data)
    //   handleReloadNft &&  handleReloadNft()
    // }catch(error){
    //   console.log("Error in Cancel from listing api")
    // }
        
  }


  return (
    <>
      {collectionList.map((item: any, index: number) => (
        <Box
          sx={{
            fontFamily: "outfit",
            background: "#181818",
            borderRadius: "18px",
            position: "relative",
            cursor: "pointer",
            '&:hover, &:focus-within': {
              '.cardcross': {
                display: 'block',
              },
            },
          }}
          key={item.nftID}
          
        >
          {activeListing && <Box className="cardcross"
          onClick={() => handleCross(item,index)}
           sx={{position:"absolute",right:"10px",top:"10px",display:"none"}}>
            <img src={cross} draggable="false" alt="" />
            </Box>
          }
          
          <Box onClick={() => handleCardClick(item.nftID,item.nftName)}>

          
          <img
            src={item.imageURL}
            alt={"image"}
            draggable="false"
            style={{ width: "-webkit-fill-available",borderRadius:"20px 20px 0px 0px",objectFit:"cover" ,objectPosition:"0 0"}}
            height={"218px"}
            // width={"243px"}
            
          />
          {isWatchListRequired && (
            <Box
              sx={{
                width: "30px",
                height: "30px",

                border: "1px solid #fff",
                borderRadius: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 1,
                right: 1,
                margin: "1rem",
              }}
            >
              <img
                src={item.isWishList ? hearticon : plainheart}
                alt={"love"}
                draggable="false"
                onClick={() => hanldeWishlist(index)}
                style={{
                  // position: "absolute",

                  margin: "8px",
                  cursor: "pointer",
                }}
              />
            </Box>
          )}{" "}
          <h3 style={{}}>{item.nftTitle}</h3>
          <Box sx={{ p: "16px" }}>
            <Typography sx={{ color: "white", fontWeight:"600",fontFamily: "outfit",fontSize:"1rem",overflow:"hidden",width:"100%",whiteSpace:"nowrap",textOverflow:"ellipsis" }}>
              {item.nftName}
            </Typography>
            {item?.price && <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              mt={2}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#858584", fontFamily: "outfit" }}
              >
                Price:
              </Typography>
              <Typography sx={{ fontFamily: "outfit" }}>
                {Number(item.price).toFixed(4)} Voeux 
              </Typography>
            </Box>}
            
            {item?.supply && 
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              mt={2}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#858584", fontFamily: "outfit" }}
              >
                Supply:
              </Typography>
              <Typography sx={{ fontFamily: "outfit" }}>
                {item.supply}
              </Typography>
            </Box>
            }
          </Box>
          </Box>
        </Box>
      ))}
    </>

    // <Grid item xs={12} sm={6} md={4} lg={3}>
    //   <Box
    //     sx={{
    //       display: "grid",
    //       gridTemplateColumns: {
    //         xs: "repeat(1, 100%)",
    //         lg: "repeat(5, 276px)",

    //       },
    //     }}
    //   >

    //   </Box>
    // </Grid>
  );
};

export default NftCollectionLiveCard;
