
import {
  // REWARDFARM_CONTRACT_ADDRESS,
  REWARDFARM_CONTRACT_ABI,
} from "../constants/ABIs/rewardABI";
import { RewardFarm } from "../contracts/RewardFarm";


export class RewardFarmService {
  rewardFarmInstance: RewardFarm;
  farmAddress: string;
  constructor(REWARDFARM_CONTRACT_ADDRESS: string) {
    this.farmAddress = REWARDFARM_CONTRACT_ADDRESS;
    this.rewardFarmInstance = new RewardFarm(
      REWARDFARM_CONTRACT_ADDRESS,
      REWARDFARM_CONTRACT_ABI
    );
  }

  getBalance = async (address: string): Promise<number> => {
    try {
      console.log("in getwalletBalance");
      const balance = await this.rewardFarmInstance.getBalance(address);
      return Number(balance);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  getFinishedTime = async (): Promise<number> => {
    try {
      console.log("in getFinishedTime");
      return await this.rewardFarmInstance.getFinishTime();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  getOwner = async (): Promise<string> => {
    try {
      return await this.rewardFarmInstance.getFarmOwner();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  getEarings = async (address: string): Promise<number> => {
    try {
      console.log("getEarings farm service", this.rewardFarmInstance);
      const fetchedEarnings = await this.rewardFarmInstance?.getEarnings(
        address
      );
      console.log("fetchedEarnings:", fetchedEarnings);
      return fetchedEarnings;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  getTotalSupply = async (): Promise<number> => {
    try {
      return await this.rewardFarmInstance.getTotalSupply();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  getRewardAmount = async (account: string) => {
    try {
      return await this.rewardFarmInstance.getRewards(account);
    } catch (err) {
      throw err;
    }
  };

  getUserRewardPerTokenPaid = async (account: string) => {
    try {
      return await this.rewardFarmInstance.getUserRewardPerTokenPaid(account);
    } catch (err) {
      throw err;
    }
  };

  getFarmDuration = async() =>{
    try{
      return await this.rewardFarmInstance.getDuration();
    }catch(err){
      throw err;
    }
  }

  // getApproval = async(tokenInstance:DappToken, amount:BigInt) =>{
  //   try{

  //    const approveResp =  await tokenInstance?.approveTransaction(this.farmAddress, amount);
  //    console.log("approveResp:",approveResp, this.farmAddress);
  //    const resp = await tokenInstance?.getAllowance("0x2C280905F711722894d9748773E80F31fF2b8Be2", this.farmAddress);
  //    console.log("allowance response ==>", Number(resp));
  //    return approveResp;
  //   }catch(err){
  //     console.error(err);
  //     throw err;
  //   }

  // }
  // getTokenApproval = async (amount:number)=> {
  //   try{

  //     const tokenInstance = new DappToken(DAPPTOKEN_CONTRACT_ADDRESS, DAPPTOKEN_CONTRACT_ABI);
  //     const intervalId = setTimeout(async () =>{
  //       if(tokenInstance.contractInstance){
  //         console.log("in if condition for checking if tokenInstance exists");
  //         let newAmount = BigInt(0);
  //         console.log("amount: ", amount*10**10);
  //         if(amount > 0 && amount < 1){
  //           newAmount = BigInt(amount*10**10)*(BigInt(10)**BigInt(8));
  //         }else{
  //           newAmount = BigInt(amount)*(BigInt(10)**BigInt(18));
  //         }
  //         console.log("newAmount:", newAmount);
  //        const tx = await this.getApproval(tokenInstance, newAmount);
  //        console.log("tx value==>", tx);
  //       //  const investResp = await this.rewardFarmInstance?.investStake(newAmount);
  //       //   console.log("investResp:", investResp);
  //       //   return investResp;
  //        clearInterval(intervalId);

  //       }
  //     },100);

  //   }catch(err){
  //     throw err
  //   }
  // }
  changeAmountToDecimal =(amount:number)=>{
    let newAmount = BigInt(0);
    if (amount > 0 && amount < 1) {
      
      newAmount =
        BigInt(Math.ceil(amount * (10 ** 10))) * BigInt(10) ** BigInt(8);
    } else if (amount >= 1){
      newAmount = BigInt(amount) * (BigInt(10) ** BigInt(18));
    } 
    return newAmount;
  }

  investNow = async (amount: number) => {
    try {
      const  newAmount = this.changeAmountToDecimal(amount);
      const investResp = await this.rewardFarmInstance?.investStake(newAmount);
      console.log("investResp:", investResp);
      return investResp;
    } catch (err) {
      throw err;
    }
  };

  withdraw = async (amount: number) => {
    try {
      const  newAmount = this.changeAmountToDecimal(amount);
      const resp = await this.rewardFarmInstance?.withdrawToken(newAmount);
      console.log("response of withdraw: ", resp);
      return resp;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };


  getRewardRate = async()=>{
    try{
      return await this.rewardFarmInstance?.getRewardRate();
    }catch (err) {
      throw err;
    }
  }

  setRewardsDuration = async(duration:number)=>{
    try{
      return await this.rewardFarmInstance?.setRewardsDuration(duration);
    }catch (err) {
      throw err;
    }
  }

  setRewardRate = async(amount:number)=>{
    try{
      const newAmount = this.changeAmountToDecimal(amount);
      return await this.rewardFarmInstance?.notifyRewardAmount(newAmount);
    }catch (err) {
      throw err; 
    }
  }

  getRemainingDuration = async()=>{
    try{
      return await this.rewardFarmInstance?.getRemainingTIme();
    }catch (err) {
      throw err;
    }
  }
}
