import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import creatorImage from "../../assets/images/creatorImage.png";
import { useNavigate } from "react-router-dom";

interface CreatorItem {
  nftName: string;
  imageUrl: string;
  fullName: string;
}

const NftCard = ({ nftItem }: { nftItem: any }) => {

  console.log("nftItem nftcard",nftItem)
  const naviagte = useNavigate();

const handleCardClick = (nftid: string) => {
  naviagte(`/nftDetail/${nftid}`)
  // naviagte("/overview", { state: id });
};
  console.log("nft item", nftItem);
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderRadius: 4,
        background: "#181818",
        cursor:"pointer"
      }}
      onClick={() => handleCardClick(nftItem?.nft?._id)}
    >
      <Box
        sx={{
          margin: 0,
          padding: 0,
          height:"237px"
        }}
      >
        <img src={nftItem?.nft?.imageUrl} alt="" height={"100%"} width={"100%"} style={{objectFit:"cover",borderRadius:"20px 20px 0px 0",objectPosition:"0 0"}} />
      </Box>
      <Box
        sx={{
          //   background: "#181818",
          marginTop: 0,

          display: "flex",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <Typography
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ fontFamily: "outfit" ,display:"inline",overflow:"hidden",width:"100%",whiteSpace:"nowrap",textOverflow:"ellipsis",fontWeight:"600"}}
        >
          {nftItem?.nft?.nftName}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gap={2}
          sx={{
            marginTop:"16px"
          }}
        >
          <Box
            sx={{
              background: "#181818",
            
            }}
          >
            <img src= {nftItem.user?.profilePic ? nftItem.user?.profilePic : creatorImage } alt=""  />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography sx={{ fontFamily: "outfit" }}>Creator</Typography>
            <Typography sx={{ fontFamily: "outfit" }}>
              {nftItem.user?.fullName ? nftItem.user?.fullName : nftItem.user?.walletAddress.slice(0,6)+"....."+nftItem.user?.walletAddress.slice(-6) }
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NftCard;
