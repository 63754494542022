import Box from "@mui/system/Box";
import DownloadIcon from "../../assets/svgs/Download.svg";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import DragDrop from "../../utils/getS3ImageURL";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";

const DragAndDrop = ({
  createCollection,
  getImageurl,
  data,
}: {
  createCollection?: Boolean;
  getImageurl: (data?: any, file?: any) => void;
  data: any;
}) => {
  useEffect(() => {
    console.log("checking iteration", data);
    setSelectedImage(data.imageURL);
  }, [data]);

  const [selectedImage, setSelectedImage] = useState<any>(data.imageURL);
  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    console.log("file67", file);
    if (file.size >= 50000000) {
      showToastErrorMessageClick("Please uplaod image which is less than 50MB");
      return false;
    }
    try {
      var data = await DragDrop("createnft", file);
      // setSelectedImage(data)
      getImageurl(data, file);
      console.log("image url drag and drop", data);
    } catch (error) {
      showToastErrorMessageClick(
        "Failed in uploading the image. Please try again!"
      );
    }
  };

  const handleImageDelete = () => {
    setSelectedImage("");
  };
  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  return (
    <>
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />

      <Box>
        {!selectedImage ? (
          <Box
            sx={{
              textAlign: "center",
              padding: createCollection
                ? { xs: "60px 20px", lg: "129px 0" }
                : { xs: "60px 20px", lg: "213px 0" },
              width: "auto",
              background: "rgba(217, 217, 217, 0.11)",
              borderRadius: "24px",
              border: "1px solid #FFF",
              marginTop: { xs: "50px", lg: "0" },
              position: "relative",
            }}
          >
            <input
              required
              type="file"
              accept="image/*,"
              style={{
                position: "absolute",
                left: "0px",
                width: "100%",
                right: 0,
                height: "100%",
                top: 0,
                opacity: "0",
              }}
              onChange={(e) => handleImageChange(e)}
            />
            {/* <Button ref={}>CheckButton</Button> */}

            <img
              src={DownloadIcon}
              style={{
                marginBottom: "24px",
                borderRadius: "30px",
                objectFit: "cover",
              }}
              alt=""
            />
            <Typography
              sx={{
                color: "#FFF",
                fontSize: { xs: "18px", md: "22px" },
                fontWeight: 600,
                mb: 1,
                fontFamily: "outfit",
              }}
            >
              Drag & Drop Media
            </Typography>
            <Link
              sx={{
                color: "#FF990D",
                fontSize: { xs: "18px", md: "22px" },
                fontWeight: 400,

                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {" "}
              Browse Files
            </Link>

            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "18px", md: "22px" },
                fontWeight: 600,
                mb: 1,
                mt: 1,
                fontFamily: "outfit",
              }}
            >
              Max size: 50 MB
            </Typography>
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "18px", md: "22px" },
                fontWeight: 400,
                fontFamily: "outfit",
              }}
            >
              JPG, PNG, GIF, SVG
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              textAlign: "center",
              width: { xs: "auto", lg: "688px" },
              height: { xs: "auto", lg: "600px" },
              background: "rgba(217, 217, 217, 0.11)",
              borderRadius: "24px",
              border: "1px solid #FFF",
              marginTop: { xs: "50px", lg: "0" },
              position: "relative",
            }}
          >
            <img
              src={selectedImage}
              alt=""
              style={{
                width: "-webkit-fill-available",
                height: "600px",
                borderRadius: "30px",
                objectFit: "cover",
              }}
            />
            <Button
              sx={{
                position: "absolute",
                zIndex: 0,
                right: 0,
                top: 1,
                color: "white",
              }}
              onClick={() => handleImageDelete()}
            >
              <DeleteIcon />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DragAndDrop;
