import Box from "@mui/material/Box";
import NewNftHeader from "../../../components/newNftHeader/NewNftHeader";
import styles from "../NftLanding.module.css";
import NftCollectionLiveCard from "../NftCollectionLiveCard";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import LandingPageFooter from "../../../components/landingPageFooter/LandingPageFooter";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { Environment } from "../../../environments";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const NftCategoryCardPage = () => {
  const location = useLocation();
  let data = location.state;
  console.log("log params", data);
  const [category, setCategory] = useState(data.category);

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const [collectName, setCollectName] = useState("");
  useEffect(() => {
    handleShowLoader(true);
    setCategory(data.category);
    // setCollectName(data?.collectionName)
    getAllNft();
  }, []);

  const handleReload = (collectionId: any, collectionName: any) => {
    handleShowLoader(true);
    setCategory("collection");
    // setReload(true)
    setCollectName(collectionName);

    getCollectionNft(collectionId);
    setHeaderName(`NFTs of ${collectionName} Collection `);
  };
  // useEffect(() => {

  //   getAllNft();
  // },[reload])

  const [headerName, setHeaderName] = useState<any>("");
  const [nftList, setNftList] = useState([]);
  //state to show loader
  const [showLoader, setShowLoader] = useState(true);
  const getAllNft = async () => {
    try {
      if (category === "latest") {
        setCategory("latest");
        getLatestNft();
        setHeaderName("Latest NFTs");
        return;
      } else if (category === "Prime") {
        setCategory("Prime");
        setHeaderName("Prime NFTs");
        getPrimeNft();
        return;
      } else if (category === "collection") {
        setCategory("collection");
        getCollectionNft(data.collectionId);
        setHeaderName(`NFTs of ${data.collectionName} Collection `);
        return;
      } else if (category === "trending") {
        setCategory("trending");
        setHeaderName("Trending NFTs");
      } else if (category === "collectionList") {
        setCategory("collectionList");
        getAllCollections();
        setHeaderName("Collections ");
      } else {
        setCategory("feature");
        setHeaderName("Feature NFTs");
      }
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/${category}`
      );

      const filetredData = response.data?.filter(
        (x: any) => x?.listingPrice !== undefined
      );
      console.log("trending,  featured category", filetredData);
      const listingNft = filetredData.map((data: any) => {
        return {
          imageURL: data.nft?.imageUrl ? data.nft?.imageUrl : data.imageUrl,
          nftName: data.nft?.nftName ? data.nft?.nftName : data.nftName,
          price: data?.listingPrice
            ? data?.listingPrice
            : data?.listings?.listingPrice,
          supply: data?.nft?.supply ? data.nft?.supply : data?.supply,
          nftID: data?.nft?._id ? data?.nft?._id : data?._id,
        };
      });
      setNftList(listingNft);
      handleShowLoader(false);
      console.log("all the NFT which are made for sale", response);
    } catch (error) {
      handleShowLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  const getCollectionNft = async (collectionId: any) => {
    try {
      // http://localhost:8000/nft/collectionotherNft?Collectionid=65b3b38265f65df67cdb97fc&nftId=65b4ca455c858bb3738b2700 nft/collection/${id}
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/collection/${collectionId}`
      );
      console.log("response", response);
      // let newarra = response.data.slice(0, 15);
      // console.log("newarra", newarra);
      const filetredData = response.data?.filter(
        (x: any) => x.listings?.listingPrice !== undefined
      );
      console.log("getCollectionNft category", response);
      // const filetredData = response.data?.filter((x:any) => x.listings?.listingPrice !== undefined )
      const listingNft = filetredData.map((data: any) => {
        return {
          imageURL: data.imageUrl,
          nftName: data.nftName,
          price: data?.listings?.listingPrice,
          supply: data.supply,
          nftID: data._id,
        };
      });
      setNftList(listingNft);
      handleShowLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      handleShowLoader(false);
    }
  };

  const getAllCollections = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nftCollection`
      );
      console.log("response", response);
      // let newarra = response.data.slice(0, 15);
      // console.log("newarra", newarra);
      // const filetredData = response.data?.filter((x:any) => x.listings?.listingPrice !== undefined )
      // console.log("getCollectionNft category",response)
      // const filetredData = response.data?.filter((x:any) => x.listings?.listingPrice !== undefined )
      const listingNft = response.data.map((data: any) => {
        return {
          imageURL: data.url,
          nftName: data.contractName,

          // supply: data.supply,
          nftID: data._id,
        };
      });
      setNftList(listingNft);
      handleShowLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      handleShowLoader(false);
    }
  };

  const getLatestNft = async () => {
    try {
      const response = await axios.get(`${Environment.metavoeuxBackend}nft`);
      console.log("response", response);
      // let newarra = response.data.slice(0, 15);
      // console.log("newarra", newarra);
      console.log("getAllNft category", response);
      const filetredData = response.data?.filter(
        (x: any) => x?.listingPrice !== undefined
      );
      const listingNft = filetredData.map((data: any) => {
        return {
          imageURL: data?.nft?.imageUrl,
          nftName: data?.nft?.nftName,
          price: data?.listingPrice,
          supply: data?.nft?.supply,
          nftID: data?.nft?._id,
        };
      });
      setNftList(listingNft);
      handleShowLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      handleShowLoader(false);
    }
  };

  const getPrimeNft = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/ownnft`
      );
      console.log("response", response);
      // let newarra = response.data.slice(0, 15);
      // console.log("newarra", newarra);
      console.log("getAllNft category", response);
      const filetredData = response.data?.filter(
        (x: any) => x?.listingPrice !== undefined
      );
      const listingNft = filetredData.map((data: any) => {
        return {
          imageURL: data?.nft?.imageUrl,
          nftName: data?.nft?.nftName,
          price: data?.listingPrice,
          supply: data?.nft?.supply,
          nftID: data?.nft?._id,
        };
      });
      setNftList(listingNft);
      handleShowLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      handleShowLoader(false);
    }
  };

  const handleShowLoader = (active: boolean) => {
    setShowLoader(active);
  };

  const handleBack = () => {
    navigate(-1);
  };
  const navigate = useNavigate();

  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "absolute",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <Box
        className={styles.mainContainer}
        sx={{
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          zIndex: 0,
        }}
      >
        <NewNftHeader nftHeader={true} />
        <Box sx={{ display: "flex", marginBottom: "10px", cursor: "pointer" }}>
          <ArrowBackIosIcon onClick={() => handleBack()} />
          <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
        </Box>
        <Box sx={{ mb: "40px" }}>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              ...circleStyle,
              top: { xl: "20%" },
              right: "-0px",
              transform: "translateY(-50%)",
              width: "350px", // Adjusted width
              height: "200px", // Adjusted height
              borderRadius: "50%", // Ensures it remains a perfect circle
              background: "#9D00FF",
              // background: "red",
              filter: "blur(140px)",
              backdropFilter:"blur(140px)",
              WebkitBackdropFilter:"blur(140px)", 
              overflow: "hidden",
              "@media (min-width: 1721px)": {
                width: "450px", // Adjusted width
                height: "400px", // Adjusted height
                top: { xl: "50%" },
                left: "-20%",
                filter: "blur(160px)",
                backdropFilter:"blur(160px)",
                WebkitBackdropFilter:"blur(160px)", 
              },
            }}
          ></Box>
          <Typography
            variant="h4"
            sx={{
              color: "white",
              ml: "10px",
              fontFamily: "outfit",
              mb: "30px",
            }}
          >
            {headerName}
            {/* {category.charAt(0).toUpperCase() + category.slice(1)} NFTs */}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg>
              {/* <Grid item xs={12} sm={6} md={4} lg={3}> */}
              {nftList.length == 0 ? (
                <Box>
                  {" "}
                  <Typography
                    variant="body2"
                    sx={{ color: "white", mb: "20px" }}
                  >
                    No NFT Created. Please create the Nfts
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "grid",

                    gridTemplateColumns: {
                      xs: "repeat(auto-fit, 100%)",
                      md: "repeat(auto-fit, 180px)",
                      lg: "repeat(auto-fit, 230px)",
                    },
                    rowGap: "30px",
                    columnGap: "30px",
                  }}
                >
                  <NftCollectionLiveCard
                    collectionList={nftList}
                    category={category}
                    handleReload={handleReload}
                  />
                </Box>
              )}

              {/* </Grid> */}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            ...circleStyle,
            bottom: { xl: "0%" },
            left: "45%",
            transform: "translateY(-50%)",
            width: "350px", // Adjusted width
            height: "200px", // Adjusted height
            borderRadius: "50%", // Ensures it remains a perfect circle
            background: "#9D00FF",
            // background: "red",
            filter: "blur(140px)",
            backdropFilter:"blur(140px)",
            WebkitBackdropFilter:"blur(140px)", 
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "400px", // Adjusted height
              top: { xl: "50%" },
              left: "-20%",
              filter: "blur(160px)",
              backdropFilter:"blur(160px)",
              WebkitBackdropFilter:"blur(160px)", 
            },
          }}
        ></Box>
      </Box>
      <LandingPageFooter />
    </>
  );
};

export default NftCategoryCardPage;
