import { Contract } from "ethers";
import { ContractInstance } from "./contractInstance";
import { Ask } from "../domains/ask.dto";
import { Bid } from "../domains/bid.dto";

export class Marketplace{
    contractInstance: Contract | null;

    constructor(
        MARKETPLACE_CONTRACT_ADDRESS: string,
        MARKETPLACE_CONTRACT_ABI: any[]
    ){
        this.contractInstance = null;
        // this.contractAddress = MARKETPLACE_CONTRACT_ADDRESS;
        let contract = new ContractInstance();
        contract
        .buildContractInstance(
            MARKETPLACE_CONTRACT_ADDRESS,
            MARKETPLACE_CONTRACT_ABI
        )
        .then((contIns) => {
            this.contractInstance = contIns;
        });
    }

    revertAskDoesNotExists = async (): Promise<string> =>{
        try {
            return await this.contractInstance?.REVERT_ASK_DOES_NOT_EXIST();
        }catch (err:any) {
            throw new Error(err);
        }   
    }

    revertAskInsufficientValue  = async (): Promise<string> =>{
        try {
            return await this.contractInstance?.REVERT_ASK_INSUFFICIENT_VALUE();
        } catch (err){
            throw err;
        }
    }

    revertAskIsReversed = async (): Promise<string> =>{
        try {
                return await this.contractInstance?.REVERT_ASK_IS_RESERVED();
        }catch(err){
            throw err;
        }
    }

    revertAskSellerNotOwner = async (): Promise<string> =>{
        try {
            return await this.contractInstance?.REVERT_ASK_SELLER_NOT_OWNER();
        }catch(err){
            throw err;
        }
    }

    revertBidTooLow =async ():Promise<string> => {
        try{
            return await this.contractInstance?.REVERT_BID_TOO_LOW();
        }catch(error){
            throw error;
        }
    }

    retvertCantAcceptOwnAsk = async():Promise<String> =>{
        try{
            return await this.contractInstance?.REVERT_CANT_ACCEPT_OWN_ASK();
        }catch(err){
            throw err;
        }
    }

    revertAskInsufficientEther = async ():Promise<string> => {
        try{
            return await this.contractInstance?.REVERT_INSUFFICIENT_ETHER();
        }catch(err){
            throw err;
        }
    }

    acceptAsk = async (nft:string[], tokenId:Number[], price:BigInt):Promise<object> =>{
        try{
          
            
            console.log("in marketplace contract ==>", nft, tokenId);
            return await this.contractInstance?.acceptAsk(nft, tokenId, {value:price});
        }catch(err){
             throw err;
        }
    }

    acceptBid = async (nft:string[], tokenId:Number[]):Promise<object> => {
        try{
            console.log("nft",nft,tokenId)
            const acceptBidTxn =  await this.contractInstance?.acceptBid(nft, tokenId);
            const txnRecpt = acceptBidTxn.wait();
            return txnRecpt;

        }catch(err){
            throw err;
        }
    }

    getAsk = async (nftAddress:string, tokenId:Number):Promise<Ask> => {
        try{
           const getask =  await this.contractInstance?.asks(nftAddress, tokenId);
           console.log("getask",getask)
           return getask;
        }catch(err){
            throw err;
        }
    }

    getBid = async (nftAddress:string, tokenId:Number):Promise<Bid>=>{
        try{
            return await this.contractInstance?.bids(nftAddress,tokenId);
        }catch(err){
            throw err;
        }
    }

    cancelAsk = async(nft:string[], tokenId:Number[]):Promise<object>=>{
        try{

            return await this.contractInstance?.cancelAsk(nft, tokenId);
        }catch(err){
            throw err;
        }
    }

    cancelBid = async(nft:string[], tokenId:Number[]):Promise<object>=>{
        try{
            return await this.contractInstance?.cancelBid(nft, tokenId);
        }catch(err){
            throw err;
        }
    }

    changeBenificiery = async (benificieryAddress:string):Promise<void> =>{
        try{
            await this.contractInstance?.changeBeneficiary(benificieryAddress);
        }catch(err){
            throw err
        }
    }

    createAsk = async(nft:string[], tokenId:Number[], price:Number[]):Promise<object> => {
        try{
            //pass value in the empty array if you want to reserve the sale for some specific address
            console.log("in the market place contract ==>", nft, tokenId, price, this.contractInstance);
           const response =  await this.contractInstance?.createAsk(nft, tokenId, price, ["0x0000000000000000000000000000000000000000"]);
           return response.wait();
        }catch(err) {
            throw err;
        }
    }

    createBid = async(nft:string[], tokenId:Number[], price:Number[]):Promise<object> => {
        try{
            console.log("in create bid function of market place contract", nft, tokenId, price, this.contractInstance);
            
            return await this.contractInstance?.createBid(nft, tokenId, price, {
                value: price[0]
            })
        }catch(err){
            throw  err;
        }
    }

    withdraw = async():Promise<void> =>{
        try{
            await this.contractInstance?.withdraw();
        }catch(err){
            throw err;
        }
    }
    
}