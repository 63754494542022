import { Snackbar, Alert } from "@mui/material"

const ToastMessageFailure = ({text,display,handleClose}:{text:string,display:boolean,handleClose:()=>void}) => {
return (
    <Snackbar  className="errorToast"
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={display}  onClose={handleClose}>
  <Alert
    onClose={handleClose}
    severity="error"
    variant="filled"
    sx={{ width: '100%' }}
  >
    {text}
  </Alert>
</Snackbar>
)
}

export default ToastMessageFailure