import React, { useEffect, useState } from "react";
import styles from "./NftLanding.module.css";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import NftCard from "../../components/nftCard/NftCard";
import { alpha } from "@mui/material";
import setUpWallet from "../../assets/svgs/walletSetup.svg";
import collectionSvg from "../../assets/svgs/createCollecton.svg";
import addNftSvg from "../../assets/svgs/addNft.svg";
import listNftSvg from "../../assets/svgs/listNft.svg";
import NftSection from "./nftSections/NftSection";
import creator1 from "../../assets/images/creator_1.png";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import { useNavigate } from "react-router-dom";
import circles from "../../assets/svgs/Landingcircle.svg";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import axios from "axios";
import { Environment } from "../../environments";
import { useTheme } from "@mui/system";
import MuiWalletConnect from "../../components/muiconnectwallet/Muiconnect";
import SimpleSlider from "../../components/Slider/SimpleSlider";

const NftLanding = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
    // display:"none"
  };

  const createAndSellList = [
    {
      icon: setUpWallet,
      title: "Set up your wallet",
      content:
        "Once you've set up your wallet of choice, connect it to metavoeux by clicking the wallet icon in the top right corner. Learn about the wallets we support.",
    },
    {
      icon: collectionSvg,
      title: "Create your collection",
      content:
        "Click My Collections and set up your collection. Add a collection name, a description, a profile & banner images.",
    },
    {
      icon: addNftSvg,
      title: "Add your NFTs",
      content:
        "Upload your work (image, video, audio, or 3D art), add a title, supply, description, and customise your NFTs with properties & stats.",
    },
    {
      icon: listNftSvg,
      title: "List them for sale",
      content:
        "Add your unique NFTs and list them for sale. We are here to offer extensive support throughout the selling process.",
    },
  ];

  const handleNftClick = () => {
    navigate("/overview");
  };

  const [ownNft, setOwnNft] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/ownnft`
      );
      console.log("response", response);
      setOwnNft(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handlePrimeViewAll = (data: any) => {
    navigate("/categoryNft", { state: { category: data } });
  };
  const [latestNft, setLatestNft] = useState([]);
  const [creatorData, setCreatorData] = useState([]);
  const getLatestData = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft?limit=200&page=1`
      );
      console.log("response latest data", response);
      const filetredData = response.data?.filter(
        (x: any) => x?.listingPrice !== undefined
      );
      console.log("response latest data", filetredData);
      // console.log("response", response);
      let newarra = filetredData.slice(0, 5);
      console.log("latest nft data", newarra);
      setLatestNft(newarra);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleShowLoader(true);
    fetchData()
      .then(() => {
        getLatestData();
        getFeaturedData();
        getTrendingData();
        getCollectionList();
        getAllCeatorData();
      })
      .then(() => handleShowLoader(false));
  }, []);
  const getAllCeatorData = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nftCollection/creator`
      );
      setCreatorData(response.data.slice(0, 8));
      console.log(response.data, "response-----------");
    } catch (error) {
      throw error;
    }
  };

  //featured nft api --------------------------------

  const [featuredNft, setFeaturedNft] = useState([]);
  const [trendingNft, setTrendingNft] = useState([]);
  const [collectionList, setCollectionList] = useState<any>([]);
  console.log("featurednft added", featuredNft);

  const getCollectionList = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nftCollection`
      );
      const collectionData = response.data.slice(0, 3).map((data: any) => {
        return {
          collectionName: data.contractName,
          imageUrl: data.url,
          walletAddress: data.currentOwner?.walletAddress,
          fullName: data.currentOwner?.fullName,
          collectionId: data._id,
          profileImage: data.currentOwner?.profilePic
            ? data?.currentOwner?.profilePic
            : creator1,
        };
      });
      console.log("getCollectionList", response);
      setCollectionList(collectionData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getFeaturedData = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/feature`
      );
      console.log("response featurednftadd data", response);
      const filetredData = response.data?.filter(
        (x: any) => x?.listingPrice !== undefined
      );
      console.log("response featurednftadd data", filetredData);
      setFeaturedNft(filetredData);
      console.log("featurednftadd", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getTrendingData = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/trending`
      );
      const filetredData = response.data?.filter(
        (x: any) => x?.listingPrice !== undefined
      );
      console.log("filetredData", filetredData);
      setTrendingNft(filetredData);
      console.log("featurednftadd", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCollectionClick = (collectionId: any, collectionName: any) => {
    navigate("/categoryNft", {
      state: {
        category: "collection",
        collectionId: collectionId,
        collectionName: collectionName,
      },
    });
  };

  const handleCollectionAllList = () => {
    navigate("/categoryNft", { state: { category: "collectionList" } });
  };
  //state to show loader
  const [showLoader, setShowLoader] = useState(false);
  const handleShowLoader = (active: boolean) => {
    setShowLoader(active);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);

  const [showBuyNowDialog, setShowBuyNowDialog] = useState<boolean>(false);
  const [urlValue, setUrlValue] = useState("");
  const handleMarketPlaceClick = () => {
    if (localStorage.getItem("account_address")) {
      navigate(`/nftoverview`);
    } else {
      setUrlValue(`/nftoverview`);
      setShowBuyNowDialog(true);
    }
  };

  const handleCreateNft = () => {
    if (localStorage.getItem("account_address")) {
      navigate(`/nftmarketplace`);
    } else {
      setUrlValue(`/nftmarketplace`);
      setShowBuyNowDialog(true);
    }
  };

  const handleRedirect = () => {
    navigate(`${urlValue}`);
  };
  const handleClose = () => {
    setShowBuyNowDialog(false);
  };
  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100vh",
            zIndex: 6,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",

          // mt: { lg: 3 },
          pt: 0,
          // "@media (min-width: 1721px)": {
          //   padding: "0% 10% 0% 10%",
          // },
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            height: { xs: "420px", sm: "550px", md: "600px", lg: "700px" },
            width: "100%",

            zIndex: 1,
            top: 0,
          }}
        >
          <video
            width="100%"
            style={{ width: "100%", objectFit: "cover", height: "inherit" }}
            playsInline
            height="inherit"
            autoPlay
            loop
            muted
            src="https://d24m5mu8cxqwfm.cloudfront.net/images/ggs.mp4"
          >
            {/* <source src="https://metavoeux-svgs.s3.ap-south-1.amazonaws.com/images/ggs.mp4" type="video/mp4"></source> */}
          </video>

          {/* <img src={Nft} style={{width:"inherit",height:"inherit"}}/> */}
        </Box>
        <Box
          sx={{
            position: "absolute",
            height: { xs: "420px", sm: "550px", md: "600px", lg: "700px" },
            background:
              "radial-gradient(76.75% 76.01% at 48.58% 52.68%, #000 0%, rgba(0, 0, 0, 0.70) 36.5%, rgba(0, 0, 0, 0.79) 81.68%, rgba(0, 0, 0, 0.42) 100%, #000 100%, rgba(0, 0, 0, 0.56) 100%)",
            zIndex: 2,
            width: "100%",
          }}
        ></Box>
        <Box
          className={styles.nftLanding}
          p={4}
          sx={{
            px: { xs: 1, sm: 3, md: 5 },
            pt: 0,
            "@media (min-width: 1721px)": {
              padding: "0% 10% 0% 10%",
            },
          }}
        >
          <Box sx={{ zIndex: 2 }}>
            <NewNftHeader nftHeader={true} walletwhite={true} />
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              ...circleStyle,
              top: { xl: "20%" },
              left: "0%",
              opacity: "0.1",
            }}
          >
            <img src={circles} alt="" draggable="false" />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              ...circleStyle,
              top: { xl: "40%" },
              right: "0%",
              opacity: "0.1",
              transform: "rotate(180deg)",
            }}
          >
            <img src={circles} alt="" draggable="false" />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              ...circleStyle,
              top: { xl: "60%" },
              left: "0%",
              opacity: "0.1",
            }}
          >
            <img src={circles} draggable="false" />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              ...circleStyle,
              top: { xl: "80%" },
              right: "0%",
              opacity: "0.1",
              transform: "rotate(180deg)",
            }}
          >
            <img src={circles} draggable="false" />
          </Box>
          <Box
            // display="flex"
            // alignItems="center"
            // flexDirection="column"
            // width="100%"
            sx={{
              "@media (max-width: 600px)": {
                padding: "0% 10px",
              },
            }}
          >
            <Box
              display="flex"
              flexDirection={{
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              }}
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "inherit",
                justifyContent: "space-between",
              }}
            >
              <Box
                display="flex"
                // alignItems="center"
                flexDirection="column"
                // justifyContent="flex-end"
                sx={{
                  height: "100%",
                  zIndex: 4,
                  width: { xs: "100%", md: "100%" },
                  alignItems: "center",
                  margin: { xs: 10, sm: 13, md: 18, lg: "130px 0 160px 0" },
                  "@media (max-width:350px)": {
                    margin: "60px 0",
                  },
                  marginLeft: 0,
                  marginRight: 0,
                  // border: '1px solid yellow',
                }}
              >
                {!isMobile && (
                  <>
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: {
                          xs: 20,
                          sm: 30,
                          md: 36,
                          lg: 52,
                          xl: 65,
                        },
                        fontWeight: 600,
                        lineHeight: 1.5,
                        letterSpacing: 1.35,
                        textTransform: "capitalize",
                      }}
                    >
                      Create, Buy and Sell{" "}
                      <span style={{ color: "#FFBB33" }}>NFTs</span>
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: {
                          xs: 20,
                          sm: 30,
                          md: 36,
                          lg: 52,
                          xl: 65,
                        },
                        fontWeight: 600,
                        lineHeight: 1.5,
                        letterSpacing: 1.35,
                        textTransform: "capitalize",
                      }}
                    >
                      on fully decentralized platform
                    </Typography>
                    {/* <Typography
                    sx={{
                      color: "white",
                      fontFamily: "outfit",
                      fontSize: {
                        xs: 20,
                        sm: 20,
                        md: 30,
                        lg: 45,
                        xl: 55,
                      },
                      fontWeight: 600,
                      lineHeight: 1.5,
                      letterSpacing: 1.35,
                      textTransform: "capitalize",
                    }}
                  >
                   
                  </Typography> */}
                    {/* <Typography
                    sx={{
                      color: "white",
                      fontFamily: "outfit",
                      fontSize: {
                        xs: 20,
                        sm: 20,
                        md: 30,
                        lg: 45,
                        xl: 55,
                      },
                      fontWeight: 600,
                      lineHeight: 1.5,
                      letterSpacing: 1.35,
                      textTransform: "capitalize",
                    }}
                  >
                   
                  </Typography> */}
                  </>
                )}

                {isMobile && (
                  <>
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: {
                          xs: 24,
                          sm: 20,
                          md: 30,
                          lg: 45,
                          xl: 55,
                        },
                        fontWeight: 600,
                        lineHeight: 1.5,
                        letterSpacing: 1.35,
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      Create, Buy and Sell{" "}
                      <span style={{ color: "#FFBB33" }}>NFTs</span>
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: {
                          xs: 24,
                          sm: 20,
                          md: 30,
                          lg: 45,
                          xl: 55,
                        },
                        fontWeight: 600,
                        lineHeight: 1.5,
                        letterSpacing: 1.35,
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      on fully decentralized platform
                    </Typography>
                    {/* <Typography
                    sx={{
                      color: "white",
                      fontFamily: "outfit",
                      fontSize: {
                        xs: 25,
                        sm: 20,
                        md: 30,
                        lg: 45,
                        xl: 55,
                      },
                      fontWeight: 600,
                      lineHeight: 1.5,
                      letterSpacing: 1.35,
                      textTransform: "capitalize",
                    }}
                  >
                    decentralized
                  </Typography> */}
                    <Typography
                      sx={{
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: {
                          xs: 25,
                          sm: 20,
                          md: 30,
                          lg: 45,
                          xl: 55,
                        },
                        fontWeight: 600,
                        lineHeight: 1.5,
                        letterSpacing: 1.35,
                        textTransform: "capitalize",
                      }}
                    ></Typography>
                  </>
                )}

                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "20px",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 3,
                      fontFamily: "outfit",
                      background:
                        "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                      fontSize: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 28,
                        xl: 28,
                      },
                      fontfamily: "outfit",
                    }}
                    onClick={() => handleCreateNft()}
                  >
                    Create NFT
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 3,
                      fontFamily: "outfit",
                      border: "1px solid #fff",
                      background: "transparent",
                      fontSize: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 28,
                        xl: 28,
                      },
                      fontfamily: "outfit",
                      "&:hover": {
                        background: "white",
                        color: "black",
                      },
                    }}
                    onClick={() => handleMarketPlaceClick()}
                  >
                    Marketplace
                  </Button>
                </p>
              </Box>
              <Box
                sx={{
                  height: {
                    xs: 300,
                    sm: 400,
                    md: 400,
                    lg: 600,
                  },
                  width: {
                    xs: 300,
                    sm: 300,
                    md: 400,
                    lg: 600,
                  },
                  display: "none", //wil chnage
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1,
                  marginTop: { xs: 0, md: 10 },
                }}
              >
                <img
                  src="https://d24m5mu8cxqwfm.cloudfront.net/images/bannerbackground.svg"
                  alt="img"
                  draggable="false"
                  height={"95%"}
                />
              </Box>

              {/* vector */}
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "0%" },
                  right: "0%",
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(140px)",
                  backdropFilter: "blur(140px)",
                  WebkitBackdropFilter: "blur(140px)",
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "0%" },
                    right: "0%",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                  },
                }}
              ></Box>

              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "10%" },
                  left: "0%",
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(140px)",
                  backdropFilter: "blur(140px)",
                  WebkitBackdropFilter: "blur(140px)",
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "0%" },
                    right: "0%",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                  },
                }}
              ></Box>

              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "25%" },
                  left: "0%",
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(140px)",
                  backdropFilter: "blur(140px)",
                  WebkitBackdropFilter: "blur(140px)",
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "0%" },
                    right: "0%",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                  },
                }}
              ></Box>
            </Box>

            {isMobile ? (
              <SimpleSlider
                data={ownNft}
                collectionCardClick={handleCollectionClick}
                heading="Prime NFTs"
                category="Prime"
              />
            ) : (
              <Box
                sx={{
                  mt: 10,
                  width: { xs: "100%", md: "100%" },
                  backgroundColor: alpha("#E0E0E0", 0.12),
                  borderRadius: 10,
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: "40px 50px",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    textAlign: "center",
                    fontSize: "2rem",
                    // fontStyle: "bold",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    mb: "30px",
                    fontFamily: "outfit",
                  }}
                >
                  Prime NFTs
                </Typography>

                <Grid
                  container
                  rowSpacing={4}
                  columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  {ownNft.slice(0, 8).map((nftItem) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <NftCard nftItem={nftItem} />
                      </Grid>
                    );
                  })}
                </Grid>
                <Button
                  variant="contained"
                  onClick={() => handlePrimeViewAll("Prime")}
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                    mt: "50px",
                    background:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                    fontSize: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 18,
                    },
                  }}
                >
                  View All
                </Button>
              </Box>
            )}

            <Box
              sx={{
                mt: 5,
                width: { xs: "100%", md: "100%", lg: "100%" },
              }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              position="relative"

              // border="1px solid white"
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "23px", md: "2rem" },
                  // fontSize: "2rem",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  fontFamily: "outfit",
                }}
              >
                Create and Sell Your NFTs
              </Typography>
              {/* <Typography
              textAlign="center"
              sx={{
                mt: 2,
                color: "white",
                fontSize: "22px",
                fontWeight: 300,
                textTransform: "capitalize",
                fontFamily: "outfit",
                // border:"1px solid white"
              }}
            >
              Dedicated, flexible spaces for meetings, brainstorms, team hangs,
              and everything in between.
            </Typography> */}
            </Box>
            <Box
              sx={{
                mt: 4,
                width: "100%",
                borderRadius: 10,
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                mb: 8,
              }}
            >
              <Grid container spacing={4}>
                {createAndSellList.map((element) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box
                        // padding="40px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        // border="1px solid white"
                        borderRadius={5}
                        sx={{
                          padding: { xs: "10px", md: "40px" },
                          background: alpha("#D9D9D91F", 0.12),
                        }}
                      >
                        <img src={element.icon} alt="img" draggable="false" />
                        <Typography
                          sx={{
                            m: 2,
                            whiteSpace: "nowrap",
                            fontFamily: "outfit",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                        >
                          {element.title}
                        </Typography>
                        <Typography
                          // width="80%"
                          // height="100%"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          textAlign="center"
                          sx={{
                            fontFamily: "outfit",
                            fontWeight: 300,
                            width: { xs: "100%", md: "80%" },
                            fontSize: { xs: "14px", md: "1 rem" },
                            height: { lg: "200px", xl: "140px", md: "200px" },
                          }}
                        >
                          {element.content}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {/* This is the end of create and sell cards */}
            {/* <Box>
          <Typography
        textAlign="center"
        sx={{
          color: "white",
          fontSize: "2rem",
          fontWeight: 700,
          textTransform: "capitalize",
          fontFamily: "outfit",
          mb: 4,
        }}
      >
        Featured NFTs
      </Typography>
            <SimpleSlider  
             category="feature"
            data={featuredNft}/>
          </Box> */}
            {/* <Box sx={{
            display:"flex",justifyContent:"center"
          }}> */}

            <NftSection
              cardclick={handleNftClick}
              heading="Featured NFTs"
              listData={featuredNft}
              category="feature"
            />
            {/* </Box> */}
            {isMobile ? (
              <>
                <SimpleSlider
                  data={collectionList}
                  isCollection={true}
                  collectionCardClick={handleCollectionClick}
                  heading=" Latest Collections"
                  handleViewAllAction={handleCollectionAllList}
                />
              </>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  width="90%"
                  borderRadius="1rem"
                  sx={{
                    background: alpha("#D9D9D91F", 0.12),
                    padding: { xs: "1rem", md: "2rem", lg: "3rem" },
                    width: { xs: "100%", md: "90%" },
                    marginTop: "80px",
                  }}
                >
                  <Typography
                    textAlign="center"
                    // width={"35%"}
                    // border={"1px solid white"}
                    sx={{
                      color: "white",
                      fontSize: "2rem",
                      fontWeight: 700,
                      fontFamily: "outfit",
                      width: { xs: "100%", md: "35%" },
                    }}
                  >
                    Latest Collections
                  </Typography>

                  <Box
                    // border={"1px solid white"}
                    width={"100%"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt={8}
                  >
                    <Grid container spacing={6}>
                      {collectionList.map((auction: any) => {
                        return (
                          <Grid item xs={12} sm={6} md={4}>
                            <Box
                              display="flex"
                              flexDirection="column"
                              padding={4}
                              borderRadius="1rem"
                              sx={{
                                cursor: "pointer",
                                background:
                                  "linear-gradient(46deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 70.72%)",
                                boxShadow:
                                  "1.97px -2.955px 2.955px 0px rgba(152, 89, 36, 0.15) inset, -1.97px 2.955px 2.955px 0px rgba(152, 89, 36, 0.15) inset",
                              }}
                              onClick={() =>
                                handleCollectionClick(
                                  auction.collectionId,
                                  auction.collectionName
                                )
                              }
                            >
                              {/* collectionName: data.collectionAddress,
          imageUrl: data.url,
        walletAddress:data.currentOwner?.walletAddress,
         fullName:data.currentOwner?.fullName,
         collectionId:data._id */}
                              <img
                                src={
                                  auction.imageUrl ? auction.imageUrl : creator1
                                }
                                alt="img"
                                draggable="false"
                                height="237px"
                                style={{
                                  objectFit: "cover",
                                  objectPosition: "0 0",
                                }}
                              />
                              <Typography
                                noWrap
                                sx={{
                                  mt: 3,
                                  mb: 3,
                                  color: "white",
                                  fontSize: "1.3rem",
                                  fontWeight: 700,
                                  fontFamily: "outfit",
                                }}
                              >
                                {auction.collectionName}
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                                sx={{
                                  flexDirection: { xs: "column", md: "row" },
                                }}
                              >
                                <Box
                                  display="flex"
                                  // flexDirection="column"
                                >
                                  <Box display="flex">
                                    <img
                                      src={auction?.profileImage}
                                      alt="img"
                                      draggable="false"
                                      style={{
                                        marginRight: 10,
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    <Box display="flex" flexDirection="column">
                                      <Typography
                                        color="#E0E0E0"
                                        fontSize={12}
                                        sx={{ fontFamily: "outfit" }}
                                      >
                                        Creator
                                      </Typography>
                                      <Typography sx={{ fontFamily: "outfit" }}>
                                        {auction.fullName
                                          ? auction.fullName
                                          : auction.walletAddress.slice(0, 8) +
                                            "....." +
                                            auction.walletAddress.slice(-8)}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: 3,
                      paddingLeft: 8,
                      paddingRight: 8,
                      mt: "50px",
                      background:
                        "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                      fontSize: {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 18,
                      },
                    }}
                    onClick={() => handleCollectionAllList()}
                  >
                    View All
                  </Button>
                </Box>
              </Box>
            )}

            <NftSection
              cardclick={handleNftClick}
              heading="Trending NFTs"
              listData={trendingNft}
              category="trending"
            />

            <NftSection
              cardclick={handleNftClick}
              heading="Latest NFTs"
              listData={latestNft}
              category="latest"
            />

            {!isMobile && (
              <Box
                borderRadius={"1.5rem"}
                sx={{
                  background: alpha("#E0E0E0", 0.12),
                  marginBottom: "50px",
                  padding: { xs: 2, md: "40px 50px" },
                  width: { xs: "100%" },
                  marginTop: "80px",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "2rem",
                    fontWeight: 600,
                    textTransform: "capitalize",
                    fontFamily: "outfit",
                  }}
                >
                  Creators
                </Typography>

                <Typography
                  sx={{
                    color: "white",
                    fontSize: "1.3rem",
                    fontWeight: 300,
                    textTransform: "capitalize",
                    mb: "40px",
                    fontFamily: "outfit",
                  }}
                >
                  Checkout Top Rated Creators on the NFT Marketplace
                </Typography>
                <Grid container spacing={8} sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      display: { xs: "none", sm: "none", md: "flex" },
                      ...circleStyle,
                      top: { xl: "25%" },
                      left: "0%",
                      transform: "translateY(-50%)",
                      width: "350px", // Adjusted width
                      height: "200px", // Adjusted height
                      borderRadius: "50%", // Ensures it remains a perfect circle
                      background: "#9D00FF",
                      filter: "blur(140px)",
                      backdropFilter: "blur(140px)",
                      WebkitBackdropFilter: "blur(140px)",
                      overflow: "hidden",
                      "@media (min-width: 1721px)": {
                        width: "450px", // Adjusted width
                        height: "400px", // Adjusted height
                        top: { xl: "0%" },
                        right: "0%",
                        filter: "blur(160px)",
                        backdropFilter: "blur(160px)",
                        WebkitBackdropFilter: "blur(160px)",
                      },
                    }}
                  ></Box>
                  {creatorData.length == 0 ? (
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "1.3rem",
                        fontWeight: 300,
                        textTransform: "capitalize",
                        mb: 8,
                        fontFamily: "outfit",
                      }}
                    >
                      Currently there are no creators
                    </Typography>
                  ) : (
                    creatorData.map((creator: any, index) => {
                      return (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            position="relative"
                            // padding={8}

                            pb={4}
                            pt={4}
                            borderRadius={"1.3rem"}
                            sx={{
                              background: "#181818",
                              padding: { xs: 1, md: 6 },
                            }}
                          >
                            <Box
                              border="1px solid white"
                              position="absolute"
                              height="10px"
                              width="10px"
                              display="flex"
                              alignItems={"center"}
                              justifyContent={"center"}
                              padding={2}
                              borderRadius="25px"
                              right={"60%"}
                              sx={{ left: "10px", top: "10px" }}
                            >
                              <Typography sx={{ fontFamily: "outfit" }}>
                                {index + 1}
                              </Typography>
                            </Box>

                            <img
                              src={
                                creator?.profilePic
                                  ? creator?.profilePic
                                  : creator1
                              }
                              alt="img"
                              draggable="false"
                              height="100px"
                              width="100px"
                              style={{
                                borderRadius: "100%",
                                position: "relative",
                              }}
                            />
                            <Typography
                              margin={4}
                              marginTop={1}
                              marginBottom={2}
                              sx={{
                                color: "white",
                                fontSize: "1.5rem",
                                fontWeight: 600,
                                textTransform: "capitalize",
                                fontFamily: "outfit",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px", // Adjust the width as per your design
                              }}
                            >
                              {creator?.fullName
                                ? creator?.fullName
                                : creator?.walletAddress?.slice(0, 5) +
                                  "..." +
                                  creator?.walletAddress?.slice(-5)}
                            </Typography>

                            <Typography
                              color={"#858584"}
                              display="flex"
                              alignItems="center"
                              width="100%"
                              justifyContent="space-between"
                              sx={{ fontFamily: "outfit" }}
                            >
                              Total Collection:{" "}
                              <span style={{ color: "#FFF" }}>
                                {creator.collectionCount}{" "}
                              </span>
                            </Typography>
                            <Typography
                              color={"#858584"}
                              display="flex"
                              alignItems="center"
                              width="100%"
                              justifyContent="space-between"
                              sx={{ fontFamily: "outfit" }}
                            >
                              Total NFT:{" "}
                              <span style={{ color: "#FFF" }}>
                                {creator.nftCount}{" "}
                              </span>
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })
                  )}
                  {}
                </Grid>
              </Box>
            )}
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                ...circleStyle,
                top: { xl: "45%" },
                right: "0%",
                transform: "translateY(-50%)",
                width: "350px", // Adjusted width
                height: "200px", // Adjusted height
                borderRadius: "50%", // Ensures it remains a perfect circle
                background: "#9D00FF",
                filter: "blur(140px)",
                backdropFilter: "blur(140px)",
                WebkitBackdropFilter: "blur(140px)",
                overflow: "hidden",
                "@media (min-width: 1721px)": {
                  width: "450px", // Adjusted width
                  height: "400px", // Adjusted height
                  top: { xl: "0%" },
                  right: "0%",
                  filter: "blur(160px)",
                  backdropFilter: "blur(160px)",
                  WebkitBackdropFilter: "blur(160px)",
                },
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent", // Set the dialog background to transparent
            boxShadow: "none", // Remove the default Material-UI box shadow
          },
        }}
        open={showBuyNowDialog}
        onClose={handleClose}
        maxWidth={maxWidth}
      >
        <DialogContent>
          <MuiWalletConnect
            handleClose={() => handleClose()}
            buyConnect={true}
            handleRedirect={() => handleRedirect()}
          />
        </DialogContent>
      </Dialog>
      <LandingPageFooter />
    </>
  );
};

export default NftLanding;
