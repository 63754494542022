import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import logo from "../../assets/svgs/metavoeux_logo.svg";
import email from "../../assets/svgs/email.svg";
import { useLocation, useNavigate } from "react-router-dom";
import FacebookWhite from "../../assets/svgs/facebook-white.svg";
import TelegramWhite from "../../assets/svgs/Telegram-white.svg";
import InstagramWhite from "../../assets/svgs/InstagramWhite.svg";
import TwitterWhite from "../../assets/svgs/Twitter-white.svg";
import DiscordWhite from "../../assets/svgs/Discord-white.svg";
import qrCodeNew from "../../assets/images/qrCodeNew1.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import mobilelogo from "../../assets/svgs/logomobile.svg";
import mobileqr from "../../assets/svgs/qrmobile.svg";
import instam from "../../assets/svgs/Insta m.svg";
import facebookm from "../../assets/svgs/facebook m.svg";
import youtubem from "../../assets/svgs/y m.svg";
import quoram from "../../assets/svgs/Q m.svg";
import twiiterm from "../../assets/svgs/twitter m.svg";
import discordm from "../../assets/svgs/discord m.svg";
import mediumm from "../../assets/svgs/medium m.svg";
import whtsppm from "../../assets/svgs/whatsapp m.svg";
import telegramm from "../../assets/svgs/telg.svg";

const LandingPageFooter = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const location = useLocation();

  const navigate = useNavigate();
  const footerList = [
    {
      heading: "Company",
      items: ["About Us", "Careers", "All Services"],
      clickable: true,
    },
    {
      heading: "Resources",
      items: ["Blog", "Documentation", "FAQs"],
      clickable: true,
    },
    {
      heading: "Products",
      items: [
        "NFT MarketPlace",
        "Voeux Farming",
        "Voeux Game",
        "Voeux Token",
        "Voeux Pay",
      ],
      clickable: true,
    },
    {
      heading: "Contact Us",
      items: ["info@metavoeux.io"],
      clickable: true,
    },
  ];

  const handleNavigate = (element: any) => {
    console.log("elemnegt", element);
    if (element === "Blog") {
      navigate("/blog");
    } else if (element === "About Us") {
      navigate("/about");
    } else if (element === "FAQs") {
      navigate("/faq");
    } else if (element === "VX Money") {
      navigate("/voeuxmoney");
    } else if (element === "Voeux Pay") {
      navigate("/voeuxpay");
      // window.location.href = "https://voeuxpay.com/";
    } else if (element === "Careers") {
      navigate("/career");
    } else if (element === "All Services") {
      navigate("/loadmore");
    } else if (element === "Documentation") {
      window.open(
        require("../../assets/pdf/MetavoeuxWhitePaper.pdf"),
        "_blank"
      );
    } else if (element == "Voeux Token") {
      navigate("/voeuxtoken");
    } else if (element === "Voeux Game") {
      navigate("/voeuxGame");
      // window.location.href = "/voeuxGame";
    } else if (element === "Voeux Farming") {
      navigate("/voeuxfarming");
    } else if (element === "NFT MarketPlace") {
      navigate("/nft");
    }
  };

  const socialPlatforms = [
    { icon: instam, link: "https://instagram.com/metavoeux/" },
    { icon: facebookm, link: "https://www.facebook.com/MetaVoeuxEcosystem" },
    { icon: youtubem, link: "https://youtube.com/@metavoeux/videos" },
    { icon: telegramm, link: "https://t.me/metavoeux" },
    { icon: twiiterm, link: "https://twitter.com/MetaVoeux" },
    { icon: discordm, link: "https://discord.gg/YrC7npAQ53" },
    { icon: mediumm, link: "https://medium.com/@metavoeuxit" },
    {
      icon: whtsppm,
      link: "https://whatsapp.com/channel/0029Va48rBCInlqIjvTfTs0x",
    },
    { icon: quoram, link: "https://quora.com/profile/Metavoeux" },
  ];

  const handleDocumentationClick = () => {
    window.open("../../assets/pdf/MetavoeuxWhitePaper.pdf", "_blank");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#0F0F0F",
        position: "relative",

        "@media (min-width: 1721px)": {
          paddingX: "10%",
        },
      }}
      padding={{
        xs: 2,
        md: 4,
      }}
    >
      {!isMobile && (
        <>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 5, md: 20 }}
            sx={{
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              sx={
                {
                  // width: {
                  //   xs:"100vw",
                  //   md:"18%",
                  // },
                }
              }
            >
              <Box
                sx={{
                  cursor:"pointer",
                  width: {
                    xs: "75%",
                    md: "100%",
                  },
                  height: {
                    xs: "80%",
                    md: "100%",
                  },
                }}
                onClick={() =>   navigate("/")}
              >
                <img src={logo} alt="img" draggable="false" width={"70%"} />
              </Box>
              <Stack direction="row" spacing={1}>
                <Box>
                  <img src={qrCodeNew} draggable="false" width="88px" height="88px" alt="" />
                </Box>
              </Stack>
              <Typography fontSize={"14px"} sx={{ fontFamily: "outfit" }}>
                Scan to download the MetaVoeux Ecosystem.
              </Typography>
            </Stack>
            <Box
              display={{
                xs: "Column",
                md: "row",
              }}
              sx={{
                // width: "100%",
                // display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <Grid container rowGap={5} max-width="100vw">
                {footerList.map((footerItem) => {
                  return (
                    <Grid item xs={6} sm={6} lg={3}>
                      <Typography
                        sx={{
                          color: "#FFF",
                          fontSize: { xs: "16px" },
                          // textAlign:"right",
                          fontWeight: 500,
                          mb: 2,
                          fontFamily: "outfit",
                        }}
                      >
                        {footerItem.heading}
                      </Typography>
                      <Grid
                        container
                        rowGap={1}
                        sx={
                          {
                            // backgroundColor:"grey",
                            // width: "max-content",
                          }
                        }
                      >
                        {footerItem.items.map((element) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                ...(footerItem.clickable === true && {
                                  cursor: `pointer`,
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }),
                              }}
                            >
                              {footerItem.heading === "Contact Us" && (
                                <Typography
                                  display="flex"
                                  alignItems="center"
                                  gap={2}
                                  textAlign={"right"}
                                  sx={{
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                  }}
                                >
                                  {!isMobile && (
                                    <span>
                                      <img src={email} alt="img" draggable="false" />
                                    </span>
                                  )}
                                  {element}
                                </Typography>
                              )}
                              {element === "Careers" ? (
                                <Box
                                  sx={{ display: "flex", columnGap: "10px" }}
                                >
                                  {" "}
                                  <Typography
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                    textAlign={"right"}
                                    sx={{
                                      fontSize: "14px",
                                      fontFamily: "outfit",
                                    }}
                                    onClick={() => {
                                      handleNavigate(element);
                                    }}
                                  >
                                    {element}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      padding: "5px 10px",

                                      background:
                                        "linear-gradient(102deg, #FF990D 0%, #9D00FF 100%)",

                                      borderRadius: "20px",
                                      fontSize: "12px",
                                      color: "#fff",
                                      fontWeight: "400px",
                                      lineHeight: "14.40px",
                                    }}
                                  >
                                    We’re hiring!
                                  </Typography>
                                </Box>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                  }}
                                  onClick={() => {
                                    handleNavigate(element);
                                  }}
                                >
                                  {footerItem.heading !== "Contact Us" &&
                                    element}
                                </Typography>
                              )}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Stack>
          <Stack>
            <Box
              sx={{
                // width: "100%",
                mt: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                rowGap: "20px",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "outfit",
                  "@media (max-width: 600px)": {
                    textAlign: "center",
                  },
                }}
              >
                Voeux Information Technology LLC © 2023 - 2024. All Rights
                Reserved
              </Typography>
              <Box>
                <a href="https://twitter.com/MetaVoeux" target="_blank">
                  <img style={{ marginRight: "10px" }} draggable="false" src={TwitterWhite} alt="" />
                </a>
                <a href="https://t.me/metavoeux" target="_blank">
                  <img style={{ marginRight: "10px" }} draggable="false" src={TelegramWhite}alt="" />
                </a>
                <a href="https://discord.gg/YrC7npAQ53" target="_blank">
                  <img style={{ marginRight: "10px" }} draggable="false"src={DiscordWhite}alt="" />
                </a>
                <a
                  href="https://www.facebook.com/MetaVoeuxEcosystem"
                  target="_blank"
                >
                  <img style={{ marginRight: "10px" }} draggable="false" src={FacebookWhite}alt="" />
                </a>
                <a href="https://instagram.com/metavoeux/" target="_blank">
                  <img style={{ marginRight: "10px" }} draggable="false" src={InstagramWhite} alt=""/>
                </a>
              </Box>
            </Box>
          </Stack>
        </>
      )}

      {/* for mobile */}
      {isMobile && (
        <Box>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 5, md: 20 }}
            sx={{
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Stack direction="column" spacing={2}>
              <Box
                sx={{
                  width: {
                    xs: "50%",
                  },
                  height: {
                    xs: "50%",
                  },
                }}
              >
                <img draggable="false" src={mobilelogo} alt="" width={"100%"} />
              </Box>
              <Stack direction="row" spacing={1}>
                <Box>
                  <img
                    draggable="false"
                    src={mobileqr}
                    width="88px"
                    height="88px"
                    alt=""
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>

          <Box>
            {" "}
            <Typography
              fontSize={"18px"}
              sx={{
                fontFamily: "outfit",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              Community
            </Typography>
            <Stack direction="row" spacing={1.3}>
              {socialPlatforms.map((platform, index) => (
                <Box
                  sx={{
                    "@media (max-width: 400px)": {
                      width: "20px",
                    },
                    "@media (max-width: 270px)": {
                      width: "11px",
                    },
                  }}
                >
                  <a
                    key={index}
                    href={platform.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "-webkit-fill-available" }}
                      draggable="false"
                      src={platform.icon}
                      alt={`icon-${index}`}
                    />
                  </a>
                </Box>
              ))}
            </Stack>
          </Box>

          <div className="customAccordion">
            <Accordion
              style={{
                backgroundColor: "transparent",
                color: "#fff",

                boxShadow: "none !important",
              }}
            >
              <AccordionSummary
                sx={{
                  fontSize: "18px",
                  margin: "0px !important ",
                  fontFamily: "outfit",
                  color: "#fff",
                  padding: "0px !important",
                }}
                expandIcon={<KeyboardArrowDownIcon style={{ color: "#fff" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Company
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  onClick={() => {
                    navigate("/about");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  About Us
                </Typography>
                <Box
                  sx={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      onClick={() => {
                        navigate("/career");
                      }}
                      sx={{
                        fontSize: "15px",
                        fontFamily: "outfit",
                        marginBottom: "10px",
                        marginRight: "6px",
                      }}
                    >
                      Careers
                    </Typography>
                  </Box>
                  <Box
                    sx={{ width: "100px", height: "27px", textAlign: "center" }}
                  >
                    <Typography
                      sx={{
                        padding: "5px",

                        background:
                          "linear-gradient(102deg, #FF990D 0%, #9D00FF 100%)",

                        borderRadius: "20px",
                        fontSize: "12px",
                        color: "#fff",
                        fontWeight: "400px",
                        lineHeight: "14.40px",
                      }}
                    >
                      We’re hiring!
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  onClick={() => {
                    navigate("/loadmore");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                  }}
                >
                  All Services
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "transparent",
                color: "#fff",
                boxShadow: "none !important",
              }}
            >
              <AccordionSummary
                sx={{
                  fontSize: "18px",
                  fontFamily: "outfit",
                  padding: "0px !important",
                }}
                expandIcon={<KeyboardArrowDownIcon style={{ color: "#fff" }} />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Contact Us
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  display="inline-flex"
                  alignItems="center"
                  lineHeight={0}
                  gap={2}
                  textAlign={"right"}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    color: "#fff",
                  }}
                >
                  <span>
                    <img src={email}  draggable="false"alt="img" />
                  </span>
                  support@metavoeux.io
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "transparent",
                color: "#fff",
                boxShadow: "none !important",
              }}
            >
              <AccordionSummary
                sx={{
                  fontSize: "18px",
                  fontFamily: "outfit",
                  padding: "0px !important",
                }}
                expandIcon={<KeyboardArrowDownIcon style={{ color: "#fff" }} />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Products
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  onClick={() => {
                    navigate("/nft");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  NFT Marketplace
                </Typography>
                <Typography
                  onClick={() => {
                    navigate("/voeuxfarming");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  Voeux Farming
                </Typography>
                <Typography
                  onClick={() => {
                    navigate("/voeuxGame");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  Voeux Game
                </Typography>
                <Typography
                  onClick={() => {
                    navigate("/voeuxtoken");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  Voeux Token
                </Typography>
                <Typography
                  onClick={() => {
                    navigate("/voeuxpay");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                  }}
                >
                  Voeux Pay
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "transparent",
                color: "#fff ",
                boxShadow: "none !important",
              }}
            >
              <AccordionSummary
                sx={{
                  fontSize: "18px",
                  fontFamily: "outfit",
                  padding: "0px !important",
                }}
                expandIcon={<KeyboardArrowDownIcon style={{ color: "#fff" }} />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                Resources
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  onClick={() => {
                    navigate("/blog ");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  Blog
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                  onClick={handleDocumentationClick}
                >
                  Documentation
                </Typography>
                <Typography
                  onClick={() => {
                    navigate("/faq");
                  }}
                  sx={{ fontSize: "15px", fontFamily: "outfit" }}
                >
                  FAQ
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <Box
            sx={{
              borderBottom: "1px solid #fff",
              width: "auto",
              margin: "0 auto",
              marginTop: "40px",
              opacity: "0.20",
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mt={3}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "outfit",
                color: "#CCCCCC",
                textAlign: isMobile ? "center" : "initial",
              }}
            >
              Voeux Information Technology LLC © 2023 - 2024. All Rights
              Reserved
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default LandingPageFooter;
