import React from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import innovativeimage from "../../assets/svgs/Innovative Technology.svg";
import allinoneimage from "../../assets/svgs/eco system.svg";
import Sovereignimage from "../../assets/svgs/Independent.svg";
const cardData1 = [
  {
    id: 1,
    image: innovativeimage,
    title: "Innovative Technology",
    caption:
      "Metavoeux uses advanced blockchain tech to provide safe and clear financial solutions. We're on a mission to revolutionize digital experiences using safe and secure tech.",
  },
  {
    id: 2,
    image: allinoneimage,
    title: "All in one Ecosystem (App)",
    caption:
      "We have many different apps (Dapps) for many purposes, including finance, entertainment, and games.",
  },
  {
    id: 3,
    image: Sovereignimage,
    title: "Sovereign",
    caption:
      "Our model, which doesn't rely on trust and doesn't involve keeping your assets in someone else's care, ensures that your privacy and security are well-protected.",
  },
];

const cardData2 = [
  {
    id: 4,
    image: innovativeimage,
    title: "Community-Driven",
    caption:
      "Our community is really important to us. They have a strong say in making decisions and guiding the future of Voeux. Our community is passionate, engaged, and much more.",
  },
  {
    id: 5,
    image: allinoneimage,
    title: "Experienced Team",
    caption:
      "Our team is experts in blockchain, finance, games and web3.0 Technology.",
  },
];
const Muicard = () => {
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    // flexShrink: 0,
  };
  return (
    <Box>
      {/* First row */}
      <Stack sx={{ px: { lg: "10%", xl: "15%" } }} direction={"column"}>
        <Grid container spacing={2} sx={{ position: "relative" }}>
          {cardData1.map((card, index) => (
            <Grid
              item
              key={card.id}
              xs={12}
              sm={6}
              md={index === 2 ? 12 : 6}
              lg={index > 2 ? 6 : 4}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <Card
                sx={{
                  backgroundColor: "#9D00FF",
                  color: "#fff",
                  height: { lg: "280px", xl: "310px" },
                  width: { lg: "280px", xl: "280px" },
                  borderRadius: { xs: "24px", md: "15.558px" },
                  backdropFilter: "blur(30px)",
                  background: "rgba(255, 255, 255, 0.09)",
                  // cursor: "pointer",
                  // ":hover": {
                  //   transform: "translateY(-10px)",
                  //   boxShadow: "rgba(236, 233, 233, 0.35) 10px 10px 10px",
                  // },
                }}
              >
                <Box>
                  <CardMedia
                    component="img"
                    style={{
                      width: "50px",
                      height: "50px",
                      margin: "1rem",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "1.5rem",
                      padding: 3,
                    }}
                    sx={{
                      backgroundColor: "#A719FF",
                    }}
                    src={card.image}
                    alt={card.title}
                    draggable="false"
                  />
                </Box>

                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "white",
                      fontFamily: "outfit",
                      fontSize: { xs: "20px", md: "20px" },
                      fontWeight: "600",
                      margin: "0px 10px 2px",
                    }}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="white"
                    sx={{
                      fontFamily: "outfit",
                      fontSize: { xs: "16px" },
                      fontWeight: "300",
                      margin: "0px 10px 2px",
                      // textAlign: "justify",
                    }}
                  >
                    {card.caption}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>

      {/* Second row */}
      <Stack sx={{ px: { lg: "10%", xl: "15%" } }}>
        <Grid container justifyContent="center" spacing={3}>
          {cardData2.map((card, index) => (
            <Grid
              item
              key={card.id}
              xs={12}
              sm={6}
              md={index === 1 ? 12 : 6}
              lg={index > 1 ? 6 : 4}
              style={{
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <Card
                sx={{
                  backgroundColor: "#9D00FF",
                  color: "#fff",
                  height: { lg: "280px", xl: "310px" },
                  width: { lg: "280px", xl: "280px" },
                  borderRadius: { xs: "24px", md: "15.558px" },
                  backdropFilter: "blur(30px)",
                  background: "rgba(255, 255, 255, 0.09)",
                  // cursor: "pointer",
                  // ":hover": {
                  //   transform: "translateY(-10px)",
                  //   boxShadow: "rgba(236, 233, 233, 0.35) 10px 10px 10px",
                  // },
                }}
              >
                <Box>
                  <CardMedia
                    component="img"
                    style={{
                      width: "50px",
                      height: "50px",
                      margin: "1rem",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "1.5rem",
                      padding: 3,
                    }}
                    sx={{
                      backgroundColor: "#A719FF",
                    }}
                    src={card.image}
                    alt={card.title}
                    draggable="false"
                  />
                </Box>

                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "white",
                      fontFamily: "outfit",
                      fontSize: { xs: "20px", md: "20px" },
                      fontWeight: "600",
                      margin: "0px 5px 2px",
                    }}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="white"
                    sx={{
                      fontFamily: "outfit",
                      fontSize: { xs: "16px" },
                      fontWeight: "300",

                      margin: "0px 5px 5px",
                    }}
                  >
                    {card.caption}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default Muicard;
