import Stack from '@mui/material/Stack';
import BannerImg from "../../assets/images/banner.png";
  function Voeuxbanner() {
    return (
<Stack  direction="column" 
  sx={{ 
    width: { xs: "100%", md: 'unset' },
    padding:1
}}
>
 <img src={BannerImg} alt='bannerimage'  />
</Stack>

    );
  }
  
  export default Voeuxbanner
