import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  ClickAwayListener,
  Tooltip,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogProps,
  Menu,
  MenuItem,
  Popper,
} from "@mui/material";
import { Box, Stack, maxWidth, useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import logo from "../../assets/svgs/metavoeux_logo.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import wallet from "../../assets/VoeuxGame/wallet.svg";

import walletYellow from "../../assets/VoeuxGame/walletYellow.svg";
import MuiWalletConnect from "../../components/muiconnectwallet/Muiconnect";
import axios from "axios";
import { Environment } from "src/environments";
import ProfileLogout from "../../assets/svgs/ProfilelogoutBlack.svg";
import smallcoin from "../../assets/VoeuxGame/smallcoin.svg";

type Anchor = "right";

const VoeuxGameHeader = ({menubar=true,reload=false}:{menubar?:any,reload?:any}) => {

// const [reload1, setReload] = useState(reload)
const account_address = localStorage.getItem("account_address");
const email_value = localStorage.getItem("gameEmail")
const [ emailValue, setEmailValue] = useState(email_value)
useEffect(()=>{
  setEmailValue(email_value)
})
  // useEffect(() =>{
  //   // getUserPointsApi()
  // })
  const location = useLocation() 
  useEffect(() => {
    const walletAddress = localStorage.getItem("account_address")
    if(location.pathname == "/gameprofile" ){
      if(!walletAddress){
        handleWallet()
      } 

    }
  },[])
  useEffect(() =>{
    getUserPointsApi()
  },[emailValue])
  const [hideHeader, setHideHeader] = useState(false)
  useEffect(() => {
    if(reload){
      if(email){
        setHideHeader(true);
        //  getUserPointsApi()
      }else{
        setHideHeader(false)
      }
     
     
    }else{
      setHideHeader(false)
    }
  })
  let { type } = useParams();
  console.log("use parms", type);
  const [type1, setType] = useState(type);
  useEffect(() => {}, [type]);
  const navigate = useNavigate();



  const handleLogoClick = () => {
    navigate("/");
  };
  const UserRegistration = (type: any) => {
    navigate(`/user/${type}`);
  };


  const mobileMenuId = "primary-search-account-menu-mobile";
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === "right" ? 300 : "auto",
        backgroundColor: "black",
        color: "white",
        height: "200%",

        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
    >
      <Box>
        <List>
          <Box>
            <div
              style={{
                backgroundColor: "#1A0E1C",
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                position: "absolute",
                zIndex: 1,
                right: 15,
                top: 13,
              }}
            >
              <button
                onClick={toggleDrawer(anchor, false)}
                style={{
                  backgroundColor: "black",
                  color: "yellow",
                  border: "none",
                  fontSize: "25px",
                }}
              >
                X
              </button>
            </div>
          </Box>
          <Box sx={{ marginTop: "40px", padding: "10px" }}>
            <Box>
              <List sx={{ padding: "0 !important" }}>
                <ListItem>
                  <ListItemText
                    className="blendarPro"
                    sx={{
                      textTransform: "uppercase",
                      fontFamily: "Blender Pro",
                      fontSize: " 16px",
                      fontWeight: "700;",
                    }}
                    primary="Signin"
                    onClick={() => UserRegistration("signin")}
                  />
                </ListItem>
              </List>
            </Box>

            <Box>
              <List sx={{ padding: "0 !important" }}>
                <ListItem>
                  <ListItemText
                    className="blendarPro"
                    sx={{
                      textTransform: "uppercase",
                      fontFamily: "Blender Pro",
                      fontSize: " 16px",
                      fontWeight: "700;",
                    }}
                    onClick={() => UserRegistration("register")}
                    primary="Register"
                  />
                </ListItem>
              </List>
            </Box>
          </Box>
        </List>
      </Box>
    </Box>
  );

  const isMobile = useMediaQuery("(max-width:600px)");
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const [showWallet, setShowWallet] = useState(false);

  const handleWallet = () => {
    setShowWallet(true);
  };

  const closeHandleWallet = () => {
    setShowWallet(false);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);


  useEffect(() => {
    const fetchUserDetails = async () => {
      console.log("fetchUserDetails == >");
      try {
        // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
        const response = await axios.get(
          `${Environment.metavoeuxBackend}user/userDetails?walletAddress=${account_address}`
        );
        console.log("response nft", response.data);
        // formdata.user  = response.data
        // handleShowLoader()
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserDetails();
  },[]);


  const userLogout = () => {
    localStorage.removeItem("gameEmail")
    localStorage.removeItem("token")
    setEmail("")
    setHideHeader(false)
    navigate("/voeuxGame")
  }
  useEffect(() =>{

  },[hideHeader])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDisplayLogout = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.removeItem("account_address")
    localStorage.removeItem("ownUser")
    localStorage.removeItem("userId")
    handleMenuClose();
    navigate("/voeuxGame")
  };

  const menuId = "primary-search-account-menu";

  // focus out
  const handleCloseWalletClickAway = (event: Event | React.SyntheticEvent) => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
      return;
    }
    setAnchorEl(null);
  };
const [email, setEmail] = useState<any>("")
  useEffect(() => {
    const email = localStorage.getItem("gameEmail")
    setEmail(email)
    // getUserPointsApi()
  },[email])
  const renderMenu = (
    <Popper
      className="profileMenu"
      anchorEl={anchorEl}
      placement="bottom-start"
      id={menuId}
      keepMounted
      open={isMenuOpen}
    >
      <ClickAwayListener onClickAway={handleCloseWalletClickAway}>
        <MenuItem
          sx={{
            background: "#fdee0c",
            color: "black",
            padding: "10px 20px 10px 20px",
            "&:hover": {
              background: "#fdee0c",
            },
          }}
          onClick={() => handleLogout()}
        >
          <ListItemIcon>
            <img src={ProfileLogout} />
          </ListItemIcon>
          <Typography variant="body2" sx={{ color: "black", ml: "10px" }}>
            Logout
          </Typography>
        </MenuItem>
      </ClickAwayListener>
    </Popper>
  );

  useEffect(() => {
    console.log("useeffect", account_address);
  }, [account_address]);


  const [ userResponse, setUserResponse] = useState<any>()
  const getUserPointsApi = async () => { 
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}game/all-point?email=${email_value}`
      );
      console.log("game user point ", response);

      const gamedetails = {
        totalPoint: Number(response?.data?.data?.VirtualCurrency?.["CN"]) + Number(response?.data?.data?.VirtualCurrency?.["CR"]),
      }
      setUserResponse({...gamedetails})
    }catch(error){
     console.log("Error in reading the points")
    }
  }
console.log("userResponse",userResponse)
  return (
    <>
      <Box
        sx={{
          px: 2,
          padding: { xs: "10px 20px", lg: "0px !important" },
          "@media(max-width:300px)":{
            padding:"0px"
          }
        }}
      >
        <AppBar
          position="static"
          elevation={0}
          sx={{
            backgroundColor: "transparent",
            borderBottom: "none",
            px: { md: 0 },
          }}
        >
          <Toolbar
            sx={{
              height: "6rem",
              px: 0,
              padding: "0px !important",

              // padding: location.pathname === "/" ? "0 !important" :""
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {/* firstbox */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  "@media(max-width:300px)":{
                    width:"80%"
                  }
                }}
              >
                {/* for showing metavoeux logo in mobile view */}
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{
                    display: { xl: "none", sm: "flex", md: "none" },
                    px: 0,
                    "@media(max-width:300px)":{
                      width:"inherit"
                    }
                  }}

                >
                  <img
                    draggable="false"
                    src={logo}
                    alt="log"
                    onClick={handleLogoClick}
                    style={{width:"inherit"}}
                  />
                </IconButton>
                <Typography
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  gap={2}
                  sx={{
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <img
                    draggable="false"
                    src={logo}
                    alt="logo"
                    style={{ cursor: "pointer" }}
                    onClick={handleLogoClick}
                  />
                </Typography>
                <Box sx={{display:"flex",alignItems:"center",columnGap:"20px"}}>
                {email_value && <Box sx={{display:"flex",columnGap:"5px"}}> <img draggable="false" src={smallcoin} alt="img" />{" "}
                <Typography>{isMobile ? "" : "VX POINTS:" }  {userResponse?.totalPoint} </Typography></Box> }
                {!isMobile && menubar == true && (
                  <Box className="menu  headerdesktop">
                    <List
                      className="menu-list"
                      sx={{ padding: "0 !important" }}
                    >
                      {account_address ? (
                        <ListItem
                          className="menu-item"
                          onClick={(e) => handleDisplayLogout(e)}
                        >
                          <img
                            width={25}
                            draggable="false"
                            src={wallet}
                            alt="wallet"
                          />
                          <ListItemText
                            className="blendarPro"
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Blender Pro",
                              fontSize: " 16px",
                              fontWeight: "700;",
                            }}
                            primary={
                              account_address.slice(0, 3) +
                              "..." +
                              account_address.slice(-3)
                            }
                          />
                        </ListItem>
                      ) : (
                        <ListItem
                          className="menu-item"
                          onClick={() => handleWallet()}
                        >
                          <img
                            width={25}
                            draggable="false"
                            src={wallet}
                            alt="wallet"
                          />
                          <ListItemText
                            className="blendarPro"
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Blender Pro",
                              fontSize: " 16px",
                              fontWeight: "700;",
                            }}
                            primary="Wallet"
                          />
                        </ListItem>
                      )}
                      <ListItem
                        className="menu-item"
                        sx={{
                          padding: "0px !important",
                          visibility: type == "signin" ? "hidden" : "visible",
                        }}
                      >
                        <ListItemText className="" primary="|" />
                      </ListItem>
                      {email_value ?<><ListItem
                        className={
                          type == "logout"
                            ? "menu-item filledMenu"
                            : "menu-item"
                        }
                      >
                        <ListItemText
                          className="blendarPro"
                          primary="Logout"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "Blender Pro",
                            fontSize: " 16px",
                            fontWeight: "700px",
                          }}
                          onClick={() => userLogout()}
                          // onClick={UserRegistration}
                        />
                      </ListItem></> : <> <ListItem
                        className={
                          type == "signin"
                            ? "menu-item filledMenu"
                            : "menu-item"
                        }
                      >
                        <ListItemText
                          className="blendarPro"
                          primary="SignIn"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "Blender Pro",
                            fontSize: " 16px",
                            fontWeight: "700px",
                          }}
                          onClick={() => UserRegistration("signin")}
                          // onClick={UserRegistration}
                        />
                      </ListItem>
                      <ListItem
                        className={
                          type == "register"
                            ? "menu-item filledMenu"
                            :  type == "signin" ? "menu-item " :" menu-item filledMenu"
                           
                        }
                      >
                        <ListItemText
                          className="blendarPro"
                          primary="Register"
                          sx={{
                            fontSize: "16px",
                            textTransform: "uppercase",
                            fontStyle: "normal",
                            fontWeight: "700px",
                          }}
                          // onClick={UserRegistration}
                          onClick={() => UserRegistration("register")}
                        />
                      </ListItem></>}
                     
                    </List>
                  </Box>
                )}

                {isMobile && menubar == true && (
                  <Box
                    className=" headersection"
                    sx={{
                      marginTop: "14px",
                      marginLeft: "12px",
                      "@media(max-width:300px)":{
                        marginTop: "0px",
                        marginLeft: "unset",
                      }
                    }}
                  >
                    <List
                      className="menu-list"
                      sx={{ padding: "0 !important", height: "35px" }}
                    >
                      {account_address ? (
                        <ListItem
                          className="menu-item"
                          onClick={(e) => handleDisplayLogout(e)}
                        >
                          <img
                            width={25}
                            draggable="false"
                            src={walletYellow}
                            alt="wallet"
                          />
                          {/* <ListItemText
                            className="blendarPro"
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Blender Pro",
                              fontSize: " 16px",
                              fontWeight: "700;",
                            }}
                            primary={
                              account_address.slice(0, 3) +
                              "..." +
                              account_address.slice(-3)
                            }
                          /> */}
                        </ListItem>
                      ) : (
                        <ListItem
                          className="menu-item"
                          onClick={() => handleWallet()}
                        >
                          <img
                            width={25}
                            draggable="false"
                            src={wallet}
                            alt="wallet"
                          />
                          <ListItemText
                            className="blendarPro"
                            sx={{
                              textTransform: "uppercase",
                              fontFamily: "Blender Pro",
                              fontSize: " 16px",
                              fontWeight: "700;",
                            }}
                            primary="Wallet"
                          />
                        </ListItem>
                      )}
                    </List>
                  </Box>
                )}
                {menubar == true && renderMenu}
                
              </Box>
              </Box>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              {(["right"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={toggleDrawer(anchor, true)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    // onClose={() => toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      {/* wallet */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent", // Set the dialog background to transparent
            boxShadow: "none", // Remove the default Material-UI box shadow
          },
        }} // Apply backdrop filter for transparency effect
        maxWidth={maxWidth}
        open={showWallet}
        onClose={closeHandleWallet}
      >
        {/* <DialogTitle>Optional sizes</DialogTitle> */}
        <DialogContent>
          <MuiWalletConnect
            handleClose={() => closeHandleWallet()}
            buyConnect={false}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};
export default VoeuxGameHeader;
