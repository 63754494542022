import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useMediaQuery } from "@mui/material";

interface Togglemui {
  setcheckButton: React.Dispatch<React.SetStateAction<string>>;
  checkButton: string;
}

const Togglemui: React.FC<Togglemui> = ({ setcheckButton, checkButton }) => {
  const [subType, setSubType] = React.useState("Digital Wallet");

  const handleButtonClick = (type: React.SetStateAction<string>) => {
    console.log(type);
    setcheckButton(type);

    setSubType(type);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            "& .mask-box": {
              borderRadius: "12px",
              position: "relative",

              background: "#262626",
              // boxShadow: "1px 1px 2px rgb(165, 165, 165) inset",
              border: "1px solid rgb(165, 165, 165) ",
              width: "100%",
              height: "55px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              // border: "1px solid red",
            },
            "& .mask": {
              my: 1,
              width: "45%",
              height: "45px",
              borderRadius: "15px",
              background:
                "linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%)",
              position: "absolute",
              boxShadow: "1px 0 2px #b473ff",
              transition: "left 0.5s ease", // Only transition the left property

              left: subType === "Digital Wallet" ? "0" : "inherit",
              right: subType === "Email" ? "20px" : "inherit",

              transform: "translateX(8%)",
              // border: "1px solid blue",
            },
            "& .MuiButton-root": {
              my: 1,
              borderRadius: "20px",
              border: "none",
              width: "160px",
              height: "45px",
              fontWeight: "bold",
              transition: "all 0.2s 0.5s ease",
              "&:hover": {
                opacity: 0.8,
                // border: "1px solid green",
              },
            },
          }}
        >
          <Box className="mask-box">
            <Box className="mask" />
            {["Digital Wallet", "Email"].map((type, index) => (
              <Button
                key={index}
                disableRipple
                variant="text"
                sx={{
                  my: 1,
                  color: subType === type ? "#ffffff" : "white",
                  fontFamily: "outfit",
                  fontSize: { xs: "13px", lg: "14px" },
                }}
                onClick={() => handleButtonClick(type)}
              >
                {type}
              </Button>
            ))}
          </Box>
        </Box>
      )}

      {/* mobile */}

      {isMobile && (
        <Box
          sx={{
            display: "flex",
            "& .mask-box": {
              borderRadius: "12px",
              position: "relative",

              background: "#262626",
              // boxShadow: "1px 1px 2px rgb(165, 165, 165) inset",
              border: "1px solid rgb(165, 165, 165) ",
              width: "100%",
              height: "54px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              // border: "1px solid red",
            },
            "& .mask": {
              my: 1,
              width: "45%",
              height: "45px",
              borderRadius: "11px",
              background:
                "linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%)",
              position: "absolute",
              boxShadow: "1px 0 2px #b473ff",
              transition: "left 0.5s ease", // Only transition the left property
              left: subType === "Digital Wallet" ? "0" : { xs: "50%" },
              transform: "translateX(5%)",
              // border: "1px solid blue",
            },
            "& .MuiButton-root": {
              my: 1,
              borderRadius: "20px",
              border: "none",
              width: "160px",
              height: "45px",
              fontWeight: "bold",
              transition: "all 1.2s 0.5s ease",
              "&:hover": {
                opacity: 0.8,
                // border: "1px solid green",
              },
            },
          }}
        >
          <Box className="mask-box">
            <Box className="mask" />
            {["Digital Wallet", "Email"].map((type, index) => (
              <Button
                key={index}
                disableRipple
                variant="text"
                sx={{
                  my: 1,
                  color: subType === type ? "#ffffff" : "white",
                  fontFamily: "outfit",
                  fontSize: { xs: "13px", lg: "14px" },
                }}
                onClick={() => handleButtonClick(type)}
              >
                {type}
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
export default Togglemui;
