import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import withdrawimg from "../../assets/images/Withdraw.png";

function WithdrawFarm({
  withdraw,
  handleClosewithdraw,
}: {
  withdraw: (amount: number) => void;
  handleClosewithdraw: () => void;
}) {
  const [showComponent, setShowComponent] = useState(true);
  const [withdrawAmount, setWithdrawAmount] = useState(0);

  const handleCloseButtonClick = () => {
    setShowComponent(false);
  };

  const handleWithdraw = async () => {
    await withdraw(withdrawAmount);
  };

  return (
    <Box className="sumi" sx={{ display: "flex", justifyContent: "center" }}>
      {showComponent && (
        <Box
          sx={{
            width: "571.161px",
            // height: "445px",
            borderRadius: "28.422px",
            background: "#28272C",
            marginBottom: "20px",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography
                sx={{
                  fontFamily: "outfit",
                  fontsize: "32px",
                  fontWeight: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  color: "white",
                  // marginTop: 7,
                }}
              >
                Withdraw Now
              </Typography>
            </Box>

            <>
              <Button
                sx={{
                  fontSize: "20px",
                  color: "#fff",
                  position: "absolute",
                  right: 0,
                  padding: 0,
                  top: 0,
                  lineHeight: "normal",
                }}
                onClick={() => handleClosewithdraw()}
              >
                X
              </Button>
            </>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <img src={withdrawimg} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              type="text"
              name="amount"
              placeholder="Enter Amount to invest"
              required
              inputProps={{ style: { color: "#fff" } }}
              sx={{
                border: "1px solid #777",
                borderRadius: "14px",
                color: "#828282",

                width: {
                  xs: "100%",
                  md: "480.8px",
                },
                marginTop: "14px",
                "@media (max-width: 400px)": {
                  margin: {
                    xs: "14px 20px",
                  },
                },
                background: "transparent",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "none",
                  },
              }}
              onChange={(e) => {
                setWithdrawAmount(Number(e.target.value));
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: "transparent",
                textTransform: "capitalize",
                borderRadius: 2,
                border: "0.977px solid white",
                padding: "10px 20px",
                "&:hover": {
                  backgroundColor: "none !important",
                },
              }}
              onClick={handleWithdraw}
            >
              Withdraw Now
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default WithdrawFarm;
