import {
  Typography,
  Tooltip,
  TextField,
  Button,
  TextareaAutosize,
} from "@mui/material";
import { Box } from "@mui/system";
import profileicon from "../../assets/images/profileicon.png";
import coverimage from "../../assets/images/coverimage.png";
import EditIcon from "@mui/icons-material/Edit";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useState } from "react";
import DragDrop from "../../utils/getS3ImageURL";
import axios from "axios";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { Environment } from "../../environments";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { useNavigate } from "react-router-dom";

const NftProfile = () => {
  const buttonBox = {
    border: "1px solid #FF990D",
    color: "white",
  };
  const [copyTooltip, setCopyTooltip] = useState(false);
  const [copyMobileTooltip, setCopyMobileTooltip] = useState(false);
  const handleAddressCopy = () => {
    setCopyTooltip(true);
    navigator.clipboard.writeText("0x96c4869380499A7151FEFB084b42BDED7B082220");
  };

  const [image, setImage] = useState(null);

  const handleImageChang = (m: any) => {
    const file = m.target.files[0];

    setImage(file);
  };

  const [selectedImage, setSelectedImage] = useState<any>(profileicon);

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    try {
      var data = await DragDrop("profile", file);
      formdata.profilePic = data;
      setSelectedImage(data);
    } catch (error) {
      showToastErrorMessageClick(
        "Failed in uploading the image. Please try again!"
      );
    }

    // handleUpload(file);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const [selectedCoverImage, setSelectedCoverImage] = useState<any>(coverimage);

  const handleCoverImage = async (event: any) => {
    const file = event.target.files[0];
    try {
      var data = await DragDrop("coverImage", file);
      setSelectedCoverImage(data);

      formdata.coverPic = data;
    } catch (error) {
      showToastErrorMessageClick(
        "Failed in uploading the image. Please try again!"
      );
    }

    // handleUpload(file);
  };

  const walletData = localStorage.getItem("account_address");

  const [userDetails, setUserDetails] = useState<any>({});
  const [formdata, setFormData] = useState<any>({});

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      console.log("fetchUserDetails == >");
      setShowLoader(true);
      try {
        // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
        const response = await axios.get(
          `${Environment.metavoeuxBackend}user/userDetails?walletAddress=${walletData}`
        );
        console.log("get the user details", response.data);
        // const profile = localStorage.getItem("profileIcon")
        // const bcakground =   localStorage.getItem("coverpic")
        setSelectedImage(response.data.profilePic);
        setSelectedCoverImage(response.data.coverPic);
        setUserDetails(response.data);
        setFormData(response.data);
        setShowLoader(false);
        // formdata.user  = response.data
        // handleShowLoader()
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      }
    };

    fetchUserDetails();

    // console.log("formdata",formdata);
  }, [walletData]);

  const navigate = useNavigate();
  const hanldeSaveProfileData = async () => {
    setShowLoader(true);
    formdata.userId = userDetails.userId;
    formdata.walletAddress = userDetails.walletAddress;
    formdata.publicAddress = [];
    formdata.isAllowed = true;
    formdata.isBlocked = false;
    formdata.profilePic = selectedImage;
    formdata.coverPic = selectedCoverImage;
    localStorage.setItem("profileIcon", selectedImage);
    localStorage.setItem("coverpic", selectedCoverImage);
    try {
      // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
      const response = await axios.put(
        `${Environment.metavoeuxBackend}user/update`,
        formdata
      );
      console.log("update the profile data", response.data);
      setFormData(response.data);

      setTimeout(() => {
        setShowLoader(false);
        displayToastMessage("Profile Updated Successfully");
      }, 2000);
      setTimeout(() => {
        navigate("/Userdetails");
      }, 2010);
      // setUserDetails(response.data)
      // formdata.user  = response.data
      // handleShowLoader()
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowLoader(false);
    }
  };

  const [showToastMessage, setshowToastMessage] = useState(false);
  const [toastMessage, settoastMessage] = useState("");
  const closeToastMessage = () => {
    setshowToastMessage(false);
  };
  const displayToastMessage = (text: any) => {
    settoastMessage(text);
    setshowToastMessage(true);
  };
  return (
    <>
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />

      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            top: 0,
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {" "}
            <Box>
              <img
                src={selectedImage ? selectedImage : profileicon}
                alt="profile"
                width="131px"
                height="131px"
                style={{ borderRadius: "100%" }}
              ></img>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "10px", md: "14px" },
                  fontFamily: "outfit",
                  color: "#FF990D",
                  cursor: "pointer",
                }}
              >
                {" "}
                <EditIcon sx={{ fontSize: "medium" }} />
                <input
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    opacity: "0",
                  }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e)}
                />
                Change Profile Image
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2.4 }}>
            {" "}
            <Box>
              <img
                src={formdata.coverPic ? formdata.coverPic : coverimage}
                width="100%"
                height="240px"
                alt="profile"
              ></img>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "10px", md: "14px" },
                  fontFamily: "outfit",
                  color: "#FF990D",
                  cursor: "pointer",
                }}
              >
                {" "}
                <EditIcon sx={{ fontSize: "medium" }} />
                <input
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    opacity: "0",
                  }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleCoverImage(e)}
                />
                Change Cover Image
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#F6EBFD",
              fontSize: { xs: "12px", md: "20px" },
              fontWeight: 500,
              fontFamily: "outfit",
              marginTop: "3rem",
              marginBottom: "10px",
            }}
          >
            Username
          </Typography>
          <TextField
            type="text"
            name="fullName"
            placeholder="Enter username"
            required
            inputProps={{ style: { color: "#777" } }}
            sx={{
              border: "1px solid #777",
              borderRadius: "12px",
              color: "#828282",
              width: "100%",
            }}
            value={formdata.fullName}
            onChange={(e) =>
              setFormData({ ...formdata, [e.target.name]: e.target.value })
            }
          />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#F6EBFD",
              fontSize: { xs: "12px", md: "20px" },
              fontWeight: 500,
              fontFamily: "outfit",
              marginTop: "2rem",
              marginBottom: "10px",
            }}
          >
            Bio
          </Typography>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            value={formdata.bio}
            placeholder="Tell the world your story"
            name="bio"
            style={{
              border: "1px solid #777",
              borderRadius: "12px",
              color: "#828282",
              width: "100%",
              background: "none",
              height: "120px",
              padding: "15px",
            }}
            onChange={(e) =>
              setFormData({ ...formdata, [e.target.name]: e.target.value })
            }
          />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#F6EBFD",
              fontSize: { xs: "12px", md: "20px" },
              fontWeight: 500,
              fontFamily: "outfit",
              marginBottom: "10px",
              marginTop: "2rem",
            }}
          >
            Email Address
          </Typography>
          <TextField
            type="email"
            name="email"
            placeholder="Ente email"
            required
            inputProps={{ style: { color: "#777" } }}
            sx={{
              border: "1px solid #777",
              borderRadius: "12px",
              color: "#828282",
              width: "100%",
            }}
            value={formdata.email}
            onChange={(e) =>
              setFormData({ ...formdata, [e.target.name]: e.target.value })
            }
          />
        </Box>
        {/* social icon part */}
        {/* <Typography
        sx={{
          color: "#F6EBFD",
          fontSize: { xs: "12px", md: "20px" },
          fontWeight: 500,
          fontFamily: "outfit",
          marginBottom: "10px",
          marginTop: "2rem",
        }}
      >
        Social Connection
      </Typography>
      <Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <img src={google} alt="profile" />
              <Typography
                sx={{
                  fontFamily: "outfit",
                  fontSize: { xs: "11px", md: "18px" },
                }}
              >
                {" "}
                Google
              </Typography>
            </Box>

            <Box sx={{ margin: "5px" }}>
              {" "}
              <Button
                sx={{
                  ...buttonBox,
                  fontFamily: "outfit",
                  textTransform: "none",
                  "&:hover": {
                    background: "#FF990D",
                    color: "white",
                  },
                }}
              >
                {" "}
                Connect
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <img src={facbook} alt="profile" />
              <Typography
                sx={{
                  fontFamily: "outfit",
                  fontSize: { xs: "11px", md: "18px" },
                }}
              >
                {" "}
                Facebook
              </Typography>
            </Box>

            <Box sx={{ margin: "5px" }}>
              {" "}
              <Button
                sx={{
                  ...buttonBox,
                  fontFamily: "outfit",
                  textTransform: "none",
                  "&:hover": {
                    background: "#FF990D",
                    color: "white",
                  },
                }}
              >
                {" "}
                Connect
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <img src={twitter} alt="profile" />
              <Typography
                sx={{
                  fontFamily: "outfit",
                  fontSize: { xs: "11px", md: "18px" },
                }}
              >
                Twitter
              </Typography>
            </Box>

            <Box sx={{ margin: "5px" }}>
              {" "}
              <Button
                sx={{
                  ...buttonBox,
                  fontFamily: "outfit",
                  textTransform: "none",
                  "&:hover": {
                    background: "#FF990D",
                    color: "white",
                  },
                }}
              >
                {" "}
                Connect
              </Button>
            </Box>
          </Box>
        </Box>
      </Box> */}
        <Typography
          sx={{
            color: "#F6EBFD",
            fontSize: { xs: "12px", md: "20px" },
            fontWeight: 500,
            fontFamily: "outfit",
            marginBottom: "10px",
            marginTop: "2rem",
          }}
        >
          Wallet Address{" "}
        </Typography>
        <Box
          sx={{
            height: "50px",
            width: "100%",
            border: "1px solid #7C7C7C",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <input
            type="text"
            placeholder="0x96c4869380499A7151FEFB084b42BDED7B082220"
            style={{
              color: !copyTooltip ? "#fff" : "green",
              border: "none",
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              padding: "0 10px",
              background: "#00042A",
            }}
            value={userDetails.walletAddress}
            readOnly

            // ref={address}
          />
          <Tooltip title="Copied" open={copyTooltip}>
            <ContentCopyIcon
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={() => handleAddressCopy()}
              onMouseOut={() => setCopyTooltip(false)}
            />
          </Tooltip>
        </Box>
        <Button
          sx={{
            ...buttonBox,
            marginTop: "2rem",
            width: "123px",
            textTransform: "none",
            fontSize: { xs: "9px", md: "14px" },
            marginBottom: "15px",

            "&:hover": {
              background: "#FF990D",
              color: "white",
            },
          }}
          onClick={() => hanldeSaveProfileData()}
        >
          {" "}
          Save
        </Button>
      </Box>
    </>
  );
};

export default NftProfile;
