import { Snackbar, Alert, IconButton, SnackbarContent } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

const ToastMessageInformation = ({text,display,handleClose}:{text:string,display:boolean,handleClose:()=>void}) => {
return (
<Snackbar
open={display}
onClose={handleClose}
message={text }
anchorOrigin={{ vertical: 'top', horizontal: 'right' }}

>
<SnackbarContent style={{
      backgroundColor:'#ff990d',
    }}
    action={
      <>
        <IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </>
    }
    message={text}
  />
</Snackbar>

)
}

export default ToastMessageInformation