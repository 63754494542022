import Box from "@mui/material/Box"
import VerifiedIcon from "../../assets/Createnft/useVerifiedIcon.png"
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

const UserProfile = ({nftCreateResponse}:{nftCreateResponse:any}) => {
   
      console.log("user profile",nftCreateResponse)

      const [nftData, setNftData] = useState<any>({})
const getCustomObject = () => {
  const data = {
    userAddress: nftCreateResponse?.nftData?.creator,
    nftName: nftCreateResponse?.nftData?.nftName,
    user: nftCreateResponse?.nftData?.currentOwner?._id,
    nftImage: nftCreateResponse?.nftData?.imageUrl,
    nftId: nftCreateResponse?.nftData?._id,
    userName: nftCreateResponse?.nftData?.currentOwner?.fullName,
    // tokenId: nftCreateResponse.nftData.tokenId
  }
  setNftData({...data})
}

      useEffect(()=>{
        getCustomObject()
      },[nftCreateResponse])
    return(
        <>
        <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  mb: 2,
                  width: { xs: "100%", lg: "597px" },
                  height:{
                    md:"600px",
                    xs:"400px"
                  }
                }}
              >
                <img src={nftData.nftImage} width="100%" height="100%" style={{objectFit:"cover",borderRadius:"20px"}} alt="" />
              </Box>
              {nftData?.userName && <Box
              sx={{
                display: "flex",
                columnGap: "8px",
                alignItems: "center",
              }}
            >
              <img src={VerifiedIcon} alt="" />
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "16px", md: "16px" },
                  fontWeight: 300,
                }}
              >
                {nftData.userName}
              </Typography>
            </Box> }
              
            <Box
              sx={{
                display: "flex",
                columnGap: "8px",
                alignItems: "center",
              }}
            >
             
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "16px", md: "52px" },
                  fontWeight: 600,
                }}
              >
                {nftData.nftName}
              </Typography>
              {/* <Button
                sx={{
                  padding:"0px 10px",
                  color: "white",
                  width:"100px",
                  ...borderGradientBox,
                  fontSize:"22px",
                  ml:"18px"
                }}
              >
                Art
              </Button> */}
            </Box>
            <Box>
            <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "16px", md: "22px" },
                  fontWeight: 300,
                }}
              >
                Owned by <span style={{color:"#FF990D"}}>{nftData.userAddress}</span>
              </Typography>
            
            </Box>
            </>
    )
}
export default UserProfile