import { Contract } from "ethers";
import { ContractInstance } from "./contractInstance";

export class GameReward{
        contractInstance: Contract | null = null;
        constructor(
                GAME_REWARD_CONTRACT_ADDRESS: string,
                GAME_REWARD_CONTRACT_ABI:any[]
        ){
                const contract = new ContractInstance();
                contract.buildContractInstance(
                        GAME_REWARD_CONTRACT_ADDRESS,
                        GAME_REWARD_CONTRACT_ABI
                ).then((contIns) => {
                        this.contractInstance = contIns;
                })
        }

        updateGameRewardRate = async(rewardRate:number)=>{
                try{
                        return await this.contractInstance?.updateRewardRate(rewardRate);
                }catch(err){
                        throw err;
                }
        }

        getCurrentRewardRate = async() =>{
                try{
                        console.log("in contract");
                        return await this.contractInstance?.rewardRate();
                }catch(err){
                        throw err;
                }
        }

        // before calling this function make sure user gives permission to transfer that much amount
        // call the token approve function
        depositTokenInGame = async(amount:BigInt)=>{
                try{
                        return await this.contractInstance?.depositToken(amount);
                }catch(err){
                        throw err;
                }
        }

        getGameTokenBalance = async() =>{
                try{
                        return await this.contractInstance?.balance();
                }catch(err){
                        throw err;
                }
        }

        buyPoints = async(tokenAmount:BigInt, userId:string) =>{
                
                try{          
                       const buypoint =  await this.contractInstance?.buyPoints(tokenAmount, userId);
                       await buypoint.wait();
                       const points = await this.contractInstance?.boughtPoints(userId);
                       return points;

                }catch(err){
                        throw err;      
                }
        }

        getGameAdmin = async()=>{
                try{
                        return await this.contractInstance?.admin();
                }catch(err){
                        throw err;
                }
        }

        withdrawToken = async(amount:BigInt) =>{
                try{
                        return await this.contractInstance?.withdrawToken(amount);
                }catch(err){
                        throw err;
                }
        }

        // getBoughtUserPoints = async()
}