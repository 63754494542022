import { Box, Stack, Typography, Tooltip, useMediaQuery } from "@mui/material";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import React, { useEffect, useState } from "react";
import userImage from "../../assets/userDetails/userImage.png";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import editicon from "../../assets/userDetails/editicon.png";
import polygon_round from "../../assets/userDetails/polygon_round 1.svg";
import Verified from "../../assets/userDetails/Verified Sign.png";
import styles from "../nftLanding/NftLanding.module.css";
import UserDetailTab from "./UserDetailTab";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Environment } from "src/environments";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

export default function UserDetails() {
  const isMobile = useMediaQuery("(max-width:1000px)");
  const isMobile400 = useMediaQuery("(max-width:400px)");
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const navigate = useNavigate();

  const profileicon = localStorage.getItem("profileIcon");
  const covericon = localStorage.getItem("coverpic");
  const [profileIcon, setProfileIcon] = useState<any>();
  const [coverIcon, setCoverIcon] = useState<any>();
  const [copyTooltip, setCopyTooltip] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const walletData = localStorage.getItem("account_address");
  const handleAddressCopy = () => {
    setCopyTooltip(true);
    navigator.clipboard.writeText(`${walletData}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
        const response = await axios.get(
          `${Environment.metavoeuxBackend}user/userDetails?walletAddress=${walletData}`
        );
        console.log("get user details", response.data);
        setProfileIcon(response.data.profilePic);
        setCoverIcon(response.data.coverPic);
        setProfileName(response.data.fullName);
        setCreatedDate(response.data.createdAt);
      } catch (error) {
        console.error("Error fetching user Details:", error);
      }
    };

    fetchData();
  }, [walletData]);
  //state to show loader
  const [showLoader, setShowLoader] = useState(false);
  const handleShowLoader = (active: boolean) => {
    setShowLoader(active);
  };

  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <Box
        className={styles.mainContainer}
        p={4}
        sx={{
          paddingTop: 0,
          "@media (max-width: 600px)": {
            padding: 2,
          },
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader nftHeader={true} />
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            // px: { xs: 1, sm: 3, md: 5 },
          }}
        >
          <Stack sx={{ position: "relative" }}>
            <Box sx={{ position: "relative", width: "100%" }}>
              {/* const [ profileIcon, setProfileIcon] = useState<any>()
              const [ coverIcon, setCoverIcon] = useState<any>() */}
              {/* Edit Icon */}
              <Box
                sx={{
                  position: "absolute",
                  top: "27px",
                  right: "27px",
                  cursor: "pointer",
                }}
              >
                <img src={editicon} alt=""  onClick={() => navigate("/editprofile")} />
              </Box>
              {/* Background Image */}
              <img
                src={
                  coverIcon
                    ? coverIcon
                    : "https://d24m5mu8cxqwfm.cloudfront.net/images/Image+PlaceHolder.svg"
                }
                width="100%"
                height="300px"
                alt="background"
                style={{
                  objectFit: "cover",
                  borderRadius: "15px",
                  objectPosition: "0 0",
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                // flexDirection: "row",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                width: "100%",
                px: { xs: 2, sm: 3, md: 5 },
                position: "absolute",
                // justifyContent:"space-between",
                top: { xs: "50%", sm: "75%", md: "79%", lg: "79%", xl: "70%" },
                "@media (min-width: 1721px)": {
                  px: 7,
                  position: "absolute",
                  top: "75%",
                },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: { xs: "225px", lg: "225px" },
                  height: { xs: "225px", md: "225px", lg: "225px" },
                }}
              >
                <img
                  src={profileIcon ? profileIcon : userImage}
                  width="inherit"
                  alt="background"
                  height="224px"
                  style={{
                    borderRadius: "100%",
                    objectPosition: "0 0",
                    width: "inherit",
                    height: "inherit",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "111px",
                    right: "-13px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={editicon}
                    alt=""
                    onClick={() => navigate("/editprofile")}
                  />
                </Box>
              </Box>

              {/* name address */}
              <Box
                sx={{
                  ml: { sm: "3%", xl: "5%" },
                  pt: { xl: "2%" },
                  width: { xs: "auto", lg: "225px" },
                  height: { xs: "auto", lg: "225px" },

                  marginTop: { xs: 1, sm: 10, md: 8, lg: 10, xl: 7 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {profileName ? (
                    <>
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "24px",
                          fontWeight: "400px",
                          mr: "5px",
                        }}
                      >
                        {profileName}
                      </Typography>
                      <Box
                        height="30px"
                        width="30px"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          color: "#000",
                        }}
                      >
                        <img src={Verified} height="100%" alt="" />{" "}
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Box
                    sx={{
                      // ml: 3,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      height="30px"
                      width="30px"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        color: "#000",
                      }}
                    >
                      <img
                        src={polygon_round}
                        height="100%"
                        width="90%"
                        alt="poygonicon"
                      />
                    </Box>
                    <Typography
                      sx={{
                        ml: "2px",

                        fontSize: "22px",

                        fontFamily: "outfit",
                      }}
                    >
                      Polygon
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "22px",
                      fontWeight: 400,
                    }}
                  >
                    {isMobile400
                      ? walletData?.slice(0, 5) + "...."
                      : isMobile
                      ? walletData?.slice(0, 10) + "...."
                      : walletData?.slice(0, 16) + "...."}

                    {/* Oxa8bf...ceb107 */}
                    {/* {walletData} */}
                  </Typography>
                  <Box
                    sx={{
                      mr: 1,
                      color: "#fff",
                      borderRadius: { xs: "50px", lg: "50px" },
                      whiteSpace: "nowrap",
                      width: "52.462px",
                      height: "52.462px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "50px",
                        width: "350px",
                        border: "1px solid #7C7C7C",
                        borderRadius: "50px",
                        position: "relative",
                        background: "#333",
                      }}
                    >
                      <Tooltip title="Copied" open={copyTooltip}>
                        <ContentCopyIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",

                            color: "#fff",
                          }}
                          onClick={() => handleAddressCopy()}
                          onMouseOut={() => setCopyTooltip(false)}
                        />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* ----------------- */}

              <Box
                sx={{
                  ml: { sm: "26%", md: "43%", lg: "37%", xl: "37%" },
                  pt: { xl: "4%" },
                  display: "flex",
                  alignItems: "center",

                  flexDirection: "row",
                  width: { xs: "100px", lg: "225px" },
                  height: { xs: "100px", lg: "225px" },
                  "@media (min-width: 1921px)": {
                    ml: "52%",
                  },
                }}
              >
                {/* <Box
                  sx={{
                    color: "#F2F2F2",
                    borderRadius: { xs: "10px", lg: "12px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: { sm: "14px", xl: "18px" },
                      borderRadius: { xs: "10px", lg: "12px" },
                      background: "#9D00FF",
                      px: { sm: "4px", lg: 2 },
                      py: { sm: "4px", lg: 1 },
                    }}
                  >
                    Update Cover Image
                  </Typography>
                </Box> */}
              </Box>
            </Box>
          </Stack>
          <Stack
            sx={{
              mt: { xs: "210px", sm: "160px", lg: "160px" },
              "@media (min-width: 1721px)": {
                mt: 20,
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "20px" },
                fontWeight: { lg: 600 },
                lineHeight: { lg: "27px" },
                mb: { sm: 2 },
              }}
            >
              Description
            </Typography>
            <Typography
              sx={{
                maxWidth: { lg: "881px" },
                fontSize: { lg: "16px" },
                fontWeight: { lg: 400 },
                lineHeight: { lg: "27px" },
                fontFamily: "outfit",
              }}
            >
              Dynamic means the amount of cards can be leveled up beyond 3.A
              newly minted bundle contains 9 cards with the potential to level
              up to 21 cards (7 per pack).Individual RAM Cards have multiple
              levels of rarity affecting anything from attributes to cosmetic
              enhancements.The rarity chances for this mint are significantly
              higher than they will ever be.
            </Typography>
            <Stack
              sx={{
                gap: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                py: 1,
                my: { sm: 2, lg: 3 },
                fontSize: { lg: "18px" },
                fontWeight: { lg: 400 },
                lineHeight: { lg: "normal" },
                fontFamily: "Roboto",
                color: "var(--Gray-6, #F2F2F2)",
                maxWidth: "100%",
                flexWrap: "wrap",
              }}
            >
              {/* <Box
                sx={{
                  px: 2,
                  py: 1,
                  color: "#F2F2F2",
                  background: "rgba(255, 255, 255, 0.23)",
                  borderRadius: { xs: "10px", lg: "12px" },
                  mr: 2,
                }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  Items 2,221
                </Typography>
              </Box> */}
              <Box
                sx={{
                  px: 2,
                  py: 1,
                  color: "#F2F2F2",
                  background: "rgba(255, 255, 255, 0.23)",
                  borderRadius: { xs: "10px", lg: "12px" },
                  mr: 2,
                }}
              >
                <Typography>
                  Created{" "}
                  {new Date(createdDate).toLocaleString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  px: 2,
                  py: 1,
                  color: "#F2F2F2",
                  background: "rgba(255, 255, 255, 0.23)",
                  borderRadius: { xs: "10px", lg: "12px" },
                  mr: 2,
                }}
              >
                <Typography>Creator earnings 5%</Typography>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  p: "6px 12px",
                  color: "#F2F2F2",
                  background: "rgba(255, 255, 255, 0.23)",
                  borderRadius: { xs: "10px", lg: "12px" },
                }}
              >
                {" "}
                <Box sx={{}}>
                  <img
                    src={polygon_round}
                    alt="polygon"
                    style={{ verticalAlign: "middle" }}
                  />
                </Box>
                <Typography sx={{ px: 1, mr: 0, color: "#F2F2F2" }}>
                  Polygon Chain
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack sx={{ position: "relative" }}>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                ...circleStyle,
                top: { xl: "0%" },
                left: "-22%",
                transform: "translateY(-50%)",
                width: "350px", // Adjusted width
                height: "200px", // Adjusted height
                borderRadius: "50%", // Ensures it remains a perfect circle
                background: "#9D00FF",
                // background: "red",
                filter: "blur(140px)",
                backdropFilter: "blur(140px)",
                WebkitBackdropFilter: "blur(140px)",
                overflow: "hidden",
                "@media (min-width: 1721px)": {
                  width: "450px", // Adjusted width
                  height: "400px", // Adjusted height
                  top: { xl: "50%" },
                  left: "-20%",
                  filter: "blur(160px)",
                  backdropFilter: "blur(160px)",
                  WebkitBackdropFilter: "blur(160px)",
                },
              }}
            ></Box>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                ...circleStyle,
                top: { xl: "-20%" },
                right: "2%",
                transform: "translateY(-50%)",
                width: "350px", // Adjusted width
                height: "200px", // Adjusted height
                borderRadius: "50%", // Ensures it remains a perfect circle
                background: "#9D00FF",
                // background: "red",
                filter: "blur(140px)",
                backdropFilter: "blur(140px)",
                WebkitBackdropFilter: "blur(140px)",
                overflow: "hidden",
                "@media (min-width: 1721px)": {
                  width: "450px", // Adjusted width
                  height: "400px", // Adjusted height
                  top: { xl: "50%" },
                  left: "-20%",
                  filter: "blur(160px)",
                  backdropFilter: "blur(160px)",
                  WebkitBackdropFilter: "blur(160px)",
                },
              }}
            ></Box>

            {/* <Box
              sx={{
                display: "flex",
                gap: 2,
                maxWidth: "100%",
                flexWrap: "wrap",
                flexDirection: "row",
              }}
            >
              {button.map((value, index) => (
                <Box key={index}>
                  <Button
                    sx={{
                      ...(selectedButton === value.label
                        ? borderGradient
                        : defaultButtonStyle),
                      borderRadius:
                        selectedButton === value.label ? "12px" : "none",
                      color: "#fff",
                      // px: 2,
                      whiteSpace: "nowrap",
                      "&:hover": {
                        ...hoverborderGradient,
                        borderRadius: "12px",
                        // px: 2
                      },
                    }}
                    onClick={() => setChangeData(value.label)}
                  >
                    {value.label}
                  </Button>
                </Box>
              ))}
            </Box> */}
            {/* <Box sx={{ my: 3 }}>
              <Grid container>
                <Typography>
                 
                </Typography>
                {selectedButton === "collection" && (
                  <Stack direction="row" flexWrap="wrap">
                    {card.map((item, index) => (
                      <Grid
                        item
                        xs={5.5}
                        sm={3.5}
                        md={3}
                        lg={2.4}
                        xl={2}
                        sx={{ mr: { xs: 1, sm: 2 }, mt: 3 }}
                      >
                        <Card
                          key={index}
                          sx={{
                            borderRadius: "13.991px 13.991px 0px 0px",
                            background: "transparent",
                          }}
                        >
                          <CardMedia
                            component="img"
                            alt={item.name}
                            height="170"
                            image={item.img}
                          />
                          <CardContent sx={{ background: "#181818" }}>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              sx={{
                                color: "#fff",
                                fontSize: "14px",
                                fontWeight: 600,
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Box>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ color: "#858584" }}
                              >
                                Price:
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ color: "#fff" }}
                              >
                                {item.price}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Stack>
                )}
                {selectedButton === "Offers made" && (
                  <Stack direction="row" flexWrap="wrap">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Atque vel maxime impedit error dolores? Eveniet natus ad id
                    tenetur. Quos.
                  </Stack>
                )}
                {selectedButton === "Deals" && (
                  <Stack direction="row" flexWrap="wrap">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minima, laudantium sequi dolore et reiciendis assumenda!
                  </Stack>
                )}
                {selectedButton === "Created" && (
                  <Stack direction="row" flexWrap="wrap">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Error eum deserunt natus tenetur. Ipsam voluptatem omnis,
                    officia ducimus recusandae quam nisi obcaecati inventore
                    vero adipisci quibusdam quia veritatis iusto accusamus
                    reiciendis eos in harum dicta perferendis! Tempore nam
                    dolore aliquam?
                  </Stack>
                )}
                {selectedButton === "Favorited" && (
                  <Stack direction="row" flexWrap="wrap">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Veniam nesciunt quas cumque!{" "}
                  </Stack>
                )}
                {selectedButton === "Acitvity" && (
                  <Stack direction="row" flexWrap="wrap">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Ratione, quas!{" "}
                  </Stack>
                )}
              </Grid>
           &:hover": {
                        ...hoverborderGradient,
            </Box> */}

            <UserDetailTab handleShowLoader={handleShowLoader} />
          </Stack>
        </Box>
      </Box>
      <LandingPageFooter />
    </>
  );
}
