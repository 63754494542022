import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Grid,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, Stack, maxWidth, useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";

import NewNftHeader from "../../components/newNftHeader/NewNftHeader";

import addressicon from "../../assets/svgs/Address.svg";
import networkicon from "../../assets/svgs/networkIcon.svg";
import balanceicon from "../../assets/svgs/balance.svg";

import purchaseimg from "../../assets/images/purchas.png";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import Arrow from "../../assets/svgs/Double arrow.svg";
import { useNavigate } from "react-router";

import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import axios from "axios";
import { Environment } from "../../environments";
import { RegisterFarmService } from "src/services/registerFarmService";

import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

function CreateFarm() {
  const borderGradient = {
    background:
      "linear-gradient(#232323 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
    border: "1px solid transparent",
    borderRadius: { xs: "9px", md: "10px" },
    cursor: "pointer",
    padding: { xs: "0%  1% 0% 2%", md: "1% 2% 1% 1%" },
    marginRight: { md: "20px" },
    backgroundColor: "#232323",
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/voeuxfarming");
  };

  const [farmService, setFarmService] = useState<RegisterFarmService>();

  useEffect(() => {
    const farmInst = new RegisterFarmService();
    const intervalId = setInterval(() => {
      if (
        farmInst.registerFarmInstance.contractInstance != null &&
        farmInst.registerFarmInstance.contractInstance != undefined
      ) {
        setFarmService(farmInst);
        clearInterval(intervalId);
      }
    }, 100);
  }, []);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);

  // this useEffect is for testing registerNewFarm
  // useEffect(()=>{
  //   if(farmService){
  //     setTimeout(()=>{
  //       registerNewFarm();
  //     },500)

  //   }
  // },[farmService])

  useEffect(() => {
    getLevels();
  }, [farmService]);
  const [farmID, setFarmId] = useState<any>(0);
  const getLevels = async () => {
    if (farmService) {
      const farmId: any = await farmService?.getLevels();
      setFarmId(farmId);
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const [formData, setFormData] = useState<any>({});

  const [phasesData, setPhasesData] = useState([
    { name: "", priority: "", detail: "", status: "" },
  ]);

  const addPhase = () => {
    if (phasesData.length === 0) {
      setPhasesData([{ name: "", priority: "", detail: "", status: "" }]);
    } else {
      const newArray = [...phasesData];
      const index = newArray.length - 1;
      if (
        !(
          newArray[index].name == "" ||
          newArray[index].priority == "" ||
          newArray[index].detail == "" ||
          newArray[index].status == ""
        )
      ) {
        // Prepend the new element to the copied array
        newArray.push({ name: "", priority: "", detail: "", status: "" });
        setPhasesData(newArray);
      } else {
        showToastErrorMessageClick("Please Fill all the fields in add Phase");
      }
    }
  };
  const removePhase = () => {
    const newArray = [...phasesData];
    newArray.pop();
    setPhasesData(newArray);
  };
  const [phaseForm, setPhaseFormData] = useState<any>([]);
  const handleInputPhaseData = (name: string, value: any, index: number) => {
    console.log("name", name, value, index);
    const newArray = [...phasesData];
    if (name == "name") {
      newArray[index].name = value;
    }
    if (name == "priority") {
      newArray[index].priority = value;
    }
    if (name == "detail") {
      newArray[index].detail = value;
    }
    if (name == "status") {
      newArray[index].status = value;
    }
    if (
      newArray.length == 1 &&
      newArray[0].name == "" &&
      newArray[0].priority == "" &&
      newArray[0].detail == "" &&
      newArray[0].status == ""
    ) {
      setPhaseFormData([]);
    } else {
      setPhaseFormData([...phasesData, newArray]);
    }
  };

  const handleCreateFarmData = async () => {
    handleShowLoader(true);
    console.log("formdata", formData);
    console.log("phases", phasesData);
    // console.log("phaseForm",phaseForm)
    const newPhaseForm = phasesData.map((phase: any) => {
      return {
        name: phase?.name,
        detail: phase?.detail,
        priority: Number(phase?.priority),
        status: phase?.status,
      };
    });
    console.log("newPhaseForm", newPhaseForm);
    formData.phases = newPhaseForm;
    formData.user = userID;
    formData.maxPhase = newPhaseForm.length;
    console.log("formData", formData);

    const regex = /^0x[0-9a-fA-F]{40}$/;
    if (
      !(
        regex.test(formData.tokenAddress) &&
        regex.test(formData.farmManagerDetails)
      )
    ) {
      showToastErrorMessageClick("Invalid Wallet Address");
      handleShowLoader(false);
      return;
    }

    const formDetails = {
      name: formData.farmName,
      description: formData.farmDescription,
      investmentRequired: formData.investmentRequired,
      tokenAddress: formData.tokenAddress,
      phases: formData.phases,
      manager: formData.farmManagerDetails,
      apy: formData.farmApy,
    };
    try {
      const resp = await farmService?.registerFarm(formDetails);
      console.log("response received of create farm ==>", resp);
      const farmId = await farmService?.getLevels();
      formData.blockChainfarmId = Number(farmId) - 1;
      // setFarmId()
      const creatFarmResponse = await axios.post(
        `${Environment.metavoeuxBackend}farm`,
        {
          ...formData,
        }
      );
      console.log("response", creatFarmResponse);
      setShowSuccessDialog(true);
      handleShowLoader(false);
      window.location.reload();
    } catch (error) {
      handleShowLoader(false);
      showToastMessageClick("Error in Creating Farm");
      // throw error;
    }
  };
  const walletAddress = localStorage.getItem("account_address");
  const [userID, setUserID] = useState("");
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}user/userDetails?walletAddress=${walletAddress}`
      );
      console.log("get user details", response.data);
      setUserID(response.data._id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchUserDetails();
  });

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, settoastMessage] = useState("");

  const [showLoader, setShowLoader] = useState(false);

  const handleShowLoader = (active: any) => {
    setShowLoader(active);
  };
  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (data: string) => {
    setShowToastMessage(true);
    settoastMessage(data);
  };
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };
  const [showsuccessDialog, setShowSuccessDialog] = useState(false);
  const handleSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  return (
    <>
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100vh",
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}{" "}
      <Box
        p={4}
        sx={{
          // padding: "0 2.5rem 2.5rem 2.5rem",
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          px: { xs: 1, sm: 3, md: 5 },
          "@media (max-width: 600px)": {
            padding: "0 8px ",
          },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <NewNftHeader farmHeader={true} />

        <Box
          sx={{
            ...circleStyle,
            top: { md: "10%" },
            transform: "translate(-50%, -50%)",
            width: "447.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter: "blur(120px)",
            WebkitBackdropFilter: "blur(120px)",
            // leftcorner
          }}
        ></Box>
        <Box
          sx={{
            ...circleStyle,
            top: { md: "10%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "80%", md: "90%" },
            transform: "translate( -50%)",
            width: "300.625px",
            height: "187.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(140px)",
            backdropFilter: "blur(140px)",
            WebkitBackdropFilter: "blur(140px)",
            right: "0%",
            // righttop
          }}
        ></Box>

        <Box
          sx={{
            ...circleStyle,
            top: { md: "120%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "80%", md: "90%" },
            right: { xs: "0%", md: "0%" },
            transform: "translate( -50%)",
            width: "300.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter: "blur(120px)",
            WebkitBackdropFilter: "blur(120px)",
            // rightsecond
          }}
        ></Box>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              padding: "0 10px ",
            },
          }}
        >
          <Box sx={{ display: "flex" }}>
            {" "}
            <img src={Arrow} alt="" onClick={() => handleBack()} />
            <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
          </Box>
          <Box
            height="30%"
            width={"100%"}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"10px"}
          >
            <Box
              display="flex"
              alignItems="center"
              flexDirection={"column"}
              sx={{
                width: "100%",
                background:
                  "linear-gradient(267deg, #ff990d -3.12%, #9d00ff 93.15%)",
                borderRadius: 4,
              }}
              py="2%"
            >
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: { md: "32px", xs: "24px" },
                  fontWeight: 600,
                  fontFamily: "outfit",
                }}
              >
                Admin
              </Typography>
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",

                  fontSize: {
                    xs: 16,
                    md: 22,
                  },
                  fontWeight: 300,
                  width: {
                    xs: "80%",
                    md: "50%",
                  },
                  mb: "1%",
                  fontFamily: "outfit",
                }}
              >
                Total Level of Membership Registered : {farmID ? farmID : 0}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ margin: "20px 0" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: "grid",
                  flexDirection: "column",
                  justifyContent: { xs: "none", md: "flex-start" },
                  marginBottom: "15px",
                }}
              >
                <Box
                  sx={{
                    // width: { xs: "auto",md:"100%"},
                    height: "max-content",

                    borderRadius: "28.422px",
                    background: "#28272C",
                    padding: { xs: "20px 20px", md: "40px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      rowGap: "20px",
                    }}
                  >
                    {" "}
                    <Box>
                      <img
                        src={addressicon}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />{" "}
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { md: "25px", xs: "20px" },
                          fontFamily: "outfit",
                          fontWeight: "500px",
                        }}
                      >
                        {" "}
                        Account Address
                      </Typography>{" "}
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "#FF990D",
                      fontSize: "14px",
                      fontFamily: "outfit",
                      marginBottom: "40px",
                      width: { xs: "180px" },
                      overflow: { xs: "hidden" },
                    }}
                  >
                    {" "}
                    {walletAddress}
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    {" "}
                    <Box>
                      <img
                        src={networkicon}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />{" "}
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { md: "25px", xs: "20px" },
                          fontFamily: "outfit",
                          fontWeight: "500px",
                        }}
                      >
                        {" "}
                        Connected Network
                      </Typography>{" "}
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "#FF990D",
                      fontSize: "14px",
                      fontFamily: "outfit",
                      marginBottom: "40px",

                      width: { xs: "180px" },
                      overflow: { xs: "hidden" },
                    }}
                  >
                    {" "}
                    Polygon Network
                  </Typography>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    {" "}
                    <Box>
                      <img
                        src={balanceicon}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />{" "}
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: { md: "25px", xs: "20px" },
                          fontFamily: "outfit",
                          fontWeight: "500px",
                        }}
                      >
                        {" "}
                        VXT Balance
                      </Typography>{" "}
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      color: "#FF990D",
                      fontSize: "14px",
                      fontFamily: "outfit",
                    }}
                  >
                    {" "}
                    0
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "30px",
                  }}
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    handleCreateFarmData();
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",

                      justifyContent: { xs: "center", md: "flex-end" },
                    }}
                  >
                    <Box
                      sx={{
                        // width: { xs: "auto", md: "645.716px" },
                        // height: "873.513px",
                        width: "100%",
                        borderRadius: "28.422px",
                        background: "#28272C",
                        padding: { xs: "10px", md: "30px" },
                      }}
                    >
                      <Box>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "outfit",
                              fontSize: "14px",
                              fontWeight: 200,
                              marginBottom: "5px",
                            }}
                          >
                            Enter Name
                          </Typography>
                        </Box>
                        <TextField
                          type="text"
                          name="farmName"
                          required
                          inputProps={{ style: { color: "#777" } }}
                          sx={{
                            border: "1px solid #777",
                            borderRadius: "12px",
                            color: "#828282",
                            width: "100%",
                            background: "transparent",
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: "none",
                              },
                          }}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box sx={{ marginTop: "14px" }}>
                        <Typography
                          sx={{
                            fontFamily: "outfit",
                            fontSize: "14px",
                            fontWeight: 200,
                            marginBottom: "5px",
                          }}
                        >
                          Enter your Description
                        </Typography>
                      </Box>
                      <Box className="textArea">
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          name="farmDescription"
                          style={{
                            border: "1px solid #777",
                            borderRadius: "12px",
                            color: "#828282",
                            width: "100%",
                            background: "none",
                            height: "120px",
                            padding: "15px",
                          }}
                          required
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Box sx={{ marginTop: "14px" }}>
                          <Typography
                            sx={{
                              fontFamily: "outfit",
                              fontSize: "14px",
                              fontWeight: 200,
                              marginBottom: "5px",
                            }}
                          >
                            Investment Required
                          </Typography>
                        </Box>
                        <TextField
                          type="text"
                          name="investmentRequired"
                          required
                          inputProps={{ style: { color: "#777" } }}
                          sx={{
                            border: "1px solid #777",
                            borderRadius: "12px",
                            color: "#828282",
                            width: "100%",
                            background: "transparent",
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: "none",
                              },
                          }}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Box sx={{ marginTop: "14px" }}>
                          <Typography
                            sx={{
                              fontFamily: "outfit",
                              fontSize: "14px",
                              fontWeight: 200,
                              marginBottom: "5px",
                            }}
                          >
                            Token Address
                          </Typography>
                        </Box>
                        <TextField
                          type="text"
                          name="tokenAddress"
                          required
                          inputProps={{ style: { color: "#777" } }}
                          sx={{
                            border: "1px solid #777",
                            borderRadius: "12px",
                            color: "#828282",
                            width: "100%",
                            marginBottom: "20px",
                            background: "transparent",
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: "none",
                              },
                          }}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Typography sx={{ fontSize: "21px" }}> Phases</Typography>
                      <form
                        onSubmit={(e) => {
                          return false;
                        }}
                      >
                        {phasesData.map((x, index) => (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                columnGap: "20px",
                                alignItems: "center",
                              }}
                            >
                              {/* priority */}
                              <Box sx={{ marginTop: "14px", width: "50%" }}>
                                <Typography
                                  sx={{
                                    fontFamily: "outfit",
                                    fontSize: "14px",
                                    fontWeight: 200,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Priority
                                </Typography>
                                <TextField
                                  type="number"
                                  name="priority"
                                  inputProps={{ style: { color: "#777" } }}
                                  required
                                  sx={{
                                    border: "1px solid #777",
                                    borderRadius: "12px",
                                    color: "#828282",
                                    width: "100%",
                                    background: "transparent",
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "none",
                                      },
                                  }}
                                  onChange={(e) =>
                                    handleInputPhaseData(
                                      e.target.name,
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </Box>
                              {/* phasename */}
                              <Box
                                sx={{
                                  marginTop: "14px",
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "50%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "outfit",
                                    fontSize: "14px",
                                    fontWeight: 200,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Phase Name
                                </Typography>
                                <TextField
                                  type="text"
                                  name="name"
                                  inputProps={{ style: { color: "#777" } }}
                                  sx={{
                                    border: "1px solid #777",
                                    borderRadius: "12px",
                                    color: "#828282",
                                    width: "100%",
                                    background: "transparent",
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "none",
                                      },
                                  }}
                                  required
                                  onChange={(e) =>
                                    handleInputPhaseData(
                                      e.target.name,
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                columnGap: "20px",
                                alignItems: "center",
                                flexDirection: { md: "row", xs: "column" },
                                rowGap: "14px",
                              }}
                            >
                              {/* Detail */}
                              <Box
                                sx={{
                                  marginTop: "14px",
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "50%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "outfit",
                                    fontSize: "14px",
                                    fontWeight: 200,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Detail
                                </Typography>
                                <TextField
                                  type="text"
                                  name="detail"
                                  inputProps={{ style: { color: "#777" } }}
                                  sx={{
                                    border: "1px solid #777",
                                    borderRadius: "12px",
                                    color: "#828282",
                                    width: "100%",
                                    background: "transparent",
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "none",
                                      },
                                  }}
                                  required
                                  onChange={(e) =>
                                    handleInputPhaseData(
                                      e.target.name,
                                      e.target.value,
                                      index
                                    )
                                  }
                                />
                              </Box>
                              {/* status */}
                              <Box
                                sx={{
                                  marginTop: "14px",
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "50%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "outfit",
                                    fontSize: "14px",
                                    fontWeight: 200,
                                    marginBottom: "5px",
                                  }}
                                >
                                  Status
                                </Typography>
                                <Select
                                  name="status"
                                  placeholder="Status"
                                  fullWidth
                                  className="customDropdown"
                                  sx={{
                                    border: "1px solid #777",
                                    borderRadius: "12px",
                                    color: "#828282",
                                    width: "100%",
                                    background: "transparent",
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        border: "none",
                                      },
                                  }}
                                  onChange={(e) =>
                                    handleInputPhaseData(
                                      e.target.name,
                                      e.target.value,
                                      index
                                    )
                                  }
                                >
                                  <MenuItem value="Inactive">Inactive</MenuItem>
                                  <MenuItem value="Active"> Active</MenuItem>
                                  <MenuItem value="Completed">
                                    Completed
                                  </MenuItem>
                                  <MenuItem value="Paused"> Paused</MenuItem>
                                  <MenuItem value="Skipped">Skipped</MenuItem>
                                  <MenuItem value="Terminated">
                                    {" "}
                                    Terminated
                                  </MenuItem>
                                </Select>
                              </Box>
                            </Box>
                          </>
                        ))}
                        <Stack
                          direction={isMobile ? "row" : "row"}
                          alignItems="center"
                          display="flex"
                          justifyContent="flexStart"
                          spacing={2}
                          marginTop={2}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              background: "transparent",
                              // border: "1px solid #ff990d",
                              // border:"2px solid transparent",
                              textTransform: "capitalize",
                              borderRadius: "9px",
                              gap: "5px",

                              border: " 1px solid #F2F2F2",

                              "&:hover": {
                                background: "transparent",
                              },
                            }}
                            onClick={() => addPhase()}
                            // type="submit"
                          >
                            <AddCircleOutlineIcon />
                            Add Phase
                          </Button>

                          <Button
                            variant="contained"
                            sx={{
                              background: "#EB5757",
                              // border: "1px solid white",
                              color: "white",
                              textTransform: "capitalize",
                              borderRadius: "9px",
                              gap: "5px",
                            }}
                            onClick={() => removePhase()}
                          >
                            <RemoveCircleOutlineIcon />
                            Remove Phase
                          </Button>
                        </Stack>
                      </form>

                      <Box>
                        <Box sx={{ marginTop: "14px" }}>
                          <Typography
                            sx={{
                              fontFamily: "outfit",
                              fontSize: "14px",
                              fontWeight: 200,
                              marginBottom: "5px",
                            }}
                          >
                            Manager
                          </Typography>
                        </Box>
                        <TextField
                          type="text"
                          name="farmManagerDetails"
                          required
                          inputProps={{ style: { color: "#777" } }}
                          sx={{
                            border: "1px solid #777",
                            borderRadius: "12px",
                            color: "#828282",
                            width: "100%",

                            background: "transparent",
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: "none",
                              },
                          }}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box>
                        <Box sx={{ marginTop: "14px" }}>
                          <Typography
                            sx={{
                              fontFamily: "outfit",
                              fontSize: "14px",
                              fontWeight: 200,
                              marginBottom: "5px",
                            }}
                          >
                            APY
                          </Typography>
                        </Box>
                        <TextField
                          type="text"
                          name="farmApy"
                          required
                          inputProps={{ style: { color: "#777" } }}
                          sx={{
                            border: "1px solid #777",
                            borderRadius: "12px",
                            color: "#828282",
                            width: "100%",

                            background: "transparent",
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: "none",
                              },
                          }}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box
                        sx={{
                          ...circleStyle,
                          top: "298%",
                          display: { xs: "flex", md: "none" },
                          left: "80%",
                          right: "0%",
                          transform: "translate(-50%)",
                          width: "126.625px",
                          height: "147.734px",
                          borderRadius: "547.625px",
                          background: "#9D00FF",
                          filter: "blur(120px)",
                          backdropFilter: "blur(120px)",
                          WebkitBackdropFilter: "blur(120px)",
                        }}
                      />
                      <Button
                        sx={{
                          fontSize: "14px",
                          fontFamily: "outfit",
                          width: "203.307px",
                          height: "39.894px",
                          textTransform: "capitalize",
                          ...borderGradient,
                          color: "white",
                          marginTop: "10px",
                        }}
                        type="submit"
                      >
                        Registered
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <LandingPageFooter />
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent", // Set the dialog background to transparent
            boxShadow: "none", // Remove the default Material-UI box shadow
          },
        }}
        open={showsuccessDialog}
        onClose={handleSuccessDialog}
        maxWidth={maxWidth}
      >
        <DialogContent>
          <Box>
            <Box
              sx={{
                borderRadius: "24px",

                background: "  var(--272727, #272727)",
                backdropFilter: "blur(2px)",
                position: "relative",
                padding: { xs: "10px", md: "30px" },
              }}
            >
              <Box>
                {" "}
                <img src={purchaseimg} width="100%" alt="" />
              </Box>
              <Box sx={{ flex: 1, textAlign: "center" }}>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: { xs: "13px", md: "18px" },
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  Farm Created Successfully
                </Typography>
              </Box>
              <Button
                sx={{
                  position: " absolute",
                  right: { xs: "5px", md: "8px" },
                  fontSize: { xs: "12px", md: "20px" },
                  color: "#fff",
                  top: "20px",
                }}
                onClick={handleSuccessDialog}
              >
                X
              </Button>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                    width: { xs: "auto", md: "400px" },
                    mt: "50px",
                    background:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                    fontSize: {
                      xs: 10,
                      sm: 7,
                      md: 10,
                      lg: 18,
                    },
                  }}
                  onClick={() => handleSuccessDialog()}
                >
                  Ok
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreateFarm;
