import {
  DAPPTOKEN_CONTRACT_ADDRESS,
  DAPPTOKEN_CONTRACT_ABI,
} from "../constants/ABIs/newTokenABI";
import { DappToken } from "../contracts/DappToken";
import { BrowserProvider, ethers } from "ethers";

const utils = ethers;
export class TokenService {
  tokenInstance: DappToken;
  constructor() {
    this.tokenInstance = new DappToken(
      DAPPTOKEN_CONTRACT_ADDRESS,
      DAPPTOKEN_CONTRACT_ABI
    );
  }

  getTokenSymbol = async (): Promise<string> => {
    try {
      return await this.tokenInstance.getTokenSymbol();
    } catch (err) {
      throw err;
    }
  };

  getDecimals = async (): Promise<number> => {
    try {
      return await this.tokenInstance.getDecimals();
    } catch (err) {
      throw err;
    }
  };

  getbalance = async (account: string): Promise<BigInt> => {
    try {
      return await this.tokenInstance.getBalance(account);
    } catch (err) {
      throw err;
    }
  };

  transferToken = async (to: string, value: number): Promise<boolean> => {
    try {
      return await this.tokenInstance.transferToken(to, value);
    } catch (err) {
      throw err;
    }
  };

  approveTransaction = async (
    spender: string,
    amount: number
  ): Promise<object> => {
    try {
      let newAmount = BigInt(0);
      console.log("amount: ", amount * 10 ** 10);
      if (amount > 0 && amount < 1) {
        newAmount =
          BigInt(Math.ceil(amount * 10 ** 10)) * BigInt(10) ** BigInt(8);
      } else {
        newAmount = BigInt(amount) * BigInt(10) ** BigInt(18);
      }
      return await this.tokenInstance.approveTransaction(spender, newAmount);
    } catch (err) {
      throw err;
    }
  };

  getGasBalance = async (
    address: string,
    provider: BrowserProvider
  ): Promise<number> => {
    let bal = 0;
    try {
      const balance = await provider?.getBalance(address);
      console.log("Gas Balance:", utils.formatEther(balance || "0"), "MATIC");
      bal = Number(utils.formatEther(balance || "0"));
    } catch (error) {
      console.error("Error getting gas balance:", error);
      throw error;
    }
    return bal;
  };
}
