import React from "react";
import VoeuxPayHeader from "./voexheader/VoeuxPayHeader";
import { Box, Stack } from "@mui/system";
import { Grid, Typography, useMediaQuery } from "@mui/material";

import vxpayimg from "../../assets/voeuxpay/vxpayimg.png";

import securityimg from "../../assets/voeuxpay/Security Shield.png";
import inclusiveimg from "../../assets/voeuxpay/inclus.png";
import innovationimg from "../../assets/voeuxpay/innovation.png";
import adaptimg from "../../assets/voeuxpay/adap.png";
import partnerimg from "../../assets/voeuxpay/Partnership.png";
import empowerimg from "../../assets/voeuxpay/Empowerment.png";

import whoweare from "../../assets/voeuxpay/who we are.svg";
import ourvision from "../../assets/voeuxpay/our vision.svg";

import Choosecards from "./Choosecards";
import VoeuxPayFooter from "./VoeuxPayFooter";

function VoeuxPayAboutUs() {
  const isMobile = useMediaQuery("(max-width:600px)"); // Custom breakpoint for mobile (xs)
  const coreValues = [
    {
      id: 1,
      image: securityimg,
      title: "Security",
      caption:
        "The trust and security of our users are paramount. We implement robust security measures to protect transactions, and user data, and ensure the integrity of our payment platform.",
    },
    {
      id: 2,
      image: inclusiveimg,
      title: "Inclusivity",
      caption:
        "We believe in fostering an inclusive financial landscape by providing support for various fiat currencies and crypto, enabling businesses and partners to transact seamlessly across borders.",
    },
    {
      id: 3,
      image: innovationimg,
      title: "Innovation",
      caption:
        " We are committed to driving innovation in the world of online payments, leveraging the transformative power of cryptocurrencies to create efficient and secure solutions.",
    },
    {
      id: 4,
      image: adaptimg,
      title: "Adaptability",
      caption:
        " We embrace change and continually adapt to evolving market trends and technological advancements, ensuring that our platform remains at the forefront of the crypto payments industry.",
    },
    {
      id: 5,
      image: partnerimg,
      title: "Partnerships",
      caption:
        "We actively seek partnerships with online merchants, financial institutions, and technology providers to create a collaborative ecosystem that benefits all stakeholders.",
    },
    {
      id: 6,
      image: empowerimg,
      title: "User Empowerment",
      caption:
        "When we empower users by providing them with the tools and knowledge to navigate the world of crypto payments, fostering financial literacy and responsible use of digital assets.",
    },
  ];

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  return (
    <>
      <Box
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 2.7,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <VoeuxPayHeader />
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            ...circleStyle,
            top: { xl: "40%" },
            left: "0%",
            transform: "translateY(-70%)",
            width: "300px", // Adjusted width
            height: "300px", // Adjusted height
            borderRadius: "547.625px",
            background:
              "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
            filter: "blur(160px)",
            backdropFilter: "blur(160px)",
            WebkitBackdropFilter: "blur(160px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "0%" },

              right: "-20%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            ...circleStyle,
            top: { xl: "40%" },
            right: "0%",
            transform: "translateY(-50%)",
            width: "300px", // Adjusted width
            height: "300px", // Adjusted height
            borderRadius: "672.058px",
            background: "var(--56-ccf-2, #56CCF2)",
            filter: "blur(162px)",
            backdropFilter: "blur(162px)",
            WebkitBackdropFilter: "blur(162px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "0%" },

              right: "-20%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                flex: 0.1,
                marginLeft: "1px",
                borderBottom: "1px solid #FFBB33",
              }}
            ></div>
            <Typography
              sx={{
                fontFamily: "Bebas Neue",
                position: "relative",
                marginLeft: "10px",
                fontSize: { xs: "18px", md: "31.155px" },
                textTransform: "uppercase",
                color: " #FB3",
                marginTop: "20px",
              }}
            >
              About Us
            </Typography>
          </Box>
        </Box>

        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          sx={{ mt: { xs: 3, md: 7 }, padding: "16px" }}
        >
          <Grid item xs={12} md={6}>
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "22px" },
                  fontFamily: "outfit",
                }}
              >
                Established with a passion for innovation and a commitment to
                excellence,{" "}
                <span
                  style={{
                    color: "#FB3",
                  }}
                >
                  VOEUX PAY{" "}
                </span>
                is a team of seasoned professionals in the fintech industry. We
                bring together expertise in technology, finance, and security to
                provide a payment gateway that not only meets industry standards
                but also exceeds the expectations of our clients.{" "}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sx={{ position: "relative" }}>
            <Box
              sx={{
                height: { xs: 250, sm: 300, md: 400 },
                width: { xs: 300, sm: 400, md: 500 },
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                mx: "auto",
                position: "relative",
                p: { xs: 2 },
              }}
            >
              <img
                src={whoweare}
                alt="vx"
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: "20px",
            display: { xs: "flex" },
            flexDirection: { xs: "column-reverse", md: "row" },
            alignItems: "flex-end",
            padding: "16px",
          }}
        >
          <Grid item xs={12} md={6}>
            <Stack direction="column" spacing={2}>
              <Box sx={{ display: "flex" }}>
                <img src={ourvision} alt="vx" height={"100%"} width={"100%"} />
              </Box>
            </Stack>
            {!isMobile && (
              <Stack direction="column" spacing={2}>
                <Box sx={{ display: "flex" }}>
                  <img src={vxpayimg} alt="vx" height={"100%"} width={"100%"} />
                </Box>
              </Stack>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ position: "relative", marginBottom: "5rem" }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "16px", md: "22px" },
                  fontFamily: "outfit",
                }}
              >
                We envision a world where online transactions are secure and
                reliable and effortlessly integrated into the daily operations
                of businesses. Through continuous innovation and dedication, we
                strive to be at the forefront of driving the future of digital
                payments.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* card*/}
        <Typography
          sx={{
            fontFamily: "Bebas Neue",
            fontSize: { xs: "23px", md: "36px" },
            textAlign: "center",
          }}
        >
          core values
        </Typography>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            ...circleStyle,
            top: { xl: "250%" },
            left: "0%",
            transform: "translateY(-70%)",
            width: "370px", // Adjusted width
            height: "300px", // Adjusted height
            borderRadius: "547.625px",
            background:
              "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
            filter: "blur(160px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "0%" },

              right: "-20%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            ...circleStyle,
            top: { xl: "250%" },
            right: "10%",
            transform: "translateY(-50%)",
            width: "370px", // Adjusted width
            height: "300px", // Adjusted height
            borderRadius: "672.058px",
            background: "var(--56-ccf-2, #56CCF2)",
            filter: "blur(162px)",
            backdropFilter: "blur(162px)",
            WebkitBackdropFilter: "blur(162px)",
            overflow: "hidden",
            "@media (min-width: 1721px)": {
              width: "450px", // Adjusted width
              height: "700px", // Adjusted height
              top: { xl: "0%" },

              right: "-20%",
              filter: "blur(160px)",
              backdropFilter: "blur(160px)",
              WebkitBackdropFilter: "blur(160px)",
            },
          }}
        ></Box>
        <Box>
          {" "}
          <>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {coreValues.slice(0, 3).map((card, index) => (
                <Box
                  key={card.id}
                  width={"393.776px"}
                  height={"350"}
                  m={2}
                  p={2}
                  borderRadius={4}
                  boxShadow={3}
                  bgcolor="#181818"
                  textAlign="center"
                  position="relative"
                  mb={index !== 2 ? 2 : 0}
                >
                  <img
                    src={card.image}
                    alt={card.title}
                    height={100}
                    style={{
                      position: "absolute",
                      top: 10,
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Bebas Neue",
                      marginBottom: "10px",
                      marginTop: "100px",
                      fontSize: "27.829px",
                    }}
                    variant="h5"
                    mt={4}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "outfit" }}
                    variant="body1"
                    color="white"
                    fontSize={{ xs: "14px", md: "18px" }}
                    marginTop={4}
                  >
                    {card.caption}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {coreValues.slice(3).map((card, index) => (
                <Box
                  key={card.id}
                  width={"393.776px"}
                  height={"350"}
                  m={2}
                  p={2}
                  borderRadius={4}
                  boxShadow={3}
                  bgcolor="#181818"
                  textAlign="center"
                  position="relative"
                  mb={index !== 2 ? 2 : 0}
                >
                  <img
                    src={card.image}
                    alt={card.title}
                    height={100}
                    style={{
                      position: "absolute",
                      top: 10,
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Bebas Neue",
                      marginBottom: "10px",
                      marginTop: "100px",
                      fontSize: { xs: " 25.43px", md: "27.829px" },
                    }}
                    variant="h5"
                    mt={4}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "outfit" }}
                    variant="body1"
                    color="white"
                    fontSize={"18px"}
                    marginTop={4}
                  >
                    {card.caption}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", padding: "16px" }}
        >
          <Box
            sx={{
              // width: "1310px",
              // height: "433px",
              backgroundColor: "#181818",
              borderRadius: "24px",
              marginTop: "15px",
              padding: { xs: "10px", md: "35px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box sx={{ width: { xs: "none", md: "495.442px" } }}>
                <Typography
                  sx={{
                    fontFamily: "Bebas Neue",
                    fontSize: { xs: "36px", md: "72px" },
                    fontWeight: "700px",
                  }}
                >
                  Join Us on the Path to{" "}
                  <span
                    style={{
                      color: "#FF990D",
                      fontFamily: "Bebas Neue",
                    }}
                  >
                    Success
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    fontFamily: "outfit",
                  }}
                >
                  Whether you're a small startup or an established enterprise,
                  <span style={{ color: "#FF990D" }}>Voeux Pay</span> is here to
                  partner with you on your journey to success. Experience the
                  difference between a reliable and efficient payment gateway.
                  Let's transform the way you do business online.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "350%" },
                  left: "0%",
                  transform: "translateY(-70%)",
                  width: "370px", // Adjusted width
                  height: "300px", // Adjusted height
                  borderRadius: "547.625px",
                  background:
                    "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
                  filter: "blur(160px)",
                  backdropFilter: "blur(160px)",
                  WebkitBackdropFilter: "blur(160px)",
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "700px", // Adjusted height
                    top: { xl: "0%" },

                    right: "-20%",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                  },
                }}
              ></Box>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "350%" },
                  right: "10%",
                  transform: "translateY(-50%)",
                  width: "370px", // Adjusted width
                  height: "300px", // Adjusted height
                  borderRadius: "672.058px",
                  background: "var(--56-ccf-2, #56CCF2)",
                  filter: "blur(162px)",
                  backdropFilter: "blur(162px)",
                  WebkitBackdropFilter: "blur(162px)",
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "700px", // Adjusted height
                    top: { xl: "0%" },

                    right: "-20%",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                  },
                }}
              ></Box>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Choosecards />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box marginTop={10}>
        {" "}
        <VoeuxPayFooter />
      </Box>
    </>
  );
}

export default VoeuxPayAboutUs;
