import * as React from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import { Box, Card, CardContent, CardMedia, Typography, useMediaQuery } from '@mui/material';
import "../../../components/button/ButtonLiner"
import bitcoinEventImage from "../../../assets/images/bitcoinEvent.png";
import communityImage from "../../../assets/images/communityImage.png";


interface CommunityCard {
  title: string;
  content: string;
  image: string;
  button: string;
  url: string;
}

const communityCards: CommunityCard[] = [
  {
    title: "Events",
    content:
      "Start your journey of knowledge, growth, and unforgettable moments with our events",
    image: bitcoinEventImage,
    button: "View Events",
    url: "",
  },
  {
    title: "Community",
    content:
      "Embark on a journey of growth, learning, and shared experiences with our passionate community",
    image: communityImage,
    button: "Join Discord",
    url: "https://discord.gg/YrC7npAQ53",
  },
];

const communityCardsMobile: CommunityCard[] = [
  {
    title: "Events",
    content:
      "Start your journey of knowledge and growth with us.",
    image: bitcoinEventImage,
    button: "View Events",
    url: "http",
  },
];

interface CardSectionProps { }

const CardSection: React.FC<CardSectionProps> = () => {
  const theme = useTheme() as Theme;

  const borderGradient = {
    background: "linear-gradient(#232323 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
    border: "1px solid transparent",
    borderRadius: {xs:"5px",md:"2px"},
    cursor: "pointer",
    padding: {xs:"0%  1% 0% 2%",md:"1% 2% 1% 1%"},
    marginRight: {md:"20px"},
    backgroundColor: "#232323",
  };

  const con_wall = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#ff990d",
    cursor: "pointer",
    padding: "2px 6px",
    textDecoration: "none",
  };

  const isMobile = useMediaQuery('(max-width:600px)');
  const mediaQueryStyle = {

    '@media (max-width: 600px)': {
      height: '120px',
      width: '140px',
    },
  };


  // Determine the number of cards to display based on screen size


  return (<>
    {isMobile ? communityCardsMobile.map((card, index) => (


<Card key={index}
  sx={{
    ...borderGradient,
    display: 'flex',
    my: 2,
    opacity: 1,
    padding:"8px 8px !important",
    boxShadow: "1.262px -1.893px 1.893px 0px rgba(152, 89, 36, 0.15) inset, -1.262px 1.893px 1.893px 0px rgba(152, 89, 36, 0.15) inset",
    backdropFilter: "blur(14px)",
    marginBottom: "4px",
    borderRadius: {xs:4,md:"20px"},
    marginTop:"0px !important"
  }}>
  <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center", py: { xs: 0, }, borderRadius:"20px", }}>
    <CardMedia
      component="img"
      image={card.image}
      alt={card.title}
      //  width={"100%"} 
      style={{
        height: isMobile ? '132px' : "160px",
        width: isMobile ? '120px' : "170px",
        ...mediaQueryStyle,
        borderRadius: isMobile ? '0 0 0 22px' : '0 0 0 22px',
        objectFit:"fill"
      }}
    />
    <CardContent sx={{ maxWidth: { sm: "100%", }, padding:" 0px !important",paddingLeft:"15px !important"
   
   }} >
      {/* <Typography component="div" variant="h5" sx={{
        color: "#FFF",
        fontSize: { xs: "18px", sm: "20px" },
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        mb:{xs:"7px"},
        fontFamily:"outfit"
      }}>
        {card.title}
      </Typography> */}
      <Typography variant="subtitle1" color="text.secondary" component="div" sx={{
        color: "#FFF",
        fontSize: { xs:"14px", sm: 14 },
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "18px",
        fontFamily:"outfit"
      }}>
        {card.content}
      </Typography>
      {card.url !== "" && 
       <Box sx={{ display: 'flex', alignItems: 'center', mt: "6px", }}>
       <Box sx={{
         height:{md:"40px"},
         width:{md:"140px"},
         display:"flex",
         justifyItems:"center",
         borderRadius: { xs: "8px" },
         border: " 0.835px solid #FFF",
         background: "rgba(255, 255, 255, 0.07)",
         // height: "37.581px",
         padding: { xs: "8px ", sm: "7px 10px",lg:"2px" },
         justifyContent: "center",
         alignItems: "center",
         
         // gap: "8.351px",
         flexShrink: 0,
         "&:hover": {
           background: "#fff",
           "& .addIcon": {
             color: "black"
           }
         }
       }}


       onClick={()=> {
         if(card.button == "Join Discord"){
           window.open(card.url, "_blank");
         }
       }}
       
       >
         <Typography  className="addIcon"
sx={{
           color: "#FFF",
           fontSize: { xs:"12px", sm: 12,lg:14 },
           fontStyle: "normal",
           fontWeight: 500,
           lineHeight: "normal",
           "&:hover": {

             color: "#000",
           },
           fontFamily:"outfit"

         }}>
           {card.button}
         </Typography>
       </Box>
     </Box>
      }
     
    </CardContent>
  </Box>
</Card >


// </div>
)) : communityCards.map((card, index) => (


      <Card key={index}
        sx={{
          ...borderGradient,
          display: 'flex',
          my: 2,
          opacity: 1,
          boxShadow: "1.262px -1.893px 1.893px 0px rgba(152, 89, 36, 0.15) inset, -1.262px 1.893px 1.893px 0px rgba(152, 89, 36, 0.15) inset",
          backdropFilter: "blur(14px)",
          marginBottom: "4px",
          borderRadius: {xs:4,md:"20px"},
        }}>
        <Box key={index} sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "center", py: { xs: 0, }, borderRadius:"20px", }}>
          <CardMedia
            component="img"
            image={card.image}
            alt={card.title}
            //  width={"100%"}
            style={{
              height: isMobile ? '120px' : "160px",
              width: isMobile ? '120px' : "170px",
              ...mediaQueryStyle,
              borderRadius: isMobile ? '0 0 0 22px' : '0 0 0 22px',
            }}
          />
          <CardContent sx={{ maxWidth: { sm: "100%", },mt:{xs:"4%",xl:"2%"}, py: 0,
          "@media (min-width: 1721px)": {
            mt:"2%"
          },
         }} >
            <Typography component="div" variant="h5" sx={{
              color: "#FFF",
              fontSize: { xs: 14, sm: 22 },
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              mb:{xs:"2px", md:"6px"},
              fontFamily:"outfit"
            }}>
              {card.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div" sx={{
              color: "#FFF",
              fontSize: { xs:"12px", sm: 14 },
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "156%",
              fontFamily:"outfit"
            }}>
              {card.content}
            </Typography>
            {card.url !== "" && 
             <Box sx={{ display: 'flex', alignItems: 'center', mt: "6px", }}>
             <Box sx={{
               height:{md:"40px"},
               width:{md:"140px"},
               display:"flex",
               justifyItems:"center",
               borderRadius: { xs: "7px", sm: "11.331px" },
               border: " 0.835px solid #FFF",
               background: "rgba(255, 255, 255, 0.07)",
               // height: "37.581px",
               padding: { xs: "8px ", sm: "7px 10px",lg:"2px" },
               justifyContent: "center",
               alignItems: "center",
               mt: "8px",
               // gap: "8.351px",
               flexShrink: 0,
               "&:hover": {
                 background: "#fff",
                 "& .addIcon": {
                   color: "black"
                 }
               }
             }}


             onClick={()=> {
               if(card.button == "Join Discord"){
                 window.open(card.url, "_blank");
               }
             }}
             
             >
               <Typography  className="addIcon"
sx={{
                 color: "#FFF",
                 fontSize: { xs: 7, sm: 12,lg:14 },
                 fontStyle: "normal",
                 fontWeight: 500,
                 lineHeight: "normal",
                 "&:hover": {

                   color: "#000",
                 },
                 fontFamily:"outfit"

               }}>
                 {card.button}
               </Typography>
             </Box>
           </Box>
            }
           
          </CardContent>
        </Box>
      </Card >


      // </div>
    ))}
  </>
  );
};

export default CardSection;
