

export const convertDuration = (num:number)=>{
        console.log("received duration:", num);
        const d = Math.floor(num/(3600*24));
        const h = Math.floor((num-(d*3600*24))/3600);
        const m = Math.round(((num-(d*3600*24))%3600)/60);
      
        if(d>=1){
          if(d == 1){
            return(`${d} day`);
          }
          return(`${d} days`);
        }else if(h>=1){
          if(h == 1){
            return(h + " hour");
          }
          return(h + " hours ");
        }else{
          return (m+" minutes");
        }
}
