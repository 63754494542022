import React, {  useState } from "react";
import {
  DialogProps,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import coinbase_wallet from "../../assets/svgs/WalletLinkIcon.svg";
import met_mask from "../../assets/svgs/met_mask.svg";
import trust_wallet from "../../assets/svgs/trustwallettt.svg";
import phontam_wallet from "../../assets/svgs/White Ghost_docs_nu 1.svg";
import { connectWallet } from "../../redux/slices/walletSlice";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import axios from "axios";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { Environment } from "src/environments";

interface ConnectwalletProps {
  onClose?: () => void;
  handleRedirect?: () => void;
  showToastMessageClick?:(text:string)=> void
}

const walletsButton = [
  { id: 1, image: met_mask, name: "Metamask", checkingValue: "metamask" },
  { id: 2, image: trust_wallet, name: "Trust Wallet", checkingValue: "trust" },
  {
    id: 3,
    image: coinbase_wallet,
    name: "Coin Base",
    checkingValue: "coinbase",
  },
  { id: 4, image: phontam_wallet, name: "Phantom", checkingValue: "phantom" },
];

const Muiwallet: React.FC<ConnectwalletProps> = ({
  onClose,
  handleRedirect,
  showToastMessageClick
}) => {
  console.log("showToastMessageClick",showToastMessageClick)
  const [showEmail, setShowEmail] = useState(false);
  const [showWallet, setShowWallet] = useState(true);
  const [email, setEmail] = useState("");
  const account = useSelector((state: RootState) => state.wallet.account);
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    console.log("Close button clicked");
    onClose && onClose();
  };

  const handleEmailButtonClick = () => {
    setShowWallet(false);
    setShowEmail(true);
  };

  const handleEmailChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setEmail(e.target.value);
  };

  const handleConnectClick = () => {
    console.log(`Connecting with email: ${email}`);
    onClose && onClose();
  };

  const handleDigitalWallet = () => {
    setShowEmail(false);
    setShowWallet(true);
  };

  const [networkName, setNetworkName] = useState('Mumbai testnet');
  const [rpcUrl, setRpcUrl] = useState('https://polygon-mumbai-bor-rpc.publicnode.com');

  const importNetwork = async () => {
    try {
      console.log("in try import network", networkName, rpcUrl);
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x13881',
          chainName: networkName,
          nativeCurrency: {
            name: 'Matic',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: [rpcUrl],
          blockExplorerUrls:["https://mumbai.polygonscan.com"], 
        }],
      });
      showToastMessageClick && showToastMessageClick("Network imported successfully!")
      // alert('Network imported successfully!');
      return;
    } catch (error) {
      console.error('Error importing network:', error);
      // alert('Failed to import network. Check console for error details.');
    }
  };

  const checkNetwork = async()=>{
    try{
        const providr = new ethers.BrowserProvider(window.ethereum);
        const network = await providr.getNetwork();
        console.log("network details: ", network);
        const chainId = network.chainId;
        if(Number(chainId) != 80001){
          return false;
        }else{
          return true;
        }
       
    }catch (error) {

    }
  }
  

  const connectUserWallet = async () => {
    console.log("Ïn connect to meta mask");
    handleShowLoader(true);
    if (window.ethereum) {
      try {
         const chain = await checkNetwork();
        if(!chain){
          console.log("not appropriate network");
          importNetwork();
        }
        const providr = new ethers.BrowserProvider(window.ethereum);
        
        await providr.send("eth_requestAccounts", []);
        const signer = await providr.getSigner();
        const address = await signer.getAddress();
        showToastMessageClick && showToastMessageClick("Meta Mask connected SuccessFully")
        console.log("meta mask is connected", address);
        dispatch(
          connectWallet({
            account: address,
            provider: signer.provider._network.name,
            balance: 0,
          })
        );
        localStorage.setItem("account_address", address);

        try {
          const getMetavoeuxResponse = await axios.get(
            `${Environment.metavoeuxBackend}ownuser`
          );

          const checkMetaVoeuxUser: any =
            getMetavoeuxResponse.data.filter(
              (user: any) => user.walletAddress == address
            ).length > 0
              ? true
              : false;
          localStorage.setItem("ownUser", checkMetaVoeuxUser);

          try {
            const response = await axios.post(
              `${Environment.metavoeuxBackend}user`,
              { walletAddress: address }
            );
            localStorage.setItem("userId", response.data._id);
            console.log("post data", response.data._id);
            if (response.status == 201) {
              handleClose();
              handleShowLoader(false);

              handleRedirect && handleRedirect();
              // showToastMessage("Connected to meta mask")
            }
          } catch (error: any) {
            console.log("error", error);
            if (
              error?.response?.data?.message == "WalletAddress already exists"
            ) {
              try {
                const responseGetUser = await axios.get(
                  `${Environment.metavoeuxBackend}user/userDetails`,
                  {
                    params: { walletAddress: address },
                  }
                );
                console.log("walletAddress", responseGetUser);
                handleShowLoader(false);
                localStorage.setItem("userId", responseGetUser.data._id);
                handleClose();
                handleRedirect && handleRedirect();
              } catch (error) {
                console.log("Error in reading details");
              }
            }
          }

          // handleClose();
          // handleRedirect && handleRedirect()
          // setUserOfferRecieved([...response.data])
        } catch (error) {
          console.error("Error fetching data:", error);
          handleClose();
          handleShowLoader(false);
        }
        setIsConnected(true);
      } catch (err) {
        console.error(err);
      }
    } else {
      // alert("Metamask is not detected in the browser: Please install metamask extension");
      console.error("Metamask is not detected in the browser");
    }
  };

  const connectMetaMask = () => {
    const { ethereum } = window;
    console.log("isMetaMaskInstalled ====>", ethereum?.isMetaMask);
    if (ethereum && ethereum.isMetaMask) {
      connectUserWallet();
    } else {
      window.location.href = "https://metamask.io/";
    }
  };

  const connectCoinbase = () => {
    console.log("-----------In connectCoinbase ----------------");
    const { ethereum } = window;
    console.log("isMetaMaskInstalled ====>", ethereum);
    if (ethereum && ethereum.isCoinBase) {
      connectUserWallet();
    } else {
      // window.location.href = 'https://www.coinbase.com/wallet';
      console.log("isCoinBaseInstalled ====>", ethereum);
    }
  };
  const connectTrust = () => {
    const { ethereum } = window;
    if (ethereum && ethereum.isTrust) {
      connectUserWallet();
    } else {
      window.location.href = "https://trustwallet.com/";
    }
  };
  const connectPhantom = () => {
    const { ethereum } = window;
    if (ethereum && ethereum.isPhantom) {
      connectUserWallet();
    } else {
      window.location.href = "https://phantom.app/download";
    }
  };
  const wallets: Record<string, () => void> = {
    metamask: connectMetaMask,
    coinbase: connectCoinbase,
    trust: connectTrust,
    phantom: connectPhantom,
  };

  const handleWalletConnect = (wallet: string) => {
    wallets[wallet]();
  };

  const [showLoader, setShowLoader] = useState<any>(false);
  const handleShowLoader = (active: any) => {
    setShowLoader(active);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);

  // const [toastMessage, setToastMessage] = useState("")
  // const [showToast, setShowToast] = useState(false)
  // const showToastMessage = (text:string) => {
  //   setToastMessage(text)
  //   setShowToast(true)
  // }

  // const hideToastMessage = () => {
  //   setShowToast(false)
  // }

  const isMobile = useMediaQuery("(max-width:600px)");
  const isCustomWidth = useMediaQuery(
    "(min-width: 601px) and (max-width: 710px)"
  );

  return (
    <>
      {/* <ToastMessage text={toastMessage} display={showToastMessage} handleClose={closeToastMessage}   />
    <ToastMessageFailure text={toasterrorMessage} display={showToastErrorMessage} handleClose={closeToastErrorMessage}/> */}
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1,
            left: 0,
            top: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}

      {isCustomWidth ? (
        <Box>
          {/* First Row */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              gap: "5px", // Adjust the gap between the boxes
            }}
          >
            {walletsButton.slice(0, 2).map((wallet, index) => (
              <Box
                key={wallet.id}
                sx={{
                  my: 2,

                  width: "45%", // Adjust width
                  height: "45px", // Adjust height
                  border: "1px solid #474747", // Modify border properties
                  borderRadius: "8px", // Adjust border radius
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: index === 0 ? "10px" : 0,
                  gap: "10px",
                  ":hover": {
                    cursor: "pointer",
                    border: "1px solid #fff",
                  },
                }}
                onClick={() => handleWalletConnect(wallet.checkingValue)}
              >
                <img
                  src={wallet.image}
                  alt="coin"
                  style={{ width: "30px", height: "30px" }}
                />{" "}
                {/* Adjust image size */}
                <Typography
                  align="center"
                  sx={{ color: "#fff", fontFamily: "outfit", fontSize: "12px" }}
                >
                  {wallet.name}
                </Typography>
              </Box>
            ))}
          </Stack>

          {/* Second Row */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              gap: "5px", // Adjust the gap between the boxes
            }}
          >
            {walletsButton.slice(2).map((wallet, index) => (
              <Box
                key={wallet.id}
                sx={{
                  my: 2,
                  width: "45%", // Adjust width
                  height: "45px", // Adjust height
                  border: "1px solid #474747", // Modify border properties
                  borderRadius: "8px", // Adjust border radius
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: index === 0 ? "10px" : 0,
                  gap: "10px", // Adjust margins
                  ":hover": {
                    cursor: "pointer",
                    border: "1px solid #fff",
                  },
                }}
                onClick={() => handleWalletConnect(wallet.checkingValue)}
              >
                <img
                  src={wallet.image}
                  alt="coin"
                  style={{ width: "30px", height: "30px" }}
                />{" "}
                {/* Adjust image size */}
                <Typography
                  align="center"
                  sx={{ color: "#fff", fontFamily: "outfit", fontSize: "12px" }}
                >
                  {wallet.name}
                </Typography>
              </Box>
            ))}
          </Stack>
          <Box
            sx={{ margin: "1.5rem", marginLeft: "0px", marginTop: "0.5rem" }}
          >
            <Typography
              style={{
                color: "white",
                margin: "0",
                fontSize: "15px",
                fontFamily: "outfit",
              }}
            >
              By selecting wallet you agree to Metavoeux
            </Typography>
            <Link
              href="#"
              style={{
                color: "orange",
                textDecoration: "none",
                margin: "0",
                fontSize: "15px",
                fontFamily: "outfit",
                cursor: "auto",
              }}
            >
              Privacy Policy
            </Link>{" "}
            <span style={{ color: "white" }}>&</span>{" "}
            <Link
              href="#"
              style={{
                color: "orange",
                textDecoration: "none",
                margin: "0",
                fontSize: "15px",
                fontFamily: "outfit",
                cursor: "auto",
              }}
            >
              Terms and Condition
            </Link>
          </Box>
        </Box>
      ) : isMobile ? (
        <Box>
          <Box>
            {/* First Row */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                gap: "1px", // Adjust the gap between the boxes
              }}
            >
              {walletsButton.slice(0, 2).map((wallet, index) => (
                <Box
                  key={wallet.id}
                  sx={{
                    my: 0,

                    width: "45%", // Adjust width
                    height: "45px", // Adjust height
                    border: "1px solid #474747", // Modify border properties
                    borderRadius: "8px", // Adjust border radius
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: index === 0 ? "10px" : 0,
                    gap: "10px",

                    ":hover": {
                      cursor: "pointer",
                      border: "1px solid #fff",
                    },
                  }}
                  onClick={() => handleWalletConnect(wallet.checkingValue)}
                >
                  <img
                    src={wallet.image}
                    alt="coin"
                    style={{ width: "30px", height: "30px" }}
                  />{" "}
                  {/* Adjust image size */}
                  <Typography
                    align="center"
                    sx={{
                      color: "#fff",
                      fontFamily: "outfit",
                      fontSize: "12px",
                    }}
                  >
                    {wallet.name}
                  </Typography>
                </Box>
              ))}
            </Stack>

            {/* Second Row */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                gap: "1px", // Adjust the gap between the boxes
              }}
            >
              {walletsButton.slice(2).map((wallet, index) => (
                <Box
                  key={wallet.id}
                  sx={{
                    my: 1,
                    width: "45%", // Adjust width
                    height: "45px", // Adjust height
                    border: "1px solid #474747", // Modify border properties
                    borderRadius: "8px", // Adjust border radius
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: index === 0 ? "10px" : 0,
                    gap: "10px", // Adjust margins
                    ":hover": {
                      cursor: "pointer",
                      border: "1px solid #fff",
                    },
                  }}
                  onClick={() => handleWalletConnect(wallet.checkingValue)}
                >
                  <img
                    src={wallet.image}
                    alt="coin"
                    style={{ width: "30px", height: "30px" }}
                  />{" "}
                  {/* Adjust image size */}
                  <Typography
                    align="center"
                    sx={{
                      color: "#fff",
                      fontFamily: "outfit",
                      fontSize: "12px",
                    }}
                  >
                    {wallet.name}
                  </Typography>
                </Box>
              ))}
            </Stack>
            <Box marginLeft={1}>
              <Typography
                style={{
                  color: "white",
                  margin: "0",
                  fontSize: "15px",
                  fontFamily: "outfit",
                }}
              >
                By selecting wallet you agree to Metavoeux
              </Typography>
              <Link
                href="#"
                style={{
                  color: "orange",
                  textDecoration: "none",
                  margin: "0",
                  fontSize: "15px",
                  fontFamily: "outfit",
                  cursor: "auto",
                }}
              >
                Privacy Policy
              </Link>{" "}
              <span style={{ color: "white" }}>&</span>{" "}
              <Link
                href="#"
                style={{
                  color: "orange",
                  textDecoration: "none",
                  margin: "0",
                  fontSize: "15px",
                  fontFamily: "outfit",
                  cursor: "auto",
                }}
              >
                Terms and Condition
              </Link>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          {/* First Row */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              gap: "10px", // Adjust the gap between the boxes
            }}
          >
            {walletsButton.slice(0, 2).map((wallet, index) => (
              <Box
                key={wallet.id}
                sx={{
                  my: 2,

                  width: "170px", // Adjust width
                  height: "45px", // Adjust height
                  border: "1px solid #474747", // Modify border properties
                  borderRadius: "8px", // Adjust border radius
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: index === 0 ? "10px" : 0,
                  gap: "10px",
                  ":hover": {
                    cursor: "pointer",
                    border: "1px solid #fff",
                  },
                }}
                onClick={() => handleWalletConnect(wallet.checkingValue)}
              >
                <img
                  src={wallet.image}
                  alt="coin"
                  style={{ width: "30px", height: "30px" }}
                />{" "}
                {/* Adjust image size */}
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ color: "#fff", fontFamily: "outfit" }}
                >
                  {wallet.name}
                </Typography>
              </Box>
            ))}
          </Stack>

          {/* Second Row */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              gap: "10px", // Adjust the gap between the boxes
            }}
          >
            {walletsButton.slice(2).map((wallet, index) => (
              <Box
                key={wallet.id}
                sx={{
                  my: 2,
                  width: "170px", // Adjust width
                  height: "45px", // Adjust height
                  border: "1px solid #474747", // Modify border properties
                  borderRadius: "8px", // Adjust border radius
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: index === 0 ? "10px" : 0,
                  gap: "10px", // Adjust margins
                  ":hover": {
                    cursor: "pointer",
                    border: "1px solid #fff",
                  },
                }}
                onClick={() => handleWalletConnect(wallet.checkingValue)}
              >
                <img
                  src={wallet.image}
                  alt="coin"
                  style={{ width: "30px", height: "30px" }}
                />{" "}
                {/* Adjust image size */}
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ color: "#fff", fontFamily: "outfit" }}
                >
                  {wallet.name}
                </Typography>
              </Box>
            ))}
          </Stack>
          <Box
            sx={{ margin: "1.5rem", marginLeft: "0px", marginTop: "0.5rem" }}
          >
            <Typography
              style={{
                color: "white",
                margin: "0",
                fontSize: "15px",
                fontFamily: "outfit",
              }}
            >
              By selecting wallet you agree to Metavoeux
            </Typography>
            <Link
              href="#"
              style={{
                color: "orange",
                textDecoration: "none",
                margin: "0",
                fontSize: "15px",
                fontFamily: "outfit",
                cursor: "auto",
              }}
            >
              Privacy Policy
            </Link>{" "}
            <span style={{ color: "white" }}>&</span>{" "}
            <Link
              href="#"
              style={{
                color: "orange",
                textDecoration: "none",
                margin: "0",
                fontSize: "15px",
                fontFamily: "outfit",
                cursor: "auto",
              }}
            >
              Terms and Condition
            </Link>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Muiwallet;
