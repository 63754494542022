import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import React from "react";
// import "./NftManageBids.css";

function createData(
  offerPrice: string,
  totalVolume: string,
  totalOffers: number,
  bidders: string,
  yourOffer: number
) {
  return { offerPrice, totalVolume, totalOffers, bidders, yourOffer };
}

const rows = [
  createData("Transfer", "0.482 POLYGON", 34, "wooott", 0),
  createData("Sale", "0.482 POLYGON", 34, "wooott", 0),
  createData("Transfer", "0.482 POLYGON", 34, "wooott", 0),
  createData("Transfer", "0.482 POLYGON", 34, "wooott", 0),
  createData("Sale", "0.482 POLYGON", 34, "wooott", 0),
  createData("Transfer", "0.482 POLYGON", 34, "wooott", 0),
  createData("Sale", "0.482 POLYGON", 34, "wooott", 0),
  createData("Sale", "0.482 POLYGON", 34, "wooott", 0),
  createData("Sale", "0.482 POLYGON", 34, "wooott", 0),
  createData("Transfer", "0.482 POLYGON", 34, "wooott", 0),
  createData("Sale", "0.482 POLYGON", 34, "wooott", 0),
  createData("Transfer", "0.482 POLYGON", 34, "wooott", 0),
];

const NftManageBids = () => {
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: "18px", md: "32px" },
            fontFamily: "outfit",
            fontWeight: 700,
            marginBottom: "1rem",
          }}
        >
          Manage Bids
        </Typography>

        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "outfit",
            fontWeight: 600,

            mb: "20px",
          }}
        >
          Active Asks and Bids
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "outfit",
            fontWeight: 600,
            mt: "30px",
            mb: "20px",
          }}
        >
          Offers
        </Typography>
        <Box sx={{ width: { xs: "40%" } }}>
          <div className="tableContainer">
            <table cellPadding="10px" className="basicTable">
              <thead>
                <tr>
                  <th style={{ color: "white" }}>Offer Price</th>
                  <th style={{ color: "white" }}>Total Volume</th>
                  <th style={{ color: "white" }}>Total offers</th>
                  <th style={{ color: "white" }}>Bidders</th>
                  <th style={{ color: "white" }}>Your offers</th>
                  <th style={{ color: "white" }}>Bids</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.offerPrice}>
                    <td>{row.offerPrice}</td>
                    <td>{row.totalVolume}</td>
                    <td>{row.totalOffers}</td>
                    <td>{row.bidders}</td>
                    <td>{row.yourOffer}</td>
                    <td>
                      <Button
                        sx={{
                          borderRadius: "7.2px",
                          border: " 0.6px solid var(--ff-990-d, #FF990D)",
                          padding: "9px 19px",
                          color: "#FF990D",
                          textTransform: "none",
                          height: "33px",
                          width: "150px",
                          "&:hover": {
                            background: "#FF990D",
                            color: "white",
                          },
                        }}
                      >
                        Cancel Offer
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default NftManageBids;
