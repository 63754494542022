import React, { useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Box,
  Button,
  Grid,
  alpha,
  Divider,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";

import tokenLogo from "../../assets/svgs/tokenLogo.svg";
// import finanicalIcon from "../../assets/svgs/farmFinancialIcon.svg";
import { inherits } from "util";
import ButtonLiner from "../../components/button/ButtonLiner";

import investInfoIcon from "../../assets/svgs/1investmentinfo.svg";
import farmStatusIcon from "../../assets/svgs/1farmStatus.svg";
import finanicalIcon from "../../assets/svgs/1farmFinancialIcon.svg";
import tokenInfoIcon from "../../assets/svgs/1tokenInfo.svg";
import farmUserIcon from "../../assets/svgs/1farmUser.svg";

// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import Arrow from "../../assets/svgs/Double arrow.svg";
import { useNavigate, useParams } from "react-router";

import InvestFarm from "../investfarm/InvestFarm";
import WithdrawFarm from "../investfarm/WithdrawFarm";
import { margin, useTheme } from "@mui/system";
import { RegisterFarmService } from "src/services/registerFarmService";
import { FarmDetails } from "src/domains/farmDetails.dto";
import { RootState } from "src/redux/store";
import { useSelector } from "react-redux";
import { RewardFarmService } from "src/services/rewardFarmService";
import { TokenService } from "src/services/tokenService";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";

import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { convertDuration } from "src/utils/converter";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FarmDetail = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/voeuxfarming");
  };

  const { id } = useParams();
  console.log("id useParams", id);
  const [registerFarmInstance, setRegisterFarmInstance] =
    useState<RegisterFarmService>();
  const [rewardFarmService, setRewardFarmService] =
    useState<RewardFarmService>();
  const [tokenService, setTokenService] = useState<TokenService>();
  const [farmDetail, setFarmDetail] = useState<FarmDetails>();
  const [farmDetailData, setFarmDetailData] = useState<{
    investmentInformation: any[];
    FarmStatus: any[];
    tokenInformation: any[];
    financial: any[];
  }>();
  const [isManager, setIsManager] = useState(false);
  // const useNavigate = useNavigate();
  //  let account = useSelector((state:RootState)=>state.wallet.account);
  let account = localStorage.getItem("account_address");

  const [isUserInvested, setIsUserInvested] = useState(false);
  const [userInvestedAmount, setUserInvestedAmount] = useState(0);
  const [userRewardAmount, setUserRewardAmount] = useState(0);
  const [userPerTokenRewardAmount, setUserPerTokenRewardAmount] = useState(0);

  useEffect(() => {
    handleShowLoader(true);
    const regInst = new RegisterFarmService();
    const intervalId = setInterval(() => {
      if (
        regInst.registerFarmInstance.contractInstance != null &&
        regInst.registerFarmInstance.contractInstance != undefined
      ) {
        setRegisterFarmInstance(regInst);
        clearInterval(intervalId);
      }
    }, 100);

    const tokenInst = new TokenService();
    const intvId = setInterval(() => {
      if (
        tokenInst.tokenInstance.contractInstance != null &&
        tokenInst.tokenInstance.contractInstance != undefined
      ) {
        setTokenService(tokenInst);
        clearInterval(intvId);
      }
    }, 100);
  }, []);

  const [farmAddress, setFarmAddress] = useState<string>("");
  useEffect(() => {
    const fetchData = async () => {
      const basicDetail = await registerFarmInstance?.getBasicDetails(
        Number(id)
      );
      const advanceDetail = await registerFarmInstance?.getAdvanceDetails(
        Number(id)
      );
      if (basicDetail && advanceDetail) {
        console.log(
          "account and manager ==>",
          account,
          "manager ==>",
          advanceDetail.manager
        );
        if (!account) {
          account = "";
        }
        console.log("advance detail ==>", advanceDetail);
        if (
          account.toLocaleLowerCase() ===
          advanceDetail.manager?.toLocaleLowerCase()
        ) {
          setIsManager(true);
        }
        setFarmDetail({
          name: basicDetail.name,
          description: basicDetail.description,
          investmentRequired: basicDetail.investmentRequired,
          investmentTokenInvestment: basicDetail.investmentTokenInvestment,
          status: advanceDetail.status,
          maxPhase: advanceDetail.maxPhase,
          manager: advanceDetail.manager,
          totalInvestment: advanceDetail.totalInvestment,
          apy: advanceDetail.apy,
          farmAddress: advanceDetail.farmAddress,
          supportedToken: "VOEUX",
          rewardToken: "VOEUX",
        });
      }
      if (advanceDetail?.farmAddress && !rewardFarmService) {
        console.log(
          "inside if condition of setting advance details",
          advanceDetail.farmAddress
        );
        setFarmAddress(advanceDetail?.farmAddress);
        const rewardFarmInst = new RewardFarmService(advanceDetail.farmAddress);
        const intervalId = setInterval(() => {
          if (
            rewardFarmInst.rewardFarmInstance.contractInstance != null &&
            rewardFarmInst.rewardFarmInstance.contractInstance != undefined
          ) {
            setRewardFarmService(rewardFarmInst);
            clearInterval(intervalId);
          }
        }, 100);
      }
      handleShowLoader(false);
      console.log(" both details ", basicDetail, advanceDetail);
    };

    fetchData();
  }, [registerFarmInstance]);

  const walletAddress = localStorage.getItem("account_address");

  const fetchUserDetail = async () => {
    const tempAccount: any = walletAddress;
    const totalInvestments = (await rewardFarmService?.getTotalSupply()) || 0;
    if (totalInvestments && farmDetail) {
      let totalSupply = Number(totalInvestments) / 10 ** 18;
      const newFarmValue = { ...farmDetail, totalInvestment: totalSupply };
      console.log(newFarmValue);
      setFarmDetail(newFarmValue);
    }

    const userBalance = (await rewardFarmService?.getEarings(tempAccount)) || 0;
    console.log("in fetch user detail function", userBalance);
    const investedAmount =
      (await rewardFarmService?.getBalance(tempAccount)) || 0;

    const rewards =
      (await rewardFarmService?.getRewardAmount(tempAccount)) || 0;
    const userRewardPerToken =
      (await rewardFarmService?.getUserRewardPerTokenPaid(tempAccount)) || 0;
    console.log(
      "fetched user values ==>",
      tempAccount,
      userBalance,
      investedAmount,
      rewards,
      userRewardPerToken
    );
    const newInvestedAmount = (Number(investedAmount) / 10 ** 18).toFixed(8);

    const newRewards =
      Number((Number(rewards) / 10 ** 18).toFixed(8)) +
      Number(newInvestedAmount);
    const newUserRewardPerToken = (
      Number(userRewardPerToken) /
      10 ** 18
    ).toFixed(8);

    setIsUserInvested(userBalance > 0);
    setUserInvestedAmount(Number(newInvestedAmount));
    setUserRewardAmount(Number(newRewards));
    setUserPerTokenRewardAmount(Number(newUserRewardPerToken));
  };

  useEffect(() => {
    // setTimeout(() =>{
    fetchUserDetail();
    // },5000)
  }, [rewardFarmService]);

  useEffect(() => {
    console.log("farm detail ==> ", farmDetail);
  }, [farmDetail]);

  useEffect(() => {
    if (farmDetail) {
      console.log("Farm detail ==>", farmDetail);
      const FarmStatus = [
        { value: "Completed", name: "Status", tag: true },
        { value: Number(farmDetail?.maxPhase), name: "Max Phase" },
      ];
      const tokenInformation = [
        {
          value: "Voeux",
          name: "Investment Token",
          logo: tokenLogo,
        },
        {
          value: "Voeux",
          name: "Reward Token",
          logo: tokenLogo,
        },
      ];
      const financial = [
        { value: `${Number(farmDetail?.apy)}%`, name: "APY Field" },
      ];
      const fetDuration = async () => {
        // const duration = await rewardFarmService?.getFarmDuration() || 0;
        if (rewardFarmService?.rewardFarmInstance?.contractInstance !== null && rewardFarmService?.rewardFarmInstance?.contractInstance !== undefined) {
          const duration = await rewardFarmService?.getRemainingDuration();
          console.log("fetched duration: ", duration);
          const durationInDays = convertDuration(Number(duration));
          const investmentInformation = [
            {
              value: Number(farmDetail?.investmentRequired),
              name: "Investment Required",
            },
            {
              value: Number(farmDetail?.totalInvestment),
              name: "Total Investment Allocated",
            },
            { value: durationInDays, name: "Duration" },
          ];
          setFarmDetailData({
            investmentInformation,
            FarmStatus,
            tokenInformation,
            financial,
          });
        }else{
          console.log("in else of duration")
        }
      };

      setTimeout(() => {
        fetDuration();
      }, 200)
      

      console.log(
        "farmDetailData  inside useEffect==>",
        farmDetailData?.investmentInformation
      );
    }
  }, [farmDetail, rewardFarmService]);

  const farmName = farmDetail?.name;
  const description = farmDetail?.description;
  const investmentInformation = farmDetailData?.investmentInformation;
  const farmStatus = farmDetailData?.FarmStatus;
  const tokenInformation = farmDetailData?.tokenInformation;

  const userFarmProfile = {
    rewardPerToken: `$${userPerTokenRewardAmount}`,
    investment: `$${userInvestedAmount}`,
    reward: `$${userRewardAmount}`,
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [withdraw, setWithdraw] = React.useState(false);

  const handleClickOpenwithdraw = () => {
    setWithdraw(true);
  };

  const handleClosewithdraw = () => {
    setWithdraw(false);
  };

  const [showLoader, setShowLoader] = useState(true);
  const handleShowLoader = (loaderVisible: any) => {
    setShowLoader(loaderVisible);
  };

  const handleInvestNow = async (amount: number) => {
    handleShowLoader(true);
    try {
      console.log("in handle invest now function", amount);
      const approvalInfo = await getAprroval(amount);

      console.log("approvalInfo", approvalInfo);
      setTimeout(async () => {
        const investResp = await rewardFarmService?.investNow(amount);
        console.log("investmentResp:", investResp);
        handleClose();

        setTimeout(() => {
          fetchUserDetail();
          handleShowLoader(false);
          showToastMessageClick("Investment in the Farm is Successfully");
        }, 5000);
      }, 5000);
    } catch (err) {
      handleClose();
      handleShowLoader(false);
      showToastErrorMessageClick("Failed in Creating the Farm");
      throw err;
    }
  };

  const getAprroval = async (amount: number) => {
    if (farmDetail) {
      console.log("farm address for approval ==>", farmDetail.farmAddress);
      const approvalResponse = await tokenService?.approveTransaction(
        farmDetail?.farmAddress,
        amount
      );
      // await approvalResponse?.wait()
      return approvalResponse;
    } else {
      return false;
    }
  };

  const handleWithdraw = async (amount: number) => {
    handleShowLoader(true);
    try {
      console.log("in handle withdraw", amount);
      const withdraw = await rewardFarmService?.withdraw(amount);
      console.log("withdraw in farm detail", withdraw);
      // handleShowLoader(false);
      // showToastMessageClick("Withdraw in farm is successfull")
      handleClosewithdraw();
      setTimeout(() => {
        fetchUserDetail();
        handleShowLoader(false);
        showToastMessageClick("Withdraw in farm is successfull");
      }, 5000);
    } catch (err) {
      console.error(err);
      handleShowLoader(false);
      showToastMessageClick("Error Failed in Withdrawing in farm");
      handleClosewithdraw();
    }
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, settoastMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (data: string) => {
    setShowToastMessage(true);
    settoastMessage(data);
  };
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const theme = useTheme();

  return (
    <>
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100vh",
            zIndex: 1400,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <Box
        p={4}
        sx={{
          padding: "0 2.5rem 2.5rem 2.5rem",
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          "@media (max-width: 600px)": {
            padding: "0 8px ",
          },
          overflow: "hidden",
          position: "relative",
        }}
      >
        <NewNftHeader farmHeader={true} />

        <Box
          sx={{
            ...circleStyle,
            top: { md: "10%" },
            transform: "translate(-50%, -50%)",
            width: "447.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter: "blur(120px)",
            WebkitBackdropFilter: "blur(120px)",
            // leftcorner
          }}
        ></Box>
        <Box
          sx={{
            ...circleStyle,
            top: { md: "20%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "80%", md: "90%" },
            transform: "translate( -50%)",
            width: "300.625px",
            height: "187.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(140px)",
            right: "0%",
            backdropFilter: "blur(140px)",
            WebkitBackdropFilter: "blur(140px)",
            // righttop
          }}
        ></Box>

        <Box
          sx={{
            ...circleStyle,
            top: { md: "70%" },
            display: { xs: "none", md: "flex" },
            left: { xs: "80%", md: "90%" },
            right: { xs: "0%", md: "0%" },
            transform: "translate( -50%)",
            width: "300.625px",
            height: "147.734px",
            borderRadius: "547.625px",
            background: "#9D00FF",
            filter: "blur(120px)",
            backdropFilter: "blur(120px)",
            WebkitBackdropFilter: "blur(120px)",
            // rightsecond
          }}
        ></Box>

        <Stack
          direction={"column"}
          sx={{
            "@media (max-width: 600px)": {
              padding: "0 10px ",
            },
          }}
        >
          <Box sx={{ display: "flex" }}>
            {" "}
            <img src={Arrow} alt="" onClick={() => handleBack()} />
            <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
          </Box>
          <Box
            height="30%"
            width={"100%"}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"10px"}
          >
            <Box
              display="flex"
              alignItems="center"
              flexDirection={"column"}
              sx={{
                width: "100%",
                background:
                  "linear-gradient(267deg, #ff990d -3.12%, #9d00ff 93.15%)",
                borderRadius: 4,
                position: "relative",
                overflow: "hidden",
              }}
              py="2%"
            >
              <Box
                sx={{
                  ...circleStyle,
                  top: { xs: "0", md: "-26px" },
                  display: { xs: "flex", md: "flex" },
                  left: { xs: "0%", md: "0%" },
                  transform: "translate( -23%)",
                  width: { xs: "30px", md: "85px" },
                  height: { xs: "30px", md: "85px" },

                  borderRadius: "100%",
                  background: "rgba(255, 255, 255, 0.2)",
                  // filter: "blur(140px)",
                  zIndex: "0 !important",
                }}
              ></Box>
              <Box
                sx={{
                  ...circleStyle,
                  top: { xs: "-17px", md: "-50px" },
                  display: { xs: "flex", md: "flex" },
                  right: { xs: "-6%", md: "-6%" },
                  transform: { xs: "translate( 0%)", md: "translate( -23%)" },
                  width: { xs: "60px", md: "160px" },
                  height: { xs: "60px", md: "160px" },
                  borderRadius: "100%",
                  background: "rgba(255, 255, 255, 0.2)",
                  // filter: "blur(140px)",
                  zIndex: "0 !important",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: {
                    md: 32,
                    xs: 24,
                  },
                  fontWeight: 600,
                  fontFamily: "outfit",
                }}
              >
                {farmName}
              </Typography>
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "outfit",
                  fontSize: {
                    xs: 16,
                    md: 22,
                  },
                  fontWeight: 300,
                  width: {
                    xs: "80%",
                    md: "50%",
                  },
                  mb: "1%",
                }}
              >
                {description}
              </Typography>
              {isManager && (
                <Button
                  onClick={() => {
                    navigate(`/updatefarm/${id}`);
                  }}
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    background: "white",
                    color: "#C84493",
                    fontFamily: "outfit",
                    "&:hover": {
                      background: "white",
                      color: "black",
                      border: "none",
                    },
                  }}
                >
                  Update Farm
                </Button>
              )}
            </Box>
          </Box>
          <Grid container marginTop={4} spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                padding={2}
                borderRadius={5}
                bgcolor={"#28272C"}
                height={"100%"}
              >
                <Stack
                  direction="column"
                  sx={{
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    sx={{
                      justifyContent: { md: "flex-start", xs: "center" },
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      bgcolor={"#9D00FF"}
                      borderRadius={1}
                      padding={0.1}

                      // border="1px solid white"
                    >
                      <img
                        src={investInfoIcon}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                    <Typography sx={{ fontFamily: "outfit", fontSize: "20px" }}>
                      Investment Information
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ md: "row", xs: "column" }}
                    spacing={1}
                    marginTop={2}
                    alignItems={{ xs: "center", md: "inherit" }}
                  >
                    {farmDetailData?.investmentInformation?.map(
                      (investmentItem) => {
                        return (
                          <Stack
                            direction={"column"}
                            bgcolor={"#3D2456"}
                            padding="16px 24px"
                            borderRadius="22px"
                            width={{
                              xs: "80%",
                              md: "100%",
                            }}
                          >
                            <Typography
                              sx={{ fontFamily: "outfit", fontSize: "14px" }}
                            >
                              {investmentItem.name}
                            </Typography>
                            <Typography
                              sx={{ fontFamily: "outfit", fontSize: "24px" }}
                            >
                              {investmentItem.value}
                            </Typography>
                          </Stack>
                        );
                      }
                    )}
                  </Stack>

                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    display={"flex"}
                    justifyContent={"center"}
                    spacing={2}
                    marginTop={2}
                  >
                    {/* const userFarmProfile = { 
    rewardPerToken: `$${userPerTokenRewardAmount}`,
    investment: `$${userInvestedAmount}`,
    reward: `$${userRewardAmount}`,
  }; */}

                    {userInvestedAmount <= 0 && (
                      <Button
                        variant="contained"
                        sx={{
                          background: "transparent",
                          fontFamily: "outfit",

                          textTransform: "capitalize",
                          borderRadius: 2,

                          border: "0.977px solid var(--Linear, #FF990D)",

                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        onClick={handleClickOpen}
                      >
                        Invest Now
                      </Button>
                    )}

                    {open && (
                      <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        // keepMounted
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            backgroundColor: "transparent", // Set the dialog background to transparent
                            boxShadow: "none", // Remove the default Material-UI box shadow
                          },
                        }}
                      >
                        <InvestFarm
                          handleClose={handleClose}
                          investNow={handleInvestNow}
                        />
                      </Dialog>
                    )}
                    {userInvestedAmount != 0 && (
                      <Button
                        variant="contained"
                        sx={{
                          background: "White",
                          fontFamily: "outfit",

                          color: "black",
                          textTransform: "capitalize",
                          borderRadius: 2,
                          "&:hover": {
                            background: "white",
                          },
                        }}
                        onClick={handleClickOpenwithdraw}
                      >
                        Withdraw
                      </Button>
                    )}

                    <Dialog
                      open={withdraw}
                      TransitionComponent={Transition}
                      keepMounted
                      onClose={handleClosewithdraw}
                      PaperProps={{
                        style: {
                          backgroundColor: "transparent", // Set the dialog background to transparent
                          boxShadow: "none", // Remove the default Material-UI box shadow
                        },
                      }}
                    >
                      <WithdrawFarm
                        handleClosewithdraw={handleClosewithdraw}
                        withdraw={handleWithdraw}
                      />
                    </Dialog>
                  </Stack>
                </Stack>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                padding={2}
                borderRadius={5}
                bgcolor={"#28272C"}
                height={"100%"}
              >
                <Stack
                  direction={"column"}
                  borderRadius={5}
                  alignItems={{ md: "inherit" }}
                  sx={{
                    textAlign: { md: "left", xs: "center" },
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    sx={{
                      justifyContent: {
                        md: "flex-start",
                        xs: "center",
                      },
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      bgcolor={"#9D00FF"}
                      borderRadius={1}
                      padding={0.1}

                      // border="1px solid white"
                    >
                      <img
                        src={farmStatusIcon}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                    <Typography sx={{ fontFamily: "outfit", fontSize: "20px" }}>
                      Farm Status
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{
                      md: "row",
                      xs: "column",
                    }}
                    spacing={{ xs: 1, md: 4 }}
                    marginTop={2}
                    alignItems={{ xs: "center", md: "inherit" }}
                  >
                    {farmDetailData?.FarmStatus?.map((farmStatusItem) => (
                      <Stack
                        direction={"column"}
                        bgcolor={"#3D2456"}
                        padding="16px 24px"
                        borderRadius="22px"
                        width={{
                          md: "40%",
                          xs: "80%",
                        }}
                        sx={{
                          justifyContent: {
                            md: "flex-start",
                            xs: "center",
                          },
                          alignItems: {
                            md: "flex-start",
                            xs: "center",
                          },
                        }}
                      >
                        <Typography
                          sx={{ fontFamily: "outfit", fontSize: "14px" }}
                        >
                          {farmStatusItem.name}
                        </Typography>
                        {farmStatusItem.name !== "Status" && (
                          <Typography
                            sx={{ fontFamily: "outfit", fontSize: "24px" }}
                          >
                            {farmStatusItem.value}
                          </Typography>
                        )}
                        {farmStatusItem.name === "Status" && (
                          <Typography
                            fontSize="16px"
                            sx={{
                              backgroundColor: alpha("#04DB6021", 0.12),
                              color: "#29D070",
                              padding: 1,
                              paddingX: 2,
                              borderRadius: 4,
                              width: "80%",
                              marginTop: 0.5,
                              fontFamily: "outfit",
                              alignItems: {
                                md: "flex-start",
                                xs: "center",
                              },
                            }}
                          >
                            {farmStatusItem.value}
                          </Typography>
                        )}
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Grid container marginTop={4} marginBottom={4} spacing={2}>
            <Grid item xs={12} md={3}>
              <Box
                padding={2}
                borderRadius={5}
                bgcolor={"#28272C"}
                height={"100%"}
              >
                <Stack direction={"column"} alignItems={{ md: "inherit" }}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    sx={{
                      justifyContent: {
                        md: "flex-start",
                        xs: "center",
                      },
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      bgcolor={"#9D00FF"}
                      borderRadius={1}
                      padding={0.1}
                      // border="1px solid white"
                    >
                      <img
                        src={finanicalIcon}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                    <Typography fontSize={20} sx={{ fontFamily: "outfit" }}>
                      Financials & Location
                    </Typography>
                  </Stack>
                  <Typography
                    fontSize={52}
                    sx={{ fontFamily: "outfit" }}
                    textAlign={"center"}
                  >
                    {Number(farmDetail?.apy)}
                  </Typography>
                  <Typography
                    fontSize={18}
                    sx={{ fontFamily: "outfit" }}
                    textAlign={"center"}
                  >
                    APY
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                padding={2}
                borderRadius={5}
                bgcolor={"#28272C"}
                height={"100%"}
              >
                <Stack direction={"column"} alignItems={{ md: "inherit" }}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    sx={{
                      justifyContent: {
                        md: "flex-start",
                        xs: "center",
                      },
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      bgcolor={"#9D00FF"}
                      borderRadius={1}
                      padding={0.1}
                      // border="1px solid white"
                    >
                      <img
                        src={tokenInfoIcon}
                        alt=""
                        width={"100%"}
                        height={"100%"}
                      />
                    </Box>
                    <Typography fontSize={20} sx={{ fontFamily: "outfit" }}>
                      Token Information
                    </Typography>
                  </Stack>
                  {isMobile ? (
                    <Stack
                      spacing={5}
                      // marginTop={4}
                      alignItems={"center"}
                      justifyContent={"space-around"}
                      sx={{
                        flexDirection: {
                          md: "row",
                          xs: "column",
                        },
                      }}
                    >
                      {/* {tokenInformation?.map((token) => ( */}
                      <Stack
                        direction="column"
                        alignItems={"center"}
                        justifyContent={"center"}
                        // spacing={1}
                      >
                        <Box
                          height={"50px"}
                          width={"50px"}
                          borderRadius={"25px"}
                          border="1px solid #FF990D"
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <img
                            src={tokenLogo}
                            alt=""
                            height={"80%"}
                            width={"80%"}
                          />
                        </Box>
                        <Typography
                          color={"#FF990D"}
                          fontSize={28}
                          sx={{ fontFamily: "outfit" }}
                        >
                          Voeux
                        </Typography>
                        <Typography
                          fontSize={14}
                          sx={{ fontFamily: "outfit", textAlign: "center" }}
                        >
                          Investment / Reward Required
                        </Typography>
                      </Stack>
                      {/* ))} */}
                    </Stack>
                  ) : (
                    <Stack
                      divider={
                        <Divider
                          orientation={isMobile ? "horizontal" : "vertical"}
                          // orientation={{ xs: 'horizontal', sm: 'vertical' }}
                          // orientation={{md:"vertical",xs:"horizontal"}}
                          sx={{
                            borderColor: "gray",
                            // orientation:{md:"vertical",xs:"horizontal"}
                          }}
                          flexItem
                        />
                      }
                      //  spacing={5}
                      marginTop={4}
                      alignItems={"center"}
                      justifyContent={"space-around"}
                      sx={{
                        flexDirection: {
                          md: "row",
                          xs: "column",
                        },
                      }}
                    >
                      {tokenInformation?.map((token) => (
                        <Stack
                          direction="column"
                          alignItems={"center"}
                          justifyContent={"center"}
                          // spacing={1}
                        >
                          <Box
                            height={"50px"}
                            width={"50px"}
                            borderRadius={"25px"}
                            border="1px solid #FF990D"
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <img
                              src={token.logo}
                              alt=""
                              height={"80%"}
                              width={"80%"}
                            />
                          </Box>
                          <Typography
                            color={"#FF990D"}
                            fontSize={28}
                            sx={{ fontFamily: "outfit" }}
                          >
                            {token.value}
                          </Typography>
                          <Typography
                            fontSize={14}
                            sx={{ fontFamily: "outfit", textAlign: "center" }}
                          >
                            {token.name}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  )}
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                padding={2}
                borderRadius={5}
                bgcolor={"#28272C"}
                height={"100%"}
                //     display={"flex"
                // }
                // flexDirection={"column"}
                // alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                  alignContent={"center"}
                  sx={{
                    justifyContent: {
                      md: "flex-start",
                      xs: "center",
                    },
                  }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bgcolor={"#9D00FF"}
                    borderRadius={1}
                    padding={0.1}
                    // border="1px solid white"
                  >
                    <img
                      src={farmUserIcon}
                      alt=""
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                  <Typography fontSize={20} sx={{ fontFamily: "outfit" }}>
                    User Farm Profile
                  </Typography>
                </Stack>

                <Stack direction={"column"} marginTop={4} spacing={2}>
                  <Grid
                    container
                    sx={{
                      justifyContent: {
                        md: "flex-start",
                        xs: "space-evenly",
                      },
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography sx={{ fontFamily: "outfit" }}>
                        {" "}
                        RewardPerToken
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ fontFamily: "outfit" }}>:</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontFamily: "outfit" }}>
                        {userFarmProfile.rewardPerToken}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Stack direction={"row"}>
                    <Grid
                      container
                      sx={{
                        justifyContent: {
                          md: "flex-start",
                          xs: "space-evenly",
                        },
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography sx={{ fontFamily: "outfit" }}>
                          Investment
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography sx={{ fontFamily: "outfit" }}>:</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ fontFamily: "outfit" }}>
                          {userFarmProfile.investment}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack direction={"row"}>
                    <Grid
                      container
                      sx={{
                        justifyContent: {
                          md: "flex-start",
                          xs: "space-evenly",
                        },
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography sx={{ fontFamily: "outfit" }}>
                          Reward
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography sx={{ fontFamily: "outfit" }}>:</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ fontFamily: "outfit" }}>
                          {userFarmProfile.reward}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <LandingPageFooter />
    </>
  );
};

export default FarmDetail;
