import { useLocation, useNavigate } from "react-router-dom";
import NewNftHeader from "../../../components/newNftHeader/NewNftHeader";
import styles from "../NftLanding.module.css";
import Box from "@mui/system/Box";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import NftSalesForm from "../../../components/createNft/NftSalesForm";
import { CreacteNftServices } from "../../../services/createNftService";
import LandingPageFooter from "../../../components/landingPageFooter/LandingPageFooter";
import CreateNftCollectionPage from "../../../components/createNft/CreateNftCollectionPage";
import CreateSingleNftPage from "../../../components/createNft/CreateSingleNftPage";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import axios from "axios";
import ToastMessage from "../../../components/ToastMessage/ToastMessage";
import ToastMessageFailure from "../../../components/ToastMessage/ToastMessageFailure";
import { Environment } from "../../../environments";

const NftCreate = () => {
  const [nftServiceInstance, setNftServiceInstance] =
    useState<CreacteNftServices>();
  const location = useLocation();
  const navigate = useNavigate();
  let data = location.state;
  const [nftType, setNftType] = useState(data);

  //selected image and file from drga and drop for collection
  const [collectionDrag, setCollectionDrag] = useState({
    imageURL: "",
    selectedFile: "",
  });

  //state to show loader
  const [showLoader, setShowLoader] = useState(false);
  //selected image and file from drga and drop for nft
  const [nftDrag, setNftDrag] = useState({
    imageURL: "",
    selectedFile: "",
  });
  const [showText, setshowText] = useState<any>("");
  const handleShowLoader = (active: boolean, text?: string) => {
    setShowLoader(active);
    if (active) {
      document.body.style.overflow = "hidden";
      window.scrollTo({ top: 0, behavior: "smooth" });
      // document.body.style.scrollTop = "hidden";
      setshowText(text);
      console.log(Text);
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    const nftservice = new CreacteNftServices();
    setNftServiceInstance(nftservice);
  }, []);

  const [createCollection, setCreateCollection] = useState(false);
  useEffect(() => {
    if (nftServiceInstance) {
    } else {
      console.log("nftServiceInstance not present:");
    }
  }, [nftServiceInstance]);

  const getImageurl = (data: any, file: any) => {
    setCollectionDrag((prevState) => ({
      ...prevState,
      imageURL: data,
      selectedFile: file,
    }));
    formdata.ipfs = data;
    formdata.imageUrl = data;
  };

  const getNftImageurl = (data: any, file: any) => {
    setNftDrag((prevState) => ({
      ...prevState,
      imageURL: data,
      selectedFile: file,
    }));
    formdata.ipfs = data;
    formdata.imageUrl = file;
  };

  const handleCreateCollection = () => {
    setCreateCollection(true);
  };

  const handleContniueCollection = (collectionResponse: Object) => {
    setCreateCollection(false);
    setNftType("singleNft");
    showToastMessageClick("Collection Created Successfully");
  };

  const [salesView, setSalesView] = useState<Boolean>(false);
  const handleLeftArrow = () => {
    if (!salesView) {
      if (!createCollection) {
        navigate("/nftmarketplace");
      } else {
        setCreateCollection(false);
      }
    } else {
      setSalesView(false);
    }
  };

  const [nftid, setNftId] = useState("");
  const [nftCreateResponse, setNftCreateResponse] = useState<any>({});

  const handleCreateClick = async (showSales: Boolean, data: any) => {
    const userID = localStorage.getItem("userId");
    data.user = userID;
    data.ipfs = nftDrag.imageURL;
    data.imageUrl = nftDrag.imageURL;
    setFormData({ ...data });
    if (!data.imageUrl) {
    } else {
      console.log("data while create nft:", data);
      const tokenId = data.tokenId + "";
      try {
        // const ownUser = localStorage.getItem("ownUser")
        const response = await axios.post(
          `${Environment.metavoeuxBackend}nft`,
          {
            tokenId: data.tokenId,
            collectionId: data.collectionId,
            description: data.description,
            imageUrl: data.imageUrl,
            ipfs: data.ipfs,
            nftName: data.nftName,
            supply: data.supply,
            traits: data.traits,
            user: data.user,
            // ownNft:ownUser ? true: false,
          }
        );
        console.log("create NFT api response ", response);
        setNftId(response.data._id);
        setNftCreateResponse(response.data);
        // setSalesView(showSales);
        handleShowLoader(false);
        showToastMessageClick("NFT Created Successfully");
        navigate(`/nftSales/${response.data._id}`, { state: "Create" });
        // setToastMessage("NFT created successfully")
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const [formdata, setFormData] = useState<any>({});
  const handleFormData = (data: any) => {
    setFormData({ ...data });
  };

  const [salesData, setSalesData] = useState<any>({});
  const handleSalesFormData = (data: any) => {
    console.log("nftCreateResponse", nftCreateResponse);
    salesData.user = nftCreateResponse.user;
    salesData.nft = nftCreateResponse._id;
    salesData.isSold = true;
    salesData.listingPrice = data.listingPrice;
    salesData.featureNft = true;
    setSalesData({ ...salesData });
    callNftapi();
    // setFormData({formdata})
    //right api call after this
  };

  const callNftapi = async () => {
    try {
      const response = await axios.post(
        `${Environment.metavoeuxBackend}listingnft`,
        {
          ...salesData,
        }
      );
      handleShowLoader(false);
      showToastMessageClick("NFT Created Successfully");
      // setToastMessage("NFT created successfully")
      navigate("/nftoverview");
      console.log("added the sales for specific NFT", response);
    } catch (error: any) {
      handleShowLoader(false);
      // settoasterrorMessage(error.message)
      showToastErrorMessageClick(error.message);
      console.error("Error fetching data:", error);
      setTimeout(() => {
        navigate("/nftmarketplace");
      }, 2000);
    }
  };
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };

  return (
    <>
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      <Box>
        {showLoader && (
          <Box
            sx={{
              position: "fixed",
              background: "#000000c9",
              width: "100%",
              height: "100vh",
              zIndex: 1,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingComponent text={showText} />
          </Box>
        )}

        <Box
          className={styles.mainContainer}
          p={4}
          sx={{
            px: { xs: 1, sm: 3, md: 5 },
            paddingTop: 0,

            "@media (min-width: 1721px)": {
              padding: "0% 10% 0% 10%",
            },
          }}
        >
          <NewNftHeader nftHeader={true} />
          <Box
            sx={{
              px: { xs: 0, sm: 0 },
              marginTop: { sm: 1, xl: "0rem" },
              "@media (max-width: 600px)": {
                padding: 2,
              },
            }}
          >
            {!salesView ? (
              !createCollection && nftType === "singleNft" ? (
                <CreateSingleNftPage
                  handleCreateCollection={handleCreateCollection}
                  handleCreateClick={handleCreateClick}
                  getNftImageurl={getNftImageurl}
                  handleLeftArrow={handleLeftArrow}
                  handleShowLoader={handleShowLoader}
                  getFormData={handleFormData}
                  showToastErrorMessageClick={showToastErrorMessageClick}
                />
              ) : (
                <CreateNftCollectionPage
                  handleCreateContniue={handleContniueCollection}
                  collectionDrag={collectionDrag}
                  getImageurl={getImageurl}
                  handleLeftArrow={handleLeftArrow}
                  handleShowLoader={handleShowLoader}
                  showToastErrorMessageClick={showToastErrorMessageClick}
                />
              )
            ) : (
              <NftSalesForm
                handleLeftArrow={handleLeftArrow}
                getFormData={handleSalesFormData}
                handleShowLoader={handleShowLoader}
                nftid={nftid}
                nftCreateResponse={nftCreateResponse}
              />
            )}
          </Box>
        </Box>
        <LandingPageFooter />
      </Box>
    </>
  );
};
export default NftCreate;
