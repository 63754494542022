import { Box } from "@mui/system";
import { useEffect, useState } from "react";

import signinimage from "../../assets/VoeuxGame/signinimage.png";
import registrationimage from "../../assets/VoeuxGame/registrationmage.png";

import {
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import VoeuxGameHeader from "../../components/voeuxGame/VoeuxGameHeader";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, useParams } from "react-router-dom";

import VoeuxFooter from "../../components/voeuxGame/VoeuxFooter";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import axios from "axios";
import { Environment } from "../../environments";
import { setEmail } from "../../redux/slices/gameUserMail";
import { useDispatch } from "react-redux";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { useLocation } from "react-router-dom";

function UserRegistration() {
  const location = useLocation();
  console.log("navya",location.state);

  let { type } = useParams();
  console.log("use parms", type);
  const [type1, setType] = useState(type);
  const [showSignIn, setShowSignIn] = useState(false);
  const [storeFrom, setStoreFrom] =  useState("")
  useEffect(() => {
    if(location?.state?.type == "landing"){
      setStoreFrom('landing')
    } 
    if (type == "signin") {
      setShowSignIn(false);
      setIsSignIn(false);
    } else {
      setShowSignIn(true);
      setIsSignIn(true);
    }
   
  }, [type, showSignIn]);
  useEffect(() => {
    getAllUsers()
  },[])
  const [ userName, setUserName] = useState<any>([])
  const getAllUsers = async() => {
    try{
      const response = await axios.get(
        `${Environment.metavoeuxBackend}game/user-list` )
        console.log("getAllUsers", response)
        setUserName(response.data)
    }catch(error){
      showToastErrorMessageClick("Error in reading the existing user names")
    }
  }

  const checkUserName = (nameText:any) =>{
    console.log("name value",nameText)
    if(userName.indexOf(nameText) > 0){
      showToastErrorMessageClick("UserName already exist. Please use other UserName")
    }else{
      setShowToastErrorMessage(false);
    }
  }
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/voeuxGame");
  };

  const handleListItemClick = (type: any) => {
    if(location?.state?.type =="Download"){
      navigate(`/user/${type}`,{state:{type:"Download",url:location?.state?.url}});
    }else{
      navigate(`/user/${type}`);
    }
   
    setShowSignIn(!showSignIn);
  };
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const [isSignIn, setIsSignIn] = useState(true);

  // function to toggle between sign in and registration images
  const toggleImage = () => {
    setIsSignIn(!isSignIn);
  };

  const forgotPassword = () => {
    navigate("/forgotPassword");
  };
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
const [ registrationData, setRegistrationData] = useState<any>({})
  const handleResgitration = async(e:any) => {
    setShowLoader(true)
    e.preventDefault();
    console.log("registrationData",registrationData)
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if(!registrationData.email.match(mailformat)){
      showToastErrorMessageClick("Please Enter Valid Email Address")
      setShowLoader(false)
      return false
    }
    if( registrationData.displayName.length < 5){
      showToastErrorMessageClick("User Name should be atleast 5 characters ")
      setShowLoader(false)
      return false
    }
    if( registrationData.password.length < 8){
      showToastErrorMessageClick("Password should be atleast 8 characters ")
      setShowLoader(false)
      return false
    }
    if(registrationData.password != registrationData.renter){
      showToastErrorMessageClick("Password did not match")
      setShowLoader(false)
      return false
    }
    try{
      const response = await axios.post(
        `${Environment.metavoeuxBackend}game`,
        {
          email: registrationData.email,
          password:  registrationData.password,
          displayName:registrationData.displayName,
        }
        
      );
      console.log("game registration response",response)
      showToastMessageClick("Account Registered Successfully")
        setTimeout(() => {
          if(location?.state?.type == "Download"){
            navigate("/user/signin",{state:{type:"Download",url:location?.state?.url}})
          }else{
            navigate("/user/signin")
          }
          
        },1000)
        setShowLoader(false)
        setRefresh(true)
        localStorage.setItem("gameEmail",registrationData.email)
        window.sessionStorage.setItem("gameEmail1",registrationData.email)
    }catch(error:any){
      showToastErrorMessageClick(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.message ? error?.response?.message  : error?.message  )
      setShowLoader(false)
    }
  }
  const [ refresh, setRefresh] = useState(true)
useEffect(() =>{

},[refresh])
  const [ signInData, setSignInData] = useState<any>({})
  const dispatch = useDispatch();
  const handleSignIn = async(e:any) => {
    setShowLoader(true)
    e.preventDefault()
    console.log("signInData",signInData)
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if(!signInData.email.match(mailformat)){
      showToastErrorMessageClick("Please Enter Valid Email Address")
      setShowLoader(false)
      return false
    }
    if( signInData.password.length < 8){
      showToastErrorMessageClick("Password should be atleast 8 characters ")
      setShowLoader(false)
      return false
    }
    try{
      const response = await axios.post(
        `${Environment.metavoeuxBackend}game/login`,
        {
          email: signInData.email,
          password:  signInData.password
        }
        
      );
      console.log("game registration response",response)
      showToastMessageClick("Account signed Successfully")
      dispatch(setEmail(signInData.email));
      localStorage.setItem("gameEmail",signInData.email)
      window.sessionStorage.setItem("gameEmail1",signInData.email)
      localStorage.setItem("token",response.data.token)
      if(location?.state?.type == "landing"){
        navigate("/voeuxGame")
        return false;
      }
      if(!location.state){
        navigate("/gameprofile")
        return false;
      }
      if(location.state.type == "Download"){
        const link = document.createElement('a'); 
        link.setAttribute('href', location.state.url); 
        document.body.appendChild(link); 
        link.click(); 
        document.body.removeChild(link); 
        navigate("/voeuxGame")
      }
     
      setShowLoader(false)
    }catch(error:any){
      console.log("error",error)
      showToastErrorMessageClick("Invalid Crdentails" )
      setShowLoader(false)
    }

  }

  const [ showLoader, setShowLoader] = useState(false)

  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1400,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      <Box
        sx={{
          background: "black",
          width: "100%",
          minHeight: "100vh",
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          pb: "50px",
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          overflow: "hidden",
          position: "relative",
          zIndex: 0,
        }}
      >
        <VoeuxGameHeader  reload={true}/>
        <Box
          sx={{
            ...circleStyle,
            display: { xs: "flex", sm: "flex", md: "flex" },
            top: 0,
            right: 0,
            left: "0",
            margin: "auto",
            bottom: "0px",
            // transform: "translateY(37%)",
            width: "60%", // Adjusted width
            height: "50%", // Adjusted height
            borderRadius: "100%", // Ensures it remains a perfect circle
            background: ` #464646`,
            filter: "blur(114px)",
            backdropFilter: { md: "none", xs: "blur(114px)" },
            WebkitBackdropFilter: { md: "none", xs: "blur(114px)" },
            overflow: "hidden",
          }}
        ></Box>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              padding: "0% 10px",
            },
          }}
        >
          <Box
            sx={{ display: "flex", marginBottom: "10px", cursor: "pointer",color: "#ECE218"  }}
            onClick={() => handleBack()}
          >
            <ArrowBackIosIcon  />
            <Typography sx={{ fontFamily: "outfit", }}>
              {" "}
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { md: "row", xs: "column" },
              rowGap: "30px",
            }}
          >
            {/* imgsection */}
            <Box sx={{ width: { xs: "100%", md: "70%" }, textAlign: "center" }}>
              {isSignIn ? (
                <img
                  draggable="false"
                  src={registrationimage}
                  alt="img"
                  style={{ height: "100%", width: "inherit" }}
                />
              ) : (
                <img
                  draggable="false"
                  src={signinimage}
                  alt="img"
                  style={{ height: "100%", width: "inherit" }}
                />
              )}
            </Box>
            {/* signin/register*/}
            <Box
              className="polygonStyle2"
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "#131313",

                backdropFilter: "blur(10.781231880187988px)",
                width: { md: "751px", xs: "100%" },
                height: "fit-content",
                py: "50px",
                justifyContent: "start",
                alignItems: "center",
                position: "relative",
                px: { xs: "20px" },
              }}
            >
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "flex", sm: "flex", md: "flex" },
                  top: 0,
                  margin: "auto",
                  left: "0",
                  right: "0%",
                  bottom: "0px",
                  // transform: "translateY(37%)",
                  width: "535px", // Adjusted width
                  height: "172px", // Adjusted height
                  borderRadius: "100%", // Ensures it remains a perfect circle
                  background: ` #ECE218`,
                  filter: "blur(145px)",
                  backdropFilter: { md: "none", xs: "blur(145px)" },
                  WebkitBackdropFilter: { md: "none", xs: "blur(145px)" },
                  overflow: "hidden",
                }}
              ></Box>
              <Box
                className="menu"
                sx={{ width: "100%", maxWidth: "448px", marginTop: "7%" }}
              >
                <List
                  className="menu-list"
                  sx={{
                    cursor: "pointer",
                    padding: "0px",
                    background: "transparent",
                    height: { xs: "48px" },
                  }}
                >
                  <ListItem
                    className={
                      showSignIn !== true ? "menu-item filledMenu" : "menu-item"
                    }
                    onClick={() => handleListItemClick("signin")}
                  >
                    <ListItemText
                      className="blendarPro"
                      primary="Signin"
                      sx={{
                        fontSize: "16px",
                        textTransform: "uppercase",
                        fontStyle: "normal",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                      onClick={toggleImage}
                    />
                  </ListItem>
                  <>
                    <ListItem
                      className={
                        showSignIn !== false
                          ? "menu-item filledMenu"
                          : "menu-item"
                      }
                      onClick={() => handleListItemClick("register")}
                    >
                      <ListItemText
                        className="blendarPro"
                        primary="register"
                        sx={{
                          fontSize: "16px",
                          textTransform: "uppercase",
                          fontStyle: "normal",
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                        onClick={toggleImage}
                      />
                    </ListItem>
                  </>
                </List>
              </Box>
              {showSignIn !== false ? (
                <form onSubmit={(e) => handleResgitration(e)} >
                  <Box
                    className=" inputpolygonStyle"
                    sx={{
                      border: `1px solid #818181`,
                      // padding: "2px",
                      marginTop: "20px",
                      width: { md: "auto", xs: "inherit" },
                      background: `transparent`,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(52deg)",
                        top: "-12px",
                        left: "8px",
                      }}
                    ></Box>
                    <TextField
                      sx={{
                        // height: "40px",
                        width: { md: "448px", xs: "100%" },

                        "& input": {
                          outline: "none",
                          border: "0.65px solid #818181",
                          color: "white",
                          background: "transparent",
                          paddingRight: "30px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      type="text"
                      id="email"
                      placeholder="Enter your username"
                      variant="outlined"
                      name="displayName"
                      required
                      onChange={(e) =>
                       ( setRegistrationData({
                          ...registrationData,
                          [e.target.name]: e.target.value,
                        }), checkUserName(e.target.value))
                      }
                    />
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(60deg)",
                        bottom: "-12px",
                        right: "3px",
                      }}
                    ></Box>
                  </Box>
                  <Box
                    className=" inputpolygonStyle"
                    sx={{
                      border: `1px solid #818181`,
                      // padding: "2px",
                      marginTop: "20px",
                      width: { md: "auto", xs: "inherit" },
                      background: `transparent`,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(52deg)",
                        top: "-12px",
                        left: "8px",
                      }}
                    ></Box>
                    <TextField
                    required
                      sx={{
                        // height: "40px",
                        width: { md: "448px", xs: "100%" },

                        "& input": {
                          outline: "none",
                          border: "0.65px solid #818181",
                          color: "white",
                          background: "transparent",
                          paddingRight: "30px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      type="email"
                      id="email"
                      placeholder="Enter your email address"
                      variant="outlined"
                      name="email"
                      onChange={(e) =>
                        setRegistrationData({
                          ...registrationData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(60deg)",
                        bottom: "-12px",
                        right: "3px",
                      }}
                    ></Box>
                  </Box>
                  <Box
                    className=" inputpolygonStyle"
                    sx={{
                      border: `1px solid #818181`,
                      // padding: "2px",
                      marginTop: "20px",
                      width: { md: "auto", xs: "inherit" },
                      background: `transparent`,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(52deg)",
                        top: "-12px",
                        left: "8px",
                      }}
                    ></Box>
                    <TextField
                    required
                      sx={{
                        // height: "40px",
                        width: { md: "448px", xs: "100%" },

                        "& input": {
                          outline: "none",
                          border: "0.65px solid #818181",
                          color: "white",
                          background: "transparent",
                          paddingRight: "30px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      type="password"
                      id="password"
                      placeholder="Enter password"
                      variant="outlined"
                      name="password"
                      onChange={(e) =>
                        setRegistrationData({
                          ...registrationData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(60deg)",
                        bottom: "-12px",
                        right: "3px",
                      }}
                    ></Box>
                  </Box>
                  <Box
                    className=" inputpolygonStyle"
                    sx={{
                      border: `1px solid #818181`,
                      // padding: "2px",
                      marginTop: "20px",
                      width: { md: "auto", xs: "inherit" },
                      background: `transparent`,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(52deg)",
                        top: "-12px",
                        left: "8px",
                      }}
                    ></Box>
                    <TextField
                    required
                      sx={{
                        // height: "40px",
                        width: { md: "448px", xs: "100%" },

                        "& input": {
                          outline: "none",
                          border: "0.65px solid #818181",
                          color: "white",
                          background: "transparent",
                          paddingRight: "30px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      type="password"
                      id="password"
                      placeholder="Confirm password"
                      variant="outlined"
                      name="renter"
                      onChange={(e) =>
                        setRegistrationData({
                          ...registrationData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(60deg)",
                        bottom: "-12px",
                        right: "3px",
                      }}
                    ></Box>
                  </Box>

                  <Box
                    className="inputpolygonStyle BlenderProBold"
                    sx={{
                      border: `1px solid #D2C915`,
                      padding: "2px",
                      width: { md: "448px", xs: "100%" },
                      // width: "448px",
                      background: `#D2C915`,
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                    type="submit"
                      className="BlenderProBold"
                      sx={{
                        borderColor: "white",
                        padding: "10px 16px",
                        color: "#000",
                        fontSize: "20px",
                        "&:hover": {
                          background: `#D2C915`,
                          color: "#000",
                        },
                      }}
                    >
                      register
                    </Button>
                  </Box>
                </form>
              ) : (
                // Signin session

                <>
                <form onSubmit={(e) =>  handleSignIn(e)} >
                  <Box
                    className=" inputpolygonStyle"
                    sx={{
                      border: `1px solid #818181`,
                      // padding: "2px",
                      marginTop: "20px",
                      width: { md: "auto", xs: "inherit" },
                      background: `transparent`,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(52deg)",
                        top: "-12px",
                        left: "8px",
                      }}
                    ></Box>
                    <TextField
                      sx={{
                        // height: "40px",
                        width: { md: "448px", xs: "100%" },

                        "& input": {
                          outline: "none",
                          border: "0.65px solid #818181",
                          color: "white",
                          background: "transparent",
                          paddingRight: "30px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      type="email"
                      id="email"
                      placeholder="Enter your email address"
                      variant="outlined"
                      name="email"
                      required
                      onChange={(e) =>
                        setSignInData({
                          ...signInData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(60deg)",
                        bottom: "-12px",
                        right: "3px",
                      }}
                    ></Box>
                  </Box>
                  <Box
                    className=" inputpolygonStyle"
                    sx={{
                      border: `1px solid #818181`,
                      // padding: "2px",
                      marginTop: "20px",
                      width: { md: "auto", xs: "inherit" },
                      background: `transparent`,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(52deg)",
                        top: "-12px",
                        left: "8px",
                      }}
                    ></Box>
                    <TextField
                      sx={{
                        // height: "40px",
                        width: { md: "448px", xs: "100%" },

                        "& input": {
                          outline: "none",
                          border: "0.65px solid #818181",
                          color: "white",
                          background: "transparent",
                          paddingRight: "30px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      type="password"
                      id="password"
                      placeholder="Enter password"
                      variant="outlined"
                      name="password"
                      required
                      onChange={(e) =>
                        setSignInData({
                          ...signInData,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(60deg)",
                        bottom: "-12px",
                        right: "3px",
                      }}
                    ></Box>
                  </Box>

                  <Box
                    className="inputpolygonStyle BlenderProBold"
                    sx={{
                      border: `1px solid #D2C915`,
                      padding: "2px",
                      width: { md: "448px", xs: "100%" },
                      // width: "448px",
                      background: `#D2C915`,
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      className="BlenderProBold"
                      sx={{
                        borderColor: "white",
                        padding: "10px 16px",
                        color: "#000",
                        fontSize: "20px",
                        "&:hover": {
                          background: `#D2C915`,
                          color: "#000",
                        },
                      }}
                      type="submit"
                    >
                      Signin
                    </Button>
                  </Box>
                  </form>
                  <Box className="BlenderProBold">
                    {" "}
                    <Typography
                      sx={{
                        marginTop: "20px",
                        color: "#ECE218",
                        fontSize: "20px",
                        fontFamily: "BlenderProBold",
                        cursor: "pointer",
                      }}
                      onClick={forgotPassword}
                    >
                      {" "}
                      FORGOT PASSWORD
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <VoeuxFooter />
      </Box>
    </>
  );
}

export default UserRegistration;
