import { Box,useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import manavatar1 from "../../assets/VoeuxGame/ManAvatar1.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import RadioiActive from "../../assets/VoeuxGame/yellowRadioActive.svg";
import inactiveRadio from "../../assets/VoeuxGame/inactiveRadio.svg";

import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import VoeuxGameHeader from "../../components/voeuxGame/VoeuxGameHeader";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate, } from "react-router-dom";
import coin from "../../assets/VoeuxGame/Coin.svg";
import exchangeicon from "../../assets/VoeuxGame/exchange.svg";
import smallcoin from "../../assets/VoeuxGame/smallcoin.svg";
import largetoken from "../../assets/svgs/metavoeuxCircle.svg";
import smalltok from "../../assets/svgs/metavoeuxCircle.svg";
import VoeuxFooter from "../../components/voeuxGame/VoeuxFooter";
import axios from "axios";
import { Environment } from "../../environments";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import { GameService } from "../../services/gameService";
import { TokenService } from "src/services/tokenService";
import { GAME_REWARD_CONTRACT_ADDRESS } from "src/constants/ABIs/gameRewardABI";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import React from "react";
import PurchaseSuccessfull from "./PurchaseSuccessfullPopup";

function GameUserProfile() {
  // const getEmailLocal = useSelector((state: any) => state.gameUser.email);
  const getEmailLocal = localStorage.getItem("gameEmail");
  const [userDetails, setUserDetails] = useState<any>({});
  const [userResponse, setUserResponse] = useState<any>();

  const getUserPointsApi = async (getEmailLocal: any) => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}game/all-point?email=${getEmailLocal}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      console.log("game user point ", response);

      const gamedetails = {
        totalPoint:
          Number(response?.data?.data?.VirtualCurrency?.["CN"]) +
          Number(response?.data?.data?.VirtualCurrency?.["CR"]),
        userId: response?.data?.user?._id,
        frontier: response?.data?.data?.VirtualCurrency?.["CN"],
        dash: response?.data?.data?.VirtualCurrency?.["CR"],
        email: response?.data?.user?.email,
        displayName:response?.data?.user?.displayName,
      };
      setUserResponse(response?.data);
      console.log("gamedetails", gamedetails);
      setUserDetails({ ...gamedetails });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showToastErrorMessageClick("Failed in reading the User details");
    }
  };

  const [gameService, setGameService] = useState<GameService>();

  const [rewardRate, setRewardRate] = useState<any>();
  const getRewardRate = async () => {
    if(gameService?.gameServiceInstance?.contractInstance !==  null){
      try {
        const data = await gameService?.getCurrentRewardRate();
        console.log("reward rate",data)
        setRewardRate(Number(data));
      } catch (error: any) {
        console.log("error", error);
      }
    }
   
  };
  useEffect(() => {}, [rewardRate]);
  useEffect(() => {
    const gameInst = new GameService();
    console.log("gameInst", gameInst);
    const intervalId = setInterval(() => {
      if (
        gameInst?.gameServiceInstance?.contractInstance !== null &&
        gameInst?.gameServiceInstance?.contractInstance !== undefined
      ) {
        setGameService(gameInst);
        clearInterval(intervalId);
      }
    }, 100);
  }, []);

  useEffect(() => {
    console.log("useEffect gameInst", gameService);

    getRewardRate();
    getGameAdminAddress();
  }, [gameService]);
  useEffect(() => {
    setShowLoader(true);
    getUserPointsApi(getEmailLocal);
  }, []);

  const [selectedGame, setSelectedGame] = useState("frontier");
  const [selectedPlayFabID, setSelectedPlayFabID] = useState<any>();
  const [seelctedGameId, setSeelctedGameId] = useState<any>();
  const handleGameSelection = (e: any) => {
    console.log("game selection", e.target);
    setSelectedGame(e.target.value);
    console.log(
      "play fab id",
      userResponse?.userProfile?.[e.target.id]?.PlayFabId
    );
    setSelectedPlayFabID(userResponse?.data?.PlayFabId);
    setSeelctedGameId(e.target.id);
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/voeuxGame");
  };
  const [showBuyNow, setShowBuyNow] = useState(false);

  const handleListItemClick = () => {
    setShowBuyNow(!showBuyNow);
  };
  useEffect(() => {}, [showBuyNow]);
 
  const [withdrawData, setWithdrawData] = useState<any>({});
  const [voeuxToken, setVoeuxToken] = useState<any>(0);
  const convertPoints = () => {
    console.log("convertPoints");
    if (Number(withdrawData.rewards)) {
      setVoeuxToken(Number(withdrawData.rewards) / rewardRate);
    } else {
      setVoeuxToken(0);
    }
  };
  const [voeuxPoints, setVoeuxPoints] = useState<any>(0);
  const convertCoins = () => {
    if (Number(buyPointsData.coins)) {
      var point = Number(buyPointsData.coins) * rewardRate;
      setVoeuxPoints(point);
    } else {
      setVoeuxPoints(0);
    }
  };
  useEffect(() => {
    console.log("reload");
  }, [voeuxToken, voeuxPoints]);

  const [withdrawSuccess, setWithdrawSuccess] = useState<any>();
  const handleWithdraw = async (e: any) => {
    e.preventDefault();
    setShowLoader(true);
    console.log("withdrawData", withdrawData);
    const walletRegex = /^0x[0-9a-fA-F]{40}$/;

    if (!withdrawData.wallets.match(walletRegex)) {
      showToastErrorMessageClick("Please Provide a valid wallet address");
      setShowLoader(false);
      return false;
    }
    if (Number(withdrawData.rewards) === 0) {
      showToastErrorMessageClick("Please provide point greater than 0");
      setShowLoader(false);
      return false;
    }
    if(Number(withdrawData.rewards) < 100){
      showToastErrorMessageClick("Minimum points required to withdraw is 100");
      setShowLoader(false);
      return false;
    }
    if (Number(withdrawData.rewards) >= userDetails.totalPoint) {
      showToastErrorMessageClick(
        `Please provide point less than ${userDetails.totalPoint}`
      );
      setShowLoader(false);
      return false;
    }
    if (!selectedPlayFabID) {
      showToastErrorMessageClick(
        "Please select the Game which you withdraw the points"
      );
      setShowLoader(false);
      return false;
    }

    withdrawData.email = userDetails.email;
    withdrawData.playFabId = selectedPlayFabID;
    withdrawData.gameName = seelctedGameId;
    console.log("withdraw", withdrawData);
    try {
      const response = await axios.post(
        `${Environment.metavoeuxBackend}game/change-point-into-currency`,
        { ...withdrawData },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      console.log("response withdraw", response);
      setWithdrawSuccess(response.data);
      showToastMessageClick("Points has withdrawn Successfully");
      getUserPointsApi(userDetails?.email);
      getWallet(withdrawData.wallets);
      setShowSuccessPopup(true);
      setShowLoader(false);
    } catch (error: any) {
      showToastErrorMessageClick("Error in withdrawing the VOEUX");
      console.log("error", error);
      setShowLoader(false);
    }
  };

  const [buyPointsData, setBuyPointsData] = useState<any>({});
  const handleBuyPoints = async (e: any) => {
    e.preventDefault();
    setShowLoader(true);
    console.log("buypoints", buyPointsData);
    if (Number(buyPointsData.coins) <= 0) {
      showToastErrorMessageClick("Please Provide a voeux token greater than 0");
      setShowLoader(false);
      return false;
    }
    if (!selectedPlayFabID) {
      showToastErrorMessageClick(
        "Please select the Game which you withdraw the points"
      );
      setShowLoader(false);
      return false;
    }
    if (Number(buyPointsData.coins) * rewardRate < 1) {
      showToastErrorMessageClick(
        `Minimum token you can reddem is ${1 / rewardRate}`
      );
      setShowLoader(false);
      return false;
    }
    try {
      await givePermission(buyPointsData.coins);
      setTimeout(async () => {
        const resp = await gameService?.buyPoint(
          buyPointsData.coins,
          userDetails.userId
        );
        console.log("response of buy point:", resp);
        console.log("userResponse", userResponse);
        const buyDetails = {
          email: userDetails?.email,
          rewards: Number(resp), // should update with blockchain response
          gameName: seelctedGameId,
          playFabId: userResponse.user.playFabId,
        };
        console.log("buyDetails", buyDetails);
        const response = await axios.post(
          `${Environment.metavoeuxBackend}game/change-token-into-point`,
          {
            ...buyDetails,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("change-token-into-point", response);
        setWithdrawSuccess(response.data);
        getUserPointsApi(userDetails?.email);
        showToastMessageClick(`${Number(resp)} Points added successfully.`);
        setShowLoader(false);
        getWallet(withdrawData.wallets);
        setShowSuccessBuyPopup(true);
        // setTimeout(() => {
        //   navigate("/voeuxGame")
        // },1000)
      }, 15000);

      //     withdrawData.email =  userDetails.email
      // withdrawData.playFabId = selectedPlayFabID
      // withdrawData.gameName = seelctedGameId
    } catch (error) {
      console.log("error", error);
      showToastErrorMessageClick("Error in purchase of points using tokens.");
      setShowLoader(false);
    }
  };

  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };

  //code for admin to deposit token in game for dipersals of game point claims
  const [tokenInstance, setTokenInstance] = useState<TokenService>();
  useEffect(() => {
    const tokenInst = new TokenService();
    console.log("gameInst", tokenInst);
    const intervalId = setInterval(() => {
      if (
        tokenInst?.tokenInstance?.contractInstance !== null &&
        tokenInst?.tokenInstance?.contractInstance !== undefined
      ) {
        setTokenInstance(tokenInst);
        clearInterval(intervalId);
      }
    }, 100);
  }, []);
  const [balance, setBalance] = useState<any>();
  const getWallet = async (wallet: any) => {
    try {
      const response = await tokenInstance?.getbalance(wallet);
      console.log("getWallet", response);
      setBalance(response);
    } catch (error: any) {
      console.log("error", error);
    }
  };
  const [depositTokenValue, setDepositTokenValue] = useState<number>();

  const givePermission = async (amount: number) => {
    try {
      const approveResponse = await tokenInstance?.approveTransaction(
        GAME_REWARD_CONTRACT_ADDRESS,
        amount
      );
      return approveResponse;
    } catch (err) {
      throw err;
    }
  };
  const handleDepositToken = async () => {
    try {
      console.log("in handle deposit");
      if (depositTokenValue !== undefined && depositTokenValue > 0) {
        await givePermission(depositTokenValue);
        setTimeout(async () => {
          const resp = await gameService?.depositTokenInGame(depositTokenValue);
          console.log("response of deposit token:", resp);
        }, 10000);
      } else {
        console.error("Invalid token Value");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const [showLoader, setShowLoader] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);

  const [showSucessPopup, setShowSuccessPopup] = useState(false);
  const [showSucessBuyPopup, setShowSuccessBuyPopup] = useState(false);
  const handleHideSuccessPopup = () => {
    setShowSuccessPopup(false);
  };
  const handleHideSuccessBuyPopup = () => {
    setShowSuccessBuyPopup(false);
  };

  const [showAdminButton, setShowAdminButton] = useState<any>(false);
  const getGameAdminAddress = async () => {
    try {
      const gameAdminAddress = await gameService?.getGameAdmin();
      console.log("gameAdminAddress: ", gameAdminAddress);
      const walletAddress = localStorage.getItem("account_address");
      const showAdmin =
        gameAdminAddress?.toLowerCase() === walletAddress?.toLowerCase()
          ? true
          : false;
      setShowAdminButton(showAdmin);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDashboard = () => {
    navigate("/gameAdmin");
  };
  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1400,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      <Box
        sx={{
          background: "black",
          width: "100%",
          minHeight: "100vh",
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          overflow: "hidden",
          position: "relative",
          zIndex: 0,
          // paddingBottom: "40px",
        }}
      >
        <VoeuxGameHeader reload={true} />
        {/* <Box
          sx={{
            ...circleStyle,
            display: { xs: "flex", sm: "flex", md: "flex" },
            top: 0,

            left: "0",
            margin: "auto",
            bottom: "0px",
            // transform: "translateY(37%)",
            width: "407px",
            height: "139px",
            borderRadius: "100%",
            background: ` #fcee0a`,
            filter: "blur(114px)",
            backdropFilter: { md: "none", xs: "blur(114px)" },
            WebkitBackdropFilter: { md: "none", xs: "blur(114px)" },
            overflow: "hidden",
          }}
        ></Box> */}
        <Box
          sx={{
            "@media (max-width: 600px)": {
              padding: "0% 10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginBottom: "10px",
              cursor: "pointer",
              color: "#ECE218",
            }}
          >
            <ArrowBackIosIcon onClick={() => handleBack()} />
            <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
          </Box>
          <Box sx={{ marginBottom: "20px" }}>
            <Box
              className="polygonStyle2 userProfilePolygon"
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "#131313",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backdropFilter: "blur(10.781231880187988px)",
                width: { xs: "100%", md: "80%", sm:"80%" },
                py: "40px",
                gap: "20px",
                margin: "auto",
                "@media(max-width:1240px)":{
                  width:"100%"
                },
                "@media(max-width:899px)":{
                  width:"80%"
                },
                "@media(max-width:600px)":{
                  width:"100%"
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: { xs: "none", md: "40px" },
                  justifyContent: "center",width:"inherit"
                }}
              >
                {/* userProfile section started */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "left" },
                  }}
                >
                  <Box>
                    <img
                      draggable="false"
                      src={manavatar1}
                      alt="img"
                      // style={{ height: "100%" }}
                    />
                  </Box>
                </Box>
                <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                  <Box>
                    <Typography
                      className="blendarPro"
                      sx={{
                        fontSize: "28px",
                        fontFamily: "Blender Pro",
                        textTransform: "uppercase",
                        fontWeight: "700",
                      }}
                    >
                      {userDetails.displayName} 
                       {/* {userDetails.userId?.slice(0, 3) +} */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",

                      margin: "0",
                      padding: "0",
                    }}
                  >
                    <Box
                      sx={{
                        height: "42px",
                        width: "max-content",
                        background: "rgba(0, 147.90, 255, 0.23)",
                        borderRadius: "4px",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: { xs: "center", md: "left" },
                        margin: { xs: "auto", md: "0" },
                      }}
                    >
                      <Typography className="blendarPro"
                        sx={{
                          fontSize: "18.11px",
                          color: "#06F0F6",
                          fontFamily: "Blender Pro",
                          fontWeight: "500",
                          textTransform: "uppercase",
                          padding: "10px",
                        }}
                      >
                        {" "}
                        #USER ID{" "}
                        {userDetails.userId?.slice(0, 3) +
                          "..." +
                          userDetails.userId?.slice(-3)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: "42px",
                        width: "max-content",
                        background: "rgba(255, 187, 51, 0.15)",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "center", md: "left" },
                        padding: "10px",
                        margin: { xs: "auto", md: "0" },
                      }}
                    >
                      <img
                        style={{ marginRight: "5px" }}
                        draggable="false"
                        src={smallcoin}
                        alt="img"
                      />{" "}
                      <Typography
                        className="blendarPro"
                        sx={{
                          fontSize: "18.11px",
                          color: "#FFBB33",
                          fontFamily: "Blender Pro",
                          fontWeight: "500",
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        {userDetails.totalPoint} VX Points
                      </Typography>
                    </Box>
                    <Box>
                      {" "}
                      <RadioGroup
                        sx={{
                          textTransform: "uppercase",
                          marginTop: "10px",
                        }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue=""
                        name="radio-buttons-group"
                        onChange={(e) => handleGameSelection(e)}
                      >
                        <FormControlLabel
                          sx={{
                            margin: "0! important",
                            border: "none",
                            padding: "0! important",
                          }}
                          value="frontier"
                          className={
                            selectedGame === "frontier"
                              ? "customradioActive customFonts fontSize18px"
                              : "customradio customFonts fontSize18px"
                          }
                          control={
                            <Radio
                            className="customFonts"
                              id="CN"
                              sx={{
                                color: "#FF990D",
                                fontSize: "20px",
                              }}
                              icon={<img src={inactiveRadio} height="24px" alt=""/>}
                              disabled={(Number(userDetails.frontier) === 0 && showBuyNow)}
                              checkedIcon={
                                <img src={RadioiActive} height="25px" alt=""/>
                              }
                            />
                          }
                          label={"Last Frontier : " + userDetails.frontier}
                        />
                        <FormControlLabel
                          sx={{
                            margin: "0! important",
                            border: "none",
                            padding: "0! important",
                          }}
                          value="dash"
                          className={
                            selectedGame === "dash" &&
                            Number(userDetails.dash) > 0
                              ? "mt-20 customradioActive customFonts fontSize18px"
                              : "mt-20 customradio customFonts fontSize18px"
                          }
                          control={
                            <Radio
                            
                              id="CR"
                              sx={{
                                color: "#FF990D",
                                fontSize: "20px",
                              }}
                              icon={<img src={inactiveRadio} height="24px" alt="" />}
                              disabled={(Number(userDetails.dash) === 0 && showBuyNow)}
                              // disabled={Number(userDetails.dash) === 0}
                              checkedIcon={
                                <img src={RadioiActive} height="25px" alt="" />
                              }
                            />
                          }
                          label={"Crypto Dash : " + userDetails.dash}
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                </Box>

                {/* userProfile section ended */}
                <Box
                  sx={{
                    borderRight: "1px solid #C4C4C4",
                    width: "auto",
                    margin: "0 auto",

                    opacity: "0.20",
                  }}
                />

                {/* buy now/withdraw section */}
                <Box
                  // className="polygonStyle2"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    background: "#131313",

                    backdropFilter: "blur(10.781231880187988px)",
                    width: { md: "auto", xs: "100%" },
                    height: { md: "auto", xs: "500px" },
                    justifyContent: "start",
                    alignItems: "center",
                    position: "relative",
                    px: { xs: "20px" },
                  }}
                >
                  <Box
                    className="menu"
                    sx={{ width: "100%", maxWidth: "448px", marginTop: "15%" }}
                  >
                    <List
                      className="menu-list customFonts "
                      sx={{
                        cursor: "pointer",
                        padding: "0px",
                        background: "transparent",
                        height: { xs: "48px" },
                      }}
                    >
                      <ListItem
                        className={
                          showBuyNow !== true
                            ? "menu-item filledMenu"
                            : "menu-item"
                        }
                        onClick={handleListItemClick}
                      >
                        <ListItemText
                          className="blendarPro tabStyle"
                          primary="buy vx points"
                          sx={{
                            fontSize: "16px",
                            textTransform: "uppercase",
                            fontStyle: "normal",
                            fontWeight: 500,
                            textAlign: "center",
                          }}
                        />
                      </ListItem>
                      <>
                        <ListItem
                          className={
                            showBuyNow !== false
                              ? "menu-item filledMenu"
                              : "menu-item"
                          }
                          onClick={handleListItemClick}
                        >
                          <ListItemText
                            className="blendarPro tabStyle"
                            primary="withdraw vx points"
                            sx={{
                              fontSize: {md:"14px",sm:"11px"},
                              textTransform: "uppercase",
                              fontStyle: "normal",
                              fontWeight: 500,
                              textAlign: "center",
                            }}
                          />
                        </ListItem>
                      </>
                    </List>
                  </Box>
                  {showBuyNow !== false ? (
                    <form onSubmit={(e) => handleWithdraw(e)} style={{width:'100%'}}>
                      <Box
                        className=" inputpolygonStyle"
                        sx={{
                          border: `1px solid #818181`,
                          // padding: "2px",
                          marginTop: "20px",
                          width: { md: "auto", xs: "inherit" },
                          background: `transparent`,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(52deg)",
                            top: "-12px",
                            left: "8px",
                          }}
                        ></Box>
                        <TextField
                          sx={{
                            // height: "40px",
                            width: { md: "448px", xs: "100%" },

                            "& input": {
                              outline: "none",
                              border: "0.65px solid #818181",
                              color: "white",
                              background: "transparent",
                              paddingRight: "30px",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          type="textfield"
                          id="text1"
                          placeholder="Enter Wallet address"
                          variant="outlined"
                          required
                          name="wallets"
                          onChange={(e) =>
                            setWithdrawData({
                              ...withdrawData,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(60deg)",
                            bottom: "-12px",
                            right: "3px",
                          }}
                        ></Box>
                      </Box>

                      <Box
                        className=" inputpolygonStyle"
                        sx={{
                          border: `1px solid #818181`,
                          // padding: "2px",
                          marginTop: "20px",
                          width: { md: "auto", xs: "inherit" },
                          background: `transparent`,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(52deg)",
                            top: "-12px",
                            left: "8px",
                          }}
                        ></Box>
                        <TextField
                          sx={{
                            // height: "40px",
                            width: { md: "448px", xs: "100%" },
                            "& .MuiInputBase-root": {
                              position: "relative",
                              "&::after": {
                                content: "''",
                                backgroundImage: `url(${coin})`,
                                backgroundSize: "contain",
                                width: "30px",
                                height: "30px",
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                              },
                            },
                            "& input": {
                              outline: "none",
                              border: "0.65px solid #818181",
                              color: "white",
                              background: "transparent",
                              paddingRight: "30px",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          type="textfield"
                          id="points1"
                          placeholder=" Enter vx points"
                          variant="outlined"
                          required
                          name="rewards"
                          onChange={(e) => {
                            setWithdrawData({
                              ...withdrawData,
                              [e.target.name]: Number(e.target.value),
                            });
                            convertPoints();
                          }}
                        />
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(60deg)",
                            bottom: "-12px",
                            right: "3px",
                          }}
                        ></Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "20px",

                          marginTop: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img draggable="false" src={smallcoin} alt="" />
                          <Typography
                          className="blendarPro"
                            sx={{
                              fontSize: { md: "18.34px", xs: "14px" },
                              textTransform: "uppercase",
                              fontWeight: 250,
                              fontFamily: "blendarPro",
                              "@media (max-width: 375px)": {
                                fontSize: "10px",
                              },
                            }}
                          >
                            {rewardRate} VX Points
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img draggable="false" src={exchangeicon} alt="" />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Typography
                           className="blendarPro"
                            sx={{
                              fontSize: { md: "18.34px", xs: "14px" },
                              textTransform: "uppercase",
                              fontWeight: 250,
                              fontFamily: "blendarPro",
                              "@media (max-width: 375px)": {
                                fontSize: "10px",
                              },
                            }}
                          >
                            1 voeux token
                          </Typography>
                          <img
                            draggable="false"
                            src={smalltok}
                            alt=""
                            style={{ width: "15px" }}
                          />
                        </Box>
                      </Box>

                      <Box
                        className=" inputpolygonStyle"
                        sx={{
                          border: `1px solid #818181`,
                          // padding: "2px",
                          marginTop: "20px",
                          width: { md: "auto", xs: "inherit" },
                          background: `transparent`,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(52deg)",
                            top: "-12px",
                            left: "8px",
                          }}
                        ></Box>
                        <TextField
                          sx={{
                            // height: "40px",
                            // width: "448px",
                            borderRadius: "0px !important",
                            width: { md: "448px", xs: "100%" },
                            "& .MuiInputBase-root": {
                              position: "relative",
                              "&::after": {
                                content: "''",
                                backgroundImage: `url(${largetoken})`,
                                backgroundSize: "contain",
                                width: "30px",
                                height: "30px",
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                              },
                            },
                            "& input": {
                              outline: "none",
                              border: "0px solid #818181",
                              color: "white",
                              background: "transparent",
                              paddingRight: "30px",
                              borderRadius: "0px !important",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          type="textfield"
                          id="voeuxtoken"
                          placeholder="0 voeux token"
                          variant="outlined"
                          value={
                            Number(withdrawData.rewards) / rewardRate
                              ? Number(withdrawData.rewards) / rewardRate
                              : 0 + " voeux token"
                          }
                          disabled
                        />
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(60deg)",
                            bottom: "-12px",
                            right: "3px",
                          }}
                        ></Box>
                      </Box>

                      <Box
                        className="inputpolygonStyle BlenderProBold"
                        sx={{
                          border: `1px solid #D2C915`,
                          padding: "2px",
                          width: { md: "448px", xs: "100%" },
                          // width: "448px",
                          background: `#D2C915`,
                          textAlign: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Button
                          className="BlenderProBold"
                          sx={{
                            borderColor: "white",
                            padding: "10px 16px",
                            color: "#000",
                            fontSize: "20px",
                            width: "100%",
                            "&:hover": {
                              background: `#D2C915`,
                              color: "#000",
                            },
                          }}
                          type="submit"
                        >
                          withdraw
                        </Button>
                      </Box>
                    </form>
                  ) : (
                    <form onSubmit={(e) => handleBuyPoints(e)} style={{width:'100%'}}>
                      <Box
                        className=" inputpolygonStyle"
                        sx={{
                          border: `1px solid #818181`,
                          // padding: "2px",
                          marginTop: "20px",
                          width: { md: "auto", xs: "inherit" },
                          background: `transparent`,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(52deg)",
                            top: "-12px",
                            left: "8px",
                          }}
                        ></Box>
                        <TextField
                          sx={{
                            // height: "40px",
                            width: { md: "448px", xs: "100%" },
                            "& .MuiInputBase-root": {
                              position: "relative",
                              "&::after": {
                                content: "''",
                                backgroundImage: `url(${largetoken})`,
                                backgroundSize: "contain",
                                width: "30px",
                                height: "30px",
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                              },
                            },
                            "& input": {
                              outline: "none",
                              border: "0.65px solid #818181",
                              color: "white",
                              background: "transparent",
                              paddingRight: "30px",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          type="decimal"
                          id="entertoken"
                          placeholder="Enter tokens"
                          variant="outlined"
                          name="coins"
                          // setBuyPointsData
                          // value={buyPointsData.coins}
                          onChange={(e) => (
                            convertCoins(),
                            setBuyPointsData({
                              ...buyPointsData,
                              [e.target.name]: Number(e.target.value),
                            })
                          )}
                        />
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(60deg)",
                            bottom: "-12px",
                            right: "3px",
                          }}
                        ></Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "20px",
                          marginBottom: "20px",
                          marginTop: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img draggable="false" src={smallcoin} alt="" />
                          <Typography
                           className="blendarPro"
                            sx={{
                              fontSize: { md: "18.34px", xs: "14px" },
                              textTransform: "uppercase",
                              fontWeight: 250,
                              fontFamily: "blendarPro",
                              "@media (max-width: 375px)": {
                                fontSize: "10px",
                              },
                            }}
                          >
                            {rewardRate} VX Points
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img draggable="false" src={exchangeicon} alt="" />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Typography
                           className="blendarPro"
                            sx={{
                              fontSize: { md: "18.34px", xs: "14px" },
                              textTransform: "uppercase",
                              fontWeight: 250,
                              fontFamily: "blendarPro",
                              "@media (max-width: 375px)": {
                                fontSize: "10px",
                              },
                            }}
                          >
                            1 Voeux
                          </Typography>
                          <img
                            draggable="false"
                            src={smalltok}
                            alt=""
                            style={{ width: "15px" }}
                          />
                        </Box>
                      </Box>

                      <Box
                        className="inputpolygonStyle BlenderProBold"
                        sx={{
                          border: `1px solid #D2C915`,
                          padding: "2px",
                          width: { md: "448px", xs: "100%" },
                          // width: "448px",
                          background: `#D2C915`,
                          textAlign: "center",
                        }}
                      >
                        <Button
                          className="BlenderProBold"
                          sx={{
                            borderColor: "white",

                            color: "#000",
                            fontSize: "20px",
                            width: "100%",
                            "&:hover": {
                              background: `#D2C915`,
                              color: "#000",
                            },
                          }}
                          type="submit"
                        >
                          convert
                        </Button>
                      </Box>

                      <Box
                        className=" inputpolygonStyle"
                        sx={{
                          border: `1px solid #818181`,
                          // padding: "2px",
                          marginTop: "20px",
                          width: { md: "auto", xs: "inherit" },
                          background: `transparent`,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(52deg)",
                            top: "-12px",
                            left: "8px",
                          }}
                        ></Box>
                        <TextField
                          sx={{
                            // height: "40px",
                            // width: "448px",
                            width: { md: "448px", xs: "100%" },
                            "& .MuiInputBase-root": {
                              position: "relative",
                              "&::after": {
                                content: "''",
                                backgroundImage: `url(${coin})`,
                                backgroundSize: "contain",
                                width: "30px",
                                height: "30px",
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                              },
                            },
                            "& input": {
                              outline: "none",
                              border: "0.65px solid #818181",
                              color: "white",
                              background: "transparent",
                              paddingRight: "30px",
                              borderRadius: "0px !important",
                            },
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                          type="textfield"
                          id="points"
                          placeholder="100 points"
                          variant="outlined"
                          disabled
                          value={
                            Number(buyPointsData.coins) * rewardRate
                              ? Number(buyPointsData.coins) * rewardRate +
                                " vx points"
                              : 0 + " vx points"
                          }
                        />
                        <Box
                          sx={{
                            width: "1px",
                            height: "100%",
                            background: `#818181`,
                            position: "absolute",
                            transform: "rotate(60deg)",
                            bottom: "-12px",
                            right: "3px",
                          }}
                        ></Box>
                      </Box>
                    </form>
                  )}
                </Box>
                {/* buy now/withdraw section ended*/}
              </Box>
            </Box>
            {showAdminButton && (
              <Box
                className="inputpolygonStyle BlenderProBold"
                sx={{
                  border: `1px solid #D2C915`,
                  padding: "2px",
                  width: { md: "448px", xs: "100%" },
                  background: `#D2C915`,
                  textAlign: "center",
                }}
                onClick={() => handleDashboard()}
              >
                <Button
                  className="BlenderProBold"
                  sx={{
                    borderColor: "white",
                    padding: "10px 16px",
                    color: "#000",
                    fontSize: "20px",
                    "&:hover": {
                      background: `#D2C915`,
                      color: "#000",
                    },
                  }}
                  type="submit"
                >
                  Go to Admin Dashboard
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <VoeuxFooter />
      </Box>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
        open={showSucessPopup}
        onClose={handleHideSuccessPopup}
        maxWidth={maxWidth}
      >
        <DialogContent>
          <PurchaseSuccessfull
            close={handleHideSuccessPopup}
            points={withdrawData.rewards}
            walletAddress={withdrawData.wallets}
            withdrawSuccess={withdrawSuccess}
            balance={balance}
            type="withdraw"
          />
        </DialogContent>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
        open={showSucessBuyPopup}
        onClose={handleHideSuccessBuyPopup}
        maxWidth={maxWidth}
      >
        <DialogContent>
          <PurchaseSuccessfull
            close={handleHideSuccessBuyPopup}
            points={buyPointsData.coins * rewardRate}
            balance={balance}
            type="buy"
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default GameUserProfile;
