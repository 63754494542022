import { Box } from "@mui/system";
import React from "react";
import {
  useMediaQuery,
} from "@mui/material";
function Tokencard() {
  const tokenomiCard = [
    {
      id: 1,
      amount: "200,000,000",
      title: "Total Token (100%)",
      color: "#FFF",
    },
    {
      id: 2,
      amount: "36,000,000",
      title: "Founders (18%)",
      color: "#EB5757",
    },
    {
      id: 3,
      amount: "28,000,000",
      title: "Public Sale (14%)",
      color: "#219653",
    },
    {
      id: 4,
      amount: "22,000,000",
      title: "Liquidity (11%)",
      color: "#FB3",
    },
    {
      id: 5,
      amount: "8,000,000",
      title: "Advisors (4%)",
      color: "#6FCF97",
    },
    {
      id: 6,
      amount: "48,000,000",
      title: "EcoSystem Reward (24%)",
      color: "#DE8000",
    },
    {
      id: 7,
      amount: "6,000,000",
      title: "Team (3%)",
      color: "#2F80ED",
    },
    {
      id: 8,
      amount: "20,000,000",
      title: "Private Investors (10%)",
      color: "#56CCF2",
    },
    {
      id: 9,
      amount: "32,000,000",
      title: "Pre Sale (16%)",
      color: "#9D00FF",
    },
  ];
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: {
          md:"2rem",
          xs:"0rem"
        },
        p: { lg: "0px 80px",xl: "0px 110px" } 
      }}
     
    >
      {tokenomiCard.map((card) => (
        <Box
          key={card.id}
          sx={{
            width: {md:"210.126px",xs:"100%"},
            height: {md:"132.106px",xs:"85px"},
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",

            background: "#222126",
            marginTop: {md:"1rem",xs:"10px"},
            transition: "transform 0.3s ease-in-out",
            transformOrigin: "center bottom",
          }}
          onMouseEnter={(e) => {
            const cardElement = e.currentTarget;
            cardElement.style.transform = "scale(1.05) rotateX(-5deg)";
          }}
          onMouseLeave={(e) => {
            const cardElement = e.currentTarget;
            cardElement.style.transform = "scale(1) rotateX(0deg)";
          }}
        >
          <Box
            style={{
              position: "absolute",
              left: 0,
              width: "11.412px",
              height: "100%",
              borderRadius: "10px 0px 0px 10px",
              background: card.color,
              boxShadow: `2px 4px 12.9px 0px ${card.color}`,
            }}
          ></Box>
          <p
            style={{
              fontSize: "24px",
              fontWeight: 700,
              fontFamily: "outfit",
              textAlign: "left",
              paddingLeft: isMobile ? "40px" : "30px",
              margin: "0px",
              // marginTop: "10px",
            }}
          >
            {card.amount}
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 400,
              fontFamily: "outfit",
              color: "#7C7C7C",
              textAlign: "left",
              paddingLeft: isMobile ? "40px" : "30px",
              margin: "0px",
              marginTop: "10px",
            }}
          >
            {card.title}
          </p>
        </Box>
      ))}
      {/* <Box style={{ width: "100%", }}></Box>
      {tokenomiCard.slice(5).map((card, index) => (
        <Box
          key={card.id}
          style={{
            width: "210.126px",
            height: "132.106px",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",

            marginRight:
              index === tokenomiCard.slice(5).length - 1 ? 0 : "10px",
            background: "#222126",
            transition: "transform 0.3s ease-in-out",
            transformOrigin: "center bottom",
          }}
          onMouseEnter={(e) => {
            const cardElement = e.currentTarget;
            cardElement.style.transform = "scale(1.05) rotateX(-5deg)";
          }}
          onMouseLeave={(e) => {
            const cardElement = e.currentTarget;
            cardElement.style.transform = "scale(1) rotateX(0deg)";
          }}
        >
          <Box
            style={{
              position: "absolute",
              left: 0,
              width: "11.412px",
              height: "132.106px",
              borderRadius: "10px 0px 0px 10px",
              background: card.color,
              boxShadow: `2px 4px 12.9px 0px ${card.color}`,
            }}
          ></Box>
          <p
            style={{
              fontSize: "24px",
              fontWeight: 700,
              fontFamily: "outfit",
              textAlign: "left",
              paddingLeft: "30px",
              margin: "0px",
              marginTop: "10px",
            }}
          >
            {card.amount}
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 400,
              fontFamily: "outfit",
              color: "#7C7C7C",
              textAlign: "left",
              paddingLeft: "30px",
              margin: "0px",
              marginTop: "10px",
            }}
          >
            {card.title}
          </p>
        </Box>
      ))} */}
    </Box>
  );
}

export default Tokencard;
