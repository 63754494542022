import * as React from "react";
import { useMediaQuery } from "@mui/material";

import {
  Stack,
  Typography,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";

interface TableData {
  name: string;
  logo: string;
  symbol: string;
  price: string;
  priceChange24hrs: number;
  priceChange7days: number;
  marketCap: number;
}

interface BasicTableProps {
  tableData: TableData[]; // Change from TableData to TableData[]
}

const tableButtons = [
  "Top Tokens",
 
];

const borderGradient = {
  background:
    "linear-gradient(#0C0B0F 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
  border: "2px solid transparent",
  borderRadius: { xs: "5px", md: "2px" },
  cursor: "pointer",
  padding: { xs: "0%  1% 0% 2%", md: ".5% 1% .5% 1%" },
  marginRight: { md: "20px" },
  backgroundColor: "#0C0B0F",
};
const hoverborderGradient = {
  background:
    "linear-gradient(#0C0B0F 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
  border: "2px solid transparent",
  borderRadius: { xs: "5px", md: "2px" },
  cursor: "pointer",
  padding: { xs: "0%  1% 0% 2%", md: ".5% 1% .5% 1%" },
  marginRight: { md: "20px" },
  backgroundColor: "#0C0B0F",
};

const defaultButtonStyle = {
  border: "none",

  cursor: "pointer",
  padding: { xs: "0%  1% 0% 2%", md: "1% 2% 1% 1%" },
  marginRight: { md: "20px" },
  // backgroundColor: "#232323",
};

export default function BasicTable({ tableData }: BasicTableProps) {
  const [selectedTable, setSelectedTable] = React.useState("Top Tokens");
  console.log("tableData ==> ",tableData)

  const buttontext = {
    color: "#FFF",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "7.767px",
  };

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
    top: "50%",
    left: "40%",
    transform: "translateY(-30%)",
    width: "450px",
    height: "200px",
    borderRadius: "50%",
    background: "#9D00FF",
    filter: "blur(200px)",
    backdropFilter:"blur(200px)",
    WebkitBackdropFilter:"blur(200px)",
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <TableContainer
      sx={{ backgroundColor: "transparent", position: "relative" ,overflowX:"hidden" }}
    >
      <Stack
        sx={{ ...buttontext, display: "flex", flexDirection: "row", mb: 3 , overflow:"hidden"}} 
        pl={0}
      >
        {tableButtons.map((but) => (
          <Button 
            key={but}
            variant="outlined"
            sx={{
              ...(selectedTable === but ? borderGradient : defaultButtonStyle),
              borderRadius: selectedTable === but ? "6px" : "none",
              color: "#FFF",
              whiteSpace: { xs: "initial", sm: "nowrap" }, // Allow wrapping on smaller screens
              
              height:"40px",

              px: { xs: 1 },
              ":hover": {
                ...hoverborderGradient,
                borderRadius: "6px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", sm: "16px" },
                mr: { xs: 1, sm: 2 },
                ml: { xs: 1, sm: 2 },
                fontFamily: "outfit",
                textTransform: "none",
                fontWeight: "200px",
              }}
              onClick={() => {
                setSelectedTable(but);
              }}
            >
              {but}
            </Typography>
          </Button>
        ))}
      </Stack>
      <div style={circleStyle}></div>
      {isMobile ?  
      <Table sx={{ border: "none" }} aria-label="simple table">
        
        <TableHead>
          <TableRow>
          
            <TableCell
              align="left"
              sx={{
                color: "white",
              
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Name
            </TableCell>
            {/* <TableCell align="right" sx={{ color: 'white' }}>symbol</TableCell> */}
            <TableCell
              align="center"
              sx={{
                color: "white",
                fontFamily: "outfit",
                fontSize: "16px",
               
                fontWeight: "400",
              }}
            >
              Price
            </TableCell>
            {/* <TableCell align="center" sx={{ color: 'white' }}>1hr</TableCell> */}
            <TableCell
              align="center"
              sx={{
                color: "white",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "400",
               
              }}
            >
              24hrs
            </TableCell>
          
            {/* <TableCell align="center" sx={{ color: 'white',fontFamily:"outfit" }}>Trade</TableCell> */}
            {/* Add more TableHead cells as needed */}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={row.name}>
          
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                  fontFamily: "outfit",
                }}
              >
                <Stack
                  sx={{ display: "flex", flexDirection: "row" }}
                  alignItems={"center"}
                >
                  <Box height={"2rem"} width={"2rem"} marginRight={1}>
                    <img
                      src={row.logo}
                      alt="image"
                      height={"100%"}
                      width={"100%"}
                    />
                  </Box>
                  <Typography
                    textAlign={"center"}
                    sx={{ mx: 1, fontFamily: "outfit",fontSize:{xs:"14px"}  }}
                  >
                    {row.name}
                  </Typography>
                  {/* <Typography textAlign={"center"} fontWeight={600} sx={{fontSize:{xs:"14px"} }}>
                    {row.symbol}
                  </Typography> */}
                </Stack>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                  fontFamily: "outfit",
                }}
              >
                ${row?.price}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: row?.priceChange24hrs < 0 ? "red" : "green",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                  fontFamily: "outfit",
                }}
              >
                {row?.priceChange24hrs < 0
                  ? `${row?.priceChange24hrs}%`
                  : `+${row?.priceChange24hrs}%`}
              </TableCell>
            
             
              {/* <TableCell align="center" sx={{ color: 'white',borderBottom:"1px solid rgba(185, 190, 200, 0.5)" }}>{row.percentVolume}</TableCell> */}
              {/* <TableCell align="center" sx={{ color: 'white',borderBottom:"1px solid rgba(185, 190, 200, 0.5)", }}>
                <img src={row.uawGraph} alt="" />
              </TableCell> */}
              {/* <TableCell align="center" sx={{ color: 'white',borderBottom:"1px solid rgba(185, 190, 200, 0.5)" }}>
                <Stack p={0.5} sx={{borderRadius:"8px",fontFamily:"outfit",border:"1px solid #FF990D",'&:hover':{background:"linear-gradient(90deg, #FF990D 6.07%, #FF990D 93.06%)"}, cursor:"pointer"}}>Trade</Stack>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>:  <Table sx={{ minWidth: 650, border: "none" }} aria-label="simple table">
        
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ color: "white", fontSize: "16px", fontWeight: "400" }}
            >
              #
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: "white",
                minWidth: 100,
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Name
            </TableCell>
            {/* <TableCell align="right" sx={{ color: 'white' }}>symbol</TableCell> */}
            <TableCell
              align="center"
              sx={{
                color: "white",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Price
            </TableCell>
            {/* <TableCell align="center" sx={{ color: 'white' }}>1hr</TableCell> */}
            <TableCell
              align="center"
              sx={{
                color: "white",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              24hrs
            </TableCell>
            <TableCell
              align="center"
              sx={{
                color: "white",
                fontFamily: "outfit",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              7d
            </TableCell>
            {/* <TableCell align="center" sx={{ color: 'white' }}>volume</TableCell>
            <TableCell align="center" sx={{ color: 'white' }}>volumePercentageChange</TableCell> */}
            <TableCell
              align="center"
              sx={{ color: "white", fontFamily: "outfit" }}
            >
              Market
            </TableCell>
            {/* <TableCell align="center" sx={{ color: 'white',fontFamily:"outfit" }}>Trade</TableCell> */}
            {/* Add more TableHead cells as needed */}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={row.name}>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                }}
              >
                {index + 1}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                  fontFamily: "outfit",
                }}
              >
                <Stack
                  sx={{ display: "flex", flexDirection: "row" }}
                  alignItems={"center"}
                >
                  <Box height={"2.5rem"} width={"2.5rem"} marginRight={2}>
                    <img
                      src={row.logo}
                      alt="image"
                      height={"100%"}
                      width={"100%"}
                    />
                  </Box>
                  <Typography
                    textAlign={"center"}
                    sx={{ mx: 1, fontFamily: "outfit" }}
                  >
                    {row.name}
                  </Typography>
                  <Typography textAlign={"center"} fontWeight={600}>
                    {row.symbol}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                  fontFamily: "outfit",
                }}
              >
                ${row?.price}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: row?.priceChange24hrs < 0 ? "red" : "green",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                  fontFamily: "outfit",
                }}
              >
                {row?.priceChange24hrs < 0
                  ? `${row?.priceChange24hrs}%`
                  : `+${row?.priceChange24hrs}%`}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: row.priceChange7days < 0 ? "red" : "green",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                  fontFamily: "outfit",
                }}
              >
                {row.priceChange7days < 0
                  ? `${row.priceChange7days}%`
                  : `+${row.priceChange7days}%`}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                  fontFamily: "outfit",
                }}
              >
                {row.marketCap}
              </TableCell>
              {/* <TableCell align="center" sx={{ color: 'white',borderBottom:"1px solid rgba(185, 190, 200, 0.5)" }}>{row.percentVolume}</TableCell> */}
              {/* <TableCell align="center" sx={{ color: 'white',borderBottom:"1px solid rgba(185, 190, 200, 0.5)", }}>
                <img src={row.uawGraph} alt="" />
              </TableCell> */}
              {/* <TableCell align="center" sx={{ color: 'white',borderBottom:"1px solid rgba(185, 190, 200, 0.5)" }}>
                <Stack p={0.5} sx={{borderRadius:"8px",fontFamily:"outfit",border:"1px solid #FF990D",'&:hover':{background:"linear-gradient(90deg, #FF990D 6.07%, #FF990D 93.06%)"}, cursor:"pointer"}}>Trade</Stack>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>}
     
    </TableContainer>
  );
}
