import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import NewNftHeader from "../../../components/newNftHeader/NewNftHeader";
import styles from "../NftLanding.module.css";
import LeftArrow from "../../../assets/svgs/LeftArrow.svg";
import PlaceholderImage from "../../../assets/Createnft/nftCreatePlaceholder.png";
import Createnft from "../../../assets/svgs/nft.svg";
import collection from "../../../assets/svgs/collection.svg";
import mint from "../../../assets/svgs/mint.svg";
import Typography from "@mui/material/Typography";
import RightArrow from "../../../assets/Createnft/RightArrow.svg";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import LandingPageFooter from "../../../components/landingPageFooter/LandingPageFooter";
import axios from "axios";
import { Environment } from "../../../environments";

const NftCreateLanding = () => {
  const createNftOptions = [
    {
      heading: "Drop a collection",
      description: "Launch your NFT collection for others to mint.",
      type: "collection",
    },
    {
      heading: "Mint an NFT",
      description: "Create a collection and mint NFTs directly to your wallet",
      type: "singleNft",
    },
  ];
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/nft");
  };
  const handleNftClick = (type: string) => {
    navigate("/nftCreate", { state: type });
  };
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const walletData = localStorage.getItem("account_address");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${Environment.metavoeuxBackend}user/userDetails`,
          {
            params: { walletAddress: walletData },
          }
        );
        console.log("walletAddress", response);
        localStorage.setItem("userId", response.data._id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [walletData]);
  return (
    <>
      <Box
       className={styles.nftLanding}
        p={4}
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          paddingTop: 0,
         
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader nftHeader={true} />
        <Grid item xs={12} md={6} sm={12} 
        
        >
          <Box
            sx={{
              display: "flex",
              columnGap: "86px",
              justifyContent: "space-between",
              px: { xs: 0, sm: 0,  },
              "@media (max-width: 600px)": {
                padding: "0% 10px",
              },
              // marginTop: { sm: 1, xl: "4rem" },
            }}
          >
            <Box sx={{width:{sm:"100%",md:"100%",lg:"45%"}}}>
              <img src={LeftArrow} alt="img"  draggable="false" onClick={() => handleBack()} />
              <Box sx={{ display: "flex", mb: 3 ,mt:2}}>
              
                <Typography
                  sx={{
                    color: "#FFF",
                    fontSize: { xs: "24px", md: "36px" },
                    fontWeight: 600,
                    fontFamily: "outfit",
                    mr:"10px"
                  }}
                >
                  Create NFT
                </Typography>
                <img src={Createnft} alt="img" draggable="false"/>
              </Box>

              <Box 
                sx={{
                  display: { xs: "block", md: "none" },
                  mb: 5,
                  width: { xs: "auto", lg: "597px" },
                }}
              >
                <img src={PlaceholderImage} draggable="false" width="100%" alt="" />
              </Box>
              <Box sx={{ position: "relative" }}>
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    ...circleStyle,
                    bottom: { xl: "-50%" },
                    left: "-22%",
                    transform: "translateY(-50%)",
                    width: "350px", // Adjusted width
                    height: "200px", // Adjusted height
                    borderRadius: "50%", // Ensures it remains a perfect circle
                    background: "#9D00FF",
                    // background: "red",
                    filter: "blur(140px)",
                    backdropFilter:"blur(140px)",
                    WebkitBackdropFilter:"blur(140px)", 
                    overflow: "hidden",
                    "@media (min-width: 1721px)": {
                      width: "450px", // Adjusted width
                      height: "400px", // Adjusted height
                      top: { xl: "50%" },
                      left: "-20%",
                      filter: "blur(160px)",
                      backdropFilter:"blur(160px)",
                      WebkitBackdropFilter:"blur(160px)", 
                    },
                  }}
                ></Box>
                {createNftOptions.map((options) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      // columnGap: { lg: "139px" },
                      p: { xs: "18px", lg: "38px" },
                      background: "rgba(217, 217, 217, 0.12)",
                      borderRadius: "18px",
                      mb: "38px",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "box-shadow 400ms ease-out 0s, transform 400ms ease-out 0s",
                      "&:hover":{
                        boxShadow: "rgba(0, 0, 0, 0.12) 0px 6px 24px",
                        background: "rgba(217, 217, 217, 0.12)",
                        transform: "translate(0px, -4px)",
                        cursor: "pointer"
                      }
                    }}
                    onClick={() => handleNftClick(options.type)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <Box sx={{ display: "flex", columnGap: "17px" }}>
                        <img src={options.type == "collection"? collection : mint} alt="img" draggable="false"/>
                        <Typography
                          sx={{
                            color: "#FFF",
                            fontSize: { xs: "16px", md: "24px" },
                            fontWeight: 600,
                            fontFamily: "outfit",
                          }}
                        >
                          {options.heading}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          color: "#F6EBFD",
                          fontSize: { xs: "16px", md: "18px" },
                          fontWeight: 300,
                          fontFamily: "outfit",
                        }}
                      >
                        {options.description}
                      </Typography>
                    </Box>
                    <Box>
                      <img src={RightArrow} alt="img" draggable="false"/>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                // width: { md: "auto" },
                width:{sm:"100%",md:"100%",lg:"45%"},
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  ...circleStyle,
                  top: { xl: "25%" },
                  left: "60%",
                  transform: "translateY(-50%)",
                  width: "250px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  // background: "red",
                  filter: "blur(140px)",
                  backdropFilter:"blur(140px)",
                  WebkitBackdropFilter:"blur(140px)", 
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "50%" },
                    left: "-20%",
                    filter: "blur(160px)",
                    backdropFilter:"blur(160px)",
                    WebkitBackdropFilter:"blur(160px)", 
                  },
                }}
              ></Box>

              <img src={PlaceholderImage} alt="img" width="100%" draggable="false" />
            </Box>
          </Box>
        </Grid>
      </Box>
      <LandingPageFooter />
    </>
  );
};
export default NftCreateLanding;
