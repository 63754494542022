const { NFTStorage, Blob } = require("nft.storage");
// const fs = require('fs');

// const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDgxQ0QyY0E4N2ZhNjczQjRCZTEzOThmQkI5NzRFODY3ZEE4QWYzMTQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcwNTU2MzQ5MjY3MywibmFtZSI6InRlc3QifQ.T9fG6VCz6sbBkiP0FOHbRD-gtu-za72xuuAUKEmTrBU'
const NFT_STORAGE_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDg2M0U4REE4OWJFRDI5RkVDM0NjZDg0MGNGQWM4ZTUwY0ZERTNGQmUiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcwNTEzMDY4NzA5NSwibmFtZSI6ImZpcnN0QVBJS2V5In0.bh_xVJG8-5bkvRo_s9dnMdyaD8FTRYtsUAGC4u0TAhg";
const client = new NFTStorage({ token: NFT_STORAGE_TOKEN });

export const uploadNFTImage = async (
  image,
  name,
  description = "This is dummy description"
) => {
  console.log("page index");
  console.log("page index", image);

  // const imagePath = "./assets/images/aboutImage.png";
  // const imageBuffer = fs.readFileSync(image);
  // const response = await fetch(image);
  // const blob = await response.blob();
  // const imageFile = new File([blob], 'nft.png', { type: 'image/png' })
  // const metadata = await client.store({
  //   name: name,
  //   description: description,
  //   image: image[0]
  // })
  // console.log(`https://${metadata.ipnft}.ipfs.nftstorage.link`);
  const imageBuffer = await image.arrayBuffer();
  const cidnewImage = await client.storeBlob(new Blob([imageBuffer]));
  console.log("new code", cidnewImage);

  // let cidnewImage;
  // try{
  //   cidnewImage = await client.storeDirectory(image)
  // }catch (error){
  //   console.log("error cidnewImage",error)
  // }

  console.log("cidnewImage", cidnewImage);
  console.log(`https://${cidnewImage}.ipfs.nftstorage.link`);

//   const jsonData = {
//     name: name,
//     description: description,
//     image: `ipfs://${cidnewImage}/${image.name}`,
//     edition: 1,
//     attributes: [
//       {
//         trait_type: "Herp Type",
//         value: "animated",
//       },
//     ],
//   };

  
  return cidnewImage;
};
