import { GAME_REWARD_CONTRACT_ABI, GAME_REWARD_CONTRACT_ADDRESS } from "src/constants/ABIs/gameRewardABI";
import { GameReward } from "src/contracts/GameReward";

export class GameService{
        gameServiceInstance:GameReward;
        constructor(){
                this.gameServiceInstance = new GameReward(
                        GAME_REWARD_CONTRACT_ADDRESS, GAME_REWARD_CONTRACT_ABI
                )
        }

        updateGameRewardRate = async(newRate:number)=>{
                try{
                        return await this.gameServiceInstance?.updateGameRewardRate(newRate); 
                }catch(err){
                        throw err;
                }
        }

        getCurrentRewardRate = async() =>{
                try{
                        console.log("in game service")
                        return await this.gameServiceInstance?.getCurrentRewardRate();
                }catch(err){
                        throw err;
                }
        }

        depositTokenInGame = async(amount:number)=>{
                try{
                        let newAmount = BigInt(0);
                        console.log("amount: ", amount * 10 ** 10);
                        if (amount > 0 && amount < 1) {
                          newAmount =
                            BigInt(Math.ceil(amount * 10 ** 10)) * BigInt(10) ** BigInt(8);
                        } else {
                          newAmount = BigInt(amount) * BigInt(10) ** BigInt(18);
                        } 
                        return await this.gameServiceInstance?.depositTokenInGame(newAmount);
                }catch(err){
                        throw err;
                }
        }

        getTokenBalance = async()=>{
                try{
                        return await this.gameServiceInstance?.getGameTokenBalance();
                }catch(err){
                        throw err;
                }
        }

        buyPoint = async(amount:number, userId:string)=>{
                try{
                        
                        let newAmount = BigInt(0);
                
                        if (amount > 0 && amount < 1) {
                          newAmount =
                            BigInt(Math.ceil(amount * 10 ** 10)) * BigInt(10) ** BigInt(8);
                        } else {
                          newAmount = BigInt(amount) * BigInt(10) ** BigInt(18);
                        } 

                        
                        const buypoint =  await this.gameServiceInstance?.buyPoints(newAmount, userId);
                       
                        return buypoint
                }catch(err){
                        throw err;
                }
        }
        getGameAdmin = async()=>{
                try{
                        return await this.gameServiceInstance?.getGameAdmin();
                }catch(err){
                        throw err;
                }
        }

        withdrawToken = async(amount:number)=>{
                try{
                        let newAmount = BigInt(0);
                
                        if (amount > 0 && amount < 1) {
                          newAmount =
                            BigInt(Math.ceil(amount * 10 ** 10)) * BigInt(10) ** BigInt(8);
                        } else {
                          newAmount = BigInt(amount) * BigInt(10) ** BigInt(18);
                        } 
                        return await this.gameServiceInstance?.withdrawToken(newAmount);
                }catch(err){
                        throw err;
                }
        }

}
export default GameService