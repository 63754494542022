import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import React from "react";

function createData(
  event: string,
  price: string,
  from: string,
  to: string,
  date: string
) {
  return { event, price, from, to, date };
}

const rows = [
  createData("Transfer", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Sale", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Transfer", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Transfer", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Sale", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Transfer", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Sale", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Sale", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Sale", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Transfer", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Sale", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
  createData("Transfer", "0.482 POLYGON", "BozoVault", "wooott", "1 years ago"),
];

const NftActivity = () => {
  return (
    <>
      <Box>
        <Typography
          sx={{
            fontSize: "32px",
            fontFamily: "outfit",
            fontWeight: 700,
            marginBottom: "1rem",
          }}
        >
          Activity
        </Typography>

        <Typography
          sx={{
            fontSize: "24px",
            fontFamily: "outfit",
            fontWeight: 600,
            mb: "20px",
          }}
        >
          Item Activity
        </Typography>
        <Box sx={{ width: { xs: "40%" } }}>
          <table cellPadding="20px" className="basicTable">
            <thead>
              <tr>
                <th style={{ color: "white" }}>Event</th>
                <th style={{ color: "white" }}>Price</th>
                <th style={{ color: "white" }}>From&nbsp;(g)</th>
                <th style={{ color: "white" }}>To&nbsp;(g)</th>
                <th style={{ color: "white" }}>Date&nbsp;(g)</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                <tr>
                  <td>{row.event}</td>
                  <td>{row.price}</td>
                  <td style={{ color: "#FB3" }}>{row.from}</td>
                  <td style={{ color: "#FF990D" }}>{row.to}</td>
                  <td>{row.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
};

export default NftActivity;
