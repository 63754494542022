import { Typography, useMediaQuery } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import careerimage from "../../assets/images/careeriamge.png";
import Careercardmui from "./Careercardmui";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";

function Careermui() {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Box
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          // "@media (max-width: 600px)": {
          //   padding: 2,
          // },
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader />
        <Box>
          {!isMobile && (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "23px", md: "32px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 0,
                  marginBottom: "10px",
                  fontFamily: "outfit",
                }}
              >
                We are looking for talented people like you
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mx: "auto",
                  my: 4,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    typography: { xs: "body2", sm: "body2" },
                    textAlign: { xs: "center", md: "inherit" },
                    fontFamily: "outfit",
                  }}
                  justifyContent={"center"}
                >
                  <span
                    style={{
                      color: "#F2F2F2",
                      fontSize: "20px",
                      textAlign: "center",
                      fontFamily: "outfit",
                    }}
                  >
                    Our philosophy is simple — hire a team of diverse,
                    passionate people and
                    <span style={{ display: "block" }}>
                      foster a culture that empowers you to do your best work.
                    </span>
                  </span>
                </Typography>
              </Stack>
            </>
          )}

          {isMobile && (
            <Box padding={"16px"}>
              <Typography
                sx={{
                  fontSize: "23px",
                  fontWeight: "600px",
                  fontFamily: "outfit",
                  lineHeight: "25px !important",
                  marginBottom: "7px",
                }}
              >
                We are looking for talented people like you
              </Typography>
              <p
                style={{
                  fontSize: "16px",
                  marginBottom: "1rem",
                  fontFamily: "outfit",
                }}
              >
                Our philosophy is simple — hire a team of diverse, passionate
                people and foster a culture that empowers you to do your best
                work.
              </p>
            </Box>
          )}

          <Box
            sx={{
              borderRadius: "22px",
              padding: { xs: "16px" },
            }}
          >
            <img src={careerimage} alt="" width={"100%"} />
          </Box>
          <Typography
            sx={{
              color: "orange",
              marginTop: { xs: "1rem", md: "5rem" },

              fontFamily: "outfit",
              paddingLeft: { xs: "16px" },
            }}
          >
            We are hiring!
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "23px", md: "32px" },
              fontWeight: "600px",
              fontFamily: "outfit",
              // padding: { xs: "16px" },
              paddingLeft: "16px",
            }}
          >
            Join our team
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              marginBottom: "1rem",
              fontFamily: "outfit",
              paddingLeft: { xs: "16px" },
            }}
          >
            Ready to make a change? Come be part of our amazing team. We're all
            about creating, innovating, and growing. If You're passionate about
            pushing boundaries and want to be part of something exciting,let's
            team up! Your journey starts here. Join us today!
          </Typography>
          <Careercardmui />
        </Box>
      </Box>
      <LandingPageFooter />
    </>
  );
}

export default Careermui;
