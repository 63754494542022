import { Button, TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { MarketplaceService } from "src/services/marketplaceService";
import axios from "axios";
import ToastMessage from "../../../components/ToastMessage/ToastMessage";
import { Environment } from "../../../environments";

function NftMakeAnOffer({
  nftDetails,
  createOffer,
  handleMakeOfferClose,
  handleMakeApiSuccess,
  showErrorToastMessageClick,
  handleShowLoader,
}: {
  nftDetails: any;
  createOffer: (offerPrice: number) => void;
  handleMakeOfferClose: () => void;
  handleMakeApiSuccess: () => void;
  showErrorToastMessageClick: (text: string) => void;
  handleShowLoader: (show: any) => void;
}) {
  console.log("nftDetails", nftDetails);
  const [count, setCount] = useState(0);
  const [marketplaceInstance, setMarketplaceInstance] =
    useState<MarketplaceService>();
  const [biddingPrice, setBiddingPrice] = useState("");

  const [nftDetailResponse, setNftDetailResponse] = useState<any>({});
  const [makeOfferData, setMakeOfferData] = useState<any>({});

  const getuseData = localStorage.getItem("userId");

  const [toastMessage, setToastMessage] = useState("");
  const [showToastMessage, setShowToastMessage] = useState(false);

  useEffect(() => {
    const instance = new MarketplaceService();
    setMarketplaceInstance(instance);
  }, []);

  const createNftObject = {
    nft: ["sample"],
    tokenId: [1],
    price: [23],
    to: ["0x0069e0Fe396E99d326acEB8274f3b41f91fD1866"],
  };

  useEffect(() => {
    console.log("nftMakeanOfferInstance", marketplaceInstance);
    if (marketplaceInstance) {
      marketplaceInstance.makeOffer(["address"], [0], [10001]);
    } else {
      console.log("nftServiceInstance not present:");
    }
  }, [marketplaceInstance]);

  const handleAdd = () => {
    setCount(count + 1);
  };

  const handleMinus = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  // Event handler for the input change
  const handleInputChange = (event: any) => {
    // Update the state with the new value
    console.log("biddingPrice", biddingPrice);
    setBiddingPrice(event.target.value);
  };

  const makeAnOffer = async () => {
    try {
      await createOffer(Number(biddingPrice));
      const response = await axios.post(
        `${Environment.metavoeuxBackend}makeoffer`,
        {
          ...makeOfferData,
        }
      );
      if (response.data.status == 403) {
        handleShowLoader(false);
        showErrorToastMessageClick(
          "Not allowed to make an offer, since your the creator"
        );
      } else {
        console.log("make offer", response);
        setNftDetailResponse(response.data);
        setShowToastMessage(true);
        handleMakeOfferClose();
        handleMakeApiSuccess();
        handleShowLoader(false);
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
      showErrorToastMessageClick(error.reason ? error.reason : error.message);
      handleShowLoader(false);
      // showErrorToastMessageClick("Failed in creating a Make an offer");
    }
  };
  const [maxPrice, setMaxPrice] = useState<any>(0);
  const getMaxPrice = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}makeoffer/makeOfferNft?nftid=${nftDetails.nftID}`
      );
      setMaxPrice(response.data.maxOffer);
    } catch (error) {
      showErrorToastMessageClick("Error Failed in getting latest bid");
    }
  };
  const handleMakeAnOffer = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}makeoffer/makeOfferNft?nftid=${nftDetails.nftID}`
      );
      setMaxPrice(response.data.maxOffer);
      if (response.data.maxOffer > 0 && biddingPrice < response.data.maxOffer) {
        showErrorToastMessageClick(
          `Please make an offer greater than  ${response.data.maxOffer} voeux`
        );
        return false;
      }
      handleShowLoader(true);
      makeOfferData.collectionId = nftDetails.collectionID;
      makeOfferData.offeredPrice = biddingPrice;
      makeOfferData.user = getuseData;
      makeOfferData.nft = nftDetails.nftID;
      makeOfferData.accepted = "false";
      makeOfferData.active = "false";
      setMakeOfferData({ ...makeOfferData });
      console.log("makeOfferData", makeOfferData);

      makeAnOffer();
    } catch (error) {
      showErrorToastMessageClick("Error Failed in getting latest bid");
    }
  };

  const handlesetToBestOffer = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}makeoffer/makeOfferNft?nftid=${nftDetails.nftID}`
      );
      console.log("get the best offer", response);
      setBiddingPrice(response.data.maxOffer);
      setShowToastMessage(true);
      setToastMessage(response.data.message);
    } catch (error) {
      console.error("Error fetching data:", error);
      showErrorToastMessageClick("Failed in getting the best offer");
    }
  };

  return (
    <>
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />

      <Stack
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            width: { md: "555px", xs: "100%" },
            // height: "135px",
            borderRadius: "24px",

            background:
              "linear-gradient(267deg, rgba(255, 153, 13, 0.35) -3.12%, rgba(157, 0, 255, 0.35) 93.15%)",

            backdropFilter: "blur(2px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "22px",
                  fontFamily: "outfit",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                  color: "#fff",
                  fontWeight: "500px",
                }}
              >
                Make Collection Offer
              </Typography>
            </Box>

            <>
              {/* {isVisible && ( */}
              <Button
                sx={{ fontSize: "20px", color: "#fff" }}
                onClick={() => handleMakeOfferClose()}
              >
                X
              </Button>
              {/* )} */}
            </>

            {/* <Button sx={{ fontSize: "20px", color: "#fff" }}>close</Button> */}
          </Box>
          <Box
            sx={{
              width: { md: "555px", xs: "100%" },

              borderRadius: "24px",

              background: "  var(--272727, #272727)",
              backdropFilter: "blur(2px)",
              padding: "24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                fontWeight: "300px",
                color: "#BDBDBD",
                marginBottom: "10px",
              }}
            >
              {" "}
              Price
            </Typography>

            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                  width: "auto",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontFamily: "outfit",
                    fontSize: "16px",
                  }}
                >
                  {nftDetails.price}voeux
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontFamily: "outfit",
                    color: "#BDBDBD",

                    fontWeight: "300px",
                    marginBottom: "15px",
                  }}
                >
                  Offer Price
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  alignItems: { md: "center", xs: "flex-start" },
                  // alignItems: "center",
                  rowGap: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "12px",
                    border: "1px solid #777",
                    color: "#828282",
                    // width: "100%",
                    height: "56px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    padding: "0px 20px",
                  }}
                >
                  <Box>
                    <TextField
                      type="number"
                      name="Name"
                      placeholder="Price"
                      value={biddingPrice}
                      onChange={handleInputChange}
                      required
                      fullWidth
                      sx={{
                        fontSize: "16px",
                        fontFamily: "outfit",
                        input: {
                          color: "white",
                          background: "transparent",
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: "none",
                            },
                        },
                      }}
                    ></TextField>
                  </Box>

                  <Box
                    sx={{
                      borderLeft: "1px solid #777",
                      width: "4px",
                      height: "100%",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      fontFamily: "outfit",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "2px",
                    }}
                  >
                    <Typography>VOEUX</Typography>
                  </Box>
                </Box>
                <Button
                  sx={{
                    borderRadius: "12px",
                    background: " orange",
                    width: { md: "auto", xs: "100%" },
                    color: "white",
                    textTransform: "none",
                    fontSize: "18px",
                    padding: "6px",
                    fontFamily: "outfit",
                    "&:hover": {
                      background: " orange",

                      color: "white",
                    },
                  }}
                  onClick={() => handlesetToBestOffer()}
                >
                  Set to best offer
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { md: "row", xs: "column" },
                  rowGap: "20px",
                  marginTop: "15px",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <Box>
                  <Box>
                    {" "}
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontFamily: "outfit",
                        color: "#BDBDBD",
                      }}
                    >
                      Quantity{" "}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontFamily: "outfit",
                        marginRight: { md: "2.5rem", xs: "0" },
                        color: "#fff",
                      }}
                    >
                      {" "}
                      Offer can be accepted seprately
                    </Typography>{" "}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "226px",
                    height: "52px",
                    borderRadius: "12px",
                    border: "1px solid #777",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Button sx={{ color: "#fff" }} onClick={handleAdd}>
                    +
                  </Button>
                  <Typography sx={{ color: "#fff" }} variant="h6">
                    {" "}
                    {count}
                  </Typography>
                  <Button sx={{ color: "#fff" }} onClick={handleMinus}>
                    -
                  </Button>
                </Box>
              </Box>
              <Button
                onClick={handleMakeAnOffer}
                sx={{
                  borderRadius: "12px",
                  background:
                    " var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
                  width: "100%",
                  marginTop: "32px",
                  color: "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontFamily: "outfit",
                }}
              >
                Make Offer
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
}

export default NftMakeAnOffer;
