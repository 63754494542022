import React from "react";
import NewNftHeader from "../components/newNftHeader/NewNftHeader";
import Faq from "./faqmui/Faq";
import { Box } from "@mui/system";
import LandingPageFooter from "../components/landingPageFooter/LandingPageFooter";

const FAQPage = () => {
  return (
    <>
      <Box
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader />
        

        <Faq />
      </Box>
      <LandingPageFooter />
    </>
  );
};

export default FAQPage;
