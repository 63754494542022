import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styled from "styled-components";
import  { Environment }  from "../../../environments";

import {
  Stack,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@mui/material";
import coinImage from "../../../assets/images/coinImage.png";
import walletImage from "../../../assets/images/walletImage.png";
import graphImage from "../../../assets/images/stockGraph.png";
import {
  NavigateNext as CustomNextIcon,
  NavigateBefore as CustomPrevIcon,
} from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const learnSectionCards = [
  {
    image: coinImage,
    title: "How to setup a crypto wallet in your account",
    content: "Analyzing market trends helps in making informed decisions...",
  },

  {
    image: walletImage,
    title: "How to buy cryptocurrency with a credit card",
    content: "Buying crypto with a credit card is a convenient method...",
  },
  {
    image: coinImage,
    title: "How to setup a crypto wallet in your account",
    content:
      "A crypto wallet is a place where you can securely keep your crypto...",
  },
  {
    image: graphImage,
    title: "Understanding cryptocurrency market trends",
    content: "Analyzing market trends helps in making informed decisions...",
  },
  {
    image: coinImage,
    title: "How to setup a crypto wallet in your account",
    content: "Analyzing market trends helps in making informed decisions...",
  },

  {
    image: walletImage,
    title: "How to buy cryptocurrency with a credit card",
    content: "Buying crypto with a credit card is a convenient method...",
  },
  {
    image: coinImage,
    title: "How to setup a crypto wallet in your account",
    content:
      "A crypto wallet is a place where you can securely keep your crypto...",
  },
  {
    image: graphImage,
    title: "Understanding cryptocurrency market trends",
    content: "Analyzing market trends helps in making informed decisions...",
  },
  {
    image: coinImage,
    title: "How to setup a crypto wallet in your account",
    content: "Analyzing market trends helps in making informed decisions...",
  },

  {
    image: walletImage,
    title: "How to buy cryptocurrency with a credit card",
    content: "Buying crypto with a credit card is a convenient method...",
  },
  {
    image: coinImage,
    title: "How to setup a crypto wallet in your account",
    content:
      "A crypto wallet is a place where you can securely keep your crypto...",
  },
  {
    image: graphImage,
    title: "Understanding cryptocurrency market trends",
    content: "Analyzing market trends helps in making informed decisions...",
  },
];

const TitleText = {
  fontFeatureSettings: "'salt' on, 'liga' off",

  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "17.829px",
  fontFamily: "outfit",
};

const CustomText = {
  fontFeatureSettings: "'salt' on, 'liga' off",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "15.168px",
  fontFamily: "outfit",
};

const CustomButton = {
  fontFamily: "outfit",
  fontSize: "10.188px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "11.277px",
};

const StyledSlider = styled(Slider)`
  & .slick-dots li button {
    color: white; /* Change dot color to white */
    background-color: red; /* Change dot background color to red */
    width: 10px; /* Set width of dots */
    height: 10px; /* Set height of dots */
  }
`;

export default function NormalCard() {
  const StyledSlider = styled(Slider)`
    & .slick-dots li button {
      color: white;
    }
  `;
  const isMobile = useMediaQuery("(max-width:600px)");
  const CustomNextArrow = (props: any) => {
    const { onClick } = props;
    return !isMobile ? (
      <CustomNextIcon
        style={{
          fontSize: 40,
          position: "absolute",
          right: -45,
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    ) : null;
  };

  const CustomPrevArrow = (props: any) => {
    const { onClick } = props;
    return !isMobile ? (
      <CustomPrevIcon
        style={{
          fontSize: 40,
          position: "absolute",
          left: -45,
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
    ) : null;
  };

  const settings = {
    dots: true,

    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <CustomPrevArrow />,
    prevArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const slicedCards = learnSectionCards.slice(0, 4);

  const [blogData, setBlogData] = useState<any>([]);
  const getBlogData = async () => {
    try {
      const response = await axios.get(`${Environment.metavoeuxBackend}blog`);
      console.log("blog response", response);
      setBlogData(response.data);
    } catch (error) {
      console.log("Error in getting blog");
    }
  };
  useEffect(() => {
    getBlogData();
  }, []);

  const navigate = useNavigate();
  const handleBlogClick = (blogId: any) => {
    navigate(`/blogDetails/${blogId}`);
  };

  return (
    <Grid maxWidth={"95%"} sx={{ margin: "auto", background: "#0C0B0F" }}>
      <StyledSlider {...settings} >
        {blogData.map((blog: any, index: any) => (
          <Grid
            key={index}
            // sx={{ padding: "15px",ml:2 }}
            item
            // xs={12}
            // sm={6}
            // md={4}
            onClick={() => handleBlogClick(blog.blogId)}
            sx={{
              background: "#0C0B0F",
              // boxShadow: "0px 0px 7px rgba(0,0,0,0.5)",
              borderRadius: "10px",
              overflow: "hidden",
              cursor:"pointer"
            }}
          >

            <Card
              key={index}
              sx={{
                background: "#0C0B0F",
                border: "none",
                // height: 275,
                // width:{md:330,lg:370,xl:322},
                borderRadius: "10px",
                // minWidth: "250px", // Adjust the minimum width for each card as needed
                margin: "0 8px", // Adjust the margin between cards
              }}
            >
              <CardMedia
                component="img"
                height="170px"
                image={blog.mainImage}
                alt={blog.title}
                style={{objectFit:"cover"}}
              />
              <CardContent
                sx={{
                  background: "#20182A",
                  display: "flex",
                  padding: "10.251px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ color: "#FFF", ...TitleText , height: "20px",
                  overflow: "hidden",}}
                >
                  {blog.mainHeading}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    color: "#B6B6B6",
                    ...CustomText,
                    height: "20px",
                    overflow: "hidden",
                  }}
                >
                  {blog.sortDes}
                </Typography>
                <Stack
                  sx={{
                    background: "#20182A",
                    color: "#FF990D",
                    display: "flex",
                    alignItems: "flex-end",
                    mt: { md: 2 },
                  }}
                >
                  <Typography
                    sx={{ fontFamily: "outfit", cursor: "pointer" }}
                   
                  >
                    Learn More
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          //  </Grid>
        ))}
      </StyledSlider>
    </Grid>
    // </div>
    // </div>
  );
}
