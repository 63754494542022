import Box from "@mui/material/Box";
import NewNftHeader from "../../../components/newNftHeader/NewNftHeader";
import styles from "../NftLanding.module.css";
import NftCollectionLiveCard from "../NftCollectionLiveCard";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleRoundedIcon1 from "../../../assets/svgs/CheckCircleRoundedIcon12.svg";
import inactiveRadioButton from "../../../assets/svgs/inactiveRadioButton.svg";
import checkboxActive from "../../../assets/svgs/checkboxActive.svg";
import checkboxInactive from "../../../assets/svgs/Checkbox.svg";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import LandingPageFooter from "../../../components/landingPageFooter/LandingPageFooter";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { Environment } from "../../../environments";
import { useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";


const NftOverview = () => {
  const checkvalue: Array<string> = [];

  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  useEffect(() => {
    handleShowLoader(true);
    getAllNft();
  }, []);
  const [originalNftList, setoriginalNftList] = useState<any>([]);
  const [nftList, setNftList] = useState<any>([]);
  const getAllNft = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}listingnft`
      );
      console.log("get listing nft", response);
      const filteredData = response.data?.filter(
        (x: any) => x?.listingPrice !== undefined
      );
      const listingNft = filteredData.map((data: any) => {
        return {
          imageURL: data.nft?.imageUrl,
          nftName: data.nft?.nftName,
          price: data.listingPrice,
          supply: data.nft?.supply,
          nftID: data.nft?._id,
        };
      });
      setNftList([...listingNft]);
      setoriginalNftList([...listingNft]);
      handleShowLoader(false);
      console.log("all the NFT which are made for sale", response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleNftSearch = (value: any) => {
    const newArray = originalNftList;
    console.log("newArray", newArray, value);
   
      const filteredArray = newArray.filter(
        (list: any) =>
          list?.nftName?.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      console.log("filtereddArra", filteredArray);
      if(filteredArray.length === 0){
        setNftList([]);
      }else{
        setNftList(filteredArray);
      }
  };

  // const handleCategoryChnage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedCategory(event.target.value);
  //   filterData("category", event.target.value);
  // };

  // const filterData = (category: string, value: string) => {
  //   const filterarray = selectedStatus.length > 0 ? sample : originalValue;
  //   var test;
  //   if (value) {
  //     test = filterarray.filter((x) => x[category] == value);
  //     setSample([...test]);
  //   }
  // };

  // const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSelectedType(event.target.value);
  //   filterData("type", event.target.value);
  // };

  // const filterCheckboxData = (checkvalue: any) => {
  //   const filterarray = sample;
  //   let test: any;
  //   if (checkvalue.length == 0) {
  //     test = filterarray;
  //   } else {
  //     test = filterarray.filter((x) => checkvalue.includes(x.status));
  //   }

  //   setSample([...test]);

  //   filterData("category", selectedCategory);
  //   filterData("type", selectedType);
  // };
  // const handleStatusChnage = (event: any) => {
  //   if (!selectedStatus.includes(event.target.value)) {
  //     selectedStatus.push(event.target.value);
  //   } else {
  //     const getindex = selectedStatus.indexOf(event.target.value);
  //     selectedStatus.splice(getindex, 1);
  //   }
  //   filterCheckboxData(selectedStatus);
  //   setSelectedStatus([...selectedStatus]);
  // };
  //state to show loader
  const [showLoader, setShowLoader] = useState(true);

  const handleShowLoader = (active: boolean) => {
    setShowLoader(active);
  };

  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 2,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <Box
        className={styles.nftLanding}
        p={4}
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          paddingTop: 0,
          // "@media (max-width: 600px)": {
          //   padding: 2,
          // },
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader nftHeader={true} />
        <Box
          sx={{
            "@media (max-width: 600px)": {
              padding: "0% 10px",
            },
          }}
        >
          <Box
            sx={{ display: "flex", marginBottom: "10px", cursor: "pointer" }}
          >
            <ArrowBackIosIcon onClick={() => handleBack()} />
            <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
          </Box>
          <Box sx={{ mb: "40px" }}>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                ...circleStyle,
                top: { xl: "20%" },
                right: "-0px",
                transform: "translateY(-50%)",
                width: "350px", // Adjusted width
                height: "200px", // Adjusted height
                borderRadius: "50%", // Ensures it remains a perfect circle
                background: "#9D00FF",
                // background: "red",
                filter: "blur(140px)",
                backdropFilter: "blur(140px)",
                WebkitBackdropFilter: "blur(140px)",
                overflow: "hidden",
                "@media (min-width: 1721px)": {
                  width: "450px", // Adjusted width
                  height: "400px", // Adjusted height
                  top: { xl: "50%" },
                  left: "-20%",
                  filter: "blur(160px)",
                  backdropFilter: "blur(160px)",
                  WebkitBackdropFilter: "blur(160px)",
                },
              }}
            ></Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "23px",
                  fontWeight: "500",
                  fontFamily: "outfit",
                  marginBottom: "10px",
                }}
              >
                Marketplace
              </Typography>
              <Box
                sx={{
                  padding: "0 12px 0 0px",
                  alignItems: "center",
                  display: { md: "flex", sm: "flex", xs: "none" },
                  marginBottom: "20px",
                  width: "260px",
                  float: "right",
                  border: "1px solid white",
                  borderRadius: "10px",
                }}
              >
                <TextField
                  type="text"
                  name="serach"
                  placeholder="Search"
                  inputProps={{
                    style: { color: "#fff", padding: "10px" },
                  }}
                  sx={{
                    width: "100%",
                    background: "transparent",
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                  }}
                  onChange={(e) => handleNftSearch(e.target.value)}
                />
                <SearchIcon
                  style={{
                    cursor: "pointer",
                    color: "#fff",
                  }}
                />
              </Box>
            </Box>
            <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={2}>
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    ...circleStyle,
                    top: { xl: "50%" },
                    left: "-22%",
                    transform: "translateY(-50%)",
                    width: "350px", // Adjusted width
                    height: "200px", // Adjusted height
                    borderRadius: "50%", // Ensures it remains a perfect circle
                    background: "#9D00FF",
                    // background: "red",
                    filter: "blur(140px)",
                    backdropFilter: "blur(140px)",
                    WebkitBackdropFilter: "blur(140px)",
                    overflow: "hidden",
                    "@media (min-width: 1721px)": {
                      width: "450px", // Adjusted width
                      height: "400px", // Adjusted height
                      top: { xl: "50%" },
                      left: "-20%",
                      filter: "blur(160px)",
                      backdropFilter: "blur(160px)",
                      WebkitBackdropFilter: "blur(160px)",
                    },
                  }}
                ></Box>
                {isMobile ? (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",

                        padding: "0 12px 0 0px",
                        alignItems: "center",

                        border: "1px solid #979595",
                        borderRadius: "10px",
                      }}
                    >
                      <TextField
                        type="text"
                        name="serach"
                        placeholder="Search"
                        inputProps={{
                          style: { color: "#fff", padding: "10px" },
                        }}
                        sx={{
                          width: "100%",
                        }}
                        onChange={(e) => handleNftSearch(e.target.value)}
                      />
                      <SearchIcon
                        style={{
                          cursor: "pointer",
                          color: "#979595",
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      background: "rgba(242,242,242,0.2)",
                      borderRadius: "10px",
                    }}
                  >
                    <Box>
                      <Box sx={{ p: "22px", pb: "0" }}>
                        {/* filter started */}
                        {/* <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "outfit",
                          }}
                        >
                          FILTER BY
                        </Typography> */}
                        {/* <Box
                          sx={{
                            display: "flex",

                            padding: "0 12px 0 0px",
                            alignItems: "center",

                            marginTop: "20px",

                            border: "1px solid white",
                            borderRadius: "10px",
                          }}
                        >
                          <TextField
                            type="text"
                            name="serach"
                            placeholder="Search"
                            inputProps={{
                              style: { color: "#fff", padding: "10px" },
                            }}
                            sx={{
                              width: "100%",
                            }}
                            onChange={(e) => handleNftSearch(e.target.value)}
                          />
                          <SearchIcon
                            style={{
                              cursor: "pointer",
                              color: "#fff",
                            }}
                          />
                        </Box> */}
                        {/* filter ended */}
                        {/* <Box
                      sx={{
                        ...borderGradientBox,
                        display: "flex",
                        padding: "0 12px",
                        alignItems: "center",
                        borderRadius: "30px",
                      }}
                    >
                      <TextField
                        type="text"
                        name="serach"
                        placeholder="Search"
                        inputProps={{ style: { color: "#777" } }}
                        required
                        sx={{
                          //   border: "0px solid none",
                          color: "#828282",
                          width: "100%",
                        }}
                      />
                      <SearchIcon
                        style={{
                          cursor: "pointer",
                          color: "#7C7C7C",
                        }}
                      />
                    </Box> */}
                        <Accordion
                          defaultExpanded
                          sx={{
                            background: "none",
                            boxShadow: "none",
                            color: "white",
                            fontFamily: "outfit",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "white" }} />
                            }
                            aria-controls="category"
                            id="category"
                          >
                            <Typography
                              sx={{
                                color: "white",
                                fontFamily: "outfit",
                                fontSize: "20px",
                                fontWeight: "700px",
                                marginRight: "2px",
                              }}
                            >
                              {" "}
                              Category
                            </Typography>
                            <Box
                              style={{
                                width: "max-content",
                                height: "100%",
                                background:
                                  "linear-gradient(102deg, #FF990D 0%, #9D00FF 100%)",
                                borderRadius: "32px",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#F2F2F2",
                                  fontSize: "8px",
                                  fontFamily: "Outfit",
                                  padding: "4px",
                                  position: "relative",
                                  zIndex: "1",
                                }}
                              >
                                Coming Soon
                              </Typography>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background: "rgba(255, 255, 255, 0.1)",
                                  transform: "skewY(-45deg)",
                                  zIndex: "0",
                                }}
                              />
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              // onChange={handleCategoryChnage}
                            >
                              <FormControlLabel
                                value="art"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Radio
                                    icon={
                                      <img
                                        src={inactiveRadioButton}
alt=""
                                        draggable="false"
                                        height="24px"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={CheckCircleRoundedIcon1}
                                        alt=""
                                        draggable="false"
                                        height="24px"
                                      />
                                    }
                                    sx={{
                                      color: "#9D00FF",
                                      fontFamily: "outfit",
                                    }}
                                  />
                                }
                                label="Art"
                              />
                              <FormControlLabel
                                value="gaming"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Radio
                                    sx={{
                                      color: "#9D00FF",
                                      fontFamily: "outfit",
                                    }}
                                    icon={
                                      <img
                                        src={inactiveRadioButton}
                                    alt=""
                                        draggable="false"
                                        height="24px"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={CheckCircleRoundedIcon1}
                                        alt=""
                                        height="24px"
                                        draggable="false"
                                      />
                                    }
                                  />
                                }
                                label="Gaming"
                              />
                              <FormControlLabel
                                value="music"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Radio
                                    sx={{
                                      color: "#9D00FF",
                                    }}
                                    icon={
                                      <img
                                        src={inactiveRadioButton}
                                        draggable="false"
                                        alt=""
                                        height="24px"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={CheckCircleRoundedIcon1}
                                        draggable="false"
                                        alt=""
                                        height="24px"
                                      />
                                    }
                                  />
                                }
                                label="Music"
                              />
                              <FormControlLabel
                                value="vedio"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Radio
                                    sx={{
                                      color: "#9D00FF",
                                    }}
                                    icon={
                                      <img
                                        src={inactiveRadioButton}
                                        alt=""
                                        draggable="false"
                                        height="24px"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                      alt=""
                                        src={CheckCircleRoundedIcon1}
                                        height="24px"
                                        draggable="false"
                                      />
                                    }
                                  />
                                }
                                label="Video"
                              />
                              <FormControlLabel
                                value="digitalart"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Radio
                                    sx={{
                                      color: "#9D00FF",
                                    }}
                                    icon={
                                      <img
                                      alt=""
                                        src={inactiveRadioButton}
                                        height="24px"
                                        draggable="false"
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={CheckCircleRoundedIcon1}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                  />
                                }
                                label="Digital Art"
                              />
                              <FormControlLabel
                                value="painting"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Radio
                                    sx={{
                                      color: "#9D00FF",
                                    }}
                                    icon={
                                      <img
                                        src={inactiveRadioButton}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={CheckCircleRoundedIcon1}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                  />
                                }
                                label="Painting"
                              />
                              <FormControlLabel
                                value="ai"
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Radio
                                    sx={{
                                      color: "#9D00FF",
                                    }}
                                    icon={
                                      <img
                                        src={inactiveRadioButton}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={CheckCircleRoundedIcon1}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                  />
                                }
                                label="AI"
                              />
                            </RadioGroup>
                          </AccordionDetails>
                          {/* <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions> */}
                        </Accordion>
                      </Box>

                      <hr style={{ borderWidth: "0.5px" }} />
                      <Box sx={{ p: "22px", pb: "0" }}>
                        <Accordion
                          defaultExpanded
                          sx={{
                            background: "none",
                            boxShadow: "none",
                            color: "white",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "white" }} />
                            }
                            aria-controls="status"
                            id="status"
                          >
                            <Typography
                              sx={{
                                color: "white",
                                fontFamily: "outfit",
                                fontSize: "20px",
                                fontWeight: "700px",
                                marginRight: "2px",
                              }}
                            >
                              {" "}
                              Status
                            </Typography>
                            <Box
                              style={{
                                width: "max-content",
                                height: "100%",
                                background:
                                  "linear-gradient(102deg, #FF990D 0%, #9D00FF 100%)",
                                borderRadius: "32px",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#F2F2F2",
                                  fontSize: "8px",
                                  fontFamily: "Outfit",
                                  padding: "4px",
                                  position: "relative",
                                  zIndex: "1",
                                }}
                              >
                                Coming Soon
                              </Typography>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background: "rgba(255, 255, 255, 0.1)",
                                  transform: "skewY(-45deg)",
                                  zIndex: "0",
                                }}
                              />
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup>
                              <FormControlLabel
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Checkbox
                                    // onChange={(e) => handleStatusChnage(e)}
                                    icon={
                                      <img
                                        src={checkboxInactive}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={checkboxActive}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    value="buyNow"
                                  />
                                }
                                label="Buy Now"
                              />
                              <FormControlLabel
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Checkbox
                                    // onChange={(e) => handleStatusChnage(e)}
                                    icon={
                                      <img
                                        src={checkboxInactive}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={checkboxActive}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    value="inAuction"
                                  />
                                }
                                label="In Auction"
                              />
                              <FormControlLabel
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Checkbox
                                    // onChange={(e) => handleStatusChnage(e)}
                                    icon={
                                      <img
                                        src={checkboxInactive}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={checkboxActive}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    value="sell"
                                  />
                                }
                                label="Looking to Sell"
                              />
                              <FormControlLabel
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                control={
                                  <Checkbox
                                    // onChange={(e) => handleStatusChnage(e)}
                                    icon={
                                      <img
                                        src={checkboxInactive}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={checkboxActive}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    value="offers"
                                  />
                                }
                                label="Has Offers"
                              />
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>
                      </Box>

                      <hr style={{ borderWidth: "0.5px" }} />
                      <Box sx={{ p: "22px", pb: "0" }}>
                        <Accordion
                          defaultExpanded
                          sx={{
                            background: "none",
                            boxShadow: "none",
                            color: "white",
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon sx={{ color: "white" }} />
                            }
                            aria-controls="type"
                            id="type"
                          >
                            <Typography
                              sx={{
                                color: "white",
                                fontFamily: "outfit",
                                fontSize: "20px",
                                fontWeight: "700px",
                                marginRight: "2px",
                              }}
                            >
                              {" "}
                              Price
                            </Typography>
                            <Box
                              style={{
                                width: "max-content",
                                height: "100%",
                                background:
                                  "linear-gradient(102deg, #FF990D 0%, #9D00FF 100%)",
                                borderRadius: "32px",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#F2F2F2",
                                  fontSize: "8px",
                                  fontFamily: "Outfit",
                                  padding: "4px",
                                  position: "relative",
                                  zIndex: "1",
                                }}
                              >
                                Coming Soon
                              </Typography>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  right: "0",
                                  bottom: "0",
                                  background: "rgba(255, 255, 255, 0.1)",
                                  transform: "skewY(-45deg)",
                                  zIndex: "0",
                                }}
                              />
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              // onChange={handleTypeChange}
                            >
                              <FormControlLabel
                                sx={{
                                  "& .MuiFormControlLabel-label": {
                                    fontFamily: "outfit",
                                  },
                                }}
                                value="ERC1155"
                                control={
                                  <Radio
                                    sx={{
                                      color: "#9D00FF",
                                    }}
                                    icon={
                                      <img
                                        src={inactiveRadioButton}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                    checkedIcon={
                                      <img
                                        src={CheckCircleRoundedIcon1}
                                        height="24px"
                                        draggable="false"
                                        alt=""
                                      />
                                    }
                                  />
                                }
                                label="ERC1155"
                              />
                            </RadioGroup>
                          </AccordionDetails>
                        
                        </Accordion>
                      </Box>
                     
                    </Box>
                  </Box>
                )}
              </Grid>  
              <Grid item xs={12} md={8} lg={10} className="1">
                
                {nftList.length == 0 ? (<p>No NFT Exist</p>) : (
                  <>
                   {console.log("inside else part")}
                    <Box  className="2"
                      sx={{
                        display: "grid",
                        clear: "both",
                        gridTemplateColumns: {
                          xs: "repeat(auto-fit, 100%)",
                          md: "repeat(auto-fit, 180px)",
                          lg: "repeat(auto-fit, 232px)",
                        },
                        rowGap: "30px",
                        columnGap: "30px",
                        marginLeft: { xs: "0", md: 0, lg: 0 },
                      }}

                      
                    >
                      {nftList.length > 0 &&   <NftCollectionLiveCard collectionList={nftList} />}
                    
                    </Box>
                  </>
                )}

                
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              ...circleStyle,
              bottom: { xl: "0%" },
              left: "45%",
              transform: "translateY(-50%)",
              width: "350px", // Adjusted width
              height: "200px", // Adjusted height
              borderRadius: "50%", // Ensures it remains a perfect circle
              background: "#9D00FF",
              // background: "red",
              filter: "blur(140px)",
              backdropFilter: "blur(140px)",
              WebkitBackdropFilter: "blur(140px)",
              overflow: "hidden",
              "@media (min-width: 1721px)": {
                width: "450px", // Adjusted width
                height: "400px", // Adjusted height
                top: { xl: "50%" },
                left: "-20%",
                filter: "blur(160px)",
                backdropFilter: "blur(160px)",
                WebkitBackdropFilter: "blur(160px)",
              },
            }}
          ></Box>
        </Box>
      </Box>
      <LandingPageFooter />
    </>
  );
};

export default NftOverview;
