import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InfoIcon from "@mui/icons-material/Info";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";

type Anchor = "right";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === "right" ? 250 : "auto",
        backgroundColor: "#1A0E1C", // Set background color here
        color: "white", // Set text color to contrast with the background
        height: "100%", // Ensure full height
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between", // Align items at the beginning and end
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["About Us", "Support", "Blog"].map((text, index) => (
          // Exclude "Contacts" and "Career" from the list
          !(text === "Contacts" || text === "Career") && (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ color: "white" }}>
                  {index % 5 === 0 ? <InfoIcon /> : null}
                  {index % 5 === 1 ? <BusinessCenterIcon /> : null}
                  {index % 5 === 2 ? <PhoneInTalkIcon /> : null}
                  {index % 5 === 3 ? <ContactSupportIcon /> : null}
                  {index % 5 === 4 ? <SpaceDashboardIcon /> : null}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
