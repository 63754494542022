import React from "react";
import { styled } from "@mui/system";
import { Typography, Grid, Box } from "@mui/material";
import facebooknew1 from "../../assets/socialhandles/fb.svg";
import Telegramnnew1 from "../../assets/socialhandles/telegram.svg";
import Twitternew1 from "../../assets/socialhandles/twitter.svg";
import discordnew1 from "../../assets/socialhandles/discord.svg";
import instagramnew1 from "../../assets/socialhandles/insta.svg";
import mediumnew1 from "../../assets/socialhandles/medium.svg";
import pinnew1 from "../../assets/socialhandles/painterest.svg";
import quoranew1 from "../../assets/socialhandles/quora.svg";
import redditnew1 from "../../assets/socialhandles/reddit.svg";
import tiktoknew1 from "../../assets/socialhandles/tiktok.svg";
import whatsappnew1 from "../../assets/socialhandles/whatsapp.svg";
import youtubenew1 from "../../assets/socialhandles/youtube.svg";
import linkdin from "../../assets/socialhandles/linkdin.svg";
import coinmarketcap from "../../assets/socialhandles/capcoin.svg";


const SocialPlatform = styled(Grid)({
  cursor: "pointer",
  margin: "5px",
  width: "50px",
  height: "50px",
  backgroundColor: "#272727",
  borderRadius: "8px",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const Muisocial = () => {
  const socialPlatforms = [
    { icon: facebooknew1, link: "https://www.facebook.com/MetaVoeuxEcosystem" },
    { icon: instagramnew1, link: "https://instagram.com/metavoeux/" },
    { icon: Telegramnnew1, link: "https://t.me/metavoeux" },
    { icon: linkdin, link: "https://www.linkedin.com/company/meta-voeux/" },
    { icon: youtubenew1, link: "https://youtube.com/@metavoeux/videos" },
    { icon: Twitternew1, link: "https://twitter.com/MetaVoeux" },
    { icon: discordnew1, link: "https://discord.gg/YrC7npAQ53" },
    { icon: redditnew1, link: "https://www.reddit.com/" },
    { icon: tiktoknew1, link: "https://tiktok.com/@metavoeuxecosystem" },
    { icon: quoranew1, link: "https://quora.com/profile/Metavoeux" },
    { icon: mediumnew1, link: "https://medium.com/@metavoeuxit" },
    {
      icon: whatsappnew1,
      link: "https://whatsapp.com/channel/0029Va48rBCInlqIjvTfTs0x",
    },
    { icon: pinnew1, link: "https://pinterest.com/metavoeux" },

    {
      icon: coinmarketcap,
      link: "https://coinmarketcap.com/community/profile/metavoeux/",
    },
  ];

  return (
    <>
      <Typography
        variant="h4"
        component="p"
        sx={{
          // marginBottom: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "32px",
          fontWeight: "500",
          mt: 5,
          mb: 4,
          // typography: { xs: "h5", sm: "h4" },
          textAlign: { xs: "center", md: "inherit" },
          fontFamily: "outfit",
        }}
      >
        Our Social Handles
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // ml: {
          //   sm: "9rem",
          //   md: "1rem",
          // },
          mb: 5,
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          lg={5.5}
          sx={{
            // border:"1px solid yellow",
            // mb: 1,\
            display: "grid",
    gridTemplateColumns:{md:"repeat(5,88px)",lg:"repeat(7,88px)",sm:"repeat(5,108px)",xs:"repeat(3,88px)"}, 
            maxHeight: "100%",
            // width: {
            //   xs: "100%",
            //   md: "45%",
            //   lg: "35%",
            //   xl: "60%",
            // },
            "@media (min-width: 1721px)": {
              width: "35%",
            },
          }}
        >
          {socialPlatforms.map((platform, index) => (
            <Grid
              item
             
              sx={{ display: "flex", background: "transparent",width:"100%" }}
            >
              <SocialPlatform
                key={index}
                onClick={() => {
                  window.open(platform.link, "_blank");
                }}
                sx={{
                  height: {
                    xs: "70%",
                    md: "80%",
                    // lg:"60%"
                  },
                  width: {
                    xs: "70%",
                    md: "80%",
                    // lg:"60%"
                  },
                  background: "transparent",
                }}
              >
                <img
                  src={platform.icon}
                  alt="icon image"
                  height={"100%"}
                  width={"100%"}
                />
              </SocialPlatform>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Muisocial;
