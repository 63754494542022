import React from "react";
import { Box, Stack, useMediaQuery } from "@mui/material";
import addOne from "../../assets/images/addv1.jpg";
import addTwo from "../../assets/images/addv2.jpg";

const LandingPageBanner = () => {
  console.log("addOne", addOne);
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Stack
      // marginBottom={xs:2,md:10}
      direction={{
        xs: "column",
        md: "row",
      }}
      spacing={{
        xs: 1,
        md: 4,
      }}
      padding={{
        xs: 2,
        md: 4,
      }}
      pb={{
        xs: 2,
        md: 0,
      }}
      sx={{
        marginBotttom: { xs: 2, md: 10 },
        position:"relative",
        zIndex:1,
        "@media (min-width: 1721px)": {
          padding: "0% 10% 0% 10%",
        },
      }}
    >
      <Box width={"100%"} sx={{ height: { xs: "100%", md: "90%" } }}>
        <img
          src={isMobile ? addTwo : addOne}
          alt=""
          width={"100%"}
          style={{ borderRadius: "14px" }}
        />
      </Box>
      <Box width={"100%"} sx={{ height: { xs: "100%", md: "90%" } }}>
        <img
          src={isMobile ? addOne : addTwo}
          alt=""
          width={"100%"}
          style={{ borderRadius: "14px" }}
        />
      </Box>
    </Stack>
  );
};

export default LandingPageBanner;
