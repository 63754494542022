import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language:"en",
  closewallet:false
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setlanguage: (state, action) => {
      state.language = action.payload;
      
    },
    setclosewallet: (state, action) => {
      state.closewallet = action.payload;
      
    },
    
  },
});

export const { setlanguage ,setclosewallet} = languageSlice.actions;

export default languageSlice.reducer;