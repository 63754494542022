import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import RadioiActive from "../../assets/Createnft/orangeRadioActive.svg";

const NftLanguage = () => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "32px",
          fontFamily: "outfit",
          fontWeight: 700,
          marginBottom: "1rem",
        }}
      >
        Language
      </Typography>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="English"
        name="radio-buttons-group"
      >
        <FormControlLabel
          value="English"
          control={
            <Radio
              sx={{
                color: "#FF990D",
                fontSize: "20px",
              }}
              checkedIcon={<img src={RadioiActive} height="25px" />}
            />
          }
          label="English"
        />
        <FormControlLabel
          value="gaming"
          control={
            <Radio
              sx={{
                color: "#FF990D",
                fontSize: "20px",
              }}
              checkedIcon={<img src={RadioiActive} height="25px" />}
            />
          }
          label="Español (España)"
        />
        <FormControlLabel
          value="music"
          control={
            <Radio
              sx={{
                color: "#FF990D",
                fontSize: "20px",
              }}
              checkedIcon={<img src={RadioiActive} height="25px" />}
            />
          }
          label="Français (France)"
        />
        <FormControlLabel
          value="vedio"
          control={
            <Radio
              sx={{
                color: "#FF990D",
                fontSize: "20px",
              }}
              checkedIcon={<img src={RadioiActive} height="25px" />}
            />
          }
          label="عَرَبِيّ, ʿarabīc"
        />
        <FormControlLabel
          value="digitalart"
          control={
            <Radio
              sx={{
                color: "#FF990D",
                fontSize: "20px",
              }}
              checkedIcon={<img src={RadioiActive} height="25px" />}
            />
          }
          label="日本語"
        />
        <FormControlLabel
          value="painting"
          control={
            <Radio
              sx={{
                color: "#FF990D",
                fontSize: "20px",
              }}
              checkedIcon={<img src={RadioiActive} height="25px" />}
            />
          }
          label="Türkçe"
        />
        <FormControlLabel
          value="ai"
          control={
            <Radio
              sx={{
                color: "#FF990D",
                fontSize: "20px",
              }}
              checkedIcon={<img src={RadioiActive} height="25px" />}
            />
          }
          label="中文（中国）"
        />
      </RadioGroup>
    </Box>
  );
};
export default NftLanguage;
