import React, { useState, ChangeEvent, MouseEvent } from "react";
import { Typography, Box, Button, useMediaQuery } from "@mui/material";
import walleticon from "../../assets/images/Bitcoin Wallet.png";
import Togglemui from "./Togglemui";
import Google from "../../assets/svgs/Google.svg";
import Muiwallet from "./Muiwallet";
import GoogleLoginComponent from "../googleLogin/GoogleLoginComponent";
import { useDispatch } from "react-redux";

import mobilewalletimage from "../../assets/images/Bitcoin Walletmobile.png";

interface MyMuiWalletConnectProps {
  handleClose?: () => void;
  buyConnect?: Boolean;
  handleRedirect?: () => void;
  showToastMessageClick?:(text:string)=> void
}

const MuiWalletConnect: React.FC<MyMuiWalletConnectProps> = ({
  handleClose,
  buyConnect,
  handleRedirect,
  showToastMessageClick
}) => {
  const dispatch = useDispatch();
  console.log("showToastMessageClick",showToastMessageClick)

  const [checkButton, setcheckButton] = useState("");
  const [email, setEmail] = useState("");

  const handleEmailChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setEmail(event.target.value);
  };

  const handleConnectClick = (
    event: MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    // Implement your connect logic here using the 'email' state
    console.log("Connecting...", email);
  };
  const socialMediaIcons = [
    {
      id: "google",
      icon: Google,
      link: "https://www.google.com/",
      label: "Google",
    },
    // {
    //   id: "facebook",
    //   icon: fb,
    //   link: "https://facebook.com/",
    //   label: "Facebook",
    // },
    // {
    //   id: "twitter",
    //   icon: twitter,
    //   link: "https://twitter.com/",
    //   label: "Twitter",
    // },
  ];

  const [isClose, setisClose] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const isCustomWidth = useMediaQuery(
    "(min-width: 601px) and (max-width: 710px)"
  );

  return (
    <>
      {/* mobile */}

      {/* custom width */}
      {isCustomWidth ? (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              background:
                "linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%)",
              borderRadius: "24px 0px 0px 24px",
              height: 364,
              width: "40%",
              // padding: "1rem",
              pt: 5.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={walleticon} alt="wallet" />
            </Box>
            <Typography
              sx={{
                mt: { lg: 4 },
                fontFamily: "outfit",
                color: "white",
                fontSize: "18px",
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              Connect Your Wallet to Metavoeux Ecosystem
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#272727",
              borderRadius: "0px 24px 24px 0px",
              height: "364px",
              width: "60%",
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                position: "absolute",
                right: "0px",
              }}
            >
              {isClose && (
                <Button
                  sx={{ color: "white", fontSize: "16px" }}
                  onClick={handleClose}
                >
                  X
                </Button>
              )}
            </Box>

            <Typography
              sx={{
                textAlign: "left",
                fontSize: "16px",
                color: "#fff",
                fontFamily: "outfit",
                mb: 2,
              }}
            >
              {" "}
              Connect Wallet
            </Typography>
            {buyConnect != true && (
              <Box>
                <Togglemui
                  setcheckButton={setcheckButton}
                  checkButton={checkButton}
                />
              </Box>
            )}
            {buyConnect == true ? (
              <Muiwallet
                onClose={handleClose}
                handleRedirect={handleRedirect}
                showToastMessageClick={showToastMessageClick}
              />
            ) : checkButton === "Email" ? (
              <GoogleLoginComponent />
            ) : (
              // <>
              //  <TextField
              //  fullWidth
              // sx={{
              //   marginTop: "15px",
              //   borderRadius: "12px",
              //   // width: "355px",
              //   height: "47px",
              //   "& input": {
              //     outline: "none",
              //     border: "0.915px solid #9e9e9e",
              //     borderRadius: "11.301px",
              //     color:"white"
              //   },
              //   "& fieldset": {
              //     border: "none",
              //   },
              // }}
              // type="email"
              // id="email"
              // value={email}
              // onChange={handleEmailChange}
              // placeholder="Enter your email"
              // variant="outlined"
              // />

              <Box sx={{ marginTop: "10px" }}>
                {/* <Button
            sx={{
              
              width: "348px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "12px",
              fontFamily:"outfit",
              border: "1px solid #FF990D",
              backgroundColor: "#272727",
              margin: "auto",
              color: "white",
              marginTop: "15px",
              ":hover":{
                background:"orange"
              }
            }}
          >
            Continue
          </Button> */}
                {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                borderBottom: "1px solid #fff",
                width: "40%",
                margin: "1.5rem",
                // marginTop: "15px",
              }}
            ></Typography>
            <Typography sx={{ fontSize: "18px" , color:"#fff", fontFamily:"outfit"}}>or</Typography>
            <Typography
              variant="body1"
              sx={{
                borderBottom: "1px solid #fff",
                width: "40%",
                margin: "1.5rem",
                // marginTop: "15px",
              }}
            ></Typography>
          </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* {socialMediaIcons.map((socialMedia) => (
                <a
                  key={socialMedia.id}
                  href={socialMedia.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={socialMedia.icon}
                    alt={`${socialMedia.label} Icon`}
                    width="40px"
                    height="40px"
                    onClick={() => console.log(`${socialMedia.label} Clicked`)}
                    style={{ cursor: "pointer", margin: "10px" }}
                  />
                </a>
              ))} */}

                  <Muiwallet
                    onClose={handleClose}
                    handleRedirect={handleRedirect}
                    showToastMessageClick={showToastMessageClick}
                  />
                </Box>
              </Box>
              // </>
            )}
          </Box>
        </Box>
      ) : isMobile ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              background:
                "linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%)",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              height: 236,
              width: "100%",
              // padding: "1rem",
              // pt: 5.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
              }}
            >
              {isClose && (
                <Button
                  sx={{ color: "white", fontWeight: "700", fontSize: "20px" }}
                  onClick={handleClose}
                >
                  X
                </Button>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={mobilewalletimage} alt="wallet" />
            </Box>
            <Typography
              sx={{
                fontFamily: "outfit",
                color: "white",
                fontSize: "18px",
                textDecoration: "none",
                textAlign: "center",
                marginBottom: "10px",
                lineHeight: "20px",
                marginTop: "10px",
              }}
            >
              Connect Your Wallet to <br />
              Metavoeux Ecosystem
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#272727",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              height: 320,
              width: "100%",
              // padding: "1rem",
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                color: "#fff",
                fontFamily: "outfit",
                mb: 2,
                mt: 2,
              }}
            >
              {" "}
              Connect Wallet
            </Typography>
            {buyConnect != true && (
              <Box sx={{ width: "95%", margin: "0 auto" }}>
                <Togglemui
                  setcheckButton={setcheckButton}
                  checkButton={checkButton}
                />
              </Box>
            )}
            {buyConnect == true ? (
              <Muiwallet
                onClose={handleClose}
                handleRedirect={handleRedirect}
                showToastMessageClick={showToastMessageClick}
              />
            ) : checkButton === "Email" ? (
              <GoogleLoginComponent />
            ) : (
              // <>
              //  <TextField
              //  fullWidth
              // sx={{
              //   marginTop: "15px",
              //   borderRadius: "12px",
              //   // width: "355px",
              //   height: "47px",
              //   "& input": {
              //     outline: "none",
              //     border: "0.915px solid #9e9e9e",
              //     borderRadius: "11.301px",
              //     color:"white"
              //   },
              //   "& fieldset": {
              //     border: "none",
              //   },
              // }}
              // type="email"
              // id="email"
              // value={email}
              // onChange={handleEmailChange}
              // placeholder="Enter your email"
              // variant="outlined"
              // />

              <Box sx={{ marginTop: "10px" }}>
                {/* <Button
        sx={{
          
          width: "348px",
          height: "55px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "12px",
          fontFamily:"outfit",
          border: "1px solid #FF990D",
          backgroundColor: "#272727",
          margin: "auto",
          color: "white",
          marginTop: "15px",
          ":hover":{
            background:"orange"
          }
        }}
      >
        Continue
      </Button> */}
                {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            borderBottom: "1px solid #fff",
            width: "40%",
            margin: "1.5rem",
            // marginTop: "15px",
          }}
        ></Typography>
        <Typography sx={{ fontSize: "18px" , color:"#fff", fontFamily:"outfit"}}>or</Typography>
        <Typography
          variant="body1"
          sx={{
            borderBottom: "1px solid #fff",
            width: "40%",
            margin: "1.5rem",
            // marginTop: "15px",
          }}
        ></Typography>
      </Box> */}
                <Box
                  className="navya"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* {socialMediaIcons.map((socialMedia) => (
            <a
              key={socialMedia.id}
              href={socialMedia.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={socialMedia.icon}
                alt={`${socialMedia.label} Icon`}
                width="40px"
                height="40px"
                onClick={() => console.log(`${socialMedia.label} Clicked`)}
                style={{ cursor: "pointer", margin: "10px" }}
              />
            </a>
          ))} */}

                  <Muiwallet
                    onClose={handleClose}
                    handleRedirect={handleRedirect}
                    showToastMessageClick={showToastMessageClick}
                  />
                </Box>
              </Box>
              // </>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              background:
                "linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%)",
              borderRadius: "24px 0px 0px 24px",
              height: 400,
              width: 381,
              padding: "1rem",
              pt: 5.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={walleticon} alt="wallet" />
            </Box>
            <Typography
              sx={{
                mt: { lg: 4 },
                fontFamily: "outfit",
                color: "white",
                fontSize: "23px",
                textDecoration: "none",
                textAlign: "center",
              }}
            >
              Connect Your Wallet to Metavoeux Ecosystem
            </Typography>
          </Box>
          <Box
            sx={{
              background: "#272727",
              borderRadius: "0px 24px 24px 0px",
              height: 400,
              width: 381,
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                position: "absolute",
                right: "0px",
              }}
            >
              {isClose && (
                <Button
                  sx={{ color: "white", fontSize: "20px" }}
                  onClick={handleClose}
                >
                  X
                </Button>
              )}
            </Box>

            <Typography
              sx={{
                textAlign: "left",
                fontSize: "26px",
                color: "#fff",
                fontFamily: "outfit",
                mb: 2,
              }}
            >
              {" "}
              Connect Wallet
            </Typography>
            {buyConnect != true && (
              <Box>
                <Togglemui
                  setcheckButton={setcheckButton}
                  checkButton={checkButton}
                />
              </Box>
            )}
            {buyConnect == true ? (
              <Muiwallet
                onClose={handleClose}
                handleRedirect={handleRedirect}
                showToastMessageClick={showToastMessageClick}
              />
            ) : checkButton === "Email" ? (
              <GoogleLoginComponent />
            ) : (
              // <>
              //  <TextField
              //  fullWidth
              // sx={{
              //   marginTop: "15px",
              //   borderRadius: "12px",
              //   // width: "355px",
              //   height: "47px",
              //   "& input": {
              //     outline: "none",
              //     border: "0.915px solid #9e9e9e",
              //     borderRadius: "11.301px",
              //     color:"white"
              //   },
              //   "& fieldset": {
              //     border: "none",
              //   },
              // }}
              // type="email"
              // id="email"
              // value={email}
              // onChange={handleEmailChange}
              // placeholder="Enter your email"
              // variant="outlined"
              // />

              <Box sx={{ marginTop: "10px" }}>
                {/* <Button
            sx={{
              
              width: "348px",
              height: "55px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "12px",
              fontFamily:"outfit",
              border: "1px solid #FF990D",
              backgroundColor: "#272727",
              margin: "auto",
              color: "white",
              marginTop: "15px",
              ":hover":{
                background:"orange"
              }
            }}
          >
            Continue
          </Button> */}
                {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                borderBottom: "1px solid #fff",
                width: "40%",
                margin: "1.5rem",
                // marginTop: "15px",
              }}
            ></Typography>
            <Typography sx={{ fontSize: "18px" , color:"#fff", fontFamily:"outfit"}}>or</Typography>
            <Typography
              variant="body1"
              sx={{
                borderBottom: "1px solid #fff",
                width: "40%",
                margin: "1.5rem",
                // marginTop: "15px",
              }}
            ></Typography>
          </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* {socialMediaIcons.map((socialMedia) => (
                <a
                  key={socialMedia.id}
                  href={socialMedia.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={socialMedia.icon}
                    alt={`${socialMedia.label} Icon`}
                    width="40px"
                    height="40px"
                    onClick={() => console.log(`${socialMedia.label} Clicked`)}
                    style={{ cursor: "pointer", margin: "10px" }}
                  />
                </a>
              ))} */}

                  <Muiwallet
                    onClose={handleClose}
                    handleRedirect={handleRedirect}
                    showToastMessageClick={showToastMessageClick}
                  />
                </Box>
              </Box>
              // </>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default MuiWalletConnect;
