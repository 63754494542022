import React, { useEffect, useState } from "react";
import { ClickAwayListener, Grid, Slide } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  Stack,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import menuIconFilled from "../../../assets/svgs/menuIconFilled.svg";
import logo from "../../../assets/svgs/metavoeux_logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import MenuIcon from "@mui/icons-material/Menu";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import nftmarketplaceicon from "../../../assets/tooltipservice/NFT Marketplace.svg";
import voeuxfarmingicon from "../../../assets/tooltipservice/Voeux Farming.svg";
import voeuxtokenicon from "../../../assets/tooltipservice/Voeux Token.svg";
import voeuxgameicon from "../../../assets/tooltipservice/Voeux Game.svg";
import voeuxpayicon from "../../../assets/tooltipservice/Voeux Pay.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import paymenticon from "../../../assets/voeuxpay/paymenticon.png";
import paymentinteicon from "../../../assets/voeuxpay/payinteicon.png";
import multicurrencyicon from "../../../assets/voeuxpay/multicurrencyicon.png";
import custoicon from "../../../assets/voeuxpay/custoicon.png";
import onlinebankicon from "../../../assets/voeuxpay/onlinebankicon.png";
import qricon from "../../../assets/voeuxpay/qricon.png";
import cardicon from "../../../assets/voeuxpay/cardicon.png";
import walleticon from "../../../assets/voeuxpay/walleticon.png";
import onrampicon from "../../../assets/voeuxpay/onrampicon.png";
import offrampicon from "../../../assets/voeuxpay/offrampicon.png";
import MenuAccordion from "./MenuAccordion";
import ForBusiness from "../ForBusiness";

type Anchor = "right";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const VoeuxPayHeader = ({
  nftHeader = false,
  handleContactUS = () => {},
  handleServices,
}: {
  nftHeader?: boolean;
  handleContactUS?: () => void;
  handleServices?: () => void;
}) => {
  
  const [state, setState] = React.useState({
    right: false,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);

  const [profileanchorEl, setprofileAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const profileOpen = Boolean(profileanchorEl);
  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const handleLogoClick = () => {
    navigate("/");
  };

  const [fullWidth, setFullWidth] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = () => {
    const newMaxWidth = isSmallScreen ? "sm" : "md";
    setMaxWidth(newMaxWidth);
  };

  const handleFullWidthChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFullWidth(event.target.checked);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenLanguage, setTooltipOpenLanguage] = useState(false);
  const [tooltipOpenCurrency, setTooltipOpenCurrency] = useState(false);
  const [current, setcurrent] = useState("");

  const handleTooltipClose = (data: any) => {
    if (data === "menu") {
      setTooltipOpen(false);
    } else if (data === "lang") {
      setTooltipOpenLanguage(false);
    } else if (data === "currency") {
      setTooltipOpenCurrency(false);
    }
  };
  const handleTooltipOpen = (data: any) => {
    if (data === "menu") {
      setTooltipOpen(true);
    } else if (data === "lang") {
      setTooltipOpenLanguage(true);
    } else if (data === "currency") {
      setTooltipOpenCurrency(true);
    }
  };

  const handleNavigateFromMenu = (data: any) => {
    console.log(data, "data");

    //  -----services session----

    if (data === "NFT Marketplace") {
      navigate("/nft");
    } else if (data === "voeux farming") {
      navigate("/voeuxfarming");
    } else if (data === "voeux money") {
      navigate("/voeuxmoney");
    } else if (data === "voeux token") {
      navigate("/voeuxtoken");
    } else if (data === "voeux game") {
      navigate("/voeuxGame");
      // window.location.href = "/voeuxGame";
    } else if (data === "voeux pay") {
      navigate("/voeuxpay");
      // window.location.href = "https://voeuxpay.com/";
    }

    // ----learn session-----
    else if (data === "About Us") {
      navigate("/about");
    } else if (data === "Careers") {
      navigate("/career");
    } else if (data === "Contact Us") {
      navigate("/contact");
    } else if (data === "FAQs") {
      navigate("/faq");
    } else if (data === "Blog") {
      navigate("/blog");
    } else if (data === "Settings") {
      navigate("/Settingsmenubar");
    }
  };

  const handleHover = (item: any) => {
    setcurrent(item);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const [toolopen, settoolOpen] = React.useState(false);

  const handleToolClose = () => {
    setOpen(false);
  };

  const handleToolOpen = () => {
    setOpen(true);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    // setIsOpen(!isOpen);
  };
  const isMobile = useMediaQuery("(max-width:830px)"); // Custom breakpoint for mobile (xs)
  const navigates = useNavigate();
  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === "right" ? 300 : "auto",
        backgroundColor: "#1A0E1C",
        color: "white",
        height: "200%",
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box>
        <List>
          <Box>
            <div
              style={{
                backgroundColor: "#1A0E1C",
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                position: "absolute",
                zIndex: 1,
                right: 15,
                top: 13,
              }}
            >
              <button
                onClick={toggleDrawer(anchor, false)}
                style={{
                  backgroundColor: "#1A0E1C",
                  color: "#fff",
                  border: "none",
                  fontSize: "20px",
                }}
              >
                X
              </button>
            </div>

            <Box marginTop={5}>
              <MenuAccordion />
            </Box>
          </Box>
        </List>
      </Box>
    </Box>
  );

  const dispatch = useDispatch();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const closedata = useSelector((state: any) => state.lang.closewallet);

  useEffect(() => {
    handleClose();
  }, [closedata]);

  const locationData = useLocation();

  useEffect(() => {
    if (locationData.pathname === "/nftmarketplace") {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        setOpen(true);
      }
    } else {
      setOpen(false); // Close the modal or handle it accordingly
    }
  }, [locationData.pathname]);

  const [openDialog, setOpenDialog] = useState(false);

  const handleButtonClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [showBusiness, setShowbusiness] = useState(false);

  const handleCloseBusinessDialog = () => {
    setShowbusiness(false);
  };
  return (
    <Box>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          borderBottom: "none",
          px: { md: 0 },
        }}
      >
        <Toolbar
          className="werdf"
          sx={{
            display: "flex",

            padding: "0px !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {/* firstbox */}
            <Box>
              {/* for showing metavoeux logo in mobile view */}
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ display: { xl: "none", sm: "flex", md: "none" }, px: 1 }}
              >
                <img src={logo} alt="" onClick={handleLogoClick} />
              </IconButton>
              <Typography
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
                sx={{
                  display: { xs: "none", md: "flex" },
                }}
              >
                <img
                  src={logo}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={handleLogoClick}
                />

                <BootstrapTooltip
                  open={tooltipOpen}
                  className="serviceTooltip"
                  onClose={() => handleTooltipClose("menu")}
                  title={
                    <Box sx={{ width: "auto", height: "183px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "60px",
                        }}
                      >
                        {/* FIRST COLUMN*/}
                        <Box
                          sx={{
                            flex: 1,
                            padding: "10px",
                            marginTop: "30px",
                          }}
                        >
                          <Stack>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",

                                  ":hover": {
                                    color: "orange",
                                    cursor: "pointer",
                                  },
                                }}
                                onClick={() =>
                                  handleNavigateFromMenu("NFT Marketplace")
                                }
                              >
                                <img src={nftmarketplaceicon} alt="pay" />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "21.94px",
                                    fontFamily: " Bebas Neue",
                                    fontWeight: "400px",
                                    // marginBottom: "10px",
                                  }}
                                >
                                  NFT Marketplace
                                </Typography>
                              </Box>
                            </Stack>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",

                                  ":hover": {
                                    color: "orange",
                                    cursor: "pointer",
                                  },
                                }}
                                onClick={() =>
                                  handleNavigateFromMenu("voeux farming")
                                }
                              >
                                <img src={voeuxfarmingicon} alt="pay" />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "21.94px",
                                    fontFamily: " Bebas Neue",
                                    fontWeight: "400px",
                                    // marginBottom: "10px",
                                  }}
                                >
                                  voeux farming
                                </Typography>
                              </Box>
                            </Stack>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",

                                  ":hover": {
                                    color: "orange",
                                    cursor: "pointer",
                                  },
                                }}
                                onClick={() =>
                                  handleNavigateFromMenu("voeux token")
                                }
                              >
                                <img src={voeuxtokenicon} alt="pay" />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "21.94px",
                                    fontFamily: " Bebas Neue",
                                    fontWeight: "400px",
                                    // marginBottom: "10px",
                                  }}
                                >
                                  Voeux token
                                </Typography>
                              </Box>
                            </Stack>
                          </Stack>
                        </Box>
                        {/* SECOND COLUMN */}
                        <Box
                          sx={{
                            flex: 1,
                            padding: "10px",
                            marginTop: "30px",
                          }}
                        >
                          <Stack direction="column">
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",

                                  ":hover": {
                                    color: "orange",
                                    cursor: "pointer",
                                  },
                                }}
                                onClick={() =>
                                  handleNavigateFromMenu("voeux game")
                                }
                              >
                                <img src={voeuxgameicon} alt="" />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "21.94px",
                                    fontFamily: " Bebas Neue",
                                    fontWeight: "400px",
                                    // marginBottom: "10px",
                                  }}
                                >
                                  voeux game
                                </Typography>
                              </Box>
                            </Stack>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "5px",

                                  ":hover": {
                                    color: "orange",
                                    cursor: "pointer",
                                  },
                                }}
                                onClick={() =>
                                  handleNavigateFromMenu("voeux pay")
                                }
                              >
                                <img src={voeuxpayicon} alt="" />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    fontSize: "21.94px",
                                    fontFamily: " Bebas Neue",
                                    fontWeight: "400px",
                                    // marginBottom: "10px",
                                  }}
                                >
                                  voeux pay
                                </Typography>
                              </Box>
                            </Stack>
                          </Stack>
                        </Box>
                      </Box>
                    </Box>
                  }
                  // arrow
                >
                  <IconButton onMouseEnter={() => handleTooltipOpen("menu")}>
                    <img src={menuIconFilled} alt="" height={"100%"} />
                  </IconButton>
                </BootstrapTooltip>
              </Typography>
            </Box>

            {/*voeuxpay services and login button session*/}
            <Box sx={{ display: "flex", columnGap: "10px" }}>
              {!isMobile && (
                <Box className="serviceTooltip">
                  <Box>
                    <Grid item>
                      <ClickAwayListener onClickAway={handleToolClose}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleToolClose}
                            open={open}
                            // disableFocusListener
                            // disableHoverListener
                            // disableTouchListener

                            title={
                              <Box sx={{ width: "auto", height: "253px" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "60px",
                                  }}
                                >
                                  {/* FIRST COLUMN*/}
                                  <Box
                                    sx={{
                                      flex: 1,
                                      padding: "10px",
                                      marginTop: "30px",
                                    }}
                                  >
                                    <Stack>
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",

                                            ":hover": {
                                              color: "orange",
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          <img src={paymenticon} alt="pay" />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              fontSize: "21.94px",
                                              fontFamily: " Bebas Neue",
                                              fontWeight: "400px",
                                              // marginBottom: "10px",
                                            }}
                                          >
                                            PAYMENT LINK
                                          </Typography>
                                        </Box>
                                      </Stack>
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",

                                            ":hover": {
                                              color: "orange",
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          <img
                                            src={paymentinteicon}
                                            alt="pay"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              fontSize: "21.94px",
                                              fontFamily: " Bebas Neue",
                                              fontWeight: "400px",
                                              // marginBottom: "10px",
                                            }}
                                          >
                                            PAYMENT INTEGRATION
                                          </Typography>
                                        </Box>
                                      </Stack>
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",

                                            ":hover": {
                                              color: "orange",
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          <img
                                            src={multicurrencyicon}
                                            alt="pay"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              fontSize: "21.94px",
                                              fontFamily: " Bebas Neue",
                                              fontWeight: "400px",
                                              // marginBottom: "10px",
                                            }}
                                          >
                                            MULTI CURRENCY PROCESSING
                                          </Typography>
                                        </Box>
                                      </Stack>
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "11px",

                                            ":hover": {
                                              color: "orange",
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          <img
                                            src={custoicon}
                                            alt="custoicon"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              fontSize: "21.94px",
                                              fontFamily: " Bebas Neue",
                                              fontWeight: "400px",
                                              // marginBottom: "10px",
                                            }}
                                          >
                                            CUSTOMIZED PAYMENT SOLUTION
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Stack>
                                  </Box>
                                  {/* SECOND COLUMN */}
                                  <Box
                                    sx={{
                                      flex: 1,
                                      padding: "10px",
                                      marginTop: "30px",
                                    }}
                                  >
                                    <Stack direction="column">
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",

                                            ":hover": {
                                              color: "orange",
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          <img src={walleticon} alt="" />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              fontSize: "21.94px",
                                              fontFamily: " Bebas Neue",
                                              fontWeight: "400px",
                                              // marginBottom: "10px",
                                            }}
                                          >
                                            E- WALLET
                                          </Typography>
                                        </Box>
                                      </Stack>
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",

                                            ":hover": {
                                              color: "orange",
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          <img src={cardicon} alt="" />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              fontSize: "21.94px",
                                              fontFamily: " Bebas Neue",
                                              fontWeight: "400px",
                                              // marginBottom: "10px",
                                            }}
                                          >
                                            VOEUX CARD
                                          </Typography>
                                        </Box>
                                      </Stack>
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            // height: "18px",
                                            // width: "18px",
                                            gap: "5px",
                                            ":hover": {
                                              color: "orange",
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          <img src={onlinebankicon} alt="pay" />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              fontSize: "21.94px",
                                              fontFamily: " Bebas Neue",
                                              fontWeight: "400px",
                                              // marginBottom: "10px",
                                            }}
                                          >
                                            ONLINE BANKING
                                          </Typography>
                                        </Box>
                                      </Stack>
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "5px",

                                            ":hover": {
                                              color: "orange",
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          <img src={qricon} alt="" />
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              whiteSpace: "nowrap",
                                              fontSize: "21.94px",
                                              fontFamily: " Bebas Neue",
                                              fontWeight: "400px",
                                              // marginBottom: "10px",
                                            }}
                                          >
                                            QR BANK TRANSFER
                                          </Typography>
                                        </Box>
                                      </Stack>
                                    </Stack>
                                  </Box>

                                  {/* THIRD COLUMN */}

                                  <Stack
                                    sx={{ padding: "10px", marginTop: "30px" }}
                                    direction="column"
                                  >
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: "5px",

                                          ":hover": {
                                            color: "orange",
                                            cursor: "pointer",
                                          },
                                        }}
                                      >
                                        <img src={onrampicon} alt="" />
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            whiteSpace: "nowrap",
                                            fontSize: "21.94px",
                                            fontFamily: " Bebas Neue",
                                            fontWeight: "400px",
                                            // marginBottom: "10px",
                                          }}
                                        >
                                          ON RAMP
                                        </Typography>
                                      </Box>
                                    </Stack>
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          alignItems: "center",

                                          ":hover": {
                                            color: "orange",
                                            cursor: "pointer",
                                          },
                                        }}
                                      >
                                        <img src={offrampicon} alt="" />
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            whiteSpace: "nowrap",
                                            fontSize: "21.94px",
                                            fontFamily: " Bebas Neue",
                                            fontWeight: "400px",
                                            // marginBottom: "10px",
                                          }}
                                        >
                                          Off- RAMP
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  </Stack>
                                </Box>
                              </Box>
                            }
                            arrow
                          >
                            <Button
                              // onClick={handleToolOpen}
                              onClick={() => {
                                if (handleServices) {
                                  handleServices();
                                } else {
                                  handleToolOpen();
                                }
                              }}
                            >
                              <IconButton
                                sx={{
                                  fontFamily: "Bebas Neue",
                                  fontSize: "18px",
                                  fontWeight: "400",
                                  color: "#fff",
                                }}
                                disableRipple
                                style={{ color: "#fff" }}
                              >
                                SERVICES
                                {!handleServices && <KeyboardArrowDownIcon />}
                              </IconButton>
                            </Button>
                          </Tooltip>

                          <Button
                            onClick={() => {
                              navigate("/voeuxpayabout");
                            }}
                            sx={{
                              fontFamily: "Bebas Neue ",
                              fontSize: "18px",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            ABOUT US
                          </Button>
                          <Button
                            sx={{
                              fontFamily: "Bebas Neue ",
                              fontSize: "18px",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                            onClick={() => setShowbusiness(true)}
                          >
                            BUSINESS
                          </Button>
                          <Button
                            sx={{
                              fontFamily: "Bebas Neue ",
                              fontSize: "18px",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            DEVELOPERS{" "}
                          </Button>
                        </div>
                      </ClickAwayListener>
                    </Grid>
                  </Box>
                </Box>
              )}
              {/* third box  */}
              {!isMobile && (
                <Box>
                  <Button
                    sx={{
                      border: " 0.875px solid var(--Linear, #FF990D)",
                      borderRadius: 3,
                      marginRight: "4px",
                      color: "white",
                      width: " 116px",
                      height: "44px",
                      fontFamily: "Bebas Neue",
                      fontSize: "18px",
                      fontWeight: "400px",
                    }}
                  >
                    LOGIN
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 3,
                      backgroundImage:
                        "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                      width: " 116px",
                      height: "44px",
                      fontFamily: "Bebas Neue",
                      fontSize: "18px",
                      fontWeight: "400px",
                      marginLeft: "10px",
                    }}
                    onClick={() => handleContactUS()}
                  >
                    REGISTER
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            {(["right"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={toggleDrawer(anchor, true)}
                  color="inherit"
                  sx={{ padding: "0px !important" }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  // onClose={() => toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Dialog
        open={showBusiness}
        TransitionComponent={Slide}
        keepMounted
        onClose={handleCloseBusinessDialog}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <ForBusiness onClose={handleCloseBusinessDialog} />
      </Dialog>
    </Box>
  );
};

export default VoeuxPayHeader;
