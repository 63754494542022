import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import bitcoinFarm from "../../../assets/images/BitcoinFarm.png";
import Investment from "../../../assets/svgs/Investment.svg";
import voeuxTokenLogo from "../../../assets/svgs/voeuxTokenLogo.svg";
import farmLogo from "../../../assets/svgs/farmLogo.svg";
import networkLogo from "../../../assets/svgs/networkLogo.svg";
import metavoeux from "../../../assets/svgs/metavoeux.svg";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import circleLogo from "../../../assets/svgs/metavoeuxCircle.svg"
// import circleLogo from "../../../assets/svgs/hometoken.svg"

const bull = (
    <Box
        component="span"
        sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
    >
        •
    </Box>
);

const styleMain = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#fff",
};

export default function OutlinedCard({ farms, handleStakeNow }: {
   
    farms: {
        name: string,

        description: string,

        investmentRequired: string,

        network: {
            icon: string,
            name: string,
        },
        token: {
            icon: string,
            name: string,
        },
        apy: any,

    }[], handleStakeNow: (id: number) => void
}) {
    const navigate = useNavigate();
    console.log("farms",farms)
    const card = (
        <React.Fragment>

        </React.Fragment>
    );

    return (
        <>
            {
                farms.map((farm, index) => (
                    < Grid item xs={12} sm={6} md={4} lg={3}  >
                        <Box onClick={() => handleStakeNow(index)}>
                            <Card variant="outlined" sx={{
                                background: "linear-gradient(88deg, rgb(42 42 42 / 67%), rgb(0 0 0 / 18%)),linear-gradient(266.86deg, #FF990D -3.12%, #9D00FF 93.15%)",
                                border:"0px ",
                                // background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),linear-gradient(266.86deg, #FF990D -3.12%, #9D00FF 93.15%)", border: "0px solid transparent",
                                borderRadius: {xs: "16px", sm: "16px", md: 4, lg: 4 }
                            }}>
                                <CardContent sx={{
                                    padding:"10px 16px"
                                }}>
                                    <Stack sx={{ ...styleMain }}>
                                        <Box sx={{ height: "30px", width: "30px", overflow: "hidden", borderRadius: "50%" }}>
                                            <img src={metavoeux} alt='' height="100%" width="100%" style={{ borderRadius: "50%" }} />
                                        </Box>
                                        <Typography variant="h6" sx={{ ml: 1, fontFamily: "outfit" }}>
                                            {farm.name}
                                        </Typography>
                                    </Stack>

                                    <Box sx={{  mt: 1 ,display:"flex",justifyContent:"flex-start"}} >

                                        <Stack direction="column" sx={{ ...styleMain , flexDirection:"column",alignItems:"flex-start",
                                        width:{xs:"40%",md:"170px"}
                                        }}>
                                            <Typography sx={{ fontFamily: "outfit", fontSize: "14px", color: "#ACACAC", }}>
                                                Chain
                                            </Typography>
                                            <Box sx={{ display: "flex", flexDirection: "row",  }}>

                                                <img src={networkLogo} alt='' height="18px" width="18px" />
                                                <Typography sx={{ fontFamily: "outfit",ml:"5px", fontSize: "14px", color: "#fff", }}>
                                                    {farm.network.name}
                                                </Typography>
                                            </Box>

                                        </Stack>

                                    <Stack direction="column" sx={{ ...styleMain, flexDirection:"column",alignItems:"flex-start" }}>
                                        <Typography sx={{ fontFamily: "outfit", fontSize: "14px", color: "#ACACAC", }}>
                                            Reward
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row", }}>

                                            <img src={circleLogo} alt='' height="18px" width="18px" /><Typography sx={{ fontFamily: "outfit", ml:"5px",fontSize: "14px", color: "#fff", }}>
                                                Voeux Token
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    </Box>
                                    <Box sx={{  mt: 1 ,display:"flex",justifyContent:"flex-start"}}>
                                    <Stack direction="column" sx={{ ...styleMain, flexDirection:"column",alignItems:"flex-start",
                                    width:{xs:"40%",md:"170px"}
                                     }}>
                                        <Typography sx={{ fontFamily: "outfit", fontSize: "14px", color: "#ACACAC", }}>
                                            APY
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row",}}>

                                            <Typography sx={{ fontFamily: "outfit", fontSize: "14px", color: "#fff", }}>
                                            {Number(farm.apy)}
                                            </Typography>
                                        </Box>
                                    </Stack>

                                    <Stack direction="column" sx={{ ...styleMain , flexDirection:"column",alignItems:"flex-start" }}>
                                        <Typography sx={{ fontFamily: "outfit", fontSize: "14px", color: "#ACACAC", }}>
                                            Invest
                                        </Typography>
                                        <Box sx={{ display: "flex", flexDirection: "row",  }}>

                                            <img src={circleLogo} alt='' height="18px" width="18px" /><Typography sx={{ fontFamily: "outfit",ml:"5px", fontSize: "14px", color: "#fff", }}>
                                                Voeux Token
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    </Box>
                                    {/* <Typography variant='subtitle1' sx={{ color: "#fff", my: 1,height: "80px",overflow: "hidden",fontFamily:"outfit" }} >
                    {farm.description}             </Typography> */}

                                    <Stack sx={{ ...styleMain, mt: 1, color: "#FF990D" }}>
                                        <Box sx={{ display: "flex", flexDirection: "row", height: "23px", width: "22px" }}>

                                            <img src={Investment} alt='' height="100%" width="100%" />
                                        </Box>
                                        <Typography variant="body2" sx={{  fontFamily: "outfit" }}>
                                            Investment Required : ${farm.investmentRequired}
                                        </Typography>
                                    </Stack>
                                </CardContent>
                                <Box sx={{ p: 2,pt:0 }}>

                                    <CardActions 
                                    // sx={{
                                    //     borderRadius: "10.507px", border: "0.931px solid var(--Linear, #fff)", display: "flex", justifyContent: "center", cursor: "pointer",
                                    //     color: "#fff",
                                    //     '&:hover': {
                                    //         background: "linear-gradient(102deg, #fff  7.51%,  #fff 86.21%) border-box",
                                    //         color: "black",
                                    //         border: "none",
                                    //     }
                                    // }}


                                    >
                                        <Button size="small" sx={{
                                             fontWeight: 500,
                                            textTransform: "capitalize",
                                            fontSize: 15, fontFamily: "outfit",
                                            borderRadius: "10.507px", border: "0.931px solid var(--Linear, #fff)", display: "flex", justifyContent: "center", cursor: "pointer",
                                            color: "#fff",width:"100%",
                                            '&:hover': {
                                                background: "linear-gradient(102deg, #fff  7.51%,  #fff 86.21%) border-box",
                                                color: "black",
                                                border: "none",
                                            }
                                        }}>Stake Now</Button>
                                    </CardActions>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                ))
            }
        </>
    );
}
