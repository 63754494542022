import {
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  IconButton,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";

import backimg from "../../../assets/voeuxpay/Back.svg";
import axios from "axios";
import { Environment } from "../../../environments";

function StepThree({
  currency,
  paymentMethoddata,
  getFormData,
  handleNext,
  handleBack,
  showToastErrorMessageClick,
  handleShowLoader
}: {
  currency: any;
  paymentMethoddata: any;
  getFormData:(data:any,paymentMethodName:string, serviceLogo:any) => void
  handleNext: () => void;
  handleBack: () => void;
  showToastErrorMessageClick:(text:string) => void,
  handleShowLoader:(show:any) => void
}) {
  console.log("currency", currency, paymentMethoddata);
  useEffect(() => {
    handleShowLoader(true)
    getFormDetails();
  }, [paymentMethoddata]);

  const [gatewayFarmDetails, setGatewayFarmDetails] = useState<any>({});
  const [formData, setFormData] = useState<any>({})
  const getFormDetails = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}form?currency=${currency}&services=${paymentMethoddata.serviceCode}`
      );
      const farmdetail = {
        farmdetail: response.data?.form,
      };
      setGatewayFarmDetails(farmdetail);
      formData.currency = currency 
      formData.gateway = paymentMethoddata.serviceCode 
      // formData.paymnetMethod = paymentMethoddata.paymnetMethod 
      // formData.logo = paymentMethoddata.serviceLogo
      handleShowLoader(false)
      console.log("response", response);
    } catch (error) {
      handleShowLoader(false)
      showToastErrorMessageClick("Filed in Rendering fields. Please try again")
    }
  };

 const handleNextButton = () =>{

  handleNext()
 }
  useEffect(() => {
    console.log("formData",formData)
    getFormData(formData,paymentMethoddata.paymnetMethod,paymentMethoddata.serviceLogo )
  },[formData])

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {" "}
        {/* VND Dialog session */}
        <Box sx={{ justifyContent: "center" }}>
          <Box
            sx={{
              height: "400.79px",
              width: "551px",
              borderRadius: "18.34px",
              background: "#181818",
            }}
          >
            <Box sx={{padding:"23px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img src={backimg} alt="back" onClick={handleBack} />
                <Box sx={{ flex: 1, textAlign: "center" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      sx={{
                        fontFamily: "Bebas Neue",
                        fontSize: "32.10px",
                        fontWeight: "400",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    color: "white",
                    marginTop: 2,
                    textTransform: "uppercase",
                    marginRight: "60px",
                  }}
                >
                  {paymentMethoddata.paymnetMethod}
                </Typography>
                <Box sx={{ marginTop: "28px", marginLeft: "-50px" }}>
                  {" "}
                  <img src={paymentMethoddata.serviceLogo} width={"auto"} />
                </Box>
              </Box>
            </Box>
          </Box>
          <form onSubmit={(e) => {e.preventDefault();handleNextButton()}}>
          <Box>
            {gatewayFarmDetails?.farmdetail &&
              Object.keys(gatewayFarmDetails?.farmdetail).map((k) => {
                return (
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontSize: "12.23px",
                        color: "#fff",
                        flex: 1,
                      }}
                    >
                      {k === "senderwalletaddr"
                        ? "Sender Wallet Address"
                        : k.charAt(0).toUpperCase() + k.slice(1)}
                    </Typography>
                    <Box>
                      <Box>
                        {" "}
                        {k == "gateway" && (
                          <TextField
                            sx={{
                              border: "0.76px #D0D5DD solid",
                              borderRadius: "6.11px",
                              width: "100%",
                            }}
                            value={gatewayFarmDetails?.farmdetail[k]}
                            disabled={true}
                            InputProps={{
                              style: {
                                color: "white",
                              },
                            }}
                          />
                        )}
                        {gatewayFarmDetails?.farmdetail[k] == "string" && (
                          <TextField
                            sx={{
                              border: "0.76px #D0D5DD solid",
                              borderRadius: "6.11px",
                              width: "100%",
                            }}
                            name={k}
                            InputProps={{
                              style: {
                                color: "white",
                              },
                            }}
                            required
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.value,
                              })
                            }
                          />
                        )}
                        {Array.isArray(gatewayFarmDetails?.farmdetail[k]) && (
                          <FormControl
                            sx={{
                              minWidth: "100%",
                              flex: 1,
                              border: "1px solid #fff",
                              borderRadius: "7px",
                            }}
                            size="small"
                          >
                            <Select
                             required
                              sx={{ color: "#fff" }}
                              name={k}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              IconComponent={() => (
                                <IconButton
                                  disableRipple
                                  style={{ color: "#fff" }}
                                >
                                  <ArrowDropDownIcon />
                                </IconButton>
                              )}
                            >
                              {gatewayFarmDetails?.farmdetail[k].map(
                                (bank: any) => (
                                  <MenuItem
                                    sx={{
                                      fontFamily: "outfit",
                                      color: "#000000",
                                    }}
                                    value={bank?.code ? bank?.code : bank}
                                  >
                                    {bank?.name ? bank?.name : bank}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        )}
                        {gatewayFarmDetails?.farmdetail[k] == "number" && (
                          <TextField
                            sx={{
                              border: "0.76px #D0D5DD solid",
                              borderRadius: "6.11px",
                              width: "100%",
                            }}
                            required
                            name={k}
                            InputProps={{
                              style: {
                                color: "white",
                              },
                            }}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e.target.name]: Number(e.target.value),
                              })
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
          <Button type="submit">
            Next
          </Button>
          </form>
        </Box>
      </Box>
    </Box>
    </Box>
    </Box>
  );
}

export default StepThree;
