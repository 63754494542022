import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

const NftAccount = () => {
  const buttonBox = {
    border: "1px solid #FF990D",
    color: "white",
  };
  return (
    <Box>
      {" "}
      <Typography
        sx={{
          fontSize: "32px",
          fontFamily: "outfit",
          fontWeight: 700,
          marginBottom: "1rem",
        }}
      >
        Account
      </Typography>
      <Typography sx={{ fontSize: "20px", fontFamily: "outfit" }}>
        {" "}
        Wallet{" "}
      </Typography>
      <Box
        sx={{
          border: "1px solid #777",
          borderRadius: "12px",
          color: "#828282",
          width: "100%",
          height: "56px",
        }}
      >
        <Typography sx={{ mt: "14px", ml: "10px" }}>
          {" "}
          2345.33 <span style={{ color: "#FB3" }}> MATIC</span>
        </Typography>
      </Box>
      <Button
        sx={{
          ...buttonBox,
          marginTop: "1rem",
          height: "52px",
          width: "200px",
          marginBottom: "2rem",
          borderRadius: "9.168px",
          textTransform: "none",
        }}
      >
        Withdraw Balance
      </Button>
    </Box>
  );
};
export default NftAccount;
