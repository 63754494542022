import Stack from "@mui/material/Stack";
import "./VoeuxIndex.css";
import Card from "../../components/voeuxcard/Voeuxcard";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Banner1 from "../../assets/VoeuxMoeny/banner1.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../pages/nftLanding/NftLanding.module.css";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import Box from "@mui/system/Box";
import CheckCircleRoundedIcon1 from "../../assets/svgs/checkCircleRoundedIcon.svg";
import inactiveRadioButton from "../../assets/svgs/inactiveRadioButton.svg";

function FullAppViewPage() {
  const games = [
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder6.png"),
      title: "Voeux Token Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder2.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder3.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder4.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder5.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder6.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder6.png"),
      title: "Voeux Token Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder2.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder3.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder4.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder5.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder6.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder6.png"),
      title: "Voeux Token Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder2.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder3.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder4.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder5.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder6.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder4.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder5.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ImgHolder6.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
    },
  ];
  const navigate = useNavigate();
  const handleBack = () => {
    console.log("kljhgf");
    navigate("/voeuxmoney");
  };

  const handleFullDetails = () => {
    navigate("/individual");
  };
  const [value, setValue] = useState("airdrop");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  return (
    <>
      <Box
        className={styles.mainContainer}
        sx={{
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          pb: "30px",
          position: "relative",
          zIndex: 0,
        }}
      >
        <NewNftHeader nftHeader={true} />
        <div className="w-100">
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1}>
              <KeyboardArrowLeftIcon
                fontSize="large"
                className="arrow-left"
                onClick={() => handleBack()}
                sx={{ cursor: "pointer" }}
              />

              <a
                className="breadcrumb-title"
                style={{ cursor: "pointer" }}
                onClick={() => handleBack()}
              >
                Back
              </a>
            </Stack>
            <Grid container spacing={1} md={12}>
              <Grid item xs={12} md={3}>
                <Stack
                  className="filterBy-Section"
                  spacing={2}
                  direction="column"
                >
                  <h6 className="filter-title">Filter BY</h6>
                  <h6 className="filter-title">Category</h6>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="airdrop"
                    name="radio-buttons-group"
                    onChange={(e) => handleChange(e)}
                  >
                    <FormControlLabel
                      value="airdrop"
                      control={
                        <Radio
                          icon={
                            <img
                              src={inactiveRadioButton}
                              height="24px"
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={CheckCircleRoundedIcon1}
                              height="24px"
                              alt=""
                            />
                          }
                        />
                      }
                      label="Airdrops"
                    />
                    <FormControlLabel
                      value="game"
                      control={
                        <Radio
                          icon={<img src={inactiveRadioButton} height="24px"  alt=""/>}
                          checkedIcon={
                            <img
                              src={CheckCircleRoundedIcon1}
                              height="24px"
                              alt=""
                            />
                          }
                        />
                      }
                      label="Games"
                    />
                    <FormControlLabel
                      value="video"
                      control={
                        <Radio
                          icon={<img src={inactiveRadioButton} height="24px" alt=""/>}
                          checkedIcon={
                            <img src={CheckCircleRoundedIcon1} height="24px" alt="" />
                          }
                        />
                      }
                      label="Video"
                    />
                    <FormControlLabel
                      value="music"
                      control={
                        <Radio
                          icon={<img src={inactiveRadioButton} height="24px" alt=""/>}
                          checkedIcon={
                            <img src={CheckCircleRoundedIcon1} height="24px" alt="" />
                          }
                        />
                      }
                      label="Music"
                    />
                    <FormControlLabel
                      value="sign"
                      control={
                        <Radio
                          icon={<img src={inactiveRadioButton} height="24px" alt="" />}
                          checkedIcon={
                            <img src={CheckCircleRoundedIcon1} height="24px" alt=""/>
                          }
                        />
                      }
                      label="Sign up"
                    />
                    <FormControlLabel
                      value="app"
                      control={
                        <Radio
                          icon={<img src={inactiveRadioButton} height="24px" alt="" />}
                          checkedIcon={
                            <img src={CheckCircleRoundedIcon1} height="24px" alt="" />
                          }
                        />
                      }
                      label="App Download"
                    />
                    <FormControlLabel
                      value="survey"
                      control={
                        <Radio
                          icon={<img src={inactiveRadioButton} height="24px" alt="" />}
                          checkedIcon={
                            <img src={CheckCircleRoundedIcon1} height="24px" alt=""/>
                          }
                        />
                      }
                      label="Surveys"
                    />
                    <FormControlLabel
                      value="shop"
                      control={
                        <Radio
                          icon={<img src={inactiveRadioButton} height="24px" alt=""/>}
                          checkedIcon={
                            <img src={CheckCircleRoundedIcon1} height="24px" alt="" />
                          }
                        />
                      }
                      label="Shop & Earn"
                    />
                  </RadioGroup>
                </Stack>
                <Stack direction="column" spacing={2}>
                  <img
                    src={Banner1}
                    className="banner1-size"
                    alt=""
                    draggable="false"
                    style={{ marginTop: "20px" }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={9}>
                <Card
                  type="cardstyle1"
                  data={games}
                  cardresize={true}
                  onClickAction={() => handleFullDetails()}
                />
              </Grid>
            </Grid>
          </Stack>
        </div>
      </Box>
      <LandingPageFooter />
    </>
  );
}

export default FullAppViewPage;
