import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PolygonIcon from "../../assets/Createnft/polygon_round.svg";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { CreacteNftServices } from "../../services/createNftService";
import axios from "axios";
import { uploadNFTImage } from "../../utils/index";
import { TextareaAutosize } from "@mui/material";
import DragAndDrop from "../../components/createNft/DragAndDrop";
import LeftArrow from "../../assets/svgs/LeftArrow.svg";
import { useNavigate } from "react-router-dom";
import { Environment } from "../../environments";
const circleStyle: React.CSSProperties = {
  position: "absolute",
  zIndex: -1,
  flexShrink: 0,
};

const CreateNftCollectionPage = ({
  handleCreateContniue,
  collectionDrag,
  getImageurl,
  handleLeftArrow,
  handleShowLoader,
  showToastErrorMessageClick,
}: // showError
{
  handleCreateContniue: (collectionResponse: Object) => void;
  collectionDrag: any;
  getImageurl: (data: any, file: any) => void;
  handleLeftArrow: () => void;
  handleShowLoader: (active: any, text?: any) => void;
  showToastErrorMessageClick: (data: any) => void;
  // showError:(text:string)=>void
}) => {
  const [nftServiceInstance, setNftServiceInstance] =
    useState<CreacteNftServices>();
  // const walletData = useSelector((state:any) =>state );
  const walletData = localStorage.getItem("account_address");

  const [userDetailsData, setuserDetailsData] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  // const [formdata, setFormData] = useState([])
  const [formdata, setFormData] = useState<any>({});
  const [collectionDragContent, setCollectionDrag] = useState({
    imageURL: "",
    selectedFile: "",
  });

  const getHandleImageurl = (data: any, file: any) => {
    setCollectionDrag((prevState) => ({
      ...prevState,
      imageURL: data,
      selectedFile: file,
    }));
    getImageurl(data, file);
  };

  useEffect(() => {
    const nftservice = new CreacteNftServices();
    const intervalId = setInterval(() => {
      if (
        nftservice.createNftInstance.contractInstance != null &&
        nftservice.createNftInstance.contractInstance != undefined
      ) {
        setNftServiceInstance(nftservice);
        clearInterval(intervalId);
      }
    }, 100);
  }, []);

  // useEffect(() => {
  //   if (nftServiceInstance) {

  //   } else {
  //     console.log("nftServiceInstance not present:");
  //   }
  // }, [nftServiceInstance]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      console.log("fetchUserDetails === >");
      try {
        const response = await axios.get(
          `${Environment.metavoeuxBackend}user/userDetails`,
          {
            params: { walletAddress: walletData },
          }
        );
        console.log("get user details", response);
        setuserDetailsData(response.data);
        formdata.walletAddress = response.data.walletAddress;
        handleShowLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserDetails();
    
  }, [walletData]);

  const handleCreateCollection = async () => {
    handleShowLoader(true, "Connecting to Blockchain....");
    if (!collectionDragContent.selectedFile) {
      handleShowLoader(false);
      showToastErrorMessageClick("Upload the Collection Image");
      return;
    }
    try {
      const newUriObject = {
        name: collectionName ? collectionName : "My First NFT",
        description: collectionDescription
          ? collectionDescription
          : "This is my first nft",
        image: collectionDragContent && collectionDragContent.selectedFile,
      };

      const uri: any = await uploadNFTImage(
        newUriObject.image,
        newUriObject.name,
        newUriObject.description
      );
      console.log(uri, "uri");

      const newDeployObject = {
        contractName: collectionName,
        _uri: `ipfs://${uri}/`,
        ids: [0],
        names: ["sample"],
      };
      console.log("newDeployObject", newDeployObject);
      //  try{
      const newCollection: any = await nftServiceInstance?.deployERC1155(
        newDeployObject
      );

      console.log(newCollection, "newCollection");
      formdata.contractName = collectionName;
      formdata.description = collectionDescription;
      formdata.collectionAddress = newCollection.collectionAddress;
      formdata.cid = uri;
      formdata.url = collectionDrag.imageURL;

      console.log("createNftWeb2 ==> ", formdata);
      if (!formdata.url) {
        showToastErrorMessageClick(
          "Image Failed in Uploaded. Please try with new Image."
        );
        handleShowLoader(false);
        return false;
      }
      console.log("formdata.url", formdata.url);
      // console.log(newUriObject,newDeployObject,createNftWeb2, "response");
      const response = await axios.post(
        `${Environment.metavoeuxBackend}nftCollection`,
        formdata
      );
      console.log(response, "create collection");
      handleShowLoader(false);
      handleCreateContniue(formdata);
      //  }catch(error){
      //   console.log(error);
      //  }

      // const createNftWeb2 = {
      //   "user":userDetailsData,
      //   "contractName": collectionName,
      //   "description": "test12",
      //   "ipNft":uri?.uri,
      //   // "ipUrl": uri?.url,
      //   "url": collectionDragContent.imageURL
      // }
    } catch (err) {
      console.log(err);
      // showError("Error Occureed while creating Collection")
    }
  };
  const navigate = useNavigate();
  //   const handleLeftArrow = () => {
  //     navigate("/nftmarketplace")
  //   };

  return (
    <>
      {/* <Box sx={{
      position:"absolute",
      background:"#000000c9",
      width:"100%",
      height:"100%",
      zIndex:1,
      left:0,
      display: "flex",
      justifyContent:"center",
      alignItems:"center"
    }}>

    <LoadingComponent/>
    </Box> */}
      <Grid container spacing={{ xs: 10, lg: 20 }}>
        <Grid item xs={12} md={6} lg={6}>
          <Box sx={{ position: "relative" }}>
            <img src={LeftArrow} alt="" onClick={() => handleLeftArrow()} />
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                ...circleStyle,
                top: { xl: "65%" },
                left: "-22%",
                transform: "translateY(-50%)",
                width: "350px", // Adjusted width
                height: "200px", // Adjusted height
                borderRadius: "50%", // Ensures it remains a perfect circle
                background: "#9D00FF",
                // background: "red",
                filter: "blur(140px)",
                backdropFilter: "blur(140px)",
                WebkitBackdropFilter: "blur(140px)",
                overflow: "hidden",
                "@media (min-width: 1721px)": {
                  width: "450px", // Adjusted width
                  height: "400px", // Adjusted height
                  top: { xl: "50%" },
                  left: "-20%",
                  filter: "blur(160px)",
                  backdropFilter: "blur(160px)",
                  WebkitBackdropFilter: "blur(160px)",
                },
              }}
            ></Box>
            <DragAndDrop
              createCollection
              getImageurl={getHandleImageurl}
              data={collectionDragContent}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                ...circleStyle,
                top: { xl: "23%" },
                right: "-2%",
                transform: "translateY(-50%)",
                width: "350px", // Adjusted width
                height: "200px", // Adjusted height
                borderRadius: "50%", // Ensures it remains a perfect circle
                background: "#9D00FF",
                // background: "red",
                filter: "blur(140px)",
                overflow: "hidden",
                "@media (min-width: 1721px)": {
                  width: "450px", // Adjusted width
                  height: "400px", // Adjusted height
                  top: { xl: "50%" },
                  left: "-20%",
                  filter: "blur(160px)",
                },
              }}
            ></Box>
            <Typography
              sx={{
                color: "#FFF",
                fontSize: { xs: "16px", md: "32px" },
                fontWeight: 600,
                mb: "20px",
                fontFamily: "outfit",
              }}
            >
              Drop a collection
            </Typography>
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 300,
                mb: "30px",
                fontFamily: "outfit",
              }}
            >
              You must deploy an ERC-1155 contract on the blockchain to
              establish a collection for your NFTs
            </Typography>
            {/* <Box
          sx={{
            display: "flex",
            p: "16px",
            borderRadius: "16px",
            background: "rgba(217, 217, 217, 0.11)",
            alignItems: "center",
            columnGap: "13px",
            border: "1px dashed #FFF",
            mb: "30px",
          }}
        >
          <Box
            sx={{
              borderRadius: "16px",
              background: "rgba(217, 217, 217, 0.11)",
              p: "19px ",
            }}
          >
            <img src={PlusIcon} alt="" />
          </Box>
          <Typography
            sx={{
              color: "#F6EBFD",
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: 300,
            }}
          >
            Upload Logo Image for Collection
          </Typography>
        </Box> */}
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "30px",
              }}
              onSubmit={(e) => (e.preventDefault(), handleCreateCollection())}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "20px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  Collection Name
                </Typography>
                <TextField
                  type="text"
                  name="collectionName"
                  value={collectionName}
                  onChange={(e) => {
                    setCollectionName(e.target.value);
                  }}
                  placeholder="Enter your Collection name"
                  required
                  inputProps={{ style: { color: "#fff" } }}
                  sx={{
                    border: "1px solid #777",
                    borderRadius: "12px",
                    color: "#828282",
                    width: "100%",
                    "::placeholder": { color: "red" },
                  }}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "20px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  Collection Description
                </Typography>

                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder=" Enter your Description"
                  name="description"
                  style={{
                    border: "1px solid #777",
                    borderRadius: "12px",
                    color: "#fff",
                    width: "100%",
                    background: "none",
                    height: "120px",
                    padding: "15px",
                  }}
                  required
                  onChange={(e) => {
                    setCollectionDescription(e.target.value);
                  }}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "20px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                  }}
                >
                  Blockchain:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "8px",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <img src={PolygonIcon} alt="polygon" />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "16px", md: "16px" },
                      fontWeight: 300,
                      fontFamily: "outfit",
                    }}
                  >
                    Polygon
                  </Typography>
                </Box>
              </Box>
              <Button
                sx={{
                  background: " #FF990D",
                  padding: "12px 32px",
                  color: "white",
                  fontSize: "18px",
                  borderRadius: "12px",
                  width: "40%",
                  margin: "auto",
                  "&:hover": {
                    background: " #FF990D",
                    color: "white",
                  },
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Continue
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default CreateNftCollectionPage;
