import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
// import { I18nextProvider } from 'react-i18next'
// import i18next from 'i18next'

// import "./i18n"
import global_ar from "./locales/ar/global.json";
import global_en from "./locales/en/global.json";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// i18next.init({
//   interpolation: { escapeValue: false },
//   lng: "ar",
//   fallbackLng: "ar",
//   resources: {
//     en: {
//       global: global_en,
//     },
//     ar: {
//       global: global_ar,
//     },
//   },
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
