import Stack from "@mui/material/Stack";
import "./VoeuxIndex.css";
import styles from "../../pages/nftLanding/NftLanding.module.css";
import Banner from "../../components/voeuxbanner/Voeuxbanner";
import Card from "../../components/voeuxcard/Voeuxcard";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GiftCatd from "../../assets/images/GiftCard.png";
import offettag from "../../assets/images/offertag.png";
import share from "../../assets/images/Share.png";
import creditcard from "../../assets/images/creditcard.png";
import cashback from "../../assets/images/cashback.png";
import { useNavigate } from "react-router-dom";
import Box from "@mui/system/Box";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";

function Voeuxmoneymui() {
  const offerdata = [
    { imageURL: GiftCatd, title: "Gift Card" },
    { imageURL: creditcard, title: "Vouchers" },
    { imageURL: cashback, title: "Cask Back" },
    { imageURL: offettag, title: "Offers" },
    { imageURL: share, title: "Refferal" },
  ];
  const airdrop1 = [
    {
      imageURL: require("../../assets/images/ImgHolder1.png"),
      title: "Voeux Token Airdrop",
      availableFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/ImgHolder2.png"),
      title: "Infinity Games Airdrop",
      availableFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/ImgHolder3.png"),
      title: "Infinity Games Airdrop",
      availableFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/ImgHolder4.png"),
      title: "Infinity Games Airdrop",
      availableFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/ImgHolder5.png"),
      title: "Infinity Games Airdrop",
      availableFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/ImgHolder1.png"),
      title: "Voeux Token Airdrop",
      availableFor: true,
      token: true,
    },
  ];
  const games = [
    {
      imageURL: require("../../assets/images/game1.png"),
      title: "Voeux Token Airdrop",
      availableFor: false,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game2.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game3.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game4.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game5.png"),
      title: "Infinity Games Airdrop",
      availableFor: false,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game1.png"),
      title: "Voeux Token Airdrop",
      availableFor: false,
      token: true,
    },
  ];
  const vediosData = [
    {
      imageURL: require("../../assets/images/video1.png"),
      title: "Voeux Token Airdrop",
      token: true,
    },
    {
      imageURL: require("../../assets/images/video2.png"),
      title: "Infinity Games Airdrop",
      token: true,
    },
    {
      imageURL: require("../../assets/images/video3.png"),
      title: "Infinity Games Airdrop",
      token: true,
    },
    {
      imageURL: require("../../assets/images/video4.png"),
      title: "Infinity Games Airdrop",
      token: true,
    },
    {
      imageURL: require("../../assets/images/video5.png"),
      title: "Infinity Games Airdrop",
      token: true,
    },
    {
      imageURL: require("../../assets/images/video1.png"),
      title: "Voeux Token Airdrop",
      token: true,
    },
  ];
  const Music = [
    {
      imageURL: require("../../assets/images/music1.png"),
      title: "Infinity Games Airdrop",
      caption: true,
      Signup: "signup",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music2.png"),
      title: "Infinity Games Airdrop",
      caption: true,
      Signup: "signup",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music3.png"),
      title: "Infinity Games Airdrop",
      caption: true,
      Signup: "signup",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music4.png"),
      title: "Infinity Games Airdrop",
      caption: true,
      Signup: "signup",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music5.png"),
      title: "Infinity Games Airdrop",
      caption: true,
      Signup: "signup",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music1.png"),
      title: "Voeux Token Airdrop",
      caption: true,
      Signup: "signup",
      token: true,
    },
  ];

  const Signup = [
    {
      imageURL: require("../../assets/images/music1.png"),
      title: "Music 1",
      followers: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/music2.png"),
      title: "Music 1",
      followers: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/music3.png"),
      title: "Music 1",
      followers: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/music4.png"),
      title: "Music 1",
      followers: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/music5.png"),
      title: "Music 1",
      followers: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/music1.png"),
      title: "Music 1",
      followers: true,
      token: true,
    },
  ];

  const appDownload = [
    {
      imageURL: require("../../assets/images/game1.png"),
      title: "Voeux Token ",
      caption: true,
      pcHideFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game2.png"),
      title: "Infinity Games ",
      caption: true,
      pcHideFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game3.png"),
      title: "Infinity Games ",
      caption: true,
      pcHideFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game4.png"),
      title: "Infinity Games ",
      caption: true,
      pcHideFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game5.png"),
      title: "Infinity Games ",
      caption: true,
      pcHideFor: true,
      token: true,
    },
    {
      imageURL: require("../../assets/images/game1.png"),
      title: "Voeux Token ",
      caption: true,
      pcHideFor: true,
      token: true,
    },
  ];

  const surveyData = [
    {
      imageURL: require("../../assets/images/music1.png"),
      title: "Beautiful Places art",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music2.png"),
      title: "Beautiful Places art",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music3.png"),
      title: "Beautiful Places art",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music4.png"),
      title: "Beautiful Places art",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music5.png"),
      title: "Beautiful Places art",
      token: true,
    },
    {
      imageURL: require("../../assets/images/music1.png"),
      title: "Beautiful Places art",
      token: true,
    },
  ];
  const shop = [
    {
      imageURL: require("../../assets/VoeuxMoeny/bestbuy.png"),
      title: "Best Buy",
      offer: "2% Cash Back",
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/amazon.png"),
      title: "Amazon",
      offer: "2% Cash Back",
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/ebay.png"),
      title: "ebay",
      offer: "2% Cash Back",
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/homedelivery.png"),
      title: "Homedelivery",
      offer: "2% Cash Back",
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/homedelivery.png"),
      title: "Homedelivery",
      offer: "2% Cash Back",
    },
    {
      imageURL: require("../../assets/VoeuxMoeny/homedelivery.png"),
      title: "Homedelivery",
      offer: "2% Cash Back",
    },
  ];

 
  const navigate = useNavigate();
  const handleViewAll = () => {
    navigate("/full");
  };
  const handleFullDetails = () => {
    navigate("/individual");
  };

  return (
    //   <button disabled={disabled}>{title}</button>
    <>
      <Box
        className={styles.mainContainer}
        sx={{
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          pb: "30px",
          position: "relative",
          zIndex: 0,
        }}
      >
        <NewNftHeader />
        <Stack direction="column" spacing={6}>
          <Banner />
          <Card type="offer" data={offerdata} />
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              className="card-Headers"
            >
              <h6 className="card-header-title">Airdrops</h6>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <a className="card-header-title" style={{ lineHeight: "29px" }}>
                  View All
                </a>
                <IconButton
                  sx={{ background: "#272727", borderRadius: "4px" }}
                  size="small"
                  className="buttin-icon"
                  onClick={() => handleViewAll()}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ color: "#FB3" }}
                    className="arrow-forward"
                  />
                </IconButton>
              </Stack>
            </Stack>

            <Card
              type="cardstyle1"
              iscenter={true}
              data={airdrop1}
              onClickAction={() => handleFullDetails()}
            />
          </Stack>

          <Stack direction="column" spacing={1}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              className="card-Headers"
            >
              <h6 className="card-header-title">Games</h6>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <a className="card-header-title" style={{ lineHeight: "29px" }}>
                  View All
                </a>
                <IconButton
                  sx={{ background: "#272727", borderRadius: "4px" }}
                  size="small"
                  className="buttin-icon"
                  onClick={() => handleViewAll()}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ color: "#FB3" }}
                    className="arrow-forward"
                  />
                </IconButton>
              </Stack>
            </Stack>

            <Card
              type="cardstyle1"
              iscenter={true}
              data={games}
              onClickAction={() => handleFullDetails()}
            />
          </Stack>

          <Stack direction="column" spacing={1}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              className="card-Headers"
            >
              <h6 className="card-header-title">Videos</h6>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <a className="card-header-title" style={{ lineHeight: "29px" }}>
                  View All
                </a>
                <IconButton
                  sx={{ background: "#272727", borderRadius: "4px" }}
                  size="small"
                  className="buttin-icon"
                  onClick={() => handleViewAll()}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ color: "#FB3" }}
                    className="arrow-forward"
                  />
                </IconButton>
              </Stack>
            </Stack>

            <Card
              type="cardstyle1"
              iscenter={true}
              data={vediosData}
              onClickAction={() => handleFullDetails()}
            />
          </Stack>

          <Stack direction="column" spacing={1}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              className="card-Headers"
            >
              <h6 className="card-header-title">Music</h6>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <a className="card-header-title" style={{ lineHeight: "29px" }}>
                  View All
                </a>
                <IconButton
                  sx={{ background: "#272727", borderRadius: "4px" }}
                  size="small"
                  className="buttin-icon"
                  onClick={() => handleViewAll()}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ color: "#FB3" }}
                    className="arrow-forward"
                  />
                </IconButton>
              </Stack>
            </Stack>

            <Card
              type="cardstyle1"
              iscenter={true}
              data={Signup}
              SignupButton={true}
              onClickAction={() => handleFullDetails()}
            />
          </Stack>

          <Stack direction="column" spacing={1}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              className="card-Headers"
            >
              <h6 className="card-header-title">Sign Up</h6>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <a className="card-header-title" style={{ lineHeight: "29px" }}>
                  View All
                </a>
                <IconButton
                  sx={{ background: "#272727", borderRadius: "4px" }}
                  size="small"
                  className="buttin-icon"
                  onClick={() => handleViewAll()}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ color: "#FB3" }}
                    className="arrow-forward"
                  />
                </IconButton>
              </Stack>
            </Stack>

            <Card
              type="cardstyle1"
              iscenter={true}
              data={Music}
              Signup={false}
              onClickAction={() => handleFullDetails()}
            />
          </Stack>

          <Stack direction="column" spacing={1}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              className="card-Headers"
            >
              <h6 className="card-header-title">App Download</h6>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <a className="card-header-title" style={{ lineHeight: "29px" }}>
                  View All
                </a>
                <IconButton
                  sx={{ background: "#272727", borderRadius: "4px" }}
                  size="small"
                  className="buttin-icon"
                  onClick={() => handleViewAll()}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ color: "#FB3" }}
                    className="arrow-forward"
                  />
                </IconButton>
              </Stack>
            </Stack>

            <Card
              type="cardstyle1"
              iscenter={true}
              data={appDownload}
              onClickAction={() => handleFullDetails()}
            />
          </Stack>

          <Stack direction="column" spacing={1}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              className="card-Headers"
            >
              <h6 className="card-header-title">Surveys</h6>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <a className="card-header-title" style={{ lineHeight: "29px" }}>
                  View All
                </a>
                <IconButton
                  sx={{ background: "#272727", borderRadius: "4px" }}
                  size="small"
                  className="buttin-icon"
                  onClick={() => handleViewAll()}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ color: "#FB3" }}
                    className="arrow-forward"
                  />
                </IconButton>
              </Stack>
            </Stack>

            <Card
              type="cardstyle1"
              iscenter={true}
              data={surveyData}
              Signup={false}
              tokendata={true}
              onClickAction={() => handleFullDetails()}
            />
          </Stack>

          <Stack direction="column" spacing={1}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              className="card-Headers"
            >
              <h6 className="card-header-title">Shop & Earn</h6>
              <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                <a className="card-header-title" style={{ lineHeight: "29px" }}>
                  View All
                </a>
                <IconButton
                  sx={{ background: "#272727", borderRadius: "4px" }}
                  size="small"
                  className="buttin-icon"
                  onClick={() => handleViewAll()}
                >
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ color: "#FB3" }}
                    className="arrow-forward"
                  />
                </IconButton>
              </Stack>
            </Stack>

            <Card
              type="cardstyle1"
              iscenter={true}
              data={shop}
              offerdata={true}
              onClickAction={() => handleFullDetails()}
            />
          </Stack>
        </Stack>

        {/* <Footer /> */}
      </Box>
      <LandingPageFooter />
    </>
  );
}

export default Voeuxmoneymui;
