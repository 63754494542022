import React from "react";
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { jwtPayload } from "../../domains/jwtDecode.dto";

const GoogleLoginComponent = () => {
  const navigate = useNavigate();
  //const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID || "";
  const clientId =
    "493142749572-jvngkeridfno0lv5e1nk2t5dv845v9dq.apps.googleusercontent.com" ||
    "";

  console.log("clientId", clientId);
  const handleLogin = (credentialResponse: CredentialResponse) => {
    console.log(credentialResponse, "credentialResponse");
    const token = credentialResponse.credential || "";
    localStorage.setItem("token", token);
    const decodedData = jwtDecode(token) as jwtPayload;
    const user = {
      name: decodedData.name,
      email: decodedData.email,
      picture: decodedData.picture,
    };
    localStorage.setItem("user", JSON.stringify(user));
    navigate("/");
  };

  const handleLoginError = () => {
    localStorage.removeItem("user-credential");
    console.log("Login Failed");
  };

  return (
    <div style={{ padding: "7px" ,marginTop:"19px"}}>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            handleLogin(credentialResponse);
          }}
          type="standard"
          onError={handleLoginError}
          size={"large"}
          shape="circle"
          theme="filled_black"
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleLoginComponent;
