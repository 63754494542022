import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import backgroundVideo from "../../assets/videos/subscribeVideo.mp4";
import subscribelogo from "../../assets/images/Subscribe.png";
import axios from "axios";
import { Environment } from "src/environments";
import ToastErrorComponenet from "../../components/ToastMessage/ToastMessageFailure";

const LandingPageSubscribe = () => {
  const styles = {
    "input-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      color: "red",
    },

    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "blue",
      },
    },
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  const [showEmail, setshowEmail] = useState("");

  const handleEmailChange = (event: any) => {
    const email = event.target.value;
    console.log("Email entered:", email);
    setshowEmail(email);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = async () => {
    const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!pattern.test(showEmail)) {
      showToastErrorMessageClick("Please provide valid email address");
      return false;
    }
    try {
      const response = await axios.post(
        `${Environment.metavoeuxBackend}news-letter`,
        {
          email: showEmail,
        }
      );
      console.log("API response:", response.data);
      setOpen(true);
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };
  const circleStyleBlock: React.CSSProperties = {
    position: "absolute",
    flexShrink: 0,
    borderRadius: "367.166px",
    background: "var(--ff-990-d, #FF990D)",
    filter: "blur(140px)",
    zIndex: -1,
  };
  return (
    <>
      <ToastErrorComponenet
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />

      <Box
        sx={{ marginBottom: { xs: "0px" } }}
        height="100%"
        alignItems="center"
        justifyContent="center"
        width={"100%"}
        position={"relative"}
      >
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              ...circleStyleBlock,
              position: "relative",
              top: { md: "50%" },
              // transform: {xs:"translate(-50%, 50%)",md:"translate(-50%, -50%)",lg:"translate(-50%, -50%)"},

              width: { sm: "310.625px", xs: "40%" },
              // height: "187.734px",
              height: { xs: "110px", md: "187.734px" },
              borderRadius: "547.625px",
              background: "#9D00FF",
              filter: "blur(120px)",
              // backdropFilter:"blur(120px)",
              WebkitBackdropFilter: "blur(120px)",
              zIndex: 0,
              "@media (min-width: 320px)": {
                width: "40%",
              },
            }}
          ></Box>
          <Box
            sx={{
              ...circleStyleBlock,
              position: "relative",
              top: { xs: "40%", md: "10%" },
              // right: { md: "-20%", xl: "-20%" },
              // transform: {xs:"translateY(-7%)",md:"translate(-50%,-50%)"},
              // transform:{xs:""}
              // left: { md: "90%" },

              width: { xs: "65px", md: "400px", xl: "400.166px" },
              height: { xs: "130px", md: "170.793px" },
              borderRadius: "367.166px",
              background: "var(--ff-990-d, #FF990D)",
              filter: { xs: "blur(84px)", md: "blur(130px)" },
              // backdropFilter:{ xs: "blur(84px)", md: "blur(130px)" },
              WebkitBackdropFilter: { xs: "blur(84px)", md: "blur(130px)" },
              overflow: "hidden",
              zIndex: 0,
            }}
          ></Box>
        </Box>

        <video
          style={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: -1,
          }}
          loop
          muted
          autoPlay
          playsInline
          preload="auto"
          src={
            "https://d24m5mu8cxqwfm.cloudfront.net/images/subscribeVideo.mp4"
          }
          typeof="video/mp4"
        />
        {isMobile ? (
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            maxWidth={"100%"}
            height="100%"
            p={2}
            sx={{
              zIndex: 2,
            }}
            alignItems="left"
          >
            <Typography
              // textAlign="center"
              sx={{
                color: "white",
                fontSize: { xs: "23px " },
                fontWeight: {
                  xs: 500,
                  md: 500,
                },
                textTransform: "capitalize",
                zIndex: 2,
              }}
            >
              Subscribe to our{" "}
              <span
                style={{
                  color: "#FF990D",
                  fontSize: "23px",
                  fontFamily: "outfit",
                  fontWeight: "500",
                }}
              >
                Newsletter
              </span>
            </Typography>
            <Typography
              // textAlign="center"
              sx={{
                fontFamily: "outfit",
                color: "white",
                fontSize: { xs: 14, md: 17 },
                fontWeight: {
                  xs: 300,
                },
                zIndex: 2,
                width: {
                  md: "25%",
                },
                textTransform: "capitalize",
              }}
            >
              Stay updated with latest metavoeux news anywhere around the globe
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              margin={2}
              p={"4px 4px"}
              sx={{
                background: "transparent",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid white",
                width: {
                  xs: "100%",

                  borderRadius: 8,
                },
              }}
            >
              <TextField
                type="Email"
                variant="outlined"
                placeholder="Enter email..."
                name="email"
                value={showEmail}
                sx={{
                  background: "transparent",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "none",
                    },
                }}
                inputProps={{
                  style: {
                    color: "white",
                    "&::placeholder": {
                      color: "white",
                    },
                  } as any,
                }}
                size="small"
                fullWidth
                onChange={handleEmailChange}
              />
            </Stack>
            <Button
              variant="contained"
              sx={{
                background:
                  "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                color: "white",
                padding: "6px 16px",
                borderRadius: "8px",
                textTransform: "capitalize",
                fontSize: "14px",
                width: "max-content",
                margin: "auto !important",
                marginTop: "16px !important",
                "&:hover": {
                  background:
                    "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  color: "white",
                  width: "max-content",
                },
              }}
              onClick={handleOpen}
            >
              {" "}
              Subscribe{" "}
            </Button>
            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
              open={open}
            >
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: " 555px",

                    borderRadius: "24px",

                    background:
                      "linear-gradient(267deg, rgba(255, 153, 13, 0.35) -3.12%, rgba(157, 0, 255, 0.35) 93.15%)",

                    backdropFilter: "blur(2px)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Button
                        sx={{
                          color: "#FFF",
                          fontSize: "30px",
                          marginTop: "10px",
                        }}
                        onClick={handleClose}
                      >
                        X
                      </Button>
                    </>
                  </Box>
                  <Box
                    sx={{
                      width: " 555px",

                      borderRadius: "24px",

                      background: "  var(--272727, #272727)",
                      backdropFilter: "blur(2px)",
                      padding: "24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "28px",
                          color: "#FFF",
                        }}
                      >
                        {" "}
                        Thank You For Subscribing to
                        <br />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "28px",
                          color: "#FFF",
                        }}
                      >
                        our newsletter
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img src={subscribelogo} alt="" />
                    </Box>
                  </Box>
                </Box>
              </Stack>
              {/* </DialogContent> */}
            </Dialog>
          </Stack>
        ) : (
          <Stack
            direction="column"
            spacing={2}
            justifyContent="center"
            maxWidth={"100%"}
            height="100%"
            p={2}
            sx={{
              zIndex: 2,
            }}
            alignItems="center"
          >
            <Typography
              textAlign="center"
              sx={{
                color: "white",
                fontSize: { xs: 22, md: 32 },
                fontWeight: {
                  xs: 700,
                  md: 500,
                },
                textTransform: "capitalize",
              }}
            >
              Subscribe to our{" "}
              <span
                style={{
                  color: "#FF990D",
                  fontSize: "32px",
                  fontFamily: "outfit",
                  fontWeight: "500",
                }}
              >
                Newsletter
              </span>
            </Typography>
            <Typography
              textAlign="center"
              sx={{
                fontFamily: "outfit",
                color: "white",
                fontSize: { xs: 12, md: 17 },
                fontWeight: {
                  xs: 300,
                },
                width: {
                  md: "25%",
                },
                textTransform: "capitalize",
              }}
            >
              Stay updated with latest metavoeux news anywhere around the globe
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              margin={2}
              p={"4px 4px"}
              sx={{
                background: "transparent",

                alignItems: "center",
                justifyContent: "center",
                border: "1px solid white",
                width: {
                  xs: "80%",
                  md: "30%",

                  borderRadius: 10,
                },
              }}
            >
              <TextField
                type="Email"
                variant="outlined"
                placeholder="Enter email..."
                name="email"
                value={showEmail}
                sx={{
                  background: "transparent",
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "none",
                    },
                }}
                inputProps={{
                  style: {
                    color: "white",
                    "&::placeholder": {
                      color: "white",
                    },
                  } as any,
                }}
                size="small"
                fullWidth
                onChange={handleEmailChange}
              />
              <Button
                variant="contained"
                size="large"
                sx={{
                  background: "white",
                  color: "black",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "white",
                    color: "black",
                  },
                }}
                onClick={handleOpen}
              >
                {" "}
                Subscribe{" "}
              </Button>
            </Stack>

            <Dialog
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              }}
              open={open}
            >
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: " 555px",

                    borderRadius: "24px",

                    background:
                      "linear-gradient(267deg, rgba(255, 153, 13, 0.35) -3.12%, rgba(157, 0, 255, 0.35) 93.15%)",

                    backdropFilter: "blur(2px)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Button
                        sx={{
                          color: "#FFF",
                          fontSize: "30px",
                          marginTop: "10px",
                        }}
                        onClick={handleClose}
                      >
                        X
                      </Button>
                    </>
                  </Box>
                  <Box
                    sx={{
                      width: " 555px",

                      borderRadius: "24px",

                      background: "  var(--272727, #272727)",
                      backdropFilter: "blur(2px)",
                      padding: "24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "28px",
                          color: "#FFF",
                        }}
                      >
                        {" "}
                        Thank You For Subscribing to
                        <br />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "28px",
                          color: "#FFF",
                        }}
                      >
                        our newsletter
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img src={subscribelogo} alt="" />
                    </Box>
                  </Box>
                </Box>
              </Stack>
              {/* </DialogContent> */}
            </Dialog>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default LandingPageSubscribe;
