import { Contract } from "ethers";
import { ContractInstance } from "./contractInstance";

import {Interface } from "ethers"

export class FactoryERC1155 {
  contractInstance: Contract | null;
  contractAbi:any[] | null;
  constructor(
    FactoryERC1155_CONTRACT_ADDRESS: string,
    FactoryERC1155_CONTRACT_ABI: any[]
  ) {
    this.contractAbi = FactoryERC1155_CONTRACT_ABI;
    this.contractInstance = null;
    const contract = new ContractInstance();
    contract
      .buildContractInstance(
        FactoryERC1155_CONTRACT_ADDRESS,
        FactoryERC1155_CONTRACT_ABI
      )
      .then((contIns) => {
        this.contractInstance = contIns;
      });
  }

  // this method is used for creating a collection  which is then used for creating NFTs
  // contractName is the name of the collection
  // uri is the base URI of the collection which should of this format "ipfs://CID/" 
  // CID is as hexadecimal hash string which you get after uploading an image on IPFS
  // ids is an array of index of different types of NFTs like cars nfts, house nfts, game nfts, etc this will have values starting from 0,1,2, ....
  // names is an array of different types of NFTs names as specified in ids for each index in ids there will be a corresponding name at the same index in names array.

  deployERC1155 = async (
    contractName: string,
    _uri: string,
    // ids: number[],
    // names: string[]
  ): Promise< object > => {
    try {
      console.log("inside deployERC1155 function");
      if(this.contractAbi){
        const interfac = new Interface(this.contractAbi);
        const eventSignature = interfac.getEvent('ERC1155Created')?.format();
        console.log("Event Signature:", eventSignature)
      }
      let collectionAddress = undefined;
      const event = await this.contractInstance?.on('ERC1155Created', ( tokenContract, owner, event) => {
        console.log('received event:', owner, tokenContract,'received event:', event);
        collectionAddress = tokenContract;
      });
      const transaction = await this.contractInstance?.deployERC1155(
        contractName,
        _uri,
        // ids,
        // names
      );
      // console.log("deployERC1155 in contract", transaction);
      const response = await transaction.wait(2);
      event?.on('ERC1155Created', (event) => {
        console.log('received event in next :', event);
      });
      // const receipt = await this.contractInstance?.deploymentTransaction()
       console.log( "event", event );
      console.log("response after deploying ===>", response);
      // if(event){
      //   event?.removeListener("ERC1155Created",()=>{});
      // }
     
      return {response,collectionAddress};
    } catch (err) {
      console.error(err);
      throw err;          
    }
  };

  getCurrentCollectionIndex = async()=>{
    console.log("In getCurrentCollectionIndex ");
    try{
      const collectionIndex = await this.contractInstance?.getCurrentTokenIndex();
      return collectionIndex;
    }catch(err){
      throw err;
    }
  }

  // This method gives the count of each type of NFTs  present corresponding to each type (ids)
  // id is an index in ids array
  // index is index of the collection which are present in our ecosystem, for example if the user creates a collection
  // it will get stored at index 0, if he creates other  then it will go to 1 and so on,
  // if the second user comes then his collection will go to next available index say 2, and so on 
  // this is independent of user ( collection index inside collections/tokens array )

  getCountERC1155byIndex = async (
    index: number,
    id: number
  ): Promise<number> => {
    try {
      return await this.contractInstance?.getCountERC1155byIndex(index, id);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // same as above function now instead of passing id we are passing corresponding name form names array 
  // this will give you the count of the NFTs present of a particular type which we are referring by name
  getCountERC1155byName = async (
    index: number,
    name: string
  ): Promise<number> => {
    try {
      return await this.contractInstance?.getCountERC1155byName(index, name);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };


  // It returns an object containing the following
  // 1 collection address
  // 2 colleection owner
  // 3 collection uri
  // 4 number of nfts at given id
  getERC1155byIndexAndId = async (
    index: number,
    id: number
  ): Promise<Object> => {
    try {
      return await this.contractInstance?.getERC1155byIndexAndId(index, id);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // this retuns the name from name array if you give the id and index of the collection;

  getNameById = async (index: number, id: number): Promise<string> => {
    try {
      return await this.contractInstance?.getNameById(index, id);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };



  getIdByName = async (index: number,nftname:string): Promise<string> => {
    try {
      return await this.contractInstance?.getIdByName(index,nftname);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };


  

  // this returns the deployed collection address when you pass the collection index
    indexToContract = async (index: number): Promise<string> => {
      try {
        return await this.contractInstance?.indexToContract(index);
      } catch (err) {
        console.error(err);
        throw err;
      }
    };

    contractToIndex = async (contractAddress: string): Promise<string> => {
    try {
      console.log("in contract to index address",contractAddress);
      const response =  await this.contractInstance?.contractToIndex(contractAddress);
      console.log("response", response)
      return response
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // this returns the address of the owner of a collection

  indexToOwner = async (index: number): Promise<string> => {
    try {
      return await this.contractInstance?.indexToOwner(index);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // this method  mints an nft in a given collection index
  // name is the name of the nft which you want to create 
  // amount the the number of copies you want to create i.e supply of the nft

  mintERC1155 = async (
    index: number,
    name: string,
    amount: number
  ): Promise<Object> => {
    try {
      console.log("in mint NFT contract function ==>", index, name, amount);
      let eventResponse;
      await this.contractInstance?.on("ERC1155Minted",(owner, tokenContract, amount)=>{
        console.log("NFT minted event owner, tokenAddress ==>", owner, tokenContract, amount);
          eventResponse = {
            owner, tokenContract, amount
          }
      })
      const tx = await this.contractInstance?.mintERC1155(index, name, amount);
      const receipt = await tx.wait(2);

      return {receipt, eventResponse};
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // this method gives the collection detail present at a particular index

  tokens = async (index: number): Promise<Contract> => {
    try {
      return await this.contractInstance?.tokens(index);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
}
