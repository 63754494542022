import {configureStore} from "@reduxjs/toolkit";
import walletReducer from "./slices/walletSlice";
import languageReducer from "./slices/languageSlice";
import GameProfileReducer from "./slices/gameUserMail";
import metaMaskSlice from "./slices/metaMaskSlice"

export const store = configureStore({
    reducer:{
        wallet:walletReducer,
        lang:languageReducer,
        gameUser:GameProfileReducer,
        metamask:metaMaskSlice
    }
})

export type RootState = ReturnType<typeof store.getState>;