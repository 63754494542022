
import Stack from "@mui/material/Stack";
import "./VoeuxIndex.css";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Grid from "@mui/material/Grid";
import ImgHolder1 from "../../assets/VoeuxMoeny/ImgHolder1.png";
import logo from "../../assets/VoeuxMoeny/metavoeux.png";
import Website from "../../assets/VoeuxMoeny/Website.svg";
import Divider from "@mui/material/Divider";
import telegram from "../../assets/VoeuxMoeny/telegram.svg";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import styles from "../../pages/nftLanding/NftLanding.module.css";
import { Button } from "@mui/material";
function IndividualAppDetail() {
 
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/full");
  };

  return (
    <>
      <Box
        className={styles.mainContainer}
        sx={{
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          pb: "30px",
          position: "relative",
          zIndex: 0,
        }}
      >
        <NewNftHeader nftHeader={true} />
        <div className="w-100">
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1}>
              <KeyboardArrowLeftIcon
                fontSize="large"
                className="arrow-left"
                onClick={() => handleBack()}
                sx={{ cursor: "pointer" }}
              />

              <a
                className="breadcrumb-title"
                style={{ cursor: "pointer" }}
                onClick={() => handleBack()}
              >
                Back
              </a>
            </Stack>
            <Grid container spacing={2} sx={{ columnGap: "30px" }}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <img src={ImgHolder1} alt=" "className="individual-app-image" />
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      className="app-logo-individual"
                    >
                      <img src={logo} alt="" />
                    </Stack>{" "}
                    <span className="app-title">Voeux Token Airdrop (VXT)</span>
                  </Stack>
                  <p className="individual-desc">
                    Let's join the airdrop of Voeux to have a chance to get
                    $5,000 in stable coin and tokens. Note that, rewards will be
                    airdropped to your METAONE WALLET, NOT MetaMask.
                  </p>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    columnGap="10px"
                  >
                    <img src={Website}  alt="" />
                    <span className="individual-desc">
                      www.faizandesign.com
                    </span>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    columnGap="10px"
                  >
                    <img src={Website}  alt="" />
                    <span className="individual-desc">
                      Live time: 4th Dec - 16th Dec 2023
                    </span>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    columnGap="10px"
                  >
                    <img src={Website}  alt="" />
                    <span className="individual-desc">
                      {" "}
                      Reward rule: Rewards will be only transferred to MetaOne
                      wallet accounts that own TomoOne pet
                    </span>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    columnGap="10px"
                  >
                    <img src={Website} alt="" />
                    <span className="individual-desc">
                      {" "}
                      Reward distribution: Rewards will be airdropped to your
                      METAONE WALLET, NOT MetaMask *** Lucky draw stablecoin:
                      {"\n"}- 40 slots, each gets 25${"\n"}- 60 slots, each gets
                      10${"\n"}- 80 slots, each gets 5${"\n"}
                      *** AAG token lucky draw{"\n"}- 100 slots, each gets 15$
                      in $AAG{"\n"}- 100 slots, each gets 10$ in $AAG{"\n"}- 100
                      slots, each gets 5$ in $AAG{"\n"}
                      Note:{"\n"}- Rewards will be airdropped to your submitted
                      METAONE WALLET, NOT MetaMask
                    </span>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack className="banner-lve-cards" spacing={1}>
                  <Stack alignItems="center" spacing={1}>
                    <Stack
                      className="live-container"
                      direction="row"
                      alignItems="center"
                    >
                      <span></span>
                      <p>LIVE</p>
                    </Stack>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: {
                        xs: "column",
                        md: "row",
                        alignItems: { xs: "start", md: "center" },
                      },
                    }}
                    direction="row"
                    spacing={1}
                    divider={
                      <Divider
                        orientation="vertical"
                        className="divider"
                        flexItem
                      />
                    }
                  >
                    <Stack className="live-container-details" spacing={1}>
                      <h6>START</h6>
                      <p>16 Nov 2023, 13:45</p>
                    </Stack>
                    <Stack className="live-container-details" spacing={1}>
                      <h6>END</h6>
                      <p>16 Jan 2024, 19:45</p>
                    </Stack>
                  </Stack>
                </Stack>
                <h6 className="task-style">Tasks</h6>
                <Stack
                  className="banner-lve-cards"
                  spacing={2}
                  direction="column"
                >
                  <Stack
                    sx={{
                      flexDirection: {
                        xs: "column",
                        md: "row",
                        alignItems: { xs: "start", md: "center" },
                      },
                    }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="task-detail-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        columnGap: "20px",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img src={telegram}  alt="" />
                      <span>Follow @ undeadscom on Twitter</span>
                    </div>

                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 1,
                        width: "fit-content",
                        background:
                          "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        fontSize: {
                          xs: 5,
                          sm: 7,
                          md: 12,
                          lg: 18,
                          xl: 18,
                        },
                      }}
                    >
                      Connect
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: {
                        xs: "column",
                        md: "row",
                        alignItems: { xs: "start", md: "center" },
                      },
                    }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="task-detail-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        columnGap: "20px",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img src={telegram}  alt=""  />
                      <span>Follow @ undeadscom on Twitter</span>
                    </div>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 1,
                        width: "fit-content",
                        background:
                          "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        fontSize: {
                          xs: 5,
                          sm: 7,
                          md: 12,
                          lg: 18,
                          xl: 18,
                        },
                      }}
                    >
                      Connect
                    </Button>
                  </Stack>{" "}
                  <Stack
                    sx={{
                      flexDirection: {
                        xs: "column",
                        md: "row",
                        alignItems: { xs: "start", md: "center" },
                      },
                    }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="task-detail-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        columnGap: "20px",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img src={telegram}  alt="" />
                      <span>Follow @ undeadscom on Twitter</span>
                    </div>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 1,
                        width: "fit-content",
                        background:
                          "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        fontSize: {
                          xs: 5,
                          sm: 7,
                          md: 12,
                          lg: 18,
                          xl: 18,
                        },
                      }}
                    >
                      Connect
                    </Button>
                  </Stack>{" "}
                  <Stack
                    sx={{
                      flexDirection: {
                        xs: "column",
                        md: "row",
                        alignItems: { xs: "start", md: "center" },
                      },
                    }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    className="task-detail-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        columnGap: "20px",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img src={telegram}  alt="" />
                      <span>Follow @ undeadscom on Twitter</span>
                    </div>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 1,
                        width: "fit-content",
                        background:
                          "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        fontSize: {
                          xs: 5,
                          sm: 7,
                          md: 12,
                          lg: 18,
                          xl: 18,
                        },
                      }}
                    >
                      Connect
                    </Button>
                  </Stack>{" "}
                  <Stack
                    sx={{
                      flexDirection: {
                        xs: "column",
                        md: "row",
                        alignItems: { xs: "start", md: "center" },
                      },
                    }}
                    direction={"row"}
                    alignItems="center"
                    justifyContent="space-between"
                    className="task-detail-container"
                  >
                    <div
                      style={{
                        display: "flex",
                        columnGap: "20px",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <img src={telegram}  alt="" />
                      <span>Follow @ undeadscom on Twitter</span>
                    </div>

                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: 1,
                        width: "fit-content",
                        background:
                          "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        fontSize: {
                          xs: 5,
                          sm: 7,
                          md: 12,
                          lg: 18,
                          xl: 18,
                        },
                      }}
                    >
                      Connect
                    </Button>
                    {/* <PrimaryButton text="Connect "/> */}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </div>
      </Box>

      <LandingPageFooter />
    </>
  );
}

export default IndividualAppDetail;
