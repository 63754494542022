import {
  Typography,
  Tooltip,
  TextField,
  Button,
  TextareaAutosize,
  Link,
} from "@mui/material";
import { Box } from "@mui/system";
import profileicon from "../../assets/images/profileicon.png";
import coverimage from "../../assets/images/coverimage.png";
import EditIcon from "@mui/icons-material/Edit";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useState } from "react";
import DragDrop from "../../utils/getS3ImageURL";
import axios from "axios";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { Environment } from "../../environments";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import { useNavigate } from "react-router-dom";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import personicon from "../../assets/images/person.png";
import DownloadIcon from "../../assets/svgs/Download.svg";
import DeleteIcon from "@mui/icons-material/Delete";
// import DownloadIcon from ""
function NewNftProfile() {
  const buttonBox = {
    border: "1px solid #FF990D",
    color: "white",
  };
  const [copyTooltip, setCopyTooltip] = useState(false);
  const [copyMobileTooltip, setCopyMobileTooltip] = useState(false);
  const handleAddressCopy = () => {
    setCopyTooltip(true);
    navigator.clipboard.writeText("0x96c4869380499A7151FEFB084b42BDED7B082220");
  };

  const [image, setImage] = useState(null);

  const handleImageChang = (m: any) => {
    const file = m.target.files[0];

    setImage(file);
  };

  const [selectedImage, setSelectedImage] = useState<any>();

  const handleImageChange = async (event: any) => {
    const file = event.target.files[0];
    try {
      var data = await DragDrop("profile", file);
      formdata.profilePic = data;
      console.log("profile",data )
      setSelectedImage(data);
    } catch (error) {
      showToastErrorMessageClick(
        "Failed in uploading the image. Please try again!"
      );
    }

    // handleUpload(file);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const [selectedCoverImage, setSelectedCoverImage] = useState<any>("");

  const handleCoverImage = async (event: any) => {
    const file = event.target.files[0];
    try {
      var data = await DragDrop("coverImage", file);
      setSelectedCoverImage(data);
      console.log("data",data)
      formdata.coverPic = data;
    } catch (error) {
      showToastErrorMessageClick(
        "Failed in uploading the image. Please try again!"
      );
    }

    // handleUpload(file);
  };
 

  const walletData = localStorage.getItem("account_address");

  const [userDetails, setUserDetails] = useState<any>({});
  const [formdata, setFormData] = useState<any>({});

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      console.log("fetchUserDetails == >");
      setShowLoader(true);
      try {
        // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
        const response = await axios.get(
          `${Environment.metavoeuxBackend}user/userDetails?walletAddress=${walletData}`
        );
        console.log("get the user details", response.data);
        // const profile = localStorage.getItem("profileIcon")
        // const bcakground =   localStorage.getItem("coverpic")
        if(response.data.profilePic){
          setSelectedImage(response.data.profilePic);
        }
        
        if(response.data.coverPic){
          setSelectedCoverImage(response.data.coverPic);
        }
       
        setUserDetails(response.data);
        setFormData(response.data);
        setShowLoader(false);
        // formdata.user  = response.data
        // handleShowLoader()
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      }
    };

    fetchUserDetails();

    // console.log("formdata",formdata);
  }, [walletData]);

  const navigate = useNavigate();
  const hanldeSaveProfileData = async () => {
    setShowLoader(true);
    formdata.userId = userDetails.userId;
    formdata.walletAddress = userDetails.walletAddress;
    formdata.publicAddress = [];
    formdata.isAllowed = true;
    formdata.isBlocked = false;
    formdata.profilePic = selectedImage;
    formdata.coverPic = selectedCoverImage;
    localStorage.setItem("profileIcon", selectedImage);
    localStorage.setItem("coverpic", selectedCoverImage);
    try {
      // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
      const response = await axios.put(
        `${Environment.metavoeuxBackend}user/update`,
        formdata
      );
      console.log("update the profile data", response.data);
      setFormData(response.data);

      setTimeout(() => {
        setShowLoader(false);
        displayToastMessage("Profile Updated Successfully");
      }, 2000);
      setTimeout(() => {
        navigate("/Userdetails");
      }, 2010);
      // setUserDetails(response.data)
      // formdata.user  = response.data
      // handleShowLoader()
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowLoader(false);
    }
  };

  const [showToastMessage, setshowToastMessage] = useState(false);
  const [toastMessage, settoastMessage] = useState("");
  const closeToastMessage = () => {
    setshowToastMessage(false);
  };
  const displayToastMessage = (text: any) => {
    settoastMessage(text);
    setshowToastMessage(true);
  };
  const handleImageDelete = () => {
    setSelectedCoverImage("");
  };
  return (
    <>
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />

      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            top: 0,
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <Box
        p={4}
        sx={{
          background:"#00042A",
          paddingTop: 0,
          "@media (max-width: 600px)": {
            padding: 2,
          },
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader nftHeader={true} />
        {/* cover photo */}
        <Box
          sx={{
            position: "relative",
            textAlign: "center",
            marginTop: { xs: "50px", lg: "20px" },
            padding: {
              xs: "60px 20px 50px 20px",
              lg: "100px 140px 140px 140px",
            },
            height: "339px",
            width: "auto",
            background: "rgba(217, 217, 217, 0.11)",
            boxShadow:
              "1.9700013399124146px -2.9550018310546875px 2.9550018310546875px rgba(152, 89, 36, 0.15) inset",
            borderRadius: "22px",
          }}
        >
        {selectedCoverImage == ""?
          <Box>
          <input
            type="file"
            accept="image/*,"
            style={{
              position: "absolute",
              left: "0px",
              width: "100%",
              right: 0,
              height: "100%",
              top: 0,
              opacity: "0",
            }}
            onChange={(e) => handleCoverImage(e)}
            // onChange={(e) => handleImageChange(e)}
          />
          <img
            src={DownloadIcon}
            style={{
              borderRadius: "30px",
              objectFit: "cover",
            }}
            alt=""
          />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: { xs: "15px", md: "17px" },
              fontWeight: 600,
              textAlign: "center",
              fontFamily: "outfit",
            }}
          >
            Change Cover Photo
          </Typography>
          <Link
            style={{ marginTop: "20px", color: "#FF990D" }}
            sx={{
              color: "#FF990D",
              fontSize: { xs: "12px", md: "18px" },
              fontWeight: 400,
              textDecoration: "none",
              textAlign: "center",
              cursor: "pointer",
              textDecorationLine: "underline",
            }}
          >
            Browse Files
          </Link>
          </Box>
           :
           <Box 
           sx={{    position: "absolute",
            top: 0,
            width: "100%",
            left: 0}}
            >
            <img
              src={selectedCoverImage}
              style={{
                width: "-webkit-fill-available",
                height: "339px",
                borderRadius: "30px",
                objectFit: "cover",
              }}
            />
            <Button
              sx={{
                position: "absolute",
                zIndex: 0,
                right: "8px",
                top: "8px",
                color: "white",
                padding:" 8px 6px",
                borderRadius: "100%",
                minWidth: "0px",
                background:"#464646",
                "&:hover":{
                  background:"#464646"
                }
              }}
              onClick={() => handleImageDelete()}
            >
              <DeleteIcon />
            </Button>
            </Box>

           }
          {/* Profile photo */}
          <Box
            sx={{
              position: "absolute",

              left: "0",
              right: "0",
              bottom: "-60px",
              margin: "auto",
              width: { xs: "100px", md: "127px" },
              height: { xs: "100px", md: "127px" },
              background: "#464646",
              boxShadow:
                "1.9700013399124146px -2.9550018310546875px 2.9550018310546875px rgba(152, 89, 36, 0.15) inset",
              borderRadius: "50%",
              border: "1px solid #fff",
              overflow: "hidden",
            }}
          >
            <input
              type="file"
              accept="image/*,"
              style={{
                position: "absolute",
                left: "0px",
                width: "100%",
                right: 0,
                height: "100%",
                top: 0,
                opacity: "0",
              }}
              onChange={(e) => handleImageChange(e)}
            />

            <Link
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                fontSize: "large",
                color: "#fff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={selectedImage ? selectedImage : personicon} alt="person" />
              </Box>
            </Link>
          </Box>
        </Box>

        <Box sx={{ textAlign: "center", marginTop: "60px" }}>
          <Link
            style={{ marginTop: "20px" }}
            sx={{
              color: "#FF990D",
              fontSize: { xs: "12px", md: "18px" },
              fontWeight: 400,
              textDecoration: "none",
              cursor: "pointer",
              textDecorationLine: "underline",
            }}
            onChange={(e) => handleImageChange(e)}
          >
             <input
              type="file"
              accept="image/*,"
              style={{
               
              width:"100%",
              
                position:"absolute",
                opacity: "0",
                left:"0"
              }}
              onChange={(e) => handleImageChange(e)}
            />
            Change Profile Image
          </Link>
        </Box>

        <Box
          sx={{
            padding: {
              xs: "20px 20px 160px 20px",
              md: "20px 160px 160px 160px",
            },
          }}
        >
          {/* username */}
          {/* Email address */}
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "10px",
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "15px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                    marginTop: { xs: "1rem", md: "3rem" },
                    marginBottom: "10px",
                  }}
                >
                  Username
                </Typography>
                <TextField
                  type="text"
                  name="fullName"
                  placeholder="Enter username"
                  fullWidth
                  required
                  inputProps={{ style: { color: "#777" } }}
                  sx={{
                    border: "1px solid #777777 ",
                    borderRadius: "12px",
                    color: "#828282",
                    width: "100%",
                  }}
                  value={formdata.fullName}
                  onChange={(e) =>
                    setFormData({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography
                  sx={{
                    color: "#F6EBFD",
                    fontSize: { xs: "15px", md: "20px" },
                    fontWeight: 500,
                    fontFamily: "outfit",
                    marginTop: { xs: "1rem", md: "3rem" },
                    marginBottom: "10px",
                  }}
                >
                  Email Address
                </Typography>
                <TextField
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  fullWidth
                  required
                  inputProps={{ style: { color: "#777" } }}
                  sx={{
                    border: "1px solid #777777 ",
                    borderRadius: "12px",
                    color: "#828282",
                    width: "100%",
                  }}
                  value={formdata.email}
                  onChange={(e) =>
                    setFormData({
                      ...formdata,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Box>
            </Box>
          </Box>
          {/* Bio */}
          <Box>
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "15px", md: "20px" },
                fontWeight: 500,
                fontFamily: "outfit",
                marginTop: { xs: "1rem", md: "2rem" },
                marginBottom: "10px",
              }}
            >
              Bio
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Tell the world your story"
              name="bio"
              style={{
                border: "1px solid #777",
                borderRadius: "12px",
                color: "#fff",
                width: "100%",
                background: "none",
                height: "120px",
                padding: "15px",
              }}
              onChange={(e) =>
                setFormData({ ...formdata, [e.target.name]: e.target.value })
              }
            />
          </Box>
          {/* Wallet Addresss */}
          <Box>
            <Typography
              sx={{
                color: "#F6EBFD",
                fontSize: { xs: "12px", md: "20px" },
                fontWeight: 500,
                fontFamily: "outfit",
                marginBottom: "10px",
                marginTop: { xs: "1rem", md: "2rem" },
              }}
            >
              Wallet Address{" "}
            </Typography>
            <Box
              sx={{
                height: "50px",
                width: "100%",
                border: "1px solid #7C7C7C",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <input
                type="text"
                placeholder="0x96c4869380499A7151FEFB084b42BDED7B082220"
                style={{
                  color: !copyTooltip ? "#fff" : "green",
                  border: "none",
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  padding: "0 10px",
                  background: "#00042A",
                }}
                value={userDetails.walletAddress}
                readOnly
              />
              <Tooltip title="Copied" open={copyTooltip}>
                <ContentCopyIcon
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAddressCopy()}
                  onMouseOut={() => setCopyTooltip(false)}
                />
              </Tooltip>
            </Box>
          </Box>
          {/* button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: { xs: "1rem", md: "2rem" },
            }}
          >
            <Button
              sx={{
                marginTop: "2rem",
                paddingLeft: "40px",
                paddingRight: "40px",
                textTransform: "none",
                fontSize: { xs: "9px", md: "14px" },
                marginBottom: "15px",
                background: "#FF990D",
                color: "white",
                "&:hover": {
                  background: "#FF990D",
                  color: "white",
                },
              }}
              onClick={() => hanldeSaveProfileData()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default NewNftProfile;
