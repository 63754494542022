import React from 'react';
import './ButtonLiner.css';
import { Button } from '@mui/material';

interface ButtonLinerProps {
  type: string;
  backgroundColor: string;
  onClick: () => void;
  children: React.ReactNode;
}

const ButtonLiner: React.FC<ButtonLinerProps> = ({backgroundColor, type, onClick, children }) => {
  const handleClick = () => {
    onClick();
  };

  const certicBorderGradient = {
    background: `linear-gradient(${backgroundColor} 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box`,
    border: "1px solid transparent",
    borderRadius: {xs:"5px",md:"2px"},
    cursor: "pointer",
    padding: {xs:"0%  1% 0% 2%",md:".5% 1% .5% 1%"},
    marginRight: {md:"20px"},
    backgroundColor: backgroundColor,
  };

  return (
    <Button
                onClick={handleClick}
              
                sx={{
                  ...certicBorderGradient,
                  width: { sm: "80%", md: "182px" },
                  height: { sm: "80%", md: "35px" },
                  border: "1px solid transparent",
                  borderRadius: "8px",             
                  mt: "2px", 
                  p:".7% 4%",
                  ml: { md: 0 },
                  ":hover":{
                    backgroundImage: "linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%)",
                  }
                }}
              >
              {children}
              </Button>
  );
};

export default ButtonLiner;
