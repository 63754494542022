import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  styled,
  alpha,
  Typography,
  createTheme,
  useTheme,
  useMediaQuery,
  Tooltip,
  Stack,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import menuIconFilled from "../../assets/svgs/menuIconFilled.svg";
import ProfileAvatr from "../../assets/svgs/ProfileAvatar.svg";
import ProfileLogout from "../../assets/svgs/Profilelogout.svg";
import logo from "../../assets/svgs/metavoeux_logo.svg";
import globe from "../../assets/svgs/GlobalLanguage.svg";
import walletIcon from "../../assets/svgs/wallet.svg";
import styles from "./NewNftHeader.module.css";
import profile from "../../assets/svgs/Ellipse 23.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiWalletConnect from "../muiconnectwallet/Muiconnect";
import MenuIcon from "@mui/icons-material/Menu";
import usa from "../../assets/Header/usa.png";
import uae from "../../assets/Header/uae.png";
import walletWhite from "../../assets/Header/walletWhite.svg";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { setlanguage } from "../../redux/slices/languageSlice";
import axios from "axios";
import nftmarketplaceicon from "../../assets/tooltipservice/NFT Marketplace.svg";
import nftmarketplaceyellowicon from "../../assets/tooltipservice/NFT Marketplaceyellow.svg";
import voeuxfarmingicon from "../../assets/tooltipservice/Voeux Farming.svg";
import voeuxfarmingyellowicon from "../../assets/tooltipservice/Voeux Farmingyellow.svg";
import voeuxmoneyicon from "../../assets/Header/voeuxmoneyicon.svg";
import voeuxtokenicon from "../../assets/tooltipservice/Voeux Token.svg";
import voeuxtokenyellowicon from "../../assets/tooltipservice/Voeux Tokenyellow.svg";
import voeuxgameicon from "../../assets/tooltipservice/Voeux Game.svg";
import voeuxgameyellowicon from "../../assets/tooltipservice/Voeux Gameyellow.svg";
import voeuxpayicon from "../../assets/tooltipservice/Voeux Pay.svg";
import voeuxpayyellowicon from "../../assets/tooltipservice/Voeux Payyellow.svg";
import { Environment } from "src/environments";
import ToastMessage from "../ToastMessage/ToastMessage";
import ToastMessageFailure from "../ToastMessage/ToastMessageFailure";

type Anchor = "right";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: "#00042A",
      main: "#00042A",
      dark: "#00042A",
      contrastText: "#fff",
    },
    secondary: {
      light: "#00042A",
      main: "#00042A",
      dark: "#00042A",
      contrastText: "#000",
    },
  },
});
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

interface RootState {
  lang: object; // Change 'string' to the actual type of your lang property
}

// const NewNftHeader = React.forwardRef(({ nftHeader = false,farmHeader = false }: { nftHeader?: boolean, farmHeader?:boolean}, ref) => (

const NewNftHeader = ({
  nftHeader = false,
  farmHeader = false,
  walletwhite = false,
}: {
  nftHeader?: boolean;
  farmHeader?: boolean;
  walletwhite?: boolean;
}) => {
  const location = useLocation();

  const [state, setState] = React.useState({
    right: false,
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [profileanchorEl, setprofileAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const profileOpen = Boolean(profileanchorEl);
  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [walletOpen, setWalletOpen] = React.useState(false);

  const handleProfileClose = () => {
    setAnchorEl(null);
  };
  const handleToggle = () => {
    setWalletOpen((prevOpen) => !prevOpen);
  };

  // const [ walletopen, setWalletOpen] = useState<any>(false)

  const handleCloseWallet = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    // handleLogout()
    localStorage.clear();
    setWalletOpen(false);
    navigate("/");
  };
  // focus out
  const handleCloseWalletClickAway = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setWalletOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [showLanguageDrop, setShowLanguageDrop] = useState(false);
  const [showCurrencyDrop, setShowCurrencyDrop] = useState(false);

  const [currency, setCurrency] = useState({
    icon: "$",
    label: "USD",
    value: "usdollar",
  });

  const currencyOptions = [
    {
      icon: "د. إ",
      label: "AED",
      value: "dirham",
    },
    {
      icon: "$",
      label: "USD",
      value: "usdollar",
    },
    {
      icon: "S.R",
      label: "SAR",
      value: "riyal",
    },
    {
      icon: "¥",
      label: "YEN",
      value: "yen",
    },
  ];
  const languageOption = [
    {
      icon: usa,
      label: "English",
      value: "en",
    },
    {
      icon: uae,
      label: "Arabic",
      value: "ar",
    },
  ];
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const account_address = localStorage.getItem("account_address");
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogin = () => {
    console.log("sdsd");
    setOpen(true);
    handleMenuClose();
  };

  const handleLogout = () => {
    console.log("sdsd");
    localStorage.clear();
    setOpen(false);
    handleMenuClose();
    handleLogoClick();
  };

  const walletData = localStorage.getItem("account_address");
  const [profileIcon, setProfileIcon] = useState<any>();
  useEffect(() => {
    const fetchUserDetails = async () => {
      console.log("fetchUserDetails == >");
      try {
        // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
        const response = await axios.get(
          `${Environment.metavoeuxBackend}user/userDetails?walletAddress=${walletData}`
        );
        console.log("response nft", response.data);
        // const profile = localStorage.getItem("profileIcon")
        // const bcakground =   localStorage.getItem("coverpic")
        const profileIcon = response.data.profilePic;
        setProfileIcon(profileIcon);
        // formdata.user  = response.data
        // handleShowLoader()
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchUserDetails();

    // console.log("formdata",formdata);
  }, [walletData]);

  // const profileicon= localStorage.getItem("profileIcon")
  // console.log("profileicon",profileicon)

  //   useEffect(() => {
  //     setProfileIcon(profileicon)
  //   },[profileicon])

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      className="profileMenu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{ top: "54px !important" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {account_address ? (
        <>
          {nftHeader && (
            <MenuItem
              sx={{
                background: "#00042A",
                padding: "20px 20px 10px 20px",
                "&:hover": {
                  background: "#00042A",
                },
              }}
              onClick={() => (navigate("/Userdetails"), handleMenuClose)}
            >
              <img src={ProfileAvatr} alt="" />
              <Typography variant="body2" sx={{ color: "white", ml: "10px" }}>
                Profile
              </Typography>
            </MenuItem>
          )}
          <MenuItem
            sx={{
              background: "#00042A",
              padding: "10px 20px 10px 20px",
              "&:hover": {
                background: "#00042A",
              },
            }}
            onClick={() => handleLogout()}
          >
            <ListItemIcon>
              <img src={ProfileLogout} alt="" />
            </ListItemIcon>
            <Typography variant="body2" sx={{ color: "white", ml: "10px" }}>
              Logout
            </Typography>
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem
            sx={{
              background: "#00042A",
              padding: "10px 20px 10px 20px",
              "&:hover": {
                background: "#00042A",
              },
            }}
            onClick={() => handleLogin()}
          >
            <ListItemIcon>
              <img src={ProfileLogout} alt="" />
            </ListItemIcon>
            <Typography variant="body2" sx={{ color: "white", ml: "10px" }}>
              Login
            </Typography>
          </MenuItem>
        </>
      )}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <AccountBalanceWalletIcon />
        </IconButton>
        <p>Connect Wallet</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          {/* <Badge badgeContent={17} color="error"> */}
          <NotificationsIcon />
          {/* </Badge> */}
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const handleLogoClick = () => {
    navigate("/");
  };

  const [fullWidth, setFullWidth] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);

  const [walletAddress, setWalletAddress] = useState<any>("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = () => {
    const newMaxWidth = isSmallScreen ? "sm" : "md";
    setMaxWidth(newMaxWidth);
  };

  const handleFullWidthChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFullWidth(event.target.checked);
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenLanguage, setTooltipOpenLanguage] = useState(false);
  const [tooltipOpenCurrency, setTooltipOpenCurrency] = useState(false);
  const [current, setcurrent] = useState("");

  const handleTooltipClose = (data: any) => {
    if (data === "menu") {
      setTooltipOpen(false);
    } else if (data === "lang") {
      setTooltipOpenLanguage(false);
    } else if (data === "currency") {
      setTooltipOpenCurrency(false);
    }
  };
  const handleTooltipOpen = (data: any) => {
    if (data === "menu") {
      setTooltipOpen(true);
    } else if (data === "lang") {
      setTooltipOpenLanguage(true);
    } else if (data === "currency") {
      setTooltipOpenCurrency(true);
    }
  };

  const handleNavigateFromMenu = (data: any) => {
    console.log(data, "data");

    //  -----services session----

    if (data === "NFT Marketplace") {
      navigate("/nft");
    } else if (data === "Voeux Farming") {
      navigate("/voeuxfarming");
    }
    // else if (data === "VX Money") {
    //   navigate("/voeuxmoney");
    // }
    else if (data === "Voeux Token") {
      navigate("/voeuxtoken");
    } else if (data === "Voeux Game") {
      navigate("/voeuxGame");
      // window.location.href = "/voeuxGame";
    } else if (data === "Voeux Pay") {
      navigate("/voeuxpay");
      // window.location.href = "https://voeuxpay.com/";
    }

    // ----learn session-----
    else if (data === "About Us") {
      navigate("/about");
    } else if (data === "Careers") {
      navigate("/career");
    } else if (data === "Contact Us") {
      navigate("/contact");
    } else if (data === "FAQs") {
      navigate("/faq");
    } else if (data === "Blog") {
      navigate("/blog");
    } else if (data === "Settings") {
      navigate("/Settingsmenubar");
    }
  };

  const handleHover = (item: any) => {
    setcurrent(item);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };
  const conWall = {
    display: "flex",
    width: "max-content",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#ff990d",
    cursor: "pointer",
    // padding: "0px 14px",
    textDecoration: "none",
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === "right" ? 300 : "auto",
        backgroundColor: "#1A0E1C",
        color: "white",
        height: "200%",
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box>
        <List>
          {/* Service */}

          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "outfit",
                color: "#FF990D",

                fontWeight: "500",
                marginBottom: "10px",
                marginLeft: 2,
              }}
            >
              Services
            </Typography>
          </Box>

          {[
            "NFT Marketplace",
            "Voeux Farming",
            // "VX Money",
            "Voeux Token",
            "Voeux Game",
            "Voeux Pay",
          ].map((text, index) => (
            <Box sx={{ fontFamily: "outfit" }}>
              <ListItem sx={{ fontFamily: "outfit" }} key={text} disablePadding>
                <ListItemButton
                  sx={{
                    "&:hover": {
                      backgroundColor: "orange",
                    },
                    fontFamily: "outfit",
                  }}
                  onClick={() => handleNavigateFromMenu(text)}
                >
                  <ListItemIcon sx={{ color: "white" }}>
                    {index % 6 === 0 ? (
                      <img src={nftmarketplaceicon} alt="" />
                    ) : null}
                    {index % 6 === 1 ? (
                      <img src={voeuxfarmingicon} alt="" />
                    ) : null}
                    {index % 6 === 2 ? <img src={voeuxpayicon} alt="" /> : null}
                    {index % 6 === 3 ? (
                      <img src={voeuxtokenicon} alt="" />
                    ) : null}
                    {index % 6 === 4 ? (
                      <img src={voeuxgameicon} alt="" />
                    ) : null}
                    {index % 6 === 5 ? (
                      <img src={voeuxmoneyicon} alt="" />
                    ) : null}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontFamily: "outfit" }}>
                      {text}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </Box>
          ))}

          {/* Learn */}
          {/* <Box
            sx={{
              borderTop: " 0.84px solid rgba(255, 255, 255, 0.30)",
              marginTop: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontFamily: "outfit",
                color: "#FF990D",

                fontWeight: "500",
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: 2,
              }}
            >
              Learn
            </Typography>
          </Box> */}
          {/* {["About Us", "Careers", "Contact Us", "FAQs", "Blog"].map(
            (text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  sx={{
                    "&:hover": {
                      backgroundColor: "orange",
                    },
                    fontFamily: "outfit",
                  }}
                  onClick={() => handleNavigateFromMenu(text)}
                >
                  <ListItemIcon sx={{ color: "white" }}>
                    {index % 6 === 0 ? <img src={aboutUs} /> : null}
                    {index % 6 === 1 ? <img src={Careers} /> : null}
                    {index % 6 === 2 ? <img src={Contact} /> : null}
                    {index % 6 === 3 ? <img src={Support} /> : null}
                    {index % 6 === 4 ? <img src={Blog} /> : null}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            )
          )} */}
        </List>
      </Box>
    </Box>
  );

  const dispatch = useDispatch();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [language, setLanguage] = useState<any>(globe);

  const handleCloseUserMenu = (data: any) => {
    console.log(data, "data");
    console.log("language", language);

    if (data.label === undefined || null) {
      setLanguage(language);
      setAnchorElUser(null);
      return false;
    }
    if (data.label !== "English") {
      dispatch(setlanguage("ar"));
    } else {
      dispatch(setlanguage("en"));
    }
    setLanguage(data.icon);
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const closedata = useSelector((state: any) => state.lang.closewallet);

  useEffect(() => {
    handleClose();
  }, [closedata]);

  const locationData = useLocation();

  // useEffect(() => {
  //   if (locationData.pathname === "/nftmarketplace") {
  //     const userToken = localStorage.getItem("token");
  //     if (!userToken) {
  //       setOpen(true);
  //     }
  //   } else {
  //     setOpen(false); // Close the modal or handle it accordingly
  //   }
  // }, [locationData.pathname]);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };
  return (
    <>
    <ToastMessage
    text={toastMessage}
    display={showToastMessage}
    handleClose={closeToastMessage}
  />
  <ToastMessageFailure
    text={toasterrorMessage}
    display={showToastErrorMessage}
    handleClose={closeToastErrorMessage}
  />
    <Box
      sx={{
        px: location.pathname === "/" ? 2 : 2,
        padding: { xs: "10px", lg: "0px !important" },
        "@media(max-width:300px)": {
          padding: "0px",
        },
      }}
    >
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          borderBottom: "none",
          px: { md: 0 },
        }}
      >
        <Toolbar
          className="3"
          sx={{
            height: "6rem",
            px: 0,
            padding: "0px !important",

            // padding: location.pathname === "/" ? "0 !important" :""
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              display: { xl: "none", sm: "flex", md: "none" },
              px: { xs: "10px", md: 0 },
              "@media(max-width:320px)": {
                width: "60%",
                padding: "0 10px",
                justifyContent: "flex-start",
              },
              "@media(max-width:290px)": {
                width: "60%",
                padding: "0 10px",
                justifyContent: "flex-start",
              },
            }}
          >
            <img className="sumi" src={logo} alt="logo" onClick={handleLogoClick} />
          </IconButton>
          <Typography
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <img
              src={logo}
              alt="logo"
              draggable="false"
              style={{ cursor: "pointer" }}
              onClick={handleLogoClick}
            />
            {/* hide the icon for bootstrap tooltip */}
            {/* {!nftHeader && ( */}
            <BootstrapTooltip
              open={tooltipOpen}
              className="custom-tooltip"
              onClose={() => handleTooltipClose("menu")}
              title={
                <Box sx={{ marginLeft: "25px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {/* Services Column */}
                    <Box>
                      <Stack direction="column" sx={{}}>
                        <Typography
                          sx={{
                            fontSize: "25px",
                            fontFamily: "outfit",
                            color: "#FF990D",
                          }}
                        >
                          Services
                        </Typography>

                        <Stack
                          onClick={() =>
                            handleNavigateFromMenu("NFT Marketplace")
                          }
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",

                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                            }}
                            onMouseEnter={() => handleHover("nftmarketplace")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "nftmarketplace"
                                  ? nftmarketplaceyellowicon
                                  : nftmarketplaceicon
                              }
                              alt=""
                              height="25px"
                              width="25px"
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                                fontSize: "18px",
                              }}
                            >
                              NFT Marketplace
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          onClick={() =>
                            handleNavigateFromMenu("Voeux Farming")
                          }
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",

                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                            }}
                            onMouseEnter={() => handleHover("voeuxfarming")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "voeuxfarming"
                                  ? voeuxfarmingyellowicon
                                  : voeuxfarmingicon
                              }
                              alt=""
                              height="25px"
                              width="25px"
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                                fontSize: "18px",
                              }}
                            >
                              Voeux Farming
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          onClick={() => handleNavigateFromMenu("Voeux Token")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // ml: "6px",
                            marginBottom: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",

                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                            }}
                            onMouseEnter={() => handleHover("voeuxtoken")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "voeuxtoken"
                                  ? voeuxtokenyellowicon
                                  : voeuxtokenicon
                              }
                              alt=""
                              height="25px"
                              width="25px"
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                                fontSize: "18px",
                              }}
                            >
                              Voeux Token
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box
                      sx={{
                        // flex: 1,
                        mt: "22px",
                        // marginLeft: 4,
                      }}
                    >
                      <Stack
                        direction="column"
                        sx={{ p: 1, px: 0, mt: 1, ml: 10 }}
                      >
                        <Stack
                          onClick={() => handleNavigateFromMenu("Voeux Game")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",

                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",

                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                            }}
                            onMouseEnter={() => handleHover("voeuxgame")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "voeuxgame"
                                  ? voeuxgameyellowicon
                                  : voeuxgameicon
                              }
                              alt=""
                              height="25px"
                              width="25px"
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                                fontSize: "18px",
                              }}
                            >
                              Voeux Game
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          onClick={() => handleNavigateFromMenu("Voeux Pay")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",

                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                            }}
                            onMouseEnter={() => handleHover("voeuxpay")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "voeuxpay"
                                  ? voeuxpayyellowicon
                                  : voeuxpayicon
                              }
                              alt=""
                              height="25px"
                              width="25px"
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                                fontSize: "18px",
                              }}
                            >
                              Voeux Pay
                            </Typography>
                          </Box>
                        </Stack>
                        {/* <Stack
                          onClick={() => handleNavigateFromMenu("VX Money")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",

                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "18px",
                              width: "18px",
                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                            }}
                            onMouseEnter={() => handleHover("voeuxmoney")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "voeuxmoney"
                                  ? voeuxmoneyyellowicon
                                  : voeuxmoneyicon
                              }
                              alt=""
                              height="100%"
                              width="100%"
                            />
                            <Typography
                              variant="body2"
                              sx={{ ml: 1, whiteSpace: "nowrap", fontFamily:"outfit" }}
                            >
                              VX Money
                            </Typography>
                          </Box>
                        </Stack> */}
                      </Stack>
                    </Box>

                    {/* Learn section */}
                    {/* <Box
                      sx={{
                        flex: 1,
                        borderLeft: " 0.84px solid rgba(255, 255, 255, 0.30)",
                        paddingLeft: 4,
                        // marginLeft: 4,
                      }}
                    >
                      <Stack direction="column" sx={{ p: 1, px: 0 }}>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "outfit",
                              color: "#FF990D",
                            }}
                          >
                            Learn
                          </Typography>
                        </Box>
                        <Stack
                          onClick={() => handleNavigateFromMenu("About Us")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "18px",
                              width: "18px",
                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                            }}
                            onMouseEnter={() => handleHover("aboutus")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "aboutus" ? yellowAboutUs : aboutUs
                              }
                              alt=""
                              height="100%"
                              width="100%"
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                              }}
                            >
                              About Us
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          onClick={() => handleNavigateFromMenu("Careers")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "18px",
                              width: "18px",
                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                            }}
                            onMouseEnter={() => handleHover("Careers")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "Careers" ? CareersYellow : Careers
                              }
                              alt=""
                              height="100%"
                              width="100%"
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                              }}
                            >
                              Career
                            </Typography>
                          </Box>
                        </Stack>

                        <Stack
                          onClick={() => handleNavigateFromMenu("Contact Us")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "18px",
                              width: "18px",
                            }}
                            onMouseEnter={() => handleHover("Contact")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={
                                current === "Contact" ? Contactyellow : Contact
                              }
                              alt=""
                              height="100%"
                              width="100%"
                            />

                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                              }}
                            >
                              Contact Us
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          onClick={() => handleNavigateFromMenu("FAQs")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "18px",
                              width: "18px",
                            }}
                            onMouseEnter={() => handleHover("FAQs")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={current === "FAQs" ? SupportYellow : Support}
                              alt=""
                              height="100%"
                              width="100%"
                            />

                            <Typography
                              variant="body2"
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                fontFamily: "outfit",
                              }}
                            >
                              FAQs
                            </Typography>
                          </Box>
                        </Stack>
                        <Stack
                          onClick={() => handleNavigateFromMenu("Blog")}
                          sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            // ml: "6px",
                          }}
                        >
                          <Box
                            sx={{
                              ":hover": {
                                color: "orange",
                                cursor: "pointer",
                              },
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "18px",
                              width: "18px",
                            }}
                            onMouseEnter={() => handleHover("Blog")}
                            onMouseLeave={() => handleHover("")}
                          >
                            <img
                              src={current === "Blog" ? Blogyellow : Blog}
                              alt=""
                              height="100%"
                              width="100%"
                            />

                            <Typography
                              variant="body2"
                              sx={{ ml: 1, whiteSpace: "nowrap" }}
                            >
                              Blog
                            </Typography>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box> */}
                  </Box>
                </Box>
              }
              // arrow
            >
              <IconButton onMouseEnter={() => handleTooltipOpen("menu")}>
                <img src={menuIconFilled} alt="" height={"100%"} />
              </IconButton>
            </BootstrapTooltip>
            {/* )} */}
            {/* search for NFT Module */}

            {/* search for NFT Module */}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "center",
              gap: 0,
              position: "relative",
            }}
          >
            <Box
              // className={
              //   isHovered
              //     ? `${styles.borderGradient} ${styles.hovered}`
              //     : styles.borderGradient
              // }
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              sx={{
                marginRight: "0px !important",
                padding: "5px 16px",
                background:
                  "linear-gradient(#1d1d1d 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
                border: "1px solid transparent",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                columnGap: "6px",
                "@media (max-width: 400px)": {
                  padding: "5px 5px",
                  columnGap: "4px",
                },
              }}
            >
              {/* <div 
                // className={styles.con_wall}
                 style={{marginRight:"0px !important",padding:"0px !important"}}> */}
              <img
                src={isHovered ? walletWhite : walletIcon}
                className={styles.walletIcon}
                alt="wallet icon"
                style={{ height: "16px" }}
              />
              {/* <p
                  className={styles.connectWalletText}
                  onClick={handleClickOpen}
                >
                  Connect Wallet
                </p> */}

              {!account_address ? (
                <p
                  // className={styles.connectWalletText}
                  onClick={handleClickOpen}
                  style={{ margin: "0px !important", fontSize: "14px" }}
                >
                  Wallet
                </p>
              ) : (
                <Button
                  className={styles.connectWalletText}
                  // onClick={handle}
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={walletOpen ? "composition-menu" : undefined}
                  aria-expanded={walletOpen ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    padding: "0px !important",
                    lineHeight: "20px",
                    "@media(max-width:300px)": {
                      minWidth: "0px !important",
                    },
                  }}
                >
                  {account_address.slice(0, 2) +
                    ".." +
                    account_address.slice(-2)}
                </Button>
              )}
              {/* </div> */}
            </Box>

            <div>
              <Popper
                open={walletOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{
                  background: "grey",
                  zIndex: 1,
                  transform: "none !important",
                  top: "33px !important",
                  left: "10px !important",
                  "@media (max-width: 400px)": {
                    left: "0px !important",
                  },
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={handleCloseWalletClickAway}
                      >
                        <MenuList
                          autoFocusItem={walletOpen}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleCloseWallet}
                          className="walletLogout"
                          sx={{ p: 0 }}
                        >
                          <MenuItem
                            sx={{
                              background: "#00042A",
                              padding: "10px 20px 10px 20px",
                              color: "white",
                              "&:hover": {
                                background: "#00042A",
                              },
                            }}
                            onClick={handleCloseWallet}
                          >
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
              gap: 0,
            }}
          >
            <div style={{ position: "relative" }}>
              <div
                className={
                  isHovered
                    ? `${styles.borderGradient} ${styles.hovered}`
                    : styles.borderGradient
                }
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                style={{ marginRight: "0px !important" }}
              >
                <div
                  className={styles.con_wall}
                  style={{ marginRight: "0px !important" }}
                >
                  <img
                    src={isHovered ? walletWhite : walletIcon}
                    className={styles.walletIcon}
                    alt="wallet icon"
                  />
                  {/* <p
                  className={styles.connectWalletText}
                  onClick={handleClickOpen}
                >
                  Connect Wallet
                </p> */}

                  {!account_address ? (
                    <p
                      className={styles.connectWalletText}
                      onClick={handleClickOpen}
                    >
                      Connect Wallet
                    </p>
                  ) : (
                    <Button
                      className={styles.connectWalletText}
                      // onClick={handle}
                      ref={anchorRef}
                      id="composition-button"
                      aria-controls={
                        walletOpen ? "composition-menu" : undefined
                      }
                      aria-expanded={walletOpen ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      sx={{ color: "white" }}
                    >
                      {account_address.slice(0, 6) +
                        "....." +
                        account_address.slice(-6)}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <>
              <div>
                <Popper
                  open={walletOpen}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ background: "grey", zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={handleCloseWalletClickAway}
                        >
                          <MenuList
                            autoFocusItem={walletOpen}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleCloseWallet}
                            className="walletLogout"
                            sx={{ p: 0 }}
                          >
                            <MenuItem
                              sx={{
                                background: "#00042A",
                                padding: "10px 20px 10px 20px",
                                color: "white",
                                "&:hover": {
                                  background: "#00042A",
                                },
                              }}
                              onClick={handleCloseWallet}
                            >
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </>
            {/* nft header */}
            {farmHeader && (
              <>
                <Box sx={{ position: "relative" }}>
                  <Box>
                    <IconButton
                      aria-label="profile"
                      onClick={handleProfileClick}

                      // aria-controls={profileOpen ? 'account-menu' : undefined}
                      // aria-haspopup="true"
                      // aria-expanded={profileOpen ? 'true' : undefined}
                    >
                      <img
                        src={profileIcon ? profileIcon : profile}
                        width="44px"
                        height="44px"
                        style={{ borderRadius: "100%" }}
                      />
                    </IconButton>
                  </Box>

                  {renderMenu}
                </Box>
              </>
            )}
            {nftHeader && (
              <>
                {/* <Box
                  sx={{ background: "#4B454D", borderRadius: "100%", p: 0.3 }}
                >
                  <IconButton aria-label="cart" sx={{ color: "white" }}>
                    <ShoppingCartIcon />
                  </IconButton>
                </Box> */}
                <Box sx={{ position: "relative" }}>
                  <Box>
                    <IconButton
                      aria-label="profile"
                      onClick={handleProfileClick}

                      // aria-controls={profileOpen ? 'account-menu' : undefined}
                      // aria-haspopup="true"
                      // aria-expanded={profileOpen ? 'true' : undefined}
                    >
                      <img
                        src={profileIcon ? profileIcon : profile}
                        width="44px"
                        height="44px"
                        style={{ borderRadius: "100%" }}
                      />
                    </IconButton>
                  </Box>

                  {renderMenu}
                </Box>
              </>
            )}

            <div
              onMouseOver={() => setShowLanguageDrop(true)}
              onMouseLeave={() => setShowLanguageDrop(false)}
              style={{
                margin: 0,
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!nftHeader && !farmHeader && (
                <>
                  {" "}
                  <Tooltip
                    // style={{ marginRight: "14px" }}
                    title="Open Language"
                  >
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ margin: 0, padding: 0, display: "none" }}
                    >
                      <img
                        style={{ margin: 0 }}
                        src={language}
                        alt="language icon"
                        height={"26px"}
                        width={"26px"}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      style: {
                        background: "#272727",
                      },
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {languageOption.map((val, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleCloseUserMenu(val)}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            px: 4,
                            py: 1,
                            whiteSpace: "nowrap",
                            "&:hover": {
                              width: "100%",
                              backgroundColor: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <img
                              src={val.icon}
                              alt=""
                              height="100%"
                              width="100%"
                            />
                            <Typography
                              variant="body2"
                              sx={{ ml: 1, color: "#fff" }}
                            >
                              {val.label}
                            </Typography>
                          </Box>
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </div>
            {!nftHeader && !farmHeader && (
              <div>
                <p>
                  {/* <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    <BootstrapTooltip
                      open={tooltipOpenCurrency}
                      onClose={() => handleTooltipClose("currency")}
                      title={
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          {currencyOptions.map((val, id) => (
                            <Typography
                              variant="body2"
                              sx={{
                                p: 1,
                                whiteSpace: "nowrap",
                                "&:hover": {
                                  width: "100%",
                                  backgroundColor: "orange",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {val.icon}&nbsp;&nbsp;{val.label}
                            </Typography>
                          ))}
                        </Box>
                      }
                      arrow
                    >
                      <IconButton
                        onClick={() => handleTooltipOpen("currency")}
                        sx={{ color: "#fff", fontSize: "15px" }}
                      >
                        {currency.icon}&nbsp;{currency.label}
                        <img
                          src={caretIcon}
                          alt="caret icon"
                          style={{
                            marginLeft: "1px",
                          }}
                        />
                      </IconButton>
                    </BootstrapTooltip>
                  </span> */}
                </p>
              </div>
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            {(["right"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={toggleDrawer(anchor, true)}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={() => toggleDrawer(anchor, false)}
                >
                  <div
                    style={{
                      backgroundColor: "#1A0E1C",
                      display: "flex",
                      alignItems: "right",
                      justifyContent: "right",
                      position: "absolute",
                      zIndex: 1,
                      right: 10,
                      top: 13,
                    }}
                  >
                    <button
                      onClick={toggleDrawer(anchor, false)}
                      style={{
                        backgroundColor: "#1A0E1C",
                        color: "#fff",
                        border: "none",
                        fontSize: "16px",
                      }}
                    >
                      X
                    </button>
                  </div>
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}

      <div></div>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent", // Set the dialog background to transparent
            boxShadow: "none", // Remove the default Material-UI box shadow
          },
        }} // Apply backdrop filter for transparency effect
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        {/* <DialogTitle>Optional sizes</DialogTitle> */}
        <DialogContent sx={{ padding: "0px" }}>
          <MuiWalletConnect
            handleClose={() => handleClose()}
            buyConnect={false}
            showToastMessageClick={showToastMessageClick}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </Box>
    </>
  );
};

export default NewNftHeader;
