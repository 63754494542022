//SigninGame.tsx
import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function SigninGame({handleSignInClose}:{handleSignInClose:()=> void}) {
  const location = useLocation();
  const navigate = useNavigate();
  const userProfile = () => {
    navigate("/gameprofile");
  };

  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    handleSignInClose();
  };

  return (
    <>
      {isOpen && (
        <Box >
          <Box
            className="polygonStyle2"
            sx={{
              display: "flex",
              flexDirection: "column",
              background: "#181818",
              padding: "24px 41px",
              position: "relative",
              // backdropFilter: "blur(10.781231880187988px)",
              width:{md:"564px",xs:"100%"} ,
              height: "331px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "right",
                justifyContent: "right",
                position: "absolute",
                right: "0px",
              }}
            >
              <Button
                sx={{ color: "white", fontSize: "20px" }}
                onClick={handleClose}
              >
                X
              </Button>
            </Box>

            <Typography
              className="refinary25"
              sx={{
                textAlign: "center",
                fontSize: " 23.99px;",
                color: "#fff",
                fontFamily: "Refinery 25 Bold",
                fontWeight: "400px",
                mb: 2,
                textTransform: "uppercase",
              }}
            >
              Sign in
            </Typography>
            <Box
                  className=" inputpolygonStyle"
                  sx={{
                    border: `1px solid #818181`,
                    // padding: "2px",
                    width:"100%",
                    marginTop: "20px",
                    background: `transparent`,
                    position:"relative"
                  }}
                  
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(52deg)",
                      top: "-16px",
                      left: "8px",
                    }}
                  ></Box>
                 <TextField
              sx={{
               
                // height: "40px",
                width: "100%",
                "& .MuiInputBase-root": {
                  position: "relative",
                 
                },
                "& input": {
                  outline: "none",
                  border: "0.65px solid #818181",
                  color: "white",
                  background: "transparent",
                  paddingRight: "30px",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              type="textfield"
              id="text"
              placeholder="Enter your email"
              variant="outlined"
            />
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(55deg)",
                      bottom: "-12px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box
                  className=" inputpolygonStyle"
                  sx={{
                    border: `1px solid #818181`,
                    // padding: "2px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: "100%",
                    background: `transparent`,
                    position:"relative"
                  }}
                  
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(52deg)",
                      top: "-16px",
                      left: "8px",
                    }}
                  ></Box>
                 <TextField
              sx={{
               
                // height: "40px",
                width: "100%",
                "& .MuiInputBase-root": {
                  position: "relative",
                 
                },
                "& input": {
                  outline: "none",
                  border: "0.65px solid #818181",
                  color: "white",
                  background: "transparent",
                  paddingRight: "30px",
                },
                "& fieldset": {
                  border: "none",
                },
              }}
              type="textfield"
              id="text"
              placeholder="Enter your password"
              variant="outlined"
            />
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(55deg)",
                      bottom: "-12px",
                      right: "0px",
                    }}
                  ></Box>
                </Box>
                <Box
                  className="inputpolygonStyle BlenderProBold"
                  sx={{
                    border: `1px solid #ECE218`,
                    padding: "2px",
                   
                    width: "100%",
                    background: `#ECE218`,
                    textAlign:"center"
                  }}
                  onClick={userProfile}
                >
                 
                  <Button
                    className="BlenderProBold"
                    sx={{
                      borderColor: "white",
                      padding: "10px 16px",
                      color: "#000",
                      fontSize:"20px",
                     
                      "&:hover": {
                        background: `#ECE218`,
                        color: "#000",
                      },
                    }}
                   
                  >
                    SIGNIN
                  </Button>
                 
                </Box>
            
          </Box>
        </Box>
      )}
    </>
  );
}

export default SigninGame;
