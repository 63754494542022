import { Box } from "@mui/system";
import React from "react";
import cars from "../../assets/VoeuxGame/Multiple Cars.png";
import carsMobile from "../../assets/VoeuxGame/carMobile.png";
import { Typography ,useMediaQuery} from "@mui/material";



function MulticarGame() {
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "56px",
        background:
          "radial-gradient(50% 49.66% at 50% 49.66%, #464646 0%, #000 100%)",
      }}
    >
      <Typography
        className="BlenderProBold"
        sx={{
          color: "#E31717",
          fontSize: "24px",
          fontWeight: "500",
          textTransform: "uppercase",
          textAlign: "center",
          // marginTop: "25px",
        }}
      >
        {" "}
        Crypto Dash
      </Typography>
      <Typography
        className="refinary25"
        sx={{
          color: "#fff",
          fontSize: { xs: "20px", md: "36px", lg: "56px" },
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        It's not just about speed, it's about <br />
        Accelerating to Assets.
      </Typography>

      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          ...circleStyle,
          top: { xl: "40%" },
          left: "0%",
          transform: "translateY(-70%)",
          width: "300px", // Adjusted width
          height: "300px", // Adjusted height
          borderRadius: "547.625px",
          background: "#fff",
          filter: "blur(160px)",
          backdropFilter:"blur(160px)",
          WebkitBackdropFilter:"blur(160px)",
          overflow: "hidden",
          "@media (min-width: 1721px)": {
            width: "450px", // Adjusted width
            height: "700px", // Adjusted height
            top: { xl: "0%" },

            right: "-20%",
            filter: "blur(160px)", 
            backdropFilter:"blur(160px)",
            WebkitBackdropFilter:"blur(160px)",

            
          },
        }}
      ></Box>
      {isMobile ?  <img
        draggable="false"
        src={carsMobile}
        alt="cars"
        style={{
          width: "100%",
          height: "auto",
          display: "block",
          marginTop: "48px",
        }}
      /> :  <img
      draggable="false"
      src={cars}
      alt="cars"
      style={{
        width: "100%",
        height: "auto",
        display: "block",
        marginTop: "48px",
      }}
    /> }
     
    </Box>
  );
}

export default MulticarGame;
