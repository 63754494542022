import { Contract } from "ethers";
import { ContractInstance } from "./contractInstance";

export class RewardFarm {
  contractInstance: Contract |null;

  constructor(
    REWARDFARM_CONTRACT_ADDRESS: string,
    REWARDFARM_CONTRACT_ABI: any[]
  ) {
    this.contractInstance = null;
    let contract = new ContractInstance();
     contract.buildContractInstance(
      REWARDFARM_CONTRACT_ADDRESS,
      REWARDFARM_CONTRACT_ABI
    ).then(contIns=>{
      this.contractInstance = contIns;
    });
  }

  getBalance = async (address: string): Promise<number> => {
    try {
    console.log("in getBalance contract function");
      const bal =  await this.contractInstance?.balanceOf(address);
      console.log("fetched balance in contract ==>",bal);
      return bal;
    } catch (err) {
      console.log("getBalance",err);
      throw err;
    }
  };

  getDuration = async (): Promise<number> => {
    try {
      return await this.contractInstance?.duration();
    } catch (err) {
      console.log("getDuration",err);
      throw err;
    }
  };

  getEarnings = async (_account: string): Promise<number> => {
    try {
      console.log("getEaringsreward farm",this.contractInstance);
      const earning =  await this.contractInstance?.earned(_account);
      console.log("fetched earning in contract ==>", earning);
      return earning;
    } catch (err) {
      console.log("getEarnings",err);
      throw err;
    }
  };

  getFinishTime = async (): Promise<number> => {
    try {
      return await this.contractInstance?.finishAt();
    } catch (err) {
      console.log("getFinishTime",err);
      throw err;
    }
  };

  getlastTimeRewardApplicable = async (): Promise<number> => {
    try {
      return await this.contractInstance?.lastTimeRewardApplicable();
    } catch (err) {
      console.log("getlastTimeRewardApplicable",err);
      throw err;
    }
  };

  notifyRewardAmount = async (_amount: BigInt): Promise<void> => {
    try {
      
      return await this.contractInstance?.notifyRewardAmount(_amount);
    } catch (err) {
      console.log("notifyRewardAmount",err);
      throw err;
    }
  };

  getFarmOwner = async (): Promise<string> => {
    try {
      return await this.contractInstance?.owner();
    } catch (err) {
      console.log("getFarmOwner",err);
      throw err;
    }
  };

  getRewardPerTokenValue = async (): Promise<number> => {
    try {
      return await this.contractInstance?.rewardPerToken();
    } catch (err) {
      console.log("getRewardPerTokenValue",err);
      throw err;
    }
  };

  getRewardPerTokenStoredValue = async (): Promise<number> => {
    try {
      return await this.contractInstance?.rewardPerTokenStored();
    } catch (err) {
      console.log("getRewardPerTokenStoredValue",err);
      throw err;
    }
  };

  getRewardRate = async (): Promise<number> => {
    try {
      return await this.contractInstance?.rewardRate();
    } catch (err) {
      console.log("getRewardRate",err);
      throw err;
    }
  };

  getRewards = async (address: string): Promise<number> => {
    try {
      return await this.contractInstance?.rewards(address);
    } catch (err) {
      console.log("getRewards",err);
      throw err;
    }
  };

  getRewardsToken = async (): Promise<string> => {
    try {
      return await this.contractInstance?.rewardsToken();
    } catch (err) {
      console.log("getRewardsToken",err);
      throw err;
    }
  };

  setRewardsDuration = async (_duration: number): Promise<void> => {
    try {
      return await this.contractInstance?.setRewardsDuration(_duration);
    } catch (err) {
      console.log("setRewardsDuration",err);
      throw err;
    }
  };

  investStake = async (_amount: BigInt): Promise<object> => {
    try {
      console.log("in investStake ==>", _amount);
      const stakeResp = await this.contractInstance?.stake(_amount);
      stakeResp.wait();
      return stakeResp;
    
    } catch (err) {
      console.log("investStake :",err);
      throw err;
    }
  };

  getStakingToken = async (): Promise<string> => {
    try {
      return await this.contractInstance?.stakingToken();
    } catch (err) {
      console.log("getStakingToken",err);
      throw err;
    }
  };

  getTotalSupply = async (): Promise<number> => {
    try {
      return await this.contractInstance?.totalSupply();
    } catch (err) {
      console.log("getTotalSupply",err);
      throw err;
    }
  };

  getUpdatedTimeStamp = async (): Promise<string> => {
    try {
      return await this.contractInstance?.updatedAt();
    } catch (err) {
      console.log("getUpdatedTimeStamp",err);
      throw err;
    }
  };

  getUserRewardPerTokenPaid = async (address: string): Promise<number> => {
    try {
      return await this.contractInstance?.userRewardPerTokenPaid(address);
    } catch (err) {
      console.log("getUserRewardPerTokenPaid",err);
      throw err;
    }
  };

  withdrawToken = async (_amount: BigInt): Promise<object> => {
    try {
      console.log("withdrawToken contract",_amount);
      return await this.contractInstance?.withdraw(_amount);
    } catch (err) {
      console.log("withdrawToken",err);
      throw err;
    }
  };

  getRemainingTIme = async() =>{
    try{
      const time = await this.contractInstance?.getTimeLeft();
      return time;
    }catch (err) {
      console.error(err);
      throw err;
    }
  }
}
