import React, { useState } from "react";
import newsletterlogo from "../../assets/VoeuxGame/Newsletterlogo.svg";
import { Box } from "@mui/system";
import { Button, Typography, useMediaQuery } from "@mui/material";
import backgroundNews from "../../assets/VoeuxGame/background news.png";
import char1 from "../../assets/VoeuxGame/charc1.png";
import char2 from "../../assets/VoeuxGame/char2.png";
import axios from "axios";
import { Environment } from "src/environments";
import ToastErrorComponenet from "../../components/ToastMessage/ToastMessageFailure";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function NewsGame() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [showEmail, setshowEmail] = useState("");

  const handleEmailChange = (event: any) => {
    const email = event.target.value;
    console.log("Email entered:", email);
    setshowEmail(email);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = async () => {
    const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!pattern.test(showEmail)) {
      showToastErrorMessageClick("Please provide valid email address");
      return false;
    }
    try {
      const response = await axios.post(
        `${Environment.metavoeuxBackend}news-letter`,
        {
          email: showEmail,
        }
      );
      console.log("API response:", response.data);
      SnackbarOpen();
      setOpen(true);
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };

  
  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  // Snackbar

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const SnackbarOpen = () => {
    setOpenSnackbar(true);
  };

  const SnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <ToastErrorComponenet
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      <Box
        sx={{
          // backgroundImage: `url(${newsletterimg})`,
          draggable: "false",
          backgroundSize: { xs: "cover", md: "100%" },
          backgroundRepeat: "no-repeat",
          background: "#ECE218",
          // backgroundPosition: "center",
          position: "relative",
          width: "100%",
          // height: "400px",
          padding: { md: "40px 20px 65px 20px", xs: "20px" },
          "@media (max-width: 768px)": {
            height: "auto",
            backgroundPosition: { xs: "center" },
          },
        }}
      >
        <img
          src={backgroundNews}
          alt=""
          style={{
            position: "absolute",
            zIndex: "0",
            width: "calc(100% - 20px)",
            bottom: 0,
          }}
        />
        <Box
          sx={{
            display: { lg: "block", md: "none", sm: "none", xs: "none" },
          }}
        >
          <img
            src={char1}
            alt=""
            style={{
              position: "absolute",
              zIndex: "0",
              left: 0,
              bottom: 0,
              height: "90%",
            }}
          />
          <img
            src={char2}
            alt=""
            style={{
              position: "absolute",
              zIndex: "0",
              right: 0,
              bottom: 0,
              height: "90%",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: { md: "106px", xs: "40px" },
            height: { md: "106px", xs: "40px" },
            margin: "auto",
          }}
        >
          <img draggable="false" src={newsletterlogo} alt="newslogo" />
        </Box>
        <Typography
          className="refinary25"
          sx={{
            fontSize: { md: "36px", xs: "23px" },
            fontWeight: "400px",
            color: "black",
            textAlign: "center",
            marginTop: "16px",
          }}
        >
          SIGN UP FOR THE OFFICIAL VOEUX GAME NEWSLETTER
        </Typography>
        {isMobile ? (
          <Typography
            className="blendarPro"
            sx={{
              fontFamily: " Blender Pro",
              fontSize: { md: "24px", xs: "16px" },
              fontWeight: "400px",
              color: "black",
              textAlign: "center",
              textTransform: "uppercase",
              marginTop: "16px",
            }}
          >
            From games to beyond, keep your feed up to date with the latest news
            and announcements on all things VOEUXGAMES!
          </Typography>
        ) : (
          <Typography
            className="blendarPro"
            sx={{
              fontFamily: " Blender Pro",
              fontSize: { md: "24px", xs: "16px" },
              fontWeight: "400px",
              color: "black",
              textAlign: "center",
              textTransform: "uppercase",
              marginTop: "16px",
              width: { xl: "42%", lg: "45%", md: "45%", sm: "45%" },
              margin: "auto",
            }}
          >
            From games to beyond, keep your feed up to date with the latest news
            and announcements on all things voeuxgames!
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: { md: "55%", sm: "65%", xs: "100%" },
            margin: "auto",
            marginTop: { md: "64px", xs: "38px" },
            position: "relative",
            zIndex: "3",
          }}
        >
          <input
            type="Email"
            placeholder="Enter your email address"
            className="blendarPro"
            style={{
              border: "none",
              borderBottom: "1px solid #181818",
              padding: "5px 10px",
              outline: "none",
              background: "transparent",
              maxWidth: "100%",
              width: "100%",
              fontSize: "20px",
              color: "#717171",
            }}
            onChange={handleEmailChange}
          />
          <Box
            className="polygonStyle2Button"
            sx={{
              border: "2px solid black",

              position: "relative",
              paddingLeft: "5px",
            }}
          >
            <Box
              sx={{
                width: "2px",
                height: "100%",
                background: `black`,
                position: "absolute",
                transform: "rotate(53deg)",
                top: "-14px",
                left: "4px",
                zIndex: 1,
              }}
            ></Box>
            <Button
              className="BlenderProBold"
              sx={{
                borderColor: "black",
                padding: { md: "10px 46px", xs: "7px 28px" },
                background: "transparent",
                color: "Black",
              }}
              onClick={() => handleOpen()}
            >
              Submit
            </Button>
            <Box
              sx={{
                width: "2px",
                height: "100%",
                background: `black`,
                position: "absolute",
                transform: "rotate(53deg)",
                bottom: "-9px",
                right: "-4px",
                zIndex: 1,
              }}
            ></Box>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={openSnackbar}
            autoHideDuration={1000}
            onClose={SnackbarClose}
            message="Thank you for subscribing"
            action={
              <>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={SnackbarClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
            style={{ position: "fixed" }}
          />
        </Box>
      </Box>
    </>
  );
}

export default NewsGame;
