import { Grid, Typography, CardActionArea, useMediaQuery } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import NewNftHeader from "../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../components/landingPageFooter/LandingPageFooter";
import AnupSrivastav from "../assets/AboutUs/Anup Srivastav.png";
import AbhijeetDeshmane from "../assets/AboutUs/Abhijeet Deshmane.png";
import SonalDaphale from "../assets/AboutUs/SonalDaphale.png";
import VarshaKamble from "../assets/AboutUs/VarshaKamble.png";
import MuhammadHashim from "../assets/AboutUs/MuhammadHashim.png";
import ParitoshChaudhary from "../assets/AboutUs/Paritosh Chaudhary.png";
import ZuhaibAhmedFarooqui from "../assets/AboutUs/ZuhaibAhmedFarooqui.png";
import HimanshuBharti from "../assets/AboutUs/Himanshu Bharti.png";
import SumiMolS from "../assets/AboutUs/Sumi  Mol S.png";
import MohammadSaqlain from "../assets/AboutUs/Mohammad Saqlain.png";
import RijanShreshtha from "../assets/AboutUs/Rijan Shreshtha.png";
import vijay from "../assets/AboutUs/vijay.png";
import FaizanAhmed from "../assets/AboutUs/Faizan Ahmed.png";
import MrRavi from "../assets/AboutUs/ravi6.png";
import Surbhi from "../assets/AboutUs/surbhi6.png";
import MrKrishna from "../assets/AboutUs/Mr Krishna Chand.png";
import AsiyaSharieff from "../assets/AboutUs/AsiyaSharieff.png";
import BidhanShah from "../assets/AboutUs/Bidhan Shah.png";
import ShravanBorkar from "../assets/AboutUs/ShravanBorkar.png";
import MuhammadAli from "../assets/AboutUs/MuhammadAli.png";
import SaileshKhadka from "../assets/AboutUs/SaileshKhadka.png";
import PrasadGhodeker from "../assets/AboutUs/PrasadGhodeker.png";
import GhulamShubhani from "../assets/AboutUs/GhulamShubhani.png";
import LakshmiNavya from "../assets/AboutUs/laksmiNavya.jpeg";
import HamadeJaman from "../assets/AboutUs/HamadeJaman.png";
import AnishaTaneja from "../assets/AboutUs/anishaTaneja.png";
import PrinceBharti from "../assets/AboutUs/princeBharti.png";
import SyedMushtaqAhmed from "../assets/AboutUs/SyedMushtq.png";
import facebook from "../assets/AboutUs/facebook_white.svg";
import instagram from "../assets/AboutUs/Instagram.svg";
import xLogo from "../assets/AboutUs/Vector.svg";
import linkedin from "../assets/AboutUs/linkedin.svg";
import Aboutusmui from "./aboutusmui/Aboutusmui";

const mainCard = [
  {
    name: "Mr. Ravi Subhash Giri",
    image: MrRavi,
    post: "Company Founder",
    linkedin: "http://www.linkedin.com/in/ravi-subhash-giri-7908bb27a",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Ms. Surbhi Wali Anil Zalpuri",
    image: Surbhi,
    post: "Director",
    linkedin:
      "https://www.linkedin.com/in/surbhi-wali-b068342aa?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BlypB6klkS2m6G%2FwtesK5LA%3D%3D",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Mr. Krishna Chand",
    image: MrKrishna,
    post: "Project Founder, Director",
    linkedin: "https://www.linkedin.com/in/krishnavoeux/",
    instagram: "https://www.instagram.com/krishnavoeux/",
    facebook: "https://www.facebook.com/profile.php?id=100077356040964",
    twitter: "https://twitter.com/krishnavoeux",
  },
];
const LegalAdvisorCard = [
  {
    name: "Asiya Sharieff",
    image: AsiyaSharieff,
    post: "Legal Advisor",
    linkedin: "http://www.linkedin.com/in/asiya-sharieff-78a27426b",
    instagram:
      "https://www.instagram.com/asiyakhan081086?utm_source=qr&igsh=MXN6bHdsbThueDE4OA==",
    facebook:
      "https://www.facebook.com/profile.php?id=100061293286952&mibextid=vk8aRt",
    twitter: "https://x.com/AsiyaKhan838150?t=1vX9yprPFf2qUyBVvZrEyA&s=09",
  },
  {
    name: "Anisha Taneja",
    image: AnishaTaneja,
    post: "Legal Advisor",
    linkedin: "https://www.linkedin.com/in/anisha-taneja-5a646522",
    instagram: "https://www.instagram.com/anishatan83?igsh=b28yMXBnYWJmbnZ2",
    facebook: "https://www.facebook.com/tanejaanisha?mibextid=ZbWKwL",
    twitter: "",
  },
];

const boardMenmbers = [
  {
    name: "Hamade Jaman",
    image: HamadeJaman,
    post: "COO - Voeux Group",
    linkedin: "",
    twitter: "",
    facebook: "",
    instagram: "",
  },

  {
    name: "Syed Mushtaq Ahmed",
    image: SyedMushtaqAhmed,
    post: "Managing Director - Voeux Group",
    linkedin: "",
    twitter: "",
    facebook: "",
    instagram: "",
  },
];

const financeTeam = [
  {
    name: "Prince Kumar Bharti",
    image: PrinceBharti,
    post: "Accounts and Finance Manager",
    linkedin:
      "https://www.linkedin.com/in/prince-bharti-a4881b2aa?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    twitter: "",
    facebook: "",
    instagram: "",
  },
];

const marketingTeam = [
  {
    name: "Zuhaib Ahmed Farooqui",
    image: ZuhaibAhmedFarooqui,
    post: "Business Development (UAE)",
    linkedin: "https://www.linkedin.com/in/zuhaib-ahmed-farooqui-79083a223",
    twitter: "",
    facebook: "",
    instagram: "",
  },
];

const teamCard = [
  {
    name: "Anup Srivastav",
    image: AnupSrivastav,
    dept: "Head of Operation (India)",
    linkedin: "https://www.linkedin.com/in/anup-srivastav-89318928a",
    instagram:
      "https://www.instagram.com/anup.srivastav.127?igsh=dmJoampsbmM0eTd3",
    facebook: "https://www.facebook.com/anup.srivastav.127?mibextid=eHce3h",
    twitter: "",
  },
  {
    name: "Abhijeet Deshmane",
    image: AbhijeetDeshmane,
    dept: "IT Manager (India)",
    linkedin: "https://www.linkedin.com/in/abhijeet-deshmane-18473b128/",
    instagram: "",
    facebook: "https://www.facebook.com/abhijeet.deshmane.585",
    twitter: "",
  },
  {
    name: "Sonal Daphale",
    image: SonalDaphale,
    dept: "HR & Operations  (UAE)",
    linkedin: "https://www.linkedin.com/in/sonal-daphale-11499171",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Varsha Kamble",
    image: VarshaKamble,
    dept: "HR   (India)",
    linkedin: "http://www.linkedin.com/in/varsha-k-2a68322aa",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Muhammad Hashim",
    image: MuhammadHashim,
    dept: "Project Lead (UAE)",
    linkedin: "https://www.linkedin.com/in/hashim-shah-b92796146",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Paritosh Chaudhary",
    image: ParitoshChaudhary,
    dept: "Blockchain Developer",
    linkedin: "https://www.linkedin.com/in/chaudhary-paritosh/",
    instagram:
      "https://www.instagram.com/paritosh.chaudhary?igsh=Ym14Nzh6N3o1M2tr",
    facebook: "",
    twitter: "",
  },

  {
    name: "Himanshu Bharti",
    image: HimanshuBharti,
    dept: "Full Stack Developer (UAE)",
    linkedin: "https://www.linkedin.com/in/himanshu-bharti-8a06a924b",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Sumi Mol S",
    image: SumiMolS,
    dept: "Web Developer (UAE)",
    linkedin:
      "https://www.linkedin.com/in/sumi-mol-s?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Lakshmi Navya",
    image: LakshmiNavya,
    dept: "Senior Frontend Developer (UAE)",
    linkedin: "https://www.linkedin.com/in/navya-gunda-318b5112b/",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Ghulam Shubhani",
    image: GhulamShubhani,
    dept: "Full Stack Developer (UAE)",
    linkedin:
      "https://www.linkedin.com/in/ghulamshubhani9909?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Faizan Ahmed",
    image: FaizanAhmed,
    dept: "UI/UX Designer (UAE)",
    linkedin: "https://www.linkedin.com/in/faizan-ahmed12345/",
    instagram: "https://www.instagram.com/faize_.ahmed/?igsh=c2J5a3Z3YTc0Y29z",
    facebook: "https://www.facebook.com/faizan.salman.10/",
    twitter: "",
  },
  {
    name: "Mohammad Saqlain",
    image: MohammadSaqlain,
    dept: "Graphic Designer (UAE)",
    linkedin: "https://www.linkedin.com/in/thesaqlain",
    instagram: "https://www.instagram.com/thesaqlainofficial/",
    facebook: "",
    twitter: "https://twitter.com/thesaqlaintweet",
  },
  {
    name: "Rijan Shreshtha",
    image: RijanShreshtha,
    dept: "Graphic Designer (UAE)",
    linkedin: "",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Sailesh Khadka",
    image: SaileshKhadka,
    dept: "DevOps Engineer (UAE)",
    linkedin: "https://www.linkedin.com/in/sailesh-khadka08",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Muhammad Ali",
    image: MuhammadAli,
    dept: "Game Developer (UAE)",
    linkedin: "https://www.linkedin.com/in/muhammad-ali-hamid",
    instagram:
      "https://www.instagram.com/muhammadalisheikh04?igsh=Mzd5Y2cxMXNodXVn",
    facebook:
      "https://www.facebook.com/profile.php?id=100007571070797&mibextid=9R9pXO",
    twitter: "",
  },
  {
    name: "Vijay kumar yadav",
    image: vijay,
    dept: "Graphic Designer (India)",
    linkedin: "https://www.linkedin.com/in/vijay-yadav-b418b2280",
    instagram:
      "https://www.instagram.com/vijay_yadav1203?igsh=dHhwc2xkcjlkYmxt",
    facebook:
      "https://www.facebook.com/profile.php?id=100019814460595&mibextid=ZbWKwL",
    twitter: "",
  },

  {
    name: "Bidhan Shah",
    image: BidhanShah,
    dept: "3d Designer (India)",
    linkedin: "https://www.linkedin.com/in/bidhan-saha-1ab054217",
    instagram: "",
    facebook: "",
    twitter: "",
  },
  {
    name: "Shravan Borkar",
    image: ShravanBorkar,
    dept: "Game Developer (India)",
    linkedin: "https://www.linkedin.com/in/shravan-borkar-1ab9b2206",
    instagram:
      "https://www.instagram.com/shravan.10?igsh=c24zZTl2dTg4bHBs&utm_source=qr",
    facebook: "",
    twitter: "",
  },

  {
    name: "Prasad Ghodeker",
    image: PrasadGhodeker,
    dept: "Graphic Designer (India)",
    linkedin: "https://www.linkedin.com/in/prasad-ghodekar-a41629269",
    instagram:
      "https://www.instagram.com/prasadghodekar21?igsh=MW10YnQzY3d5azcxcA%3D%3D&utm_source=qr",
    facebook: "",
    twitter: "",
  },
];

const AboutUs = () => {
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Box
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader />
        <Box>
          <Aboutusmui />
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              padding: { xs: "16px" },
            }}
          >
            <Box sx={{ mt: { xs: "1px", md: "50px" } }}>
              <Typography
                sx={{
                  fontSize: { xs: "23px", md: "32px", lg: "32px" },

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 0,
                  fontFamily: "outfit",
                }}
              >
                Our place is special.
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "23px", md: "32px", lg: "32px" },

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: 0,
                  fontFamily: "outfit",
                  textAlign: { xs: "center" },
                  marginBottom: "10px",
                }}
              >
                We are working on what is coming next
              </Typography>
            </Box>
          </Stack>

          {!isMobile && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                mx: "auto",
                my: 1,
                marginBottom: 0,
                justifyContent: "center",
                alignItems: "center",
                p: { xs: 2 },
              }}
            >
              <Typography
                sx={{
                  typography: { xs: "body2", sm: "body2" },
                  textAlign: { xs: "center", md: "inherit" },
                }}
                justifyContent={"center"}
              >
                <span
                  style={{
                    color: "#F2F2F2",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  Meet our team. We are creating Metavoeux ecosystem, an
                  all-in-one platform that combines
                  <span style={{ display: "block" }}>
                    blockchain technology. We have got a team of individuals
                    working together,
                  </span>
                  <span style={{ display: "block" }}>
                    {" "}
                    and discover the people behind the talent.
                  </span>
                </span>
              </Typography>
            </Stack>
          )}

          <Box sx={{ padding: { xs: "16px" } }}>
            <Grid
              container
              spacing={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                // my: 0,
                // p: { xs: 2 },
              }}
            >
              {mainCard.map((value, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3} // Adjust the width as needed
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Card
                    sx={{
                      background: "#272727",
                      borderRadius: "15px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "auto",
                    }}
                  >
                    <CardActionArea sx={{ cursor: "auto" }}>
                      <CardMedia
                        component="img"
                        // height={md:300} // Adjust the height as needed
                        image={value.image}
                        alt="img"
                        draggable="false"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "319px",
                          // borderRadius: "15px",
                        }}
                      />
                      <CardContent
                        sx={{
                          background: "#272727",
                          backdropFilter: "blur(10.04907512664795px)",
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            my: "4px",
                            fontFamily: "outfit",
                            fontSize: { md: "19px", lg: "1.25rem" },
                          }}
                        >
                          {value.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "var(--Orange, #F2994A)",
                            my: "4px",
                            fontFamily: "outfit",
                            fontSize: "15px",
                          }}
                        >
                          {value.post}
                        </Typography>
                        <Stack
                          sx={{
                            px: 1,
                            display: "flex",
                            flexDirection: "row",
                            my: "1px",
                            alignItems: "center",
                          }}
                        >
                          {value.facebook && (
                            <Box
                              sx={{
                                height: "20px",
                                width: "20px",
                                mr: 2,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(value.facebook, "_blank")
                              }
                            >
                              <img
                                src={facebook}
                                draggable="false"
                                alt="img"
                                height={"100%"}
                                width={"100%"}
                              />
                            </Box>
                          )}
                          {value.instagram && (
                            <Box
                              sx={{
                                height: "20px",
                                width: "20px",
                                mr: 2,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(value.instagram, "_blank")
                              }
                            >
                              <img
                                src={instagram}
                                alt="img"
                                draggable="false"
                                height={"100%"}
                                width={"100%"}
                              />
                            </Box>
                          )}
                          <Box
                            sx={{
                              height: "20px",
                              width: "20px",
                              mr: 2,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={linkedin}
                              alt="img"
                              draggable="false"
                              height={"100%"}
                              width={"100%"}
                              onClick={() =>
                                value.linkedin &&
                                window.open(value.linkedin, "_blank")
                              }
                            />
                          </Box>
                          {value.twitter && (
                            <Box
                              sx={{
                                height: "20px",
                                width: "20px",
                                mr: 2,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(value.twitter, "_blank")
                              }
                            >
                              <img
                                src={xLogo}
                                alt="img"
                                draggable="false"
                                height={"100%"}
                                width={"100%"}
                              />
                            </Box>
                          )}
                        </Stack>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {/* mainCard  ended */}

            <Typography
              sx={{
                // typography: { xs: "h5", sm: "h4" },
                textAlign: "center",
                // marginTop: "7%",
                // my: { xs: 2, md:4 },
                fontSize: { xs: "23px", md: "30px", lg: "32px" },

                fontWeight: 600,
                mt: { xs: 1, md: 10 },
                // fontFamily: "outfit"
                fontFamily: "outfit",
                marginBottom: { xs: "32px" },
                marginTop: { xs: "32px" },
              }}
            >
              Board Members
            </Typography>
            <Grid
              container
              spacing={2.5}
              rowGap={"20px"}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                my: 3,
               
                // p: { xs: 3 },

                // mt: "32px",
                // p:"0 200px"
                // p: { xs: 2 },
              }}
            >
              {boardMenmbers.map((member, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3} // Adjust the width as needed
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Card
                      sx={{
                        background: "#272727",
                        borderRadius: "15px",
                        width: { xs: "100%", md: "90%" },
                        display: "flex",
                        flexDirection: "column",
                        cursor: "auto",
                      }}
                    >
                      <CardActionArea>
                        <Box
                          height={"319px"}
                          width={"100%"}
                          boxSizing="border-box"
                        >
                          <img
                            src={member.image}
                            alt=""
                            draggable="false"
                            height={"100%"}
                            width={"100%"}
                            style={{ objectFit: "cover" }}
                          />
                        </Box>
                      </CardActionArea>
                      <CardContent
                        sx={{
                          background: "#272727",
                          backdropFilter: "blur(10.04907512664795px)",
                          color: "#fff",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                          // height: "150px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ my: "4px", fontFamily: "outfit" }}
                          whiteSpace={{ md: "nowrap" }}
                          overflow={{ md: "hidden" }}
                          textOverflow={{ md: "ellipsis" }}
                          width={{ md: "100%" }}
                          textAlign={"center"}
                        >
                          {member.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "var(--Orange, #F2994A)",
                            my: "2px",
                            fontFamily: "outfit",
                            fontSize: "15px",
                          }}
                          whiteSpace={{ md: "nowrap" }}
                          overflow={{ md: "hidden" }}
                          textOverflow={{ md: "ellipsis" }}
                          width={{ md: "100%" }}
                          textAlign={"center"}
                        >
                          {member.post}
                        </Typography>
                        <Stack
                          sx={{
                            display: "flex",
                            px: 1,
                            my: "1px",
                            flexDirection: "row",
                          }}
                        >
                          {member.facebook && (
                            <Stack>
                              <Box
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  mr: 2,
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={facebook}
                                  alt="facebook"
                                  draggable="false"
                                  height={"100%"}
                                  width={"100%"}
                                  onClick={() =>
                                    window.open(member.facebook, "_blank")
                                  }
                                />
                              </Box>
                            </Stack>
                          )}
                          {member?.instagram && (
                            <Stack>
                              <Box
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  mr: 2,
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={instagram}
                                  alt="instgram"
                                  draggable="false"
                                  height={"100%"}
                                  width={"100%"}
                                  onClick={() =>
                                    window.open(member.instagram, "_blank")
                                  }
                                />
                              </Box>
                            </Stack>
                          )}
                          <Stack>
                            <Box
                              sx={{
                                height: "20px",
                                width: "20px",
                                mr: 2,
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={linkedin}
                                alt="linkedin"
                                draggable="false"
                                height={"100%"}
                                width={"100%"}
                                onClick={() =>
                                  member.linkedin &&
                                  window.open(member.linkedin, "_blank")
                                }
                              />
                            </Box>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>

            <Stack
              spacing={2.5}
              direction={{
                xs: "column",
                md: "row",
              }}
              width={"100%"}
              // px={2}
              sx={{ justifyContent: "center" }}
            >
              <Box
                width={{ xs: "100%", md: "40%" }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    // typography: { xs: "h5", sm: "h4" },
                    fontSize: "30px",
                    fontWeight: 600,
                    fontFamily: "outfit",
                    textAlign: { xs: "center" },
                    my: { md: 6 },
                    mt: { md: 2 },
                    marginBottom: "32px",
                    // marginTop: "32px",
                  }}
                >
                  Legal Advisors
                </Typography>
                <Grid container spacing={2.5}>
                  {LegalAdvisorCard.map((value, index) => (
                    <Grid item xs={12} sm={6} sx={{ borderRadius: "10px" }}>
                      <Card
                        sx={{ background: "#272727", borderRadius: "15px" }}
                      >
                        <CardActionArea sx={{ cursor: "auto" }}>
                          <Box
                            sx={{
                              height: { lg: "240px", xl: "280px", md: "164px" },
                            }}
                            // height={"280px"}
                            width={"100%"}
                            boxSizing="border-box"
                          >
                            <img
                              draggable="false"
                              src={value.image}
                              alt=""
                              height={"100%"}
                              width={"100%"}
                            />
                          </Box>
                          <CardContent
                            sx={{
                              background: "#272727",
                              backdropFilter: "blur(10.04907512664795px)",
                              color: "#fff",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              // height: "150px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "outfit",
                                my: { xl: "1px", fontFamily: "outfit" },
                              }}
                              whiteSpace={{ md: "nowrap" }}
                              overflow={{ md: "hidden" }}
                              textOverflow={{ md: "ellipsis" }}
                              width={{ md: "100%" }}
                              textAlign={"center"}
                            >
                              {value.name}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: "var(--Orange, #F2994A)",
                                // my: "4px",
                                fontFamily: "outfit",
                                my: { xl: "1px", fontFamily: "outfit" },
                              }}
                              whiteSpace={{ md: "nowrap" }}
                              overflow={{ md: "hidden" }}
                              textOverflow={{ md: "ellipsis" }}
                              width={{ md: "100%" }}
                              textAlign={"center"}
                            >
                              {value.post}
                            </Typography>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                mt: "10px",
                                mb: "2px",
                              }}
                            >
                              {value.facebook && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={facebook}
                                    alt="facebook"
                                    draggable="false"
                                    height={"100%"}
                                    width={"100%"}
                                    onClick={() =>
                                      window.open(value.facebook, "_blank")
                                    }
                                  />
                                </Box>
                              )}
                              {value.instagram && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={instagram}
                                    alt="instagram"
                                    draggable="false"
                                    height={"100%"}
                                    width={"100%"}
                                    onClick={() =>
                                      window.open(value.instagram, "_blank")
                                    }
                                  />
                                </Box>
                              )}

                              <Box
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  mr: 2,
                                  cursor: "pointer",
                                  // my: "6px",
                                }}
                              >
                                <img
                                  src={linkedin}
                                  alt="linkedin"
                                  draggable="false"
                                  height={"100%"}
                                  width={"100%"}
                                  onClick={() =>
                                    value.linkedin &&
                                    window.open(value.linkedin, "_blank")
                                  }
                                />
                              </Box>
                              {value.twitter && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    window.open(value.twitter, "_blank")
                                  }
                                >
                                  <img
                                    src={xLogo}
                                    alt="twitter"
                                    height={"100%"}
                                    width={"100%"}
                                  />
                                </Box>
                              )}

                              {/* <Box
                        sx={{
                          height: "20px",
                          width: "20px",
                          mr: 2,
                          cursor: "pointer",
                          my: "6px",
                        }}
                      >
                        <img
                          src={linkedin}
                          alt=""
                          height={"100%"}
                          width={"100%"}
                          onClick={() => window.open(value.linkedin, "_blank")}
                        />
                      </Box> */}
                            </Stack>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box
                width={{ xs: "100%", md: "20%" }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  px: {
                    sm: 20,
                    md: 0.5,
                  },
                }}
              >
                <Typography
                  sx={{
                    // typography: { xs: "h5", sm: "h4" },
                    fontSize: "30px",
                    fontWeight: 600,
                    // fontFamily: "outfit",
                    textAlign: { xs: "center" },
                    my: { xs: 2, md: 6 },
                    mt: { xs: 1, md: 2 },
                    fontFamily: "outfit",
                    marginBottom: { xs: "32px" },
                    // marginTop: { xs: "32px" },
                  }}
                >
                  Finance
                </Typography>
                <Grid
                  container
                  // spacing={4}

                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {financeTeam.map((value, index) => (
                    <Grid item xs={12} sx={{ borderRadius: "10px" }}>
                      <Card
                        sx={{ background: "#272727", borderRadius: "15px" }}
                      >
                        <CardActionArea sx={{ cursor: "auto" }}>
                          <Box
                            // height={"280px"}
                            sx={{
                              height: { lg: "240px", xl: "280px", md: "164px" },
                            }}
                            width={"100%"}
                            boxSizing="border-box"
                          >
                            <img
                              src={value.image}
                              alt=""
                              draggable="false"
                              height={"100%"}
                              width={"100%"}
                            />
                          </Box>
                          <CardContent
                            sx={{
                              background: "#272727",
                              backdropFilter: "blur(10.04907512664795px)",
                              color: "#fff",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              // height: "150px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "outfit",
                                my: { xl: "1px", fontFamily: "outfit" },
                              }}
                              whiteSpace={{ md: "nowrap" }}
                              overflow={{ md: "hidden" }}
                              textOverflow={{ md: "ellipsis" }}
                              width={{ md: "100%" }}
                              textAlign={"center"}
                            >
                              {value.name}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: "var(--Orange, #F2994A)",
                                // my: "4px",
                                fontFamily: "outfit",
                                my: { xl: "1px", fontFamily: "outfit" },
                              }}
                              whiteSpace={{ md: "nowrap" }}
                              overflow={{ md: "hidden" }}
                              textOverflow={{ md: "ellipsis" }}
                              width={{ md: "100%" }}
                              textAlign={"center"}
                            >
                              {value.post}
                            </Typography>
                            {/* <Stack>
                    <Box
                        sx={{
                          height: "20px",
                          width: "20px",
                          mr: 2,
                          cursor: "pointer",
                          my: "6px",
                        }}
                      >
                        <img
                          src={linkedin}
                          alt=""
                          height={"100%"}
                          width={"100%"}
                          onClick={() => window.open(value.linkedin, "_blank")}
                        />
                      </Box>
                    </Stack> */}
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                mt: "10px",
                                mb: "2px",
                              }}
                            >
                              {value.facebook && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={facebook}
                                    alt="facebook"
                                    draggable="false"
                                    height={"100%"}
                                    width={"100%"}
                                    onClick={() =>
                                      window.open(value.linkedin, "_blank")
                                    }
                                  />
                                </Box>
                              )}
                              {value.instagram && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={instagram}
                                    alt="instagram"
                                    draggable="false"
                                    height={"100%"}
                                    width={"100%"}
                                    onClick={() =>
                                      window.open(value.instagram, "_blank")
                                    }
                                  />
                                </Box>
                              )}

                              <Box
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  mr: 2,
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={linkedin}
                                  alt="linkedin"
                                  draggable="false"
                                  height={"100%"}
                                  width={"100%"}
                                  onClick={() =>
                                    value.linkedin &&
                                    window.open(value.linkedin, "_blank")
                                  }
                                />
                              </Box>
                              {value.twitter && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    window.open(value.twitter, "_blank")
                                  }
                                >
                                  <img
                                    src={xLogo}
                                    alt="x"
                                    draggable="false"
                                    height={"100%"}
                                    width={"100%"}
                                  />
                                </Box>
                              )}

                              {/* <Box
                        sx={{
                          height: "20px",
                          width: "20px",
                          mr: 2,
                          cursor: "pointer",
                          my: "6px",
                        }}
                      >
                        <img
                          src={linkedin}
                          alt=""
                          height={"100%"}
                          width={"100%"}
                          onClick={() => window.open(value.linkedin, "_blank")}
                        />
                      </Box> */}
                            </Stack>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box
                width={{ xs: "100%", md: "20%" }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  px: {
                    sm: 20,
                    md: 0.5,
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: 600,
                    fontFamily: "outfit",
                    // typography: { xs: "h5", sm: "h4" },
                    textAlign: { xs: "center" },
                    my: { xs: 2, md: 6 },
                    mt: { xs: 1, md: 2 },
                    marginBottom: { xs: "32px" },
                    // marginTop: { xs: "32px" },
                  }}
                >
                  Marketing
                </Typography>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    // my: 4,
                    // p: { xs: 2 },
                  }}
                >
                  {marketingTeam.map((value, index) => (
                    <Grid item xs={12} sm={12} sx={{ borderRadius: "10px" }}>
                      <Card
                        sx={{ background: "#272727", borderRadius: "15px" }}
                      >
                        <CardActionArea sx={{ cursor: "auto" }}>
                          <Box
                            sx={{
                              height: { lg: "240px", xl: "280px", md: "164px" },
                            }}
                            // height={"280px"}
                            width={"100%"}
                            boxSizing="border-box"
                          >
                            <img
                              src={value.image}
                              alt=""
                              draggable="false"
                              height={"100%"}
                              width={"100%"}
                            />
                          </Box>
                          <CardContent
                            sx={{
                              background: "#272727",
                              backdropFilter: "blur(10.04907512664795px)",
                              color: "#fff",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              // height: "150px",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "outfit",
                                my: { xl: "1px", fontFamily: "outfit" },
                              }}
                              whiteSpace={{ md: "nowrap" }}
                              overflow={{ md: "hidden" }}
                              textOverflow={{ md: "ellipsis" }}
                              width={{ md: "100%" }}
                              textAlign={"center"}
                            >
                              {value.name}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color: "var(--Orange, #F2994A)",
                                // my: "4px",
                                fontFamily: "outfit",
                                my: { xl: "1px", fontFamily: "outfit" },
                              }}
                              whiteSpace={{ md: "nowrap" }}
                              overflow={{ md: "hidden" }}
                              textOverflow={{ md: "ellipsis" }}
                              width={{ md: "100%" }}
                              textAlign={"center"}
                            >
                              {value.post}
                            </Typography>
                            <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                mt: "10px",
                                mb: "2px",
                              }}
                            >
                              {value.facebook && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={facebook}
                                    alt="facebook"
                                    draggable="false"
                                    height={"100%"}
                                    width={"100%"}
                                    onClick={() =>
                                      window.open(value.facebook, "_blank")
                                    }
                                  />
                                </Box>
                              )}
                              {value.instagram && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    src={instagram}
                                    alt="instagram"
                                    draggable="false"
                                    height={"100%"}
                                    width={"100%"}
                                    onClick={() =>
                                      window.open(value.instagram, "_blank")
                                    }
                                  />
                                </Box>
                              )}

                              <Box
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  mr: 2,
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={linkedin}
                                  alt="linkedin"
                                  draggable="false"
                                  height={"100%"}
                                  width={"100%"}
                                  onClick={() =>
                                    value.linkedin &&
                                    window.open(value.linkedin, "_blank")
                                  }
                                />
                              </Box>
                              {value.twitter && (
                                <Box
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 2,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    window.open(value.twitter, "_blank")
                                  }
                                >
                                  <img
                                    src={xLogo}
                                    alt="xlogo"
                                    draggable="false"
                                    height={"100%"}
                                    width={"100%"}
                                    onClick={() =>
                                      window.open(value.twitter, "_blank")
                                    }
                                  />
                                </Box>
                              )}

                              {/* <Box
                        sx={{
                          height: "20px",
                          width: "20px",
                          mr: 2,
                          cursor: "pointer",
                          my: "6px",
                        }}
                      >
                        <img
                          src={linkedin}
                          alt=""
                          height={"100%"}
                          width={"100%"}
                          onClick={() => window.open(value.linkedin, "_blank")}
                        />
                      </Box> */}
                            </Stack>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>

            {/* legal ended finance marketing ended*/}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                my: 4,
              }}
            >
              <Typography
                sx={{
                  // typography: { xs: "h5", sm: "h4" },
                  fontSize: "30px",
                  fontWeight: 600,
                  fontFamily: "outfit",
                  textAlign: { xs: "center", md: "inherit" },
                  mt: { md: 8 },
                }}
              >
                <span style={{ color: "#F2F2F2", marginBottom: "32px" }}>
                  Team{" "}
                </span>
              </Typography>
            </Stack>
            <Grid container spacing={2.5} sx={{ position: "relative" }}>
              {teamCard.map((value, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2.4}
                  sx={{ borderRadius: "10px", mb: { xl: 5 } }}
                >
                  <Card sx={{ background: "#272727", borderRadius: "15px" }}>
                    <CardActionArea sx={{ cursor: "auto" }}>
                      <Box
                        sx={{ height: { xs: "100%", sm: "250px" } }}
                        width={"100%"}
                        boxSizing="border-box"
                      >
                        {/* <CardMedia
                      component="img"
                      // height={200}
                      image={value.image}
                      alt={value.name}
                      style={{
                        borderRadius: "15px",
                        // objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                    /> */}
                        <img
                          src={value.image}
                          alt=""
                          draggable="false"
                          height={"100%"}
                          width={"100%"}
                        />
                      </Box>
                      <CardContent
                        sx={{
                          background: "#272727",
                          backdropFilter: "blur(10.04907512664795px)",
                          color: "#fff",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          // height: { lg: "100px" },
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            pt: 0,
                            my: { xl: "1px", fontFamily: "outfit" },
                          }}
                        >
                          {value.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "var(--Orange, #F2994A)",
                            my: { xl: "1px", fontFamily: "outfit" },
                          }}
                        >
                          {value.dept}
                        </Typography>
                        <Stack
                          sx={{
                            px: 1,
                            display: "flex",
                            flexDirection: "row",
                            my: "6px",
                          }}
                        >
                          {value.facebook && (
                            <Box
                              sx={{
                                height: "20px",
                                width: "20px",
                                mr: 2,
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={facebook}
                                alt="facebook"
                                draggable="false"
                                height={"100%"}
                                width={"100%"}
                                onClick={() =>
                                  window.open(value.facebook, "_blank")
                                }
                              />
                            </Box>
                          )}
                          {value.instagram && (
                            <Box
                              sx={{
                                height: "20px",
                                width: "20px",
                                mr: 2,
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={instagram}
                                alt="instagram"
                                draggable="false"
                                height={"100%"}
                                width={"100%"}
                                onClick={() =>
                                  window.open(value.instagram, "_blank")
                                }
                              />
                            </Box>
                          )}

                          <Box
                            sx={{
                              height: "20px",
                              width: "20px",
                              mr: 2,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={linkedin}
                              alt="linkedin"
                              draggable="false"
                              height={"100%"}
                              width={"100%"}
                              onClick={() =>
                                value.linkedin &&
                                window.open(value.linkedin, "_blank")
                              }
                            />
                          </Box>
                          {value.twitter && (
                            <Box
                              sx={{
                                height: "20px",
                                width: "20px",
                                mr: 2,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                window.open(value.twitter, "_blank")
                              }
                            >
                              <img
                                src={xLogo}
                                alt="xlogo"
                                draggable="false"
                                height={"100%"}
                                width={"100%"}
                              />
                            </Box>
                          )}
                          {/* <Box sx={{ height: "20px", width: "20px", mr: 2 }}>
                        <img
                          src={xLogo}
                          alt=""
                          height={"100%"}
                          width={"100%"}
                        />
                      </Box> */}
                        </Stack>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* team ended */}
        </Box>
      </Box>

      <LandingPageFooter />
    </>
  );
};

export default AboutUs;
