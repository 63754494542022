import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { useEffect, useState } from "react";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import ToastMessageFailure from "../../../components/ToastMessage/ToastMessageFailure";
import metalogo from "../../../assets/images/logo.png";
const steps = [
  "Select a Currency",
  "Select Payment Method",
  "Submit Form",
  "Confirm Details",
];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const totalSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [currency, setCurrency] = useState<any>("VND");
  const getCurrencyType = (currency: any) => {
    console.log("sample", currency);
    setCurrency(currency);
  };

  const [paymentMethod, setPaymentMethod] = useState<any>({});
  const getPaymentMethod = (serviceCode: any, paymnetdata: any, logo: any) => {
    setPaymentMethod({
      serviceCode: serviceCode,
      paymnetMethod: paymnetdata,
      serviceLogo: logo,
    });
  };

  const [amount, setAmount] = useState<number>(0);
  const getAmount = (amountData: number) => {
    setAmount(amountData);
  };

  const [bank, setBank] = useState<number>(0);
  const getBank = (newBank: number) => {
    setBank(newBank);
  };

  const [ loaderText, setLoaderText] = useState("")
  const handleShowLoader = (active: any,text?:any) => {
    setShowLoader(active);
    setLoaderText(text)
  };

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  useEffect(() => {}, [paymentMethod]);

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };

  const [formData, setFormData] = useState<any>({});
  const [paymentMethodName, setPaymentMethodName] = useState<any>("");
  const [logo, setLogo] = useState<any>("");
  const getFormData = (formData: any, paymnetMethod: any, logo: any) => {
    console.log("parent formdata", formData);
    setFormData(formData);
    setPaymentMethodName(paymnetMethod);
    setLogo(logo);
  };

  return (
    <>
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100vh",
            zIndex: 1,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent text={loaderText} />
        </Box>
      )}
      <Box sx={{ p: "20px" }}>
        {" "}
        <img src={metalogo} alt="logo" />
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          ...circleStyle,
          top: { xl: "40%" },
          left: "0%",
          transform: "translateY(-70%)",
          width: "300px", // Adjusted width
          height: "300px", // Adjusted height
          borderRadius: "547.625px",
          background:
            "var(--Linear, linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%))",
          filter: "blur(160px)",
          backdropFilter:"blur(160px)",
          WebkitBackdropFilter:"blur(160px)",
          overflow: "hidden",
          "@media (min-width: 1721px)": {
            width: "450px", // Adjusted width
            height: "700px", // Adjusted height
            top: { xl: "0%" },

            right: "-20%",
            filter: "blur(160px)",
            backdropFilter:"blur(160px)",
            WebkitBackdropFilter:"blur(160px)",
          },
        }}
      ></Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          ...circleStyle,
          top: { xl: "40%" },
          right: "0%",
          transform: "translateY(-50%)",
          width: "300px", // Adjusted width
          height: "300px", // Adjusted height
          borderRadius: "672.058px",
          background: "var(--56-ccf-2, #56CCF2)",
          filter: "blur(162px)",
          backdropFilter:"blur(162px)",
          WebkitBackdropFilter:"blur(162px)",
          overflow: "hidden",
          "@media (min-width: 1721px)": {
            width: "450px", // Adjusted width
            height: "700px", // Adjusted height
            top: { xl: "0%" },

            right: "-20%",
            filter: "blur(160px)",
            backdropFilter:"blur(160px)",
            WebkitBackdropFilter:"blur(160px)",
          },
        }}
      ></Box>
      <Box
        p={30}
        sx={{
          paddingTop: 5,
          marginBottom: 0,
          "@media (max-width: 600px)": {
            padding: 2,
          },
          "@media (min-width: 1721px)": {},
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            orientation="horizontal"
          >
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      style: { marginBottom: "8px", color: "#FF990D" },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Inter",
                        fontSize: "14px",
                      }}
                      variant="body2"
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ mt: 2 }}>
            {activeStep === totalSteps ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  ></Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleNext}>
                    {activeStep === totalSteps - 1 ? "" : ""}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
          {activeStep === 0 && (
            <StepOne
              getCurrencyType={getCurrencyType}
              handleNext={handleNext}
              handleShowLoader={handleShowLoader}
              showToastErrorMessageClick={showToastErrorMessageClick}
            />
          )}
           {activeStep === 1 && (
          <StepTwo
            currency={currency}
            getPaymentMethod={getPaymentMethod}
            handleNext={handleNext}
            handleBack={handleBack}
            handleShowLoader={handleShowLoader}
            showToastErrorMessageClick={showToastErrorMessageClick}
          />
        )}
        
        {activeStep === 2 && 
        (
          <>
         
         { console.log("before component call",paymentMethod)}
          <StepThree
            currency={currency}
            paymentMethoddata={paymentMethod}
            getFormData={getFormData}
            handleNext={handleNext}
            handleBack={handleBack}
            showToastErrorMessageClick={showToastErrorMessageClick}
            handleShowLoader={handleShowLoader}
          />
           { console.log("after component call",paymentMethod)}
           </>
        )}
        

        {activeStep === 3 && (
          <StepFour
          formData={formData}
            handleBack={handleBack}
            handleNext={handleNext}
            paymentMethodName={paymentMethodName}
            logo={logo}
            handleShowLoader={handleShowLoader}
          />
        )}
        </Box>
      </Box>
    </>
  );
}
