import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import aboutimage from "../../assets/images/aboutImage.png";
import innovativeimage from "../../assets/svgs/Innovative Technology.svg";
import allinoneimage from "../../assets/svgs/eco system.svg";
import Sovereignimage from "../../assets/svgs/Independent.svg";
import Visioncard from "./Visioncardmui";

import centerIcon from "../../assets/partners/center.png";
import certicIcon from "../../assets/partners/certik.png";
import coinMarketIcon from "../../assets/partners/CoinmarketcapIcon.png";

import dinoIcon from "../../assets/partners/dino.png";
import etherIcon from "../../assets/partners/ether.png";
import hackenIcon from "../../assets/partners/Hacken.png";
import lionIcon from "../../assets/partners/lion.png";
import metamaskIcon from "../../assets/partners/metamask.png";
import pIcon from "../../assets/partners/p.png";

import shieldIcon from "../../assets/partners/shield.png";
import unicornIcon from "../../assets/partners/unicorn.png";
import wolfIcon from "../../assets/partners/wolf.png";
import coinStackIcon from "../../assets/partners/coinStack.png";

const firstPartnerList: string[] = [
  centerIcon,
  certicIcon,
  coinMarketIcon,
  dinoIcon,
  etherIcon,
  hackenIcon,
  lionIcon,
  centerIcon,
  certicIcon,
  coinMarketIcon,
  dinoIcon,
  etherIcon,
];
const secondPartnerList: string[] = [
  metamaskIcon,
  pIcon,
  shieldIcon,
  unicornIcon,
  wolfIcon,
  coinStackIcon,
  metamaskIcon,
  pIcon,
];

//
function Aboutusmui() {
  const circleStyleBlock: React.CSSProperties = {
    position: "absolute",
    flexShrink: 0,
    zIndex: -1,
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  const cardData1 = [
    {
      id: 1,
      image: innovativeimage,
      title: "Innovative Technology",
      caption:
        "Metavoeux uses advanced blockchain tech to provide safe and clear financial solutions. We're on a mission to revolutionize digital experiences using safe and secure tech.",
    },
    {
      id: 2,
      image: allinoneimage,
      title: "All in one Ecosystem (App)",
      caption:
        "We have many different apps (Dapps) for many purposes, including finance, entertainment, and games.",
    },
    {
      id: 3,
      image: Sovereignimage,
      title: "Sovereign",
      caption:
        "Our model, which doesn't rely on trust and doesn't involve keeping your assets in someone else's care, ensures that your privacy and security are well-protected.",
    },
  ];

  const cardData2 = [
    {
      id: 4,
      image: innovativeimage,
      title: "Community-Driven",
      caption:
        "Our community is really important to us. They have a strong say in making decisions and guiding the future of Voeux. Our community is passionate, engaged, and much more.",
    },
    {
      id: 5,
      image: allinoneimage,
      title: "Experienced Team",
      caption:
        "Our team is experts in blockchain, finance, games and web3.0 Technology.",
    },
  ];

  return (
    <>
      {!isMobile && (
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 23, md: 36 },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "outfit",
              // margin: "1rem",
            }}
          >
            About Us
          </Typography>
          <Box sx={{}}>
            <Box
              sx={{
                ...circleStyleBlock,
                top: { md: "30%" },
                transform: "translate(-50%, -50%)",
                width: "547.625px",
                height: "187.734px",
                borderRadius: "547.625px",
                background: "#9D00FF",
                filter: "blur(150px)",
                backdropFilter: "blur(150px)",
                WebkitBackdropFilter: "blur(150px)",
              }}
            ></Box>

            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "16px", md: "19px", lg: "21px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  fontFamily: "outfit",
                }}
              >
                Open to all, embraced by all.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: { xs: "16px", md: "19px", lg: "21px" },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // margin: 0,
                  fontFamily: "outfit",
                  // pl: { xs: 3.5 },
                }}
              >
                We are creating a future where everyone belongs.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
              sx={{ mt: { xs: 3, md: 7 } }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Grid sx={{ padding: { xs: "10px" } }} item xs={12} md={6}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: 23, md: 36 },
                        marginBottom: "1rem",
                        textAlign: { xs: "center", md: "start" },
                        fontFamily: "outfit",
                      }}
                      variant="h5"
                    >
                      Our Mission
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", md: "19px", lg: "21px" },
                        marginBottom: "1rem",
                        // lineHeight: {
                        //   sm: "32.5px",
                        // },
                        // letterSpacing: "-0.36px",
                        textAlign: "justify",
                        fontFamily: "outfit",
                      }}
                      variant="body1"
                    >
                      We are building the Metavoeux ecosystem - An all-in-one
                      platform that combines web 3.0. and blockchain technology
                      with multiple products to facilitate the users and web3
                      community.
                      <p>
                        We are creating a platform where all the products run
                        together in a single place. The platform includes NFT
                        marketplace, Voeux farming, Blockchain games (play to
                        earn), Voeux pay payment gateway, Voeux Money(act to
                        earn), Blockchain real estate, Metaverse (education and
                        learning, e-commerce, virtual land, etc), Voeux token
                        and our Voeux blockchain.
                      </p>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      height: { xs: 250, sm: 300, md: 400 },
                      width: { xs: 300, sm: 400, md: 500 },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mx: "auto",
                      position: "relative",
                      // p: { xs: 2 },
                    }}
                  >
                    <Box
                      sx={{
                        ...circleStyleBlock,
                        top: { md: "70%" },
                        left: { lg: "90%", xl: "30%" },
                        transform: "translate(-50%, -50%)",
                        width: "337.625px",
                        height: "187.734px",
                        borderRadius: "547.625px",
                        background: "#9D00FF",
                        filter: "blur(150px)",
                        backdropFilter: "blur(150px)",
                        WebkitBackdropFilter: "blur(150px)",
                      }}
                    ></Box>
                    <img
                      draggable="false"
                      src={aboutimage}
                      alt="about"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: { xs: 23, md: 36 },

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: { xs: 2, md: 6 },
                fontFamily: "outfit",
              }}
            >
              Our Vision
            </Typography>
            <Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "16px", md: "19px", lg: "21px" },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // margin: 0,
                    mx: { ms: "auto" },
                    textAlign: "center",
                    maxWidth: { xs: "340px", sm: "580px" },
                    fontFamily: "outfit",
                  }}
                >
                  Our dream is to support people and businesses with smart
                  blockchain solutions, making money easier for everyone.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  position: "relative",
                  mt: { xs: 2, md: 6 },
                }}
                // sx={{ marginBottom: "5rem", padding: "2rem" }}
              >
                <Box
                  sx={{
                    ...circleStyleBlock,
                    top: { md: "40%" },
                    transform: "translate(-50%, -50%)",
                    width: "560.625px",
                    height: "187.734px",
                    borderRadius: "547.625px",
                    background: "#9D00FF",
                    filter: "blur(180px)",
                    right: "100px",
                    backdropFilter: "blur(180px)",
                    WebkitBackdropFilter: "blur(180px)",
                  }}
                ></Box>

                <Visioncard />
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <>
        {/* for mobile */}
        {isMobile && (
          <Box sx={{ padding: "16px" }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "23px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "outfit",
                }}
              >
                About Us
              </Typography>
              <Box sx={{}}>
                <Box
                  sx={{
                    ...circleStyleBlock,
                    top: { md: "30%" },
                    transform: "translate(-50%, -50%)",
                    width: "547.625px",
                    height: "187.734px",
                    borderRadius: "547.625px",
                    background: "#9D00FF",
                    filter: "blur(150px)",
                    backdropFilter: "blur(150px)",
                    WebkitBackdropFilter: "blur(150px)",
                  }}
                ></Box>

                <Typography
                  variant="body1"
                  sx={{
                    fontsize: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    fontFamily: "outfit",
                    marginBottom: "35px",
                  }}
                >
                  We are creating a future where everyone belongs.
                </Typography>
              </Box>
              <Box>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Grid
                      //   sx={{ padding: { xs: "10px" } }}
                      item
                      xs={12}
                      md={6}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "23px",
                            // marginBottom: "1rem",

                            fontFamily: "outfit",
                          }}
                          variant="h5"
                        >
                          Our Mission
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            marginBottom: "1rem",

                            textAlign: "justify",
                            fontFamily: "outfit",
                          }}
                          variant="body1"
                        >
                          Metavoeux is on a mission to blend Web 3.0 and
                          blockchain seamlessly. Imagine a world where NFTs,
                          Voeux farming, play-to-earn games, Voeux Pay, and more
                          come together effortlessly. It's about creating a
                          future where everyone can access and benefit from the
                          digital revolution. Join us in shaping this exciting
                          journey.
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ position: "relative" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",

                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            ...circleStyleBlock,
                            top: { md: "70%" },
                            left: { lg: "90%", xl: "30%" },
                            transform: "translate(-50%, -50%)",
                            width: "337.625px",
                            height: "187.734px",
                            borderRadius: "547.625px",
                            background: "#9D00FF",
                            filter: "blur(150px)",
                            backdropFilter: "blur(150px)",
                            WebkitBackdropFilter: "blur(150px)",
                          }}
                        ></Box>
                        <img
                          src={aboutimage}
                          alt="about"
                          draggable="false"
                          style={{
                            width: "100%",
                            height: "auto",
                            marginBottom: "15px",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: 23, md: 36 },

                    mt: { xs: 2, md: 6 },
                    fontFamily: "outfit",
                  }}
                >
                  Our Vision
                </Typography>
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        fontFamily: "outfit",
                      }}
                    >
                      Our dream is to support people and businesses with smart
                      blockchain solutions, making money easier for everyone.
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      position: "relative",
                      mt: { xs: 2, md: 6 },
                    }}
                  >
                    <Box
                      sx={{
                        ...circleStyleBlock,
                        top: { md: "40%" },
                        transform: "translate(-50%, -50%)",
                        width: "560.625px",
                        height: "187.734px",
                        borderRadius: "547.625px",
                        background: "#9D00FF",
                        filter: "blur(180px)",
                        backdropFilter: "blur(180px)",
                        WebkitBackdropFilter: "blur(180px)",
                        right: "100px",
                      }}
                    ></Box>

                    {/* visioncard */}

                    <Box>
                      {/* First row */}
                      <Stack direction={"column"}>
                        <Grid
                          container
                          spacing={1}
                          sx={{ position: "relative" }}
                        >
                          {cardData1.map((card, index) => (
                            <Grid
                              item
                              key={card.id}
                              xs={12}
                              sm={6}
                              md={index === 2 ? 12 : 6}
                              lg={index > 2 ? 6 : 4}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Card
                                sx={{
                                  backgroundColor: "#9D00FF",
                                  color: "#fff",
                                  width: "382px",
                                  height: "250px",
                                  borderRadius: { xs: "24px", md: "15.558px" },
                                  backdropFilter: "blur(30px)",
                                  background: "rgba(255, 255, 255, 0.09)",
                                }}
                              >
                                <Box>
                                  <CardMedia
                                    component="img"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      margin: "1rem",
                                      borderRadius: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginLeft: "1.5rem",
                                      padding: 3,
                                    }}
                                    sx={{
                                      backgroundColor: "#A719FF",
                                    }}
                                    src={card.image}
                                    alt={card.title}
                                  />
                                </Box>

                                <CardContent>
                                  <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{
                                      color: "white",
                                      fontFamily: "outfit",
                                      fontSize: { xs: "20px", md: "20px" },
                                      fontWeight: "600",
                                      margin: "0px 10px 2px",
                                    }}
                                  >
                                    {card.title}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="white"
                                    sx={{
                                      fontFamily: "outfit",
                                      fontSize: { xs: "16px" },
                                      fontWeight: "300",
                                      margin: "0px 10px 2px",
                                      // textAlign: "justify",
                                    }}
                                  >
                                    {card.caption}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Stack>

                      {/* Second row */}
                      <Stack
                        sx={{
                          px: { lg: "10%", xl: "15%" },
                          // marginLeft: "17px",
                          marginTop: "10px",
                        }}
                      >
                        <Grid container justifyContent="center" spacing={1}>
                          {cardData2.map((card, index) => (
                            <Grid
                              item
                              key={card.id}
                              xs={12}
                              sm={6}
                              md={index === 1 ? 12 : 6}
                              lg={index > 1 ? 6 : 4}
                              style={{
                                justifyContent: "center",
                                marginBottom: "20px",
                              }}
                            >
                              <Card
                                sx={{
                                  backgroundColor: "#9D00FF",
                                  color: "#fff",
                                  width: "100%",
                                  height: "250px",
                                  borderRadius: { xs: "24px", md: "15.558px" },
                                  backdropFilter: "blur(30px)",
                                  background: "rgba(255, 255, 255, 0.09)",
                                }}
                              >
                                <Box>
                                  <CardMedia
                                    component="img"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      margin: "1rem",
                                      borderRadius: "10px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginLeft: "1.5rem",
                                      padding: 3,
                                    }}
                                    sx={{
                                      backgroundColor: "#A719FF",
                                    }}
                                    src={card.image}
                                    alt={card.title}
                                  />
                                </Box>

                                <CardContent>
                                  <Typography
                                    variant="h5"
                                    component="div"
                                    sx={{
                                      color: "white",
                                      fontFamily: "outfit",
                                      fontSize: { xs: "20px", md: "20px" },
                                      fontWeight: "600",
                                      margin: "0px 5px 2px",
                                    }}
                                  >
                                    {card.title}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="white"
                                    sx={{
                                      fontFamily: "outfit",
                                      fontSize: { xs: "16px" },
                                      fontWeight: "300",

                                      margin: "0px 5px 5px",
                                    }}
                                  >
                                    {card.caption}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </>
    </>
  );
}

export default Aboutusmui;
