 import { createNftData } from "src/domains/createNft.dto";
// import { contractToIndex } from "src/domains/contractToIndex.dto";

// import { getIdByName } from "src/domains/getIdByName.dto";

import { FactoryERC1155 } from "../contracts/FactoryERC1155";

import {
  FactoryERC1155_CONTRACT_ADDRESS,
  FactoryERC1155_CONTRACT_ABI,
} from "../constants/ABIs/FactoryERC1155ABI";

export class CreacteNftServices {
  createNftInstance: FactoryERC1155;

  constructor() {
    this.createNftInstance = new FactoryERC1155(
      FactoryERC1155_CONTRACT_ADDRESS,
      FactoryERC1155_CONTRACT_ABI
    );
  }

  deployERC1155 = async (
    createNewNftCollection: createNftData
  ): Promise<object> => {
    try {
      console.log(this.createNftInstance, "ueyeruer");
      // Your logic here
      const response =  await this.createNftInstance?.deployERC1155(
        createNewNftCollection.contractName,
        createNewNftCollection._uri,
        // createNewNftCollection.ids,
        // createNewNftCollection.names
      );

      const collectionIndex = await this.createNftInstance?.getCurrentCollectionIndex();
      const collectionAddress = await this.createNftInstance?.indexToContract(Number(collectionIndex));
      return {
       response,
       collectionAddress,
      }
      // Return an object or any other value if needed
      // return { success: true, message: "ERC1155 deployed successfully" };
    } catch (err) {
      console.log("nft collection ", err);
      throw err;
    }
  };

  createERC1155NFT = async (
    index: number,
    name: string,
    amount: number
  ): Promise<Object> => {
    try {
      console.log("inside create nft", index, name, amount);
      const data =  await this.createNftInstance?.mintERC1155(index, name, amount);
     console.log("data return", data)
     return data
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  contractToIndex = async(
    contractAddress: string
  ): Promise<string> => {
    try{
      const data =  await this.createNftInstance?.contractToIndex(contractAddress);
      console.log("data return", data)
      return data
    }catch (err) {
      console.error(err);
      throw err;
    }
  };

  getIdByName = async(
    index: number,
    nftName:string
  ): Promise<string> => {
    try{
      const data =  await this.createNftInstance?.getIdByName(index,nftName);
      console.log("getIdByName", data)
      return data
    }catch (err) {
      console.error(err);
      throw err;
    }
  };



  getCollectionIndex = async(collectionAddress:string):Promise<string>=>{

    console.log("collection to index function this is ", collectionAddress, "Hi bro no value");
    try{
      
      const index =  await this.createNftInstance?.contractToIndex(collectionAddress);
      console.log("received index in get index function ===>", index);
      return index;
    }catch(err){
      throw err;
    }
  }
}
