import {
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import VoeuxGameHeader from "./VoeuxGameHeader";
import smallcoin from "../../assets/VoeuxGame/smallcoin.svg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { TokenService } from "../../services/tokenService";
import axios from "axios";
import { Environment } from "src/environments";
import { GameService } from "../../services/gameService";
import { GAME_REWARD_CONTRACT_ADDRESS } from "src/constants/ABIs/gameRewardABI";
import ToastMessageInformation from "../../components/ToastMessage/ToastMessageInformation";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import smalltoken from "../../assets/VoeuxGame/smallmetatok.png";
import direction from "../../assets/VoeuxGame/direction.png";
import tinycoin from "../../assets/VoeuxGame/tinycoin.png";
import largetoken from "../../assets/svgs/metavoeuxCircle.svg";
import exchangeicon from "../../assets/VoeuxGame/exchange.svg";
import smalltok from "../../assets/svgs/metavoeuxCircle.svg";

function AdminGame() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/gameprofile");
  };

  const [tokenInstance, setTokenInstance] = useState<TokenService>();
  useEffect(() => {
    setShowLoader(true);
    const tokenInst = new TokenService();
    const intervalId = setInterval(() => {
      if (
        tokenInst?.tokenInstance?.contractInstance !== null &&
        tokenInst?.tokenInstance?.contractInstance !== undefined
      ) {
        setTokenInstance(tokenInst);
        clearInterval(intervalId);
      }
    }, 100);
  }, []);

  const [gameService, setGameService] = useState<GameService>();
  useEffect(() => {
    const gameInst = new GameService();
    console.log("gameInst", gameInst);
    const intervalId = setInterval(() => {
      if (
        gameInst?.gameServiceInstance?.contractInstance !== null &&
        gameInst?.gameServiceInstance?.contractInstance !== undefined
      ) {
        setGameService(gameInst);
        clearInterval(intervalId);
      }
    }, 100);
  }, []);
  const [allPoints, setAllPoints] = useState<any>({});
  const getAllPoints = async () => {
    try {
      const response = await axios.get(`${Environment.metavoeuxBackend}game/`);
      console.log("game all point ", response);
      setAllPoints(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllPoints().then(() => {
      getRewardRate().then(() => {
        getGameToken();
        setShowLoader(false);
      });
    });
  }, [gameService]);

  const [rewardRate, setRewardRate] = useState<any>();
  const [token, setToken] = useState<number>(0);
  const [tokenData, setTokenData] = useState<any>("");

  const getRewardRate = async () => {
    try {
      const data = await gameService?.getCurrentRewardRate();
      setRewardRate(Number(data));
      const noOfToken = Math.round(Number(allPoints.totalPoint) / Number(data));
      const value1 = noOfToken;
      setToken(noOfToken);
      setTokenData(value1);
      if (noOfToken) {
        handleError(noOfToken);
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };
  const [depositTokenValue, setDepositTokenValue] = useState<number>();
  const [withdrawTokenValue,setWithdrawTokenValue] = useState<number>();

  const givePermission = async (amount: number) => {
    try {
      const approveResponse = await tokenInstance?.approveTransaction(
        GAME_REWARD_CONTRACT_ADDRESS,
        amount
      );
      return approveResponse;
    } catch (err) {
      throw err;
    }
  };
  const handleDepositToken = async () => {
    try {
      if (depositTokenValue !== undefined && depositTokenValue > 0) {
        await givePermission(depositTokenValue);
        setTimeout(async () => {
          const resp = await gameService?.depositTokenInGame(depositTokenValue);
          console.log("response of deposit token:", resp);
          setTimeout(async () => {
            await getGameToken();
          }, 5000);
        }, 10000);
      } else {
        console.error("Invalid token Value");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleWithdrawToken = async () => {
    try{
      if(withdrawTokenValue && withdrawTokenValue > 0){
        const resp = await gameService?.withdrawToken(withdrawTokenValue);
      console.log("withdraw token response:", resp);
      }
      
    }catch (err){
      console.log("error in withdraw token", err);
    }
  }

  const [balance, setBalance] = useState<any>();
  const getGameToken = async () => {
    try {
      const response = await gameService?.getTokenBalance();
      console.log("getGameToken", response);
      const sample = Number(response);
      const filteredData = sample / 10 ** 18;
      setBalance(filteredData);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const [showWarningToast, setShowWarningToast] = useState(false);
  const handleError = (tokenData: any) => {
    if (balance < tokenData) {
      setShowWarningToast(true);
      showToastInfoMessageClick(`Minimum Token Required ${tokenData}`);
    } else {
      setShowWarningToast(false);
      closeToastInfoMessage();
    }
    setShowLoader(false);
  };
  const [showInfoToastMessage, setShowInfoMessage] = useState<any>(false);
  const [toastInfoMessage, setToastInfoMessage] = useState<string>("");
  const closeToastInfoMessage = () => {
    setShowInfoMessage(false);
  };
  const showToastInfoMessageClick = (text: string) => {
    setShowInfoMessage(true);
    setToastInfoMessage(text);
  };

  const [newRewardRate, setNewRewardRate] = useState<number>();

  const updateGameRewardRate = async (newRewardRate: number) => {
    try {
      const gameRewardRate = await gameService?.updateGameRewardRate(
        newRewardRate
      );
      console.log("response of update game reward rate", gameRewardRate);
    } catch (err) {
      console.error("Error updating game reward rate:", err);
    }
  };

  const [showLoader, setShowLoader] = useState(false);
  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1400,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      {showWarningToast && (
        <ToastMessageInformation
          text={toastInfoMessage}
          display={showInfoToastMessage}
          handleClose={closeToastInfoMessage}
        />
      )}

      <Box
        sx={{
          background: "black",
          width: "100%",
          minHeight: "100vh",
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          overflow: "hidden",
        }}
      >
        <Box>
          <VoeuxGameHeader />
          <Box
            sx={{
              display: "flex",
              marginBottom: "10px",
              cursor: "pointer",
              color: "#ECE218",
            }}
          >
            <ArrowBackIosIcon onClick={() => handleBack()} />
            <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              className="polygonStyle2 userProfilePolygon"
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "#181818",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                backdropFilter: "blur(10.781231880187988px)",
               
                width: { xs: "100%", md: "100%",lg:"80%", sm:"80%" },
                height: "max-content",
                py: "40px",
                gap: "20px",
                "@media(max-width:1240px)":{
                  width:"100%"
                },
                "@media(max-width:899px)":{
                  width:"80%"
                },
                "@media(max-width:600px)":{
                  width:"100%"
                },
              }}
            >
              <Box>
                {" "}
                <Typography
                  className="blendarPro"
                  sx={{
                    fontSize: { xs: "22px", md: "36px" },
                    fontFamily: "Blender Pro",
                    fontWeight: " 700",
                  }}
                >
                  {" "}
                  ADMIN
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  className="adminCardPolygon BlenderProBold"
                  sx={{
                    width: "280px",
                    height: "189px",
                    background: "rgba(255, 187, 51, 0.15)",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box textAlign="center">
                    {" "}
                    <Typography
                    className="blendarPro"
                      sx={{
                        fontFamily: "Blender Pro",
                        fontSize: { xs: "30px", md: "56.19px" },
                        textTransform: "uppercase",
                        fontWeight: "500px",
                        color: "#FFBB33",
                      }}
                    >
                      {allPoints.totalPoint}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img draggable="false" src={smallcoin} alt="img" />
                    <Typography
                      className="blendarPro"
                      sx={{
                        fontSize: "18.11px",
                        color: "#FFBB33",
                        fontFamily: "Blender Pro",
                        fontWeight: "500px",
                        textTransform: "uppercase",
                        marginLeft: "10px",
                      }}
                    >
                      total user vx points
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className="adminCardPolygon BlenderProBold"
                  sx={{
                    width: "280px",
                    height: "189px",
                    background: "rgba(0, 147.90, 255, 0.23)",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box textAlign="center">
                    {" "}
                    <Typography
                     className="blendarPro"
                      sx={{
                        fontFamily: "Blender Pro",
                        fontSize: { xs: "30px", md: "56.19px" },
                        textTransform: "uppercase",
                        fontWeight: "500",
                        color: "#06F0F6",
                      }}
                    >
                      {balance}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <img draggable="false" src={smalltoken} alt="img" />
                    <Typography
                      className="blendarPro"
                      sx={{
                        fontSize: "18.11px",
                        color: "#06F0F6",
                        fontFamily: "Blender Pro",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        marginLeft: "10px",
                      }}
                    >
                      current Token balance
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className="adminCardPolygon BlenderProBold"
                  sx={{
                    width: "280px",
                    height: "189px",
                    background: "#575305",
                    borderRadius: "4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box textAlign="center">
                    {" "}
                    <Typography
                     className="blendarPro"
                      sx={{
                        fontFamily: "Blender Pro",
                        fontSize: { xs: "30px", md: "56.19px" },
                        textTransform: "uppercase",
                        fontWeight: "500px",
                        color: "#ECE218",
                      }}
                    >
                      {token}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <img draggable="false" src={tinycoin} alt="img" />
                    <img draggable="false" src={direction} alt="img" />
                    <img draggable="false" src={smalltoken} alt="img" />
                    <Typography
                      className="blendarPro"
                      sx={{
                        fontSize: "18.11px",
                        color: "#ECE218",
                        fontFamily: "Blender Pro",
                        fontWeight: "500",
                        textTransform: "uppercase",
                        marginLeft: "10px",
                      }}
                    >
                      EXPECTED Token balance
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #CCCCCC",
                  width: "70%",
                  margin: "0 auto",
                  opacity: "0.20px",
                }}
              />
              {/* deposit  */}
              <Box
                sx={{
                  width: "100%",

                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  position: "relative",
                  display: "flex",
                  padding:"10px 40px"
                }}
              >
                <Box>
                  <Box
                    className=" inputpolygonStyle"
                    sx={{
                      border: `1px solid #818181`,
                      marginTop: "20px",
                      width: { md: "auto", xs: "inherit" },
                      background: `transparent`,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(52deg)",
                        top: "-12px",
                        left: "8px",
                      }}
                    ></Box>
                    <TextField
                      sx={{
                        width: { md: "448px", xs: "100%" },
                        "& .MuiInputBase-root": {
                          position: "relative",
                          "&::after": {
                            content: "''",
                            backgroundImage: `url(${largetoken})`,
                            backgroundSize: "contain",
                            width: "30px",
                            height: "30px",
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          },
                        },
                        "& input": {
                          outline: "none",
                          border: "0.65px solid #818181",
                          color: "white",
                          background: "transparent",
                          paddingRight: "30px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      type="number"
                      id="entertoken"
                      placeholder="DESPOIT VOEUX POINTS"
                      variant="outlined"
                      name="coins"
                      onChange={(e) => {
                        setDepositTokenValue(Number(e.target.value));
                      }}
                    />
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(60deg)",
                        bottom: "-12px",
                        right: "3px",
                      }}
                    ></Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img draggable="false" src={smallcoin} alt="img" />
                    <Typography  
                     className="blendarPro"
                      sx={{
                        fontSize: { md: "18.34px", xs: "14px" },
                        textTransform: "uppercase",
                        fontWeight: 250,
                        fontFamily: "blendarPro",
                        "@media (max-width: 375px)": {
                          fontSize: "10px",
                        },
                      }}
                    >
                      5000 VX Points
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img draggable="false" src={exchangeicon} alt="img" />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography  className="blendarPro"
                      sx={{
                        fontSize: { md: "18.34px", xs: "14px" },
                        textTransform: "uppercase",
                        fontWeight: 250,
                        fontFamily: "blendarPro",
                        "@media (max-width: 375px)": {
                          fontSize: "10px",
                        },
                      }}
                    >
                      1 Voeux
                    </Typography>
                    <img
                      draggable="false"
                      src={smalltok}
                      alt="img"
                      style={{ width: "15px" }}
                    />
                  </Box>
                </Box>
                <Box
                  className="inputpolygonStyle BlenderProBold"
                  sx={{
                    border: `1px solid #D2C915`,
                    padding: "2px",
                    width: { md: "448px", xs: "100%" },
                    // width: "448px",
                    background: `#D2C915`,
                    textAlign: "center",
                  }}
                  onClick={handleDepositToken}
                >
                  <Button
                    className="BlenderProBold"
                    sx={{
                      borderColor: "white",

                      color: "#000",
                      fontSize: "20px",
                      "&:hover": {
                        background: `#D2C915`,
                        color: "#000",
                      },
                    }}
                  >
                    Deposit Now
                  </Button>
                </Box>
              </Box>
              {/* withdraw */}
              <Box
                sx={{
                  width: "100%",

                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  position: "relative",
                  display: "flex", padding:"10px 40px"
                }}
              >
                <Box>
                  <Box
                    className=" inputpolygonStyle"
                    sx={{
                      border: `1px solid #818181`,
                      marginTop: "20px",
                      width: { md: "auto", xs: "inherit" },
                      background: `transparent`,
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(52deg)",
                        top: "-12px",
                        left: "8px",
                      }}
                    ></Box>
                    <TextField  className="blendarPro"
                      sx={{
                        width: { md: "448px", xs: "100%" },
                        "& .MuiInputBase-root": {
                          position: "relative",
                          "&::after": {
                            content: "''",
                            backgroundImage: `url(${largetoken})`,
                            backgroundSize: "contain",
                            width: "30px",
                            height: "30px",
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          },
                        },
                        "& input": {
                          outline: "none",
                          border: "0.65px solid #818181",
                          color: "white",
                          background: "transparent",
                          paddingRight: "30px",
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                      type="number"
                      id="entertoken"
                      placeholder="WITHDRAW VOEUX POINTS"
                      variant="outlined"
                      name="coins"
                      onChange={(e) => {
                        setWithdrawTokenValue(Number(e.target.value));
                      }}
                    />
                    <Box
                      sx={{
                        width: "1px",
                        height: "100%",
                        background: `#818181`,
                        position: "absolute",
                        transform: "rotate(60deg)",
                        bottom: "-12px",
                        right: "3px",
                      }}
                    ></Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img draggable="false" src={smallcoin} alt="img" />
                    <Typography  className="blendarPro"
                      sx={{
                        fontSize: { md: "18.34px", xs: "14px" },
                        textTransform: "uppercase",
                        fontWeight: 250,
                        fontFamily: "blendarPro",
                        "@media (max-width: 375px)": {
                          fontSize: "10px",
                        },
                      }}
                    >
                      5000 VX Points
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img draggable="false" src={exchangeicon} alt="img" />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Typography  className="blendarPro"
                      sx={{
                        fontSize: { md: "18.34px", xs: "14px" },
                        textTransform: "uppercase",
                        fontWeight: 250,
                        fontFamily: "blendarPro",
                        "@media (max-width: 375px)": {
                          fontSize: "10px",
                        },
                      }}
                    >
                      1 Voeux
                    </Typography>
                    <img
                      draggable="false"
                      src={smalltok}
                      alt="img"
                      style={{ width: "15px" }}
                    />
                  </Box>
                </Box>
                <Box
                  className="inputpolygonStyle BlenderProBold"
                  sx={{
                    border: `1px solid #D2C915`,
                    padding: "2px",
                    width: { md: "448px", xs: "100%" },
                    // width: "448px",
                    background: `#D2C915`,
                    textAlign: "center",
                  }}
                  onClick={handleWithdrawToken}
                >
                  <Button
                    className="BlenderProBold"
                    sx={{
                      borderColor: "white",

                      color: "#000",
                      fontSize: "20px",
                      "&:hover": {
                        background: `#D2C915`,
                        color: "#000",
                      },
                    }}
                  >
                    Withdraw Now
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default AdminGame;
