import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Grid,
  useMediaQuery,
} from "@mui/material";
import LandingPageBanner from "../../components/landingPageBanner/LandingPageBanner";
import LandingPageSubscribe from "../../components/landingPageSubscribe/LandingPageSubscribe";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import certikLogo from "../../assets/svgs/certikLogo.svg";
import ButtonLiner from "../../components/button/ButtonLiner";
import BasicTable from "../../components/Table/BasicTable";
import chart from "../../assets/svgs/chart.svg";
import bitCoin from "../../assets/svgs/Bitcoin.svg";
import CardSection from "../component/HomePage/CardSection";
import LandingPageFloatingList from "../../components/LandingPageFloatingList/LandingPageFloatingList";
import Muicard from "../../components/muicard/Muicard";
import NormalCard from "../component/HomePage/NormalCard";
import Muisocial from "../../components/muisocial/Muisocial";
import centerIcon from "../../assets/partners/center.png";
import certicIcon from "../../assets/partners/certik.png";
import coinMarketIcon from "../../assets/partners/CoinmarketcapIcon.png";
import dinoIcon from "../../assets/partners/dino.png";
import etherIcon from "../../assets/partners/ether.png";
import hackenIcon from "../../assets/partners/Hacken.png";
import lionIcon from "../../assets/partners/lion.png";
import whitePaper from "../../assets/LandingPage/whitePaper.svg";
import "./LandingPage.css";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import WhitePaperINBlacknew from "../../assets/LandingPage/WhitePaperInBlack1.svg";
import WhitePaperInWhiteText from "../../assets/LandingPage/WhitePaperInWhiteText.svg";
import axios from "axios";
import { Environment } from "../../../src/environments";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCheckMetaMask } from "src/redux/slices/metaMaskSlice";
import LadingPageVedio from "../../assets/LandingPage/landingpage.mp4";
import LandingPageVedioFade from "../../assets/LandingPage/Fader_overlay.png";
interface TableData {
  index: number;
  name: string;
  symbol: string;
  icon: string;
  balance: number;
  uaw: string;
  percentUaw: string;
  volume: string;
  percentVolume: string;
  uawGraph: string;
}

const tableData: TableData[] = [
  {
    index: 1,
    name: "Bitcoin",
    symbol: "BTC",
    icon: bitCoin,
    balance: 36201.34,
    uaw: "59k",
    percentUaw: "+1.71%",
    volume: "59k",
    percentVolume: "+1.71%",
    uawGraph: chart,
  },
  {
    index: 2,
    name: "Bitcoin",
    symbol: "BTC",
    icon: bitCoin,
    balance: 36201.34,
    uaw: "59k",
    percentUaw: "+1.71%",
    volume: "59k",
    percentVolume: "+1.71%",
    uawGraph: chart,
  },
  {
    index: 3,
    name: "Bitcoin",
    symbol: "BTC",
    icon: bitCoin,
    balance: 36201.34,
    uaw: "59k",
    percentUaw: "+1.71%",
    volume: "59k",
    percentVolume: "+1.71%",
    uawGraph: chart,
  },
  {
    index: 4,
    name: "Bitcoin",
    symbol: "BTC",
    icon: bitCoin,
    balance: 36201.34,
    uaw: "59k",
    percentUaw: "+1.71%",
    volume: "59k",
    percentVolume: "+1.71%",
    uawGraph: chart,
  },
  {
    index: 5,
    name: "Bitcoin",
    symbol: "BTC",
    icon: bitCoin,
    balance: 36201.34,
    uaw: "59k",
    percentUaw: "+1.71%",
    volume: "59k",
    percentVolume: "+1.71%",
    uawGraph: chart,
  },
  {
    index: 6,
    name: "Bitcoin",
    symbol: "BTC",
    icon: bitCoin,
    balance: 36201.34,
    uaw: "59k",
    percentUaw: "+1.71%",
    volume: "59k",
    percentVolume: "+1.71%",
    uawGraph: chart,
  },
  {
    index: 7,
    name: "Bitcoin",
    symbol: "BTC",
    icon: bitCoin,
    balance: 36201.34,
    uaw: "59k",
    percentUaw: "+1.71%",
    volume: "59k",
    percentVolume: "+1.71%",
    uawGraph: chart,
  },
  {
    index: 8,
    name: "Bitcoin",
    symbol: "BTC",
    icon: bitCoin,
    balance: 36201.34,
    uaw: "59k",
    percentUaw: "+1.71%",
    volume: "59k",
    percentVolume: "+1.71%",
    uawGraph: chart,
  },
];

const textStyles = {
  fontSize: { xs: 16, sm: 28, md: 40, lg: 42, xl: 46 },
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: { xs: "27px", sm: "40px", md: "64px", lg: "72px", xl: "70px" },
  textTransform: "capitalize",
};
const textStylesContaint = {
  fontSize: { xs: 11, sm: 17, md: 19, xl: 22 },
  fontStyle: "normal",
  // fontWeight: 100,
  lineHeight: { xs: "164%", xl: "160%" },
  color: "#FFF",
};
const LandingPage = () => {
  const [showButton, setShowButton] = useState(false);
  const firstPartnerList: string[] = [
    centerIcon,
    certicIcon,
    coinMarketIcon,
    dinoIcon,
    etherIcon,
    hackenIcon,
    lionIcon,
    centerIcon,
    certicIcon,
    coinMarketIcon,
    dinoIcon,
    etherIcon,
    hackenIcon,
    lionIcon,
    centerIcon,
    certicIcon,
    coinMarketIcon,
    dinoIcon,
    etherIcon,
    hackenIcon,
    lionIcon,
    centerIcon,
    certicIcon,
    coinMarketIcon,
    dinoIcon,
    etherIcon,
    hackenIcon,
    lionIcon,
  ];
  // const secondPartnerList: string[] = [
  //   metamaskIcon,
  //   pIcon,
  //   shieldIcon,
  //   unicornIcon,
  //   wolfIcon,
  //   coinStackIcon,
  //   metamaskIcon,
  //   pIcon,
  //   shieldIcon,
  //   unicornIcon,
  //   wolfIcon,
  //   coinStackIcon,
  //   metamaskIcon,
  //   pIcon,
  //   shieldIcon,
  //   unicornIcon,
  //   wolfIcon,
  //   coinStackIcon,
  //   metamaskIcon,
  //   pIcon,
  //   shieldIcon,
  //   unicornIcon,
  //   wolfIcon,
  //   coinStackIcon,
  // ];

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const data = localStorage.getItem("metaMaskAddress");
    if (data == null) {
      dispatch(setCheckMetaMask(false));
    }
    // Perform your action here
    console.log("Router changed");
  }, [location]);

  const [tokenList, setTokenList] = useState<
    {
      name: string;
      logo: string;
      symbol: string;
      price: string;
      priceChange24hrs: number;
      priceChange7days: number;
      marketCap: number;
    }[]
  >([]);
  const [nftList, setNftList] = useState<
    {
      title: string;
      img: string;
      floorPrice: number;
      floorPriceChange24hrs: number;
      marketCap: number;
      marketCapChange24hrs: number;
      volume: number;
      volumeChange24hrs: number;
    }[]
  >([]);
  const [showTokenTable, setShowTokenTable] = useState(false);
  const [showNftTable, setShowNftTable] = useState(false);

  const [tokenResData, setTokenResData] = useState<any>([]);
  const getTopTokens = async () => {
    try {
      const response = await axios.get(`${Environment.metavoeuxBackend}token`);

      const tokens = response.data.map((token: any) => {
        return {
          rank: token.rank,
          name: token.name,
          symbol: token.symbol,
          logo: token.logo,
          price: Number(token.quote.price).toFixed(4),
          priceChange24hrs: Number(token.quote.percentageChange24h).toFixed(4),
          priceChange7days: Number(token.quote.percentageChange7d).toFixed(4),
          marketCap: token.quote.marketCap,
        };
      });
      console.log("tokens details", tokens);
      setTokenResData(tokens);

      setTokenList(tokens);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getTopTokens();
    const intervalId = setInterval(() => {
      getTopTokens();
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleMouseOverWhiteButton = () => {
    setShowButton(true);
  };
  const handleMouseOutWhiteButton = () => {
    setShowButton(false);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    top: "25%",
    left: "25%",
    transform: "translateY(-50%)",
    width: "372.165px",
    height: "127.584px",
    flexShrink: 0,
    borderRadius: "372.165px",
    background: "var(--9-d-00-ff, #9D00FF)",
    filter: "blur(100px)",
    backdropFilter: "blur(100px)",
    WebkitBackdropFilter: "blur(100px)",
    zIndex: -1,
  };
  const circleStyleBlock: React.CSSProperties = {
    position: "absolute",
    flexShrink: 0,
    borderRadius: "367.166px",
    background: "var(--ff-990-d, #FF990D)",
    filter: "blur(140px)",
    backdropFilter: "blur(140px)",
    WebkitBackdropFilter: "blur(140px)",
    zIndex: -1,
  };
  const circleStyleFirst: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    // left: "50%",
    transform: "translate(-50%, -50%)",
    width: "547.625px",
    height: "187.734px",
    flexShrink: 0,
    borderRadius: "547.625px",
    background: "#9D00FF",
    filter: "blur(150px)",
    backdropFilter: "blur(150px)",
    WebkitBackdropFilter: "blur(150px)",
    zIndex: -1,
  };

  const handleButtonClick = () => {
    console.log("button click");
  };

  return (
    <>
      <Box
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 4% 10%",
          },
        }}
      >
        <NewNftHeader />
        <Grid
          container
          sx={{
            mb: { xs: 0, sm: 0, lg: 12, xl: 12 },
            "@media  (min-width: 1600px)": {
              mb: "0px !important",
            },
            //  border: "1px solid white"
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              mt: { md: 2, xl: 3 },
              maxWidth: { md: "600px", lg: "800px", xl: "700px" },
              //  border: "1px solid blue"
              position: "relative",
              "@media  (min-width: 768px)": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <Box
              sx={{
                ...circleStyleBlock,
                top: { md: "50%" },
                transform: {
                  xs: "translate(-50%, 10%)",
                  md: "translate(-50%, 50%)",
                  lg: "translate(-50%, 50%)",
                },
                width: "547.625px",
                // height: "187.734px",
                height: { xs: "110px", md: "187.734px" },
                borderRadius: "547.625px",
                background: "#9D00FF",
                filter: "blur(150px)",
                backdropFilter: "blur(150px)",
                WebkitBackdropFilter: "blur(150px)",
              }}
            ></Box>
            <Stack
              sx={{
                display: "flex",
                justifyContent: { xs: "center" },
                mt: { xs: 0, sm: 5, lg: 1, xl: 1 },
                maxWidth: { md: "600px", lg: "800px", xl: "700px" },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  ...textStyles,
                  "@media (max-width: 600px)": {
                    maxWidth: "390px",
                    textAlign: "center",
                    mx: "auto",
                    fontSize: { xs: "25px !important" },
                    fontWeight: { xs: 500 },
                    lineHeight: { xs: "36px" },
                  },
                }}
              >
                <span style={{ color: "#FF990D", marginRight: "8px" }}>
                  MetaVoeux
                </span>
                <span style={{ color: "#F2F2F2" }}>
                  All-in-One Metaverse Hub for{" "}
                </span>

                <span style={{ color: "#F2F2F2" }}>
                  Blockchain, NFTs, and Games!
                </span>
              </Typography>
            </Stack>
            {!isMobile && (
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: { xs: 2, md: 2 },
                  mb: { xs: 0, md: 2 },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    ...textStylesContaint,
                    "@media (max-width: 600px)": {
                      maxWidth: "300px",
                      textAlign: "center",
                      mx: "auto",
                      fontSize: { xs: "15px !important" },
                      fontWeight: "300 !important",
                    },
                  }}
                >
                  <span>
                    MetaVoeux combines blockchain technology, DeFi and the
                    Metaverse. It's powered by MetaVoeux Ecosystem
                  </span>
                </Typography>
              </Stack>
            )}
            {/* web */}
            <Stack
              rowGap={3}
              sx={{
                display: { xs: "none", md: "flex" },
                my: { xs: 0, sm: 3, lg: 11 },
                justifyContent: "start",
                alignItems: "start",
                marginTop: "1.6px !important",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
              }}
            >
              <ButtonLiner
                type="image"
                onClick={handleButtonClick}
                backgroundColor="#360855"
              >
                {/* <Box sx={{py:"2px"}}> */}
                <img
                  src={certikLogo}
                  alt="certik logo"
                  draggable="false"
                  height={"80%"}
                  width={"100%"}
                />

                {/* </Box> */}
              </ButtonLiner>

              <Button
                onClick={() => {
                  window.open(
                    require("../../assets/pdf/MetavoeuxWhitePaper.pdf"),
                    "_blank"
                  );
                }}
                className="buttonAnimation"
                onMouseOver={handleMouseOverWhiteButton}
                onMouseLeave={handleMouseOutWhiteButton}
                sx={{
                  width: { sm: "80%", md: "182px" },
                  height: { sm: "80%", md: "35px" },
                  border: "1px solid white",
                  background: "#fff",
                  borderRadius: "8px",
                  "&:hover": {
                    background: "none",
                    color: "black",
                  },
                  mt: "2px",
                  // pt: "5px",
                  p: ".7% 4%",

                  ml: { md: 1 },
                  // paddingX: { sm: 4 },
                }}
              >
                {!showButton && (
                  <img
                    src={WhitePaperInWhiteText}
                    alt="img"
                    draggable="false"
                    height={"100%"}
                    width={"100%"}
                  />
                )}
                {showButton && (
                  <img
                    src={WhitePaperINBlacknew}
                    alt="img"
                    draggable="false"
                    height={"42%"}
                    width={"100%"}
                  />
                )}
              </Button>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: { sm: 0 },
              alignItems: "top",
              "@media (max-width: 600px)": {
                m: 0,
                p: 0,
              },
              position: "relative",
            }}
          >
            <Box
              sx={{
                ...circleStyleBlock,
                top: { md: "50%" },
                right: { xs: "35px", md: "0%", xl: "0%" },
                display: "none",
                transform: { xs: "translateY(11%)", md: "translateY(-50%)" },
                // transform:{xs:""}
                // left: { md: "90%" },

                width: { xs: "35px", md: "400px", xl: "700.166px" },
                height: { xs: "300px", md: "170.793px" },
                borderRadius: "367.166px",
                background: " #FF990D",
                filter: { xs: "blur(70px)", md: "blur(130px)" },
                backdropFilter: { xs: "blur(70px)", md: "blur(130px)" },
                WebkitBackdropFilter: { xs: "blur(70px)", md: "blur(130px)" },
                overflow: "hidden",
                "@media (min-width: 1721px)": {
                  right: "-9%",
                },
                "@media (min-width: 1920px)": {
                  right: "-14%",
                  height: "250.793px",
                  filter: "blur(170px)",
                },
              }}
            ></Box>
            <Box
              sx={{
                height: { md: "80%", xl: "90%" },
                width: { md: "80%", xl: "90%" },
                "@media (max-width: 600px)": {
                  width: "80%",
                  height: "100%",
                  m: 0,
                  p: 0,
                },
              }}
            >
              {isMobile ? (
                <img
                  src={
                    "https://d24m5mu8cxqwfm.cloudfront.net/images/blocknew2.gif"
                  }
                  alt="block"
                  className="mainImage"
                  height={"100%"}
                  draggable="false"
                  width={"100%"}
                  style={{
                    backgroundPosition: "left center",
                    backgroundSize: "cover",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    height: "600px",
                    width: "400px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "20%",
                  }}
                >
                  {/* <img
                src={
                  "https://d24m5mu8cxqwfm.cloudfront.net/images/blocknew2.gif"
                }
                alt="block"
                className="mainImage"
                height={"100%"}
                draggable="false"
                width={"100%"}
                style={{
                  backgroundPosition: "left center",
                  backgroundSize: "cover",
                }}
              /> */}
                  <video
                    style={{
                      objectFit: "cover",
                      objectPosition: "0 0",
                      height: "inherit",
                      width: "100%",
                      margin: "auto",
                    }}
                    // style={{ width: "inherit" }}
                    // height="inherit"
                    autoPlay
                    loop
                    muted
                  >
                    <source src={LadingPageVedio} type="video/mp4"></source>
                  </video>
                  <img
                    src={LandingPageVedioFade}
                    style={{
                      objectFit: "cover",
                      margin: "auto",
                      width: "100%",
                      objectPosition: "0 0",
                      height: "inherit",
                      position: "absolute",
                      zIndex: 1,
                      top: 0,
                      left: 0,
                      right: 0,
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack
              // rowGap={2}
              sx={{
                display: { xs: "flex", md: "none" },
                my: { xs: 0 },
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                // mx: "auto",
                // px:"auto"
              }}
            >
              <Box
                sx={{ width: { sm: "200px", md: "182px" }, marginTop: "-2px" }}
              >
                {/* <Button sx={{
                 background: `linear-gradient(#0c0b0f 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box`,
                 border: "1px solid transparent",
                 borderRadius: {xs:"5px",md:"2px"},
                 cursor: "pointer",
                 padding: {xs:"0%  1% 0% 2%",md:".5% 1% .5% 1%"},
                 marginRight: {md:"20px"},
                //  backgroundColor: backgroundColor,
              }}>
 <img
                  src={certikLogo}
                  alt="certik logo"
                  height={"80%"}
                  width={"100%"}
                />
              </Button> */}
                <ButtonLiner
                  type="image"
                  onClick={handleButtonClick}
                  backgroundColor="#0c0b0f"
                >
                  <Box
                    sx={{
                      py: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={certikLogo}
                      alt="certik logo"
                      draggable="false"
                      height={"40%"}
                      width={"60%"}
                    />
                  </Box>
                </ButtonLiner>
              </Box>
              <Button
                onClick={() => {}}
                onMouseOver={handleMouseOverWhiteButton}
                onMouseLeave={handleMouseOutWhiteButton}
                sx={{
                  width: { xs: "11rem" },
                  height: { xs: "65%" },

                  borderRadius: "10px",
                  "&:hover": {
                    background: "white",
                  },
                }}
              >
                {!showButton && (
                  <img
                    src={whitePaper}
                    alt="img"
                    height={"100%"}
                    width={"100%"}
                    draggable="false"
                  />
                )}
                {showButton && (
                  <img
                    src={whitePaper}
                    alt="img"
                    height={"80%"}
                    width={"100%"}
                    draggable="false"
                  />
                )}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        {/* <Grid xs={12}></Grid> */}
        <Muicard />
      </Box>
      <Grid
        container
        sx={{
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          px: { xs: 2, sm: 3, md: 5 },
        }}
      >
        <Typography
          sx={{
            typography: { xs: "h5", sm: "h4" },
            textAlign: { xs: "center", md: "inherit" },
            mb: { xs: 3, lg: 3, xl: 4 },
            mt: 4,
            width: "100vw",
            fontSize: { xs: "23px !important", md: "32px !important" },

            "@media (min-width: 1721px)": {
              paddingX: 0,
            },
          }}
        >
          <span
            style={{
              color: "#F2F2F2",
              // fontSize: "32px",
              fontWeight: "500",
              fontFamily: "outfit",
            }}
          >
            {" "}
            Popular{" "}
          </span>
          <span
            style={{
              color: "#FF990D",
              // fontSize: "32px",
              fontWeight: "500",
              fontFamily: "outfit",
            }}
          >
            Cryptocurrencies
          </span>
        </Typography>

        <LandingPageFloatingList list={tokenList} />
        <Grid
          item
          xs={12}
          sx={{
            my: 4,
            "@media (min-width: 1721px)": {
              paddingX: 0,
            },
          }}
        >
          <BasicTable tableData={tokenList.slice(0, 10)} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{ overflowX: "hidden", mt: { lg: 4, xl: 0 }, position: "relative" }}
      >
        <Grid
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Typography
            sx={{
              color: "#FFF",
              textAlign: "center",
              // fontSize: '32px',
              fontStyle: "normal",
              fontWeight: 600,
              m: 4,
              lineHeight: "34.173px", // You can also use '170.867%' here directly
              typography: { xs: "h5", sm: "h4" },
            }}
          >
            Trusted Partners 
          </Typography> */}
        </Grid>
        {/* <Grid item>
          <PartnerIconsContainer list={firstPartnerList} />
        </Grid> */}
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 6,
          mb: 0,
          px: { xs: 2, sm: 3, md: 5 },
          "@media (min-width: 1721px)": {
            padding: "1% 10%",
          },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={7}
          paddingBottom={0}
          sx={
            {
              // backgroundColor: "lightgray",
            }
          }
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "521.475px",
            }}
          >
            <Typography
              sx={{
                // typography: { xs: "h5", sm: "h4" },
                textAlign: { xs: "center", md: "inherit" },
                fontSize: { xs: "23px", md: "32px" },
                position: "relative",
              }}
            >
              <span
                style={{
                  color: "#F2F2F2",
                  fontFamily: "outfit",
                  fontWeight: "500",
                }}
              >
                Join The Voeux Club{" "}
              </span>
              <span
                style={{
                  color: "#FF990D",
                  fontFamily: "outfit",
                  fontWeight: "500",
                }}
              >
                Community
              </span>
              <span
                style={{
                  color: "#F2F2F2",
                  fontFamily: "outfit",
                  fontWeight: "500",
                }}
              >
                {" "}
                {!isMobile && "around the world"}
              </span>
            </Typography>
          </Stack>

          <Box
            sx={{
              // width: "100%",
              filter: "drop-shadow(0px 3.162px 37.548px #2F0849)",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",

              // height: "100%",
              // py:0,
            }}
          >
            <Box
              sx={{
                // py:0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: {
                  xs: 2,
                  md: 0,
                  lg: 0,
                },
                width: {
                  xs: "innerWidth",
                  md: "40%",
                  lg: "100%",
                },
                position: "relative",
              }}
            >
              <Box
                sx={{
                  ...circleStyleBlock,
                  // top: { md: "25%" },
                  left: { md: "25%" },
                  // transform: "translateY(-50%)",
                  width: "90%",
                  height: "97.584px",
                  borderRadius: "372.165px",
                  background: "var(--9-d-00-ff, #9D00FF)",
                  filter: "blur(100px)",
                  backdropFilter: "blur(100px)",
                  WebkitBackdropFilter: "blur(100px)",
                  top: "86px",
                }}
              ></Box>

              {/* earth img hai ye  */}
              <img
                src="https://d24m5mu8cxqwfm.cloudfront.net/images/earth_icon2.svg"
                alt="earth"
                draggable="false"
                height={"100%"}
                width={"100%"}
                style={{}}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <CardSection />
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            mb: 3,
            "@media (min-width: 1721px)": {
              padding: "0% 10%",
            },
            px: { xs: 1, sm: 3, md: 5 },
          }}
          spacing={
            {
              // lg:6
            }
          }
        >
          <Grid item xs={12} sx={{}}>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "521.475px",
              }}
            >
              <Typography
                sx={{
                  typography: { xs: "h5", sm: "h4" },
                  textAlign: { xs: "center", md: "inherit" },
                  mt: { xs: "10px", sm: 0 },
                  mb: { md: 4 },
                }}
              >
                <span
                  style={{
                    color: "#F2F2F2",
                    fontFamily: "outfit",
                    fontSize: "32px",
                    fontWeight: "500",
                  }}
                >
                  Learn About{" "}
                </span>
                <span
                  style={{
                    color: "#FF990D",
                    fontFamily: "outfit",
                    fontSize: "32px",
                    fontWeight: "500",
                  }}
                >
                  Web3
                </span>
              </Typography>
            </Stack>
            <NormalCard />
          </Grid>

          {/* <Grid item xs={12} sm={6} marginTop={0} paddingTop={0}>
            <SimpleCard />
          </Grid> */}
          {/* <Box sx={{px:5}}> */}

          {/* </Box> */}
        </Grid>
      )}

      {!isMobile && <Muisocial />}

      <LandingPageBanner />
      <LandingPageSubscribe />
      <LandingPageFooter />
    </>
  );
};

export default LandingPage;
