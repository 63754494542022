import { Button, Typography, Grid } from "@mui/material";
import { Box, Stack } from "@mui/system";
import certikLogo from "../../assets/svgs/certikLogo.svg";
import NewNftHeader from "../newNftHeader/NewNftHeader";
import hometokenimage from "../../assets/images/hometoken.png";
import Tokencard from "./Tokencard";
import LandingPageFooter from "../landingPageFooter/LandingPageFooter";
import useMediaQuery from "@mui/material/useMediaQuery";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ButtonLiner from "../button/ButtonLiner";
import airdrop from "../../assets/images/AirDrop.png";
import WhitePaperINBlacknew from "../../assets/LandingPage/WhitePaperInBlack1.svg";
import WhitePaperInWhiteText from "../../assets/LandingPage/WhitePaperInWhiteText.svg";

import whitePaper from "../../assets/LandingPage/whitePaper.svg";
import React, { useRef, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
const textStyles = {
  fontSize: { xs: "22px", md: "56px" },
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: { xs: "27px", md: "94px" },
  textTransform: "capitalize",
};

function Voeuxtoken() {
  const [showButton, setShowButton] = useState(false);
  const address = useRef(null);
  function handleButtonClick(): void {
    throw new Error("Function not implemented.");
  }

  const handleWhitePaperClick = () => {
    window.open(require("../../assets/pdf/MetavoeuxWhitePaper.pdf"), "_blank");
  };

  const handleMouseOverWhiteButton = () => {
    setShowButton(true);
  };
  const handleMouseOutWhiteButton = () => {
    setShowButton(false);
  };

  const copyFieldStyle = {
    border: "1px solid #7C7C7C",
    color: "#7C7C7C",
    borderRadius: "4px",
    padding: "1px",
    outline: "none",
  };

  const borderGradient = {
    background:
      "linear-gradient(#232323 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
    border: "2px solid transparent",
    borderRadius: "10px",
    cursor: "pointer",
    padding: ".5% 4%",
    marginRight: "20px",
    backgroundColor: "#232323",
  };

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const [copyTooltip, setCopyTooltip] = useState(false);
  const [copyMobileTooltip, setCopyMobileTooltip] = useState(false);
  const handleAddressCopy = () => {
    setCopyTooltip(true);
    navigator.clipboard.writeText("0x96c4869380499A7151FEFB084b42BDED7B082220");
  };

  const handleMobileAddressCopy = () => {
    setCopyMobileTooltip(true);
    navigator.clipboard.writeText("0x96c4869380499A7151FEFB084b42BDED7B082220");
  };
  const sample = () => {
    console.log("lkjhg");
    setCopyMobileTooltip(false);
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      {/* <Box sx={{ px: { md: 6.5 } }}> */}

      {/* </Box> */}
      <Box>
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            px: { xs: 1, sm: 3, md: 5 },
            // mt: { lg: 3 },
            pt: 0,
            "@media (min-width: 1721px)": {
              padding: "0% 10% 0% 10%",
            },
            overflow: "hidden",
          }}
        >
          <NewNftHeader />
          <Box
            sx={{
              "@media (max-width: 600px)": {
                padding: "0% 10px",
              },
            }}
          >
            <Stack>
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ position: "relative" }}
              >
                <Box
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    ...circleStyle,
                    top: { xl: "20%" },
                    left: "0%",
                    transform: "translateY(-50%)",
                    width: "350px", // Adjusted width
                    height: "200px", // Adjusted height
                    borderRadius: "50%", // Ensures it remains a perfect circle
                    background: "#9D00FF",
                    filter: "blur(140px)",
                    backdropFilter: "blur(140px)",
                    WebkitBackdropFilter: "blur(140px)",
                    overflow: "hidden",
                    "@media (min-width: 1721px)": {
                      width: "450px", // Adjusted width
                      height: "400px", // Adjusted height
                      top: { xl: "50%" },
                      left: "-20%",
                      filter: "blur(160px)",
                      backdropFilter: "blur(160px)",
                      WebkitBackdropFilter: "blur(160px)",
                    },
                  }}
                ></Box>

                <Grid item xs={12} sm={6} sx={{ mt: { md: 0 } }}>
                  <Stack
                    sx={{
                      display: "flex",
                      // justifyContent: { xs: "center", md: "flex-start" },
                      // alignItems:"flex-start",
                      maxWidth: {
                        xs: "100%", // Define the default maxWidth
                        xl: "585px", // Define maxWidth for extra-large screens (xl breakpoint)
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        ...textStyles,
                        textAlign: { xs: "center", md: "start" },
                      }}
                    >
                      <span
                        style={{
                          color: "#F2F2F2",
                          fontFamily: "outfit",
                          marginRight: "3px",
                        }}
                      >
                        Unlock the metaVoeux EcoSystem with
                      </span>
                      {/* 
                  <span style={{  marginLeft: "10px",marginRight: "2px", color: "#F2F2F2", fontFamily: "outfit" }}>
                    metaVoeux EcoSystem with
                  </span> */}
                      {isMobile ? (
                        <span
                          style={{
                            color: "#fff",
                            marginLeft: "10px",
                            fontFamily: "outfit",
                          }}
                        >
                          Token
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "#FF990D",
                            marginLeft: "10px",
                            fontFamily: "outfit",
                          }}
                        >
                          Token
                        </span>
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FF990D",
                        fontSize: { xs: "18px", xl: "22px" },
                        fontWeight: "400",
                        fontFamily: "outfit",
                        my: "1rem",
                        textAlign: { xs: "center", md: "start" },
                      }}
                    >
                      Native Token of MetaVoeux
                    </Typography>

                    {/* it will use letter */}

                    <Stack
                      sx={{
                        display: { xs: "none", md: "flex" },
                        my: { xs: 0, sm: 3, lg: 4 },
                        justifyContent: "start",
                        alignItems: { xs: "center", sm: "center", lg: "start" },
                        flexDirection: {
                          xs: "row",
                          md: "row",
                        },
                        marginTop: "15px !important",
                        marginBottom: "35px !important",
                      }}
                    >
                      {/* <a
        href={samplefile}
        download="Example-PDF-document"
        target="_blank"
        rel="noreferrer"
      >
        <button>Download .pdf file</button>
      </a> */}

                      <ButtonLiner
                        type="image"
                        onClick={handleButtonClick}
                        backgroundColor="#360855"
                      >
                        {/* <Box sx={{py:"2px"}}> */}
                        <img
                          src={certikLogo}
                          alt="certik logo"
                          height={"80%"}
                          width={"100%"}
                        />

                        {/* </Box> */}
                      </ButtonLiner>

                      <Button
                        onClick={handleWhitePaperClick}
                        onMouseOver={handleMouseOverWhiteButton}
                        onMouseLeave={handleMouseOutWhiteButton}
                        sx={{
                          width: { sm: "80%", md: "182px" },
                          // height: {xs:"50%", sm: "80%", md: "35px" },

                          border: "1px solid white",
                          borderRadius: "8px",
                          color: "white",
                          "&:hover": {
                            background: "white",
                            color: "black",
                          },
                          mt: "2px",
                          // pt: "5px",
                          p: "10px 20px",

                          // ml: { md: 1 },
                          // paddingX: { sm: 4 },
                        }}
                      >
                        {!showButton && (
                          <img
                            src={WhitePaperINBlacknew}
                            alt=""
                            height={"42%"}
                            width={"100%"}
                          />
                        )}
                        {showButton && (
                          <img
                            src={WhitePaperInWhiteText}
                            alt=""
                            height={"42%"}
                            width={"100%"}
                          />
                        )}
                      </Button>
                    </Stack>
                    <Grid item xs={12}>
                      {/* mobile */}
                      <Stack
                        // rowGap={2}
                        sx={{
                          display: { xs: "flex", md: "none" },
                          my: { xs: 0 },
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                          columnGap: "10px",
                          marginTop: "15px !important",
                          marginBottom: "35px !important",
                        }}
                      >
                        <Box
                          sx={{
                            width: { sm: "200px", md: "182px" },
                            marginTop: "-2px",
                          }}
                        >
                          {/* <Button sx={{
                 background: `linear-gradient(#0c0b0f 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box`,
                 border: "1px solid transparent",
                 borderRadius: {xs:"5px",md:"2px"},
                 cursor: "pointer",
                 padding: {xs:"0%  1% 0% 2%",md:".5% 1% .5% 1%"},
                 marginRight: {md:"20px"},
                //  backgroundColor: backgroundColor,
              }}>
 <img
                  src={certikLogo}
                  alt="certik logo"
                  height={"80%"}
                  width={"100%"}
                />
              </Button> */}
                          <ButtonLiner
                            type="image"
                            onClick={handleButtonClick}
                            backgroundColor="#0c0b0f"
                          >
                            <Box
                              sx={{
                                py: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={certikLogo}
                                alt="certik logo"
                                height={"40%"}
                                width={"60%"}
                              />
                            </Box>
                          </ButtonLiner>
                        </Box>

                        <Button
                          onClick={handleWhitePaperClick}
                          onMouseOver={handleMouseOverWhiteButton}
                          onMouseLeave={handleMouseOutWhiteButton}
                          sx={{
                            width: { xs: "11rem" },
                            height: { xs: "65%" },

                            borderRadius: "10px",
                            "&:hover": {
                              background: "white",
                            },
                          }}
                        >
                          {!showButton && (
                            <img
                              src={whitePaper}
                              alt=""
                              height={"100%"}
                              width={"100%"}
                            />
                          )}
                          {showButton && (
                            <img
                              src={whitePaper}
                              alt=""
                              height={"80%"}
                              width={"100%"}
                            />
                          )}
                        </Button>

                        {/* <Button
                      onClick={handleWhitePaperClick}
                      onMouseOver={handleMouseOverWhiteButton}
                      onMouseLeave={handleMouseOutWhiteButton}
                      sx={{
                        width: { sm: "80%", md: "182px" },
                        // height: {xs:"50%", sm: "80%", md: "35px" },

                        border: "1px solid white",
                        borderRadius: "8px",
                        color: "white",
                        "&:hover": {
                          background: "white",
                          color: "black",
                        },
                        // mt: "2px",
                        // pt: "5px",
                        p: "11px 20px",

                        // ml: { md: 1 },
                        // paddingX: { sm: 4 },
                      }}
                    >
                      {!showButton && (
                        <img
                          src={WhitePaperINBlacknew}
                          alt=""
                          height={"42%"}
                          width={"100%"}
                        />
                      )}
                      {showButton && (
                        <img
                          src={WhitePaperInWhiteText}
                          alt=""
                          height={"42%"}
                          width={"100%"}
                        />
                      )}
                    </Button> */}
                      </Stack>
                    </Grid>
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: "700",
                        fontFamily: "outfit",
                        color: "white",
                        display: "block",
                        textAlign: { xs: "center", md: "start" },
                        // Set display to "block" to move to the next line
                      }}
                    >
                      {" "}
                      1 VOEUX = <span style={{ color: "#FF990D" }}>1 </span>USDT
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={6} sx={{ mt: { md: 0 } }}>
                  <Box
                    sx={{
                      height: { xl: "60%" },
                      width: { xs: "100%", sm: "100%", lg: "100%" },
                      textAlign: "right",
                      position: "relative",
                      display: "flex",
                      justifyContent: "flex-start",
                      marginRight: { xs: 0, md: "140px" },
                    }}
                  >
                    <Box
                      sx={{
                        ...circleStyle,
                        display: { xs: "flex", sm: "flex", md: "flex" },
                        top: { xl: "60%", xs: "40%" },
                        left: { xl: "50%", xs: "0%" },
                        transform: "translateY(-50%)",
                        width: "650px", // Adjusted width
                        height: { xl: "300px", xs: "170px" },
                        borderRadius: "50%", // Ensures it remains a perfect circle
                        background: "#FF9C0E",
                        filter: "blur(170px)",
                        backdropFilter: "blur(170px)",
                        WebkitBackdropFilter: "blur(170px)",
                        overflow: "hidden",
                        "@media (min-width: 1721px)": {
                          width: "550px", // Adjusted width
                          height: "200px", // Adjusted height
                          top: { xl: "60%" },
                          left: "80%",
                          filter: "blur(210px)",
                          backdropFilter: "blur(210px)",
                          WebkitBackdropFilter: "blur(210px)",
                        },
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: { md: "511.29px", xs: "inherit" },
                        marginBottom: {
                          xs: "20px ",
                        },
                      }}
                    >
                      <img
                        src={
                          "https://d24m5mu8cxqwfm.cloudfront.net/images/Coin_Fall_Wide.gif"
                        }
                        alt="certik logo"
                        width={"100%"}
                        height="100%"
                      />
                    </Box>
                  </Box>
                  {/* second grid */}
                  {/* <Typography> ANIMATION</Typography> */}
                </Grid>
              </Grid>
            </Stack>
            <Stack>
              <Grid
                container
                spacing={1}
                alignItems="center"
                sx={{ position: "relative" }}
              >
                <Box
                  sx={{
                    ...circleStyle,
                    display: { xs: "none", sm: "none", md: "flex" },
                    top: { xl: "60%" },
                    left: "0%",
                    transform: "translateY(-50%)",
                    width: "550px", // Adjusted width
                    height: "200px", // Adjusted height
                    borderRadius: "0 50% 50% 0", // Ensures it remains a perfect circle
                    background: "#FF9C0E",
                    filter: "blur(160px)",
                    backdropFilter: "blur(160px)",
                    WebkitBackdropFilter: "blur(160px)",
                    overflow: "hidden",
                    "@media (min-width: 1721px)": {
                      width: "550px", // Adjusted width
                      height: "200px", // Adjusted height
                      top: { xl: "60%" },
                      left: "0%",
                      filter: "blur(210px)",
                      backdropFilter: "blur(210px)",
                      WebkitBackdropFilter: "blur(210px)",
                    },
                  }}
                ></Box>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    mt: { xs: 2, md: 2 },
                    width: {
                      xs: "100%",
                      sm: "50%",
                      md: "50%",
                      lg: "50%",
                      xl: "50%",
                    },
                    justifyContent: "center",
                    display: { xs: "none", md: "flex" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: { md: 1 },

                      width: {
                        xs: "100%",
                        sm: "346.519px",
                        md: "446.519px",
                        lg: "446.519px",
                        xl: "446.519px",
                      },
                      height: "auto", // Set height to auto to maintain aspect ratio
                      margin: {
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "2rem",
                        xl: "2rem",
                      }, // Adjust margin for different screen sizes
                    }}
                  >
                    <img
                      src={hometokenimage}
                      alt="tokenimage"
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} sx={{ mt: { md: 2 } }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "26px", md: "52px" },
                      fontWeight: "700",
                      fontFamily: "outfit",
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    What is Voeux{" "}
                    <span style={{ color: "#FF990D" }}>Token</span> ?
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      fontWeight: "400",
                      fontFamily: "outfit",
                      lineHeight: { xs: "30px", md: "39px" },
                      px: { md: 2, xs: 0 },
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    VOEUX is released as an ERC-20 token that serves as a
                    utility token for the whole MetaVoeux ecosystem. It allows
                    users to settle all kinds of business and personal
                    arrangements between themselves and any of the MetaVoeux
                    ecosystem products and services.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "flex-start" },
                    }}
                  >
                    {/* <Button
                sx={{
                  width: { xs: "181px", md: "214.892px" },
                  height: { xs: "34px", md: "51.638px" },
                  border: "1px solid #FF990D",
                  borderRadius: "12px",
                  color: "white",
                  fontFamily: "outfit",
                  background: "transparent", // Set background to transparent
                  marginTop: "1rem",
                  "&:hover": {
                    background: "var(--ffbb-33, #FF990D)", // Hover color
                  },
                  textTransform: "capitalize",
                }}
              >
                
                Buy Voeux Token
              </Button> */}
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    mt: { xs: 2, md: 2 },
                    width: {
                      xs: "100%",
                      sm: "50%",
                      md: "50%",
                      lg: "50%",
                      xl: "50%",
                    },
                    display: {
                      xs: "flex",
                      md: "none",
                      justifyContent: "center",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",

                      marginBottom: { md: 1 },

                      width: {
                        xs: "60%",
                        sm: "346.519px",
                        md: "446.519px",
                        lg: "446.519px",
                        xl: "446.519px",
                      },
                      height: "auto", // Set height to auto to maintain aspect ratio
                      margin: {
                        xs: "0",
                        sm: "0",
                        md: "0",
                        lg: "7rem",
                        xl: "7rem",
                      }, // Adjust margin for different screen sizes
                    }}
                  >
                    <img
                      src={hometokenimage}
                      alt="tokenimage"
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                mx: "auto",

                marginTop: "2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "22px", md: "36px" },
                  fontWeight: "700",
                  fontFamily: "outfit",
                }}
              >
                Tokenomics
              </Typography>
              <div>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                    fontFamily: "outfit",
                    lineHeight: "39px",
                    maxWidth: { md: "600px", lg: "720px", xl: "800px" },
                  }}
                >
                  The Metavoeux tokenomics are designed to incentivize users to
                  participate in the Metavoeux platforms and to contribute to
                  the Metavoeux economy. Metavoeux is creating a more engaging
                  and rewarding user experience by rewarding users for their
                  actions.
                </Typography>
              </div>
            </Stack>
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  marginBottom: { xs: "0px", md: "2rem" },
                  width: "903.206p",
                  height: { md: "616.541px" },
                  margin: "auto",
                  marginTop: "40px",
                }}
              >
                {/* <img
                  src={
                    "https://d24m5mu8cxqwfm.cloudfront.net/images/Tokenomics-new.webm"
                  }
                  alt="tokenimage"
                  width={"100%"}
                  height={"100%"}
                /> */}
                <video width="100%" height="100%" autoPlay loop muted>
                  <source
                    src={
                      "https://d24m5mu8cxqwfm.cloudfront.net/images/Tokenomics-new.webm"
                    }
                    type="video/mp4"
                  ></source>
                </video>
              </Box>
            </Stack>

            <Box
              sx={{
                marginTop: { xs: "10px", md: "2rem" },
                position: "relative",
              }}
            >
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "flex", sm: "flex", md: "flex" },
                  top: { xl: "30%", xs: "10%" },
                  left: "0%",
                  transform: "translateY(-50%)",
                  width: "250px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(132px)",
                  backdropFilter: "blur(132px)",
                  WebkitBackdropFilter: "blur(132px)",
                  overflow: "hidden",
                }}
              ></Box>
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "flex", sm: "flex", md: "flex" },
                  top: { xl: "30%", xs: "50%" },
                  // left: "83%",
                  right: "0",
                  transform: "translateY(-50%)",
                  width: "250px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(132px)",
                  backdropFilter: "blur(132px)",
                  WebkitBackdropFilter: "blur(132px)",
                  overflow: "hidden",
                }}
              ></Box>
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "flex", sm: "none", md: "none" },
                  top: { xs: "92%" },
                  // left: "83%",
                  right: "0",
                  transform: "translateY(-50%)",
                  width: "250px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(132px)",
                  backdropFilter: "blur(132px)",
                  WebkitBackdropFilter: "blur(132px)",
                  overflow: "hidden",
                }}
              ></Box>
              <Tokencard />
            </Box>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                marginTop: { xs: "2rem", xl: "5rem" },
                position: "relative",
              }}
            >
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "flex", sm: "flex", md: "none" },
                  top: { xs: "100%" },
                  left: "-20%",
                  transform: "translateY(130%)",
                  width: "250px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(132px)",
                  backdropFilter: "blur(132px)",
                  WebkitBackdropFilter: "blur(132px)",
                  overflow: "hidden",
                }}
              ></Box>
              <Typography
                sx={{
                  fontSize: { xs: "22px", md: "36px" },
                  fontWeight: "700",
                  fontFamily: "outfit",
                  mb: { xs: "0rem", xl: "2rem" },
                }}
              >
                Token Contract
              </Typography>
            </Stack>

            {/* mobile view */}
            <Stack
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  backgroundColor: "#222126",
                  width: { xs: "100%" },
                  borderRadius: "0 0 10px 10px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    borderTop: "10px solid #FF990D",
                    borderRadius: "10px 10px 0 0 ",
                    backgroundColor: "#222126",
                    px: { xs: 2, md: "0px" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: { xs: "18px", md: "24px" }, mt: 2 }}
                  >
                    Address
                  </Typography>
                  <Box
                    sx={{
                      py: 1,
                      px: 1,
                      border: "0px solid #7C7C7C",
                      height: { xs: "40px", xl: "70px" },
                      position: "relative",
                      borderRadius: "8px",
                      overflow: "hidden", // Prevents text from exceeding the box
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#7C7C7C",
                        marginRight: "20px", // Add margin to create a gap
                      }}
                    >
                      0x96c4869380FEFB...
                      {/* 0x96c4869380499A7151FEFB084b42BDED7B082220 */}
                    </Typography>
                    <Box
                      sx={{
                        height: "40px",
                        width: "40px",
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Copied" open={copyMobileTooltip}>
                        <ContentCopyIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            background: "#222126",
                            color: "#7C7C7C",
                          }}
                          onMouseEnter={() => handleMobileAddressCopy()}
                          onMouseOut={() => sample()}
                          onMouseLeave={() => sample()}
                        />
                      </Tooltip>
                      {/* <ContentCopyIcon
                    style={{
                      cursor: "pointer",
                      background: "#222126",
                      color: "#7C7C7C",
                      marginLeft: "1rem",
                    }}
                   
                  /> */}
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: { xs: 2, md: 5 },
                    py: { xs: 1, md: 2 },

                    borderTop: "1px solid #FF990D",
                    backgroundColor: "#222126",
                    px: { xs: 2, md: "0px" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: { xs: "18px", md: "24px" }, mt: 2 }}
                  >
                    Decimal
                  </Typography>
                  <Box
                    sx={{
                      py: 1,
                      // px: 1,
                      border: "0px solid #7C7C7C",
                      height: { xs: "40px", xl: "70px" },
                      position: "relative",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        whiteSpace: "wrap",
                        display: "flex",
                        justifyContent: "center",
                        color: "#7C7C7C",
                      }}
                    >
                      18
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: { xs: 2, md: 5 },
                    py: { xs: 1, md: 2 },
                    borderTop: "1px solid #FF990D",
                    backgroundColor: "#222126",
                    px: { xs: 2, md: "0px" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: { xs: "18px", md: "24px" }, mt: 2 }}
                  >
                    Network
                  </Typography>
                  <Box
                    sx={{
                      py: 1,
                      // px: 1,
                      border: "0px solid #7C7C7C",
                      height: { xs: "40px", xl: "70px" },
                      position: "relative",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        whiteSpace: "wrap",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        color: "#7C7C7C",
                      }}
                    >
                      Polygon (Matic)
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: { xs: 2, md: 5 },
                    pt: { xs: 1, md: 2 },
                    pb: 2,
                    borderTop: "1px solid #FF990D",
                    backgroundColor: "#222126",
                    px: { xs: 2, md: "0px" },
                    borderRadius: "0px 0px 10px 10px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: { xs: "18px", md: "24px" }, mt: 2 }}
                  >
                    Token Symbol
                  </Typography>
                  <Box
                    sx={{
                      py: 1,
                      // px: 1,
                      border: "0px solid #7C7C7C",
                      height: { xs: "40px", xl: "70px" },
                      position: "relative",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        whiteSpace: "wrap",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        color: "#7C7C7C",
                      }}
                    >
                      VOEUX
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Stack>

            {/* this is for laptop  */}
            <Stack
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "none", sm: "none", md: "flex" },
                  top: { xl: "-25%" },
                  left: "0%",
                  transform: "translateY(30%)",
                  width: "250px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(132px)",
                  backdropFilter: "blur(132px)",
                  WebkitBackdropFilter: "blur(132px)",
                  overflow: "hidden",
                }}
              ></Box>
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "none", sm: "none", md: "flex" },
                  top: { xl: "-25%" },
                  // left: "83%",
                  right: "0",
                  transform: "translateY(30%)",
                  width: "250px", // Adjusted width
                  height: "200px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(132px)",
                  backdropFilter: "blur(132px)",
                  WebkitBackdropFilter: "blur(132px)",
                  overflow: "hidden",
                }}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  borderRadius: "1rem",
                  background: "#222126",
                  marginTop: { xl: "2rem" },
                }}
              >
                <Grid container spacing={2} alignItems="center" sx={{}}>
                  {/* First box */}
                  <Grid item xs={12} sm={4}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* Small box on the left */}
                      <div
                        style={{
                          width: "11.412px",
                          height: "132.106px",
                          flexShrink: 0,
                          borderRadius: "10px 0 0 10px",
                          background: "var(--ff-990-d, #FF990D)",
                        }}
                      ></div>

                      <Box
                        style={{
                          width: "389.472px",
                          height: "132.106px",
                          borderRadius: "0 12px 12px 0",
                          textAlign: "left",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: "1rem",
                            fontSize: "24px",
                            fontWeight: "700",
                            fontFamily: "outfit",
                          }}
                        >
                          Address
                          <br />
                          <Box
                            sx={{
                              height: "50px",
                              width: "350px",
                              border: "0px solid #7C7C7C",
                              borderRadius: "8px",
                              position: "relative",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="0x96c4869380499A7151FEFB084b42BDED7B082220"
                              style={{
                                color: !copyTooltip ? "#7C7C7C" : "green",
                                border: "none",
                                width: "100%",
                                height: "100%",
                                borderRadius: "8px",
                                padding: "0 10px",
                                background: "#222126",
                              }}
                              value="0x96c4869380499A7151FEFB084b42BDED7B082220"
                              readOnly
                              ref={address}
                            />
                            <Tooltip title="Copied" open={copyTooltip}>
                              <ContentCopyIcon
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                  background: "#222126",
                                  color: "#7C7C7C",
                                }}
                                onClick={() => handleAddressCopy()}
                                onMouseOut={() => setCopyTooltip(false)}
                              />
                            </Tooltip>
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {/* Separator line */}
                  <Grid item>
                    <div
                      style={{
                        width: "1px",
                        height: "131.647px",
                        backgroundColor: "#FF990D",
                      }}
                    />
                  </Grid>

                  {/* Second box */}
                  <Grid item>
                    <Box
                      style={{
                        width: "169.472px",
                        height: "132.106px",
                        borderRadius: "12px",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "700",
                          fontFamily: "outfit",
                        }}
                      >
                        Decimal
                        <br />
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: "#7C7C7C",
                            fontFamily: "outfit",
                          }}
                        >
                          18
                        </span>
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Separator line */}
                  <Grid item>
                    <div
                      style={{
                        width: "1px",
                        height: "131.647px",
                        backgroundColor: "#FF990D",
                      }}
                    />
                  </Grid>

                  {/* Third box */}
                  <Grid item>
                    <Box
                      style={{
                        width: "234.439px",
                        height: "132.106px",
                        borderRadius: "12px",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "700",
                          fontFamily: "outfit",
                        }}
                      >
                        Network
                        <br />
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: "#7C7C7C",
                            fontFamily: "outfit",
                          }}
                        >
                          Polygon (Matic)
                        </span>
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Separator line */}
                  <Grid item>
                    <div
                      style={{
                        width: "1px",
                        height: "131.647px",
                        backgroundColor: "#FF990D",
                      }}
                    />
                  </Grid>

                  {/* Fourth box */}
                  <Grid item>
                    <Box
                      style={{
                        width: "300.169px",
                        height: "132.106px",
                        borderRadius: "12px",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "24px",
                          fontWeight: "700",
                          fontFamily: "outfit",
                        }}
                      >
                        Token Symbol
                        <br />
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: "#7C7C7C",
                            fontFamily: "outfit",
                          }}
                        >
                          VOEUX
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
            {/* giveaway session */}
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mx: 2,
                my: { xs: 2, sm: 3, md: 4, lg: 5 },
                "@media (max-width: 600px)": {
                  margin: 0,
                },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", sm: "100%", md: "100%", lg: "80%" },
                  height: "100%",
                  borderRadius: { md: 10, xs: "20px" },
                  background:
                    "linear-gradient(257deg, #FF990D -18.71%, #9D00FF 97.91%)",
                  marginTop: "2rem",

                  padding: 4,
                  marginBottom: "40px",
                }}
              >
                <Grid container sx={{ position: "relative" }}>
                  {/* Left Grid */}
                  {/* <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "none", md: "flex" },
                  top: { xl: "-20%" },
                  left: { xl: "-10%" },
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "100px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(80px)",
                  // overflow:"hidden",
                  "@media (min-width: 1721px)": {
                    width: "350px", // Adjusted width
                    height: "300px", // Adjusted height
                    top: { xl: "20%" },
                    left: "-20%",
                    filter: "blur(100px)",
                  },
                }}
              ></Box>
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "none", md: "flex" },
                  top: { xl: "-20%" },
                  left: { xl: "85%" },
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "100px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: { xl: "blur(80px)" },
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "50%" },
                    left: "-20%",
                    filter: "blur(160px)",
                  },
                }}
              ></Box>
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "none", md: "flex" },
                  top: { xl: "90%" },
                  left: { xl: "-10%" },
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "100px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: "blur(80px)",
                  // overflow:"hidden",
                  "@media (min-width: 1721px)": {
                    width: "350px", // Adjusted width
                    height: "300px", // Adjusted height
                    top: { xl: "20%" },
                    left: "-20%",
                    filter: "blur(100px)",
                  },
                }}
              ></Box>
              <Box
                sx={{
                  ...circleStyle,
                  display: { xs: "none", md: "flex" },
                  top: { xl: "90%" },
                  left: { xl: "85%" },
                  transform: "translateY(-50%)",
                  width: "350px", // Adjusted width
                  height: "100px", // Adjusted height
                  borderRadius: "50%", // Ensures it remains a perfect circle
                  background: "#9D00FF",
                  filter: { xl: "blur(80px)" },
                  overflow: "hidden",
                  "@media (min-width: 1721px)": {
                    width: "450px", // Adjusted width
                    height: "400px", // Adjusted height
                    top: { xl: "50%" },
                    left: "-20%",
                    filter: "blur(160px)",
                  },
                }}
              ></Box> */}
                  <Box
                    sx={{
                      ...circleStyle,
                      display: { xs: "flex", sm: "flex", md: "flex" },
                      top: { xl: "90%", xs: "90%" },
                      left: "-20%",
                      transform: "translateY(37%)",
                      width: "250px", // Adjusted width
                      height: "200px", // Adjusted height
                      borderRadius: "50%", // Ensures it remains a perfect circle

                      background: "#9D00FF",
                      filter: "blur(132px)",
                      backdropFilter: "blur(132px)",
                      WebkitBackdropFilter: "blur(132px)",
                      overflow: "hidden",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      ...circleStyle,
                      display: { xs: "flex", sm: "flex", md: "flex" },
                      top: { xl: "90%" },
                      left: "90%",
                      transform: "translateY(37%)",
                      width: "250px", // Adjusted width
                      height: "200px", // Adjusted height
                      borderRadius: "50%", // Ensures it remains a perfect circle
                      background: "#9D00FF",
                      filter: "blur(132px)",
                      backdropFilter: "blur(132px)",
                      WebkitBackdropFilter: "blur(132px)",
                      overflow: "hidden",
                    }}
                  ></Box>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        sx={{
                          color: "#FB3",
                          textShadow: "0px 4.699px 0px #000",
                          fontFamily: "Outfit",
                          fontSize: { md: "32px", xs: "23px" },
                          fontWeight: 900,
                          lineHeight: "123%",
                          letterSpacing: "1.28px",
                          marginBottom: "1rem",
                          textAlign: { xs: "center", md: "start" },
                        }}
                      >
                        Exclusive Voeux Token Airdrop
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontWeight: 400,
                          fontSize: {
                            xs: 16,
                            md: 20,
                          },
                          textAlign: { md: "left", xs: "center" },
                          marginBottom: { md: "2 rem", xs: 0 },
                          lineHeight: { md: "39px", xs: "30px" },
                          maxWidth: {
                            xs: "100%", // Define the default maxWidth
                            xl: "560px", // Define maxWidth for extra-large screens (xl breakpoint)
                          },
                        }}
                        variant="h6"
                      >
                        {isMobile
                          ? `Welcome to the Voeux Token Celebration Airdrop! 🌟 Don't
                      miss out on this incredible opportunity to get your hands
                      on exclusive Voeux Tokens for free.`
                          : ` Welcome to the Voeux Token Celebration Airdrop! 🌟 Don't
                      miss out on this incredible opportunity to get your hands
                      on exclusive Voeux Tokens for free. Seize the moment and
                      be part of the future of decentralized finance—empowering
                      you with financial freedom like never before.`}
                      </Typography>
                      {/* <Button
                    sx={{
                      width: { sm: "80%", md: "214px" },
                      height:"51px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "12px",
                      flexShrink: 0,
                      background: "var(--ffbb-33, #FB3)",
                      color: "white",
                      fontFamily: "outfit",
                    }}
                  >
                    Join The Giveaway
                  </Button> */}
                    </Stack>
                  </Grid>

                  {/* Right Grid */}
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%", xl: "70%" },
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        marginTop: {
                          xs: 2,
                        },
                      }}
                    >
                      <img
                        src={airdrop}
                        alt="giveawayimage"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </Box>
        <LandingPageFooter />
      </Box>
    </>
  );
}

export default Voeuxtoken;
