import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid} from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import NftProfile from "../../../components/profileNft/NftProfile";
import NftManageBids from "../../../components/profileNft/NftManageBids";
import NftAccount from "../../../components/profileNft/NftAccount";
import NftLanguage from "../../../components/profileNft/NftLanguage";
import NftActivity from "../../../components/profileNft/NftActivity";
import NftWatchList from "../../../components/profileNft/NftWatchList";
import NewNftHeader from "../../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../../components/landingPageFooter/LandingPageFooter";

export default function SettingsSlideMenu() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const GradientBox = {
    borderRadius: "12px",
    transition: "border-radius 0.3s",
    fontFamily: "outfit",
  };

  return (
    <Box
      // className={styles.mainContainer}
      sx={{ width: "100%", typography: "body1" }}
    >
      <NewNftHeader />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Box
            sx={{
              maxWidth: { xs: 620, sm: 480, lg: "100%" },
              bgcolor: "Transparent",
              marginTop: "3rem",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                title: "indicator",
                sx: { backgroundColor: "transparent" },
              }}
            >
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage: "#D9D9D9",
                  },
                }}
                label="Profile"
                value="1"
              />
              {/* Add the rest of the tabs similarly */}
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage: "#D9D9D9",
                  },
                }}
                label="Manage Bids"
                value="2"
              />
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage: "#D9D9D9",
                  },
                }}
                label="Account"
                value="3"
              />
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage: "#D9D9D9",
                  },
                }}
                label="Watchlist"
                value="4"
              />
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage: "#D9D9D9",
                  },
                }}
                label="Language"
                value="5"
              />
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage: "#D9D9D9",
                  },
                }}
                label="Activity"
                value="6"
              />
            </Tabs>
          </Box>
        </Box>
        <Grid container style={{ padding: 20, margin: 0 }}>
          <TabPanel value="1" style={{ padding: 0 }}>
            <NftProfile />
          </TabPanel>
          <TabPanel value="2" style={{ padding: 0 }}>
            <NftManageBids />
          </TabPanel>
          <TabPanel value="3" style={{ padding: 0 }}>
            <NftAccount />
          </TabPanel>
          <TabPanel value="4" style={{ padding: 0 }}>
            <NftWatchList />
          </TabPanel>
          <TabPanel value="5" style={{ padding: 0 }}>
            <NftLanguage />
          </TabPanel>
          <TabPanel value="6" style={{ padding: 0 }}>
            <NftActivity />
          </TabPanel>
        </Grid>
      </TabContext>
      <LandingPageFooter />
    </Box>
  );
}
