import { Button, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box} from "@mui/system";
import {  useState } from "react";
import holdingmoney from "../../assets/images/Holding Money Bag.png";
import holdingmoneymob from "../../assets/images/holidingbagmob.png";
import coinfallimg1 from "../../assets/images/fallingcoin1.png";
import coinfallimg2 from "../../assets/images/fallingcoin2.png";
import fallongmob1 from "../../assets/images/fallinmob1.png";
import fallingmob2 from "../../assets/images/fallinmob2.png";

function InvestFarm({
  investNow,
  handleClose,
}: {
  investNow: (amount: number) => void;
  handleClose: () => void;
}) {
  const [showComponent, setShowComponent] = useState(true);
  const [amount, setAmount] = useState(0);

  const handleCloseButtonClick = () => {
    setShowComponent(false);
  };

  const handleInvestNow = async () => {
    console.log("in handle invest now ");

    investNow(amount);
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      className="sumi"
      sx={{
        display: "flex",
        justifyContent: "center",
        background: "transparent",
      }}
    >
      {showComponent && (
        <Box
          sx={{
            width: "571.161px",
            // height: "445px",
            borderRadius: "28.422px",
            background: "#28272C",
            marginBottom: "20px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <>
              <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                {!isMobile ? (
                  <img src={coinfallimg1} alt="coins" />
                ) : (
                  <img src={fallongmob1} alt="coins" />
                )}
              </Box>
              <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                {!isMobile ? (
                  <img src={coinfallimg2} alt="coins" />
                ) : (
                  <img src={fallingmob2} alt="coins" />
                )}
              </Box>
            </>
            <>
              <Button
                sx={{
                  fontSize: { xs: "20px", md: "30px" },
                  color: "#fff",
                  position: "absolute",
                  fontWeight: 400,
                  right: 0,
                  padding: 0,
                  top: 17,
                  lineHeight: "normal",
                }}
                onClick={handleClose}
              >
                X
              </Button>
            </>

            <Box
              sx={{ margin: "auto", textAlign: "center", marginTop: "17px" }}
            >
              <Typography
                sx={{
                  fontFamily: "outfit",
                  fontSize: { xs: "18px", md: "24px" },
                  fontWeight: "400",
                  color: "white",
                  marginBottom: "10px",
                }}
              >
                Invest Now
              </Typography>
              {!isMobile ? (
                <img src={holdingmoney} alt="holding money" />
              ) : (
                <img src={holdingmoneymob} alt="holding money" />
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: { xs: "24px", md: "10px" },
            }}
          >
            <TextField
              type="text"
              name="amount"
              placeholder="Enter Amount to invest"
              required
              inputProps={{ style: { color: "#fff" } }}
              sx={{
                border: "1px solid #777",
                borderRadius: "14px",
                color: "#828282",

                width: {
                  xs: "100%",
                  md: "480.8px",
                },
                marginTop: "14px",
                "@media (max-width: 400px)": {
                  margin: {
                    xs: "14px 20px",
                  },
                },
                background: "transparent",
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: "none",
                  },
              }}
              onChange={(e) => {
                setAmount(Number(e.target.value));
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: { xs: "10px", md: "20px" },
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: "transparent",
                textTransform: "capitalize",
                borderRadius: 3,
                border: "0.977px solid white",
                padding: "10px 20px",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
              onClick={handleInvestNow}
            >
              Invest Now
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default InvestFarm;
