import { Typography, Button } from "@mui/material";
import { Stack, Box } from "@mui/system";
import React, { useState } from "react";
import insifficentimg from "../../../assets/svgs/inSufficientFunds.svg"
import { useNavigate } from "react-router-dom";

function InSufficient({handleSufficentClose}:{handleSufficentClose:() => void}) {

  const navigate =  useNavigate()
  const [showComponent, setShowComponent] = useState(true);

  const handleCloseButtonClick = () => {
    setShowComponent(false);
    handleSufficentClose()
    navigate("/nft")
  };

  const handleGoback = () =>{
    navigate("/nft")
  }

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "15px",
      }}
    >
      {showComponent && (
        <Box
          sx={{
            width: " 555px",
            // height: "135px",
            borderRadius: "24px",

            background:
              " linear-gradient(267deg, rgba(255, 153, 13, 0.40) -3.12%, rgba(157, 0, 255, 0.40) 93.15%)",

            backdropFilter: "blur(2px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "13px", md: "22px" },
                  fontWeight: "500",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                  color:"white"
                }}
              >
                InSufficient Funds
              </Typography>
            </Box>

            <>
              {/* {isVisible && ( */}
              <Button
                sx={{
                  position: " absolute",
                  right: { xs: "5px", md: "8px" },
                  fontSize: { xs: "12px", md: "20px" },
                  color: "#fff",
                }}
                onClick={handleCloseButtonClick}
              >
                X
              </Button>
            </>
          </Box>

          <Box
            sx={{
              borderRadius: "24px",

              background: "  var(--272727, #272727)",
              backdropFilter: "blur(2px)",

              padding: { xs: "10px", md: "30px" },
            }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "13px", md: "24px" },
                  fontWeight: "400",
                  color:"white"
                }}
              >
                Sorry you dont have sufficient funds to buy this item
           
              </Typography>
            </Box>
            <Box sx={{justifyContent:"center",display:"flex"}}>
              {" "}
              <img src={insifficentimg} draggable="false" width="216px" height="216px" alt="" />
            </Box>
           
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: 3,
                  paddingLeft: 8,
                  paddingRight: 8,
                  width: { xs: "auto", md: "400px" },
                  mt: "50px",
                  background:
                    "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  fontSize: {
                    xs: 10,
                    sm: 7,
                    md: 10,
                    lg: 18,
                  },
                }}
                onClick={() =>handleGoback()}
              >
                Go Back
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Stack>
  );
}

export default InSufficient;
