
import axios from "axios"
import { Environment } from "../environments";

const genrateImageUrl = async (fileImage, additionalData) => {
  console.log("ander123", fileImage);
  const formData = new FormData();
  formData.append('image', fileImage);
  formData.append('folderName', additionalData);
  try {
    const response =  await axios.post(`${Environment.metavoeuxBackend}upload`,formData);
    console.log("response",response)
    return response?.data.url
  } catch (error) {
    console.error('Error uploading file:', error);
  }
}


export default async function handleUpload(pathlocation, file) {
  if (file) {
    try {
      const imageUrl = await genrateImageUrl(file, pathlocation);
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }
}
