// mui card
import React, { useState } from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
  Grid,
} from "@mui/material";
import nftServiceIcon from "../../assets/svgs/nftServiceIcon.svg";
import voeuxMoney from "../../assets/svgs/voeuxMoneyServiceIcon.svg";
import blockchainGameIcon from "../../assets/svgs/blockchainGameServiceIcon.svg";
import cryptoicon from "../../assets/svgs/cryptoimage.svg";
import voeuxPayIcon from "../../assets/svgs/voeuxPayServiceIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import voeuxtoken from "../../assets/svgs/voeuxtoken.svg";

const cardData = [
  {
    id: 1,
    image: nftServiceIcon,
    title: "NFT Marketplace",
    caption: "Create, buy and sell NFTs on a fully decentralized platform.",
    linkType: "internal",
    link: "/nft",
  },
  {
    id: 2,
    image: cryptoicon,
    title: "Voeux Farming",
    caption: "Earn While You Hold Explore Our Crypto Staking Farm.",
    linkType: "internal",
    link: "/voeuxfarming",
  },
  {
    id: 3,
    image: blockchainGameIcon,
    title: "Voeux Game",
    caption: "Powered games offer an exciting new way to play and earn.",
    linkType: "internal",
    link: "/voeuxGame",
  },
  {
    id: 4,
    image: voeuxPayIcon,
    title: "Voeux Pay",
    caption: "Your gateway to seamless and secure transactions.",
    linkType: "internal",
    link: "/voeuxpay",
  },
  {
    id: 5,
    image: voeuxtoken,
    title: "Voeux Token",
    caption: "Unlock the metavoeux ecosystem with voeux token.",
    linkType: "internal",
    link: "/voeuxtoken",
  },
  {
    id: 6,
    image: voeuxMoney,
    title: "Voeux Money",
    caption: "Your activities, your earnings, your choice.",
    linkType: "internal",
    link: "/voeuxmoney",
  },
  // {
  //   id: 7,
  //   image: luckydraw,
  //   title: "Voeux Lucky Draw",
  //   caption: "Dive into the Voeux Lucky Draw, unlock your luck.",
  //   linkType: "external",
  //   link: "https://uat.voeuxluckydraw.com/",
  // },
  // {
  //   id: 8,
  //   image: blockchainRealStateIcon,
  //   title: "Blockchain Real Estate",
  //   caption:
  //     "Real Estate solutions, transforming properties, securing futures.",
  //   linkType: "internal",
  //   link: "",
  // },
];

const Muicard = () => {
  const location = useLocation();
  const [hoverElement, setHoverElement] = useState({ show: false, title: "" });

  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/loadmore");
  };

  const circleStyleBlock: React.CSSProperties = {
    position: "absolute",
    flexShrink: 0,
    borderRadius: "367.166px",
    background: "var(--ff-990-d, #FF990D)",
    filter: "blur(140px)",
    backdropFilter: "blur(140px)",
    WebkitBackdropFilter: "blur(140px)",
    zIndex: -1,
  };

  const borderGradientBox = {
    background:
      "linear-gradient(#272727 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
    border: "2px solid transparent",
    borderRadius: "16px",
    cursor: "pointer",
    backgroundColor: "#272727",
  };

  const handleHover = (data: {
    id?: number;
    image?: string;
    title: any;
    caption?: string;
  }) => {
    const cardsToShowComingSoon = [
      // "Voeux Money",
      "Voeux Lucky Draw",
      "Blockchain Real Estate",
      "Metaverse",
      "Blockchain Games",
    ];

    if (cardsToShowComingSoon.includes(data.title)) {
      setHoverElement({ show: true, title: data.title });
    } else {
      setHoverElement({ show: false, title: "" });
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  const numberOfCardsToShow = isMobile ? 6 : cardData.length;

  // const handleButtonClick = () => {
  //   navigate("/loadmore");
  // };

  return (
    <>
      <Grid
        container
        spacing={{ xs: 1, md: 3, overflow: "hidden", position: "relative" }}
      >
        <Grid item xs={12} sx={{ position: "relative", fontFamily: "outfit" }}>
          <Typography
            variant="h4"
            sx={{
              margin: { xs: 0 },
              fontWeight: "500",

              // typography: { xs: "h5", sm: "h4" },
              textAlign: { xs: "center", md: "center" },
              mt: { xs: 2, sm: 0 },
              mb: { xs: 1.5, md: 0 },
              fontSize: { xs: "23px", lg: "32px" },
              fontFamily: "Outfit",
            }}
          >
            All your needs in one place
          </Typography>
        </Grid>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 90%)",
              sm: "repeat(2, 350px)",
              md: "repeat(2, 400px)",
              lg: "repeat(3, 400px)",
              xl: "repeat(3, 440px)",
            },
            gridGap: { xs: "14px", md: "20px" },
            justifyContent: "center",
            width: "100%",
            marginTop: { xs: "10px", md: "40px" },
          }}
        >
          {cardData.slice(0, numberOfCardsToShow).map((card) => (
            <Grid
              item
              key={card.id}
              // xs={6}
              // sm={6}
              // md={4}
              // lg={3}
              onClick={() => {
                if (card.linkType === "internal") {
                  navigate(card.link);
                } else if (card.linkType == "external") {
                  window.location.href = card.link;
                }
              }}
              onMouseOver={() => {
                handleHover(card);
              }}
              onMouseEnter={() => {
                handleHover(card);
              }}
              onMouseOut={() => {
                setHoverElement({ show: false, title: "" });
              }}
            >
              <Card
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "column" },
                  ":hover": {
                    ...borderGradientBox,
                  },
                  height: {
                    xs: "auto",
                    md: 200,
                    lg: 240,
                  },
                  color: "#fff",
                  borderRadius: "15.558px",

                  backgroundColor: "#272727",
                  //
                }}
              >
                {hoverElement.show && hoverElement.title === card.title ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        background:
                          "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "28px", fontFamily: "outfit" }}
                      >
                        Coming Soon
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        height: "20px",
                        width: "20px",
                        margin: { xs: "1rem" },
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "24px !important",
                        padding: { xs: 2, md: 3 },
                      }}
                      style={{
                        backgroundColor: "orange",
                      }}
                    >
                      <CardMedia
                        component="img"
                        style={{
                          width: isMobile ? "20px" : "30px",
                          height: isMobile ? "20px" : "30px",
                        }}
                        src={card.image}
                        alt={card.title}
                      />
                    </Box>
                    <CardContent
                      sx={{ px: { xs: 0, sm: 3 }, paddingBottom: 0 }}
                    >
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          p: "auto",
                          display: "flex",
                          justifyContent: {
                            xs: "center",
                            md: "flex-start",
                          },
                          alignItems: "center",
                          color: "white",
                          fontSize: {
                            xs: 16,
                            md: 16,
                            lg: 24,
                          },
                          fontWeight: "700",
                          ml: { md: 0.5 },
                          mt: { xs: 1 },
                          fontFamily: "outfit",
                        }}
                      >
                        {card.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: 12,
                            md: 18,
                          },
                          fontWeight: "300",
                          ml: { md: 0.5 },
                          mt: { md: 1 },
                          display: { xs: "none", md: "-webkit-box" },
                          WebkitLineClamp: 3,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontFamily: "outfit",
                        }}
                      >
                        {card.caption}
                      </Typography>
                    </CardContent>
                  </>
                )}
              </Card>
            </Grid>
          ))}
        </Box>
        <Box
          sx={{
            ...circleStyleBlock,

            // left: { md: "35%" },

            width: "340.625px",
            height: "687.734px",
            borderRadius: "200.625px",
            background: "#9D00FF",
            filter: "blur(180px)",
            right: "0px",
            backdropFilter: "blur(180px)",
            WebkitBackdropFilter: "blur(180px)",
          }}
        ></Box>
      </Grid>

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "center",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Box>
          <div
            className={styles.borderGradient}
            onClick={() => {
              navigate("/loadmore");
            }}
          >
            <div className={styles.con_wall}>
              <Box
                sx={{
                  width: "120px",
                  height: "30px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleLearnMoreClick}
              >
                <p
                  style={{
                    whiteSpace: "nowrap",
                    margin: 0,
                    color: "white",
                  }}
                >
                  Load more
                </p>
                <ArrowForwardIosIcon
                  style={{
                    fontSize: "small",
                    color: "white",
                    marginTop: 2,
                    marginLeft: 4,
                  }}
                />
              </Box>
            </div>
          </div>
        </Box>
      </Box> */}
    </>
  );
};

export default Muicard;
