import { JsonRpcProvider, Contract, ethers, ContractRunner, JsonRpcSigner } from "ethers";
export class ContractInstance {
  provider: JsonRpcProvider;
  constructor() {
    const apiKey = "922d112f79c14c4c834c1d804cbc8df6";
    const app_URI = "https://polygon-mumbai.infura.io/v3";
    const endPointURL = `${app_URI}/${apiKey}`;
    this.provider = new JsonRpcProvider(endPointURL);
  }

  buildContractInstance = async (
    CONTRACT_ADDRESS: string,
    CONTRACT_ABI: any[]
  ): Promise<Contract> => {
    try {
      let signer: JsonRpcSigner;
      if (window.ethereum) {
        const providr = new ethers.BrowserProvider(window.ethereum);
        await providr.send("eth_requestAccounts", []);
        signer = await providr.getSigner();
      }else{
        // alert("Connect metamask"); 
        signer = new JsonRpcSigner(this.provider,"0x2C280905F711722894d9748773E80F31fF2b8Be2");
      }
      return new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer) as Contract;
     
    } catch (err) {
      console.error("Contract instance", err);
      throw err;
    }
  };
}
