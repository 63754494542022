import { useEffect } from "react";
import Slider from "react-slick";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { alpha } from "@mui/material";
const ProfileIcon =
  "https://d24m5mu8cxqwfm.cloudfront.net/images/profile_icon_nft.svg";
function SimpleSlider({
  data,
  category,
  heading,
  isCollection = false,
  collectionCardClick = () => {},
  handleViewAllAction,
  ismoreLikeCollection = false,
}: {
  data: any;
  category?: any;
  heading?: any;
  isCollection?: any;
  collectionCardClick?: (id: any, name?: any) => void;
  handleViewAllAction?: () => void;
  ismoreLikeCollection?: any;
}) {
  const navigate = useNavigate();
  console.log("simple slider category", category, heading, data);

  const settings12 = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow: 1,
    speed: 500,
  };
  useEffect(() => {
    console.log("slider", collectionCardClick);
  }, []);
  const handleCardClick = (data: any) => {
    // console.log("data",data)
    if (isCollection) {
      collectionCardClick(data.collectionId, data.collectionName);
    } else if (ismoreLikeCollection) {
      collectionCardClick(data.nft._id);
    } else {
      navigate(`/nftDetail/${data._id}`);
    }
  };

  const handleViewAll = (caetgory: string) => {
    if (handleViewAllAction) {
      handleViewAllAction();
    } else {
      navigate("/categoryNft", { state: { category: caetgory } });
    }
  };

  return (
    <Box
      sx={{
        background: alpha("#D9D9D91F", 0.12),

        marginTop: "34px",
        borderRadius: "8px",
        paddingBottom: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: { xs: "1rem" },
        }}
      >
        <Typography
          textAlign="center"
          sx={{
            color: "white",
            fontSize: "1rem",
            fontWeight: 700,
            textTransform: "capitalize",
            fontFamily: "outfit",
            mb: 2,
          }}
        >
          {heading}
        </Typography>
        {!ismoreLikeCollection && (
          <Button
            variant="contained"
            onClick={() => handleViewAll(category)}
            sx={{
              textTransform: "capitalize",

              background: "none",
              boxShadow: "none",
              color: "#FF990D ",
              padding: "2px",
              height: "fit-content",
              fontSize: {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 18,
              },
              "&:hover": {
                color: "#FF990D ",
              },
            }}
          >
            View All
          </Button>
        )}
      </Box>
      <div className="slider-container NftCardsSlider">
        <Slider {...settings12}>
          {data.map((data: any) => {
            return (
              <div>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  spacing={2}
                  lg
                  sx={{ marginRight: "20px" }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      borderRadius: 5,
                      background: "#181818",
                      height: "100%",
                      width: { md: "300px" },
                      cursor: "pointer",
                    }}
                    onClick={() => handleCardClick(data)}
                  >
                    <Box
                      sx={{
                        margin: 0,
                        padding: 0,
                        // width:{lg:"230px",md:"100%"}
                      }}
                    >
                      <img
                        src={data.imageUrl ? data.imageUrl : data.nft.imageUrl}
                        alt="image"
                        height={"218px"}
                        width={"100%"}
                        style={{
                          borderRadius: "20px 20px 0px 0px",
                          objectFit: "cover",
                          objectPosition: "0 0",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                        // pt: 1,
                      }}
                    >
                      <Typography
                        textAlign="left"
                        sx={{
                          fontFamily: "outfit",
                          overflow: "hidden",
                          width: "100%",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {isCollection
                          ? data?.collectionName
                          : data.nftName
                          ? data.nftName
                          : data.nft.nftName}
                      </Typography>
                      {isCollection ? (
                        <Box
                          display="flex"
                          alignItems="baseline"
                          justifyContent="space-between"
                          width="100%"
                          sx={{ flexDirection: { xs: "column", md: "row" } }}
                        >
                          <Box
                            display="flex"
                            sx={{
                              mt: "20px",
                            }}
                            // flexDirection="column"
                          >
                            <Box display="flex">
                              <img
                                src={
                                  data?.profileImage
                                    ? data?.profileImage
                                    : ProfileIcon
                                }
                                alt=""
                                style={{
                                  marginRight: 10,
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                              <Box display="flex" flexDirection="column">
                                <Typography
                                  color="#E0E0E0"
                                  fontSize={12}
                                  sx={{ fontFamily: "outfit" }}
                                >
                                  Creator
                                </Typography>
                                <Typography sx={{ fontFamily: "outfit" }}>
                                  {data?.fullName
                                    ? data?.fullName
                                    : data?.walletAddress?.slice(0, 3) +
                                      "....." +
                                      data?.walletAddress?.slice(-3)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            gap={1}
                            mt={2}
                          >
                            <Box display="flex">
                              <img
                                src={
                                  data?.user?.profilePic
                                    ? data?.user?.profilePic
                                    : ProfileIcon
                                }
                                alt="img"
                                height={"100%"}
                                width={"100%"}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                }}
                              />
                            </Box>

                            <Typography sx={{ fontFamily: "outfit" }}>
                              {data?.user?.fullName
                                ? data?.user?.fullName
                                : data?.user?.walletAddress.slice(0, 6) +
                                  "...." +
                                  data?.user?.walletAddress.slice(-6)}

                              {/* {data.nft
                                ? data.nft?.currentOwner?.fullName
                                  ? data.nft?.currentOwner?.fullName
                                  : data.nft?.currentOwner?.walletAddress?.slice(
                                      0,
                                      3
                                    ) +
                                    "....." +
                                    data.nft?.currentOwner?.walletAddress?.slice(
                                      -3
                                    )
                                : data.fullName
                                ? data.fullName
                                : data?.currentOwner?.walletAddress?.slice(
                                    0,
                                    3
                                  ) +
                                  "....." +
                                  data?.currentOwner?.walletAddress?.slice(-3)} */}
                            </Typography>
                          </Box>
                          {!ismoreLikeCollection && (
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              mt={2}
                            >
                              <Typography
                                sx={{
                                  color: "#858584",
                                  fontSize: "0.8rem",
                                  fontWeight: 400,
                                  fontFamily: "outfit",
                                }}
                              >
                                Price
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#FFF",
                                  fontSize: "0.8rem",
                                  fontWeight: 400,
                                  fontFamily: "outfit",
                                }}
                              >
                                {data?.listings?.listingPrice
                                  ? Number(
                                      data?.listings?.listingPrice
                                    ).toFixed(4)
                                  : Number(data?.listingPrice).toFixed(4)}{" "}
                                Voeux
                              </Typography>
                            </Box>
                          )}
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            mt={1}
                          >
                            <Typography
                              sx={{
                                color: "#858584",
                                fontSize: "0.8rem",
                                fontWeight: 400,
                                fontFamily: "outfit",
                              }}
                            >
                              Supply
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "0.8rem",
                                fontWeight: 400,
                                fontFamily: "outfit",
                              }}
                            >
                              {data?.supply ? data?.supply : data?.nft?.supply}
                              {/* {data.supply} */}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Grid>
              </div>
            );
          })}
        </Slider>
      </div>
    </Box>
  );
}

export default SimpleSlider;
