import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";


import GameImage from "../../assets/VoeuxGame/CarBig 1.png";
import one from "../../assets/VoeuxGame/CarSmall 1.png";
import two from "../../assets/VoeuxGame/CarSmall 2.png";
import three from "../../assets/VoeuxGame/CarSmall 3.png";
import four from "../../assets/VoeuxGame/CarSmall 4.png";
import bigone from "../../assets/VoeuxGame/CarBig 1.png";
import bigtwo from "../../assets/VoeuxGame/CarBig 2.png";
import bigthree from "../../assets/VoeuxGame/CarBig 3.png";
import bigfour from "../../assets/VoeuxGame/CarBig 4.png";
import SigninGame from "./SigninGame";
import { useNavigate } from "react-router-dom";

const ResponsiveBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
  },
}));

const CarRacing = () => {
  const gameImages = [
    { Image: one },
    { Image: two },
    { Image: three },
    { Image: four },
  ];
  const bigImages = [
    { Bigimg: bigone },
    { Bigimg: bigtwo },
    { Bigimg: bigthree },
    { Bigimg: bigfour },
  ];

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleImageSelect = (index: any) => {
    setSelectedImage(bigImages[index].Bigimg);
    setSelectedIndex(index);
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 3) {
        setCount(0);
      } else {
        setCount((prevCount) => prevCount + 1);
        setSelectedImage(bigImages[count].Bigimg);
        setSelectedIndex(count);
      }
    }, 6000);
    return () => clearInterval(interval);
  });

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    console.log("timeout", counter);
    let timeout: any;
    if (isMobile) {
      if (counter >= 3) {
        setCounter(-1);
        handleImageSelect(0);
      } else {
        timeout = setTimeout(() => {
          setCounter(counter + 1);
          handleImageSelect(counter + 1);
        }, 10000);
      }

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [counter]);

  const isMobile = useMediaQuery("(max-width:600px)");

  const [isSignInOpen, setIsSignInOpen] = useState(false);

  const navigate = useNavigate()
  const handleSignInOpen = () => {
    var email=localStorage.getItem("gameEmail")
    if(email){
      navigate("/gameprofile")
    }else{
      navigate("/user/signin")
    }
    
  };
  const handleSignInClose = () => {
    setIsSignInOpen(false);
  };

  const handleDownload = () =>{
    var gmail=localStorage.getItem("gameEmail")
    if(!gmail){
      navigate(`/user/signin`,{state:{type:"Download",url:"https://voeux-game.s3.ap-south-1.amazonaws.com/CarRace.7z"}});
    }else{
      const link = document.createElement('a'); 
      link.setAttribute('href', "https://voeux-game.s3.ap-south-1.amazonaws.com/CarRace.7z"); 
      document.body.appendChild(link); 
      link.click(); 
      document.body.removeChild(link); 
    }
}

  return (
    <>
      <ResponsiveBox sx={{ padding: "0 30px",
       marginBottom:{md:"130px",xs:"50px"},
       textAlign:{md:"left",xs:"center"}
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "45px",
            flexDirection: {
              md: "column",
              lg: "row",
              sm: "column",
              xs: "column",
            },
          }}
        >
          <Box sx={{ paddingLeft: { md: "0" } }}>
            <ResponsiveTypography
              className="refinary25"
              sx={{
                color: "#FFF",
                fontSize: {md:"99px",xs:"23px"},
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "116%",
                letterSpacing: "1.39px",
                textTransform: "uppercase",
              }}
            >
              crypto dash
            </ResponsiveTypography>

            {/* one */}
            <ResponsiveTypography
              className="blendarPro"
              sx={{
                color: "#FFF",
                fontSize: {md:"32px",xs:"18px"},
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "111%",
                letterSpacing: "1.39px",
                textTransform: "uppercase",
                marginTop:"10px"
              }}
            >
              {selectedIndex === 0 &&
                " Every win unlocks exciting rewards. Collect new cars with superior performance, and customize your ride with sleek visuals."}
              {selectedIndex === 1 &&
                "Climb leaderboards, and make your mark among the elite. Compete globally, showcasing your driving prowess against the best times."}
              {selectedIndex === 2 &&
                " Refine your racing expertise across various game modes. Challenge yourself with time trials, and dominate the competition in races."}
              {selectedIndex === 3 &&
                "  Enhance your car's performance with a customized upgrade system. Optimize the engine, suspension, and ride for every track."}
            </ResponsiveTypography>


            <Box sx={{display:"flex",columnGap:"20px",marginBottom:"30px",
              justifyContent:{md:"flex-start",xs:"center"}
            }}>

             
              <Box
                  className="polygonStyle2"
                  sx={{
                    border: `1px solid #D2C915`,
                    // padding: "2px",
                    marginTop: "20px",
                    // width: "max-content",
                    background: `#D2C915`,  display:"flex",justifyContent:"center",
                    width:{md:"170px",sm:"170px",xs:"146px"}
                  }}
                  onClick={() =>  handleDownload()}
                >
                
                  <Button
                    className="BlenderProBold"
                    sx={{
                      borderColor: "white",
                      padding: "10px 16px",
                      color: "#000",
                      "&:hover": {
                        background: `#fff`,
                      },
                      "@media (max-width: 360px)": {
                        //  marginBottom:"210px"
                        fontSize:"11px"
                      },
                     
                    }}

                  >
                    Download Now
                  </Button>
                </Box>
                <Box className="custom-button-shape "
                onClick={handleSignInOpen} 
                  sx={{
                   height:"max-content",
                    width: "max-content",
                    marginTop: "20px",
                    
                  }}>
                  <Button className="custom-button-shape sellPoint BlenderProBold"
                       onClick={handleSignInOpen}
                   sx={{
                      borderColor: "white",
                      padding: "12px 16px",
                      color: "#D2C915",
                      borderRadius:"0",
                      "&:hover": {
                        background: `#D2C915`,
                        color: "#000",
                      },
                      "@media (max-width: 360px)": {
                        //  marginBottom:"210px"
                        fontSize:"11px"
                      },
                    }}>
                  BUY / SELL VX POINTS
                  </Button>
                </Box>
               
                </Box>



            
          </Box>

          <Box>
            <Box
              // className="menu polygonStyle2"
              sx={{
                display: "flex",
                flexDirection: "column",
                // background:
                //   "linear-gradient(180deg, rgba(166, 160, 186, 0.15) 0%, rgba(0, 0, 0, 0.20) 100%)",
                padding: "2px",
                position: "relative",
                width: { xl: "733px", md: "100%" },
                filter: selectedIndex
                  ? "drop-shadow(white 1px 1px 0px)"
                  : "drop-shadow(white 0px 0px 0px)",
                // boxShadow:
                //   "0px -8.625px 2.156px 0px rgba(235, 235, 235, 0.10) inset, -2.156px 0px 2.156px 0px rgba(235, 235, 235, 0.10) inset, 2.156px 0px 2.156px 0px rgba(235, 235, 235, 0.10) inset",
                // backdropFilter: "blur(10.781231880187988px)",
              }}
            >
              <img
                draggable="false"
                src={selectedImage || GameImage}
                alt="Game"
                style={{
                  filter: selectedIndex
                    ? "drop-shadow(white -1px -1px 0px)"
                    : "drop-shadow(white 0px 0px 0px)",
                }}
              />
            </Box>

            <Box
              sx={{
                // display: "flex",
                justifyContent: "space-between",
                marginTop: "31px",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                rowGap: { xs: "15px" },
                display: { xs: "none", md: "flex" },
              }}
            >
              {gameImages.map((img, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "2px",
                    width: "168px",
                    position: "relative",
                    filter:
                      index === selectedIndex
                        ? "drop-shadow(white 2px 2px 0px)"
                        : "drop-shadow(white 0px 0px 0px)",
                    cursor: "pointer",
                  }}
                >
                  <img
                    onClick={() => handleImageSelect(index)}
                    draggable="false"
                    src={img.Image}
                    alt={`Game ${index + 1}`}
                    style={{
                      filter:
                        index === selectedIndex
                          ? "drop-shadow(white -2px -2px 0px)"
                          : "drop-shadow(white 0px 0px 0px)",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Dialog
                PaperProps={{
                  style: {
                    backgroundColor: "transparent", // Set the dialog background to transparent
                    boxShadow: "none", // Remove the default Material-UI box shadow
                  },
                }}
                open={isSignInOpen}
                onClose={handleSignInClose}
              >
                <DialogContent>
                  <SigninGame handleSignInClose={handleSignInClose} />
                </DialogContent>
              </Dialog>
           
      </ResponsiveBox>
    </>
  );
};

export default CarRacing;
