import { useEffect, useState } from "react";
import ProfileNftDesktop from "../../../components/profileNft/ProfileNftDesktop";
import ProfileNftMobile from "../../../components/profileNft/ProfileNftMobile";

const ProfileNft = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  return (
    <>
      <ProfileNftDesktop />{" "}
    </>
  );
};
export default ProfileNft;
