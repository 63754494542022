import React from "react";
import footerbackground from "../../assets/VoeuxGame/gamemobfooter.png";
import footerbackgroundlap from "../../assets/VoeuxGame/footerbackground.png";
import { Box, Stack } from "@mui/system";
import {
  Typography,
  useMediaQuery,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import mobilelogo from "../../assets/svgs/logomobile.svg";
import mobileqr from "../../assets/svgs/qrmobile.svg";
import instam from "../../assets/svgs/Insta m.svg";
import facebookm from "../../assets/svgs/facebook m.svg";
import youtubem from "../../assets/svgs/y m.svg";
import quoram from "../../assets/svgs/Q m.svg";
import twiiterm from "../../assets/svgs/twitter m.svg";
import discordm from "../../assets/svgs/discord m.svg";
import mediumm from "../../assets/svgs/medium m.svg";
import whtsppm from "../../assets/svgs/whatsapp m.svg";
import telegramm from "../../assets/svgs/telg.svg";
// laptop
import qrCodeNew from "../../assets/VoeuxGame/qr.png";
import logo from "../../assets/svgs/metavoeux_logo.svg";
import FacebookWhite from "../../assets/VoeuxGame/facebook.svg";
import TelegramWhite from "../../assets/VoeuxGame/Telegram.svg";
import InstagramWhite from "../../assets/VoeuxGame/insta.png";
import TwitterWhite from "../../assets/VoeuxGame/twitt.png";
import DiscordWhite from "../../assets/VoeuxGame/discord.svg";

function VoeuxFooter({scrollIntoGames}:{scrollIntoGames?:()=> void }) {
  const isMobile = useMediaQuery("(max-width:600px)");

  const navigate = useNavigate();

  // mobile
  const socialPlatforms = [
    { icon: instam, link: "https://instagram.com/metavoeux/" },
    { icon: facebookm, link: "https://www.facebook.com/MetaVoeuxEcosystem" },
    { icon: youtubem, link: "https://youtube.com/@metavoeux/videos" },
    { icon: telegramm, link: "https://t.me/metavoeux" },
    { icon: twiiterm, link: "https://twitter.com/MetaVoeux" },
    { icon: discordm, link: "https://discord.gg/YrC7npAQ53" },
    { icon: mediumm, link: "https://medium.com/@metavoeuxit" },
    {
      icon: whtsppm,
      link: "https://whatsapp.com/channel/0029Va48rBCInlqIjvTfTs0x",
    },
    { icon: quoram, link: "https://quora.com/profile/Metavoeux" },
  ];
  const handleDocumentationClick = () => {
    window.open("../../assets/pdf/MetavoeuxWhitePaper.pdf", "_blank");
  };

  // laptop

  const location = useLocation();

  const footerList = [
    {
      heading: "Company",
      items: ["About Us", "All Services"],
      clickable: true,
    },
    {
      heading: "Games",
      items: ["Chronicle", "Racing"],
      clickable: true,
    },
    {
      heading: "Resources",
      items: ["Blog", "Documentation", "FAQ"],
      clickable: true,
    },
    {
      heading: "Social Media",
      items: [
        {
          text:"facebook",
          icon:FacebookWhite,
          url:"https://www.facebook.com/MetaVoeuxEcosystem"
        },
        {
          text:"telegram",
          icon:TelegramWhite,
          url:"https://t.me/metavoeux"
        },
        {
          text:"instagram",
          icon:InstagramWhite,
          url:"https://instagram.com/metavoeux/"
        },
        {
          text:"twitter",
          icon:TwitterWhite,
          url:"https://twitter.com/MetaVoeux"
        },
        {
          text:"discord",
          icon:DiscordWhite,
          url:"https://discord.gg/YrC7npAQ53"
        }
      ],
    },
  ];

  const handleNavigate = (element: any) => {
    if (element === "About Us") {
      navigate("/about");
    } else if (element === "All Services") {
      navigate("/loadmore");
    } else if (element === "Chronicle") {
        if(location.pathname==="/voeuxGame"){
          scrollIntoGames && scrollIntoGames()
        }else{
          navigate("/voeuxGame",{state:{type:"game"}});
        }
      
    } else if (element === "Racing") {
      navigate("/voeuxGame",{state:{type:"game"}});
    } else if (element === "Blog") {
      navigate("/blog");
    } else if (element === "Documentation") {
      window.open(
        require("../../assets/pdf/MetavoeuxWhitePaper.pdf"),
        "_blank"
      );
    } else if (element === "FAQ") {
      navigate("/faq");
    }
  };

  const handleNavigateSocial = (url:any,text:any) =>{
    window.open(url, "_blank");
  }

  return (
    <>
      <>
        {isMobile && (
          <Box
            sx={{
              backgroundImage: `url(${footerbackground})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                "@media (min-width: 1721px)": {
                  paddingX: "10%",
                },
              }}
              padding={{
                xs: 2,
                md: 7,
              }}
            >
              <Box>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 5, md: 20 }}
                  sx={{
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                >
                  <Stack direction="column" spacing={2}>
                    <Box
                      sx={{
                        width: {
                          xs: "50%",
                        },
                        height: {
                          xs: "50%",
                        },
                      }}
                    >
                      <img
                        draggable="false"
                        src={mobilelogo}
                        alt=""
                        width={"100%"}
                      />
                    </Box>
                    <Stack direction="row" spacing={1}>
                      <Box>
                        <img
                          draggable="false"
                          src={mobileqr}
                          width="88px"
                          height="88px"
                          alt=""
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>

                <Box>
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontFamily: " Anurati",
                      fontWeight: "400px",
                      padding: "0px !important",
                    }}
                  >
                    Community
                  </Typography>
                  <Stack direction="row" spacing={1.3}>
                    {socialPlatforms.map((platform, index) => (
                      <Box
                        sx={{
                          "@media (max-width: 400px)": {
                            width: "20px",
                          },
                          "@media (max-width: 270px)": {
                            width: "11px",
                          },
                        }}
                      >
                        <a
                          key={index}
                          href={platform.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{ width: "-webkit-fill-available" }}
                            draggable="false"
                            src={platform.icon}
                            alt={`icon-${index}`}
                          />
                        </a>
                      </Box>
                    ))}
                  </Stack>
                </Box>

                <div className="customAccordion">
                  <Accordion
                    style={{
                      backgroundColor: "transparent",
                      color: "#fff",

                      boxShadow: "none !important",
                    }}
                  >
                    <AccordionSummary
                      className="customaccordianFont"
                      sx={{
                        fontSize: "18px",
                        fontFamily: "Anurati !important",
                        fontWeight: "400px",
                        padding: "0px !important",
                      }}
                      expandIcon={
                        <KeyboardArrowDownIcon style={{ color: "#fff" }} />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Company
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        onClick={() => {
                          navigate("/about");
                        }}
                        sx={{
                          fontSize: "15px",
                          fontFamily: "outfit",
                          marginBottom: "10px",
                        }}
                      >
                        About Us
                      </Typography>

                      <Typography
                        onClick={() => {
                          navigate("/loadmore");
                        }}
                        sx={{
                          fontSize: "15px",
                          fontFamily: "outfit",
                        }}
                      >
                        All Services
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    style={{
                      backgroundColor: "transparent",
                      color: "#fff",
                      boxShadow: "none !important",
                    }}
                  >
                    <AccordionSummary
                      className="customaccordianFont"
                      sx={{
                        fontSize: "18px",
                        fontFamily: " Anurati !important",
                        fontWeight: "400px",
                        padding: "0px !important",
                      }}
                      expandIcon={
                        <KeyboardArrowDownIcon style={{ color: "#fff" }} />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Games
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        onClick={() => {
                          navigate("/");
                        }}
                        sx={{
                          fontSize: "15px",
                          fontFamily: "outfit",
                          marginBottom: "10px",
                        }}
                      >
                        Chronicle
                      </Typography>

                      <Typography
                        onClick={() => {
                          navigate("/");
                        }}
                        sx={{
                          fontSize: "15px",
                          fontFamily: "outfit",
                        }}
                      >
                        Racing
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    style={{
                      backgroundColor: "transparent",
                      color: "#fff ",
                      boxShadow: "none !important",
                    }}
                  >
                    <AccordionSummary
                      className="customaccordianFont"
                      sx={{
                        fontSize: "18px",
                        fontFamily: " Anurati !important",
                        fontWeight: "400px",
                        padding: "0px !important",
                      }}
                      expandIcon={
                        <KeyboardArrowDownIcon style={{ color: "#fff" }} />
                      }
                      aria-controls="panel4-content"
                      id="panel4-header"
                    >
                      Resources
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        onClick={() => {
                          navigate("/blog ");
                        }}
                        sx={{
                          fontSize: "15px",
                          fontFamily: "outfit",
                          marginBottom: "10px",
                        }}
                      >
                        Blog
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "outfit",
                          marginBottom: "10px",
                        }}
                        onClick={handleDocumentationClick}
                      >
                        Documentation
                      </Typography>
                      <Typography
                        onClick={() => {
                          navigate("/faq");
                        }}
                        sx={{ fontSize: "15px", fontFamily: "outfit" }}
                      >
                        FAQ
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <Box
                  sx={{
                    borderBottom: "1px solid #fff",
                    width: "auto",
                    margin: "0 auto",
                    marginTop: "40px",
                    opacity: "0.20",
                  }}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  mt={3}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontFamily: "outfit",
                      color: "#CCCCCC",
                      textAlign: isMobile ? "center" : "initial",
                    }}
                  >
                    Voeux Information Technology LLC © 2023 - 2024. All Rights
                    Reserved
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
        )}
      </>
      <>
        {!isMobile && (
          <Box
            sx={{
              backgroundImage: `url(${footerbackgroundlap})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "100%",
              height: "auto",
            }}
          >
            <Box
              sx={{
                "@media (min-width: 1721px)": {
                  paddingX: "10%",
                },
              }}
              padding={{
                xs: 2,
                md: 7,
              }}
            >
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 5, md: 20 }}
                sx={{
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Stack direction="column" spacing={1}>
                  <Box
                    sx={{
                      cursor:"pointer",
                      width: {
                        xs: "75%",
                        md: "100%",
                      },
                      height: {
                        xs: "80%",
                        md: "100%",
                      },
                    }}
                    onClick={() =>   navigate("/")}
                  >
                    <img
                      draggable="false"
                      src={logo}
                      alt="logo
              "
                      width={"100%"}
                    />
                  </Box>
                  <Stack direction="row" spacing={1}>
                    <Box>
                      <img
                        draggable="false"
                        src={qrCodeNew}
                        width="88px"
                        height="88px"
                        alt="qr
                  "
                      />
                    </Box>
                  </Stack>
                  <Typography
                    fontSize={"15px"}
                    sx={{
                      fontFamily: "outfit",
                      width: "281px",
                      color: "#CCCCCC",
                    }}
                  >
                    Scan to view MetaVoeux Ecosystem
                  </Typography>
                </Stack>
                <Box
                  display={{
                    xs: "Column",
                    md: "row",
                  }}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    width: "60%",
                  }}
                >
                  <Grid container rowGap={1} max-width="100vw">
                    {footerList.map((footerItem) => (
                      <Grid item xs={6} sm={6} lg={3} key={footerItem.heading}>
                        <Typography
                          sx={{
                            color: "#FFF",
                            fontSize: { xs: "16px", md: "21.96px" },
                            fontWeight: 400,
                            mb: 2,
                            fontFamily: " Anurati",
                          }}
                        >
                          {footerItem.heading}
                        </Typography>
                        <Grid container rowGap={1} columnGap={0.1}>
                          {footerItem.items.map((element:any, index) =>
                            footerItem.heading === "Social Media" ? (
                              <> 
                                <a onClick={() => handleNavigateSocial(element.url,element.text)} >
                                  <img
                                    draggable="false"
                                    alt="socialmediaicon"
                                    src={element.icon}
                                  />
                                </a>
                              </>
                            ) : (
                              <>
                                {" "}
                                <Grid
                                  item
                                  xs={12}
                                  key={index}
                                  sx={{
                                    ...(footerItem.clickable === true && {
                                      cursor: `pointer`,
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                      fontSize: " 16px;",
                                      color: "#CCCCCC",
                                    }),
                                  }}
                                  color={"red"}
                                  fontFamily={"outfit"}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontFamily: "outfit",
                                    }}
                                    onClick={() => {
                                      handleNavigate(element);
                                    }}
                                  >
                                    {element}
                                  </Typography>
                                </Grid>
                              </>
                            )
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Stack>
              <Box
                sx={{
                  borderBottom: "1px solid #fff",
                  width: "auto",
                  margin: "0 auto",
                  marginTop: "40px",
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                mt={3}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    color: "#CCCCCC",
                    textAlign: isMobile ? "center" : "initial",
                    fontWeight: "400px",
                  }}
                >
                  Voeux Information Technology LLC © 2023 - 2024. All Rights
                  Reserved
                </Typography>
              </Stack>
            </Box>
          </Box>
        )}
      </>
    </>
  );
}

export default VoeuxFooter;
