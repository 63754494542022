import { Marketplace } from "src/contracts/Marketplace";
import {
  MARKETPLACE_CONTRACT_ADDRESS,
  MARKETPLACE_CONTRACT_ABI,
} from "../constants/ABIs/marketPlaceABI";
import { Ask } from "src/domains/ask.dto";
import { ERC1155Token } from "src/contracts/ERC1155Token";
import { ERC1155TOKEN_ABI } from "src/constants/ABIs/ERC1155TokenABI";

export class MarketplaceService {
  marketplaceInstance: Marketplace;

  constructor() {
    this.marketplaceInstance = new Marketplace(
      MARKETPLACE_CONTRACT_ADDRESS,
      MARKETPLACE_CONTRACT_ABI
    );
  }

  listNft = async (
    nfts: string[],
    tokenIds: number[],
    prices: number[]
  ): Promise<object> => {
    try {
      console.log("in list nft service ==>", nfts, prices, tokenIds);

      // await this.giveMarketPlacePermission(nfts[0]);
      const NFTInstance = await this.getNFTInstance(nfts[0]);
      const intervalId = setInterval(async () => {
        console.log("inside set interval");
        if (NFTInstance.contractInstance) {
          this.givePermission(NFTInstance);
          clearInterval(intervalId);
        }
      }, 500);
      return await this.marketplaceInstance?.createAsk(nfts, tokenIds, prices);
    } catch (e) {
      throw e;
    }
  };

  private getNFTInstance = async (nftAddress: string): Promise<any> => {
    try {
      const NFTInstance = new ERC1155Token(nftAddress, ERC1155TOKEN_ABI);
      return NFTInstance;
    } catch (err) {
      console.log("error while creating nft instance", err);
      throw err;
    }
  };

  // private giveMarketPlacePermission = async(nftAddress:string):Promise<void> => {
  //         try{

  //         }catch(err){
  //                 throw err;
  //         }

  // }

  private givePermission = async (nftInstance: ERC1155Token) => {
    await nftInstance.grantTransferPermission();
  };

  buyNft = async (
    nfts: string[],
    tokenIds: number[],
    price: number
  ): Promise<object> => {
    try {

      const response = await this.marketplaceInstance?.acceptAsk(
        nfts,
        tokenIds,
        BigInt(price)
      );
      console.log("nft bought response:", response);
      return response;
    } catch (err) {
      throw err;
    }
  };

  makeOffer = async (
    nfts: string[],
    tokenIds: number[],
    prices: number[]
  ): Promise<object> => {
    try {
      console.log(
        "in market place service makeOffer",
        nfts[0],
        tokenIds[0],
        prices[0]
      );
      return await this.marketplaceInstance?.createBid(nfts, tokenIds, prices);
    } catch (err) {
      throw err;
    }
  };

  cancelNftSale = async (
    nfts: string[],
    tokenIds: number[]
  ): Promise<object> => {
    try {
      const NFTInstance = await this.getNFTInstance(nfts[0]);

      const intervalId = setInterval(async () => {
        if (NFTInstance.contractInstance) {
          this.revokePermission(NFTInstance);
          clearInterval(intervalId);
        }
      }, 500);
      return await this.marketplaceInstance?.cancelAsk(nfts, tokenIds);
    } catch (err) {
      throw err;
    }
  };

  revokePermission = async (NFTInstance: ERC1155Token) => {
    await NFTInstance?.revokeTransferPermission();
  };
  cancelOffer = async (nfts: string[], tokenIds: number[]): Promise<object> => {
    try {
      return await this.marketplaceInstance?.cancelBid(nfts, tokenIds);
    } catch (err) {
      throw err;
    }
  };

  isNftListed = async (nft: string, tokenId: number): Promise<Ask> => {
    try {
      return await this.marketplaceInstance?.getAsk(nft, tokenId);
    } catch (err) {
      throw err;
    }
  };

  acceptOffer = async (nft: string[], tokenId: number[]) => {
    try {
      return await this.marketplaceInstance?.acceptBid(nft, tokenId);
    } catch (err) {
      throw err;
    }
  };

  getOffer = async (nft: string, tokenId: number)=>{
    try{
      return await this.marketplaceInstance?.getBid(nft, tokenId);
    }catch(err){
      throw err;
    }
  }
}
