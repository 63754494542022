
import { registerFarmAbi, registerFarmAddress } from "../constants/ABIs/registerFarmABI";
import { RegisterFarm } from "../contracts/RegisterFarm";
import { AdvanceFarmDetails } from "../domains/advanceFarmDetails.dto";
import { BasicFarmDetails } from "../domains/basicFarmDetails.dto";
import { Phase, registerFarmData } from "../domains/registerFarm.dto";
import { UpdateFarm, UpdateFarmResponse } from "../domains/updateFarm.dto";

export class RegisterFarmService{
    registerFarmInstance: RegisterFarm;
    constructor(){
        this.registerFarmInstance =  new RegisterFarm(registerFarmAddress, registerFarmAbi);
    }

    registerFarm = async(registerFarmData:registerFarmData):Promise<Object>=>{
        try{
            console.log("in registerFarm Service -==>", this.registerFarmInstance);
            return await this.registerFarmInstance.registerFarm(registerFarmData);
        }catch(err){
            console.error("RegisterFarm service", err);
            throw err
        }
        
    }
    getAdminAddress = async():Promise<string>=>{
        return await this.registerFarmInstance.getAdminAddress();
    }
    getLevels = async():Promise<number> => {
        try{
            
            const level =  await this.registerFarmInstance.getLevels();
            console.log("in the register service getLevels ==>", level);
            return level;
        }catch(err){
            console.error(err);
            throw err;
        }
        
    }

    getBasicDetails = async(farmId:number):Promise<BasicFarmDetails>=>{
        try{
            console.log("in the register service received farm id==>",farmId);
            return await this.registerFarmInstance.getBasicFarmDetails(farmId);
        }catch(err){
            console.error(err)
            throw err;
        }
       
    }

    getAdvanceDetails = async(farmId:number):Promise<AdvanceFarmDetails>=>{
        try{
            console.log("in the register service received farm id==>",farmId);
            return await this.registerFarmInstance.getAdvanceFarmDetails(farmId);
        }catch(err){
            console.error(err)
            throw err;
        }
       
    }

    getPhaseDetails = async(farmId:number, phaseId:number):Promise<Phase>=>{
        try{
            return await this.registerFarmInstance.getFarmPhaseDetails(farmId, phaseId);
        }catch(err){
            throw err;
        }
    }

    updateFarm = async(updateFarmData:UpdateFarm):Promise<UpdateFarmResponse>=>{
        try{
            if (this.registerFarmInstance) {
            console.log("inside service function", updateFarmData)
            
            return await this.registerFarmInstance?.updateFarm(updateFarmData);
            }else{
                throw new Error("register farm instance does not exists")
            }
        }catch(err){
            console.log("-----------------------------inside service function--------------------------------")
            throw err;
        }
    }
}