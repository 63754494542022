import { Contract } from "ethers";
import { ContractInstance } from "./contractInstance";
import { registerFarmData } from "../domains/registerFarm.dto";
import { Phase } from "../domains/registerFarm.dto";
import { BasicFarmDetails } from "../domains/basicFarmDetails.dto";
import { AdvanceFarmDetails } from "../domains/advanceFarmDetails.dto";
import { UpdateFarm, UpdateFarmResponse } from "../domains/updateFarm.dto";

export class RegisterFarm {
  contractInstance: Contract | null;

  constructor(
    REGISTERFARM_CONTRACT_ADDRESS: string,
    REGISTERFARM_CONTRACT_ABI: any[]
  ) {
    this.contractInstance = null;
    let contract = new ContractInstance();
    contract
      .buildContractInstance(
        REGISTERFARM_CONTRACT_ADDRESS,
        REGISTERFARM_CONTRACT_ABI
      )
      .then((contIns) => {
        this.contractInstance = contIns;
      });
  }

  registerFarm = async (
    registerFarmData: registerFarmData
  ): Promise<Object> => {
    try {
      console.info("in register farm function contract", this.contractInstance);
      console.log("Register Farm Data:", registerFarmData);
      registerFarmData.phases = registerFarmData.phases.map(
        (phase: Phase) => {
          return { ...phase, status: 1 };
        }
      );
      const registerFarmTxn = await this.contractInstance?.registerFarm(
        registerFarmData.name,
        registerFarmData.description,
        registerFarmData.investmentRequired,
        registerFarmData.tokenAddress,
        registerFarmData.phases,
        registerFarmData.manager,
        registerFarmData.apy
      );
      console.log("registerFarm Transaction ==>", registerFarmTxn);
      return registerFarmTxn;
    } catch (err) {
      console.error("RegisterFarm", err);
      throw err;
    }
  };

  getAdminAddress = async (): Promise<string> => {
    try{
      return await this.contractInstance?.getAdminAddress();
    }catch(err){
      console.error("GetAdminAddress",err)
      throw err;
    }
    
  };

  getLevels = async (): Promise<number> => {
    try {
      const level =
        await this.contractInstance?.getCountOfTotalFarmRegistered();
      console.log("in register contract getLevels==>", level);
      return level;
    } catch (err) {
      console.error("GetLevels",err);
      throw err;
    }
  };

  getBasicFarmDetails = async (farmId: number): Promise<BasicFarmDetails> => {
    try {
      return await this.contractInstance?.getFarmBasicDetails(farmId);
    } catch (err) {
      console.error("GetBasicFarmDetails",err);
      throw err;
    }
  };
  getAdvanceFarmDetails = async (
    farmId: number
  ): Promise<AdvanceFarmDetails> => {
    try {
      return await this.contractInstance?.getFarmAdvanceDetails(farmId);
    } catch (err) {
      console.error("GetAdvanceFarmDetails",err);
      throw err;
    }
  };

  checkTokenAllowedStatus = async (tokenAddress: string): Promise<number> => {
    try {
      return await this.contractInstance?.checkTokenAllowedStatus(tokenAddress);
    } catch (err) {
      console.error("CheckTokenAllowedStatus",err);
      throw err;
    }
  };

  getFarmPhaseDetails = async (
    farmId: number,
    phaseId: number
  ): Promise<Phase> => {
    try {
      return await this.contractInstance?.getFarmPhaseDetails(farmId, phaseId);
    } catch (err) {
      console.error("GetFarmPhaseDetails",err);
      throw err;
    }
  };

  checkTokenAllowed = async (tokenAddress: string): Promise<void> => {
    try {
      return await this.contractInstance?.tokenAllowed(tokenAddress);
    } catch (err) {
      console.error("CheckTokenAllowed",err);
      throw err;
    }
  };

  // updateFarm = async (
  //   updateFarmData: UpdateFarm
  // ): Promise<UpdateFarmResponse> => {
  //   try {
  //     console.log("in the contract function ==>");
  //     if (this.contractInstance && this.contractInstance.updateFarm) {

  //       return await this.contractInstance?.updateFarm(
  //         updateFarmData.farmId,
  //         updateFarmData.description,
  //         updateFarmData.investmentRequired,
  //         updateFarmData.phases,
  //         updateFarmData.apy
  //       );
  //     }else{
  //       throw new Error('Contract instance or updateFarm function is undefined.');
  //     }
  //   } catch (err) {
  //     console.error("UpdateFarm",err);
  //     throw err;
  //   }
  // };
  updateFarm = async (
    updateFarmData: UpdateFarm
  ): Promise<UpdateFarmResponse> => {
    try {
      console.log("Entering updateFarm function");
  
      if (!this.contractInstance) {
        console.error("Contract instance is undefined");
        throw new Error('Contract instance is undefined.');
      }
  
      if (typeof this.contractInstance.updateFarm !== 'function') {
        console.error("updateFarm method is not a function");
        throw new Error('updateFarm method is not a function.');
      }
      if (!this.contractInstance || !this.contractInstance.updateFarm) {
        console.error("Contract instance or updateFarm method is undefined.");
        throw new Error('Contract instance or updateFarm method is undefined.');
      }
  
      console.log("Calling contractInstance.updateFarm", updateFarmData.phases);
      
      const phases:any = updateFarmData.phases.map((phase:any)=>{

        if(phase.status==="Inactive"){
            phase.status = 0
        }else if(phase.status==="Active"){
          phase.status = 1
        }else if(phase.status==="Completed"){
          phase.status = 2
        }else if(phase.status==="Paused"){
          phase.status = 3
        }else if(phase.status==="Skipped"){
          phase.status = 4
        }else if(phase.status==="Terminated"){
          phase.status = 5
        }
        return phase;
    })

    // const statusMapping:Record<string, number> = {
    //   "Inactive": 0,
    //   "Active": 1,
    //   "Completed": 2,
    //   "Paused": 3,
    //   "Skipped": 4,
    //   "Terminated": 5
    // };
    
    // const phases = updateFarmData.phases.map((phase:any) => {
    //   if (statusMapping.hasOwnProperty(phase.status)) {
    //     phase.status = statusMapping[phase.status];
    //   } else {
    //     phase.status = -1; 
    //   }
    //   return phase;
    // });

    console.log("phases ===>",phases)
      
    const updatePromise = this.contractInstance.updateFarm(
      updateFarmData.farmId,
      updateFarmData.description,
      updateFarmData.investmentRequired,
      phases,
      updateFarmData.apy
    );

    if (!updatePromise || typeof updatePromise.then !== 'function') {
      console.error("updateFarm did not return a valid promise");
      throw new Error('updateFarm did not return a valid promise.');
    }

    const result = await updatePromise;

    console.log("updateFarm result ===>", result);
    console.log("Exiting updateFarm function");

    return result;
    } catch (err) {
      console.error("Error in updateFarm", err);
      throw err;
    }
  };
  

  updateFarmPhaseStatus = async (
    farmId: number,
    phaseId: number,
    status: number
  ): Promise<Phase[]> => {
    try {
      return await this.contractInstance?.updateFarmPhaseStatus(
        farmId,
        phaseId,
        status
      );
    } catch (err) {
      console.error("UpdateFarmPhaseStatus",err);
      throw err;
    }
  };

  updateFarmStatus = async (
    farmId: number,
    status: number
  ): Promise<number> => {
    try {
      return await this.contractInstance?.updateFarmStatus(farmId, status);
    } catch (err) {
      console.error("UpdateFarmStatus",err);
      throw err;
    }
  };
}
