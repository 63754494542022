import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email:""
}
const gameUserMail = createSlice({
    name:"gameUserMail",
    initialState,
    reducers: {
        setEmail: (state, action) => {
          state.email = action.payload;
          
        },
    }
})

export const { setEmail } = gameUserMail.actions;

export default gameUserMail.reducer;