import React from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import contactimg from "../../assets/images/mailro.jpeg";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";

function Contmui() {
  const [selectedOption, setSelectedOption] = React.useState("");

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Box
        p={4}
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          // "@media (max-width: 600px)": {
          //   padding: 2,
          // },
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
      <NewNftHeader />
      <Box
        // border={"1px solid white"}
        position={"absolute"}
        sx={{
          top: 200,
          bottom: 100,
          height: 0,
          width: 200,
          backgroundColor: alpha("#9D00FF", 0.4),
          // backdropFilter:"blur(200px)",
          borderTopRightRadius: 100,
          borderBottomRightRadius: 100,
          boxShadow: "0 0 100px 100px rgba(157, 0, 255, 0.35)",
        }}
      ></Box>
      <Box sx={{ px: { sm: 5, lg: "15%" }, pt: 3, pb: 5 }}>
        <Grid
          container
          sx={{
            borderRadius: "18px",
            opacity: 0.95,
            background: "#272727",
            backdropFilter: "blur(15.558157920837402px)",
            // margin: "auto",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              // px: 5,
              // padding: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: "white", // font color
            }}
          >
            <Box sx={{ py: 3, px: 3 }}>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  marginBottom: "15px",
                }}
              >
                Contact Us
              </Typography>
              <Typography
                sx={{ color: "white", fontSize: "16px", marginBottom: "15px" }}
              >
                You can reach us anytime&nbsp;
                <span style={{ color: "orange", marginLeft: "2px" }}>
                  via support@metavoeux.io
                </span>
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography>First Name</Typography>
                  <Box>
                    <TextField
                      type="text"
                      name="firstName"
                      fullWidth
                      placeholder="Enter your first name"
                      className="firstnameinput"
                      required
                      InputProps={{
                        style: {
                          color: "white",
                          border: "1px solid #797979",
                          borderRadius: "8px",
                          marginBottom: "1rem",
                          boxShadow: "none",
                        },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>Last Name</Typography>
                  <TextField
                    type="text"
                    name="lastName"
                    fullWidth
                    placeholder="Enter your last name"
                    className="firstnameinput"
                    required
                    InputProps={{
                      style: {
                        color: "white",
                        border: "1px solid #797979",
                        borderRadius: "8px",
                        boxShadow: "none",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Typography>Email</Typography>
              <TextField
                type="email"
                name="email"
                fullWidth
                placeholder="yourcompany@.com"
                className="emphn"
                required
                InputProps={{
                  style: {
                    border: "1px solid #797979",
                    borderRadius: "8px",
                    marginBottom: "1rem",
                    color: "white",
                    outline: "none", // Remove default clickable blue border
                  },
                }}
              />

              <Box>
                <Typography>Phone Number</Typography>
              </Box>

              <Box>
                <FormControl>
                  <Select
                    style={{
                      border: "1px solid #797979",
                      borderRadius: "8px",
                      marginBottom: "1rem",
                      color: "white",
                      outline: "none",
                    }}
                    labelId="country"
                    id="simpleselect"
                    value="option1" // Set the default value to "option1" (+971)
                    onChange={handleChange}
                  >
                    <MenuItem value="option1">+971</MenuItem>
                    <MenuItem value="option2">+990</MenuItem>
                    <MenuItem value="option3">+105</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  sx={{
                    width: { lg: "81%", xl: "81%" },
                    border: "1px solid #797979",
                    borderRadius: "8px",
                    color: "white",
                    marginLeft: "5px",
                    outline: "none",
                  }}
                ></TextField>
              </Box>

              <Box>
                <Typography>Message</Typography>
                <TextField
                  multiline
                  rows={4}
                  name="message"
                  fullWidth
                  placeholder="Leave us a message.."
                  required
                  InputProps={{
                    style: {
                      color: "white",
                      background: "transparent",
                      border: "1px solid #797979",
                      borderRadius: "8px",
                      marginBottom: "5px",
                      width: "100%",
                      outline: "none",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#9E9E9E",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  gap: "5px",
                  marginTop: 2,
                }}
              >
                <input type="checkbox" />
                You agree to our
                <a href="#" style={{ color: "orange", textDecoration: "none" }}>
                  privacy policy
                </a>
                &
                <a href="#" style={{ color: "orange", textDecoration: "none" }}>
                  Terms and Condition
                </a>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#272727",
                  border: "1px solid #FF990D",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "12px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "100%",
                  height: "50px",
                  margin: "15px",
                  "&:hover": {
                    backgroundColor: "#FF990D",
                  },
                }}
              >
                <p>Submit</p>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box paddingX={{ xs: 1, md: 0 }} height={"100%"}>
              <img
                src={contactimg}
                alt="mailimage"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "18px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        // border={"1px solid white"}
        position={"absolute"}
        sx={{
          // top:200,
          right: 0,
          bottom: 0,
          height: 0,
          width: 400,
          backgroundColor: alpha("#9D00FF", 0.4),
          borderTopRightRadius: 100,
          borderBottomRightRadius: 100,
          boxShadow: "0 0 100px 100px rgba(157, 0, 255, 0.35)",
          zIndex: -10,
        }}
      ></Box>
      <LandingPageFooter />
    </Box>
  );
}

export default Contmui;
