import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Stack } from "@mui/system";
import { Typography } from "@mui/material";

import paymenticon from "../../../assets/voeuxpay/paymenticon.png";
import paymentinteicon from "../../../assets/voeuxpay/payinteicon.png";
import multicurrencyicon from "../../../assets/voeuxpay/multicurrencyicon.png";
import custoicon from "../../../assets/voeuxpay/custoicon.png";
import onlinebankicon from "../../../assets/voeuxpay/onlinebankicon.png";
import qricon from "../../../assets/voeuxpay/qricon.png";
import cardicon from "../../../assets/voeuxpay/cardicon.png";
import walleticon from "../../../assets/voeuxpay/walleticon.png";
import onrampicon from "../../../assets/voeuxpay/onrampicon.png";
import offrampicon from "../../../assets/voeuxpay/offrampicon.png";
import { useNavigate } from "react-router-dom";

export default function MenuAccordion() {
  const navigate = useNavigate();
  return (
    <>
      <Box>
        <div className="customAccordion">
          <Accordion sx={{ border: "none" }}>
            <AccordionSummary
              sx={{ background: "#1A0E1C" }}
              expandIcon={
                <ExpandMoreIcon sx={{ color: "#fff", alignItems: "center" }} />
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "22px",
                  fontFamily: " Bebas Neue",
                  fontWeight: "400px",
                  color: "#fff",
                  marginLeft: "14px",
                }}
              >
                Services
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "#1A0E1C" }}>
              <Box sx={{ width: "auto" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box
                    sx={{
                      // flex: 1,
                      padding: "10px",
                    }}
                  >
                    <Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={paymenticon} alt="pay" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            PAYMENT LINK
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={paymentinteicon} alt="pay" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            PAYMENT INTEGRATION
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={multicurrencyicon} alt="pay" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            MULTI CURRENCY PROCESSING
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "11px",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={custoicon} alt="custoicon" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            CUSTOMIZED PAYMENT SOLUTION
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={walleticon} alt="" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            E- WALLET
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={cardicon} alt="" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            VOEUX CARD
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={onlinebankicon} alt="pay" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            ONLINE BANKING
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={qricon} alt="" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            QR BANK TRANSFER
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={onrampicon} alt="" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            ON RAMP
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",

                            ":hover": {
                              color: "orange",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img src={offrampicon} alt="" />
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "21.94px",
                              fontFamily: " Bebas Neue",
                              fontWeight: "400px",
                              color: "#fff",
                            }}
                          >
                            Off- RAMP
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </div>
        <Box sx={{ marginLeft: "14px" }}>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography
              onClick={() => {
                navigate("/voeuxpayabout");
              }}
              sx={{
                whiteSpace: "nowrap",
                fontSize: "22px",
                fontFamily: " Bebas Neue",
                fontWeight: "400px",
                color: "#fff",
                marginLeft: "14px",
              }}
            >
              About Us
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontSize: "22px",
                fontFamily: " Bebas Neue",
                fontWeight: "400px",
                color: "#fff",
                marginLeft: "14px",
              }}
            >
              Business
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                fontSize: "22px",
                fontFamily: " Bebas Neue",
                fontWeight: "400px",
                color: "#fff",
                marginLeft: "14px",
              }}
            >
              Developers
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              border: " 0.875px solid var(--Linear, #FF990D)",
              borderRadius: 3,
              marginRight: "4px",
              color: "white",
              width: " 116px",
              height: "44px",
              fontFamily: "Bebas Neue",
              fontSize: "18px",
              fontWeight: "400px",
            }}
          >
            LOGIN
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 3,
              backgroundImage:
                "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
              width: " 116px",
              height: "44px",
              fontFamily: "Bebas Neue",
              fontSize: "18px",
              fontWeight: "400px",
              marginLeft: "10px",
            }}
          >
            REGISTER
          </Button>
        </Box>
      </Box>
    </>
  );
}
