// Choosecards.tsx
import React, { useState } from "react";
import { Box, Dialog, Typography } from "@mui/material";
import arrow from "../../assets/voeuxpay/Arrow 1.svg";
import individualimg from "../../assets/voeuxpay/individualimg.svg";
import businessimg from "../../assets/voeuxpay/businessimg.svg";
import ForIndividual from "./ForIndividual";
import ForBusiness from "./ForBusiness";
import Slide from "@mui/material/Slide";

interface CardData {
  id: number;
  image: string;
  title: string;
  caption: string;
  direction: string;
  arrowimg: string;
}

const cardData: CardData[] = [
  {
    id: 1,
    image: individualimg,
    title: "For individuals",
    caption:
      "Take charge of your account with customized settings and preferences. Swift and hassle-free account setup for your convenience.",
    direction: "Open Account",
    arrowimg: arrow,
  },
  {
    id: 2,
    image: businessimg,
    title: "For business",
    caption:
      "Access multi-currency to crypto accounts for convenient global payments. Enjoy a secure platform with vigilant fraud monitoring.",
    direction: "Open Account",
    arrowimg: arrow,
  },
];

const Choosecards: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState<number | null>(null);

  const handleCardClick = (id: number) => {
    setDialogOpen(id);
  };

  const handleCloseDialog = () => {
    setDialogOpen(null);
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {cardData.map((card) => (
        <Card
          key={card.id}
          {...card}
          onClick={() => handleCardClick(card.id)}
        />
      ))}

      {dialogOpen === 1 && (
        <Dialog
          open={dialogOpen === 1}
          TransitionComponent={Slide}
          keepMounted
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <ForIndividual onClose={handleCloseDialog} />
        </Dialog>
      )}

      {dialogOpen === 2 && (
        <Dialog
          open={dialogOpen === 2}
          TransitionComponent={Slide}
          keepMounted
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <ForBusiness onClose={handleCloseDialog} />
        </Dialog>
      )}
    </div>
  );
};

interface CardProps extends CardData {
  onClick: () => void;
}

const Card: React.FC<CardProps> = ({
  image,
  title,
  caption,
  direction,
  arrowimg,
  onClick,
}) => {
  return (
    <Box
      sx={{
        width: "300.20px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "24.497px",
        margin: "10px",

        background: "#181818",
      }}
      onClick={onClick}
    >
      <Box sx={{ marginTop: "10px" }}>
        <img src={image} alt={title} />
      </Box>
      <Typography
        sx={{
          fontSize: "24px",
          fontFamily: "Bebas Neue",
          textTransform: "uppercase",
          marginTop: "10px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          margin: "5px 0",
          fontFamily: "outfit",
          fontSize: { xs: "16px", md: "18px" },

          textAlign: "center",
        }}
      >
        {caption}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          gap: 1,
        }}
      >
        <Typography
          sx={{
            margin: "0",
            color: "#FF990D",
            fontFamily: "outfit",
            fontSize: "18px",
          }}
        >
          {direction}
        </Typography>
        <img src={arrowimg} alt="Arrow" />
      </Box>
    </Box>
  );
};

export default Choosecards;
