// ForBusiness.tsx
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

interface ForBusinessProps {
  onClose: () => void;
}

function ForBusiness({ onClose }: ForBusinessProps) {
  const [showComponent, setShowComponent] = useState(true);

  const handleCloseButtonClick = () => {
    setShowComponent(false);
    onClose();
  };

  return (
    <Box sx={{ position: "relative", width: "100%", height: "auto" }}>
      {showComponent && (
        <Box
          sx={{
            position: "relative",
            textAlign: "center",
            width: "100%",
            // height: "1140px",
            background: "#181818",
            borderRadius: "18.34px",
            border: "1.53 solid",
            backdropFilter: "blur(168.41px)",
            padding: "20px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "32.10px",
                fontFamily: "Bebas Neue",
                color: "#fff",
              }}
            >
              For business
            </Typography>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontSize: "12.23px",
                color: "#CCCCCC",
              }}
            >
              We Strive to get back to you in 1-2 business days
            </Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontFamily: "outfit",
                        color: "#fff",
                        textAlign: "left",
                      }}
                    >
                      First Name*
                    </Typography>
                    <TextField
                      type="text"
                      name="firstName"
                      fullWidth
                      placeholder="Enter your first name"
                      className="firstnameinput"
                      required
                      InputProps={{
                        style: {
                          color: "white",
                          border: "1px solid #797979",
                          borderRadius: "8px",
                          boxShadow: "none",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontFamily: "outfit",
                        color: "#fff",
                        textAlign: "left",
                      }}
                    >
                      Last Name*
                    </Typography>
                    <TextField
                      type="text"
                      name="lastName"
                      fullWidth
                      placeholder="Enter your last name"
                      className="firstnameinput"
                      required
                      InputProps={{
                        style: {
                          color: "white",
                          border: "1px solid #797979",
                          borderRadius: "8px",
                          boxShadow: "none",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Grid sx={{ mb: "1rem" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                textAlign: "left",
                color: "#fff",
              }}
            >
              Phone Number
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControl>
                <Select
                  style={{
                    border: "1px solid #797979",
                    borderRadius: "8px",

                    color: "white",
                    outline: "none",
                  }}
                  labelId="country"
                  id="simpleselect"
                  value="option1"
                >
                  <MenuItem value="option1">+971</MenuItem>
                  <MenuItem value="option2">+990</MenuItem>
                  <MenuItem value="option3">+105</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                sx={{
                  border: "1px solid #797979",
                  borderRadius: "8px",
                  color: "white",
                  //   marginLeft: "5px",
                  outline: "none",
                }}
              />
            </Box>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "outfit",
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                Email*
              </Typography>
              <TextField
                type="email"
                name="email"
                fullWidth
                placeholder="you@company.com"
                className="emphn"
                required
                InputProps={{
                  style: {
                    border: "1px solid #797979",
                    borderRadius: "8px",
                    marginBottom: "1rem",
                    color: "white",
                    outline: "none",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "outfit",
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                Country*
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControl>
                  <Select
                    style={{
                      border: "1px solid #797979",
                      borderRadius: "8px",
                      color: "white",
                      outline: "none",
                    }}
                    labelId="country"
                    id="simpleselect"
                    value="option1"
                  >
                    <MenuItem value="option1">+971</MenuItem>
                    <MenuItem value="option2">+990</MenuItem>
                    <MenuItem value="option3">+105</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  sx={{
                    border: "1px solid #797979",
                    borderRadius: "8px",
                    color: "white",
                    // marginLeft: "5px",
                    outline: "none",
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                textAlign: "left",
                color: "#fff",
              }}
            >
              UBO Citizenship
            </Typography>
            <Box>
              <TextField
                type="text"
                name="assist"
                fullWidth
                className="assist"
                required
                InputProps={{
                  style: {
                    color: "white",
                    border: "1px solid #797979",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                textAlign: "left",
                color: "#fff",
              }}
            >
              Job Title
            </Typography>
            <Box>
              <TextField
                type="text"
                name="assist"
                fullWidth
                className="assist"
                required
                InputProps={{
                  style: {
                    color: "white",
                    border: "1px solid #797979",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                textAlign: "left",
                color: "#fff",
              }}
            >
              Website
            </Typography>
            <Box>
              <TextField
                type="text"
                name="assist"
                fullWidth
                className="assist"
                required
                InputProps={{
                  style: {
                    color: "white",
                    border: "1px solid #797979",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                textAlign: "left",
                color: "#fff",
              }}
            >
              Average Monthly Transaction Volume
            </Typography>
            <Box>
              <TextField
                type="text"
                name="assist"
                fullWidth
                className="assist"
                required
                InputProps={{
                  style: {
                    color: "white",
                    border: "1px solid #797979",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                textAlign: "left",
                color: "#fff",
              }}
            >
              UBO Citizenship
            </Typography>
            <Box>
              <TextField
                type="text"
                name="assist"
                fullWidth
                className="assist"
                required
                InputProps={{
                  style: {
                    color: "white",
                    border: "1px solid #797979",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                textAlign: "left",
                color: "#fff",
              }}
            >
              Local or international Transactions ? Which Country ?
            </Typography>
            <Box>
              <TextField
                type="text"
                name="assist"
                fullWidth
                className="assist"
                required
                InputProps={{
                  style: {
                    color: "white",
                    border: "1px solid #797979",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                textAlign: "left",
                color: "#fff",
              }}
            >
              Target Country Process
            </Typography>
            <Box>
              <TextField
                type="text"
                name="assist"
                fullWidth
                className="assist"
                required
                InputProps={{
                  style: {
                    color: "white",
                    border: "1px solid #797979",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              gap: "5px",
              marginTop: 2,
              flexDirection: "row",
            }}
          >
            <input type="checkbox" />

            <a
              href="#"
              style={{
                color: "#fff",
                textDecoration: "none",
                fontSize: " 12.23px",
              }}
            >
              I agree to receive other communications from Voeuxpay
            </a>
          </Box>
          <Typography
            sx={{ fontSize: " 12.23px", fontFamily: "outfit", color: "#fff" }}
          >
            {" "}
            By continuing you agree to our Terms of Use & Privacy Policy notice,
            in particular the purpose for which your personal data is collected
          </Typography>

          <Box
            sx={{
              background: "#FF990D",
              borderRadius: " 9.69px",
              marginTop: "1rem",
            }}
          >
            {" "}
            <Button
              sx={{
                textTransform: "none",
                color: "#fff",
              }}
            >
              {" "}
              submit
            </Button>
          </Box>
          <Button
            onClick={handleCloseButtonClick}
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
            }}
          >
            X
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default ForBusiness;
