import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import minus from "../../assets/Faq/minus-circle.svg";
import plus from "../../assets/Faq/plus-circle.svg";
import { Box } from "@mui/material";

interface FaqAccordion {
  id: number;
  question: string;
  answer: string;
}

interface Props {
  FaqAccordionData: FaqAccordion[];
}

export default function BasicAccordion({ FaqAccordionData }: Props) {
  const [expanded, setExpanded] = React.useState<number | false>(
    FaqAccordionData.length > 0 ? FaqAccordionData[0].id : false
  );

  const handleChange =
    (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      {FaqAccordionData.map((faq) => (
        <div className="customAccordion">
          <Accordion
            key={faq.id}
            expanded={expanded === faq.id}
            onChange={handleChange(faq.id)}
            sx={{
              backgroundColor: "transparent",
              // "&:hover": {
              //   backgroundColor: "rgba(255, 255, 255, 0.1)",
              // },
            }}
          >
            <AccordionSummary
              expandIcon={
                <Box>
                  <img src={expanded === faq.id ? minus : plus} alt="" />
                </Box>
              }
              aria-controls={`panel${faq.id}-content`}
              id={`panel${faq.id}-header`}
              sx={{
                color: expanded === faq.id ? "#FFA500" : "#fff", // Orange color for question when expanded
              }}
            >
              <Typography
                sx={{
                  color: expanded === faq.id ? "#FFA500" : "#fff",
                  fontSize: { xs: "14px", lg: "18px" },
                  fontFamily: "outfit",
                  fontWeight: "500",
                }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "14px", lg: "16px" },
                  fontFamily: "outfit",
                  fontWeight: "400",
                }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
}
