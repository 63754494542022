import React, { useState } from "react";
import {
  Card,
  Box,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@mui/material";
import nftServiceIcon from "../../assets/svgs/nftServiceIcon.svg";
import voeuxMoney from "../../assets/svgs/voeuxMoneyServiceIcon.svg";
import blockchainGameIcon from "../../assets/svgs/blockchainGameServiceIcon.svg";
import voeuxPayIcon from "../../assets/svgs/voeuxPayServiceIcon.svg";
import vr from "../../assets/svgs/vr.svg";
import cryptoicon from "../../assets/svgs/cryptoimage.svg";
import voeuxtoken from "../../assets/svgs/voeuxtoken.svg";
import { useLocation, useNavigate } from "react-router-dom";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";


const cardData = [
  {
    id: 1,
    image: nftServiceIcon,
    title: "NFT Marketplace",
    caption: "Create, buy and sell NFTs on a fully decentralized platform.",
  },
  {
    id: 2,
    image: cryptoicon,
    title: "Voeux Farming",
    caption: "Earn While You Hold Explore Our Crypto Staking Farm.",
  },
  {
    id: 3,
    image: blockchainGameIcon,
    title: "Voeux Game",
    caption: "Powered games offer an exciting new way to play and earn.",
  },
  {
    id: 4,
    image: voeuxPayIcon,
    title: "Voeux Pay",
    caption: "Your gateway to seamless and secure transactions.",
  },
  {
    id: 5,
    image: voeuxtoken,
    title: "Voeux Token",
    caption: "Unlock the metavoeux ecosystem with voeux token.",
  },
  // {
  //   id: 6,
  //   image: luckydraw,
  //   title: "Voeux Lucky Draw",
  //   caption: "Dive into the Voeux Lucky Draw, unlock your luck.",
  // },
  {
    id: 7,
    image: voeuxMoney,
    title: "Voeux Money",
    caption: "Your activities, your earnings, your choice.",
  },
  // {
  //   id: 8,
  //   image: blockchainRealStateIcon,
  //   title: "Blockchain Real Estate",
  //   caption:
  //     "Real Estate solutions, transforming properties, securing futures.",
  // },
  {
    id: 9,
    image: vr,
    title: "Metaverse",
    caption:
      "Connecting physical and digital worlds for limitless connections & endless experiences.",
  },
  // {
  //   id: 10,
  //   image: Dappicon,
  //   title: "Dapps",
  //   caption: "The simplest way to use decentralized apps.",
  // },
  // {
  //   id: 11,
  //   image: Defiicon,
  //   title: "Defi",
  //   caption: "Decentralize Your Finances, Where Finance Meets Simple Solutions.",
  // },
  // {
  //   id: 12,
  //   image: blochainvoeux,
  //   title: "Voeux Blockchain",
  //   caption:
  //     "Securely scaling the blockchain for a future that's fast and here.",
  // },

  // {
  //   id: 13,
  //   image: cryptoicon,
  //   title: "Voeux Exchange",
  //   caption:
  //     "Securely buy, sell, and trade cryptocurrencies with just a few clicks.",
  // },

  // {
  //   id: 14,
  //   image: web3social,
  //   title: "Web3 Social Media",
  //   caption: "Where Connectivity Meets Innovation, Socialize with Future.",
  // },

  // {
  //   id: 15,
  //   image: web3streaming,
  //   title: "Web3 Streaming Music",
  //   caption:
  //     "Release the power of music and blockchain with our Web3 music platform.",
  // },
];

const Loadmorepage = () => {
  // const { t, i18n } = useTranslation("global");

  const location = useLocation();
  const navigate = useNavigate();
  const handleLearnMoreClick = () => {
    navigate("/services");
  };

  const navigateToLink = (data: { title: string }) => {
    if (data.title === "Voeux Lucky Draw") {
      // window.location.href = "https://uat.voeuxluckydraw.com/";
    } else if (data.title === "Voeux Pay") {
      // window.location.href = "https://voeuxpay.com/";
      navigate("/voeuxpay");
    } else if (data.title === "Voeux Farming") {
      navigate("/voeuxfarming");
    } else if (data.title === "NFT Marketplace") {
      navigate("/nft");
    } else if (data.title === "Voeux Token") {
      navigate("/voeuxtoken");
    } else if (data.title === "Voeux Game") {
      navigate("/voeuxGame");
    } else if (data.title === "Voeux Money") {
      navigate("/voeuxmoney");
    }
  };

  const [hoverElement, setHoverElement] = useState({ show: false, title: "" });

  const handleHover = (data: { title: string }) => {
    const cardsToShowComingSoon = [
      "Voeux Lucky Draw",
      "Blockchain Real Estate",
      "Metaverse",
      // "Voeux Game",
      "Dapps",
      // "Voeux Token",
      "Defi",
      "Voeux Blockchain",
      "Web3 Social Media",
      "Voeux Exchange",
      "Web3 Streaming Music",
      // "Voeux Money",
    ];
    if (cardsToShowComingSoon.includes(data.title)) {
      setHoverElement({ show: true, title: data.title });
    } else {
      setHoverElement({ show: false, title: "" });
    }
  };

  const borderGradientBox = {
    background:
      "linear-gradient(#272727 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
    border: "2px solid transparent",
    borderRadius: "16px",

    backgroundColor: "#272727",
  };

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  return (
    <Box
      sx={{
        "@media (min-width: 1721px)": {
          padding: "0% 10% 4% 10%",
        },
      }}
    >
      <Box sx={{ padding: { xs: "15px", md: "0 40px" } }}>
        <NewNftHeader />
      </Box>
      <Box
        sx={{
          ...circleStyle,
          display: { xs: "flex", sm: "flex", md: "flex" },
          down: { xl: "20%" },
          left: "1%",
          transform: "translateY(37%)",
          width: "250px", // Adjusted width
          height: "200px", // Adjusted height
          borderRadius: "50%", // Ensures it remains a perfect circle
          background: "#9D00FF",
          filter: "blur(132px)",
          backdropFilter:"blur(132px)",
          WebkitBackdropFilter:"blur(132px)",  
          overflow: "hidden",
        }}
      ></Box>
      <Box sx={{ px: 5, my: { lg: 7 }, mb: "40px", overflow: "hidden" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "23px", md: "32px" },
                margin: { xs: 0 },
                fontWeight: "500",
                fontFamily: "Outfit",
              }}
            >
              {/* {t("All your needs in one place")} */}
              All your needs in one place
            </Typography>
          </Grid>
          {cardData.map((card) => (
            <Grid
              item
              key={card.id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => {
                navigateToLink(card);
              }}
              onMouseOver={() => {
                handleHover(card);
              }}
              onMouseEnter={() => {
                handleHover(card);
              }}
              onMouseOut={() => {
                setHoverElement({ show: false, title: "" });
              }}
            >
              <Card
                sx={{
                  ":hover": {
                    ...borderGradientBox,
                  },
                  height: {
                    xs: 180,
                    md: 200,
                    lg: 240,
                  },
                }}
                style={{
                  backgroundColor: "#272727",
                  color: "#fff",
                  borderRadius: "15.558px",
                }}
              >
                {hoverElement.show && hoverElement.title === card.title ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      background:
                        "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                    }}
                  >
                    <Typography sx={{ fontSize: "28px", fontFamily: "outfit" }}>
                      Coming Soon
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "20px",
                      width: "20px",
                      margin: "1rem",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "1.5rem",
                      padding: 3,
                    }}
                    style={{
                      backgroundColor: "orange",
                    }}
                  >
                    <CardMedia
                      component="img"
                      style={{ width: "30px", height: "30px" }}
                      src={card.image}
                      alt={card.title}
                    />
                  </Box>
                )}
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "white",
                      fontSize: {
                        xs: 18,
                        md: 16,
                        lg: 24,
                      },
                      fontFamily: "outfit",
                      fontWeight: "700",
                      ml: 0.5,
                      mt: 0,
                    }}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: 14,
                        md: 18,
                        fontFamily: "outfit",
                      },
                      fontFamily: "outfit",
                      fontWeight: "300",
                      ml: 0.5,
                      mt: 1,
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {card.caption}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          ...circleStyle,
          display: { xs: "flex", sm: "flex", md: "flex" },
          bottom: { xl: "0%" },
          right: { xl: "0%" },
          transform: "translateY(37%)",
          width: "250px", // Adjusted width
          height: "200px", // Adjusted height
          borderRadius: "50%", // Ensures it remains a perfect circle
          background: "#9D00FF",
          filter: "blur(132px)",
          backdropFilter:"blur(132px)",
          WebkitBackdropFilter:"blur(132px)", 
        }}
      ></Box>
      <LandingPageFooter />
    </Box>
  );
};

export default Loadmorepage;
