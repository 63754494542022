import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    checkMetaMask:false
}
const metaMaskSlice = createSlice({
    name:"metaMaskSlice",
    initialState,
    reducers: {
        setCheckMetaMask: (state, action) => {
          state.checkMetaMask = action.payload;
          
        },
    }
})

export const { setCheckMetaMask } = metaMaskSlice.actions;

export default metaMaskSlice.reducer;