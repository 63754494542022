import React, { useEffect, useMemo, useState } from "react";
import AppRouter from "./router";
// import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import AWS from "aws-sdk";
import { changeAccount } from "./redux/slices/walletSlice";
import { RootState } from "./redux/store";
import { Environment } from "./environments";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ToastMessageFailure from "./components/ToastMessage/ToastMessageFailure";
import ToastMessageInformation from "./components/ToastMessage/ToastMessageInformation";
import { color } from "@mui/system";
import { ethers } from "ethers";
import { setCheckMetaMask } from "./redux/slices/metaMaskSlice";

// import { I18nextProvider } from 'react-i18next'
// import i18next from 'i18next'
// import  translate  from 'google-translate-api-x';

// import GoogleTranslate from "react-google-translate-component";

// import global_ar from "./locales/ar/global.json";
// import global_en from "./locales/en/global.json";
// import axios from "axios";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

//import ComingSoonPage from "./pages/home/CommingSoonPage";

function App() {
  const lang = useSelector((state: any) => state.lang);
  // const res =  translate('Ik spreek Engels', {to: 'en'}).then((val)=>{console.log(val)}).catch(err=>{console.log(err)
  // });
  //   // const bew = await axios.post("https://translation.googleapis.com/language/translate/v2")
  //   console.log(res,"opop");
  // useEffect(() => {
  //   const getdata =async()=>{

  //   }
  //   getdata()
  // }, [])

  // const data23 = https://translation.googleapis.com/language/translate/v2
  // console.log(https://translation.googleapis.com/language/translate/v2);

  // Memoize the 'language' property from the 'lang' object
  const selectedLanguage = useMemo(() => lang.language, [lang]);

  // i18next.init({
  //   interpolation: { escapeValue: false },
  //   lng: selectedLanguage,
  //   fallbackLng: "en",
  //   resources: {
  //     en: {
  //       global: global_en,
  //     },
  //     ar: {
  //       global: global_ar,
  //     },
  //   },
  // });

  // useEffect(() => {
  //   // Get the language from the query string
  //   const params = new URLSearchParams(window.location.search);
  //   const currentLanguage = params.get("lng");

  //   // Check if the language has changed before updating the URL
  //   if (currentLanguage !== selectedLanguage) {
  //     let loc = "http://localhost:3000/";
  //     window.location.replace(loc + "?lng=" + selectedLanguage);
  //   }
  // }, [selectedLanguage]);
  const dispatch = useDispatch();
  // const navigate = useNavigate()
  const account = useSelector((state: RootState) => state.wallet.account);
  const handleAccountsChanged = async (accounts: string[]) => {
    if (accounts.length > 0 && account !== accounts[0]) {
      dispatch(
        changeAccount({
          account: accounts[0],
        })
      );
      localStorage.setItem("account_address", accounts[0]);
      dispatch(setCheckMetaMask(true))
      closeToastErrorMessage()
    } else {
      dispatch(
        changeAccount({
          account: account,
        })
      );
      localStorage.removeItem("metaMaskAddress")
      dispatch(setCheckMetaMask(false))
      window.location.reload();
      return false
    }

    //navya code
    const address = window.ethereum.selectedAddress;
    console.log("metamesk", address);

    try {
      const getMetavoeuxResponse = await axios.get(
        `${Environment.metavoeuxBackend}ownuser`
      );

      const checkMetaVoeuxUser: any =
        getMetavoeuxResponse.data.filter(
          (user: any) => user.walletAddress == address
        ).length > 0
          ? true
          : false;
      localStorage.setItem("ownUser", checkMetaVoeuxUser);

      try {
        const response = await axios.post(
          `${Environment.metavoeuxBackend}user`,
          { walletAddress: address }
        );
        localStorage.setItem("userId", response.data._id);
        console.log("post data", response.data._id);
        if (response.status == 201) {
          // handleClose();
          // handleShowLoader(false)
          // handleRedirect && handleRedirect()
          // showToastMessage("Connected to meta mask")
        }
      } catch (error: any) {
        console.log("error", error);
        if (error?.response?.data?.message == "WalletAddress already exists") {
          try {
            const responseGetUser = await axios.get(
              `${Environment.metavoeuxBackend}user/userDetails`,
              {
                params: { walletAddress: address },
              }
            );
            console.log("walletAddress", responseGetUser);
            // handleShowLoader(false)
            localStorage.setItem("userId", responseGetUser.data._id);
            // handleClose();
            // handleRedirect && handleRedirect()
          } catch (error) {
            console.log("Error in reading details");
          }
        }
      }
    } catch (error: any) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener(
          "accountsChanged",
          handleAccountsChanged
        );
      }
    };
  });
  let address = undefined;
  // const getSelectedAddress = async() =>{
  //   const data = await window?.ethereum
  //   console.log("getSelectedAddress",data)
  //   return data
  // }
  const [update, setUpdate] = useState(false)
  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);

  useEffect(() => {
    // const getSelectedAddress = async () => {
    //   if (!window?.ethereum) {
    //     showToastInfoMessageClick("Please Install Metamask");
    //     return false;
    //   }
  
    //   try {
    //     const accounts = await window.ethereum.request({
    //       method: "eth_requestAccounts",
    //     });
  
    //     if (accounts.length === 0) {
    //       showToastErrorMessageClick(
    //         "To perform any actions with MetaMask, please connect to MetaMask first."
    //       )
    //       localStorage.removeItem("metaMaskAddress")
    //       setUpdate(true)
    //     } else {
    //       closeToastErrorMessage();
    //       localStorage.setItem("metaMaskAddress", true.toString())
    //     }
    //   } catch (error) {
    //     showToastErrorMessageClick(
    //       "Error accessing MetaMask accounts. Please make sure MetaMask is installed and unlocked."
    //     );
    //     console.error("Error requesting accounts:", error);
    //   }
    // };
  
    // getSelectedAddress();
  },[]);

  const getdata = async() =>{
    const providr = new ethers.BrowserProvider(window.ethereum);
        console.log("provider",providr)
        if (!window?.ethereum) {
              showToastInfoMessageClick("Please Install Metamask");
              return false;
            }
    // await providr.send("eth_requestAccounts", []);
   try{

    const signer = await providr.getSigner();

      const address = await signer.getAddress()

    localStorage.setItem("metaMaskAddress", address)
   }catch(error){
    localStorage.removeItem("metaMaskAddress")
      // alert("error")
      showToastErrorMessageClick(
              "Error accessing MetaMask accounts. Please make sure MetaMask is installed and unlocked."
            );
   }
   
    // const address = await signer.getAddress();
    // console.log("address",address)
  }
  const account1 = useSelector((state: RootState) => state.metamask.checkMetaMask);
  console.log("checkMetaMask",account1)
  useEffect(() => {
   
    !account1 && getdata()
    
  })


  
  // useEffect(() => {
  //   const getSelectedAddress = async () => {
  //     // const accounts = await window.ethereum.request({
  //     //   method: "eth_accounts",
  //     // });
  //     // console.log("accounts", accounts);
  //     if (!window?.ethereum) {
  //       showToastInfoMessageClick("Please Install Metamask");
  //       return false;
  //     }
  //     // const data = await window?.ethereum;
  //     const accounts = await window.ethereum.request({
  //       method: "eth_requestAccounts",
  //     });
  //     console.log("accounts", accounts);
  //     if (accounts.length == 0) {
  //       showToastErrorMessageClick(
  //         "To perform any actions with MetaMask, please connect to MetaMask first."
  //       )
  //       localStorage.removeItem("metaMaskAddress")
  //       setUpdate(true)
  //       // address = accounts[0];
  //       // console.log("address", address);
  //     } else {
  //       closeToastErrorMessage();
  //       localStorage.setItem("metaMaskAddress",true.toString())
  //     }
  //   };
  //   getSelectedAddress();
  // });
// const dispatch = useDispatch()
  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
    setUpdate(true)
    dispatch(setCheckMetaMask(true));
  };

  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };

  const closeToastInfoMessage = () => {
    setshowInfoToastMessage(false);
  };

  const [showInfoToastMessage, setshowInfoToastMessage] = useState(false);
  const [toastInfoMessage, settoastInfoMessage] = useState("");

  const showToastInfoMessageClick = (data: string) => {
    setshowInfoToastMessage(true);
    settoastInfoMessage(data);
  };

  return (
    <div className="app">
      <ToastMessageInformation
        text={toastInfoMessage}
        display={showInfoToastMessage}
        handleClose={closeToastInfoMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
      <div id="google_translate_element"></div>
      {<AppRouter />}
      {/* <I18nextProvider i18n={i18next}>
        <AppRouter />
      </I18nextProvider> */}
    </div>
  );
}

export default App;
