import {
  Typography,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import backimg from "../../../assets/voeuxpay/Back.svg";
import axios from "axios";
import { Environment } from "../../../environments";

function StepFour({
  formData,
  handleBack,
  paymentMethodName,
  logo,
  handleShowLoader

}: {
  formData: any;
  handleNext: () => void;
  handleBack: () => void;
  paymentMethodName:any;
  logo:any,
  handleShowLoader:(active:any,text?:any)=> void
}) {
  useEffect(() => {
    console.log("stepFour", formData);
  }, [formData]);

  const [ bankDetails, setbankDetails]= useState({})
  const handleNextButton = async() =>{
    handleShowLoader(true,"Please do refresh the page until we redirect to paymnet gateway")
    const newFormData = {...formData}
    newFormData.accNum = "ACB767687687"
    try{
      const response = await axios.post(
        `${Environment.metavoeuxBackend}payment`,{
          ...newFormData
        }
      );
      if(response.data.success == true){
        const detail ={
          externalApiResponse:response.data?.externalApiResponse,
          paymentResult:response.data?.paymentResult,
        }
        setbankDetails(detail)
        handleShowLoader(false)
        window.open(detail.externalApiResponse?.data?.payment?.url);
      }
      console.log(response.data)
     
     
    }catch(error){
      console.log("error")
    }
  }
  return (
    <>
   
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          height: "316.79px",
          width: "551px",
          borderRadius: "18.34px",
          background: "#181818",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img src={backimg} alt="back" onClick={handleBack} />
          <Box sx={{ flex: 1, textAlign: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "32.10px",
                  fontWeight: "400",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  color: "white",
                  marginTop: 2,
                  textTransform: "uppercase",
                  marginRight: "60px",
                }}
              >
                {paymentMethodName}
              </Typography>
              <Box sx={{ marginTop: "28px", marginLeft: "-50px" }}>
                {" "}
                <img src={logo} width={"auto"} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            marginRight: "15px",
            padding: "20px",
            gap: "10px",
          }}
        >
          { Object.keys(formData).map((keys) => {
            return (
              <> <Typography
              sx={{
                fontFamily: "outfit",
                fontSize: "12.23px",
                color: "#fff",

                flex: 1,
              }}
            >
              {keys}
            </Typography>
            <Typography
              sx={{
                fontFamily: "outfit",
                fontSize: "12.23px",
                color: "#8D8D8D",
                marginBottom: "10px",
                flex: 1,
              }}
            >
               {formData[keys]}
            </Typography></>
            )
          })}
         

          <Button
            sx={{
              fontFamily: "Bebas Neue",
              fontSize: "18px",
              textTransform: "uppercase",
              background: "#FF990D",
              width: "100%",
              color: "#fff",
            }}
            onClick={() => handleNextButton()}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
    </>
  );
}

export default StepFour;
