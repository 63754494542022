import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NftLanding from "./pages/nftLanding/NftLanding";
import LandingPageFooter from "./components/landingPageFooter/LandingPageFooter";
import LandingPageSubscribe from "./components/landingPageSubscribe/LandingPageSubscribe";
import LandingPageBanner from "./components/landingPageBanner/LandingPageBanner";
import LandingPage from "./pages/LandingPage/LandingPage";
import Muicard from "./components/muicard/Muicard";
import Muisocial from "./components/muisocial/Muisocial";
import ExploreFarm from "./pages/ExploreFarm";
import Blog from "./pages/Blog";
import AboutUs from "./pages/AboutUs";
import Contmui from "./pages/contactpage/Contmui";
import Aboutusmui from "./pages/aboutusmui/Aboutusmui";
import MuiWalletConnect from "./components/muiconnectwallet/Muiconnect";
import Careermui from "./pages/careermui/Careermui";
import Loadmorepage from "./pages/loadmorelandingpage/Loadmorepage";
import FAQPage from "./pages/FAQPage";
import Voeuxmoneymui from "./pages/voeuxMoney/Voeuxmoneymui";
import Voeuxtoken from "./components/voeuxToken/Voeuxtokenhome";
import FarmDetail from "./pages/farmDetail/FarmDetail";
import RightMenuBar from "./pages/RightMenuBar";
import ScrollToTopOnPageChange from "./pages/ScrollToTopOnPageChange";
import UserDetails from "./pages/UserDetails/UserDetails";
import NftCreateLanding from "./pages/nftLanding/nftCreate/NftCreateLanding";
import NftCreate from "./pages/nftLanding/nftCreate/NftCreate";
import NftOverview from "./pages/nftLanding/nftCreate/NFTOverview";
import ProfileNft from "./pages/nftLanding/nftSections/ProfileNft";
import FullAppViewPage from "./pages/voeuxMoney/FullAppViewPage";
import IndividualAppDetail from "./pages/voeuxMoney/IndividualAppDetail";
import SettingsSlideMenu from "./pages/nftLanding/nftSections/SettingsSlideMenu";
import CreateFarm from "./pages/createFarm/CreateFarm";
import UpdateFarm from "./pages/updatefarm/UpdateFarm";
import NftCategoryCardPage from "./pages/nftLanding/nftCreate/NftCategoryCardPage";
import NftDetailPage from "./pages/nftLanding/NftDetailPage";
import VoeuxPayLanding from "./components/voeuxpay/VoeuxPayLanding";
import NftSalesPage from "./components/createNft/NftSalesPage";
import BlogDetails from "./pages/BlogDetails";
import VoeuxPayAboutUs from "./components/voeuxpay/VoeuxPayAboutUs";
import SelectCurrency from "./components/voeuxpay/form.tsx/SelectCurrency";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import VoeuxGame from "./pages/voeuxGame/VoeuxGame";
import NewsGame from "./components/voeuxGame/NewsGame";
import CarRacing from "./components/voeuxGame/CarRacing";
import GameUserProfile from "./components/voeuxGame/GameUserProfile";
import GameForgotPassword from "./components/voeuxGame/ForgotPassword";
import UserRegistration from "./components/voeuxGame/UserRegistration";
import ResetPassword from "./components/voeuxGame/ResetPassword";
import AdminGame from "./components/voeuxGame/AdminGame";
import NewNftProfile from "./components/profileNft/NewNftProfile";
import RewardAndEarn from "./components/voeuxGame/RewardAndEarn";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <ScrollToTopOnPageChange />
      <Routes>
       
        <Route path="/muicard" element={<Muicard />} />
        <Route path="/nft" element={<NftLanding />} />
        <Route path="/muisocial" element={<Muisocial />} />
        <Route path="/muiconnect" element={<MuiWalletConnect />} />
        <Route path="/contact" element={<Contmui />} />
        <Route path="/aboutusmui" element={<Aboutusmui />} />
        <Route path="/aboutusmui" element={<Aboutusmui />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/footer" element={<LandingPageFooter />} />
        <Route path="/subscribe" element={<LandingPageSubscribe />} />
        <Route path="/banner" element={<LandingPageBanner />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/career" element={<Careermui />} />
        <Route path="/muicard" element={<Muicard />} />
        <Route path="/nft" element={<NftLanding />} />
        <Route path="/nftmarketplace" element={<NftCreateLanding />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/nftCreate" element={<NftCreate />} />
          <Route path="/farmdetails" element={<FarmDetail />} />
        <Route path="/voeuxfarming" element={<ExploreFarm />} />
        <Route path="/nftDetail/:nftid" element={<NftDetailPage />} />
        <Route path="/nftSales/:nftid" element={<NftSalesPage />} />
        <Route path="/voeuxGame" element={<VoeuxGame />} />
        <Route path="/gameprofile" element={<GameUserProfile />} />
        <Route path="/gameAdmin" element={<AdminGame />} />

        <Route path="/createfarm" element={<CreateFarm />} />
        <Route path="/updatefarm/:id" element={<UpdateFarm />} />
        <Route path="/staking/:id" element={<FarmDetail />} />
        </Route>
        
        <Route path="/nftoverview" element={<NftOverview />} />

        <Route path="/blogDetails/:blogId" element={<BlogDetails />} />

        <Route path="/categoryNft" element={<NftCategoryCardPage />} />

        <Route path="/newsgame" element={<NewsGame />} />
        <Route path="/cryptodash" element={<CarRacing />} />

        <Route path="/forgotPassword" element={<GameForgotPassword />} />
        <Route path="/resetPassword/?" element={<ResetPassword />} />
        <Route path="/user/:type" element={<UserRegistration />} />

        <Route path="/muisocial" element={<Muisocial />} />
        <Route path="/loadmore" element={<Loadmorepage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/voeuxmoney" element={<Voeuxmoneymui />} />
        <Route path="/voeuxtoken" element={<Voeuxtoken />} />
        <Route path="/footer" element={<LandingPageFooter />} />
        <Route path="/subscribe" element={<LandingPageSubscribe />} />
        <Route path="/banner" element={<LandingPageBanner />} />
        <Route path="/muicard" element={<Muicard />} />
        <Route path="/nft" element={<NftLanding />} />
        <Route path="/muisocial" element={<Muisocial />} />
        <Route path="/rightmenu" element={<RightMenuBar />} />
        <Route path="/userdetails" element={<UserDetails />} />
        <Route path="/full" element={<FullAppViewPage />}></Route>
        <Route path="/individual" element={<IndividualAppDetail />}></Route>
        <Route path="/Settingsmenubar" element={<SettingsSlideMenu />} />
        <Route path="/profile" element={<ProfileNft />} />

        <Route path="/voeuxpay" element={<VoeuxPayLanding />} />
        <Route path="/voeuxpayabout" element={<VoeuxPayAboutUs />} />
        <Route path="/selectcurrency" element={<SelectCurrency />} />
        <Route path="/editprofile" element={<NewNftProfile />} />
        <Route path="*" element={<h3>There's nothing here: 404!</h3>} />
      </Routes>
      {/* <Media query="(max-width: 599px)">
        {(matches) =>
          matches ? (
            <Routes>
              <Route path="/Settingsmenubar" element={<SettingsSlideMenu />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/profile" element={<ProfileNft />} />
            </Routes>
          )
        }
      </Media> */}
    </Router>
  );
};

export default AppRouter;
