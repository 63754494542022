import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import gifticon from "../../assets/VoeuxGame/Giftpng.png";
// import rewardgift from "../../assets/VoeuxGame/rewardgift.png";
import rewardgift from "../../assets/VoeuxGame/rewardGift.svg"
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Environment } from "../../environments";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import { useNavigate } from "react-router-dom";

function RewardAndEarn({onClose}:{onClose:() => void}) {
  const circleStyleBlock: React.CSSProperties = {
    position: "absolute",
    flexShrink: 0,
    zIndex: -1,
  };

  useEffect(() => {
    getAllUsers()
  },[])
  const [ userName, setUserName] = useState<any>([])
  const getAllUsers = async() => {
    try{
      const response = await axios.get(
        `${Environment.metavoeuxBackend}game/user-list` )
        console.log("getAllUsers", response)
        setUserName(response.data)
    }catch(error){
      showToastErrorMessageClick("Error in reading the existing user names")
    }
  }

  const checkUserName = (nameText:any) =>{
    console.log("name value",nameText)
    if(userName.indexOf(nameText) > 0){
      showToastErrorMessageClick("UserName already exist. Please use other UserName")
    }else{
      setShowToastErrorMessage(false);
    }
  }
  
  const [ registrationData, setRegistrationData] = useState<any>({})
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const closeToastErrorMessage = () => {
    setShowToastErrorMessage(false);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const [showToastErrorMessage, setShowToastErrorMessage] = useState(false);
  const [toasterrorMessage, settoasterrorMessage] = useState("");

  const showToastErrorMessageClick = (data: string) => {
    setShowToastErrorMessage(true);
    settoasterrorMessage(data);
  };

  const handleSingin = () => {
    navigate("/user/signin",{state:{type:"landing"}})
  }
  
  const navigate = useNavigate()
  const handleResgitration = async(e:any) => {
    setShowLoader(true)
    e.preventDefault();
    console.log("registrationData",registrationData)
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if(!registrationData.email.match(mailformat)){
      showToastErrorMessageClick("Please Enter Valid Email Address")
      setShowLoader(false)
      return false
    }
    if( registrationData.displayName.length < 5){
      showToastErrorMessageClick("User Name should be atleast 5 characters ")
      setShowLoader(false)
      return false
    }
    if( registrationData.password.length < 8){
      showToastErrorMessageClick("Password should be atleast 8 characters ")
      setShowLoader(false)
      return false
    }
    if(registrationData.password != registrationData.renter){
      showToastErrorMessageClick("Password did not match")
      setShowLoader(false)
      return false
    }
    try{
      const response = await axios.post(
        `${Environment.metavoeuxBackend}game`,
        {
          email: registrationData.email,
          password:  registrationData.password,
          displayName:registrationData.displayName,
        }
        
      );
      console.log("game registration response",response)
      showToastMessageClick("Account Registered Successfully")
      navigate("/user/signin",{state:{type:"landing"}})
        setShowLoader(false)
        // setRefresh(true)
        localStorage.setItem("gameEmail",registrationData.email)
        window.sessionStorage.setItem("gameEmail1",registrationData.email)
    }catch(error:any){
      showToastErrorMessageClick(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.message ? error?.response?.message  : error?.message  )
      setShowLoader(false)
    }
  }
  const [ showLoader, setShowLoader] = useState(false)
  return (
    <Box sx={{position:"relative"}}>
       {showLoader && (
        <Box
          sx={{
            position: "absolute",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1400,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
    <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toasterrorMessage}
        display={showToastErrorMessage}
        handleClose={closeToastErrorMessage}
      />
    <Box sx={{ marginTop: "20px" }}>
      <Box
        className="polygonStyle2 userProfilePolygon"
        sx={{
          background: ` linear-gradient(102deg, #FF990D 0%, #9D00FF 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)`,
          position: "relative", overflow:"hidden",
          backdropFilter: "blur(10.781231880187988px)",
          width: { xs: "100%", md: "100%", sm: "100%" },
          py: "4px",

          margin: "auto",
          "@media(max-width:1240px)": {
            width: "100%",
          },
          "@media(max-width:899px)": {
            width: "80%",
          },
          "@media(max-width:600px)": {
            width: "100%",
          },
        }}
      >
        <Box
          className="polygonStyle2 userProfilePolygon"
          sx={{
            display: "flex",
            flexDirection: "column",overflow:"hidden",
            background: "#131313",
            justifyContent: "center",
            position: "relative",padding:"60px",
            backdropFilter: "blur(10.781231880187988px)",
            width: { xs: "99%", md: "99%", sm: "99%" },
            height: { xs: "99%", md: "99%", sm: "99%" },
            py: "40px",

            margin: "auto",
            "@media(max-width:1240px)": {
              width: "100%",
            },
            "@media(max-width:899px)": {
              width: "98%",
            },
            "@media(max-width:600px)": {
              width: "97%",
            },
          }}
        >
          <Button
            sx={{
              fontSize: "35px",
              color: "#fff",
              position: "absolute",
              right: 0,
              padding: 0,
              top: "10px",
              lineHeight: "normal",
            }}
            onClick={() => onClose()}
          >
            X
          </Button>
          <Box>

        
          <Box
            sx={{
              ...circleStyleBlock,
              top: { md: "30%" },
              transform: "translate(-50%, -50%)",
              width: "547.625px",
              height: "187.734px",
              borderRadius: "9999px",
              background: " linear-gradient(102deg, #FF990D 0%, #9D00FF 100%)",
              filter: "blur(170.66px)",
              backdropFilter: "blur(238.66px)",
              WebkitBackdropFilter: "blur(238.66px)",
            }}
          ></Box>
 <Box
                sx={{
                  ...circleStyleBlock,
                  top: { md: "30%" },
                  transform: "translate(-50%, -50%)",
                  width: "547.625px",
                  height: "130px",
                  borderRadius: "9999px",
                  background: " #ECE218",
                  filter: "blur(238.66px)",
                  // backdropFilter: "blur(238.66px)",
                  // WebkitBackdropFilter: "blur(238.66px)",
                }}
              ></Box>
  </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: "none", md: "40px" },
              justifyContent: "center",alignItems:"center",
              width: "inherit",
            }}
          >
            {/* form section started */}
            <Box
              sx={{
                display: "flex",flexDirection:"column",rowGap:"20px",
                justifyContent: { xs: "center", md: "left" },
              }}
            >
              <Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: "outfit",
                          fontSize: "25px",
                          fontWeight: "600",
                          color:"white !important"
                        }}
                      >
                        Refer & Earn
                      </Typography>
                    </Box>
                    <Box>
                      <img src={gifticon} />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "outfit",
                        fontSize: "13px",
                        fontWeight: "400",
                        color:"white !important"
                      }}
                    >
                      Please Register to Avail Free 200 <br />
                      <span style={{ color: "#ECE218" }}> Points</span>
                    </Typography>
                  </Box>
                </Box>
                <form onSubmit={(e) => handleResgitration(e)}>
                {/* username */}
                <Box
                  className=" inputpolygonStyle"
                  sx={{
                    border: `1px solid #818181`,
                    marginTop: "20px",
                    width: { md: "auto", xs: "inherit" },
                    background: `transparent`,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(52deg)",
                      top: "-12px",
                      left: "8px",
                    }}
                  ></Box>
                  <TextField
                  aria-required = {true}
                    sx={{
                      width: { xs: "100%",md:"360px" },
                      "& input": {
                        outline: "none",
                        border: "none",
                        color: "white",
                        background: "transparent",
                        paddingRight: "30px",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                    type="textfield"
                    id="text1"
                    placeholder="ENTER YOUR USERNAME"
                    variant="outlined"
                    required
                    name="displayName"
                    fullWidth
                    onChange={(e) =>
                      ( setRegistrationData({
                         ...registrationData,
                         [e.target.name]: e.target.value,
                       }), checkUserName(e.target.value))
                     }
                  />
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(60deg)",
                      bottom: "-12px",
                      right: "3px",
                    }}
                  ></Box>
                </Box>
                {/* email */}
                <Box
                  className=" inputpolygonStyle"
                  sx={{
                    border: `1px solid #818181`,
                    marginTop: "10px",
                    width: { md: "auto", xs: "inherit" },
                    background: `transparent`,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(52deg)",
                      top: "-12px",
                      left: "8px",
                    }}
                  ></Box>
                  <TextField
                      aria-required = {true}
                    sx={{
                      width: { xs: "100%",md:"360px" },
                      "& input": {
                        outline: "none",
                        border: "none",
                        color: "white",
                        background: "transparent",
                        paddingRight: "30px",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                    type="textfield"
                    id="text2"
                    placeholder="ENTER YOUR EMAIL ADDRESS"
                    variant="outlined"
                    required
                    name="email"
                    onChange={(e) =>
                      setRegistrationData({
                        ...registrationData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(60deg)",
                      bottom: "-12px",
                      right: "3px",
                    }}
                  ></Box>
                </Box>
                {/* password */}
                <Box
                  className=" inputpolygonStyle"
                  sx={{
                    border: `1px solid #818181`,
                    marginTop: "10px",
                    width: { md: "auto", xs: "inherit" },
                    background: `transparent`,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(52deg)",
                      top: "-12px",
                      left: "8px",
                    }}
                  ></Box>
                  <TextField
                      aria-required = {true}
                    sx={{
                      width: { xs: "100%",md:"360px" },
                      "& input": {
                        outline: "none",
                        border: "none",
                        color: "white",
                        background: "transparent",
                        paddingRight: "30px",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                    type="password"
                    id="text3"
                    placeholder="ENTER PASSWORD"
                    variant="outlined"
                    required
                    name="password"
                    onChange={(e) =>
                      setRegistrationData({
                        ...registrationData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(60deg)",
                      bottom: "-12px",
                      right: "3px",
                    }}
                  ></Box>
                </Box>
                {/* confirm password */}
                <Box
                  className=" inputpolygonStyle"
                  sx={{
                    border: `1px solid #818181`,
                    marginTop: "10px",
                    width: { md: "auto", xs: "inherit" },
                    background: `transparent`,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(52deg)",
                      top: "-12px",
                      left: "8px",
                    }}
                  ></Box>
                  <TextField
                      aria-required = {true}
                    sx={{
                      width: { xs: "100%" ,md:"360px"},
                      "& input": {
                        outline: "none",
                        border: "none",
                        color: "white",
                        background: "transparent",
                        paddingRight: "30px",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                    type="password"
                    id="text4"
                    placeholder="ENTER CONFIRM PASSWORD"
                    variant="outlined"
                    required
                    name="renter"
                    onChange={(e) =>
                      setRegistrationData({
                        ...registrationData,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <Box
                    sx={{
                      width: "1px",
                      height: "100%",
                      background: `#818181`,
                      position: "absolute",
                      transform: "rotate(60deg)",
                      bottom: "-12px",
                      right: "3px",
                    }}
                  ></Box>
                </Box>
                <Box>
                  {" "}
                  <Box
                    className="inputpolygonStyle BlenderProBold"
                    sx={{
                      padding: "2px",
                      width: { xs: "100%" ,md:"360px"},
                      background: ` linear-gradient(102deg, #FF990D 0%, #9D00FF 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)`,
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      className="BlenderProBold"
                      sx={{
                      
                        padding: "10px 16px",
                        color: "#fff",
                        fontSize: "20px",
                        "&:hover": {
                          background: `transparent`,
                          color: "#fff",
                        },
                      }}
                      type="submit"
                    >
                      REGISTER
                    </Button>
                  </Box>
                </Box>
                </form>
              </Box>
              <Box>
            <Typography   className="blendarPro" sx={{
              color: "#FFF",
              textAlign: "center",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 250,
              lineHeight: "normal",
              letterSpacing: "-0.178px",
            }}>OR</Typography>
            <Typography
            className="blendarPro"
             sx={{
              color: "#FFF",
              textAlign: "center",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 250,
              lineHeight: "normal",
              letterSpacing: "-0.178px",marginTop:"10px"
            }}>Already have an account?, <span style={{color:"#ECE218",cursor:"pointer"}} onClick={() => handleSingin()}>Sign In </span></Typography>
          </Box>
            </Box>

            {/* form section ended */}
            {/* img section */}
            <Box>
             
              <img style={{ height: "inherit%",width:"inherit" }} src={rewardgift} />{" "}
            </Box>
          </Box>
         
        </Box>
      </Box>
    </Box>
    </Box>
  );
}

export default RewardAndEarn;
