import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Link,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import nft1 from "../../assets/userDetails/nft1.png";
import nft2 from "../../assets/userDetails/nft2.png";
import nft3 from "../../assets/userDetails/nft3.png";
import nft4 from "../../assets/userDetails/nft4.png";
import { useEffect, useState } from "react";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import NFTTable from "../../components/nftTable/NFTTable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NftCollectionLiveCard from "../nftLanding/NftCollectionLiveCard";
import { Environment } from "src/environments";
import { MarketplaceService } from "src/services/marketplaceService";

export default function UserDetailTab({
  handleShowLoader,
}: {
  handleShowLoader: (data: any) => void;
}) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [marketplaceService, setMarketplaceService] =
    useState<MarketplaceService>();

  const [show, setShow] = React.useState([]);
  // useEffect(() => {
  //   axios
  //     .get("http://192.168.70.114:8000/nft")
  //     .then((res) => {
  //       setShow(res.data);
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // });

  const cardData = [
    {
      img: nft1,
      name: "AstroFiction",
      price: 23.09,
    },
    {
      img: nft2,
      name: "ColorfulDog 0524",
      price: 23.09,
    },
    {
      img: nft3,
      name: "Distant Galaxy",
      price: 23.09,
    },
    {
      img: nft4,
      name: "Life On Edena",
      price: 23.09,
    },
    {
      img: nft1,
      name: "ColorfulDog 0524",
      price: 23.09,
    },
    {
      img: nft2,
      name: "AstroFiction",
      price: 23.09,
    },
    {
      img: nft3,
      name: "Distant Galaxy",
      price: 23.09,
    },
    {
      img: nft4,
      name: "Life On Edena",
      price: 23.09,
    },
    {
      img: nft1,
      name: "AstroFiction",
      price: 23.09,
    },
    {
      img: nft2,
      name: "ColorfulDog 0524",
      price: 23.09,
    },
    {
      img: nft3,
      name: "Distant Galaxy",
      price: 23.09,
    },
    {
      img: nft4,
      name: "Life On Edena",
      price: 23.09,
    },
    {
      img: nft1,
      name: "ColorfulDog 0524",
      price: 23.09,
    },
    {
      img: nft2,
      name: "AstroFiction",
      price: 23.09,
    },
    {
      img: nft3,
      name: "Distant Galaxy",
      price: 23.09,
    },
    {
      img: nft4,
      name: "Life On Edena",
      price: 23.09,
    },
    {
      img: nft1,
      name: "AstroFiction",
      price: 23.09,
    },
    {
      img: nft2,
      name: "ColorfulDog 0524",
      price: 23.09,
    },
  ];
  const walletData = localStorage.getItem("account_address");
  const [collectionList, setCollectionList] = useState<any>([]);
  const [userOfferRecieved, setUserOfferRecieved] = useState<any>([]);

  const callNftapi = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nftCollection/user`,
        {
          params: { walletAddress: walletData },
        }
      );
      console.log("nft collection user", response);
      setCollectionList([...response.data]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [userId, setUserId] = useState("");
  const callOfferRecivedAPi = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}makeoffer/make_Offer_collection?ownerId=${user_ID}`
      );
      console.log("offer recived for sepcific NFT", response);
      let sample: any = response.data.map((obj: any) => ({
        "NFT Name": obj.nftData?.nftName,
        "Offered Price": obj?.offerData?.offeredPrice,
        // "Original Price": obj.nft.listingPrice,
        "Created At": obj?.createdAt,
        "Bidder": obj?.offerData?.user,
      }));
      setUserOfferRecieved([...sample]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [offerMadeList, setOfferMadeList] = useState<any>([]);
  const [offerMadeListResponse, setOfferMadeListResponse] = useState<any>([]);
  const callOfferMadeApi = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}makeoffer/ouroffer?userid=${user_ID}`
      );
      console.log("callOfferMadeApi", response);
      setOfferMadeListResponse(response.data);
      let sample: any = response.data.filter((data:any) =>  data.isCancelled ==  false).map((obj: any) => ({
        "NFT Name": obj.nft.nftName,
        "Offered Price": obj.offeredPrice,
        // "Original Price": obj.nft.listingPrice,
        "Created At": obj.createdAt,
        Delete: true,
      }));
      // setTraitInputData(sample)

      // setUserOfferRecieved([...sample])

      setOfferMadeList([...sample]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const smallScreen = useMediaQuery("(max-width: 768px)");

  const fetchUserDetails = async () => {
    try {
      // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
      const response = await axios.get(
        `${Environment.metavoeuxBackend}user/userDetails?walletAddress=${walletData}`,
        {
          params: { walletAddress: walletData },
        }
      );
      console.log("user details", response.data);
      const userId = response.data._id;
      setUserId(userId);
      // const profile = localStorage.getItem("profileIcon")
      // const bcakground =   localStorage.getItem("coverpic")

      // formdata.user  = response.data
      // handleShowLoader()
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [activeNftResponse, setActiveNftResponse] = useState<any>([]);
  const [activeNft, setActiveNft] = useState<any>([]);
  const user_ID = localStorage.getItem("userId");
  const callActiveListingAPi = async () => {
    try {
      // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
      const response = await axios.get(
        `${Environment.metavoeuxBackend}listingnft/userId?userId=${user_ID}`
      );
      setActiveNftResponse(response.data);
      console.log("callActiveListingAPi", response.data);
      const activeList = response.data?.map((data: any) => {
        return {
          imageURL: data?.nft?.imageUrl,
          nftName: data?.nft?.nftName,
          price: data?.listingPrice,
          supply: data?.nft?.supply,
          nftID: data?.nft?._id,
          userId: data?.user,
          listingId: data?._id,
        };
      });
      setActiveNft(activeList);
      // setUserId(userId)
      // setActiveNft()
      // const profile = localStorage.getItem("profileIcon")
      // const bcakground =   localStorage.getItem("coverpic")

      // formdata.user  = response.data
      // handleShowLoader()
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [soldNft, setSoldNft] = useState<any>([]);
  const callSoldListingApi = async () => {
    try {
      // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
      const response = await axios.get(
        `${Environment.metavoeuxBackend}listingnft/sold?userId=${user_ID}`
      );
      console.log("callActiveListingAPi", response);
      const soldList = response.data?.map((data: any) => {
        return {
          imageURL: data.nft.imageUrl,
          nftName: data.nft.nftName,
          price: data.sellingPrice,
          supply: data.nft.supply,
          nftID: data.nft._id,
        };
      });
      setSoldNft(soldList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [unListNft, setUnListNft] = useState<any>([]);
  const callUnListingApi = async () => {
    try {
      // http://192.168.70.24:8000/nftCollection/user?walletAddress=0x2691F33b04eFcEc328E1ff4cf27e04766FD64D31
      const response = await axios.get(
        `${Environment.metavoeuxBackend}listingnft/unlisted?userId=${user_ID}`
      );
      // const response = await axios.get(`${Environment.metavoeux}/listingnft/notlisted`);
      console.log("callUnListingApi", response.data);
      const soldList = response.data?.map((data: any) => {
        return {
          imageURL: data.imageUrl,
          nftName: data.nftName,
          price: data?.listingPrice,
          supply: data.supply,
          nftID: data._id,
        };
      });
      setUnListNft(soldList);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [reloadNft, setReloadNft] = useState(false);
  const handleReloadNft = () => {
    setReloadNft(true);
  };
  useEffect(() => {
    callActiveListingAPi();
    callUnListingApi();
  }, [reloadNft]);
  const exececuteAllAPi = async () => {
    await fetchUserDetails()
      .then(() => {
        callNftapi();
        callOfferRecivedAPi();
        callOfferMadeApi();
        callActiveListingAPi();
        callSoldListingApi();
        callUnListingApi();
      })
      .then(() => handleShowLoader(false));
  };

  useEffect(() => {
    handleShowLoader(true);
    exececuteAllAPi();
  }, [user_ID]);

  const [showNftCards, setShowNftCards] = useState(false);

  const [nftCollectionList, setNftCollectionList] = useState<any>([]);
  const callGetNFTCollection = async (id?: string) => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/collection/${id}`
      );
      console.log("callGetNFTCollection", response);
      const filetredData = response.data?.filter(
        (x: any) => x.listings?.listingPrice !== undefined
      );
      setNftCollectionList([...filetredData]);
      // setUserOfferRecieved([...response.data])
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [collectionName, setCollectionName] = useState("");
  const handleCollectionCardClick = (
    id: string,
    type: string,
    contractName: string
  ) => {
    if (type == "collection") {
      setShowNftCards(true);
      callGetNFTCollection(id);
      setCollectionName(contractName);
    } else {
      setShowNftCards(false);
    }
  };
  const handleNftcardclick = () => {
    setShowNftCards(false);
  };

  const navigate = useNavigate();
  const handleNftDetailClick = (nftid: string) => {
    navigate(`/nftDetail/${nftid}`);
  };

  useEffect(() => {
    const instance = new MarketplaceService();
    setMarketplaceService(instance);
  }, []);

  // useEffect(() => {
  //   handleRemoveNftFromListing();
  // }, [marketplaceService]);

  const handleCancelOffer = (row: any, index: any) => {
    handleShowLoader(true);
    console.log("row", row, index);
    handleCancelOfferBlock(index);
  };

  const handleCancelOfferBlock = async (index: any) => {
    console.log("in cancel offer");
    console.log("offerMadeListResponse",offerMadeListResponse)
    try {
      const nftAddress =
        offerMadeListResponse[index].collectionData.collectionAddress;
      const tokenId = offerMadeListResponse[index].nft.tokenId;
      // const cancelResp = await marketplaceService?.cancelOffer(
      //   [nftAddress],
      //   [tokenId]
      // );
      // console.log("cancel offer response ==>", cancelResp);
      // http://localhost:8000/listingnft/cancel?userId=65b3b0bf707a8ae80e614537&nftId=65b4f6598eebb8d6da8adad9&listingId=65b4f6808eebb8d6da8adae4
      const response = await axios.delete(
        `${Environment.metavoeuxBackend}makeoffer/cancel?userId=${offerMadeListResponse[index].user}&nftId=${offerMadeListResponse[index].nft._id}&offerId=${offerMadeListResponse[index]._id}`
      );
      callOfferMadeApi();
      handleShowLoader(false);
      // handle web2 backend logic after offer cancellation
    } catch (err) {
      throw err;
      handleShowLoader(false);
    }
  };

  const handleActiveListing = (index: any) => {
    handleShowLoader(true);
    console.log("getting data", index);
    console.log(offerMadeListResponse[index]);
    handleRemoveNftFromListing(index);
  };
  //Functionlaity for cross click in activte listing
  const handleRemoveNftFromListing = async (index: any) => {
    try {
      const NftAddress =
        activeNftResponse[index].nft.collectionId.collectionAddress;
      const tokenId = activeNftResponse[index].nft.tokenId;
      console.log("In handle remove nft from listing", NftAddress, tokenId);
      const removedResp = await marketplaceService?.cancelNftSale(
        [NftAddress],
        [tokenId]
      );
      console.log("removed nft from listing response ==>", removedResp);
      const response = await axios.delete(
        `${Environment.metavoeuxBackend}listingnft/cancel?userId=${activeNftResponse[index].user}&nftId=${activeNftResponse[index].nft._id}&listingId=${activeNftResponse[index]._id}`
      );
      console.log("handleCross", response.data);
      handleReloadNft && handleReloadNft();
      handleShowLoader(false);
    } catch (err) {
      console.error(err);
      handleShowLoader(false);
      throw err;
    }
  };
  const renderNFtCard = () => {
    return nftCollectionList.length == 0 ? (
      <Box>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            color: "#fff",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          No NFT Details for {collectionName}
        </Typography>
      </Box>
    ) : (
      nftCollectionList.map((card: any, index: number) => (
        <Grid
          item
          // xs={12}
          // sm={3.5}
          // md={3}
          // lg={2.4}
          // xl={2}
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fit, 100%)",
              md: "repeat(auto-fit, 180px)",
              lg: "repeat(auto-fit, 232px)",
            },
          }}
          // sx={{ mr: { xs: 1, sm: 2 }, mt: 3 }}
        >
          <Stack direction="row" flexWrap="wrap">
            <Card
              key={index}
              sx={{
                borderRadius: "13.991px",
                background: "transparent",
                cursor: "pointer",
                width: "100%",
              }}
              onClick={() => handleNftDetailClick(card._id)}
              // onClick={() => handleCollectionCardClick(card._id,"collection", card.contractName)}
            >
              <CardMedia
                component="img"
                alt={card.nftName}
                height="217"
                width="100%"
                image={card.imageUrl}
                sx={{ objectFit: "cover", objectPosition: "0 0" }}
              />
              <CardContent sx={{ background: "#181818" }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: 600,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {card.nftName}
                </Typography>
                {/* <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#858584" }}
                  >
                    {card.description.slice(0, 40)}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#fff" }}
                  >
                    Price: {card.listings.listingPrice}
                  </Typography>
                </Box> */}

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  mt={2}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#858584", fontFamily: "outfit" }}
                  >
                    Price:
                  </Typography>
                  <Typography sx={{ fontFamily: "outfit", color: "#858584" }}>
                    {Number(card.listings.listingPrice).toFixed(4)} Voeux
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  mt={2}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ color: "#858584", fontFamily: "outfit" }}
                  >
                    Supply:
                  </Typography>
                  <Typography sx={{ fontFamily: "outfit", color: "#858584" }}>
                    {card?.supply}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      ))
    );
  };
  const renderCards = () => {
    return collectionList.map((card: any, index: number) => (
      <Grid
        item
        // xs={12}
        // sm={3.5}
        // md={3}
        // lg={2.4}
        // xl={2}
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(auto-fit, 100%)",
            md: "repeat(auto-fit, 180px)",
            lg: "repeat(auto-fit, 232px)",
          },
        }}

        // sx={{ mr: { xs: 1, sm: 2 }, mt: 3 }}
      >
        <Stack direction="row" flexWrap="wrap">
          <Card
            key={index}
            sx={{
              borderRadius: "13.991px ",
              background: "transparent",
              cursor: "pointer",
              width: "100%",
            }}
            onClick={() =>
              handleCollectionCardClick(
                card._id,
                "collection",
                card.contractName
              )
            }
          >
            <CardMedia
              component="img"
              alt={card.nftName}
              height="217"
              width="100%"
              image={card.url}
              sx={{ objectFit: "cover", objectPosition: "0 0" }}
            />
            <CardContent sx={{ background: "#181818" }}>
              <Typography
                gutterBottom
                component="div"
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: 600,
                  fontFamily: "outfit",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {card.contractName}
              </Typography>
              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ color: "#858584", height: "42px", overflow: "hidden" }}
                >
                  {card.description.slice(0, 40)}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ color: "#fff" }}
                >
                  {card?.listingPrice}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
    ));
  };

  const GradientBox = {
    borderRadius: "12px",
    transition: "border-radius 0.3s",
    fontFamily: "outfit",
  };

  // jsx starts here
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Box
            sx={{
              maxWidth: { xs: 320, sm: 480, lg: "100%" },
              bgcolor: "Transparent",
            }}
          >
            <Tabs
              className="customTabs nfttabs"
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                title: "indicator",
                sx: { backgroundColor: "transparent" },
              }}
            >
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  mr: "20px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                }}
                label="Collection"
                value="1"
              />
              {/* Add the rest of the tabs similarly */}
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  mr: "20px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                }}
                label="Offers made"
                value="2"
              />
              {/* <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                  ":focus": {
                    color: "orange",
                  },
                }}
                label="Deals"
                value="3"
              /> */}
              {/* <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                  ":focus": {
                    color: "orange",
                  },
                }}
                label="Created"
                value=""
              /> */}
              {/* <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                  ":focus": {
                    color: "orange",
                  },
                }}
                label="Favorited"
                value="5"
              /> */}
              {/* <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                  ":focus": {
                    color: "orange",
                  },
                }}
                label="Activity"
                value="6"
              /> */}
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  mr: "20px",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                }}
                label="Offers Received"
                value="7"
              />
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  mr: "20px",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                }}
                label="Active listing"
                value="8"
              />
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  mr: "20px",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                }}
                label="Sold"
                value="9"
              />
              <Tab
                sx={{
                  color: "white",
                  ...GradientBox,
                  fontSize: "18px",
                  textTransform: "none",
                  mr: "20px",
                  "&:hover": {
                    color: "white",
                    backgroundImage:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                  },
                }}
                label="Not Listing"
                value="10"
              />
            </Tabs>
          </Box>
        </Box>
        <TabPanel value="1">
          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "outfit",
              marginBottom: "2rem",
            }}
          >
            Creator Collections
          </Typography>
          {/* required  */}
          {showNftCards && (
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "outfit",
                marginBottom: "2rem",
                cursor: "pointer",
                color: "#FF990D",
              }}
            >
              <Link
                onClick={handleNftcardclick}
                sx={{
                  color: "white",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Creator Collection
              </Link>{" "}
              / {collectionName}
            </Typography>
          )}
          <Grid container spacing={2}>
            {!showNftCards ? renderCards() : renderNFtCard()}
          </Grid>
        </TabPanel>
        {/* Add TabPanels for the rest of the tabs similarly */}
        <TabPanel value="2">
          {offerMadeList.length > 0 ? (
            <NFTTable
              nftList={offerMadeList}
              numberList={true}
              handleCancelOffer={handleCancelOffer}
            />
          ) : (
            <Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: "outfit",
                  marginBottom: "2rem",
                  cursor: "pointer",
                }}
              >
                No Offers Made
              </Typography>
            </Box>
          )}
        </TabPanel>
        <TabPanel value="3">Content for Deals tab</TabPanel>
        <TabPanel value="4">Content for cretaed tab</TabPanel>
        <TabPanel value="5">Content for Favorited tab</TabPanel>
        <TabPanel value="6">Content for Activity tab</TabPanel>
        <TabPanel value="7">
          {userOfferRecieved.length > 0 ? (
            <NFTTable nftList={userOfferRecieved} numberList={true} />
          ) : (
            <Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontFamily: "outfit",
                  marginBottom: "2rem",
                  cursor: "pointer",
                }}
              >
                No Offers Recieved
              </Typography>
            </Box>
          )}
        </TabPanel>
        <TabPanel value="8">
          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "outfit",
              marginBottom: "2rem",
            }}
          >
            Active Listing for the User
          </Typography>

          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: {
                xs: "repeat(auto-fit, 100%)",
                md: "repeat(auto-fit, 180px)",
                lg: "repeat(auto-fit, 232px)",
              },
              rowGap: "30px",
              columnGap: "30px",
            }}
          >
            <NftCollectionLiveCard
              collectionList={activeNft}
              activeListing={true}
              handleReloadNft={handleActiveListing}
            />
          </Box>
        </TabPanel>
        <TabPanel value="9">
          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "outfit",
              marginBottom: "2rem",
            }}
          >
            Sold NFT
          </Typography>

          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: {
                xs: "repeat(auto-fit, 100%)",
                md: "repeat(auto-fit, 180px)",
                lg: "repeat(auto-fit, 232px)",
              },
              rowGap: "30px",
              columnGap: "30px",
            }}
          >
            <NftCollectionLiveCard collectionList={soldNft} />
          </Box>
        </TabPanel>
        <TabPanel value="10">
          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "outfit",
              marginBottom: "2rem",
            }}
          >
            NFT's Not listed
          </Typography>

          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: {
                xs: "repeat(auto-fit, 100%)",
                md: "repeat(auto-fit, 180px)",
                lg: "repeat(auto-fit, 232px)",
              },
              rowGap: "30px",
              columnGap: "30px",
              marginTop: "20px",
            }}
          >
            <NftCollectionLiveCard
              collectionList={unListNft}
              detailsPage={true}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
