import { Box } from "@mui/system";
import backgroundimg from "../../assets/VoeuxGame/charbackground.png";
import { Typography, useMediaQuery } from "@mui/material";
import pink from "../../assets/VoeuxGame/pink.svg";
import blue from "../../assets/VoeuxGame/blue.svg";
import yellow from "../../assets/VoeuxGame/yellow.svg";
import brown from "../../assets/VoeuxGame/brown.svg";
import green from "../../assets/VoeuxGame/green.svg";

import Slider from "react-slick";
const CharacterData = [
  {
    id: 1,
    backgroundimage: pink,
    Characterimage: "https://d24m5mu8cxqwfm.cloudfront.net/images/pinkchar.svg",
    backHeight: "298px",
    characterHeight: "383px",
  },
  {
    id: 2,
    backgroundimage: blue,
    Characterimage: "https://d24m5mu8cxqwfm.cloudfront.net/images/bluechar.svg",
    backHeight: "298px",
    characterHeight: "383px",
  },
  {
    id: 3,
    backgroundimage: yellow,
    Characterimage:
      "https://d24m5mu8cxqwfm.cloudfront.net/images/yellowchar.svg",
    backHeight: "298px",
    characterHeight: "331px",
  },
  {
    id: 4,
    backgroundimage: brown,
    Characterimage:
      "https://d24m5mu8cxqwfm.cloudfront.net/images/brownchar.svg",
    backHeight: "298px",
    characterHeight: "340px",
  },
  {
    id: 5,
    backgroundimage: green,
    Characterimage:
      "https://d24m5mu8cxqwfm.cloudfront.net/images/greenchar.svg",
    backHeight: "298px",
    characterHeight: "325px",
  },
];

function CharacterGame() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "40px",
    // slidesToShow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
  };
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${backgroundimg} )`,
          draggable: "false",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "center", paddingTop: "48px" }}
        >
          {" "}
          <Typography
            className="BlenderProBold"
            sx={{
              color: "#D2C915",
              fontSize: { md: "32px", xs: "18px" },
              fontWeight: " 500px",
              textTransform: "uppercase",
            }}
          >
            {" "}
            LAST FRONTIER
          </Typography>
        </Box>
        <Box>
          {" "}
          <Typography
            className="refinary25"
            sx={{
              color: "#fff",
              fontSize: { xs: "23px", md: "56px" },
              fontWeight: " 400px",
              textTransform: "uppercase",
              textAlign: "center",
            }}
          >
            MULTIPLE CHARACTERS TO CHOOSE FROM
          </Typography>
        </Box>
        {isMobile ? (
          <Box>
            <div className="slider-container characters">
              <Slider {...settings}>
                {CharacterData.map((character) => (
                  <Box
                    key={character.id}
                    sx={{
                      position: "relative",
                      // backgroundImage: `url(${character.backgroundimage})`,
                      // backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      // paddingBottom: "31px",
                      backgroundSize: "contain",
                      transition: "all 0.3s ease-in-out",
                      transformOrigin: "center bottom",
                      "&:hover img": {
                        // transform: "scale(1.25)",
                        // bottom: "80px !important",
                      },
                      // "&:hover": {
                      //   transform: "scale(1.25)",
                      // },
                    }}
                  >
                    <img
                      draggable="false"
                      src={character.backgroundimage}
                      alt=""
                      style={{
                        position: "absolute",
                        zIndex: -1,
                        height: "280px",
                        width: "300px",
                        top: "70px",
                      }}
                    />
                    <img
                      draggable="false"
                      src={character.Characterimage}
                      alt="characterimg"
                      style={{
                        margin: "auto",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        height: "314px",
                        objectFit: "fill",
                      }}
                    />
                  </Box>
                ))}
              </Slider>
            </div>
          </Box>
        ) : (
          <>
            <Box>
              {" "}
              <video
                style={{
                  objectFit: "cover",
                  height: "100%",
                  width: "100%",
                }}
                loop
                muted
                autoPlay
                playsInline
                preload="auto"
                src={
                  "https://d24m5mu8cxqwfm.cloudfront.net/images/gameCharacter.webm"
                }
                typeof="video/webm"
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default CharacterGame;
