import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import bussinessimg from "../../assets/voeuxpay/businessacc.svg";
import integrateimg from "../../assets/voeuxpay/integrate.svg";
import payoptionimg from "../../assets/voeuxpay/payoption.svg";
import getprofitimg from "../../assets/voeuxpay/getprofit.svg";

import one from "../../assets/voeuxpay/one.svg";
import two from "../../assets/voeuxpay/two.svg";
import three from "../../assets/voeuxpay/three.svg";
import four from "../../assets/voeuxpay/four.svg";

function StepsMobile() {
  const gameImages = [
    { numberimg: one, Image: bussinessimg, title: "Create Business Account" },
    { numberimg: two, Image: integrateimg, title: "Integrate With VoeuxPay" },
    {
      numberimg: three,
      Image: payoptionimg,
      title: "Place Your Payment Option",
    },
    { numberimg: four, Image: getprofitimg, title: "Get Profit" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        flexWrap: "wrap",
      }}
    >
      {gameImages.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: ["calc(50% - 8px)", "calc(25% - 8px)"],
            height: "160px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            background: "#181818",
            borderRadius: "12px",
            border: "0.98px solid",
            borderColor: "rgba(255, 255, 255, 0.1)",

            padding: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
            position: "relative",
          }}
        >
          <Box sx={{ position: "absolute", top: "8px", left: "8px" }}>
            <img
              src={item.numberimg}
              alt={`Step ${index + 1}`}
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          </Box>
          <img
            src={item.Image}
            alt={`Step ${index + 1}`}
            style={{ width: "80px", height: "80px" }}
          />
          <Typography
            variant="body2"
            component="p"
            sx={{
              marginTop: "8px",
              color: "#fff",
              fontFamily: "Bebas Neue",
              fontSize: "16px",
              textTransform: "uppercase",
            }}
          >
            {item.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

export default StepsMobile;
