import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import logo from "../../assets/svgs/metavoeux_logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import FacebookWhite from "../../assets/svgs/facebook-white.svg";
import TelegramWhite from "../../assets/svgs/Telegram-white.svg";
import InstagramWhite from "../../assets/svgs/InstagramWhite.svg";
import TwitterWhite from "../../assets/svgs/Twitter-white.svg";
import DiscordWhite from "../../assets/svgs/Discord-white.svg";
import qrCodeNew from "../../assets/images/qrCodeNew1.png";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import mobilelogo from "../../assets/svgs/logomobile.svg";
import mobileqr from "../../assets/svgs/qrmobile.svg";
import email from "../../assets/svgs/email.svg";

import instam from "../../assets/svgs/Insta m.svg";
import facebookm from "../../assets/svgs/facebook m.svg";
import youtubem from "../../assets/svgs/y m.svg";
import reditm from "../../assets/svgs/reddit m.svg";
import quoram from "../../assets/svgs/Q m.svg";
import twiiterm from "../../assets/svgs/twitter m.svg";
import discordm from "../../assets/svgs/discord m.svg";
import mediumm from "../../assets/svgs/medium m.svg";
import whtsppm from "../../assets/svgs/whatsapp m.svg";
import telegramm from "../../assets/svgs/telg.svg";

const VoeuxPayFooter = ({getInTouchRef,servicesRef,howToWork,faq}:{getInTouchRef?:any,servicesRef?:any,howToWork?:any,faq?:any}) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const location = useLocation();

  const navigate = useNavigate();
  const footerList = [
    {
      heading: "Company",
      items: ["About Us", "Services", "How to work", "Contact Us"],
      clickable: true,
    },
    {
      heading: "Resources",
      items: ["FAQ", "Privacy Policy", "Terms & Condition"],
      clickable: true,
    },
    {
      heading: "Contact Us",
      items: ["Email: Support@voeuxpay.com"],

      clickable: true,
    },
  ];

  const handleNavigate = (element: any) => {
    if (element === "About Us") {
      navigate("/voeuxpayabout");
    } else if (element === "Services") {
      if(location.pathname == "/voeuxpay"){
        servicesRef.current?.scrollIntoView({ behavior: "smooth" });
      }else{
        navigate("/voeuxpay", { state: "services" });
      }
     
    } else if (element === "How to work") {
      if(location.pathname == "/voeuxpay"){
        howToWork.current?.scrollIntoView({ behavior: "smooth" });
      }else{
        navigate("/voeuxpay", { state: "work" });
      }
     
    } else if (element === "Contact Us") {
      if(location.pathname == "/voeuxpay"){
        getInTouchRef.current?.scrollIntoView({ behavior: "smooth" });
      }else{
        navigate("/voeuxpay", { state: "contact" });
      }
      
    } else if (element === "FAQ") {
      if(location.pathname == "/voeuxpay"){
        faq.current?.scrollIntoView({ behavior: "smooth" });
      }else{
        navigate("/voeuxpay", { state: "faq" });
      }
     
    }
  };


  const socialPlatforms = [
    { icon: instam, link: "https://instagram.com/metavoeux/" },
    { icon: facebookm, link: "https://www.facebook.com/MetaVoeuxEcosystem" },
    { icon: youtubem, link: "https://youtube.com/@metavoeux/videos" },
    { icon: telegramm, link: "https://t.me/metavoeux" },
    { icon: twiiterm, link: "https://twitter.com/MetaVoeux" },
    { icon: discordm, link: "https://discord.gg/YrC7npAQ53" },
    { icon: mediumm, link: "https://medium.com/@metavoeuxit" },
    {
      icon: whtsppm,
      link: "https://whatsapp.com/channel/0029Va48rBCInlqIjvTfTs0x",
    },
    { icon: quoram, link: "https://quora.com/profile/Metavoeux" },
  ];

  const handleDocumentationClick = () => {
    window.open("../../assets/pdf/MetavoeuxWhitePaper.pdf", "_blank");
  };

  return (
    <Box
      sx={{
        backgroundColor: "#0F0F0F",
        "@media (min-width: 1721px)": {
          paddingX: "10%",
        },
      }}
      padding={{
        xs: 2,
        md: 7,
      }}
    >
      {!isMobile && (
        <>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 5, md: 20 }}
            sx={{
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Stack direction="column" spacing={2}>
              <Box
                sx={{
                  cursor:"pointer",
                  width: {
                    xs: "75%",
                    md: "100%",
                  },
                  height: {
                    xs: "80%",
                    md: "100%",
                  },
                }}
                onClick={() =>   navigate("/")}
              >
                <img src={logo} alt="" width={"100%"} />
              </Box>
              <Stack direction="row" spacing={1}>
                <Box>
                  <img src={qrCodeNew} width="88px" height="88px" alt="" />
                </Box>
              </Stack>
              <Typography fontSize={"16px"} sx={{ fontFamily: "outfit" }}>
                Scan to view MetaVoeux Ecosystem
              </Typography>
            </Stack>
            <Box
              display={{
                xs: "Column",
                md: "row",
              }}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <Grid container rowGap={5} max-width="100vw">
                {footerList.map((footerItem) => {
                  return (
                    <Grid item xs={6} sm={6} lg={3}>
                      <Typography
                        sx={{
                          color: "#FFF",
                          fontSize: { xs: "16px", md: "24px" },

                          fontWeight: 500,
                          mb: 2,
                          fontFamily: " Bebas Neue",
                        }}
                      >
                        {footerItem.heading}
                      </Typography>
                      <Grid container rowGap={1} columnGap={4}>
                        {footerItem.items.map((element) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                ...(footerItem.clickable === true && {
                                  cursor: `pointer`,
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                  fontSize: " 16px;",
                                  color: "#CCCCCC",
                                }),
                              }}
                              color={"red"}
                              fontFamily={"outfit"}
                            >
                              {footerItem.heading === "Contact Us" && (
                                <Typography
                                  display="flex"
                                  alignItems="center"
                                  gap={2}
                                  sx={{
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                  }}
                                >
                                  {element}
                                </Typography>
                              )}
                              <Typography
                                sx={{ fontSize: "14px", fontFamily: "outfit" }}
                                onClick={() => {
                                  handleNavigate(element);
                                }}
                              >
                                {footerItem.heading !== "Contact Us" && element}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Stack>
          <Stack>
            <Box
              sx={{
                mt: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                rowGap: "20px",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontFamily: "outfit",
                  "@media (max-width: 600px)": {
                    textAlign: "center",
                    color: "#CCCCCC",
                  },
                }}
              >
                Voeux Information Technology LLC © 2023 - 2024. All Rights
                Reserved
              </Typography>
              <Box>
                <a href="https://twitter.com/MetaVoeux">
                  <img style={{ marginRight: "10px" }} src={TwitterWhite} />
                </a>
                <a href="https://t.me/metavoeux">
                  <img style={{ marginRight: "10px" }} src={TelegramWhite} />
                </a>
                <a href="https://discord.gg/YrC7npAQ53">
                  <img style={{ marginRight: "10px" }} src={DiscordWhite} />
                </a>
                <a href="https://www.facebook.com/MetaVoeuxEcosystem">
                  <img style={{ marginRight: "10px" }} src={FacebookWhite} />
                </a>
                <a href="https://instagram.com/metavoeux/">
                  <img style={{ marginRight: "10px" }} src={InstagramWhite} />
                </a>
              </Box>
            </Box>
          </Stack>
        </>
      )}

      {/* for mobile */}
      {isMobile && (
        <Box>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 5, md: 20 }}
            sx={{
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <Stack direction="column" spacing={2}>
              <Box
                sx={{
                  width: {
                    xs: "50%",
                  },
                  height: {
                    xs: "50%",
                  },
                }}
              >
                <img draggable="false" src={mobilelogo} alt="" width={"100%"} />
              </Box>
              <Stack direction="row" spacing={1}>
                <Box>
                  <img
                    draggable="false"
                    src={mobileqr}
                    width="88px"
                    height="88px"
                    alt=""
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>

          <Box>
            {" "}
            <Typography
              fontSize={"18px"}
              sx={{
                fontFamily: "outfit",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              Community
            </Typography>
            <Stack direction="row" spacing={1.3}>
              {socialPlatforms.map((platform, index) => (
                <Box
                  sx={{
                    "@media (max-width: 400px)": {
                      width: "20px",
                    },
                    "@media (max-width: 270px)": {
                      width: "11px",
                    },
                  }}
                >
                  <a
                    key={index}
                    href={platform.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "-webkit-fill-available" }}
                      draggable="false"
                      src={platform.icon}
                      alt={`icon-${index}`}
                    />
                  </a>
                </Box>
              ))}
            </Stack>
          </Box>

          <div className="customAccordion">
            <Accordion
              style={{
                backgroundColor: "transparent",
                color: "#fff",

                boxShadow: "none !important",
              }}
            >
              <AccordionSummary
                sx={{
                  fontSize: "18px",
                  margin: "0px !important ",
                  fontFamily: "outfit",
                  color: "#fff",
                  padding: "0px !important",
                }}
                expandIcon={<KeyboardArrowDownIcon style={{ color: "#fff" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Company
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  onClick={() => {
                    navigate("/voeuxpayabout");
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  About Us
                </Typography>
                <Box
                  sx={{
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      onClick={() => {
                        navigate("/voeuxpay", { state: "services" });
                      }}
                      sx={{
                        fontSize: "15px",
                        fontFamily: "outfit",
                        marginBottom: "10px",
                        marginRight: "6px",
                      }}
                    >
                      Services
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    onClick={() => {
                      navigate("/voeuxpay", { state: "work" });
                    }}
                    sx={{
                      fontSize: "15px",
                      fontFamily: "outfit",
                      marginBottom: "10px",
                      marginRight: "6px",
                    }}
                  >
                    How to work
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    onClick={() => {
                      navigate("/voeuxpay", { state: "contact" });
                    }}
                    sx={{
                      fontSize: "15px",
                      fontFamily: "outfit",
                      marginBottom: "10px",
                      marginRight: "6px",
                    }}
                  >
                    Contact Us
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                backgroundColor: "transparent",
                color: "#fff ",
                boxShadow: "none !important",
              }}
            >
              <AccordionSummary
                sx={{
                  fontSize: "18px",
                  fontFamily: "outfit",
                  padding: "0px !important",
                }}
                expandIcon={<KeyboardArrowDownIcon style={{ color: "#fff" }} />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                Resources
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  onClick={() => {
                    navigate("/voeuxpay", { state: "faq" });
                  }}
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  FAQ
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontFamily: "outfit",
                    marginBottom: "10px",
                  }}
                >
                  Privacy Policy
                </Typography>
                <Typography sx={{ fontSize: "15px", fontFamily: "outfit" }}>
                  Terms & Condition
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <Box
            sx={{
              borderBottom: "1px solid #fff",
              width: "auto",
              margin: "0 auto",
              marginTop: "40px",
              opacity: "0.20",
            }}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mt={3}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "outfit",
                color: "#CCCCCC",
                textAlign: isMobile ? "center" : "initial",
              }}
            >
              Voeux Information Technology LLC © 2023 - 2024. All Rights
              Reserved
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
    
  );
};

export default VoeuxPayFooter;
