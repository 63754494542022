import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account: "",
  provider: "",
  balance: 0,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    connectWallet: (state, action) => {
      state.account = action.payload.account;
      state.provider = action.payload.provider;
      state.balance = action.payload.balance;
    },
    changeAccount: (state, action) => {
      state.account = action.payload.account;
    },
  },
});

export const { connectWallet, changeAccount } = walletSlice.actions;

export default walletSlice.reducer;
