import { Box, Grid, Stack, Typography, CardActionArea } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import arrow from "../../assets/svgs/arrow-up-right.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import { , } from '@mui/material';
// import { Stack } from "@mui/system";

import Blog1 from "../../assets/images/block1.png";
import Blog2 from "../../assets/images/block2.png";
import Blog3 from "../../assets/images/block3.png";
import block4 from "../../assets/images/block4.png";
import block5 from "../../assets/images/block5.png";
import block6 from "../../assets/images/block6.png";
import { maxHeight } from "@mui/system";
import axios from "axios";
import { Environment } from "src/environments";

const newData = [
  {
    image: Blog1,
    name: "Cryptocurrency ",
    Date: "• 09 Jan 2024",
    heading: "5 Tips to Secure Your Cryptocurrency Holdings",
    SubHeading:
      "5 Tips to Secure Your Cryptocurrency Holdings 1. Safeguard your seed phrase offline to mitigate the risk of digital theft. Store it in a secure location away...",
    url: "https://medium.com/@metavoeuxecosystem/5-tips-to-secure-your-cryptocurrency-holdings-5448b7277a02",
  },
  {
    image: Blog2,
    name: "Metaverse ",
    Date: "• 28 Dec 2023",
    heading: "Unveiling the Metaverse: An Essential Guide for Beginners ",
    SubHeading:
      "Unveiling the Metaverse: An Essential Guide for Beginners Introduction to the Metaverse Metaverse has gained significant",
    url: "https://medium.com/@metavoeuxecosystem/unveiling-the-metaverse-an-essential-guide-for-beginners-ea0db3deb1ab",
  },
  {
    image: Blog3,
    name: "Blockchain ",
    Date: "• 22 Dec 2023",
    heading: "What is Blockchain Technology?",
    SubHeading:
      "Blockchain is a revolutionary technology that acts like a secure and transparent digital ledger. Instead of having a central authority, information is stored in “blocks,”...",
    url: "https://medium.com/@metavoeuxecosystem/what-is-blockchain-technology-7723047bc5fd",
  },
  {
    image: block4,
    name: "Payment Gateway ",
    Date: "• 5 Dec 2023",
    heading: "Launching Soon: Voeux Pay - Changing How We Pay Online",
    url: "https://medium.com/@metavoeuxecosystem/launching-soon-voeux-pay-changing-how-we-pay-online-5e7cb5810582",
    SubHeading:
      "Launching Soon: Voeux Pay — Changing How We Pay Online In the fast-paced world of online transactions, having a good payment..",
  },
  {
    image: block5,
    name: "Smart Contracts ",
    Date: "• 29 Nov 2023",
    heading:
      "Smart Contracts Revolutionizing Trust, Transparency, and Efficiency in the Digital World.",
    url: "https://medium.com/@metavoeuxecosystem/smart-contracts-revolutionizing-trust-transparency-and-efficiency-in-the-digital-world-88f6a71f8137",
    SubHeading:
      "Smart Contracts Revolutionizing Trust, Transparency, and Efficiency in the Digital World. Smart contracts, self-executing contracts with the..",
  },
  {
    image: block6,
    name: "Blockchain ",
    Date: "• 28 Nov 2023",
    heading: "Importance of Understanding Blockchain.",
    url: "https://medium.com/@metavoeuxecosystem/importance-of-understanding-blockchain-ed6e9f3c9d47",
    SubHeading:
      "What is Blockchain: Blockchain is a distributed immutable ledger which is a completely transparent record-keeping system. It consists of a chain of...",
  },
];

export default function BlogCard() {
  const navigate = useNavigate();
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const handleBlogClick = (data: any) => {
    window.open(data.url, "_blank");
  };

  const handleBlogDetailsClick = (blogId: any) => {
    // window.open(, "_blank");
    navigate(`/blogDetails/${blogId}`);
  };

  const [blogData, setBlogData] = useState<any>([]);
  const getBlogData = async () => {
    try {
      // http://localhost:8000/blog
      const response = await axios.get(`${Environment.metavoeuxBackend}blog`);
      console.log("blog response", response);
      setBlogData(response.data);
    } catch (error) {
      console.log("Error in getting blog");
    }
  };
  useEffect(() => {
    getBlogData();
  }, []);
  return (
    <>
      <Stack sx={{ mb: { xl: 5 } }}>
        <Grid container spacing={3} sx={{ position: "relative" }}>
          <Box
            sx={{
              ...circleStyle,
              display:{md:"flex",xs:"none"}, 
              top: { xl: "10%" },
              left: { md: "90%", xl: "64%" },
              transform: "translateY(-50%)",
              width: {md:"450px",xs:"200px"},
              height: {md:"400px",xs:"200px"}, // Adjusted width
              borderRadius: "50%", // Ensures it remains a perfect circle
              background: "#9D00FF",
              filter: "blur(180px)",
              backdropFilter: "blur(180px)",
              WebkitBackdropFilter: "blur(180px)",
              overflow: "hidden",
            }}
          ></Box>
          <Box
            sx={{
              ...circleStyle,
              top: { xl: "80%" },
              display:{md:"flex",xs:"none"}, 
              left: { md: "0%", xl: "13%" },
              transform: "translateY(-50%)",
              width: { md: "350px" }, // Adjusted width
              height: { md: "400px" }, // Adjusted height
              borderRadius: "50%", // Ensures it remains a perfect circle
              background: "#9D00FF",
              filter: "blur(180px)",
              backdropFilter:"blur(180px)",
              WebkitBackdropFilter:"blur(180px)",  
              overflow: "hidden",
            }}
          ></Box>

          {/* {newData.map((value, id) => (
            <Grid item xs={12} sm={6} md={3} xl={2.4} key={id}>
              <Card
                sx={{
                  // maxWidth: 345,
                  // border: "1px solid red",
                  borderRadius: 4.402,
                  opacity: 0.95,
                  background: "#272727",
                  backdropFilter: "blur(12.059269905090332px)",
                  overflow: "hidden",
                }}
                key={id}
                onClick={() => handleBlogClick(value)}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    //    height="192"
                    sx={{
                      height: {
                        xs: "100%",
                        sm: "100%",
                        md: "192px",
                        lg: "192px",
                      },
                      objectFit: "inherit",
                    }}
                    image={value.image}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#FF990D",
                          ontFamily: "outfit",
                          fontSize: { xs: "8px", md: "10px" },
                        }}
                      >
                        {value.name}
                        {value.Date}
                      </Typography>
                      <img
                        src={arrow}
                        alt=""
                        style={{ width: "8%", height: "8%" }}
                      />
                    </Stack>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: { xs: "12px", md: "17px" },
                        height: { xs: "40px", sm: "40px", md: "52px" },

                        overflow: "hidden",
                      }}
                    >
                      {value.heading}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontFamily: "outfit",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxHeight: "50px",
                      }}
                    >
                      {value.SubHeading}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))} */}

          {blogData.map((blog: any, index: any) => (
            <Grid item xs={12} sm={6} md={3} xl={2.4} key={index}>
              <Card
                sx={{
                  borderRadius: 4.402,
                  opacity: 0.95,
                  background: "#272727",
                  backdropFilter: "blur(12.059269905090332px)",
                  overflow: "hidden",
                }}
                key={index}
                onClick={() => handleBlogDetailsClick(blog.blogId)}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{
                      height: {
                        xs: "100%",
                        sm: "100%",
                        md: "192px",
                        lg: "192px",
                      },
                      objectFit: "inherit",
                    }}
                    image={blog.mainImage}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#FF990D",
                          ontFamily: "outfit",
                          fontSize: { xs: "8px", md: "10px" },
                        }}
                      >
                        {blog.category +
                          " . " +
                          new Date(blog.createdAt).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                      </Typography>
                      <img
                        src={arrow}
                        alt=""
                        style={{ width: "8%", height: "8%" }}
                      />
                    </Stack>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#fff",
                        fontFamily: "outfit",
                        fontSize: { xs: "12px", md: "17px" },
                        height: { xs: "40px", sm: "40px", md: "52px" },

                        overflow: "hidden",
                      }}
                    >
                      {blog.mainHeading}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        color: "#fff",
                        fontSize: "12px",
                        fontFamily: "outfit",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        maxHeight: "50px",
                        height: "50px",
                      }}
                    >
                      {blog.sortDes}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
}
