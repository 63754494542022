import { Box, Stack } from "@mui/system";
import React from "react";
import NewNftHeader from "../components/newNftHeader/NewNftHeader";
import { Typography } from "@mui/material";
import blogImage from "../assets/images/blogstart.png";
import BlogCard from "./component/BlogCard";
import LandingPageFooter from "../components/landingPageFooter/LandingPageFooter";

const Blog = () => {
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  return (
    <>
      <Box
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader />
        <Box
          sx={{
            pt: { xs: 1, sm: 3, md: 1 },
            // px: { xs: 1, sm: 3, md: 5 },

            postision: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              ...circleStyle,
              top: { xl: "0%" },
              left: { md: "0%" },
              transform: "translateY(-50%)",
              width: { md: "450px",xs:"200px" }, // Adjusted width
              height: { md: "400px" ,xs:"200px"}, // Adjusted height
              borderRadius: "50%", // Ensures it remains a perfect circle
              background: "#9D00FF",
              filter: "blur(180px)",
              backdropFilter:"blur(180px)",
                    WebkitBackdropFilter:"blur(180px)",  
              overflow: "hidden",
            }}
          ></Box>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              my: { xs: 1, lg: 1 },
              "@media (min-width: 1721px)": {
                py: "10px",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontFamily: "outfit",
                fontSize: { xs: "22px", md: "36px" },
                "@media (min-width: 1721px)": {
                  py: "10px",
                },
              }}
            >
              Blog
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontFamily: "outfit",
                fontSize: { xs: "14px", md: "20px" },
                my: { xs: 1, md: 2 },
              }}
            >
               Get inside the Metavoeux ecosystem with our blogs.
            </Typography>
          </Stack>
          <Box
            sx={{
              borderRadius: "22px",
              "@media (min-width: 1721px)": {
                my: "20px",
              },
            }}
          >
            <img src={blogImage} alt="" width={"100%"} />
          </Box>
          <Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                my: { xs: 3, md: 5 },
              }}
            >
              {/* <Box>
              <Typography
                variant="h5"
                sx={{
                  mt: 2,
                  mb: 1,
                  fontFamily: "outfit",
                  fontSize: { xs: "18px", md: "30px" },
                }}
              >
                UX review presentations
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "outfit",
                  fontSize: { xs: "10px", md: "16px" },
                }}
              >
                How do you create compelling presentations that wow your
                colleagues and impress your managers?
              </Typography>
            </Box> */}
              {/* <Box
              sx={{
                height: "30px",
                width: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={arrow} alt="" width={"100%"} />
            </Box> */}
            </Stack>

            <BlogCard />
          </Stack>
        </Box>
      </Box>
      <Box sx={{ mt: { xs: 5 } }}>
        <LandingPageFooter />
      </Box>
    </>
  );
};

export default Blog;
