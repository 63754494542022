import "./VoeuxGame.css";
import VoeuxGameHeader from "../../components/voeuxGame/VoeuxGameHeader";
import { Box, useTheme } from "@mui/system";
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import VoeuxGamePerson from "../../assets/VoeuxGame/voeuxGamePerson.png";
import VoeuxGamePersonGradient from "../../assets/VoeuxGame/PersonBackground.svg";
import chronincalGame from "../../assets/VoeuxGame/chronicalGame.png";
import racingGame from "../../assets/VoeuxGame/racingGame.png";
import comingSoon1 from "../../assets/VoeuxGame/soon1.png";
import comingSoon2 from "../../assets/VoeuxGame/soon2.png";
import RacingCar from "../../assets/VoeuxGame/RacingCar.png";
import chronicalGame1 from "../../assets/VoeuxGame/chronicalGame1.png";
import crown from "../../assets/VoeuxGame/CasinoCrown.png";
import VoeuxFooter from "../../components/voeuxGame/VoeuxFooter";
import NewsGame from "../../components/voeuxGame/NewsGame";
import MulticarGame from "../../components/voeuxGame/MulticarGame";
import CharacterGame from "../../components/voeuxGame/CharacterGame";
import GameDetails from "../../components/voeuxGame/GameDetails";
import mobilebanner from "../../assets/VoeuxGame/mobilegamebanner.png";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import VoeuxGameRegistration from "../../components/voeuxGame/VoeuxGameRegistration";
import React from "react";
import RewardAndEarn from "../../components/voeuxGame/RewardAndEarn";

const VoeuxGame = () => {
  const data = useLocation();
  console.log("data", data);
  const gameref = useRef<any>(null);
  useEffect(() => {
    if (data.state?.type === "game") {
      gameref?.current.scrollIntoView({ behavior: "smooth" });
    }
  });
  const scrollIntoGames = () => {
    gameref?.current.scrollIntoView({ behavior: "smooth" });
  };
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow: 1,
    speed: 1000,
    autoplay: false,
  };

  const gameDetails = [
    {
      gameName: "racing game",
      gameDescription:
        "Car racing game where you conquer stages, outsmart AI opponents and claim thrilling rewards.",
      gameImage: RacingCar,
      isDownload: true,
      url: "https://voeux-game.s3.ap-south-1.amazonaws.com/CarRace.7z",
    },
    {
      gameName: "chronicle game",
      gameDescription:
        "A multiplayer fighting game where players battle each other in stages. The winner of each stage earns rewards.",
      gameImage: chronicalGame1,
      isDownload: true,
      url: "https://voeux-game.s3.ap-south-1.amazonaws.com/VoeuxFinalBuild.zip",
    },
    {
      gameName: "VOEUX GAME",
      gameDescription:
        "Voeux Game allows users to play games using various cryptocurrencies such as Bitcoin (BTC), Ethereum (ETH), and others.",
      gameImage: crown,
      isDownload: false,
    },
  ];

  const gamebuttonColor = ["E31717", "FB3", "56CCF2"];
  const gamebuttonBackColor = [
    "rgba(227, 23, 23, 0.19)",
    "rgba(255, 187, 51, 0.22)",
    "rgba(86, 204, 242, 0.19)",
  ];

  const gameList = [
    {
      gameName: "CHRONICLE GAME",
      gameImage: chronincalGame,
      download: true,
      url: "https://voeux-game.s3.ap-south-1.amazonaws.com/VoeuxFinalBuild.zip",
    },
    {
      gameName: "RACING GAME",
      gameImage: racingGame,
      download: true,
      url: "https://voeux-game.s3.ap-south-1.amazonaws.com/CarRace.7z",
    },
    {
      gameName: "COMING SOON",
      gameImage: comingSoon1,
      download: false,
    },
    {
      gameName: "COMING SOON",
      gameImage: comingSoon2,
      download: false,
    },
  ];
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const handleDownload = (url: any) => {
    if (url) {
      var gmail = localStorage.getItem("gameEmail");
      if (!gmail) {
        navigate(`/user/signin`, { state: { type: "Download", url: url } });
      } else {
        const link = document.createElement("a");
        link.setAttribute("href", url);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [showRegistration, setShowRegistration] = useState(false);
  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);

  const handleCloseRegistrationPopup = () => {
    setShowRegistration(false);
  };
  useEffect(() => {
    const getEmail = localStorage.getItem("gameEmail");
    if (getEmail) {
      setShowRegistration(false);
    } else {
      setShowRegistration(true);
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          background: "black",
          width: "100%",
          minHeight: "100vh",
          px: { xs: 1, sm: 3, md: 5 },
          // mt: { lg: 3 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
          overflow: "hidden",
        }}
      >
        <Box
        // sx={
        //   {
        //     width: "100%",
        //     minHeight: "100vh",
        //     px: { xs: 1, sm: 3, md: 5 },
        //     // mt: { lg: 3 },
        //     pt: 0,
        //     "@media (min-width: 1721px)": {
        //       padding: "0% 10% 0% 10%",
        //     },
        //     overflow: "hidden",
        //   }
        // }
        >
          <VoeuxGameHeader reload={true} />
          {!isMobile && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                background:
                  "radial-gradient(50% 49.66% at 50% 49.66%, #464646 0%, #000 100%)",
                padding: "100px 0px",
                justifyContent: "center",
                columnGap: "60px",
              }}
            >
              <Typography
                sx={{
                  textShadow: "0px 14.062px 14.062px rgba(0, 0, 0, 0.25)",
                  fontFamily: "Anurati",
                  fontSize: {
                    xs: "50px",
                    sm: "86px",
                    md: "105px",
                    lg: "130px",
                    xl: "200px",
                  },
                  fontWeight: 400,
                  zIndex: 2,
                }}
              >
                VOEUX
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  // width: "100%",
                  textAlign: "center",
                  top: "-14px",
                  display: "flex",
                  justifyContent: "center",
                  left: { xl: "100px", lg: "150px", md: "140px", sm: "100px" },
                  right: "0px",
                  margin: "auto",
                }}
              >
                <img
                  draggable="false"
                  src={VoeuxGamePersonGradient}
                  alt=""
                  height="490px"
                  style={{ position: "absolute", zIndex: 1, top: "60px" }}
                />
                <img
                  draggable="false"
                  src={VoeuxGamePerson}
                  alt=""
                  height="540px"
                  style={{
                    position: "absolute",
                    zIndex: 3,
                    // paddingLeft: "100px",
                  }}
                />
              </Typography>
              <Typography
                sx={{
                  textShadow: "0px 14.062px 14.062px rgba(0, 0, 0, 0.25)",
                  fontFamily: "Anurati",
                  fontSize: {
                    xs: "50px",
                    sm: "86px",
                    md: "105px",
                    lg: "130px",
                    xl: "200px",
                  },
                  fontWeight: 400,
                  zIndex: 4,
                }}
              >
                GAME
              </Typography>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  background: "linear-gradient(#e6646500 50%, #000 70%)",
                  zIndex: "4",
                  height: "100%",
                  width: "100%",
                }}
              ></Box>
              <Typography
                className="Blastage"
                sx={{
                  color: "#ECE218",
                  textShadow: "0px 4px 29.9px #ECE218",
                  position: "absolute",
                  textAlign: "center",
                  zIndex: 4,
                  bottom: "50px",
                  //  fontSize:"72px",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  lineHeight: "116%",
                  letterSpacing: "3.592px",
                  fontSize: {
                    sm: "37px",
                    md: "42px",
                    lg: "62px",
                    xl: "72px",
                  },
                }}
              >
                PLAY TO EARN
              </Typography>
            </Box>
          )}

          {isMobile && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                background:
                  "radial-gradient(50% 49.66% at 50% 49.66%, #464646 0%, #000 100%)",

                justifyContent: "center",
              }}
            >
              <img
                draggable="false"
                alt=""
                src={mobilebanner}
                style={{ position: "relative", width: "100%" }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  background: "linear-gradient(#e6646500 50%, #000 70%)",
                  zIndex: "4",
                  height: "100%",
                  width: "100%",
                }}
              ></Box>
              <Typography
                className="Blastage"
                sx={{
                  color: "#ECE218",
                  textShadow: "0px 4px 29.9px #ECE218",
                  position: "absolute",
                  textAlign: "center",
                  zIndex: 4,
                  bottom: "30px",
                  //  fontSize:"72px",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  lineHeight: "116%",
                  letterSpacing: "3.592px",
                  fontSize: {
                    xs: "22px",
                    sm: "37px",
                    md: "42px",
                    lg: "62px",
                    xl: "72px",
                  },
                  "@media (max-width:340px)": {
                    fontSize: "14px",
                    bottom: "20px",
                  },
                }}
              >
                PLAY TO EARN
              </Typography>
            </Box>
          )}

          {isMobile ? (
            <Box
              sx={{
                background:
                  "linear-gradient(270deg, #ECE218 0%, rgba(236, 226, 24, 0.00) 100%)",
                padding: "25px 0px 25px 15px",

                zIndex: 4,
                position: "relative",
              }}
            >
              <div className="slider-container NftCardsSlider gameNames">
                <Slider {...settings}>
                  {gameList.map((gamelist, index) => (
                    <Box key={index}>
                      <Box
                        sx={{
                          borderColor: "white",
                          padding: "0px !important",
                          background: "transparent",
                          marginRight: "15px",
                        }}
                      >
                        <Box
                          className="menu-item"
                          sx={{
                            height: "143px",
                            width: "100%",
                            padding: "0px !important",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            filter: "drop-shadow(white 0px 0px 1px)",
                            backdropFilter: "drop-shadow(white 0px 0px 1px)",
                            WebkitBackdropFilter:
                              "drop-shadow(white 0px 0px 1px)",
                          }}
                        >
                          <img
                            draggable="false"
                            src={gamelist.gameImage}
                            alt=""
                            style={{
                              position: "relative",
                              zIndex: 0,
                              width: "100%",
                              height: "100%",
                              filter: "drop-shadow(0px 0px 0px white)",
                              backdropFilter: "drop-shadow(0px 0px 0px white)",
                              WebkitBackdropFilter:
                                "drop-shadow(0px 0px 0px white)",
                            }}
                          />
                          <Typography
                            className="refinary25"
                            sx={{
                              color: " #FFF",
                              textShadow: "0px 2.529px 8.408px #FB3",
                              fontSize: { md: "32px", xs: "24px" },
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "131%",
                              position: "absolute",
                              top: gamelist.download ? "30px" : "unset",
                            }}
                          >
                            {gamelist.gameName}
                          </Typography>

                          {gamelist.download && (
                            <Box
                              className="custom-button-shape bgwhite"
                              sx={{
                                height: "max-content",
                                width: "max-content",
                                position: "absolute",
                                bottom: "20px",
                              }}
                            >
                              <Button
                                className="custom-button-shape BlenderProBold"
                                // href={gamelist.url && gamelist.url}
                                onClick={() => handleDownload(gamelist.url)}
                                sx={{
                                  borderColor: "white",
                                  padding: "10px 16px",
                                  color: "#fff",
                                  borderRadius: "0",

                                  "@media (max-width: 360px)": {
                                    //  marginBottom:"210px"
                                    fontSize: "11px",
                                  },
                                }}
                              >
                                Download Now
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Slider>
              </div>
            </Box>
          ) : (
            <Box
              sx={{
                background:
                  "linear-gradient(270deg, #ECE218 0%, rgba(236, 226, 24, 0.00) 100%)",
                padding: "25px 15px",
                display: "grid",
                textAlign: "-webkit-center",
                gridTemplateColumns: {
                  xl: "repeat(4,310px)",
                  lg: "repeat(4,23%)",
                  md: "repeat(2,350px)",
                  sm: "repeat(1,300px)",
                  xs: "repeat(1,250px)",
                },

                gridGap: "30px",
                justifyContent: "center",
                zIndex: 4,
                position: "relative",
              }}
            >
              {gameList.map((gamelist, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      borderColor: "white",
                      padding: "0px !important",
                      background: "transparent",
                    }}
                  >
                    <Box
                      className="menu-item"
                      sx={{
                        height: "153px",
                        padding: "0px !important",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        filter: "drop-shadow(white 0px 0px 1px)",
                      }}
                    >
                      <img
                        draggable="false"
                        src={gamelist.gameImage}
                        alt=""
                        style={{
                          position: "absolute",
                          zIndex: -1,
                          filter: "drop-shadow(0px 0px 0px white)",
                          width: "-webkit-fill-available",
                          height: "-webkit-fill-available",
                        }}
                      />
                      <Typography
                        className="refinary25"
                        sx={{
                          color: " #FFF",
                          textShadow: "0px 2.529px 8.408px #FB3",
                          fontSize: "32px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "131%",
                        }}
                      >
                        {gamelist.gameName}
                      </Typography>

                      {gamelist.download && (
                        <Box
                          className="custom-button-shape bgwhite"
                          sx={{
                            height: "max-content",
                            width: "max-content",
                            position: "relative",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            className="custom-button-shape BlenderProBold blackDownload"
                            onClick={() => handleDownload(gamelist.url)}
                            sx={{
                              borderColor: "white",
                              padding: "10px 16px",
                              color: "#fff",
                              borderRadius: "0",

                              "@media (max-width: 360px)": {
                                //  marginBottom:"210px"
                                fontSize: "11px",
                              },
                              "&:hover": {
                                color: "black",
                              },
                            }}
                          >
                            Download Now
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Box
            ref={gameref}
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              "@media (max-width:300px)": {
                gridTemplateColumns: "repeat(1,100%)",
                padding: "5px",
              },
              gap: "20px",
              marginTop: "83px",
              marginBottom: { md: "141px", xs: "70px" },
              justifyContent: "space-between",
              padding: "16px",
            }}
          >
            {gameDetails.map((game, index) => (
              <Box
                key={index}
                className="polygonStyle2"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  background: "black",
                  padding: "24px 41px",
                  "@media (max-width:300px)": {
                    padding: "24px 15px",
                  },
                  // border: `1px solid  #${gameDetailsColor[index]}`,
                  position: "relative",
                  boxShadow:
                    "0px -8.625px 2.156px 0px rgba(235, 235, 235, 0.10) inset, -2.156px 0px 2.156px 0px rgba(235, 235, 235, 0.10) inset, 2.156px 0px 2.156px 0px rgba(235, 235, 235, 0.10) inset",
                  // backdropFilter: "blur(10.781231880187988px)",
                  minWidth: 0,
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.1)",
                    zIndex: 3,
                  },
                }}
              >
                {/* <Box sx={{width:"1px",height:"100%",background:`#${gameDetailsColor[index]}`,position:"absolute",transform:"rotate(29deg)",top:"-68px",left:"3px",zIndex:1}}></Box> */}
                <Box
                  sx={{
                    ...circleStyle,
                    display: { xs: "flex", sm: "flex", md: "flex" },
                    top: 0,
                    margin: "auto",
                    left: "0",
                    right: "0%",
                    bottom: "0px",
                    // transform: "translateY(37%)",
                    width: "360px", // Adjusted width
                    height: "123px", // Adjusted height
                    borderRadius: "100%", // Ensures it remains a perfect circle
                    background: ` #${gamebuttonColor[index]}`,
                    filter: "blur(140px)",
                    backdropFilter: { md: "none", xs: "blur(140x)" },
                    WebkitBackdropFilter: { md: "none", xs: "blur(140px)" },
                    overflow: "hidden",
                  }}
                ></Box>
                <img
                  draggable="false"
                  alt=""
                  src={game.gameImage}
                  width="60px"
                  height="60px"
                />
                <Typography
                  className="refinary25"
                  sx={{
                    color: " #FFF",
                    fontSize: { md: "36px", xs: "23px" },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "116%",
                    letterSpacing: "1.413px",
                    textTransform: "uppercase",
                    marginTop: "13px",
                  }}
                >
                  {game.gameName}
                </Typography>
                <Typography
                  className="blendarPro"
                  sx={{
                    color: " #FFF",
                    fontSize: { md: "24px", xs: "16px" },
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "116%",
                    letterSpacing: "1.413px",
                    marginTop: "13px",
                  }}
                >
                  {game.gameDescription}
                </Typography>
                <Box sx={{ height: "70px" }}>
                  <Box
                    className="custom-button-shape"
                    onClick={() => handleDownload(game.url)}
                    sx={{
                      height: "max-content",
                      width: "max-content",
                      position: "absolute",
                      bottom: "30px",
                      backgroundColor: `#${gamebuttonColor[index]} !important`,
                    }}
                  >
                    <Button
                      className="custom-button-shape BlenderProBold"
                      sx={{
                        borderColor: "white",
                        padding: "12px 19px",
                        color: "#fff",
                        borderRadius: "0",
                        backgroundColor: "transparent",
                        "@media (max-width: 360px)": {
                          //  marginBottom:"210px"
                          fontSize: "11px",
                        },
                        "&::before": {
                          backgroundColor: `${gamebuttonBackColor[index]} !important`,
                        },
                        "&:hover:before": {
                          backgroundColor: `#${gamebuttonColor[index]} !important`,
                        },
                      }}
                    >
                      Download Now
                    </Button>
                  </Box>
                </Box>

                {/* <Box sx={{width:"1px",height:"100%",background:`#${gameDetailsColor[index]}`,position:"absolute",transform:"rotate(31deg)",bottom:"-26.7px",right:"-31.5px",zIndex:1}}></Box> */}
              </Box>
            ))}
          </Box>
          <GameDetails />
        </Box>
      </Box>
      <CharacterGame />
      <MulticarGame />
      <NewsGame />
      <VoeuxFooter scrollIntoGames={scrollIntoGames} />
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent", // Set the dialog background to transparent
            boxShadow: "none", // Remove the default Material-UI box shadow
          },
        }} // Apply backdrop filter for transparency effect
        maxWidth={maxWidth}
        open={showRegistration}
        onClose={handleCloseRegistrationPopup}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <RewardAndEarn onClose={handleCloseRegistrationPopup} />
          {/* <VoeuxGameRegistration onClose = {handleCloseRegistrationPopup}/> */}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default VoeuxGame;
