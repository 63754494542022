//GameDetails.tsx
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled } from "@mui/system";
import GameImage from "../../assets/VoeuxGame/one without border.png";
import one from "../../assets/VoeuxGame/small 1.png";
import two from "../../assets/VoeuxGame/small 2.png";
import three from "../../assets/VoeuxGame/small 3.png";
import four from "../../assets/VoeuxGame/small 4.png";
import bigone from "../../assets/VoeuxGame/Big 1.png";
import bigtwo from "../../assets/VoeuxGame/Big 2.png";
import bigthree from "../../assets/VoeuxGame/Big 3.png";
import bigfour from "../../assets/VoeuxGame/Big 4.png";
import SigninGame from "./SigninGame";
import CarRacing from "./CarRacing";
import { useNavigate } from "react-router";

const ResponsiveBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

const ResponsiveTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "28px",
  },
}));

const GameDetails = () => {
  const gameImages = [
    { Image: one },
    { Image: two },
    { Image: three },
    { Image: four },
  ];
  const bigImages = [
    { Bigimg: bigone },
    { Bigimg: bigtwo },
    { Bigimg: bigthree },
    { Bigimg: bigfour },
  ];

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleImageSelect = (index: any) => {
    console.log("index",index)
    setSelectedImage(bigImages[index].Bigimg);
    setSelectedIndex(index);
  };

  const [showCarRacing, setShowCarRacing] = useState(false);

  const handleListItemClick = () => {
    setShowCarRacing(!showCarRacing);
  };


  useEffect(() => {
  }, [showCarRacing]);

  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const navigate = useNavigate()

  const handleSignInOpen = () => {
    var email=localStorage.getItem("gameEmail")
    if(email){
      navigate("/gameprofile")
    }else{
      navigate("/user/signin")
    }
    // setIsSignInOpen(true);
    
  };

  const handleSignInClose = () => {
    setIsSignInOpen(false);
  };

  const handleDownload = () =>{
      var gmail=localStorage.getItem("gameEmail")
      if(!gmail){
        navigate(`/user/signin`,{state:{type:"Download",url:"https://voeux-game.s3.ap-south-1.amazonaws.com/VoeuxFinalBuild.zip"}});
      }else{
        const link = document.createElement('a'); 
        link.setAttribute('href', "https://voeux-game.s3.ap-south-1.amazonaws.com/VoeuxFinalBuild.zip"); 
        document.body.appendChild(link); 
        link.click(); 
        document.body.removeChild(link); 
      }
  }

  const [counter, setCounter] = useState(-1);

  useEffect(() => {
    console.log("timeout",counter)
    let timeout:any;
    if(isMobile){
      if(counter >= 3){
        setCounter(-1)
        handleImageSelect(0);
      }else{
        timeout = setTimeout(() => {
          setCounter(counter + 1);
          handleImageSelect(counter + 1);
        }, 10000);
      }
     
  
      return () => {
        clearTimeout(timeout);
      };
    }
  
  }, [counter]);

 
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Box
        className="menu"
        sx={{ width: "100%", maxWidth: "341px", margin: "auto" }}
      >
        <List
          className="menu-list"
          sx={{ padding: "0 !important", cursor: "pointer" }}
        >
          <ListItem
            className={
              showCarRacing !== true ? "menu-item filledMenu" : "menu-item"
            }
            onClick={handleListItemClick}
            sx={{textAlign:"center"}}
          >
            <ListItemText className="blendarPro" primary="LAST FRONTIER" />
          </ListItem>
          <>
            <ListItem
              className={
                showCarRacing !== false ? "menu-item filledMenu" : "menu-item"
              }
              onClick={handleListItemClick}
              sx={{textAlign:"center"}}
            >
              <ListItemText
                className="blendarPro"
                primary="crypto dash"
                sx={{
                  fontSize: "16px",
                  textTransform: "uppercase",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              />
            </ListItem>
          </>
        </List>
      </Box>

      {showCarRacing ? (
        <CarRacing />
      ) : (
        <ResponsiveBox sx={{ padding: "0 30px",
        marginBottom:{md:"130px",xs:"50px"},
        textAlign:{md:"left",xs:"center"}
         }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "45px",
              flexDirection: {
                md: "column",
                lg: "row",
                sm: "column",
                xs: "column",
              },
            }}
          >
            <Box sx={{ paddingLeft: { md: "0" } }}>
              <ResponsiveTypography
                className="refinary25"
                sx={{
                  color: "#FFF",
                  fontSize: {md:"99px",xs:"23px"},
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "116%",
                  letterSpacing: "1.39px",
                  textTransform: "uppercase",
                }}
              >
                LAST FRONTIER
              </ResponsiveTypography>

              <ResponsiveTypography
                className="blendarPro"
                sx={{
                  color: "#FFF",
                  fontSize: {md:"32px",xs:"18px"},
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "111%",
                  letterSpacing: "1.39px",
                  textTransform: "uppercase",
                  marginTop:"10px"
                }}
              >
                {selectedIndex === 0 &&
                  " Last Frontier is a game that can be played with other players online."}
                {selectedIndex === 1 &&
                  " The core gameplay of Chronicle is fighting. Players use a variety of attacks and combos to defeat their opponents."}
                {selectedIndex === 2 &&
                  " Chronicle features a variety of stages, each with its own unique layout and hazards"}
                {selectedIndex === 3 &&
                  "  Players who win stages earn rewards and new weapons, abilities,and cosmetics."}
              </ResponsiveTypography>
              <Box sx={{display:"flex",columnGap:"20px",marginBottom:"30px",
              justifyContent:{md:"flex-start",xs:"center"}
            }}>

             
              <Box
                  className="polygonStyle2"
                  sx={{
                    border: `1px solid #D2C915`,
                    // padding: "2px",
                    marginTop: "20px",
                    // width: "max-content",
                    background: `#D2C915`,
                    display:"flex",
                    width:{md:"170px",sm:"170px",xs:"146px"}
                  }}
                  onClick={() =>  handleDownload()}
                >
                  <Button
                    className="BlenderProBold"
                    sx={{
                      borderColor: "white",
                      padding: "10px 16px",
                      color: "#000",
                     width:"100%",
                      "&:hover": {
                        background: `#D2C915`,
                      },
                      "@media (max-width: 360px)": {
                        //  marginBottom:"210px"
                        fontSize:"11px"
                      },
                    }}
                  >
                    Download Now
                  </Button>
                </Box>
                <Box className="custom-button-shape "
                onClick={handleSignInOpen} 
                  sx={{
                   height:"max-content",
                    width: "max-content",
                    marginTop: "20px",
                    
                  }}>
                  <Button className="custom-button-shape sellPoint BlenderProBold"
                       onClick={handleSignInOpen}
                   sx={{
                      borderColor: "white",
                      padding: "12px 16px",
                      color: "#D2C915",
                      borderRadius:"0",
                      "&:hover": {
                        background: `#D2C915`,
                        color: "#000",
                      },
                      "@media (max-width: 360px)": {
                        //  marginBottom:"210px"
                        fontSize:"11px"
                      },
                    }}>
                  BUY / SELL VX POINTS
                  </Button>
                </Box>
              
                </Box>

                 
             
            </Box>

            <Box>
              <Box
                // className="menu polygonStyle2"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // background:
                  //   "linear-gradient(180deg, rgba(166, 160, 186, 0.15) 0%, rgba(0, 0, 0, 0.20) 100%)",
                  padding: "2px",
                  position: "relative",
                  width: { xl: "733px", md: "100%" },
                  filter: selectedIndex
                      ? "drop-shadow(white 1px 1px 0px)"
                      : "drop-shadow(white 1px 1px 0px)"
                  // boxShadow:
                  //   "0px -8.625px 2.156px 0px rgba(235, 235, 235, 0.10) inset, -2.156px 0px 2.156px 0px rgba(235, 235, 235, 0.10) inset, 2.156px 0px 2.156px 0px rgba(235, 235, 235, 0.10) inset",
                  // backdropFilter: "blur(10.781231880187988px)",
                }}
              >
                <img
                  draggable="false"
                  src={selectedImage || GameImage}
                  alt="Game"
                  style={{
                    filter: selectedIndex
                      ? "drop-shadow(white -1px -1px 0px)"
                      : "drop-shadow(white -1px -1px 0px)"
                  }} 
                />
              </Box>
                 
              <Box
                sx={{
                  // display: "flex",
                  justifyContent: "space-between",
                  marginTop: "31px",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                  rowGap: { xs: "15px" },
                  display:{xs:"none",md:"flex"}
                }}
              >
                {gameImages.map((img, index) => (
                  <Box
                    key={index}
                    // className="menu polygonStyle2"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // background:
                      //   "linear-gradient(180deg, rgba(166, 160, 186, 0.15) 0%, rgba(0, 0, 0, 0.20) 100%)",
                      padding: "2px",
                      width: "168px",
                      position: "relative",
                      filter: index === selectedIndex
                      ? "drop-shadow(white 2px 2px 0px)" 
                      : "drop-shadow(white 0px 0px 0px)",
                      // boxShadow:
                      //   "0px -8.625px 2.156px 0px rgba(235, 235, 235, 0.10) inset, -2.156px 0px 2.156px 0px rgba(235, 235, 235, 0.10) inset, 2.156px 0px 2.156px 0px rgba(235, 235, 235, 0.10) inset",
                      // backdropFilter: "blur(10.781231880187988px)",
                      cursor: "pointer",
                      
                    }}
                  >
                    <img
                      onClick={() => handleImageSelect(index)}
                      draggable="false"
                      src={img.Image}
                      alt={`Game ${index + 1}`}
                      style={{
                        filter:
                          index === selectedIndex
                          ? "drop-shadow(white -2px -2px 0px)"
                          : "drop-shadow(white 0px 0px 0px)"
                      }}
                    />
                  </Box>
                ))}
              </Box>
             
            </Box>
          </Box>
          <Dialog
                  PaperProps={{
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  }}
                  open={isSignInOpen}
                  onClose={handleSignInClose}
                >
                  <DialogContent>
                    <SigninGame handleSignInClose={handleSignInClose}/>
                  </DialogContent>
                </Dialog>
        </ResponsiveBox>
      )}
    </>
  );
};

export default GameDetails;
