import Box from "@mui/material/Box";
import { Typography, Button, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import SimpleSlider from "../../../../src/components/Slider/SimpleSlider";
import { alpha } from "@mui/material";
const ProfileIcon =
  "https://d24m5mu8cxqwfm.cloudfront.net/images/profile_icon_nft.svg";

const NftSection = (props: any) => {
  {
    console.log("NftSection", props);
  }
  const isMobile = useMediaQuery("(max-width:600px)");
  const {
    heading = "",
    listData = [],
    cardclick = {},
    category = "",
  } = { ...props };
  console.log(heading, listData, cardclick, category);
  const navigate = useNavigate();

  const handleViewAll = (caetgory: string) => {
    navigate("/categoryNft", { state: { category: caetgory } });
  };

  const handleCardClick = (data: any) => {
    // console.log("data",data)
    navigate(`/nftDetail/${data?.nft?._id}`);
  };
  console.log("list data", listData);
  const isDesktop = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1535px)"
  );

  return (
    <>
      {isMobile ? (
        <SimpleSlider category="feature" data={listData} heading={heading} />
      ) : (
        <Box
          sx={{
            mt: "80px",
            width: { xs: "100%", md: "100%" },
            backgroundColor: alpha("#E0E0E0", 0.12),
            borderRadius: 10,
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            padding: "40px 50px",
          }}
        >
          <Box
            sx={{
              // display: "flex",
              // justifyContent: "center",
              width: "inherit",
            }}
          >
            <Box
              sx={{
                // mt: 8,
                // mb: 8,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // border: "1px solid white",
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  color: "white",
                  fontSize: "2rem",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  fontFamily: "outfit",
                  mb: 4,
                }}
              >
                {heading}
              </Typography>
              <Grid container spacing={3}>
                {listData.slice(0, isDesktop ? 4 : 5).map((data: any) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          borderRadius: 5,
                          background: "#181818",
                          height: "100%",
                          // width: { md: "260px" },
                          cursor: "pointer",
                        }}
                        onClick={() => handleCardClick(data)}
                      >
                        <Box
                          sx={{
                            margin: 0,
                            padding: 0,
                            // width:{lg:"230px",md:"100%"}
                          }}
                        >
                          <img
                            src={
                              data.imageUrl ? data.imageUrl : data.nft.imageUrl
                            }
                            draggable="false"
                            alt="image"
                            height={"218px"}
                            width={"100%"}
                            style={{
                              borderRadius: "20px 20px 0px 0px",
                              objectFit: "cover",
                              objectPosition: "0 0",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            marginTop: 0,
                            display: "flex",
                            flexDirection: "column",
                            p: 4,
                            pt: 1,
                          }}
                        >
                          <Typography
                            textAlign="left"
                            sx={{
                              fontFamily: "outfit",
                              fontWeight: "600",
                              overflow: "hidden",
                              width: { lg: "150px", xl: "200px" },
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data.nftName ? data.nftName : data.nft.nftName}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            gap={1}
                            mt={2}
                          >
                            <Box display="flex">
                              <img
                                draggable="false"
                                src={
                                  data?.user?.profilePic
                                    ? data?.user?.profilePic
                                    : ProfileIcon
                                }
                                alt="img"
                                height={"100%"}
                                width={"100%"}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                }}
                              />
                            </Box>

                            <Typography sx={{ fontFamily: "outfit" }}>
                              {data?.user?.fullName
                                ? data?.user?.fullName
                                : data?.user?.walletAddress.slice(0, 6) +
                                  "...." +
                                  data?.user?.walletAddress.slice(-6)}
                            </Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            mt={2}
                          >
                            <Typography
                              sx={{
                                color: "#858584",
                                fontSize: "0.8rem",
                                fontWeight: 400,
                                fontFamily: "outfit",
                              }}
                            >
                              Price
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "0.8rem",
                                fontWeight: 400,
                                fontFamily: "outfit",
                              }}
                            >
                              {data?.listings?.listingPrice
                                ? Number(data?.listings?.listingPrice).toFixed(
                                    4
                                  )
                                : Number(data?.listingPrice).toFixed(4)}{" "}
                              Voeux
                            </Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            mt={1}
                          >
                            <Typography
                              sx={{
                                color: "#858584",
                                fontSize: "0.8rem",
                                fontWeight: 400,
                                fontFamily: "outfit",
                              }}
                            >
                              Supply
                            </Typography>
                            <Typography
                              sx={{
                                color: "#FFF",
                                fontSize: "0.8rem",
                                fontWeight: 400,
                                fontFamily: "outfit",
                              }}
                            >
                              {data?.supply ? data?.supply : data?.nft?.supply}
                              {/* {data.supply} */}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>

              {/* ======================= */}

              {/* ========================= */}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                //   border="1px solid white"
              >
                <Button
                  variant="contained"
                  onClick={() => handleViewAll(category)}
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: 3,
                    paddingLeft: 8,
                    paddingRight: 8,
                    mt: "40px",
                    background:
                      "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                    fontSize: {
                      xs: 12,
                      sm: 12,
                      md: 12,
                      lg: 18,
                    },
                  }}
                >
                  View All
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {/* } */}
    </>
  );
};

export default NftSection;
