// StepOne.tsx
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import { Environment } from "../../../environments";
import axios from "axios";
const StepOne = ({
  getCurrencyType,
  handleNext,
  handleShowLoader,
  showToastErrorMessageClick,
}: {
  getCurrencyType: (data: any) => void;
  handleNext: () => void;
  handleShowLoader: (active: any) => void;
  showToastErrorMessageClick: (text: string) => void;
}) => {
  const [currencyValue, setCurrencyValue] = useState("VND");
  const handleCurrencyChnage = (e: any) => {
    console.log("value", e.target.value);
    setCurrencyValue(e.target.value);
    getCurrencyType(e.target.value);
  };

  useEffect(() => {
    handleShowLoader(true);
    getAllCurrecny();
  }, []);
  const [currency, setCurrency] = useState<any>([]);
  const getAllCurrecny = async () => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}currency`
      );
      const currencies = response.data?.map((currency: any) => {
        return {
          currencyName: currency.currency,
          services: currency.services,
          currencyID: currency._id,
        };
      });
      setCurrency(currencies);
      setTimeout(() => {
        handleShowLoader(false);
      }, 200);
    } catch (error) {
      console.log("error", error);
      showToastErrorMessageClick("Failed in Currency API");
      handleShowLoader(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            height: "316.79px",
            width: "551px",
            borderRadius: "18.34px",
            background: "#181818",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Typography
                sx={{
                  fontFamily: "Bebas Neue",
                  fontSize: "32.10px",
                  fontWeight: "400",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  color: "white",
                  marginTop: 5,
                  textTransform: "uppercase",
                }}
              >
                Select a currency
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "15px",
              padding: "20px",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "outfit",
                fontSize: "12.23px",
                color: "#fff",
                marginBottom: "10px",
                flex: 1,
              }}
            >
              Select a Currency
            </Typography>
            <FormControl
              sx={{
                minWidth: "100%",
                flex: 1,
                border: "1px solid #fff",
                borderRadius: "7px",
              }}
              size="small"
            >
              <Select
                value={currencyValue}
                sx={{ color: "#fff" }}
                IconComponent={() => (
                  <IconButton disableRipple style={{ color: "#fff" }}>
                    <ArrowDropDownIcon />
                  </IconButton>
                )}
                onChange={(e) => handleCurrencyChnage(e)}
              >
                {currency.map((x: any) => (
                  <MenuItem
                    sx={{ fontFamily: "outfit", color: "#black" }}
                    value={x.currencyName}
                  >
                    {x.currencyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              sx={{
                fontFamily: "Bebas Neue",
                fontSize: "18px",
                textTransform: "uppercase",
                background: "#FF990D",
                width: "100%",
                color: "#fff",
                "&:hover": {
                  background: "#FF990D",
                  color: "#fff",
                },
              }}
              onClick={() => handleNext()}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StepOne;
