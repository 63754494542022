// export const registerFarmAddress = "0xf31e6C840eA4e50fD05C4964d47693BfbCE37801"
// export const registerFarmAddress = "0x4cf074beeacf4638a63a92fac6d222c9e4c6f83d"
export const registerFarmAddress = "0xFc77bed704Fd36053f6C9A6A8b7f77DC3e61bb5E"
//contract address of staking farm = " 0x9C2eE079634050f84475CaD49B45f5A917ACEc97"
// export const registerFarmAddress = ""

  // walletAddress = 0xA8bf4d5ee912CD8a1fa104fc524D3E0D2Ec9B107, 0x07ab7bb7439b1851b0f06723ea83f374d72f7600

export const registerFarmAbi = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "string",
        "name": "farmName",
        "type": "string"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "investmentRequirement",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "managerAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "farmAddress",
        "type": "address"
      }
    ],
    "name": "NewFarmRegisteration",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenCount",
        "type": "uint256"
      }
    ],
    "name": "NewStake",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "tokenCount",
        "type": "uint256"
      }
    ],
    "name": "NewUnstake",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      }
    ],
    "name": "checkTokenAllowedStatus",
    "outputs": [
      {
        "internalType": "enum VoeuxFarmRegistry.TokenStatus",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAdminAddress",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getCountOfTotalFarmRegistered",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      }
    ],
    "name": "getFarmAdvanceDetails",
    "outputs": [
      {
        "internalType": "enum VoeuxFarmRegistry.Status",
        "name": "status",
        "type": "uint8"
      },
      {
        "internalType": "uint256",
        "name": "maxPhase",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "manager",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "totalInvestment",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "apy",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "farmAddress",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      }
    ],
    "name": "getFarmBasicDetails",
    "outputs": [
      {
        "internalType": "string",
        "name": "name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "description",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "investmentRequired",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "investmentTokenInvestment",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "phaseId",
        "type": "uint256"
      }
    ],
    "name": "getFarmPhaseDetails",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "priority",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "detail",
            "type": "string"
          },
          {
            "internalType": "enum VoeuxFarmRegistry.Status",
            "name": "status",
            "type": "uint8"
          }
        ],
        "internalType": "struct VoeuxFarmRegistry.Phase",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "_name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "_description",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "_investmentRequired",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_tokenAddress",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "priority",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "detail",
            "type": "string"
          },
          {
            "internalType": "enum VoeuxFarmRegistry.Status",
            "name": "status",
            "type": "uint8"
          }
        ],
        "internalType": "struct VoeuxFarmRegistry.Phase[]",
        "name": "_phases",
        "type": "tuple[]"
      },
      {
        "internalType": "address",
        "name": "_manager",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_apy",
        "type": "uint256"
      }
    ],
    "name": "registerFarm",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      }
    ],
    "name": "tokenAllowed",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      },
      {
        "internalType": "string",
        "name": "_description",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "_investmentRequired",
        "type": "uint256"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "priority",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "detail",
            "type": "string"
          },
          {
            "internalType": "enum VoeuxFarmRegistry.Status",
            "name": "status",
            "type": "uint8"
          }
        ],
        "internalType": "struct VoeuxFarmRegistry.Phase[]",
        "name": "_phases",
        "type": "tuple[]"
      },
      {
        "internalType": "uint256",
        "name": "_apy",
        "type": "uint256"
      }
    ],
    "name": "updateFarm",
    "outputs": [
      {
        "internalType": "string",
        "name": "name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "description",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "investmentRequired",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "investmentTokenInvestment",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "phaseId",
        "type": "uint256"
      },
      {
        "internalType": "enum VoeuxFarmRegistry.Status",
        "name": "status",
        "type": "uint8"
      }
    ],
    "name": "updateFarmPhaseStatus",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "priority",
            "type": "uint256"
          },
          {
            "internalType": "string",
            "name": "name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "detail",
            "type": "string"
          },
          {
            "internalType": "enum VoeuxFarmRegistry.Status",
            "name": "status",
            "type": "uint8"
          }
        ],
        "internalType": "struct VoeuxFarmRegistry.Phase",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "farmId",
        "type": "uint256"
      },
      {
        "internalType": "enum VoeuxFarmRegistry.Status",
        "name": "status",
        "type": "uint8"
      }
    ],
    "name": "updateFarmStatus",
    "outputs": [
      {
        "internalType": "enum VoeuxFarmRegistry.Status",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]