import { Snackbar, Alert } from "@mui/material"

const ToastMessage = ({text,display,handleClose}:{text:string,display:boolean,handleClose:()=>void}) => {
return (
    <Snackbar 
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={display} autoHideDuration={2000} onClose={handleClose}>
  <Alert
    onClose={handleClose}
    severity="success"
    variant="filled"
    sx={{ width: '100%' }}
  >
    {text}
  </Alert>
</Snackbar>
)
}

export default ToastMessage