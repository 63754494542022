import {
 Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

interface TableData {
  name: string;
  logo: string;
  symbol: string;
  price: string;
  priceChange24hrs: number;
  priceChange7days: number;
  marketCap: number;
}

interface BasicTableProps {
  tableData: TableData[]; // Change from TableData to TableData[]
}

const tableButtons = [
  "Top Tokens",
  "Top NFT Collection",
  "Top Dapps",
  "Top Defi Projects",
];

const borderGradient = {
  background:
    "linear-gradient(#0C0B0F 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
  border: "2px solid transparent",
  borderRadius: { xs: "5px", md: "2px" },
  cursor: "pointer",
  padding: { xs: "0%  1% 0% 2%", md: ".5% 1% .5% 1%" },
  marginRight: { md: "20px" },
  backgroundColor: "#0C0B0F",
};
const hoverborderGradient = {
  background:
    "linear-gradient(#0C0B0F 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
  border: "2px solid transparent",
  borderRadius: { xs: "5px", md: "2px" },
  cursor: "pointer",
  padding: { xs: "0%  1% 0% 2%", md: ".5% 1% .5% 1%" },
  marginRight: { md: "20px" },
  backgroundColor: "#0C0B0F",
};

const defaultButtonStyle = {
  border: "none",

  cursor: "pointer",
  padding: { xs: "0%  1% 0% 2%", md: "1% 2% 1% 1%" },
  marginRight: { md: "20px" },
  // backgroundColor: "#232323",
};
interface NftList {
  Title?: string;
  img?: string;
  floorPrice?: number;
  price?: string;
  floorPriceChange24hrs?: number;
  marketCap?: number;
  marketCapChange24hrs?: number;
  volume?: number;
  volumeChange24hrs?: number;
  usdPrice?: string;
  quantity?: number;
  FloorDifference?: string;
  Expiration?: string;
  From?: string;
  event?: string;
  to?: string;
  date?: string;
user?:string
Email?:string
"Offered Price"?:string
"Created At"?:string
"NFT Name"?:string
"Original Price"?:string,
"Delete"?:Boolean
}

export default function NFTTable({
  nftList,
  numberList,
  handleCancelOffer = ()=>{}
   
}: {
  handleCancelOffer?: (data:any,rowindex:Number)=>void;
  nftList: NftList[];
  numberList: Boolean;
}) {
  const [selectedTable, setSelectedTable] = useState("Top Tokens");
  const [headers, setHeaders] = useState<string[]>([]);
  const getHeaders = (data: any) => {
    console.log(data);
    console.log("keys",Object.keys(data[0]));
    setHeaders(Object.keys(data[0]));
    // Object.keys(data);
  };
  useEffect(() => {
    getHeaders(nftList);
    console.log("headers",headers)
    console.log(numberList);
  },[]);
  const buttonBorder = {
    display: "flex",
    height: "25.889px",
    padding: "3.883px 6.472px",
    justifyContent: "center",
    alignItems: "center",
    gap: "2.589px",
    flexShrink: 0,
    borderRadius: "5.178px",
    border: "0.968px solid  #FF990D",
  };

  const buttontext = {
    color: "#FFF",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "7.767px",
  };

  const circleStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "40%",
    transform: "translateY(-30%)",
    width: "450px", // Adjusted width
    height: "200px", // Adjusted height
    flexShrink: 0,
    borderRadius: "50%",
    background: "#9D00FF",
    filter: "blur(200px)",
    backdropFilter:"blur(200px)",
    WebkitBackdropFilter:"blur(200px)",
    zIndex: -1,
  };
{console.log("nftList",nftList)}

 

  return (
    <>
    
      <TableContainer
        sx={{ backgroundColor: "transparent", position: "relative" }}
      >
        {/* <Stack
        sx={{ ...buttontext, display: "flex", flexDirection: "row", mb: 3 }}
        pl={0}
      >
        {tableButtons.map((but) => (
          <Button
            key={but}
            variant="outlined"
            sx={{
              ...(selectedTable === but ? borderGradient : defaultButtonStyle),
              borderRadius: selectedTable === but ? "8px" : "none",
              color: "#FFF",
              whiteSpace: { xs: "initial", sm: "nowrap" }, // Allow wrapping on smaller screens
              px: { xs: 1 },
              ":hover": {
                ...hoverborderGradient,
                borderRadius: "8px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", sm: "16px" },
                mr: { xs: 1, sm: 2 },
                ml: { xs: 1, sm: 2 },
              }}
              onClick={() => {
                setSelectedTable(but);
              }}
            >
              {but}
            </Typography>
          </Button>
        ))}
      </Stack> */}
        <div style={circleStyle}></div>

        <Table sx={{ minWidth: 650, border: "none" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {numberList && (
                <TableCell align="center" sx={{ color: "white" }}>
                  #
                </TableCell>
              )}
              {headers.map((item, index) => (
                <TableCell align="center" sx={{ color: "white" }}>
                 {item !== "Delete" ? item :""} 
                </TableCell>
              ))}

              {/* Add more TableHead cells as needed */}
            </TableRow>
          </TableHead>
          <TableBody>

          {nftList.map((row:any, rowindex) => (
            <TableRow key={row.Title}>
                 {numberList && (
                  <TableCell
                    align="center"
                    sx={{
                      color: "white",
                      borderBottom: "1px solid rgba(185, 190, 200, 0.5)",
                    }}
                  >
                    {rowindex + 1}
                  </TableCell>
                )}
              { headers.map((item:any, headerindex) => (
                <>
              
                 <TableCell align="center" sx={{ color: "white" }}>
                  {item == "Delete" ?   <Button
                  onClick={() => handleCancelOffer(row,rowindex)}
                      sx={{
                        padding:"10px 22px",
                        margin: "0.5rem",
                        borderRadius: "10px",
                        backgroundColor: "#FF990D",
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: "16px",
                        textTransform: "none",
                        "&:hover":{
                          backgroundColor: "#FF990D",
                          color: "white",
                        }
                        
                      }}
                      // disabled={nftDetailResponse.sold ?  true : false}
                      // onClick={() => setShowBuyNowDialog(true)}
                      // onClick={handleBuyNow}
                    >
                     Delete Offer
                    </Button>: row[item]
                    }
              </TableCell>
                </>
             
            ))}
            </TableRow>
           
          ))}

           
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
