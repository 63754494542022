import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Coins from "../../assets/VoeuxGame/Coins.png";
import { useNavigate } from "react-router-dom";

const PurchaseSuccessfull = ({
  close,
  points,
  withdrawSuccess,
  walletAddress,
  balance,
  type,
}: {
  close: () => void;
  points?: any;
  withdrawSuccess?: any;
  walletAddress?: any;
  balance?: any;
  type?: any;
}) => {
  console.log("withdrawSuccess", withdrawSuccess, balance);
  const sample = Number(balance);
  const filteredData = sample / 10 ** 18;

  const navigate = useNavigate();
  const handleClose = () => {
    close();
    navigate("/voeuxGame");
  };

  return (
    <>
      <Box
        className="polygonStyle2"
        sx={{ background: "#181818", padding: "80px", textAlign: "center" }}
      >
        <Button
          sx={{
            position: " absolute",
            right: { xs: "5px", md: "8px" },
            fontSize: { xs: "12px", md: "20px" },
            color: "#fff",
            top: "20px",
          }}
          onClick={() => handleClose()}
        >
          X
        </Button>
        <Typography
          className="blendarPro"
          sx={{
            fontSize: "36px",
            color: "#6FCF97",
            fontFamily: " Blender Pro",
            fontWeight: "700px",
            textTransform: "uppercase",
          }}
        >
          {" "}
          purchase successful
        </Typography>
        {type === "buy" ? (
          <Typography
            className="blendarPro"
            sx={{
              fontSize: "24px",
              color: "#fff",
              fontFamily: " Blender Pro",
              fontWeight: "400px",
              textTransform: "uppercase",
            }}
          >
            {" "}
            {points} vx points purchased
          </Typography>
        ) : (
          <Typography
            className="blendarPro"
            sx={{
              fontSize: "24px",
              color: "#fff",
              fontFamily: " Blender Pro",
              fontWeight: "400px",
              textTransform: "uppercase",
            }}
          >
            {" "}
            {points} vx points redeemed
          </Typography>
        )}

        <Typography
          className="blendarPro"
          sx={{
            fontSize: "24px",
            color: "#fff",
            fontFamily: " Blender Pro",
            fontWeight: "400px",
            textTransform: "uppercase",
          }}
        >
          {" "}
          successfully
        </Typography>
        {withdrawSuccess?.data && (
          <Typography
            className="blendarPro"
            sx={{
              fontSize: "24px",
              color: "#fff",
              fontFamily: " Blender Pro",
              fontWeight: "400px",
              textTransform: "uppercase",
            }}
          >
            {" "}
            Current Point{" "}
            {withdrawSuccess?.data?.Balance
              ? withdrawSuccess?.data?.Balance
              : withdrawSuccess?.data?.data?.Balance}
          </Typography>
        )}

        {filteredData ? (
          <Typography
            className="blendarPro"
            sx={{
              fontSize: "24px",
              color: "#fff",
              fontFamily: " Blender Pro",
              fontWeight: "400px",
              textTransform: "uppercase",
            }}
          >
            {" "}
            Current Wallet Points {filteredData}
          </Typography>
        ) : (
          ""
        )}

        <img src={Coins}alt="" />
      </Box>
    </>
  );
};
export default PurchaseSuccessfull;
