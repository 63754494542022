const env = {
  prod: {
    metavoeuxBackend: "https://metavoeuxserver.com/prod/",
  },

  testing: {
    metavoeuxBackend: "https://metavoeuxserver.com/qc/",
  },

  local: {
    metavoeuxBackend: "http://localhost:8000/",
  },
};

export const Environment = env.testing;
