//Overview.tsx
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import styles from "./NftLanding.module.css";
import overviewimage from "../../assets/images/overview.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteBorderTwoToneIcon from "@mui/icons-material/FavoriteBorderTwoTone";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import NftCollectionLiveCard from "./NftCollectionLiveCard";
import NFTTable from "../../components/nftTable/NFTTable";
import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import MuiWalletConnect from "../../components/muiconnectwallet/Muiconnect";
import Purchase from "../../pages/nftLanding/nftCreate/Purchase";
import InSufficientFunds from "../../pages/nftLanding/nftCreate/InSufficeint";
import NftMakeAnOffer from "./nftSections/NftMakeAnOffer";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import ToastMessageFailure from "../../components/ToastMessage/ToastMessageFailure";
import ToastMessageInformation from "../../components/ToastMessage/ToastMessageInformation";
import { MarketplaceService } from "src/services/marketplaceService";
import { Environment } from "../../environments";
import { TokenService } from "src/services/tokenService";
import { MARKETPLACE_CONTRACT_ADDRESS } from "src/constants/ABIs/marketPlaceABI";
import SimpleSlider from "../../components/Slider/SimpleSlider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function NftDetailPage() {
  let { nftid } = useParams();
  console.log("use parms", nftid);
  const [nftId, setNftId] = useState(nftid);
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: -1,
    flexShrink: 0,
  };
  const borderGradientBox = {
    background:
      "linear-gradient(#272727 0 0) padding-box, linear-gradient(102deg, #ff990d 7.51%, #9d00ff 86.21%) border-box",
    border: "2px solid transparent",
    borderRadius: "10px",

    backgroundColor: "#272727",
  };
  const GradientBox = {
    borderRadius: "12px",
    transition: "border-radius 0.3s",
    fontFamily: "outfit",
  };
  const [value, setValue] = React.useState("1");

  const itemactivity = [
    {
      event: "Transfer",
      price: " 0.482 POLYGON",
      From: "BozoVault",
      to: "wooott",
      date: "1 years ago",
    },
    {
      event: "Sale",
      price: " 0.482 POLYGON",
      From: "BozoVault",
      to: "wooott",
      date: "1 years ago",
    },
    {
      event: "Sale",
      price: " 0.482 POLYGON",
      From: "BozoVault",
      to: "wooott",
      date: "1 years ago",
    },
    {
      event: "Transfer",
      price: " 0.482 POLYGON",
      From: "BozoVault",
      to: "wooott",
      date: "1 years ago",
    },
    {
      event: "Transfer",
      price: " 0.482 POLYGON",
      From: "BozoVault",
      to: "wooott",
      date: "1 years ago",
    },
  ];
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const defaultMaxWidth = isSmallScreen ? "sm" : "md"; // Set your preferred default maxWidth for small and larger screens

  const [maxWidth, setMaxWidth] =
    React.useState<DialogProps["maxWidth"]>(defaultMaxWidth);

  const [showBuyNowDialog, setShowBuyNowDialog] = useState(false);
  const [showMakeAnOffer, setshowMakeAnOffer] = useState(false);
  const [showToastMessage, setShowToastMessage] = useState<any>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [showErrorToastMessage, setErrorShowToastMessage] =
    useState<any>(false);

  const [showInfoToastMessage, setShowInfoMessage] = useState<any>(false);
  const [toastInfoMessage, setToastInfoMessage] = useState<string>("");

  const [toastErrorMessage, setToastErrorMessage] = useState<string>("");
  const [showLoader, setShowLoader] = useState(true);
  const [nftDetailResponse, setNftDetailResponse] = useState<any>({});
  const [nftOfferTable, setNftOfferTable] = useState<any>([]);
  const [moreLikeCollection, setMoreLikeCollection] = useState<any>([]);
  const [moreLikeCollectionMobile, setMoreLikeCollectionMobile] = useState<any>(
    []
  );
  const [showPurchase, setShowPurchase] = useState<boolean>(false);
  const [showInSufficient, setShowInSufficient] = useState<boolean>(false);

  const [tokenService, setTokenService] = useState<TokenService>();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setShowBuyNowDialog(false);
  };
  const handleMakeOfferClose = () => {
    setshowMakeAnOffer(false);
  };

  const handleBuyNowClose = () => {
    setShowPurchase(false);
  };

  const handleSufficentClose = () => {
    setShowInSufficient(false);
  };

  const Close = () => {
    setShowBuyNowDialog(false);
  };

  const localUserId = localStorage.getItem("userId");
  const callBuyNowAPi = async () => {
    handleShowLoader(true);
    setTimeout(async () => {
      handleShowLoader(false);
      setShowPurchase(true);
      try {
        const response = await axios.post(
          `${Environment.metavoeuxBackend}listingnft/buy`,
          {
            user: localUserId,
            nft: nftDetailResponse.nftID,
            listingNFtId: nftDetailResponse.listingNftID,
          }
        );
        handleShowLoader(false);
        console.log("response", response);
      } catch (error) {
        handleShowLoader(false);
        showErrorToastMessageClick("Failed in buying NFT");
        console.log("error", error);
      }
    }, 2000);
  };

  const handleBack = () => {
    navigate(-1);
  };
  const navigate = useNavigate();

  const handleMoreLikeNFtClick = (nftID: any) => {
    callNftDetails(nftID).then(() => {
      callMoreCollection(nftID);
      callNftOfferList(nftID);
    });
    navigate(`/nftDetail/${nftid}`);
  };
  const localWalletAddress = localStorage.getItem("account_address");
  const callNftDetails = async (id: any) => {
    try {
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/${id}`
      );
      // buidling own object based on response

      console.log("response callNftDetails", response);
      nftDetailResponse.nftName = response.data.nftData.nftName;
      nftDetailResponse.ownedBy = response.data.nftData.currentOwner.fullName;
      nftDetailResponse.walletAddress =
        response.data.nftData.currentOwner.walletAddress.toString();
      nftDetailResponse.price = response.data.listingNftData?.listingPrice;
      nftDetailResponse.description = response.data.nftData.description;
      nftDetailResponse.supply = response.data.nftData.supply;
      nftDetailResponse.collectionAddress =
        response.data.nftData.collectionId.collectionAddress;
      nftDetailResponse.updatedAt = response.data.nftData.updatedAt;
      nftDetailResponse.creatorFee = response.data.nftData?.creatorFee;
      nftDetailResponse.traits =
        response.data.nftData.traits.length > 0
          ? response.data.nftData.traits
          : undefined;
      nftDetailResponse.collectionID = response.data.nftData.collectionId._id;
      nftDetailResponse.nftID = response.data.nftData._id;
      nftDetailResponse.tokenId = response.data.nftData.tokenId;
      nftDetailResponse.listingNftID = response.data.listingNftData?._id;
      nftDetailResponse.sold = response.data.listingNftData?.isSold;
      nftDetailResponse.userID = response.data.nftData.currentOwner._id;
      nftDetailResponse.imageUrl = response.data.nftData.imageUrl;
      nftDetailResponse.collectionName =
        response.data.nftData.collectionId.contractName;

      nftDetailResponse.ownerAddress = response.data.nftData.owners.find(
        (owner: any) => {
          if (owner.ownerId === nftDetailResponse.ownedBy) {
            return owner.ownerAddress;
          }
        }
      );
      console.log("nftDetailResponse", nftDetailResponse);

      setNftDetailResponse({ ...nftDetailResponse });
      // setCollectionId(nftDetailResponse.collectionID);
    } catch (error) {
      showErrorToastMessageClick("Error in fetching the NFT details. ");
      console.error("Error fetching data:", error);
    }
  };

  const [offerId, setOfferID] = useState("");
  const [offerUser, setOfferUser] = useState("");
  const callNftOfferList = async (nftID: any) => {
    try {
      console.log("environment", `${Environment.metavoeuxBackend}`);
      const response = await axios.get(
        `${Environment.metavoeuxBackend}makeoffer/nftoffer/${nftID}`
      );
      console.log("offer list for NFT", response.data);
      let NftOffer: any = response.data
        .filter((data: any) => data.isCancelled == false)
        .map((offerDetails: any) => ({
          user: offerDetails.user.fullName
            ? offerDetails.user.fullName
            : offerDetails.user.walletAddress.slice(0, 6),
          Email: offerDetails.user.email,
          "Offered Price": offerDetails.offeredPrice,
          "Created At": offerDetails.createdAt,
        }));
      setOfferID(response.data[0]?._id);
      console.log("NftOffer", NftOffer);
      setOfferUser(response.data[0]?.user?._id);
      setNftOfferTable(NftOffer);
    } catch (error) {
      showErrorToastMessageClick("Error in fetching the NFT offer.");
      console.error("Error fetching data:", error);
    }
  };

  const callMoreCollection = async (nftID: any) => {
    try {
      console.log("nft detail response, call more", nftDetailResponse);
      const response = await axios.get(
        `${Environment.metavoeuxBackend}nft/collectionotherNft?Collectionid=${nftDetailResponse.collectionID}&nftId=${nftID}`
      );
      console.log("more like this collection", response);
      const moreLikeCollection = response.data.map((data: any) => {
        return {
          imageURL: data.nft.imageUrl,
          nftName: data.nft.nftName,
          price: data.listing.listingPrice,
          supply: data.nft.supply,
          nftID: data.nft._id,
        };
      });
      setMoreLikeCollectionMobile(response.data);
      setMoreLikeCollection(moreLikeCollection);
    } catch (error) {
      showErrorToastMessageClick("Failed in rendering the similar NFT");
      console.error("Error fetching data:", error);
    }
  };

  const callAllApi = async () => {
    callNftDetails(nftId).then(() => {
      callNftOfferList(nftId);
      handleMoreCollection();
      // setTimeout(() => {
      //   checkNFTIsListed();
      // },1000)
    });
    console.log("2");
  };

  const handleMoreCollection = async () => {
    await callMoreCollection(nftId);
    handleShowLoader(false);
  };

  const checkNFTIsListed = async () => {
    await isListed();
  };

  useEffect(() => {
    handleShowLoader(true);
    console.log("1");
    callAllApi();
  }, []);
  const [loaderText, setLoaderText] = useState("");

  const handleShowLoader = (active: boolean) => {
    setShowLoader(active);
  };

  const closeToastMessage = () => {
    setShowToastMessage(false);
  };

  const closeToastErrorMessage = () => {
    setErrorShowToastMessage(false);
  };

  const closeToastInfoMessage = () => {
    setShowInfoMessage(false);
  };

  const showToastInfoMessageClick = (text: string) => {
    setShowInfoMessage(true);
    setToastInfoMessage(text);
  };

  const showToastMessageClick = (text: string) => {
    setShowToastMessage(true);
    setToastMessage(text);
  };

  const showErrorToastMessageClick = (text: string) => {
    setErrorShowToastMessage(true);
    setToastErrorMessage(text);
  };

  const handleMakeApiSuccess = () => {
    showToastMessageClick("Make best offer is created");
    callNftOfferList(nftDetailResponse.nftID);
  };

  const [marketplaceService, setMarketplaceService] =
    useState<MarketplaceService>();

  useEffect(() => {
    const inst = new MarketplaceService();
    const intervalId = setInterval(() => {
      if (
        inst.marketplaceInstance.contractInstance != null &&
        inst.marketplaceInstance.contractInstance != undefined
      ) {
        setMarketplaceService(inst);
        clearInterval(intervalId);
      }
    }, 100);

    const tokenSerInst = new TokenService();
    const intvId = setInterval(() => {
      if (
        tokenSerInst.tokenInstance.contractInstance != null &&
        tokenSerInst.tokenInstance.contractInstance != undefined
      ) {
        setTokenService(tokenSerInst);
        clearInterval(intvId);
      }
    }, 100);
  }, []);

  useEffect(() => {
    console.log(
      "detail --> ",
      marketplaceService?.marketplaceInstance?.contractInstance
    );
    if (
      nftDetailResponse &&
      marketplaceService?.marketplaceInstance?.contractInstance !== undefined
    ) {
      setTimeout(() => {
        checkNFTIsListed();
      }, 1000);
    }
  }, [marketplaceService, nftDetailResponse]);

  const getApproval = async (amount = nftDetailResponse?.price) => {
    const approval = await tokenService?.approveTransaction(
      MARKETPLACE_CONTRACT_ADDRESS,
      amount
    );
    console.log("approval for buy nft", approval);
    return approval;
  };

  const buyNow = async () => {
    // const account_address = localStorage.getItem("account_address");
    // if (!account_address) {
    //   setShowBuyNowDialog(true);
    //   return;
    // }
    handleShowLoader(true);
    console.log("In buy now");
    console.log("marketplaceService", marketplaceService);
    console.log("nftDetailResponse", nftDetailResponse);
    const buyData = {
      collectionAdd: nftDetailResponse.collectionAddress,
      tokenId: nftDetailResponse.tokenId,
      price: nftDetailResponse.price * 10 ** 9,
    };

    try {
      const res = await marketplaceService?.buyNft(
        [buyData.collectionAdd],
        [buyData.tokenId],
        buyData.price
      );
      // handleBuyNowButton()
      handleShowLoader(false);
      callBuyNowAPi();
    } catch (error) {
      handleShowLoader(false);
      showErrorToastMessageClick("Failed in Buying NFT");
      // throw error;
    }
  };

  const [disableButtons, setDisableButton] = useState(false);
  const isListed = async () => {
    try {
      console.log("marketplaceService", marketplaceService);
      const listed = await marketplaceService?.isNftListed(
        nftDetailResponse.collectionAddress,
        nftDetailResponse.tokenId
      );
      console.log("ask detail==>", listed);
      if (listed?.exists) {
        setDisableButton(false);
        return true;
      } else {
        showToastInfoMessageClick("NFT is not listed for sale");
        setDisableButton(true);
        // alert("Nft not listed for sale");
        return false;
      }
    } catch (error) {
      console.log("isListed:", error);
      showToastInfoMessageClick("Failed in check for NFT List status");
      setDisableButton(true);
      return false;
    }
  };

  const handleRedirectbuyNow = () => {
    if (clickMakeOffer) {
      setshowMakeAnOffer(true);
    } else {
      handleBuyNow();
    }
  };
  const handleBuyNow = async () => {
    setClickMakeOffer(false);
    const account_address = localStorage.getItem("account_address");
    if (!account_address) {
      setShowBuyNowDialog(true);
      return;
    }
    handleShowLoader(true);
    const walletAddress = localStorage.getItem("account_address") || "";
    if (walletAddress !== "") {
      let userBalance = await tokenService?.getbalance(walletAddress);

      const newUserBal = BigInt(userBalance ? userBalance.toString() : 0);
      const nftPrice = BigInt(
        BigInt(nftDetailResponse.price) * BigInt(10) ** BigInt(8)
      );
      if (newUserBal >= nftPrice) {
        try {
          const aprroval = await getApproval();
          setTimeout(async () => {
            await buyNow();
          }, 10000);
        } catch (error: any) {
          handleShowLoader(false);
          showToastInfoMessageClick("User denied transaction signature");
        }
      } else {
        setShowInSufficient(true);
        handleShowLoader(false);
      }
    }
  };

  const [clickMakeOffer, setClickMakeOffer] = useState(false);
  const handleMakeanOffer = () => {
    const account_address = localStorage.getItem("account_address");
    setClickMakeOffer(true);
    if (!account_address) {
      setShowBuyNowDialog(true);
      return;
    }
    setshowMakeAnOffer(true);
  };

  const createOffer = async (offerPrice: number) => {
    try {
      const nftAddress = nftDetailResponse.collectionAddress;
      const tokenId = nftDetailResponse.tokenId;
      const newPrice = offerPrice * 10 ** 9;
      const aprroval = await getApproval(offerPrice);

      const resp = await marketplaceService?.makeOffer(
        [nftAddress],
        [tokenId],
        [newPrice]
      );
      console.log("created offer response: ", resp);
    } catch (err) {
      showErrorToastMessageClick("Error in Making a new offer to the NFT");
      return false;
    }
  };

  const handleCancelOffer = async () => {
    console.log("in cancel offer");
    try {
      const nftAddress = nftDetailResponse.collectionAddress;
      const tokenId = nftDetailResponse.tokenId;
      const cancelResp = await marketplaceService?.cancelOffer(
        [nftAddress],
        [tokenId]
      );
    } catch (err) {
      showErrorToastMessageClick("Error in cancel the offer.");

      // throw err;
    }
  };

  const [offerExists, setOfferExists] = useState<boolean>();
  const checkOffer = async () => {
    try {
      const nftAddress = nftDetailResponse?.collectionAddress;
      const tokenId = nftDetailResponse?.tokenId;
      const offerResp = await marketplaceService?.getOffer(nftAddress, tokenId);
      // const askResp = await marketplaceService?.isNftListed(nftAddress, tokenId);
      console.log("offer Response", offerResp);
      if (offerResp?.exists) {
        setOfferExists(true);
        nftDetailResponse.MaxOfferPrice = Number(offerResp.price) / 10 ** 9;
        setNftDetailResponse({ ...nftDetailResponse });
      } else {
        setOfferExists(false);
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  useEffect(() => {
    if (
      nftDetailResponse?.walletAddress?.toLowerCase() ===
      localWalletAddress?.toLowerCase()
    ) {
      checkOffer();
    }
  }, [marketplaceService]);

  const handleAcceptAPi = async () => {
    try {
      const response = await axios.post(
        `${Environment.metavoeuxBackend}makeoffer/accept-offer`,
        {
          // userId: nftDetailResponse.userID,
          userId: offerUser,
          nftId: nftDetailResponse.nftID,
          listingNFtId: nftDetailResponse.listingNftID,
          offerId: offerId,
        }
      );
      console.log("response", response);
      handleShowLoader(false);
      setShowPurchase(true);
    } catch (error) {
      handleShowLoader(false);
      showErrorToastMessageClick("Error Failed in Accepting the Offer");
    }
  };
  const handleAcceptOffer = async () => {
    handleShowLoader(true);
    const nftAddress = nftDetailResponse?.collectionAddress;
    const tokenId = nftDetailResponse?.tokenId;
    console.log(
      "handleAcceptOffer==>",
      marketplaceService?.marketplaceInstance?.contractInstance
    );
    try {
      const acceptOffer = await marketplaceService?.acceptOffer(
        [nftAddress],
        [tokenId]
      );
      console.log("accept offer response", acceptOffer);
      handleAcceptAPi();
    } catch (err) {
      // throw err;
      showErrorToastMessageClick("Error Failed in accepting the offer");
      handleShowLoader(false);
    }
  };

  // useEffect(()=>{
  //   console.log("check condition if wallet address equal", nftDetailResponse?.walletAddress?.toLowerCase() === localWalletAddress, "addresses ==>", nftDetailResponse?.walletAddress?.toLowerCase(), localWalletAddress)
  // })
  const isMobile = useMediaQuery("(max-width:600px)");

  const [showFullTooltip, setShowFullTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");

  useEffect(() => {
    if (nftDetailResponse["nftName"]?.length > 18) {
      setTooltipContent(nftDetailResponse["nftName"]);
    } else {
      setTooltipContent("");
    }
  }, [nftDetailResponse]);

  return (
    <>
      {showLoader && (
        <Box
          sx={{
            position: "fixed",
            background: "#000000c9",
            width: "100%",
            height: "100%",
            zIndex: 1400,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent />
        </Box>
      )}
      <ToastMessage
        text={toastMessage}
        display={showToastMessage}
        handleClose={closeToastMessage}
      />
      <ToastMessageFailure
        text={toastErrorMessage}
        display={showErrorToastMessage}
        handleClose={closeToastErrorMessage}
      />
      <ToastMessageInformation
        text={toastInfoMessage}
        display={showInfoToastMessage}
        handleClose={closeToastInfoMessage}
      />
      <Box
        className={styles.nftLanding}
        sx={{
          px: { xs: 1, sm: 3, md: 5 },
          pt: 0,
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },

          pb: "30px",
          position: "relative",
          zIndex: 0,
        }}
      >
        <NewNftHeader nftHeader={true} />
        <Box sx={{ display: "flex", marginBottom: "10px", cursor: "pointer" }}>
          <ArrowBackIosIcon onClick={() => handleBack()} />
          <Typography sx={{ fontFamily: "outfit" }}> Back</Typography>
        </Box>
        <Stack
          sx={{
            zIndex: 0,
            alignItems: { md: "center", xs: "center" },
            width: { lg: "80%", md: "100%" },
            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              ...circleStyle,
              top: { xl: "50%", lg: "50%" },
              left: "-0px",
              transform: "translateY(-50%)",
              width: "350px", // Adjusted width
              height: "200px", // Adjusted height
              borderRadius: "50%", // Ensures it remains a perfect circle
              background: "#9D00FF",
              // background: "red",
              filter: "blur(140px)",
              backdropFilter: "blur(140px)",
              WebkitBackdropFilter: "blur(140px)",
              overflow: "hidden",
              "@media (min-width: 1721px)": {
                width: "450px", // Adjusted width
                height: "400px", // Adjusted height
                top: { xl: "50%" },
                left: "-20%",
                filter: "blur(160px)",
                backdropFilter: "blur(160px)",
                WebkitBackdropFilter: "blur(160px)",
              },
            }}
          ></Box>

          <Grid
            container
            spacing={2}
            sx={{
              width: {
                xs: "100%",
                md: "auto",
                marginLeft: { md: "unset", xs: "0" },
              },
            }}
          >
            <Grid item xs={12} sm={6} sx={{ mt: { md: 0 }, width: "100%" }}>
              <Box
                sx={{
                  width: { xs: "auto", lg: "503px" },
                  height: "400px",
                }}
              >
                <img
                  src={
                    nftDetailResponse["imageUrl"]
                      ? nftDetailResponse["imageUrl"]
                      : overviewimage
                  }
                  alt="overview"
                  draggable="false"
                  width={"100%"}
                  height="inherit"
                  style={{
                    objectFit: "cover",
                    height: "inherit",
                    objectPosition: " 0 0",
                    borderRadius: "20.168px",
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                mt: { md: 0 },
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", lg: "500px" },
                  borderRadius: "20.168px",
                  border: " 0.84px solid rgba(255, 255, 255, 0.30)",
                  padding: { xs: "16px", lg: "2rem" },
                  height: "auto",
                  // marginLeft: "2px",
                  // paddingLeft: "3rem",
                }}
              >
                <Box sx={{ display: "flex", gap: "5px" }}>
                  {/* <Box sx={{ color: "blue" }}>
                    <Verified />
                  </Box> */}
                  <Typography
                    sx={{
                      fontSize: "20.168px",
                      fontFamily: "outfit",
                      fontWeight: "400",
                    }}
                  >
                    Collection Name: {nftDetailResponse["collectionName"]}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: 20, lg: 35.294 },
                      fontFamily: "outfit",
                      fontWeight: "600",
                    }}
                  >
                    {nftDetailResponse["nftName"]?.length > 18 ? (
                      <Tooltip
                        title={tooltipContent}
                        arrow
                        placement="bottom"
                        enterDelay={500}
                        enterNextDelay={500}
                        open={showFullTooltip}
                        sx={{ backgroundColor: "#black !important" }}
                      >
                        <span
                          onMouseEnter={() => setShowFullTooltip(true)}
                          onMouseLeave={() => setShowFullTooltip(false)}
                        >
                          {nftDetailResponse["nftName"].slice(0, 19) + "..."}
                        </span>
                      </Tooltip>
                    ) : (
                      nftDetailResponse["nftName"]
                    )}
                  </Typography>
                  <Button
                    sx={{
                      width: "77px",
                      height: "27px",
                      color: "white",

                      ...borderGradientBox,
                    }}
                  >
                    Art
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    width: { xs: "200px", md: "100%" },
                    overflow: { xs: "hidden", md: "visible" },
                    fontFamily: "outfit",
                  }}
                >
                  {" "}
                  Owned by{" "}
                  <span style={{ color: "#FF990D" }}>
                    {" "}
                    {nftDetailResponse["ownedBy"]
                      ? nftDetailResponse["ownedBy"]
                      : nftDetailResponse["walletAddress"]?.slice(0, 8) +
                        "....." +
                        nftDetailResponse["walletAddress"]?.slice(-8)}
                  </span>
                </Typography>
                <Stack>
                  <Box>
                    <Box
                      sx={{
                        display: "none",
                        mt: "20px",
                        gap: "15px",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "15px" }}>
                        <VisibilityIcon />
                        <Typography
                          sx={{ fontFamily: "outfit", fontSize: "15px" }}
                        >
                          123 views
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", gap: "15px" }}>
                        <FavoriteBorderTwoToneIcon />
                        <Typography
                          sx={{ fontFamily: "outfit", fontSize: "15px" }}
                        >
                          3 favourites
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
                <Box
                  sx={{
                    width: { xs: "auto", lg: "433px" },
                    height: "0.84px",
                    borderBottom: "1px solid white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "32px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontFamily: "outfit",
                      marginTop: "32px",
                    }}
                  >
                    Price : {nftDetailResponse.price} VOEUX{" "}
                  </Typography>
                </Box>
                {nftDetailResponse?.walletAddress?.toLowerCase() ===
                  localWalletAddress?.toLocaleLowerCase() &&
                  nftDetailResponse.sold && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "outfit",
                        }}
                      >
                        Offer Price :{" "}
                        {nftDetailResponse.MaxOfferPrice
                          ? nftDetailResponse.MaxOfferPrice
                          : 0}{" "}
                        VOEUX{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13.46px",
                          fontFamily: "outfit",
                          color: "gray",
                          marginTop: "2rem",
                          marginLeft: "10px",
                        }}
                      >
                        {" "}
                      </Typography>
                    </Box>
                  )}
                {nftDetailResponse?.walletAddress?.toLowerCase() !==
                localWalletAddress?.toLocaleLowerCase() ? (
                  <Box
                    sx={{
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      rowGap: "15px",
                    }}
                  >
                    <Button
                      sx={{
                        // ...borderGradientBox,
                        width: { xs: "auto", lg: "402.525px" },
                        height: "46.219px",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: "16px",
                        textTransform: "none",
                        borderRadius: "10px",
                        background:
                          "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        "&.Mui-disabled": {
                          background: "#1E213F",
                          color: "#7B7B7B !important",
                        },
                      }}
                      disabled={
                        nftDetailResponse.sold ||
                        disableButtons ||
                        nftDetailResponse.walletAddress == localWalletAddress
                          ? true
                          : false
                      }
                      onClick={() => handleMakeanOffer()}
                      className="makeanoffer"
                    >
                      <LocalOfferIcon />
                      Make an offer
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        flexDirection: { xs: "column", lg: "row" },
                      }}
                    >
                      {/* <Button
                      sx={{
                        width: "198.321px",
                        margin: "0.5rem",
                        borderRadius: "10px",
                        backgroundColor: "#181818",
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: "16px",
                        textTransform: "none",
                      }}
                    >
                      <ShoppingCartIcon />
                      Add to cart
                    </Button> */}
                      <Button
                        sx={{
                          width: "198.321px",
                          margin: "0.5rem",
                          borderRadius: "10px",
                          backgroundColor: "#FF990D",
                          color: "white",
                          fontFamily: "outfit",
                          fontSize: "16px",
                          textTransform: "none",
                          "&.Mui-disabled": {
                            background: "#1E213F",
                            color: "#7B7B7B",
                          },
                          "&:hover": {
                            backgroundColor: "#FF990D",
                            color: "white",
                          },
                        }}
                        disabled={
                          nftDetailResponse.sold ||
                          disableButtons ||
                          nftDetailResponse.walletAddress === localWalletAddress
                            ? true
                            : false
                        }
                        // onClick={() => setShowBuyNowDialog(true)}
                        onClick={handleBuyNow}
                      >
                        Buy Now
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      flexDirection: { xs: "column", lg: "row" },
                    }}
                  >
                    <Button
                      sx={{
                        width: "198.321px",
                        margin: "0.5rem",
                        borderRadius: "10px",
                        backgroundColor: "#FF990D",
                        color: "white",
                        fontFamily: "outfit",
                        fontSize: "16px",
                        textTransform: "none",
                        "&.Mui-disabled": {
                          background: "#1E213F",
                          color: "#7B7B7B",
                        },
                        "&:hover": {
                          backgroundColor: "#FF990D",
                          color: "white",
                        },
                      }}
                      disabled={!offerExists}
                      // onClick={() => setShowBuyNowDialog(true)}
                      onClick={handleAcceptOffer}
                    >
                      Accept Offer
                    </Button>
                  </Box>
                )}

                {/* <Typography
                  sx={{
                    fontSize: "20px",
                    fontFamily: "outfit",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  Sale ends in 0d 0h 17m 55s
                </Typography> */}
              </Box>
            </Grid>
          </Grid>
          {/* tabs started */}
          <Box sx={{ width: "100%", typography: "body1", marginTop: "0rem" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <Box
                  sx={{
                    maxWidth: { xs: 320, sm: 480, lg: "100%" },
                    bgcolor: "Transparent",
                    marginTop: "3rem",
                  }}
                >
                  <Tabs
                    className="customTabs nfttabs"
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                    TabIndicatorProps={{
                      title: "indicator",
                      sx: { backgroundColor: "transparent" },
                    }}
                  >
                    <Tab
                      sx={{
                        color: "white",
                        ...GradientBox,
                        fontSize: "16px",
                        textTransform: "none",
                        mr: "20px",
                        "&:hover": {
                          color: "white",
                          backgroundImage:
                            "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        },
                      }}
                      label="Overview"
                      value="1"
                    />
                    <Tab
                      sx={{
                        color: "white",
                        ...GradientBox,
                        fontSize: "16px",
                        textTransform: "none",
                        mr: "20px",
                        "&:hover": {
                          color: "white",
                          backgroundImage:
                            "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        },
                      }}
                      label="Traits"
                      value="2"
                    />
                    <Tab
                      sx={{
                        color: "white",
                        ...GradientBox,
                        fontSize: "16px",
                        mr: "20px",
                        textTransform: "none",
                        "&:hover": {
                          color: "white",
                          backgroundImage:
                            "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        },
                      }}
                      // onClick={getNftOffers}
                      label="Offer Received"
                      value="3"
                    />
                    {/* <Tab
                      sx={{
                        color: "white",
                        ...GradientBox,
                        fontSize: "16px",
                        textTransform: "none",
                        "&:hover": {
                          color: "white",
                          backgroundImage:
                            "linear-gradient(102deg, #FF990D 7.51%, #9D00FF 86.21%)",
                        },
                      }}
                      label="Item Activity"
                      value="4"
                    /> */}
                  </Tabs>
                </Box>
              </Box>
              <TabPanel
                value="1"
                sx={{
                  padding: "20px 0px",
                  "@media (max-width: 600px)": {
                    padding: "20px 10px",
                  },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontFamily: "outfit",
                      fontWeight: "600",
                      lineHeight: "27px",
                    }}
                  >
                    {" "}
                    Description
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontFamily: "outfit" }}>
                    {nftDetailResponse["description"]}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: { xs: "auto", lg: "auto" },
                    height: "0.84px",
                    borderBottom: "1px solid white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "32px",
                    marginBottom: "32px",
                  }}
                />
                {/* <Box>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontFamily: "outfit",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    About Creator
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontFamily: "outfit" }}>
                    {" "}
                    Dynamic means the amount of cards can be leveled up beyond
                    3.A newly minted bundle contains 9 cards with the potential
                    to level up to 21 cards (7 per pack).Individual RAM Cards
                    have multiple levels of rarity affecting anything from
                    attributes to cosmetic enhancements.The rarity chances for
                    this mint are significantly higher than they will ever be.
                  </Typography>
                </Box> */}
                {/* <Box
                  sx={{
                    width: { xs: "auto", lg: "auto" },
                    height: "0.84px",
                    borderBottom: "1px solid white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "32px",
                    marginBottom: "32px",
                  }}
                /> */}
                <Box
                  className="gtestbvta"
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "16px",
                    marginBottom: "32px",
                    width: { lg: "600px", md: "100%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontFamily: "outfit",
                      marginBottom: "15px",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    Details
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      marginBottom: "10px",
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        flexBasis: "50%",
                      }}
                    >
                      Supply{" "}
                    </Typography>
                    <Typography sx={{ fontFamily: "Outfit", fontSize: "16px" }}>
                      {nftDetailResponse["supply"]}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      marginBottom: "10px",
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        flexBasis: "50%",
                      }}
                    >
                      Owner{" "}
                    </Typography>
                    <Typography sx={{ fontFamily: "Outfit", fontSize: "16px" }}>
                      {nftDetailResponse["ownedBy"]
                        ? nftDetailResponse["ownedBy"]
                        : nftDetailResponse["walletAddress"]?.slice(0, 20) +
                          "....."}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      marginBottom: "10px",
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        flexBasis: "50%",
                      }}
                    >
                      Token ID
                    </Typography>
                    <Typography sx={{ fontFamily: "Outfit", fontSize: "16px" }}>
                      {nftDetailResponse["tokenId"]}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      marginBottom: "10px",
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        flexBasis: "50%",
                      }}
                    >
                      Token Standard
                    </Typography>
                    <Typography sx={{ fontFamily: "Outfit", fontSize: "16px" }}>
                      ERC-1115{" "}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      marginBottom: "10px",
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        flexBasis: "50%",
                      }}
                    >
                      Block Chain{" "}
                    </Typography>
                    <Typography sx={{ fontFamily: "Outfit", fontSize: "16px" }}>
                      Polygon{" "}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      marginBottom: "10px",
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        flexBasis: "50%",
                      }}
                    >
                      Last Updated{" "}
                    </Typography>
                    <Typography sx={{ fontFamily: "Outfit", fontSize: "16px" }}>
                      {new Date(nftDetailResponse["updatedAt"]).toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: { xs: "auto", lg: "auto" },
                    height: "0.84px",
                    borderBottom: "1px solid white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "32px",
                    marginBottom: "32px",
                  }}
                />
              </TabPanel>
              {/* overview ended */}
              {/* --------------------------------------- */}
              {/* <Traits started */}
              <TabPanel
                value="2"
                sx={{
                  padding: "20px 0px",
                  "@media (max-width: 600px)": {
                    padding: "20px 10px",
                  },
                }}
              >
                <Box
                  sx={{
                    fontFamily: "outfit",
                    fontSize: "16px",
                    marginBottom: "30px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontFamily: "outfit",
                        fontWeight: "600",
                      }}
                    >
                      Traits
                    </Typography>
                    {/* <Typography sx={{ color: "orange", marginBottom: "1rem" }}>
                      {" "}
                      Background
                    </Typography> */}
                  </Box>

                  {!nftDetailResponse.traits ? (
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontFamily: "outfit",
                          fontWeight: "400",
                        }}
                      >
                        No Traits to NFT
                      </Typography>
                      <Box
                        sx={{
                          width: { xs: "auto", lg: "auto" },
                          height: "0.84px",
                          borderBottom: "1px solid white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "32px",
                          marginBottom: "32px",
                        }}
                      />
                      {/* <Typography sx={{ color: "orange", marginBottom: "1rem" }}>
                      {" "}
                      Background
                    </Typography> */}
                    </Box>
                  ) : (
                    nftDetailResponse.traits?.map((item: any) => (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "outfit",
                            mt: "20px",
                          }}
                        >
                          <Typography sx={{ marginBottom: "10px" }}>
                            {item.type}{" "}
                          </Typography>
                          <Typography sx={{ marginBottom: "10px" }}>
                            {item.value}{" "}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: { xs: "auto", lg: "auto" },
                            height: "0.84px",
                            borderBottom: "1px solid white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "12px",
                            marginBottom: "12px",
                          }}
                        />
                      </>
                    ))
                  )}

                  {/* <Typography sx={{ marginBottom: "10px" }}>Red </Typography>
                    <Typography sx={{ marginBottom: "10px" }}>20% </Typography> */}

                  {/* <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ marginBottom: "10px" }}>Floor</Typography>
                    <Typography sx={{ marginBottom: "10px" }}>
                      0.58 Voeux{" "}
                    </Typography>
                  </Box> */}

                  {/* <Box>
                    <Typography
                      sx={{
                        color: "orange",
                        fontSize: "16px",
                        fontFamil: "outfit",
                        marginBottom: "1rem",
                      }}
                    >
                      Ears
                    </Typography>
                  </Box> */}
                  {/* <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ marginBottom: "10px" }}>
                      Orange{" "}
                    </Typography>
                    <Typography sx={{ marginBottom: "10px" }}>10% </Typography>
                  </Box> */}
                  {/* <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography sx={{ marginBottom: "10px" }}>Floor</Typography>
                    <Typography sx={{ marginBottom: "10px" }}>
                      0.58 Voeux{" "}
                    </Typography>
                  </Box> */}
                </Box>
              </TabPanel>
              {/* Traits ended */}
              {/* ---------------------------- */}
              {/* offer receieved started */}

              <TabPanel
                value="3"
                sx={{
                  padding: "20px 0px",
                  "@media (max-width: 600px)": {
                    padding: "20px 10px",
                  },
                }}
              >
                {nftOfferTable.length == 0 ? (
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontFamily: "outfit",
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    No Offer exist
                  </Typography>
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontFamily: "outfit",
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      Offer
                    </Typography>
                    <NFTTable
                      nftList={nftOfferTable}
                      numberList={true}
                      handleCancelOffer={handleCancelOffer}
                    />
                  </Box>
                )}
              </TabPanel>
              {/* offfer recevied ended */}
              {/* ---------------------------------- */}
              {/* item activity started */}
              <TabPanel
                value="4"
                sx={{
                  padding: "20px 0px",
                  "@media (max-width: 600px)": {
                    padding: "20px 10px",
                  },
                }}
              >
                <Box>
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontFamily: "outfit",
                      fontWeight: "600",
                    }}
                  >
                    Item Activity
                  </Typography>
                  <NFTTable
                    nftList={itemactivity}
                    numberList={false}
                    handleCancelOffer={handleCancelOffer}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Stack>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent", // Set the dialog background to transparent
              boxShadow: "none", // Remove the default Material-UI box shadow
            },
          }}
          open={showBuyNowDialog}
          onClose={handleClose}
          maxWidth={maxWidth}
        >
          <DialogContent>
            <MuiWalletConnect
              handleClose={() => handleClose()}
              buyConnect={true}
              handleRedirect={() => handleRedirectbuyNow()}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "hidden",
            },
          }}
          open={showPurchase}
          onClose={handleBuyNowClose}
          maxWidth={maxWidth}
        >
          <DialogContent>
            <Purchase
              handleBuyNowClose={handleBuyNowClose}
              nftData={nftDetailResponse}
            />
          </DialogContent>
        </Dialog>

        {/* //InSufficientFunds */}
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "hidden",
            },
          }}
          open={showInSufficient}
          onClose={handleSufficentClose}
          maxWidth={maxWidth}
        >
          <DialogContent>
            <InSufficientFunds handleSufficentClose={handleSufficentClose} />
          </DialogContent>
        </Dialog>
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              overflow: "hidden",
            },
          }}
          open={showMakeAnOffer}
          onClose={handleMakeOfferClose}
          maxWidth={maxWidth}
        >
          <DialogContent>
            <NftMakeAnOffer
              nftDetails={nftDetailResponse}
              createOffer={createOffer}
              handleMakeOfferClose={handleMakeOfferClose}
              handleMakeApiSuccess={handleMakeApiSuccess}
              showErrorToastMessageClick={showErrorToastMessageClick}
              handleShowLoader={handleShowLoader}
            />
          </DialogContent>
        </Dialog>
        <Box
          sx={{
            "@media (max-width: 600px)": {
              padding: "0 10px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "24px", xs: "22px" },
              fontFamily: "outfit",
              fontWeight: "600",
              marginBottom: "1rem",
            }}
          >
            {" "}
            More from this collection
          </Typography>
          <></>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(auto-fit, 100%)",
                  sm: "repeat(auto-fit, 255px)",
                  lg: "repeat(auto-fit, 255px)",
                },
                rowGap: "30px",
                columnGap: "30px",
              }}
            >
              {moreLikeCollection.length == 0 ? (
                <Typography variant="body2" sx={{ color: "white", mb: "20px" }}>
                  No More Like this Collection to display
                </Typography>
              ) : isMobile ? (
                <SimpleSlider
                  data={moreLikeCollectionMobile}
                  collectionCardClick={handleMoreLikeNFtClick}
                  ismoreLikeCollection={true}
                  category="moreLikeCollection"
                />
              ) : (
                <NftCollectionLiveCard
                  collectionList={moreLikeCollection}
                  category="moreLikeCollection"
                  handleMoreLikeNFtClick={handleMoreLikeNFtClick}
                />
              )}

              {/* <NftCollectionCard collectionList={collectionList} /> */}
            </Box>
          </Grid>
        </Box>
      </Box>
      <LandingPageFooter />
    </>
  );
}

export default NftDetailPage;
