import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, Stack, display, flexbox } from "@mui/system";
import React, { useEffect, useState } from "react";

import ProfileIcon from "@mui/icons-material/Person";
import Managebidsicon from "@mui/icons-material/Gavel";
import AccountsIcon from "@mui/icons-material/ManageAccounts";
import WatchListIcon from "@mui/icons-material/WatchLater";
import LanguageIcon from "@mui/icons-material/GTranslate";
import ActivityIcon from "@mui/icons-material/LocalActivity";

import NftCollectionCard from "../../pages/nftLanding/NftCollectionCard";
import NftLanguage from "./NftLanguage";
import NftActivity from "./NftActivity";
import NftManageBids from "./NftManageBids";
import NftProfile from "./NftProfile";
import NftAccount from "./NftAccount";
import NftWatchList from "./NftWatchList";

import styles from "../../pages/nftLanding/NftLanding.module.css";
import distantgalaxy from "../../assets/images/distantgalaxy.png";
import life from "../../assets/images/life.png";
import AstroFiction from "../../assets/images/AstroFiction.png";
import ColorfulDog from "../../assets/images/ColorfulDog.png";
import { useLocation } from "react-router-dom";

import NewNftHeader from "../../components/newNftHeader/NewNftHeader";
import LandingPageFooter from "../../components/landingPageFooter/LandingPageFooter";
import axios from "axios";
import DragDrop from "../../utils/getS3ImageURL";

function ProfileNftDesktop() {
  const location = useLocation();
  let data = location.state;
  // const [profileClick, setProfileClick] = useState(data)

  const collectionList = [
    {
      id: 1,
      creatorimg: distantgalaxy,
      category: "art",
      nftTitle: "Distant Galaxy",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "buyNow",
      type: "ERC721",
      isWishList: false,
    },
    {
      id: 2,
      creatorimg: distantgalaxy,
      category: "art",
      nftTitle: "Life On Edena",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "buyNow",
      type: "ERC721",
      isWishList: false,
    },
    {
      id: 3,
      creatorimg: distantgalaxy,
      category: "art",
      nftTitle: "AstroFiction",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "buyNow",
      type: "ERC721",
      isWishList: false,
    },
    {
      id: 4,
      creatorimg: distantgalaxy,
      category: "gaming",
      nftTitle: "ColorfulDog 0524",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "buyNow",
      type: "ERC721",
      isWishList: false,
    },
    {
      id: 5,
      creatorimg: life,
      category: "gaming",
      nftTitle: "AstroFiction",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "inAuction",
      type: "ERC721",
      isWishList: false,
    },
    {
      id: 6,
      creatorimg: life,
      category: "music",
      nftTitle: "Distant Galaxy",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "inAuction",
      type: "ERC721",
      isWishList: false,
    },
    {
      id: 7,
      creatorimg: life,
      category: "music",
      nftTitle: "Life On Edena",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "inAuction",
      type: "ERC1155",
      isWishList: false,
    },
    {
      id: 8,
      creatorimg: AstroFiction,
      category: "music",
      nftTitle: "AstroFiction",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "sell",
      type: "ERC1155",
      isWishList: false,
    },
    {
      id: 9,
      creatorimg: AstroFiction,
      category: "vedio",
      nftTitle: "ColorfulDog 0524",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "sell",
      type: "ERC1155",
      isWishList: false,
    },
    {
      id: 10,
      creatorimg: AstroFiction,
      category: "vedio",
      nftTitle: "Distant Galaxy",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "sell",
      type: "ERC1155",
      isWishList: false,
    },
    {
      id: 11,
      creatorimg: ColorfulDog,
      category: "digitalart",
      nftTitle: "Life On Edena",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "sell",
      type: "ERC1155",
      isWishList: false,
    },
    {
      id: 12,
      creatorimg: ColorfulDog,
      category: "digitalart",
      nftTitle: "AstroFiction",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "offers",
      type: "ERC1155",
      isWishList: false,
    },
    {
      id: 13,
      creatorimg: ColorfulDog,
      category: "digitalart",
      nftTitle: "ColorfulDog 0524",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "offers",
      type: "ERC1155",
      isWishList: false,
    },
    {
      id: 14,
      creatorimg: ColorfulDog,
      category: "painting",
      nftTitle: "Distant Galaxy",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "offers",
      type: "ERC1155",
      isWishList: false,
    },
    {
      id: 15,
      creatorimg: ColorfulDog,
      category: "ai",
      nftTitle: "Distant Galaxy",
      pricetitle: "price",
      price: "1.63 Voeux",
      status: "offers",
      type: "ERC1155",
      isWishList: false,
    },
  ];

  const [tabs, setTabsData] = useState({
    profile: true,
    manage: false,
    account: false,
    watchList: false,
    language: false,
    activity: false,
  });

  const [profileTabsActive, setProfileTabsActive] = useState("Profile");
  const handleTabsData = (value: string) => {
    // console.log("kjhugt", value);

    if (value == "Profile") {
      setTabsData({
        profile: true,
        manage: false,
        account: false,
        watchList: false,
        language: false,
        activity: false,
      });
    }
    if (value == "manage") {
      setTabsData({
        profile: false,
        manage: true,
        account: false,
        watchList: false,
        language: false,
        activity: false,
      });
    }
    if (value == "account") {
      setTabsData({
        profile: false,
        manage: false,
        account: true,
        watchList: false,
        language: false,
        activity: false,
      });
    }
    if (value == "watchList") {
      setTabsData({
        profile: false,
        manage: false,
        account: false,
        watchList: true,
        language: false,
        activity: false,
      });
    }
    if (value == "language") {
      setTabsData({
        profile: false,
        manage: false,
        account: false,
        watchList: false,
        language: true,
        activity: false,
      });
    }
    if (value == "activity") {
      setTabsData({
        profile: false,
        manage: false,
        account: false,
        watchList: false,
        language: false,
        activity: true,
      });
    }
  };
  const buttonBox = {
    border: "1px solid #FF990D",
    color: "white",
  };
  const circleStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: 0,
    flexShrink: 0,
  };
  const [copyTooltip, setCopyTooltip] = useState(false);
  const [copyMobileTooltip, setCopyMobileTooltip] = useState(false);
  const handleAddressCopy = () => {
    setCopyTooltip(true);
    navigator.clipboard.writeText("0x96c4869380499A7151FEFB084b42BDED7B082220");
  };

  const handleMobileAddressCopy = () => {
    setCopyMobileTooltip(true);
    navigator.clipboard.writeText("0x96c4869380499A7151FEFB084b42BDED7B082220");
  };
  const [formdata, setFormData] = useState<any>({});

  return (
    <>
      <Box
        className={styles.mainContainer}
        sx={{
          background: "#00042A",
          "@media (min-width: 1721px)": {
            padding: "0% 10% 0% 10%",
          },
        }}
      >
        <NewNftHeader nftHeader={true} />
        <Box>
          <Stack sx={{ pt: "60px" }}>
            <Grid sx={{ display: "flex" }} container spacing={12}>
              <Grid item xs={12} sm={6} sx={{ mt: { md: 0 }, width: "100%" }}>
                <NftProfile />
              </Grid>
            </Grid>
            {tabs.account && (
              <Box>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(auto-fit, 100%)",
                        lg: "repeat(auto-fit, 276px)",
                        marginTop: "2rem",
                      },
                      rowGap: "15px",
                      columnGap: "15px",
                    }}
                  >
                    <NftCollectionCard
                      collectionList={collectionList}
                      isWatchListRequired={true}
                    />
                  </Box>
                </Grid>
              </Box>
            )}

            {tabs.watchList && (
              <Box>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(auto-fit, 100%)",
                        lg: "repeat(auto-fit, 276px)",
                        marginTop: "2rem",
                      },
                      rowGap: "15px",
                      columnGap: "15px",
                    }}
                  >
                    <NftCollectionCard
                      collectionList={collectionList}
                      isWatchListRequired={true}
                    />
                  </Box>
                </Grid>
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
      <LandingPageFooter />
    </>
  );
}

export default ProfileNftDesktop;
