import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import indiaflag from "../../assets/voeuxpay/IN.png";
import vietnam from "../../assets/voeuxpay/VN.png";
import thai from "../../assets/voeuxpay/TH.png";
import indo from "../../assets/voeuxpay/INDO.png";
import sing from "../../assets/voeuxpay/SG.png";
import mal from "../../assets/voeuxpay/MY.png";
import phil from "../../assets/voeuxpay/PH.png";
import styles1 from "../../../src/components/LandingPageFloatingList/LandingPageFloatingList.module.css";
const flagsDataone = [
  { image: indiaflag, country: "INDIA" ,color:"#27AE60"},
  { image: vietnam, country: " VIETNAM",color:"#8E28A5" },
  { image: thai, country: " THAILAND",color:"#6452FF" },
  { image: indo, country: " INDONESIA" ,color:"#56CCF2"},
  { image: phil, country: " PHILIPPINES",color:"#F2C94C" },
  { image: sing, country: " SINGAPORE",color:"#00FF38" },
  { image: mal, country: " MALAYSIA" ,color:"#EB5757"},
  { image: phil, country: " PHILIPPINES",color:"#F2C94C"  },
  { image: indiaflag, country: "INDIA" ,color:"#27AE60"},
  { image: vietnam, country: " VIETNAM",color:"#8E28A5" },
  { image: thai, country: " THAILAND",color:"#6452FF" },
  { image: indo, country: " INDONESIA",color:"#56CCF2" },
  { image: phil, country: " PHILIPPINES",color:"#F2C94C"  },
  { image: indiaflag, country: "INDIA",color:"#27AE60" },
  { image: vietnam, country: " VIETNAM",color:"#8E28A5" },
  { image: thai, country: " THAILAND",color:"#6452FF"  },
  { image: phil, country: " PHILIPPINES",color:"#F2C94C"  },
  { image: indiaflag, country: "INDIA",color:"#27AE60" },
  { image: vietnam, country: " VIETNAM",color:"#8E28A5" },
  { image: thai, country: " THAILAND",color:"#6452FF"  },
];

function FlagAnimation() {
  return (
    <>
      <Box
        display="flex"
        className={styles1.floatingListContainer}
        sx={{ margin: "auto",width:"60%" }}
      >
        {flagsDataone.map((flag) => {
          return (
            <Box
              className={styles1.floatingList}
              // mr={2}
              sx={{ fontFamily: "outfit" ,marginRight:"70px"}}
            >
              <Box
                //   border={"1px solid white"}
                mr={2}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    fontFamily: "outfit",
                    display: "flex",
                    width:"131px",    
                    alignItems: "baseline",
                  }}
                >
                  <img
                    src={flag.image}
                    alt={flag.country}
                    style={{ width: "28px", height: "20px" }}
                  />

                  <Typography
                    sx={{ fontSize: "36px", fontFamily: " Bebas Neue",color:flag.color }}
                  >
                    {flag.country}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default FlagAnimation;
